const b2cPolicies = {
  names: {
    signUpSignIn: process.env.VUE_APP_B2C_SIGNIN_POLICY,
    forgotPassword: "B2C_1_reset_password_email"
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.VUE_APP_AZURE_AUTHORITY,
    },
    forgotPassword: {
      authority: process.env.VUE_APP_AZURE_RESET_PASS,
    },
  },
}

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorities.signUpSignIn.authority, b2cPolicies.authorities.forgotPassword.authority],
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line
          console.log(level, message)
        }
      }
    }
  }
}

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
const loginRequest = {
  scopes: ["openid", "profile"],
  prompt: "login",
}

// Add here scopes for access token to be used at the API endpoints.
const tokenRequest = {
  scopes: [process.env.VUE_APP_TOKEN_REQUEST_SCOPE],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
}

export default {
  b2cPolicies,
  msalConfig,
  loginRequest,
  tokenRequest
}
