<template>
  <validation-observer ref="observer" tag="fieldset">
    <b-card-body>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="hold"
            ref="hold"
            :label="$t('hold') | capitalize"
            label-cols="2"
            v-slot="{ state }"
            >
              <b-form-select v-model="distribution.hold" :options="holdOptions" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="notes"
            ref="notes"
            :label="$t('notes') | capitalize"
            label-cols="2"
            v-slot="{ state }"
            >
              <b-form-textarea v-model="distribution.notes" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </validation-observer>
</template>

<script>
import DistributionFormGroup from "@/components/DistributionFormGroup"
import { ValidationObserver } from "vee-validate"
import { capitalize } from "lodash"
import { distributionCollegeTypes } from "@/constants"
import { distributionSectionMixin } from "@/utils/mixins"

export default {
  name: "OtherSection",
  components: { DistributionFormGroup, ValidationObserver },
  mixins: [ distributionSectionMixin ],
  props: {
    distribution: Object
  },
  data () {
    return {
      holdOptions: [
        {
          text: capitalize(this.$t("performers")),
          value: distributionCollegeTypes.PERFORMER
        },
        {
          text: capitalize(this.$t("makers")),
          value: distributionCollegeTypes.MAKER
        },
        {
          text: capitalize(this.$t("both")),
          value: distributionCollegeTypes.BOTH
        }
      ],
    }
  },
}
</script>
