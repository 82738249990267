<template>
  <modal
      id="edit-first-makers"
      :customTitle="$t('update_first_makers') | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered 
      @ok="saveFirstMakers"
      @show="showModal"
      :okTitle="$t('save') | capitalize"
      footer-class="pl-5 pr-5"
    >
    <div>
      <b-table
        :fields="fields"
        :items="items"
        striped
        fixed
      >
        <template v-slot:cell(name)="row">
          <b-input-group>
            <b-form-input
                v-model="row.item.name"
            />
          </b-input-group>
        </template>
        <template v-slot:cell(nationalities)="row">
          <b-row>
            <b-col cols="11">
              <multi-select
                v-model="row.item.nationalities"
                :multiple="true"
                :options="countryOptions"
                label="text"
                track-by="alpha2"
              ></multi-select>
            </b-col>
            <b-col cols="1" class="m-auto">
              <fa-icon
                :icon="['fas', 'trash']"
                role="button"
                :title="$t('delete') | lowercase"
                class="clickable"
                @click="removeFirstMaker(row.index)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-button class="ml-3 btn-outline-red"
        @click="addFirstMaker">
        {{ $t("add_first_maker") | capitalize }}
      </b-button>
    </div>
  </modal>
</template>

<script>
import { mapState } from "vuex"
import { startCase } from "lodash"

export default {
  name: "EditFirstMakers",
  props: {
    firstMakers: {
      type: Array,
      required: true,
    }
  },
  data () {
    return {
      items: [],
      fields: [
        {
          key: "name",
          label: startCase(this.$t("name"))
        },
        {
          key: "nationalities",
          label: startCase((this.$t("nationalities")))
        },
      ]
    }
  },
  computed: {
    ...mapState("consts", ["countries"]),
    countryOptions () {
      return this.countries ? this.countries : null
    }
  },
  methods: {
    showModal () {
      this.items = this.firstMakers.map( firstMaker => {
        return {
          name: firstMaker.name,
          nationalities: firstMaker.nationalities.map(alpha2 => this.countries.find(c => c.alpha2 === alpha2))
        }
      })
    },
    removeFirstMaker (index) {
      this.items.splice(index, 1)
    },
    addFirstMaker () {
      this.items.push({
        name: "",
        nationalities: [],
      })
    },
    saveFirstMakers () {
      this.$emit("ok", this.items.filter(fm => fm.name).map(firstMaker => {
        return {
          name: firstMaker.name,
          nationalities: firstMaker.nationalities.map(c => c.alpha2)
        }
      }))
    }
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  min-height: 40px;
}
.custom-modal {
  .modal-body {
    .table-striped {
      border: none;
      ::v-deep tr{
        background-color: $modal-gray !important;
      }
    }
  }
}
.btn-outline-red {
  min-width: 108px;
}
</style>
