<template>
  <list-layout
      :isChild="true"
      :title="$t('ownership_conflicts') | startcase"
      :count="userActionCounts.ownershipConflicts"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
  >
    <template v-slot:search>
      <ValidationObserver ref="fuzzySearchForm">
        <b-form-group class="pt-1">
          <form-field
            class="file-search"
            :placeholder="$t('search') | capitalize"
            v-model="filters.fuzzy_search.value"
            :enter-action="applyFilters"
            :icon="['fas', 'search']"
          />
        </b-form-group>
      </ValidationObserver>
    </template>

    <template v-slot:filters>
      <b-row class="justify-content-end pt-1">
        <b-col cols="7">
          <b-form-group
            label-cols="4"
            :label="$t('select_date_range') | capitalize"
            v-if="showResolved"
          >
            <date-picker
              class="w-100"
              v-model="dateRange"
              range
              :format="$config.DATE_FORMAT"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <select-search
                :list-endpoint="$api.agents.agentsList"
                :placeholder="$t('search_agent') | capitalize"
                search-name="search"
                exclude-name="id"
                :params="{}"
                text-field="name"
                value-field="id"
                display-field="text"
                v-model="selectedAgent"
                ref="selectAgent"
                :show-value="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="pt-1 d-flex justify-content-end">
          <b-button  class="mr-3" size="sm" variant="outline-primary" @click="exportConflicts">
            <fa-icon size="sm" :icon="['fa', 'cloud-upload-alt']" />
            {{ $t("export") | capitalize }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-col cols="3" class="pt-2 d-flex justify-content-end">
          <b-form-checkbox v-model="hideInvalidated" class="mr-3" switch>
            {{ $t("hide_invalidated") | capitalize }}
          </b-form-checkbox>
        </b-col>
         <b-col cols="3" class="pt-2 d-flex justify-content-end">
          <b-form-checkbox v-model="showResolved" class="mr-3" switch>
            {{ $t("show_resolved") | capitalize }}
          </b-form-checkbox>
        </b-col>
     </b-row>
    </template>

    <template v-slot:counts>
      <pagination
          class="smaller p-0 mb-3 mt-3"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="perPage"
          @limit="onLimit"
      />
    </template>
    <modal 
      id="ownership-conflicts-export-modal" 
      :customTitle="exportTitle" 
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered 
      hide-footer>
      <ownership-conflicts-export-form :count="count"/>
    </modal>


    <b-card no-body v-for="(conflict, index) in data" :key="index" :class="{ 'blink': shouldBlink(conflict.uuid), 'd-none': isHidden(conflict.uuid)}">
      <modal
        :id="`confirmation-modal-solved-${conflict.uuid}`"
        :customTitle="$t('user_action_solved') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="md"
        centered 
        hide-footer
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
      >
        <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
        <b-row class="float-right modal-footer mt-4">
          <b-button @click="blinkAndHide(conflict.uuid, `conflict-${conflict.uuid}`, getData)" class="btn-outline-red">
            {{ $t("accept") | capitalize }}
          </b-button>
        </b-row>
      </modal>
      <b-card-header class="first-level">
        <b-row align-v="center">
          <b-col cols="6">
            <fa-icon
              v-if="isInProgressFrontend(conflict.uuid) || (isInProgressBackend(conflict.userActionsStatuses) && !isReleasedFrontend(conflict.uuid))"
              :icon="['fa', 'user-edit']"
               color="red"
            />
            <collapse-button class="collapse-button" :toggle="`conflict-${conflict.uuid}`" :expanded="expanded[index]" :disabled="loading" />
            <database-link :item-id="conflict.uuid" :type="linkTypes.RECORDING">{{ conflict.resoundId }}</database-link>
            {{ conflict.title }} - {{ conflict.artist }}
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <b-row class="w-100">
              <b-col cols="2">
                {{ $t('created_on') | capitalize }}:
                {{ parseDate(conflict.userActionCreated, $config.DATE_FORMAT) }}
              </b-col>
              <b-col cols="2">
                {{ $t('playcount') | capitalize }}:
                {{ conflict.playCount }}
              </b-col>
              <b-col cols="2">
                {{ $t('weighted_playcount') | capitalize }}:
                {{ conflict.weightedPlayCount }}
              </b-col>
              <b-col cols="2">
                  {{ $t('royalties_paid') | capitalize }}:
                  <span class="smaller">
                    CAD
                  </span>
                  {{ conflict.royaltiesPaid }}
              </b-col>
              <b-col cols="2">
                {{ $t('estimated_track_value') | capitalize }}:
                <span class="smaller">
                  CAD
                </span>
                {{ conflict.estimatedTrackValue }}
              </b-col>
              <b-col cols="2">
                <status
                  v-if="(
                    isInProgressFrontend(conflict.uuid) ||
                    (isInProgressBackend(conflict.userActionsStatuses) && !isReleasedFrontend(conflict.uuid))
                  ) && !isSolved(conflict.uuid)"
                  :text="$t('in_progress') | capitalize"
                  :icon="['fas', 'question-circle']"
                  color="orange"
                />
                <status
                  v-else-if="(isPending(conflict.userActionsStatuses) || isReleasedFrontend(conflict.uuid)) && !isSolved(conflict.uuid)"
                  :text="$t('conflict') | capitalize"
                  :icon="['fas', 'exclamation-circle']"
                  color="red"
                />
                <status
                  v-else
                  :text="$t('resolved') | capitalize"
                  :icon="['fas', 'check-circle']"
                  color="green"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse
        accordion="owners-conflicts-list"
        :id="'conflict-' + conflict.uuid"
        v-model="expanded[index]"
        role="tabpanel"
        @hide="onClosed(conflict.uuid)"
        @show="onOpened(conflict.uuid)"
      >
        <b-card-body v-if="expanded[index]">
          <owners
            :recordingUUID="conflict.uuid"
            :resound-id="conflict.resoundId"
            :view-mode="viewMode"
            :solved-manually="solvedManually"
            :onDone="onDone"
            :hide-invalidated="hideInvalidated"
          />
          <div class="mt-3">
            <h3 class="text-red">{{ $t("comments") | startcase }}</h3>
            <comments
              :createEndpoint="$api.comments.ownershipConflictCommentCreate"
              :listEndpoint="$api.comments.ownershipConflictCommentsList"
              :uuid="conflict.uuid"
              :editing=false
              :deleting=false
            />
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="count"
      :per-page="perPage"
    />
  </list-layout>
</template>

<script>
import {
  UserActionTypeCodes,
  actionsTabs, 
  conflictModes,
  getPerPageItemDefaults,
  linkTypes,
  userActionNotifications, 
  userActionStatuses 
} from "@/constants"
import { capitalize, get } from "lodash"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import { mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink"
import OwnershipConflictsExportForm from "./Forms/OwnershipConflictsExportForm"
import Pagination from "@/components/Pagination"
import { ValidationObserver } from "vee-validate"

import { websocketsMixin } from "@/utils/wsmixin"


export default {
  name: "OwnershipConflictList",
  mixins: [datePickerMixin, listRouteMixin, websocketsMixin],
  computed: {
    ...mapState("user", ["permissions"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    viewMode (){
      if (!this.permissions.actionsTabs[actionsTabs.RIGHTS_OWNERSHIP_CONFLICT].canWrite) {
        return conflictModes.VIEW
      }
      return this.showResolved ? conflictModes.VIEW : conflictModes.SOLVE
    },
    // WS values
    shownUuidList () {
      return this.data.map(c => c.uuid)
    },

    exportTitle () {
      return capitalize(this.$t("export_ownership_conflicts"))
    },
    showResolved: {
      get () {
        return this.filters.status.value.length === 1 && this.filters.status.value[0] === userActionStatuses.DONE
      },
      set (value) {
        this.filters.status.value = value ? userActionStatuses.DONE : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
      },
    },
  },
  components: {
    DatabaseLink,
    Owners: () => import("../Repertoire/Recordings/RecordingDetail/Owners/Owners"),
    OwnershipConflictsExportForm,
    Pagination,
    ValidationObserver,
  },
  data () {
    return {
      data: [],
      count: 0,
      loading: true,
      hideInvalidated: true,
      page: 1,
      expanded: [],
      filters: {
        fuzzy_search: { value: "", defaultValue: "" },
        agent: { value: "", defaultValue: "" },
        status: { value: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS], defaultValue: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS] },
        action_types: { value: "", defaultValue: [UserActionTypeCodes.RIGHTS_OWNERSHIP_CONFLICT] },
      },
      perPage: Number(getPerPageItemDefaults(this.$route)),
      // WS values
      userActionNotification: userActionNotifications.OWNERSHIP,
      storeStatusesKey: "wsOwnershipConflictStatuses",
      selectedAgent: null,
      linkTypes,
    }
  },
  methods: {
    ...mapMutations("alert", ["error"]),
    onDone () {
      this.getData()
    },
    exportConflicts () {
      this.$bvModal.show("ownership-conflicts-export-modal")
    },
    responseAdapter (resp) {
      if (resp) {
        this.data = resp.data.results
        this.count = resp.data.count
      }
      this.loading = false
    },
    getData () {
      this.loading = true
      this.$api.actions.ownershipConflicts({ ...this.$route.query })
        .then((resp) => {
          this.responseAdapter(resp)
        })
        .catch((error) => {
          if (error.response.data.fuzzySearch) {
            this.error(capitalize(this.$t("wrong_filters")))
          }
          this.$refs.fuzzySearchForm.setErrors(error.response.data)
        })
        .finally(() => {
          this.expanded = []
        })
    },
  },
  watch: {
    selectedAgent (value) {
      this.filters.agent.value = value ? value.id : null
    },
    page (value) {
      this.onPagination(value)
      this.getData()
    },
    perPage () {
      if (this.page === 1) {
        this.getData()
      } else {
        this.page = 1
      }
    },
    "filters.agent.value": function (newVal) {
      if (!newVal) {
        this.selectedAgent = null
      } else {
        this.$refs.selectAgent.searchValue = newVal
      }
    }
  },
  mounted () {
    this.getData()
    this.page = Number(get(this.$route.query, "page", 1))
    this.perPage = Number(get(this.$route.query, "limit", this.perPage))
    this.showResolved = get(this.$route.query, "status", [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]) === [userActionStatuses.DONE]
  }
}
</script>
<style scoped>
::v-deep td:nth-child(1) {
  width: 1%;
  vertical-align: middle;
}

::v-deep .mid {
  vertical-align: middle;
}

/* stylelint-disable */
::v-deep .VueTables__child-row-toggler--closed::before {
  content: "\203a";
  font-size: 30px;
}

::v-deep .VueTables__child-row-toggler--open::before {
  content: "\2304";
  font-size: 30px;
}

::v-deep .smaller {
  font-size: 10pt;
}
.card {
  margin-bottom: 10px;
}
/* stylelint-enable */
</style>
