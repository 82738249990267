export default {
  accept: "accept",
  action_type: "action type",
  actions: "actions",
  active: "active",
  activity_type: "activity type",
  add: "add",
  add_a_comment: "add a comment",
  add_a_parameter: "add a parameter",
  add_album: "add album",
  add_all: "add all",
  add_comment: "add comment",
  add_featured_performer: "add featured performer",
  add_filter: "add filter",
  add_first_maker: "add first maker",
  add_new_parameter: "add new parameter",
  add_non_featured_performer: "add non-featured performer",
  add_ownership: "add ownership",
  advanced_search: "advanced search",
  affected_payments_by_effective_date: "affected payments by effective date",
  agent: "agent",
  aggregated: "aggregated",
  album: "album",
  album_names: "album names",
  albums: "albums",
  alerts: "alerts",
  aliases: "aliases",
  all: "all",
  all_except: "all except",
  all_member_collectives: "all member collectives",
  amount: "amount",
  amount_gross: "gross amount",
  answered_by: "answered by",
  any: "any",
  anyone: "anyone",
  anyone_but_my_mc: "Anyone but my MC",
  apply: "apply",
  apply_effective_date: "apply effective date",
  approve_merge: "approve merge",
  artist: "artist",
  artist_prefix: "artist prefix",
  at: "at",
  auto_manually_matched: "auto manually matched",
  automatched: "automatched",
  automatched_count: "automatched count",
  automatic: "automatic",
  backup_eligibility_scheme: "backup eligibility scheme",
  batch: "batch",
  both: "both",
  broadcast_end: "broadcast end",
  broadcast_start: "broadcast start",
  broadcaster_id: "broadcaster ID",
  broadcaster_name: "broadcaster name",
  broadcasters: "broadcasters",
  by: "by",
  by_distribution: "by distribution",
  by_transaction: "by transaction",
  calculate_withholding: "calculate withtholding",
  canada: "canada",
  canada_and: "Canada and",
  cancel: "cancel",
  cancel_create_tariff_period: "Are you sure you want to cancel creating this Tariff period? Any unsaved progress will be lost",
  candidates: "candidates",
  category: "category",
  challenge: "challenge",
  challenge_match: "challenge match",
  challenge_owner: "challenge linked owner",
  challenge_pending: "challenge pending",
  challenge_performer: "challenge linked performer",
  challenge_source_metadata: "challenge linked metadata",
  challenge_without_recording: "create a challenge without a suggested recording",
  challenge_without_rs_id: "Challenge Without Re:Sound ID",
  challenged: "challenged",
  challenged_by: "challenged by",
  challenged_on: "challenged on",
  challenges: "challenges",
  challenges_pending: "challenges pending",
  child_recordings_to_merge: "child recording(s) to merge",
  choose_vpp_method: "Choose the method for creating VPP",
  citizenship: "citizenship",
  claims: "claims",
  code: "code",
  cof: "cof",
  collapse_all: "collapse all",
  collective: "collective",
  collective_society: "Collective/Society",
  collectives: "collectives",
  college: "college",
  comment: "comment",
  comment_created_successfully: "Comment created successfully",
  comment_deleted_successfully: "Comment deleted successfully",
  comment_edited_successfully: "Comment edited successfully",
  comment_without_recording: "create a comment without a suggested recording",
  comments: "comments",
  common: "common",
  compact_view: "compact view",
  compare_performers: "compare performers",
  complete: "complete",
  completed: "completed",
  composer: "composer",
  composers: "composers",
  confirm: "confirm",
  confirm_the_match: "confirm the match",
  conflict: "conflict",
  conflicted_ipn: "conflicting IPN",
  conflicted_isrc: "conflicting ISRC",
  conflicts: "conflicts",
  connect_reproduction: "CONNECT",
  consolidated_metadata: "consolidated metadata",
  consolidated_usages: "consolidated usages",
  contact_admin: "please contact an administrator",
  contact_service_desk: "contact service desk",
  copy: "copy",
  copy_role: "copy role",
  copyright: "copyright",
  copyrights: "copyrights",
  copyrights_and_tariffs: "copyrights and tariffs",
  core_data: "core data",
  core_metadata: "core metadata",
  countries: "countries",
  country: "country",
  country_of_fixation: "country of fixation",
  country_of_residency: "Country of Residency",
  create: "create",
  create_merge_request: "create merge request",
  create_new_sr: "Create new SR",
  create_new_tariff_period: "create new tariff period",
  create_rightsholder: "Create rightsholder",
  create_role: "create user role",
  create_tariff_period: "create tariff period",
  create_tariff_period_confirm: "Are you sure you want to create the tariff/period?",
  created: "created",
  created_by: "created by",
  created_on: "created on",
  created_successfully: "created successfully",
  crescendo_id: "crescendo ID",
  crescendo_help: "crescendo help",
  crescendo_system: "Crescendo System",
  csv: "csv",
  currency: "currency",
  current: "current",
  current_matched_recording: "current matched recording",
  cusma: "cusma",
  custom: "custom",
  da_agent: "DA Agent",
  da_name: "DA Name",
  da_type: "DA Type",
  database: "database",
  date: "date",
  date_bought: "date bought",
  date_end: "date end",
  date_from: "date from",
  date_of_birth: "date of birth",
  date_of_death: "date of death",
  date_of_merge: "date of merge",
  date_range: "date range",
  date_sold: "date sold",
  date_start: "date start",
  date_to: "date to",
  default_split: "default split",
  delete: "delete",
  delete_comment: "delete comment",
  delete_user: "delete user",
  delete_tariff_period: "delete tariff period",
  deleted: "deleted",
  depending_of_how_big_the_repertoire_is: "depending of how big the repertoire is, the export process can take a while",
  depending_of_how_big_the_report_is: "depending of how big the report is, the export process can take a while",
  description: "description",
  detailed_stats: "detailed statistics",
  different_type: "different type",
  direct_assignor: "direct assignor",
  direct_assignors: "direct assignors",
  dis: "dis",
  display_all_users: "Display all Users",
  display_by: "display by",
  display_by_group: "Display by group",
  distributed: "distributed",
  distribution: "distribution",
  distribution_date: "distribution date",
  distribution_hold: "distribution hold",
  distribution_methodology: "distribution methodology",
  distribution_methodology_and_eligibility: "distribution methodology and eligibility",
  distribution_period: "distribution period",
  do_you_really_want_to_delete_comment: "do you really want to delete this comment",
  do_you_really_want_to_delete_user: "do you really want to delete this user",
  do_you_really_want_to_delete_tariff_period: "do you really want to delete this tariff period",
  do_you_really_want_to_invalidate_file: "do you really want to invalidate this repertoire file",
  do_you_really_want_to_invalidate_shares: "do you really want to invalidate this shares",
  do_you_really_want_to_link_this_maker: "do you really want to link this maker",
  do_you_really_want_to_link_this_performer: "do you really want to link this performer",
  do_you_really_want_to_link_this_recording: "do you really want to link this recording",
  do_you_really_want_to_unmatch_usage: "do you really want to unmatch this usage",
  do_you_want_to_launch_rematching_process: "do you want to launch the rematching process",
  done: "done",
  dont_have_financial_interest: "without financial interest",
  download: "download",
  download_template: "download template",
  duplicated_conflicts: "Duplicated ISRCs & IPNs",
  duplicated_conflicts_ipn: "Duplicated IPNs",
  duplicated_conflicts_isrc: "Duplicated ISRC",
  duplicated_performer: "duplicated performer in different categories",
  dur: "dur.",
  duration: "duration",
  edit: "edit",
  edit_role: "edit user role",
  effective_date: "effective date",
  eligibility: "eligibility",
  eligibility_conflict: "eligibility conflict",
  eligibility_conflicts: "eligibility conflicts",
  eligibility_data: "eligibility data",
  eligibility_end_date: "eligibility end date",
  eligibility_metadata: "eligibility metadata",
  eligibility_parameter: "eligibility parameter",
  eligibility_scheme: "eligibility scheme",
  eligibility_start_date: "eligibility start date",
  email: "email",
  end_date: "end date",
  end_date_should_be_greater_or_equal: "End date should be greater or equal to start date",
  endpoint: "endpoint",
  enter_name_or_description: "enter a name or description",
  error: "error",
  error_occurred: "an error occurred, please contact an administrator",
  error_type: "error type",
  error_updating_user: "error updating user",
  errors: "errors",
  estimated_track_value: "estimated track value",
  excel: "excel",
  except: "except",
  expand_all: "expand all",
  expand_all_sources: "expand all sources",
  export: "export",
  export_ownership_conflicts: "export ownership conflicts",
  export_repertoire: "export repertoire",
  export_repertoire_for_ss: "export repertoire for sister society",
  export_tariff_period_list: "export tariff period list",
  export_unclaimed_recordings: "export unclaimed recodings",
  export_usages: "export usages",
  exported_file_will_contain_unique_recordings: "exported file will contain unique recordings, and will have less results than shown on screen when multiple consolidated usages are matched to the same recording.",
  external: "external",
  failed: "failed",
  featured: "featured",
  featured_and_non_featured: "featured and non-featured",
  female: "female",
  field: "field",
  file: "file",
  filename: "filename",
  files_exceed_the_rows_limitation: "files exceed the 200,000 rows limitation of the Excel file format",
  fill_in_the_missing_fields: "please fill in all the required information before submitting",
  filter_by_action: "filter by action",
  filter_by_collective_society: "Filter by Collective/Society",
  filter_by_role: "Filter by Role",
  filter_by_sister_society: "Filter by Collective/Society",
  filter_by_state: "filter by state",
  filters: "filters",
  first_maker: "first maker",
  first_maker_nationality: "first maker nationality",
  first_makers: "first makers",
  first_makers_nationalities: "first makers nationalities",
  fixation_country: "fixation country",
  fixation_year: "fixation year",
  fm_nationality: "FM Nationality",
  fmn: "fmn",
  for: "for",
  form_contains_errors: "form contains errors",
  format_file: "format file",
  from_maker_source: "Data from maker source or migrated from OPUS",
  from_role: "from role",
  gender: "gender",
  general_details: "general details",
  genre: "genre",
  genre_conflict_pending: "genre conflict pending",
  genre_type: "Genre Type",
  go_to: "go to",
  gross: "gross",
  gross_amount:"gross amount",
  has_an_agent: "has an agent",
  has_candidates: "has candidates",
  has_financial_interest: "has financial interest",
  has_owners: "has owners",
  has_performers: "has performers",
  have_financial_interest: "with financial interest",
  help_guide: "help guide",
  hide_invalidated: "hide invalidated",
  hide_without_mandates: "hide without mandates",
  highlight_differences: "highlight differences",
  history: "history",
  hold: "hold",
  hold_reason: "hold reason",
  hotkeys: "hotkeys",
  iconography: "iconography",
  id: "ID",
  id_proprietary_id: "ID / Proprietary ID",
  id_resound_id: "ID / Resound ID",
  import: "import",
  in: "in",
  in_progress: "in progress",
  inactive: "inactive",
  incompatible_types_to_merge: "selected recording has incompatible types to merge",
  inconsistent_genres: "different genres provided in different sources",
  inconsistent_number_of_performers: "inconsistent number of performers provided in different sources",
  indirect_mc: "Indirect MC",
  information: "information",
  informational: "informational",
  ingested: "ingested",
  ingested_count: "ingested count",
  ingested_on: "ingested on",
  ingestion_error: "ingestion error",
  ingestion_error_message: "file ingestion encountered an error:",
  ingestion_stats: "ingestion stats",
  initial_status: "Initial status",
  inline_error: "error occurred",
  inline_success: "successfully matched",
  input: "input",
  internal: "internal",
  internal_or_external: "internal or external",
  international: "international",
  invalidate: "invalidate",
  invalidate_file: "invalidate file",
  invalidate_shares: "invalidate shares",
  invalidated: "invalidated",
  invoice: "invoice",
  ipn: "IPN",
  is_junk: "is junk",
  is_not_junk: "is not junk",
  isrc: "ISRC",
  item: "item",
  items: "items",
  items_per_page: "items per page",
  junk: "junk",
  junk_history: "junk history",
  junk_status: "junk status",
  junked_usage: "junked usage",
  keep: "keep",
  landed: "landed",
  landing_recording: "landing recording",
  latest_review: "latest review",
  launch_rematch_process: "landed",
  lead_role: "team lead user role",
  level: "level",
  lineup_adjustments: "lineup adjustments",
  lineup_conflict: "line-up conflict",
  link_maker: "link maker",
  link_performer: "link performer",
  link_recording: "link recording",
  linked: "linked",
  linked_with_master: "linked with the master",
  lock_featured: "lock featured",
  lock_non_featured: "lock non-featured",
  log_format: "log format",
  main_details: "main details",
  main_performers: "main performers",
  make_comment: "make a comment",
  maker: "maker",
  maker_manual_matching: "maker manual matching",
  maker_matching_challenge: "maker matching challenge",
  maker_name: "maker name",
  maker_vpp: "Maker VPP",
  makers: "makers",
  male: "male",
  mandate: "mandate",
  mandate_conflict: "mandate conflict",
  mandate_conflict_pending: "mandate conflict pending",
  mandate_file: "mandate file",
  mandates: "mandates",
  manual: "manual",
  manual_matching: "manual matching",
  manual_submissions: "manual submissions",
  manually_created: "manually created",
  manually_linked: "manually linked",
  manually_matched: "manually matched",
  manually_matched_count: "manually matched count",
  mark_as: "mark as",
  mark_as_junk: "mark as junk",
  mark_as_not_junk: "mark as not junk",
  marked_as: "marked as",
  match: "Match",
  match_date: "match date",
  match_sr: "Match Recording",
  matched: "matched",
  matched_by: "matched by",
  matched_on: "matched on",
  matched_status: "matched status",
  matched_to: "matched to",
  matching_conflict_pending: "matching conflict pending",
  matching_conflicts: "matching conflicts",
  matching_conflicts_down_key: "Select next conflict",
  matching_conflicts_question_key: "Show this dialog",
  matching_conflicts_r_key: "Refresh data on the page",
  matching_conflicts_up_key: "Select previous conflict",
  matching_date: "matching date",
  matching_stats: "matching Stats",
  matching_stats_export: "matching statistics export",
  matching_type: "matching type",
  mc: "member collective",
  mc_source: "member collective source",
  member_collective: "member collective",
  member_collectives: "member collectives",
  member_name: "member name",
  merge: "merge",
  merge_makers: "merge makers",
  merge_performers: "merge performers",
  merge_recordings: "merge recordings",
  merge_request_created: "merge request created successfully",
  merge_requests: "merge requests",
  merged_makers: "merged makers",
  merged_performers: "merged performers",
  merged_recordings: "merged recordings",
  merged_srs: "merged recordings",
  modify_shares: "modify shares",
  more_performers_than_specified: "reconciling results in more performers in category than specified by the sources",
  more_resources: "more resources",
  multi_sorting_help_text: "Use Shift key to select multiple columns for sorting",
  music_master: "music master",
  music_video: "music video",
  must_be_a_decimal: "this field must be a decimal number",
  must_be_a_year: "the value must be a valid year",
  must_be_before_target: "this field must be after {target}",
  must_be_duration: "invalid duration (format mm:ss)",
  must_be_isrc: "Invalid ISRC (format CAK401400212)",
  must_be_less_than: "this field must be less than {max}",
  must_be_positive: "this field must be a positive number",
  my_matches: "my matches",
  my_mc: "My MC",
  name: "name",
  nationalities: "nationalities",
  nationality: "nationality",
  negative_adjustment_resolved: "negative adjustment resolved",
  negative_adjustment_suggested: "negative adjustment suggested",
  negative_adjustments: "negative adjustments",
  net: "net",
  new_data: "new data",
  new_tariff_period: "new tariff/period",
  no: "no",
  no_access: "no access",
  no_access_featured: "You have no permission to review this recording's featured lineup",
  no_access_non_featured: "You have no permission to review this recording's non-featured lineup",
  no_changes_were_made: "No changes were made",
  no_continue_editing: "No, continue editing",
  no_filter: "no filter",
  no_mandates: "no active mandates",
  no_performers_featured: "No featured Performers for this Recording yet",
  no_performers_non_featured: "No non-featured Performers for this Recording yet",
  no_permissions_ownership: "You have no permission to review this recording's ownership information",
  no_proxy: "No proxy",
  no_stations: "No stations for this Broadcaster yet",
  non_featured: "non-featured",
  none: "none",
  not_enough_number_of_performers: "not enough number of performers",
  not_finished: "not finished",
  not_junk: "not junk",
  not_started: "not started",
  notes: "notes",
  notification_center: "notification center",
  nr: "nr",
  number_of_performers: "# of perfs",
  of: "of",
  official: "official",
  old_data: "old data",
  on: "on",
  only_affected_rights_holders: "only affected rights holders",
  open_in_new_tab: "open in new tab",
  open_period: "open period",
  open_period_end_date: "open period end date",
  open_period_end_date_help_text: "open period end date should be set to 6 years after the end date of the tariff period",
  open_period_start_date: "open period start date",
  open_period_start_date_help_text: "open period start date should be set to the 1st day of the month following the end date of the tariff period",
  original_artist: "original artist",
  original_title: "original title",
  other: "other",
  owners: "owners",
  ownership: "ownership",
  ownership_adjustments: "ownership adjustments",
  ownership_conflicts: "ownership conflicts",
  page: "page",
  parameters: "parameters",
  parent_recording: "parent recording",
  parties: "parties",
  payee: "payee",
  payee_type: "payee type",
  payment_due: "payment due",
  payment_hold: "payment hold",
  payment_status: "payment status",
  payment_uploads: "payment uploads",
  payments: "payments",
  pc_maker: "PC Maker",
  pc_performer: "PC Performer",
  pending: "pending",
  pending_for_validation: "pending for validation",
  pending_matches: "Pending matches",
  pending_user_actions: "pending user actions",
  performer: "performer",
  performer_id: "performer id",
  performer_in_both_categories: "same performer participates in both categories in sources",
  performer_lineups: "performer lineups",
  performer_manual_matching: "performer manual matching",
  performer_matching_challenge: "performer matching challenge",
  performer_name: "performer name",
  performer_vpp: "Performer VPP",
  performers: "performers",
  performers_comparison: "performers comparison",
  performers_linked: "performers linked successfully",
  period: "period",
  period_description: "period descr.",
  period_end_date: "period end date",
  period_end_date_help_text: "end date should be later than the value entered for the period start date",
  period_start_date: "period start date",
  period_start_date_help_text: "date should be later than the previous tariff period's end date for the same tariff and should not overlap with the start and end date range of any other tariff periods for the same tariff",
  placeholder: "placeholder",
  playcount: "playcount",
  please_pick_a_parent_maker: "please pick a parent maker",
  please_pick_a_parent_performer: "please pick a parent performer",
  please_pick_a_parent_recording: "please pick a parent recording",
  previous: "previous",
  primary_eligibility_scheme: "primary eligibility scheme",
  process_already_running: "process is already running",
  processing: "processing",
  processing_may_take_some_time: "processing may take some time",
  promoted: "promoted",
  proprietary_id: "Proprietary ID",
  proprietary_ids: "Proprietary IDs",
  province: "province",
  province_station_count: "province station count",
  provincial_population: "provincial population",
  proxy_period_end_date: "proxy period end date",
  proxy_period_start_date: "proxy period start date",
  proxy_tariff: "proxy tariff",
  raised_by: "raised by",
  raised_by_member_collective: "raised_by_member_collective",
  range_filter_empty_bound_description: "*leave empty to exclude",
  rank: "rank",
  raw: "raw",
  ready: "ready",
  ready_for_sync: "ready for synchronization",
  real: "real",
  real_action: "real.",
  real_refunded_for_sync: "recording is marked as refunded for sync",
  real_released_for_sync: "recording is marked as released for sync",
  real_sync_status: "sync.",
  reason: "reason",
  rec: "rec",
  reconciled_lineup: "reconciled lineup",
  reconciliation_error: "reconciliation error",
  reconciliation_pending: "reconciliation pending",
  recording: "recording",
  recording_albums: "recording albums",
  recording_artist: "recording artist",
  recording_challenges_actions: "recording challenges",
  recording_eligibility_conflict_resolved: "recording eligibility conflict resolved",
  recording_id: "Recording ID",
  recording_isrc: "Recording ISRC",
  recording_matching_challenge: "recording matching challenge",
  recording_matching_stats: "Rec. Match. Stats",
  recording_matching_status: "recording matching status",
  recording_title: "recording title",
  recording_type: "recording type",
  recordings: "recordings",
  recordings_count: "recordings count",
  recordings_in_other_merge_requests: "highlighted recordings are already part of other merge request(s)",
  recordings_linked: "recordings merged successfully",
  recordings_merge_request_rejected: "merge rejected successfully",
  recordings_reconciliation_started: "recordings update has been started.",
  refund_payment: "refund payment",
  refund_undistributed: "refund undistributed",
  reject_merge: "reject merge",
  release_payment: "release payment",
  release_year: "release year",
  released: "released",
  releases: "releases",
  rematch_usages: "rematch usages",
  rematching_launched: "rematch launched",
  rep_track: "RepTrack",
  repertoire: "repertoire",
  repertoire_actions: "repertoire actions",
  repertoire_errors: "repertoire errors",
  repertoire_file: "repertoire file",
  repertoire_file_invalidated: "repertoire file invalidated successfully",
  repertoire_matching: "repertoire matching",
  reporting: "reporting",
  representative: "representative",
  requested_on: "requested on",
  reserve: "reserve",
  reset: "reset",
  reset_changes: "reset last action",
  reset_to_default: "reset to default",
  resolve: "resolve",
  resolved: "resolved",
  resolved_matches: "Resolved matches",
  resolved_on: "resolved on",
  resound_id: "Resound ID",
  result: "result",
  revenue: "revenue",
  revenue_currency: "revenue currency",
  revenue_help_text: "enter the collected revenue as a currency value",
  review_date: "review date",
  review_history: "review history",
  reviewed: "reviewed",
  reviewed_by: "Reviewed by",
  reviewed_count: "reviewed count",
  reviewer: "reviewer",
  rh_id: "rh. id",
  rh_name: "RH. name",
  rig: "rig",
  rights_holder: "rightsholder",
  rights_holder_type: "rightsholder type",
  rights_holders: "Rights Holders",
  rightsholder: "rightsholder",
  rightsholder_challenges: "rightsholder challenges",
  rightsholder_id: "rightsholder id",
  rightsholder_matching_stats: "Righ. Match. Stats",
  rightsholder_matching_status: "rightsholder matching status",
  rightsholder_name: "rightsholder name",
  rightsholder_type: "rightsholder type",
  rightsholders: "rightsholders",
  rightsholders_actions: "rightsholders actions",
  rightsholders_linked: "rightsholders linked successfully",
  role: "role",
  royalties: "royalties",
  royalties_paid: "royalties paid",
  royalty_details: "royalty details",
  salesforce_id: "Salesforce ID",
  same_type: "same type",
  save: "save",
  save_back: "save & back",
  saved_as_draft: "saved as draft",
  saved_changes: "changes were successfully saved",
  saved_changes_solved: "changes were successfully saved and the conflict was solved",
  search: "search",
  search_agent: "search agent",
  search_all_recordings: "search all recordings",
  search_for_a_maker: "search for a maker",
  search_maker: "search maker",
  search_other_candidates: "search other candidates",
  search_performer: "search performer",
  search_recording: "search recording",
  search_rightsholder: "search rightsholder",
  search_suggested_match_id: "Search Suggested Re:Sound ID",
  search_user: "search user",
  searched_recordings: "searched recordings",
  secondary_eligibility_scheme: "secondary eligibility scheme",
  see_sr_history: "See SR's whole history",
  select_all: "select all",
  select_all_page: "select all in this page",
  select_countries: "select countries",
  select_country_of_fixation: "select country of fixation",
  select_date_range: "select date range",
  select_first_maker_nationalities: "select first maker nationalities",
  select_first_makers: "select first makers",
  select_mc: "select mc",
  select_member_collectives: "select member collectives",
  select_source_metadata: "select source metadata",
  select_stations: "select stations",
  select_status: "select status",
  select_tariffs: "select tariffs",
  selector: "selector",
  sent_for_sync: "Data was sent for sync",
  sf: "sf",
  share: "share",
  shared_repertoire_area: "shared repertoire area",
  shares_invalidated: "shares invalidated successfully",
  shares_modified: "shares modified successfully",
  show_all_types: "show all types",
  show_available_unmatch: "show available for unmatch only",
  show_by_file: "show by file",
  show_done: "show done",
  show_from_secondary_sources: "show from secondary sources",
  show_from_secondary_sources_help: "show performer conflicts from non-performer sources and maker conflicts from non-maker sources",
  show_history: "Show history",
  show_landing_data: "Show landing data",
  show_only_source_metadata_in_conflict: "show only source metadata in conflict",
  show_parent_data: "show parent recording data",
  show_rep_tracks: "Show RepTracks",
  show_resolved: "show resolved",
  show_same_type: "same type recordings",
  show_source_metadata: "show source metadata",
  show_system_challenges: "show system challenges",
  showing: "showing",
  simple_repertoire_export: "export filtered recordings",
  sister_society: "sister society",
  solve: "solve",
  sound_exchange: "sound exchange",
  sound_recording: "sound recording",
  source: "source",
  source_file: "source file",
  source_isrc: "Source ISRC",
  source_metadata: "source metadata",
  source_name: "source name",
  source_proprietary_id: "Source Proprietary ID",
  sources: "sources",
  split: "split %",
  sr_history: "SR history",
  sra: "sra",
  standard: "standard",
  standard_usage_based: "standard usage based",
  start_date: "start date",
  statement_period: "stat. period",
  station: "station",
  station_name: "station name",
  stations: "stations",
  status: "status",
  success: "success",
  suggest_effective_date: "suggest effective date",
  suggested_effective_date: "suggested effective date",
  suggested_match_id: "Suggested Re:Sound ID",
  suggested_recordings: "suggested recordings",
  swap: "swap",
  sync_payments: "sync payments",
  system_users: "system users",
  tariff: "tariff",
  tariff_code: "tariff code",
  tariff_period_created: "new tariff/period has been created",
  tariff_period_creation_cancelled: "Tariff period creation process has been canceled. No Tariff period was created",
  tariff_periods: "tariff periods",
  tariffs: "tariffs",
  technical_documentation: "technical documentation",
  territories: "territories",
  territory: "territory",
  the_information_is_too_different_merge: "The information is too different. Please check this box to force the merge.",
  the_information_is_too_different_merge_request: "The information is too different. Please check this box to force the merge request.",
  this_field_is_required: "this field is required",
  this_field_must_be_a_valid_email: "this field must be a valid email",
  this_fields_confirmation_does_not_match: "this fields confirmation does not match",
  this_maker_is_not_in_the_candidates_list: "this maker is not in the candidates list",
  this_performer_is_not_in_the_candidates_list: "this performer is not in the candidates list",
  this_recording_is_not_in_the_candidates_list: "this recording is not in the candidates list",
  threshold: "threshold",
  threshold_reached: "threshold reached",
  title: "title",
  today: "today",
  total: "total",
  total_unique: "total unique",
  type: "type",
  type_of_right: "type of right",
  unclaimed_for: "unclaimed for",
  undo_create: "undo create",
  undo_match: "undo match",
  undone_error: "Couldn't undo action",
  undone_success: "Action undone successfully",
  unique_count: "unique count",
  unknown: "unknown",
  unlink_create_rightsholder: "Unlink and create a new rightsholder",
  unlink_create_sr: "Unlink and create a new SR",
  unlinked: "unlinked",
  unmatch_usage: "Unmatch this usage",
  unmatched: "unmatched",
  unmatched_count: "unmatched count",
  unrepresented: "unrepresented",
  update_albums: "update albums",
  update_first_makers: "update first makers",
  upload_by: "upload by",
  upload_date: "upload date",
  upload_user: "upload user",
  uploaded_by: "uploaded by",
  uploaded_on: "uploaded on",
  uploader: "uploader",
  uploads: "uploads",
  usage: "usage",
  usage_adjustment: "usage adjustment",
  usage_adjustments: "usage adjustments",
  usage_challenge: "usage challenge",
  usage_challenge_actions: "usage challenges",
  usage_challenge_resolved: "usage challenge resolved",
  usage_challenge_unmatched: "usage challenge unmatched",
  usage_date_range: "usage date range",
  usage_files: "usage files",
  usage_id: "Usage ID",
  usage_info: "usage information",
  usage_matching: "usage matching",
  usage_period: "usage period",
  usage_year: "usage year",
  usages: "usages",
  usages_reviewed: "usages reviewed",
  usages_updated: "usages updated",
  use: "use",
  use_current_matched_recording: "use current matched recording",
  use_proxy_tariff: "use proxy tariff",
  use_suggested_recording: "use suggested recording",
  user: "user",
  user_action_has_been_solved: "the user action you were reviewing has been solved by another user or process",
  user_action_solved: "user action solved",
  user_actions: "user actions",
  user_id: "user id",
  user_management: "user management",
  user_name: "user name",
  user_role: "user role",
  user_roles: "user roles",
  users: "users",
  view_qa: "View QA",
  view_reprod_usages: "view reprod. usages",
  vpp: "VPP",
  vpp_type: "VPP type",
  waiting_for_sync: "waiting for synchronization",
  we_will_send_you_an_email_with_a_link: "we’ll send you an email with a link to download the report when the process is completed",
  weighted_playcount: "weighted playcount",
  weighted_playcount_short: "w. playcount",
  when: "when",
  with_candidates: "with candidates",
  with_comment: "with comment",
  with_financial_interest: "financial interest",
  without_candidates: "without candidates",
  word: "word",
  works_database: "works database",
  worldwide: "worldwide",
  worldwide_except: "worldwide except",
  wppt: "wppt",
  wrong_filters: "Provided filter values are not valid. Check search query syntax or contact administrator.",
  wrong_uuids_specified: "wrong uuids specified",
  wrote: "wrote",
  ww: "WW",
  ww_except: "WW except",
  year: "year",
  year_of_fixation: "year of fixation",
  year_of_release: "year of release",
  yes: "yes",
  yes_cancel_editing: "yes, cancel editing",
  yof: "yof",
  yor: "yor",
}
