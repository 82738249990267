<template>
  <div>
    <b-table
    striped
    fixed
    :items="data"
    :fields="sortColumns(fields)"
    :thead-class="showHeader ? '' : 'd-none'"
    >
      <template v-slot:head(hasUsages)="data">
        <span :title="data.label" class="text-truncate d-block">{{ data.label }}</span>
      </template>
      <template v-slot:head(duration)="data">
        <span :title="data.label" class="text-truncate d-block">{{ data.label }}</span>
      </template>
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :class="field.tdClass"
        />
      </template>
      <!-- Fields with alternative values -->
      <template v-slot:cell(isrc)="row">
        {{ row.item.isrc }}
        <p class="small" v-if="row.item.alternativeIsrcs && row.item.alternativeIsrcs.length">
          <em>{{ row.item.alternativeIsrcs.join(", ") }}</em>
        </p>
      </template>

      <template v-slot:cell(firstMakers)="row">
        <div v-for="firstMaker in row.item.firstMakers" :key="firstMaker.name">
          {{ firstMaker.name ? firstMaker.name : firstMaker }}
        </div>
      </template>

      <template v-slot:cell(firstMakersNationalities)="row">
        <template v-if="row.item.firstMakers && row.item.firstMakers.length">
          <div v-for="firstMaker in row.item.firstMakers" :key="firstMaker.name">
            {{ parseFirstMakerNationalities(firstMaker.nationalities || []) }}
          </div>
        </template>
        <template v-else>
          <div v-for="(nationalities, index) in row.item.firstMakersNationalities" :key="index">
            {{ parseFirstMakerNationalities(nationalities || []) }}
          </div>
        </template>
      </template>

      <template v-slot:cell(title)="row">
        {{ row.item.title }}
        <p class="small" v-if="row.item.alternativeTitles && row.item.alternativeTitles.length">
          <em>{{ row.item.alternativeTitles.join(", ") }}</em>
        </p>
      </template>

      <template v-slot:cell(artist)="row">
        {{ row.item.artist }}
        <p class="small" v-if="row.item.alternativeArtists && row.item.alternativeArtists.length">
          <em>{{ row.item.alternativeArtists.join(", ") }}</em>
        </p>
      </template>

      <template v-slot:cell(duration)="data">
        {{ data.value ? $options.filters.toDuration(data.value) : "" }}
      </template>

      <!-- Album names -->
      <template v-slot:cell(releases)="row">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="row.item.releases"
        />
      </template>

      <template v-slot:cell(sources)="row">
        <sources :sources="getSources(row)" :source-type="getSourceType(row)"/>
      </template>

      <!-- Resound Id -->
      <template v-slot:cell(resoundId)="row">
        <div class="d-flex">
          <b-checkbox
            :value="row.item.id"
            @change="rowChecked(row.item.id)"
            v-model="selectedRows"
            v-if="isRecordingAction"
          />
          <database-link :item-id="row.item.id" :type="isRecordingAction ? linkTypes.RECORDING : linkTypes.RIGHTS_HOLDER">
            {{ row.item.resoundId }}
          </database-link>
        </div>
      </template>

       <!-- Composers -->
      <template v-slot:cell(composers)="row">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="row.item.composers ? row.item.composers : []"
        />
      </template>

      <!-- Performers -->
      <template v-slot:cell(performers)="row">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="row.item.performers ? row.item.performers : []"
        />
      </template>

      <!-- Owners -->
      <template v-slot:cell(owners)="row">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="row.item.owners ? row.item.owners : []"
        />
        <b-tooltip v-if="row.item.owners && row.item.owners.length === 1"
          :target="'owners_' + row.item.id"
          triggers="hover"
        >
          {{ row.item.owners && row.item.owners.length === 1 ? row.item.owners[0] : "" }}
        </b-tooltip>
      </template>

      <template v-slot:cell(hasUsages)="row">
        <div v-if="typeof row.item.hasUsages !== 'undefined'">
          <router-link
            target="_blank"
            :to="{
              name: 'consolidated-usage-list',
              query: {
                recording: row.item.id,
                year: null
              },
            }"
            v-if="row.item.hasUsages"
            class="text-info"
          >
            {{ $t("yes") | capitalize }}
          </router-link>
          <span v-else>
            {{ $t("no") | capitalize }}
          </span>
        </div>
      </template>

      <!-- Recordings count -->
      <template v-slot:cell(recordingsCount)="row">
        {{ row.item.recordingsCount }}
        <fa-icon
            @click="showModal(row.item.id)"
            v-if="showRecordings && row.item.recordingsCount > 0"
            class="blue clickable ml-2 more-values-icon"
            size="sm"
            :icon="['fas', 'plus-circle']"
        />
        <b-modal
          v-if="showRecordings"
          :id="`recordingsModal-${row.item.id}`"
          modal-class="custom-modal info"
          size="xl"
          centered
          hide-footer>
          <template #modal-title>
            <h4 class="d-flex align-items-center mb-0">
              <fa-icon :icon="['fas', 'exclamation-circle']" class="icon-blue mr-2"/>
              {{ $t('shared_repertoire_area') | startcase }}
            </h4>
          </template>
          <popup-recordings-list :rightsHolderUUID="row.item.id"/>
          <b-row class="modal-footer float-right mt-4">
            <b-button class="btn-outline-red" @click="$bvModal.hide(`recordingsModal-${row.item.id}`)">
              {{ $t("cancel") | capitalize }}
            </b-button>
          </b-row>
        </b-modal>
      </template>

      <!-- Country -->
      <template v-slot:cell(fixationCountry)="data">
        {{ data.value.alpha2 ? data.value.alpha2 : data.value }}
      </template>

      <!-- Nationalities -->
      <template v-slot:cell(nationalities)="row">
        {{ row.item.nationalities.map(n => isObject(n) ? n.alpha2 : n).join(", ") }}
      </template>

      <!-- First Maker Nationality -->
      <template v-slot:cell(firstMakerNationalities)="row">
          <span>
            {{
              row.item.firstMakerNationalities && row.item.firstMakerNationalities.length ? row.item.firstMakerNationalities.join(", ") : ""
            }}
          </span>
      </template>

      <!-- Aliases -->
      <template v-slot:cell(aliases)="row">
        {{ row.item.aliases.join(", ") }}
      </template>

      <!-- Collectives -->
      <template v-slot:cell(memberCollectives)="row">
        {{ row.item.memberCollectives && row.item.memberCollectives.length > 0 ? row.item.memberCollectives.map(mc => mc.name).join(", ") : capitalize($t('unrepresented')) }}
      </template>

      <!-- Date Of Birth -->
      <template v-slot:cell(dateOfBirth)="row">
        {{ row.item.dateOfBirth ? parseDate(row.item.dateOfBirth, $config.DATE_FORMAT) : "" }}
      </template>

      <!-- Is ROME Eligible-->
      <template v-slot:cell(isRomeEligible)="row">
        <boolean-status :is-true="row.item.eligibility.isRomeEligible"/>
      </template>

      <!-- Is WPPT Eligible-->
      <template v-slot:cell(isWpptEligible)="row">
        <boolean-status :is-true="row.item.eligibility.isWpptEligible"/>
      </template>

      <!-- Is ROME + WPPT Eligible-->
      <template v-slot:cell(isRomeWpptEligible)="row">
        <boolean-status :is-true="row.item.eligibility.isRomeWpptEligible"/>
      </template>

      <!-- Is CUSMA Eligible-->
      <template v-slot:cell(isCusmaEligible)="row">
        <boolean-status :is-true="row.item.eligibility.isCusmaEligible"/>
      </template>

      <!-- Is PC Maker Eligible-->
      <template v-slot:cell(isPcMakerEligible)="row">
        <boolean-status :is-true="row.item.eligibility.isPcMakerEligible"/>
      </template>

      <!-- Is PC Performer Eligible-->
      <template v-slot:cell(isPcPerformerEligible)="row">
        <boolean-status :is-true="row.item.eligibility.isPcPerformerEligible"/>
      </template>

      <template
        v-if="action && permissions.actionsTabs[actionsTabs.MATCHING_CONFLICTS].canWrite"
        v-slot:cell(actions)="row"
      >
        <div class="text-right">
          <b-button v-on:click="onClick(row.item.id)" :disabled="waiting" variant="outline-secondary">
            <template v-if="!waiting">{{ buttonCaption }}</template>
            <template v-else><b-spinner variant="light" small></b-spinner></template>
          </b-button>
        </div>
      </template>
      <template v-slot:table-caption>{{ caption | capitalize }}</template>
    </b-table>
  </div>
</template>
<script>
import { actionsTabs, linkTypes, sourceTypes } from "@/constants"
import { isArray, isObject, isString } from "lodash"
import BooleanStatus from "@/components/BooleanStatus"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import PopupRecordingsList from "@/pages/Repertoire/Recordings/PopupRecordingsList.vue"
import Sources from "@/components/Sources.vue"
import { capitalize } from "lodash"
import { mapState } from "vuex"
import parseDate from "@/utils/date-parser"
import { sortColumns } from "@/pages/UserActions/utils"

export default {
  name: "MetadataConflictTable",
  components: {
    PopupRecordingsList,
    DatabaseLink,
    BooleanStatus,
    NamesListModal,
    Sources,
  },
  methods: {
    sortColumns,
    parseDate,
    isObject,
    isArray,
    capitalize,
    showModal (rightsholder_uuid) {
      this.$bvModal.show(`recordingsModal-${rightsholder_uuid}`)
    },
    onClick (uuid) {
      this.method(uuid)
    },
    parseFirstMakerNationalities (nationalities) {
      if (nationalities && nationalities.length && isString(nationalities)) {
        return nationalities
      }

      if (nationalities && nationalities.length && isArray(nationalities)) {
        return nationalities.join(", ")
      }

      return "-"
    },
    getSources (row) {
      if (row.item.repertoireFiles) {
        return row.item.repertoireFiles
      } else {
        return row.item.sources.files.length ? row.item.sources.files : row.item.sources.inputSources
      }
    },
    getSourceType (row) {
      if (row.item.repertoireFiles || row.item.sources.files.length) {
        return sourceTypes.REPERTOIRE_FILE
      } else {
        return sourceTypes.RIGHTSHOLDER_BATCH
      }
    },
    rowChecked (rowId) {
      if (this.selectedRows.includes(rowId)) {
        this.selectedRows.splice(this.selectedRows.indexOf(rowId), 1)
      } else {
        this.selectedRows.push(rowId)
      }
    },
  },
  props: {
    data: Array,
    fields: Array,
    caption: String,
    action: Boolean,
    method: Function,
    buttonCaption: String,
    isRecordingAction: Boolean,
    showRecordings: Boolean,
    showHeader: {
      type: Boolean,
      default: true
    },
    selectedRows: Array
  },
  computed: {
    ...mapState("user", ["permissions", "waiting"]),
  },
  data () {
    return {
      actionsTabs,
      linkTypes,
    }
  }
}
</script>
