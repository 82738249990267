<template>
  <b-card
      class="mb-2 format-button"
      :class="{ 'selected': selectedFormat === exportFormat, 'disabled': disabled, 'clickable': !disabled }"
      align="center"
      >
        <b-card-body class="text-center">
        <b-container class="image-container">
          <b-img :src="require('@/assets/' + formatDesc.img)"/>
        </b-container>
        <b-card-title>
          <slot name="title">{{ formatDesc.title }}</slot>
        </b-card-title>
        <b-card-text>
          <slot name="text">{{ formatDesc.desc }}</slot>
        </b-card-text>
        </b-card-body>
  </b-card>
</template>

<script>
import { exportFormats } from "@/constants"
import { upperCase } from "lodash"

export default {
  name: "ExportFormatCard",
  props: {
    exportFormat: {
      required: true,
      validator: function (value) {
        return Object.values(exportFormats).includes(value)
      }
    },
    selectedFormat: {
      required: false,
      type: String,
      default: null
    },
    disabled: {
      default: false,
      type: Boolean
    },
  },
  computed: {
    formatDesc () {
      if (this.exportFormat === exportFormats.EXCEL) {
        return { title: "Microsoft Excel", desc: "Excel files have a limit of 200,000 rows", img: "excel.png" }
      } else if (this.exportFormat === exportFormats.CSV) {
        return { title: "CSV", desc: upperCase(this.$t("csv"))  + " " + this.$t("files_exceed_the_rows_limitation"), img: "csv.png" }
      } else {
        return { title: "DDEX", desc: "DDEX Standard Format", img: "ddex.png" }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.format-button {
  height: 100%;

  &.selected {
    background-color: #FFFFFF !important;
  }
  
  &.disabled {
    opacity: 0.6;
  }

  &.card {
    border: 1px solid $gray-light;
    border-radius: 4px;
    background-color: $modal-gray;
  }

  .card-body {
    height: 100%;
  }

  .card-title {
    font-weight: bold;
    margin-top: 10px;
  }
}

.image-container {
  min-height: 50%;
}
</style>
