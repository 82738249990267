<template>
  <div>
    <b-row class="heading-container">
      <go-back />
      <h4 v-if="rightsholderData" class="ml-2">
        {{ rightsholderData.resoundId }} -
        {{ rightsholderData.name }}
      </h4>
    </b-row>
    <b-card class="rightsholder-details">
      <rightsholder-table
          v-if="rightsholderData"
          :data="[rightsholderData]"
          :fields="primaryFields"
          class-name="general-details">
      </rightsholder-table>
      <rightsholder-table
        className="general-details"
        v-if="rightsholderData && rightsholderData.type === rightsholderTypes.PERFORMER"
        :data="[rightsholderData]"
        :fields="secondaryFields"
      >
      </rightsholder-table>
    </b-card>
    <tabs-layout @change="onTabChange" :tabs="this.sections" v-model="tabIndex">
      <template v-slot:tabTitle="{ tab }">{{
        tab.tabTitle | startcase
      }}</template>

      <template v-slot:static-content> </template>

      <template v-slot:content="{ tab }">
        <component
          :rightsholderId="rightsholderId"
          :data="tab.data"
          :canEdit="tab.canEdit"
          class="tab-content"
          v-bind:is="tab.component"
        ></component>
      </template>
    </tabs-layout>
  </div>
</template>

<script>
import { actionsTabs, rightsholderDetailTabs, rightsholderTypes } from "@/constants"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { startCase, values } from "lodash"
import GeneralDetails from "./GeneralDetails"
import MergedRightsholders from "./MergedRightsholders"
import PaymentsHistory from "@/pages/Payment/PaymentsHistory"
import RightsHolderFieldsMixin from "@/pages/Rightsholders/RightsHolderDetail/RightsHolderFieldsMixin"
import RightsHolderMandates from "./RightsHolderMandates"
import RightsHolderRepertoire from "./RightsHolderRepertoire"
import RightsholderTable from "@/pages/Rightsholders/RightsHolderDetail/RightsholderTable"

export default {
  name: "RightsholderDetail",
  components: {
    GeneralDetails,
    RightsholderTable,
    RightsHolderRepertoire,
    MergedRightsholders
  },
  props: {
    rightsholderId: String,
    type: String,
  },
  mixins: [RightsHolderFieldsMixin],
  data () {
    return {
      tabIndex: 0,
      tabs: values(rightsholderDetailTabs),
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", "getTariffs"]),
    ...mapActions("rightsholders", ["getRightsholderData"]),
    ...mapMutations("rightsholders", ["setRightsholderData"]),
    onTabChange ({ index }) {
      const clearParams = this.tabIndex !== index
      this.tabIndex = index
      this.$router.replace(this.getRoute(index, clearParams)).catch(() => {})
    },
    getMergeTabTitle () {
      if (this.rightsholderData) {
        return this.rightsholderData.type === "maker"
          ? this.$t("merged_makers")
          : this.$t("merged_performers")
      }
    },
    getRoute (index, clearParams) {
      const query = { component: this.component }
      return {
        name: "rightsholder-detail",
        params: { type: this.sections[index].type },
        query: clearParams ? query : { ...query, ...this.$route.query },
      }
    },
    geType () {
      if (this.rightsholderData !== null) {
        return this.rightsholderData.type
      } else {
        this.getRightsholderData(this.rightsholderId)
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isInternal"]),
    ...mapState("rightsholders", ["rightsholderData"]),
    ...mapState("user", ["permissions"]),
    sections () {
      return [
        {
          tabTitle: this.$t("general_details"),
          component: GeneralDetails,
          type: rightsholderDetailTabs.GENERAL_DETAILS,
          data: {
            fields: this.sourceFields,
            rightsholderType: this.geType()
          },
        },
        {
          tabTitle: this.$t("mandates"),
          component: RightsHolderMandates,
          type: rightsholderDetailTabs.MANDATES,
          data: {
            type: this.geType(),
            isRightsHolderDetail: true
          },
          canEdit: this.permissions.actionsTabs[actionsTabs.MANDATES].canWrite
        },
        {
          tabTitle: this.$t("payments"),
          component: PaymentsHistory,
          type: rightsholderDetailTabs.PAYMENTS,
        },
        {
          tabTitle: this.$t("repertoire"),
          component: RightsHolderRepertoire,
          type: rightsholderDetailTabs.REPERTOIRE,
        },
        {
          tabTitle: this.getMergeTabTitle(),
          component: MergedRightsholders,
          type: rightsholderDetailTabs.MERGED_RIGHTSHOLDERS,
          data: {
            rightsHolderData: this.rightsholderData,
            header: this.getMergeTabTitle()
          },
          visible: this.hasMerges,
        },
      ]
    },
    hasMerges () {
      if (this.rightsholderData) {
        return this.rightsholderData.mergedChildren.length > 0
      }
      else {
        return false 
      }
    },
    sourceFields () {
      const rightsholderFields = this.data && this.data.type == rightsholderTypes.MAKER ? this.primaryFields : this.fields
      let sourceFields = rightsholderFields.filter(field => field.source)
      sourceFields.unshift({
        key: "proprietaryId",
        label: startCase(this.$t("proprietary_id")),
        width: "15%",
        source: true,
      }, {
        key: "memberCollectives",
        tdClass: "blue",
        label: startCase(this.$t("mc")),
        width: "10%",
      })
      sourceFields.push({
        key: "sources",
        label: "",
        width: "5%",
      }, {
        key: "actions",
        label: "",
        width: "10%",
      })
      return sourceFields.map(field => {return { ...field }})
    }
  },
  destroyed () {
    this.setRightsholderData(null)
  },
  mounted () {
    this.getRightsholderData(this.rightsholderId)
    this.getCountries()
    this.getTariffs()

    let defaultRoute = {
      name: "rightsholder-detail",
      params: { type: rightsholderDetailTabs.GENERAL_DETAILS },
    }

    if (!this.tabs.includes(this.type)) {
      this.$router.replace(defaultRoute)
    } else {
      this.tabIndex = this.sections.findIndex(
        (section) => section.type === this.type
      )
    }
  },
}
</script>
<style lang="scss" scoped>

.bordered-row {
  border: 1px solid $gray;
  border-bottom: none;
}

.rightsholder-details {
  margin-top: 40px;
  border: none;
  background: #fafafa;

  .general-details {
    margin-bottom: 50px;
  }

  ::v-deep table {
    thead {
      background: transparent;

      th {
        font-weight: bold;
        padding: 0;
        font-size: 1rem;
      }
    }

    tbody {
      td {
        padding: 0;
      }
    }
  }
}
</style>
