<template>
  <validation-observer ref="observer" tag="fieldset">
    <b-card-body>
      <b-row>
        <b-col>
          <distribution-form-group
            :label="$t('description') | capitalize"
            :description="$t('enter_name_or_description') | capitalize"
            label-cols="2"
            rules="required"
            vid="description"
            ref="description"
            v-slot="{ state }"
          >
            <b-form-input required v-model="distribution.description" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="tariff"
            ref="tariff"
            :label="$t('tariff') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
          >
            <b-form-select required v-model="distribution.tariff" :options="tariffOptions" :state="state"></b-form-select>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="periodStart"
            ref="periodStart"
            :label="$t('period_start_date') | capitalize"
            :description="$t('period_start_date_help_text') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <date-picker
              :input-attr="{required: true}"
              type="date"
              v-model="distribution.periodStart"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="periodEnd"
            ref="periodEnd"
            :label="$t('period_end_date') | capitalize"
            :description="$t('period_end_date_help_text') | capitalize"
            label-cols="2"
            :rules="`required|after:@periodStart,${$config.DATE_FORMAT}`"
            v-slot="{ state }"
            >
            <date-picker
              :input-attr="{required: true}"
              type="date"
              v-model="distribution.periodEnd"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="useProxyTariff"
            ref="useProxyTariff"
            :label="$t('use_proxy_tariff') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <b-form-select v-model="distribution.useProxyTariff" required :state="state">
              <b-form-select-option :value="false">{{ $t("no") | capitalize }}</b-form-select-option>
              <b-form-select-option :value="true">{{ $t("yes") | capitalize }}</b-form-select-option>
            </b-form-select>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.useProxyTariff">
        <b-col>
          <distribution-form-group
            vid="proxyTariff"
            ref="proxyTariff"
            :label="$t('proxy_tariff') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
          >
            <b-form-select required v-model="distribution.proxyTariff" :options="proxyTariffOptions" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.useProxyTariff">
        <b-col>
          <distribution-form-group
            vid="proxyPeriodStart"
            ref="proxyPeriodStart"
            :label="$t('proxy_period_start_date') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <date-picker
              :input-attr="{required: true}"
              type="date"
              v-model="distribution.proxyPeriodStart"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.useProxyTariff">
        <b-col>
          <distribution-form-group
            vid="proxyPeriodEnd"
            ref="proxyPeriodEnd"
            :label="$t('proxy_period_end_date') | capitalize"
            label-cols="2"
            :rules="`required|after:@proxyPeriodStart,${$config.DATE_FORMAT}`"
            v-slot="{ state }"
            >
            <date-picker
              type="date"
              :input-attr="{required: true}"
              v-model="distribution.proxyPeriodEnd"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="country"
            ref="country"
            :label="$t('country') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <b-form-select required v-model="distribution.country" :options="countryOptions" disabled :state="state">

            </b-form-select>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="college"
            ref="college"
            :label="$t('college') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <b-form-select required v-model="distribution.college" :options="collegeOptions" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="revenueCurrency"
            ref="revenueCurrency"
            :label="$t('revenue_currency') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <b-form-select required v-model="distribution.revenueCurrency" :options="currencyOptions" disabled :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.college === distributionCollegeTypes.BOTH || distribution.college === distributionCollegeTypes.PERFORMER">
        <b-col>
          <distribution-form-group
            vid="revenuePerformer"
            ref="revenuePerformer"
            :label="`${$t('revenue')} (${$t('performer')})` | capitalize"
            :description="$t('revenue_help_text') | capitalize"
            label-cols="2"
            rules="double|positive"
            v-slot="{ state }"
          >
            <b-form-input number v-model="distribution.revenuePerformer" :state="state"></b-form-input>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.college === distributionCollegeTypes.BOTH || distribution.college === distributionCollegeTypes.MAKER">
        <b-col>
          <distribution-form-group
            vid="revenueMaker"
            ref="revenueMaker"
            :label="`${$t('revenue')} (${$t('maker')})` | capitalize"
            :description="$t('revenue_help_text') | capitalize"
            label-cols="2"
            rules="double|positive"
            v-slot="{ state }"
          >
            <b-form-input number v-model="distribution.revenueMaker" :state="state"></b-form-input>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.college === distributionCollegeTypes.BOTH || distribution.college === distributionCollegeTypes.PERFORMER">
        <b-col>
          <distribution-form-group
            vid="revenueReservePerformer"
            ref="revenueReservePerformer"
            :label="`${$t('reserve')} (${$t('performer')})` | capitalize"
            label-cols="2"
            :rules="'double|positive' + (distribution.reserveValueTypePerformer === distributionReserveValueTypes.PERCENT ? '|max_value:100' : '')"
            :detect-input="false"
            v-slot="{ state, validate }"
            @hook:mounted="validate(distribution.revenueReservePerformer)"
          >
            <b-input-group>
              <template v-slot:prepend>
                <b-form-select :options="reserveTypeOptions" v-model="distribution.reserveValueTypePerformer" @change="validate(distribution.revenueReservePerformer)"/>
              </template>
              <b-form-input number v-model="distribution.revenueReservePerformer" :state="state" @input="validate"></b-form-input>
            </b-input-group>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.college === distributionCollegeTypes.BOTH || distribution.college === distributionCollegeTypes.MAKER">
        <b-col>
          <distribution-form-group
            vid="revenueReserveMaker"
            ref="revenueReserveMaker"
            :label="`${$t('reserve')} (${$t('maker')})` | capitalize"
            label-cols="2"
            :rules="'double|positive' + (distribution.reserveValueTypeMaker === distributionReserveValueTypes.PERCENT ? '|max_value:100' : '')"
            :detect-input="false"
            v-slot="{ state, validate }"
            @hook:mounted="validate(distribution.revenueReserveMaker)"
          >
            <b-input-group>
              <template v-slot:prepend>
                <b-form-select :options="reserveTypeOptions" v-model="distribution.reserveValueTypeMaker" @change="validate(distribution.reserveMaker)"/>
              </template>
              <b-form-input number v-model="distribution.revenueReserveMaker" :state="state" @input="validate"></b-form-input>
            </b-input-group>
          </distribution-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </validation-observer>
</template>

<script>

import { distributionCollegeTypes, distributionReserveValueTypes } from "@/constants"
import { mapActions, mapState } from "vuex"
import DistributionFormGroup from "@/components/DistributionFormGroup"
import { ValidationObserver } from "vee-validate"
import { capitalize } from "lodash"
import { distributionSectionMixin } from "@/utils/mixins"
import moment from "moment"

export default {
  name: "MainSection",
  components: { DistributionFormGroup, ValidationObserver },
  mixins: [ distributionSectionMixin ],
  props: {
    distribution: Object
  },
  data () {
    return {
      distributionCollegeTypes,
      distributionReserveValueTypes,
      collegeOptions: [
        {
          text: capitalize(this.$t("performer")),
          value: distributionCollegeTypes.PERFORMER,
        },
        {
          text: capitalize(this.$t("maker")),
          value: distributionCollegeTypes.MAKER,
        },
        {
          text: capitalize(this.$t("both")),
          value: distributionCollegeTypes.BOTH,
        },
      ],
      currencyOptions: [
        {
          text: "CAD",
          value: "CAD",
        }
      ],
      reserveTypeOptions: [
        {
          text: "%",
          value: distributionReserveValueTypes.PERCENT,
        },
        {
          text: "CAD",
          value: distributionReserveValueTypes.MONEY,
        }
      ]
    }
  },
  computed: {
    ...mapState("consts", ["tariffs", "countries"]),
    tariffOptions () {
      return this.tariffs.filter(t => t.copyright === "ER").map(t => ({
        text: t.text,
        value: t.code,
      }))
    },
    proxyTariffOptions () {
      return this.tariffs.filter(t => t.copyright === "ER" && t.proxies.length === 0).map(t => ({
        text: t.text,
        value: t.code,
      }))
    },
    countryOptions () {
      return this.countries.map(c => ({
        text: c.text,
        value: c.alpha3
      }))
    },
    requiredFields () {
      let fields = ["description", "tariff", "college", "periodStart", "periodEnd", "useProxyTariff"]
      if (this.distribution.useProxyTariff) {
        fields.push("proxyTariff", "proxyPeriodStart", "proxyPeriodEnd")
      }
      return fields
    },
    readonlyFields () {
      return ["country", "revenueCurrency"]

    }
  },
  methods: {
    ...mapActions("consts", ["getTariffs", "getCountries"]),
    fillProxyPeriod () {
      if (this.distribution.useProxyTariff) {
        this.distribution.proxyPeriodStart = this.distribution.periodStart
        this.distribution.proxyPeriodEnd = this.distribution.periodEnd
      }
    },
    fillProxyTariff (newVal=null) {
      if (!this.distribution.useProxyTariff) {
        return
      }
      let tariff = newVal ? this.tariffs.find(t => t.code === newVal) : this.tariffs.find(t => t.code === this.distribution.tariff)
      if (!tariff.proxies.length) {
        this.distribution.proxyTariff = null
      } else {
        let year = new Date().getFullYear()
        if (this.distribution.periodStart) {
          year = moment(this.distribution.periodStart).year()
        }
        for (let proxy of tariff.proxies) {
          if (proxy.year === year) {
            this.distribution.proxyTariff = proxy.proxy
            break
          }
        }
      }
    },
    async validate () {
      await this.$refs.observer.validate()
    }
  },
  watch: {
    "distribution.tariff": function (newVal) {
      this.fillProxyTariff(newVal)
      this.fillProxyPeriod()
    },
    "distribution.periodStart": function () {
      this.fillProxyTariff()
      this.fillProxyPeriod()
    },
    "distribution.periodEnd": function () {
      this.fillProxyTariff()
      this.fillProxyPeriod()
    },
    "distribution.useProxyTariff": function (newVal) {
      if (!newVal) {
        this.distribution.proxyTariff = null
        this.distribution.proxyPeriodStart = null
        this.distribution.proxyPeriodEnd = null
      }
      this.fillProxyTariff()
      this.fillProxyPeriod()
    }
  },
  mounted () {
    this.getTariffs()
    this.getCountries()
  }
}
</script>

<style lang="scss">
  .input-group-prepend .custom-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $gray;
  }
</style>
