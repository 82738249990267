var render = function render(){var _vm=this,_c=_vm._self._c;return _c('list-layout',{attrs:{"title":_vm._f("capitalize")(_vm.$t('uploads')),"filters-pending":_vm.filtersPending,"apply-filters":_vm.applyFilters,"reset-filters":_vm.resetFilters,"filters-applied":_vm.filtersApplied,"no-counts":true,"no-search":true,"search-filter":_vm.filters.fuzzySearch},scopedSlots:_vm._u([{key:"beforeFilters",fn:function(){return [_c('b-row',[_c('b-col',[_c('filter-builder',{attrs:{"id":_vm.userPreferenceNames.REPERTOIRE_FILES_LIST_FILTER,"filters":_vm.getFiltersWithOptions(),"filters-pending":_vm.filtersPending,"apply-filters":_vm.applyFilters,"reset-filters":_vm.resetFilters,"filters-applied":_vm.filtersApplied},on:{"filter":_vm.onFilter}})],1)],1)]},proxy:true},{key:"heading-right",fn:function(){return [_c('b-button',{staticClass:"ml-4 d-flex align-items-center",attrs:{"variant":"secondary","href":_vm.resoundTemplateUrl}},[_c('fa-icon',{staticClass:"mr-2",attrs:{"size":"lg","icon":['fa', 'file-download']}}),_vm._v(" "+_vm._s(_vm._f("startcase")(_vm.$t("download_template")))+" ")],1)]},proxy:true},{key:"table",fn:function(){return [_c('t-table',{ref:"repertoire",attrs:{"id":"repertoire","columns":_vm.columns,"options":_vm.options,"limit":_vm.limit},on:{"loaded":_vm.onLoaded,"loading":_vm.onLoading,"pagination":_vm.onPagination,"sorted":_vm.onSorted},scopedSlots:_vm._u([{key:"counts",fn:function(){return [_c('pagination',{staticClass:"smaller p-0",attrs:{"page":_vm.page,"count":_vm.count,"loading":_vm.loading},on:{"limit":_vm.onLimit},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})]},proxy:true},{key:"file_name",fn:function({ row }){return [_c('router-link',{staticClass:"text-info",attrs:{"to":{
            name: 'landing-recordings',
            params: { repFileId: row.id },
            query: 'rightsholder_rs_id' in _vm.$route.query && _vm.filters.rightsHolderUuid.value ? {
              rightsholder_rs_id: _vm.filters.rightsHolderUuid.value.resoundId,
              rights_holder_uuid: _vm.filters.rightsHolderUuid.value.id,
            } : {}
          }}},[_vm._v(" "+_vm._s(row.fileName)+" ")])]}},{key:"uploader",fn:function({ row }){return [_c('router-link',{staticClass:"text-info",attrs:{"to":{ name: 'users-update', params: { uuid: row.uploader.uuid } }}},[_vm._v(" "+_vm._s(row.uploader.firstName + " " + row.uploader.lastName)+" ")])]}},{key:"upload_date",fn:function({ row }){return [_c('date-time-cell',{attrs:{"dateTime":row.uploadDate}})]}},{key:"status",fn:function({ row }){return [(row.status === _vm.fileStatuses.PENDING)?_c('status',{attrs:{"text":_vm.$t('pending'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e(),(row.status === _vm.fileStatuses.PROCESSING)?_c('status',{attrs:{"text":_vm.$t('processing'),"icon":['fas', 'question-circle'],"color":"orange"}}):_vm._e(),(row.status === _vm.fileStatuses.LANDED)?_c('status',{attrs:{"text":_vm.$t('landed'),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(row.status === _vm.fileStatuses.INGESTED)?_c('status',{attrs:{"text":_vm.$t('ingested'),"icon":['fas', 'check-circle'],"color":"green"}}):_vm._e(),(row.status === _vm.fileStatuses.ERROR)?_c('status',{attrs:{"text":_vm.$t('error'),"icon":['fas', 'exclamation-circle'],"color":"red","active":true,"clickable":""},on:{"click":function($event){return _vm.showErrors(row, true)}}}):_vm._e()]}},{key:"ingestionStats",fn:function({ row }){return [(row.stats && row.stats.landingDbIngestion)?_c('div',_vm._l((['totalCount', 'ingestedCount', 'errorCount']),function(item){return _c('div',{key:item,staticClass:"table-sub-class text-sm inline"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getStatsDisplay(item)))+": "+_vm._s(_vm._f("get")(row.stats.landingDbIngestion,[item], ""))+" "),(
                  item === 'errorCount' &&
                    row.stats.landingDbIngestion.errorCount > 0
                )?_c('fa-icon',{staticClass:"blue clickable ml-2",attrs:{"size":"sm","icon":['fas', 'plus-circle']},on:{"click":function($event){return _vm.showErrors(row)}}}):_vm._e(),(row.errorFileName && item == 'errorCount')?_c('fa-icon',{staticClass:"blue clickable ml-2",attrs:{"size":"sm","icon":['fas', 'file-download']},on:{"click":function($event){return _vm.downloadErrorFile(row)}}}):_vm._e()],1)])}),0):_vm._e()]}},{key:"matchingStats",fn:function({ row }){return [(row.stats && row.stats.singleViewIngestion)?_c('div',_vm._l(([
              'totalUniqueCount',
              'linkedCount',
              'createdCount',
              'manuallyLinkedCount',
              'manuallyCreatedCount',
              'pendingForValidationCount'
            ]),function(k){return _c('div',{key:k,staticClass:"table-sub-class text-sm inline"},[(k === 'pendingForValidationCount' && row.stats.singleViewIngestion[k] > 0)?_c('div',[_c('router-link',{staticClass:"text-info",attrs:{"to":{
                name: 'user-actions',
                params: {
                  type: 'matching-conflicts'
                },
                query: {
                  'repertoire_file': row.id
                }
              }}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getStatsDisplay(k)))+": "+_vm._s(row.stats.singleViewIngestion[k])+" ")])],1):_c('div',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getStatsDisplay(k)))+": "+_vm._s(row.stats.singleViewIngestion[k])+" ")])])}),0):_vm._e()]}},{key:"invalidated",fn:function({ row }){return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.invalidated != _vm.invalidatedStatus.INVALIDATED),expression:"row.invalidated != invalidatedStatus.INVALIDATED"}]},[(_vm.permissions.actions.canDeleteRepertoireFile)?_c('fa-icon',{staticClass:"cursor-pointer",attrs:{"role":"button","size":"1x","title":_vm._f("lowercase")(_vm.$t('delete')),"icon":['fa', 'trash'],"color":"#c23d5b"},on:{"click":function($event){return _vm.onDelete(row.id)}}}):_vm._e(),_c('modal',{attrs:{"id":'confirmation-modal-' + row.id,"customTitle":_vm._f("capitalize")(_vm.$t('invalidate_file')),"modal-class":"custom-modal edit","modalType":"edit","size":"md","centered":"","okTitle":"Confirm"},on:{"ok":function($event){return _vm.onConfirmDelete(row)}}},[_c('div',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("do_you_really_want_to_invalidate_file")))+"? ")])])],1)]}}])}),_c('modal',{ref:"errorsModal",attrs:{"id":"errorsModal","customTitle":_vm._f("capitalize")(_vm.$t('errors')),"modal-class":"custom-modal error","modalType":"error","size":"xl","centered":"","hide-footer":""}},[_c('FilesErrorsList',{key:_vm._f("get")(_vm.currentFile,'id'),attrs:{"item":_vm.currentFile,"module":"repertoirefiles","onlyShowFileLevelErrors":_vm.onlyShowFileLevelErrors,"file-errors-endpoint":_vm.$api.repertoire.repertoireFileErrorsList,"file-errors-download-endpoint":_vm.$api.repertoire.repertoireFileErrorsListFile}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }