<template>
  <div>
    <b-row>
      <h4 class="ml-3">
        {{ $t("current_matched_recording") | startcase }}
      </h4>
    </b-row>
    <!-- Unmatch Confirmation Modal -->
    <modal
      :id="usageModalTypes.UNMATCH_USAGE"
      :customTitle="$t('unmatch_usage') | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="md"
      centered
      @ok="onConfirmUnmatch()"
      okTitle="Confirm"
    >
      <div>{{ $t("do_you_really_want_to_unmatch_usage") | capitalize }}?</div>
    </modal>
    <modal
      :id="formId"
      :customTitle="challengeView ? $t('use_current_matched_recording') : $t('challenge_match') | startcase"
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered
      hide-footer
    >
      <usage-challenge-form
          @challenged="matchChallenged"
          @resolved="challengeResolved"
          :id="formId"
          :recording="matchedRecording"
          :challenge-id="challengeUuid"
          :usage="usage"
          :solved-manually="solvedManually"
          :suggestedRecordingsData="suggestedRecordingsData"
      />
    </modal>
    <v-server-table
      ref="recordingTable"
      :columns="columns"
      :options="options">
      <!-- RS ID -->
      <template v-slot:resoundId="{ row }">
        <database-link :type="linkTypes.RECORDING" :item-id="row.id">{{ row.resoundId }}</database-link>
      </template>
      <!-- Title -->
      <template v-slot:title="{ row }">
        <span :title="row.title">{{ row.title }}</span>
      </template>
      <!-- Albums -->
      <template v-slot:album="row">
        <NamesListModal
            class-names="table-meta mb-2"
            :values="getAlbums(row) || []"
        />
      </template>
      <!-- Composers -->
      <template v-slot:composer="row">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="getExtraData(row, 'composer') || []"
        />
      </template>
      <!-- First Maker Name -->
      <template v-slot:firstMakerNames="{ row }">
        <div v-for="firstMaker in row.firstMakers" :key="firstMaker.id">
          {{ firstMaker.name }}
        </div>
      </template>
      <!-- Nationality -->
      <template v-slot:nationality="{ row }">
        <div v-for="firstMaker in row.firstMakers" :key="firstMaker.id">
          {{ firstMaker.nationalities && firstMaker.nationalities.length ? firstMaker.nationalities.join(", ") : "-" }}
        </div>
      </template>
      <!-- Fixation Country -->
      <template v-slot:fixationCountry="{ row }">
        <span :title="row.fixationCountry ? row.fixationCountry.name : ''">
          {{ row.fixationCountry ? row.fixationCountry.name : "" }}
        </span>
      </template>
      <!-- Duration -->
      <template v-slot:duration="{ row }">
        <span>
          {{ row.duration ? $options.filters.toDuration(row.duration) : "" }}
        </span>
      </template>
      <template v-slot:actions>
        <div class="text-right mr-1">
          <fa-icon class="pt-0 pb-0 clickable hover-red" v-if="challengeUuid && !challengeView" @click="setChallengeView" size="sm" :icon="['fa', 'hand-paper']"/>
          <span v-else-if="hasPermissionToModifyChallenge()">
            <b-button
              @click="openChallengeModal"
              class="pt-0 pb-0 mr-3 mb-1"
              variant="outline-secondary">
              {{ challengeView ? $t("keep") : $t("challenge") | capitalize }}
            </b-button>
            <b-button
              v-if="challengeUuid"
              @click="onUnmatchModal"
              :icon="['fa', 'unlink']"
              class="pt-0 pb-0 mb-1"
            >
              <span>
                <fa-icon size="sm" icon="trash" color="white" />
                  {{ $t("unmatch_usage") | capitalize }}
              </span>
            </b-button>
          </span>
        </div>
      </template>
    </v-server-table>
  </div>
</template>

<script>
import { actionsTabs, linkTypes, usageModalTypes } from "@/constants"
import { capitalize, startCase, upperCase } from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import UsageChallengeForm from "@/pages/Usages/Forms/UsageChallengeForm"

export default {
  name: "UsageMatchedRecording",
  components: { DatabaseLink, NamesListModal, UsageChallengeForm, },
  props: {
    challengeUuid: String,
    challengeView: Boolean,
    usage: Object,
    uuid: String,
    solvedManually: {
      type: Array,
      required: false,
    }
  },
  data () {
    return {
      linkTypes,
      usageModalTypes,
      suggestedRecordingsData: [],
      matchedRecording: null,
      columns: [
        "resoundId",
        "artist",
        "title",
        "album",
        "composer",
        "isrc",
        "firstMakerNames",
        "nationality",
        "fixationCountry",
        "fixationYear",
        "releaseYear",
        "type",
        "duration",
        "genreType",
        "actions",
      ],
      options: {
        sortable: [],
        headings: {
          "artist": capitalize(this.$t("artist")),
          "title": capitalize(this.$t("title")),
          "album": capitalize(this.$t("album")),
          "composer": capitalize(this.$t("composer")),
          "isrc": upperCase(this.$t("isrc")),
          "resoundId": this.$t("resound_id"),
          "firstMakerNames": startCase(this.$t("first_makers")),
          "nationality": upperCase(this.$t("fmn")),
          "fixationYear": upperCase(this.$t("yof")),
          "releaseYear": upperCase(this.$t("yor")),
          "fixationCountry": upperCase(this.$t("cof")),
          "type": capitalize(this.$t("type")),
          "duration": capitalize(this.$t("dur")),
          "genreType": startCase(this.$t("genre")),
          "actions": "",
        },
        columnsClasses: {
          artist: "width-mini short-text",
          title: "width-mini short-text",
          album: "width-micro-big short-text",
          composer: "width-micro-big short-text",
          isrc: "width-micro-big",
          resoundId: "width-micro-big",
          firstMakerNames: "width-micro-big short-text",
          nationality: "width-micro short-text",
          fixationYear: "width-nano-big",
          releaseYear: "width-nano-big",
          fixationCountry: "width-micro short-text",
          type: "width-nano-big",
          duration: "width-nano-big",
          genreType: "width-nano-big",
          actions: "width-small",
        },
        responseAdapter ({ data }) {
          this.$parent.$parent.matchedRecording = data
          return {
            data: [data],
            count: 1
          }
        },
        requestFunction () {
          this.data = []
          return this.$api.repertoire.recordings(this.$parent.$parent.uuid)
        },
      },
      suggestedOptions: {
        sortable: [],
        responseAdapter: ({ data }) => {
          this.suggestedRecordingsData = data.flatMap(item => item.candidates)
          return {
            data: data,
            count: data.count
          }
        },
        requestFunction: () => {
          this.suggestedRecordingsData = []
          return this.$api.usages.usagesCandidatesList({ uuids: this.usage.id })
        },
      },
      actionsTabs,
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", ]),
    ...mapMutations("alert", ["success", "error"]),
    ...mapActions("userActions", ["getUserActionsSummary"]),
    getAlbums (data) {
      return data.row.releases.length ?
        data.row.releases.map(x => x.title):
      // There are some recordings that still have the albums in extras
        this.getExtraData(data, "release")
    },
    getExtraData (data, tag) {
      return data.row.extras.length
        ? data.row.extras
          .filter((x) => x.tag === tag)
          .map((x) => x.value)
        : null
    },
    matchChallenged (obj) {
      this.$emit("challenged", obj)
    },
    challengeResolved (obj) {
      this.$emit("resolved", obj)
    },
    openChallengeModal () {
      this.$bvModal.show(this.formId)
    },
    onUnmatchModal () {
      this.$bvModal.show(this.usageModalTypes.UNMATCH_USAGE)
    },
    setChallengeView () {
      this.$emit("resolving-challenge")
    },
    hasPermissionToModifyChallenge () {
      if (this.challengeView) {
        return this.permissions.actionsTabs[actionsTabs.USAGE_CHALLENGES].canWrite
      }
      return this.permissions.actions.canChallengeUsage
    },
    onConfirmUnmatch () {
      this.$api.actions.unmatchUsageMatchingChallenge(this.challengeUuid)
        .then((result) => {
          this.isLoading = false
          this.success(capitalize(this.$t("usage_challenge_unmatched")))
          this.$emit("resolved", result.data.recording)
          this.getUserActionsSummary({ partial: true })
        })
        .catch((error) => {
          this.isLoading = false
          let msg = `Error unmatch recording. ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    fetchSuggestedRecordings () {
      this.suggestedOptions.requestFunction().then(response => {
        const data = response.data
        this.suggestedRecordingsData = data.flatMap(item => item.candidates)
      })
    },
  },
  computed: {
    ...mapState("consts", ["countries"]),
    ...mapState("user", ["permissions"]),
    formId () {
      return this.challengeView ? usageModalTypes.KEEP_RECORDING : usageModalTypes.CHALLENGE_MATCH
    }
  },
  watch: {
    uuid () {
      this.fetchSuggestedRecordings()
      this.$refs.recordingTable.getData()
    },
    matchedRecording (newVal) {
      this.$emit("loaded", newVal)
    }
  },
  mounted () {
    this.fetchSuggestedRecordings()
    if (!this.countries.length) {
      this.getCountries()
    }
  },
}
</script>
<style lang="scss" scoped>
  .hover-red {
    &:hover {
      color: $red;
    }
  }

  .table {
    table-layout: fixed;
  }
</style>
