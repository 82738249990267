import * as Msal from "@azure/msal-browser"
import { actionsTabs, tokenCookieName } from "@/constants"
import Vue from "vue"
import config from "@/utils/auth/config"
import moment from "moment"
import { router } from "@/utils"
import { store } from "@/store"

const myMSALObj = new Msal.PublicClientApplication(config.msalConfig)

const forgotPasswordError = "AADB2C90118"
let auth = new Vue({
  data () {
    return {
      user: {},
      error: null,
      loading: true,
      redirectCallbackIsRegistered: true,
      loginStatus: true,
    }
  },

  methods: {
    isAuthenticated () {
      const account = myMSALObj.getActiveAccount()
      const now = moment()
      if (account !== null && now.diff(moment.unix(account.idTokenClaims.exp), "hours") >= 24) {
        return false
      }
      return account
    },

    async handleRedirect (response) {
      if (response === null) {
        return
      }
      
      if (response) {
        myMSALObj.setActiveAccount(response.account)
        let userData = await store.dispatch("user/getUser")
          .catch(async function () {
            alert("User not active or does not exist. Please contact the administrator.")
            this.logout()
          })
        this.$api.members.membersList().then((members) => {
          store.commit("consts/setMemberCollectives", members.data)
        })

        this.getAuthResponse().then((token) => {
          this.$connect(`${process.env.VUE_APP_API_WS_HOST}?token=${token}`)
          store.dispatch("userActions/getUserActionsSummary", { partial: true })
        })
        // Clear preferences that perhaps remained from the previous session
        store.commit("user/clearPreferences")
        const redirect = localStorage.getItem("redirect")
        if (redirect) {
          // if URI has a redirect param
          router.push(redirect)
          localStorage.removeItem("redirect")
        } else {
          // redirect to preferred landing page after login
          if (userData.preferredLandingPage) {
            localStorage.setItem("landingPage", userData.preferredLandingPage)
            const parsedUserData = JSON.parse(userData.preferredLandingPage)
            router.push({
              name: parsedUserData.name,
              params: { type: parsedUserData.type },
            })
          } else {
            router.push({ name: "user-actions", params: { type: actionsTabs.NOTIFICATIONS } })
          }
        }
      }

      // We need to reject id tokens that were not issued with the default sign-in policy.
      // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
      // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview

      if (response.tokenType === "id_token" && response.idToken.claims["tfp"] !== config.b2cPolicies.names.signUpSignIn) {
        await myMSALObj.logoutRedirect()
      }

      // redirect where the user was
      if (response.accountState) {
        router.push(response.accountState)
      }
    },

    async handlerRedirectError (error) {
    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (error.errorMessage !== undefined && error.errorMessage.indexOf(forgotPasswordError) > -1) {
        try {
          // Password reset policy/authority
          await myMSALObj.loginRedirect(config.b2cPolicies.authorities.forgotPassword)
        } catch (err) {
          // console.log(err)
        }
      }
      else {
        this.loginStatus = true
        this.login()
      }
    },

    registerRedirectCallback () {
      // Register Callbacks for Redirect flow
      if (this.redirectCallbackIsRegistered) {
        myMSALObj.handleRedirectPromise().then(this.handleRedirect).catch(this.handlerRedirectError)
      }
    },

    login () {
      let loginRequest = Object.assign({}, config.loginRequest)
      loginRequest.state = router.currentRoute.name
      if (this.loginStatus) {
        myMSALObj.loginRedirect(loginRequest)
      }
    },

    acquireTokenSilent (options) {
      return myMSALObj.acquireTokenSilent({ ...config.tokenRequest, ...options })
    },

    acquireTokenRedirect (options) {
      return myMSALObj.acquireTokenRedirect({ ...config.tokenRequest, ...options })
    },

    async getAuthResponse () {
      let res
      try {
        res = await this.acquireTokenSilent({ "state": router.currentRoute.fullPath })
      } catch (error) {
        if (error instanceof Msal.InteractionRequiredAuthError) {
          res = await this.acquireTokenRedirect({ "state": router.currentRoute.fullPath })
        }
      }
      if (!res || !res.accessToken) {
        await this.logout()
      }
      Vue.$cookies.set(tokenCookieName, res.accessToken)
      return res.accessToken
    },

    async logout () {
      Vue.$cookies.remove(tokenCookieName)
      // Adding an event listener to detect when the window is unloaded after the redirect
      window.addEventListener("unload", () => {
        // Clear msal.interaction.status from session storage
        sessionStorage.removeItem("msal.interaction.status")
        localStorage.removeItem("msal.interaction.status")
      })
      localStorage.removeItem("landingPage")
      await myMSALObj.logoutRedirect()
    },
  },

  async created () {
    const fragmentIdentifier = window.location.hash
    if (fragmentIdentifier) {
      this.loginStatus = false
      this.registerRedirectCallback()
    }
  },

})

export default {
  install: function (Vue) {
    Vue.prototype.$auth = auth
  }
}
