<template>
  <div class="d-flex align-items-center">
    <b-badge @click="switchView" class="mr-1 clickable" pill variant="secondary">
      {{ selectedObjects.length }} selected
    </b-badge>
    <span @click="clear" class="clickable text-red small">
      Clear selected
    </span>
  </div>
</template>

<script>
export default {
  name: "MergeSummary",
  props: {
    selectedObjects: {
      type: Array,
      required: true,
    },
    switchView: {
      type: Function,
      required: true,
    },
    clear: {
      type: Function,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    badgeDisplayField: {
      type: String,
      required: true,
    },
  },
  methods: {
    removeSelected (id) {
      this.selectedObjects.splice(this.selectedObjects.map(r => r.id).indexOf(id), 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  font-size: 75% !important;
}
</style>
