<template>
  <list-layout :isChild="true">
    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        ref="file-errors"
        @loaded="onLoaded"
      >
      </v-server-table>
    </template>
  </list-layout>
</template>

<script>
import { capitalize } from "lodash"

export default {
  name: "RepertoireErrorDetail",
  props: {
    file: Object,
  },
  methods: {
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
    },
  },
  data () {
    return {
      columns: ["itemSeq", "field", "type", "reason"],
      options: {
        sortable: [],
        headings: {
          itemSeq: capitalize(this.$t("item")),
          field: capitalize(this.$t("field")),
          type: capitalize(this.$t("error_type")),
          reason: capitalize(this.$t("reason")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          return this.$api.repertoire.repertoireFileErrorsList(
            this.$parent.$parent.$parent.file.id, queryParams
          )
        },
      },
    }
  },
}
</script>
