<template>
  <div class="d-flex align-items-center">
    <div>
      <span>
        {{ `${count ? page * value - (value - 1) : 0}-${Math.min(page * value, count)} ${$t('of')} ${count} ${$t('items')}` }}
      </span>
    </div>
    <div class="ml-4" v-if="perPageSelector">
      <b-form-group
        class="mb-0"
        label-cols="auto"
        :label="$t('items_per_page') | capitalize"
      >
        <b-form-select v-model="innerValue" :options="options"/>
      </b-form-group>
    </div>
    <div class="ml-4" v-if="goToPage">
      <b-form-group
        class="mb-0"
        label-cols="auto"
        :label="$t('page') | capitalize"
      >
        <b-input-group>
        <b-form-input type="number" v-model="innerPage" :max="Math.ceil(count / innerValue)" :min="1"/>
          <b-input-group-append>
            <b-button @click="setPage" class="btn-outline-dark">{{ $t("go_to") | capitalize }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
    <slot name="buttons"/>
    <b-spinner class="ml-2" v-if="loading" variant="light" small></b-spinner>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    page: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    perPageSelector: {
      type: Boolean,
      default: true
    },
    reference: {
      type: String,
      default: ""
    },
    goToPage: {
      type: Boolean,
      default: false,
    },
    // must be included in props
    value: {
      type: Number
    },
  },
  data: () => ({
    innerPage: null,
    innerValue: 10,
    options: [10, 20, 30, 50, 100, 200]
  }),
  computed: {
    maxPage () {
      return Math.ceil(this.count / this.value)
    }
  },
  methods: {
    setPage () {
      if (this.innerPage > this.maxPage) {
        this.innerPage = this.maxPage
      }
      if (this.innerPage < 1) {
        this.innerPage = 1
      }
      this.$emit("page", this.innerPage)
    }
  },
  watch: {
    page (newVal) {
      this.innerPage = newVal
    },
    // Handles internal model changes.
    innerValue (newVal) {
      this.innerPage = this.page
      this.$emit("input", newVal)
      this.$emit("limit", newVal, this.reference)
    },
    // Handles external model changes.
    value: {
      handler (newVal) {
        this.innerValue = newVal
      },
      immediate: true
    }
  },
  mounted () {
    this.innerPage = this.page
  }
}
</script>
<style lang="scss" scoped>

::v-deep .col-form-label {
  margin: auto;
}
</style>
