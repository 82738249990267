import { get } from "lodash"

const state = {
  pendingRequests: new Map()
}

const mutations = {
  addPendingRequest (state, requestConfig) {
    const keepState = get(requestConfig, "keepState", true)
    const method = get(requestConfig, "method", "")
    if (keepState && (!method || method.toLowerCase() === "get")) {
      const key = requestConfig.url
      if (!requestConfig.signal) {
        const abortController = new AbortController()
        requestConfig.signal = abortController.signal
        if (!state.pendingRequests.has(key)) {
          state.pendingRequests.set(key, abortController)
        }
      }
    }
  },
  removePendingRequest (state, requestConfig) {
    const keepState = get(requestConfig, "keepState", true)
    const method = get(requestConfig, "method", "")
    if (keepState && (!method || method.toLowerCase() === "get")) {
      const key = requestConfig.url
      if (state.pendingRequests.has(key)) {
        const abortController = state.pendingRequests.get(key)
        abortController.abort()
        state.pendingRequests.delete(key)
      }
    }
  },
  clearPendingRequests (state) {
    for (const [, abortController] of state.pendingRequests) {
      abortController.abort()
    }
    state.pendingRequests.clear()
  }
}

export const requests = {
  namespaced: true,
  state,
  mutations
}
