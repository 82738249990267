const actions = {
  validationError (context, event) {
    throw new Error(JSON.stringify(event))
  },
  unhandledError (context, event) {
    throw new Error(JSON.stringify(event))
  },
}

export const errors = {
  namespaced: true,
  actions
}