import { mapActions, mapMutations, mapState } from "vuex"
import { userActionNotificationStatuses, userActionStatuses } from "@/constants"

const websocketsMixin = {
  data () {
    return {
      userActionNotification: null,
      openedUserActions: [],
      statusWatchers: [],
      blinking: [],
      hidden: [],
      solvedManually: [],
      reloadMethod: null,
      // Only the usage matching page needs to reload after we hide, this is because the select all page button could
      // lead into selecting a hidden row
      reloadMethodAfterHide: null,
    }
  },
  computed: {
    ...mapState("userActions", [
      "wsMandateConflictStatuses",
      "wsUserActionStatuses",
      "wsLineupConflictStatuses",
      "wsOwnershipConflictStatuses",
      "wsRecordingEligibilityConflictStatuses",
      "wsRecordingLineupNegativeAdjustmentStatuses",
      "wsRecordingOwnershipNegativeAdjustmentStatuses",
      "wsUniqueUsageMatchingStatuses",
      "wsIsrcConflictStatus",
      "wsIpnConflictStatus",
      "channelName"
    ])
  },
  methods: {
    ...mapMutations("userActions", ["resetState"]),
    ...mapActions("userActions", ["notifyUserAction"]),
    statusWatcher (newVal, oldVal) {
      let modalShown = false
      this.openedUserActions.forEach((userAction) => {
        let userActionUuid = userAction.uuid
        // If the user action is not even in the store, then ignore it for now
        if (userActionUuid in this[this.storeStatusesKey]) {
          if (this.isSolved(userActionUuid) && !this.wasSolvedManually(userActionUuid)) {
            this.$bvModal.show(`confirmation-modal-solved-${userActionUuid}`)
            modalShown = true
          } else if(!this.isInProgressFrontend(userActionUuid) && !this.ownsAction(userActionUuid, oldVal) && !this.ownsAction(userActionUuid)) {
            // Random interval to prevent, as much as possible, the concurrency for taking over the user action
            setTimeout(
              () => {
                this.notifyUserAction({
                  id: userActionUuid,
                  type: this.userActionNotification.type,
                  action_identifier: this.userActionNotification.action_identifier,
                  status: userActionNotificationStatuses.OPEN,
                  extra: userAction.extra,
                })
              },
              Math.random() * 3000,
            )
          }
        }
      })

      if (!modalShown) {
        let solvedList = Object.keys(this[this.storeStatusesKey]).filter(
          uuid => this[this.storeStatusesKey][uuid].status === userActionNotificationStatuses.SOLVED
        )
        let shownList = this.shownUuidList

        solvedList.forEach(uuid => {
          if (shownList && shownList.includes(uuid.replaceAll("-", ""))) {
            if (!this.wasSolvedManually(uuid)) {
              this.blinkAndHide(uuid, false, this.reloadMethodAfterHide)
            }
          }
        })
      }
    },
    isHidden (uuid) {
      return this.hidden.includes(uuid)
    },
    wasSolvedManually (uuid) {
      return this.solvedManually.includes(uuid)
    },
    shouldBlink (uuid) {
      return this.blinking.includes(uuid)
    },
    isInProgressBackend (statuses) {
      return statuses.includes(userActionStatuses.IN_PROGRESS)
    },
    isInProgressFrontend (uuid, oldVal=null) {
      let conflict_uuid = uuid.replaceAll("-", "")
      let evaluate_on = this[this.storeStatusesKey]
      if (oldVal) {
        evaluate_on = oldVal
      }
      return conflict_uuid in evaluate_on && evaluate_on[conflict_uuid].status === userActionNotificationStatuses.OPEN
    },
    isPending (statuses) {
      return statuses.includes(userActionStatuses.PENDING)
    },
    isReleasedFrontend (uuid) {
      let conflict_uuid = uuid.replaceAll("-", "")
      return (
        (
          conflict_uuid in this[this.storeStatusesKey] &&
          this[this.storeStatusesKey][conflict_uuid].status === userActionNotificationStatuses.RELEASE
        )
      )
    },
    isSolved (uuid) {
      let conflict_uuid = uuid.replaceAll("-", "")
      return (
        (
          conflict_uuid in this[this.storeStatusesKey] &&
          this[this.storeStatusesKey][conflict_uuid].status === userActionNotificationStatuses.SOLVED
        )
      )
    },
    blinkAndHide (uuid, toggle=null, reload=null) {
      if (toggle) {
        this.$root.$emit("bv::toggle::collapse", toggle)
      }
      this.$bvModal.hide(`confirmation-modal-solved-${uuid}`)
      this.blinking.push(uuid)
      if (reload) {
        setTimeout(reload, 6000)
      } else {
        setTimeout(() => {this.hidden.push(uuid)}, 6000)
      }
      setTimeout(() => {this.blinking = this.blinking.filter(blinking => blinking !== uuid)}, 6000)
    },
    ownsAction (uuid, oldVal = null) {
      let evaluate_on = this[this.storeStatusesKey]
      if (oldVal) {
        evaluate_on = oldVal
      }
      let conflict_uuid = uuid.replaceAll("-", "")
      return conflict_uuid in evaluate_on && evaluate_on[conflict_uuid].channel === this.channelName
    },
    onOpened (uuid, extra={}) {
      this.openedUserActions.push(
        {
          "uuid": uuid,
          "extra": extra
        }
      )
      if (!this.isInProgressFrontend(uuid) || this.ownsAction(uuid)) {
        this.notifyUserAction({
          id: uuid,
          type: this.userActionNotification.type,
          action_identifier: this.userActionNotification.action_identifier,
          status: userActionNotificationStatuses.OPEN,
          extra: extra
        })
      }
    },
    onClosed (uuid, extra={}) {
      this.openedUserActions = this.openedUserActions.filter(
        userAction => userAction.uuid !== uuid
      )
      if (this.ownsAction(uuid)) {
        this.notifyUserAction({
          id: uuid,
          type: this.userActionNotification.type,
          action_identifier: this.userActionNotification.action_identifier,
          status: userActionNotificationStatuses.RELEASE,
          extra: extra
        })
      }
    },
    releaseAll () {
      this.openedUserActions.forEach((uuid) => {
        this.onClosed(uuid.uuid, uuid.extra)
      })
    },
  },
  mounted () {
    this.$watch(this.storeStatusesKey, this.statusWatcher, { deep: true })
    window.addEventListener("beforeunload", this.releaseAll)
  },
  beforeDestroy () {
    this.releaseAll()
    this.resetState(this.storeStatusesKey)
  }
}

export { websocketsMixin }
