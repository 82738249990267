import { Pages } from "@/utils/pages"
import { get } from "lodash"
import moment from "moment"

const RESOUND = "Re:Sound"
const CONNECT = "CONNECT"

const CHUNK_SIZE_UUIDS = 50

const reconciliationStatuses = {
  PENDING: "1",
  DONE: "2",
  ERROR: "3"
}

const landingRecordingReconciliationStatuses = {
  READY: "R",
  NOT_READY: "N",
}

const activationStatuses = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "pending",
}

const fileStatuses = {
  ERROR: "ER",
  INGESTED: "IN",
  LANDED: "LN",
  PROCESSING: "PR",
  PENDING: "PN",
  MATCHED: "MT",
}

const invalidatedStatus = {
  VALIDATED: "1",
  INVALIDATED: "2",
  IN_REAL_SYNC: "3",
  SYNCED: "4",
}

const landingDataStatuses = {
  CREATED: "CR",
  LINKED: "LN",
  MANUALLY_CREATED: "MC",
  MANUALLY_LINKED: "ML",
  PENDING: "PN",
}

const landingStatuses = {
  CR: "created",
  LN: "linked",
  PN: "pending",
  MC: "manually_created",
  ML: "manually_linked",
}

const rightsholderTypes = {
  PERFORMER: "performer",
  MAKER: "maker"
}

const paymentRightsholderTypes = {
  P: "performer",
  M: "maker"
}

const actionsTabs = {
  CLAIMS: "claims",
  MANDATES: "mandates",
  RECORDING_CHALLENGES: "recording-challenges",
  NOTIFICATIONS: "notifications",
  MATCHING_CONFLICTS: "matching-conflicts",
  REPERTOIRE_ERRORS: "repertoire-errors",
  RIGHTSHOLDERS: "rightsholders",
  RIGHTSHOLDER_CHALLENGES: "rightsholder-challenges",
  LINEUP_CONFLICTS: "lineup-conflicts",
  RIGHTS_OWNERSHIP_CONFLICT: "ownership-conflicts",
  USAGE_MATCHING: "usage-matching",
  USAGE_CHALLENGES: "usage-challenges",
  ELIGIBILITY_CONFLICTS: "eligibility-conflicts",
  NEGATIVE_ADJUSTMENTS: "negative-adjustments",
  DUPLICATED_CONFLICTS: "duplicated-conflicts",
  MERGE_REQUESTS: "merge-requests",
}

const metadataConflictTypeCodes = {
  RECORDING_METADATA_CONFLICT: "2",
  PERFORMERS_METADATA_CONFLICT: "3",
  MAKERS_METADATA_CONFLICT: "7",
}

const statementConflictTypeCodes = {
  STATEMENT_RECORDING_MATCHING: "15",
  STATEMENT_PERFORMER_MATCHING: "16",
  STATEMENT_MAKER_MATCHING: "17",
}

const conflictTypeCodes = {
  RECORDING_CONFLICT: "2",
  PERFORMERS_CONFLICT: "3",
  MAKERS_CONFLICT: "4",
}

const UserActionTypeCodes = {
  PERFORMERS_METADATA_CONFLICT: "3",
  RIGHTS_OWNERSHIP_CONFLICT: "4",
  RECORDING_CHALLENGE: "6",
  MAKERS_METADATA_CONFLICT: "7",
  PERFORMER_CHALLENGE: "8",
  MAKER_CHALLENGE: "9",
  RECORDING_LINEUP_CONFLICT: "10",
  ELIGIBILITY_CONFLICT: "18",
  DUPLICATE_ISRC: "22",
  DUPLICATE_IPN: "23",
}

const recordingDetailTabs = {
  GENERAL_DETAILS: "general-details",
  USAGE: "usage",
  PAYMENTS: "payments",
  OWNERS: "owners",
  PERFORMERS: "performers",
  COMMENTS: "comments",
  MERGED_RECORDINGS: "merged-recordings",
  SR_HISTORY: "sr-history",
}

const rightsholderDetailTabs = {
  GENERAL_DETAILS: "general-details",
  MANDATES: "mandates",
  PAYMENTS: "payments",
  REPERTOIRE: "repertoire",
  MERGED_RIGHTSHOLDERS: "merged-rightsholders"
}

const mergedRightsHolderTypes = {
  MAKER: "maker",
  PERFORMER: "performer"
}

let partyNameTypes = {
  PA: "1",  // "Patronym"
  PP: "2",  // "Pseudonym of individuals"
  PG: "3",  // "Pseudonym groups"
  MO: "4",  // "Modification"
  DF: "5",  // "Different spelling"
  ST: "6",  // "Standardized spelling"
  OR: "7",  // "Other reference"
  HR: "8",  // "Holding reference"
}

const partyTypes = {
  SOCIETY: "society",
  CONTRIBUTOR: "contributor",
  PUBLISHER: "publisher",
}

const soundRecordingTypes = {
  SOUND_RECORDING: "1",
  MUSIC_VIDEO: "2",
}

const junkStatuses = {
  IS_JUNK: "true",
  IS_NOT_JUNK: "false",
}

const usageMatchingStatuses = {
  UNMATCHED: 1,
  AUTOMATCHED: 2,
  MANUALLY_MATCHED: 3,
}

const inlineRowStatus = {
  ERROR: 6,
  SUCCESS: 7,
}

const usageTypes = {
  DURATION: 1,
  PLAY_COUNT: 2,
  AMOUNT: 3,
}

const userActionStatuses = {
  PENDING: "1",
  IN_PROGRESS: "2",
  DONE: "3",
}

const userActionTypes = {
  METADATA: "1",
  RIGHTS: "3",
  RECORDING: "4"
}

const userTypes = {
  INTERNAL: "internal",
  EXTERNAL: "external",
}

const workSources = {
  API: "api",
  FILE: "file",
}

const latestPeriods = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
}

const mandateScopes = {
  DOMESTIC: "domestic",
  WORLDWIDE: "worldwide",
  NONE: "none",
}

const perPageItemsSm = 10
const perPageItemsMd = 20
const perPageItemsLg = 30

const resoundUserRoles = [
  "AD",
  "DM",
  "PD",
  "DS",
  "UL",
  "UM",
  "DA",
  "RA",
  "RL",
  "RM",
  "AC",
  "IT",
  "RO",
  "SF",
  "RX",
  "IA",
]
const sisterSocietyUserRole = "SS"

const salesforceCommunitiesUserRole = "SC"

const connectUserRole = "CO"

const duplicatedTypes = {
  ISRC: "isrc",
  IPN: "ipn",
}

const sourceTypes = {
  REPERTOIRE_FILE: "repertoire_file",
  RIGHTSHOLDER_BATCH: "rightsholder_batch",
  PAYMENT_FILE: "payment_file",
  USAGE_FILE: "usage_file",
}

const adjustmentTypes = {
  LINEUP: "lineup_adjustments",
  OWNERSHIP: "ownership_adjustments",
  USAGE: "usage_matching",
}

const metadataConflictSourceTypes = {
  FILE: "file",
  INPUT_SOURCE: "input_source",
  PAYMENT_FILE: "payment_file",
}

const exportFormats = {
  EXCEL: "xlsx",
  CSV: "csv",
  DDEX: "ddex",
}

const rhBatchTypes = {
  ENDPOINT: "endpoint",
  CRESCENDO_FILE: "crescendo",
  SOUNDEXCHANGE: "soundexchange",
}

const usageModalTypes = {
  KEEP_RECORDING: "keep-recording-modal",
  USE_SUGGESTED_RECORDING: "use-suggested-recording-modal",
  CHALLENGE_MATCH: "usage-challenge-modal",
  COMMENT_CHALLENGE: "challenge-comment-modal",
  REVIEW_USAGES: "review-usages-modal",
  UNMATCH_USAGE: "unmatch-confirm-model",
}

function getPerPageItemDefaults (route) {
  const defaultRetValue = perPageItemsSm
  let page = route?.name
  let type = route?.params?.type
  let advSegregator = (elseValue, cases) => {
    return () => {
      let queryParams = route?.query
      if (cases && typeof cases === "object" && Object.keys(cases).length){
        if (queryParams && typeof queryParams === "object" && Object.keys(queryParams).length){
          for (let caseItem in cases) {
            if (queryParams[caseItem] && queryParams[caseItem] !== "false")
              return cases[caseItem]
          }
        }
      }
      return elseValue ?? defaultRetValue
    }
  }
  let retValue = get({
    [Pages.RepertoireRecordingList]: perPageItemsLg,
    [Pages.ConsolidatedUsageList]: perPageItemsLg,
    [Pages.RightsHoldersList]: perPageItemsLg,
    [Pages.PaymentFilesList]: perPageItemsLg,
    [Pages.Stations]: perPageItemsLg,
    [Pages.MatchingStatistics]: perPageItemsLg,
    [Pages.UsersList]: perPageItemsLg,
    "user-actions": {
      [actionsTabs.MATCHING_CONFLICTS]: advSegregator(perPageItemsLg, {
        show_by_file: perPageItemsMd
      }),
      [actionsTabs.USAGE_MATCHING]: perPageItemsLg,
      [actionsTabs.USAGE_CHALLENGES]: perPageItemsLg,
      [actionsTabs.ELIGIBILITY_CONFLICTS]: perPageItemsLg,
      [actionsTabs.NEGATIVE_ADJUSTMENTS]: perPageItemsLg,
      [actionsTabs.DUPLICATED_CONFLICTS]: perPageItemsLg,
      [actionsTabs.MERGE_REQUESTS]: perPageItemsLg,
      [actionsTabs.NOTIFICATIONS]: perPageItemsMd,
      [actionsTabs.MANDATES]: perPageItemsMd,
      [actionsTabs.REPERTOIRE_ERRORS]: perPageItemsMd,
      [actionsTabs.RECORDING_CHALLENGES]: perPageItemsMd,
      [actionsTabs.RIGHTSHOLDER_CHALLENGES]: perPageItemsMd,
      [actionsTabs.LINEUP_CONFLICTS]: perPageItemsMd,
      [actionsTabs.RIGHTS_OWNERSHIP_CONFLICT]: perPageItemsMd,
    },
    [Pages.RepertoireRecordingList]: perPageItemsLg,
  }, [page, type].filter(Boolean).join("."), defaultRetValue)

  if (typeof retValue === "function"){
    return retValue()
  }
  return retValue
}

function getUserActionStatusDisplay (status) {
  return get({
    [userActionStatuses.PENDING]: "pending",
    [userActionStatuses.IN_PROGRESS]: "in_progress",
    [userActionStatuses.DONE]: "done",
  }, status)
}

function getRecordingTypeDisplay (type) {
  return get({
    [soundRecordingTypes.SOUND_RECORDING]: "sound_recording",
    [soundRecordingTypes.MUSIC_VIDEO]: "music_video",
  }, type)
}

function getActionTypeDisplay (type) {
  return get({
    [metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT]: this.$t("recording"),
    [metadataConflictTypeCodes.MAKERS_METADATA_CONFLICT]: this.$t("maker"),
    [metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT]: this.$t("performer"),
  }, type)
}

function getRecordingActionTypeDisplay (type) {
  return get({
    [UserActionTypeCodes.MAKERS_METADATA_CONFLICT]: "maker_manual_matching",
    [UserActionTypeCodes.PERFORMERS_METADATA_CONFLICT]: "performer_manual_matching",
    [UserActionTypeCodes.RECORDING_CHALLENGE]: "recording_matching_challenge",
    [UserActionTypeCodes.MAKER_CHALLENGE]: "maker_matching_challenge",
    [UserActionTypeCodes.PERFORMER_CHALLENGE]: "performer_matching_challenge",
    [UserActionTypeCodes.RECORDING_LINEUP_CONFLICT]: "lineup_conflict",
    [UserActionTypeCodes.RIGHTS_OWNERSHIP_CONFLICT]: "ownership_conflict",
    [UserActionTypeCodes.DUPLICATE_ISRC]: "duplicated_conflicts_isrc",
    [UserActionTypeCodes.DUPLICATE_IPN]: "duplicated_conflicts_ipn",
    [UserActionTypeCodes.ELIGIBILITY_CONFLICT]: "eligibility_conflicts",
  }, type)
}

function getActivationStatusIcon (status) {
  return get({
    [activationStatuses.ACTIVE]: ["fas", "check-circle"],
    [activationStatuses.INACTIVE]: ["fas", "exclamation-circle"],
    [activationStatuses.PENDING]: ["fas", "question-circle"]
  }, status)
}

function getActivationStatusClass (status) {
  return get({
    [activationStatuses.ACTIVE]: "text-green",
    [activationStatuses.INACTIVE]: "text-red",
    [activationStatuses.PENDING]: "text-danger"
  }, status)
}

function getMergedRightsholderFieldList (type) {
  let commonFields = [
    "name",
    "resoundId",
    "nationalities",
    "dateOfMerge"
  ]

  return get({
    [mergedRightsHolderTypes.MAKER]: commonFields.concat(["agent"]),
    [mergedRightsHolderTypes.PERFORMER]: commonFields.concat(["ipn", "dateOfBirth"]),
  }, type)
}

function getCleanDate (date) {
  let cleaned = date == null ? null : moment(date).format("YYYY-MM-DD")
  return cleaned
}

const conflictModes = {
  EDIT: "edit",
  SOLVE: "solve",
  VIEW: "view",
  ADJUSTMENT: "adjustment",
}

const logActionTypes = {
  ADDITION: "1",
  CHANGE: "2",
  DELETION: "3",
  LOGIN: "4",
  CARVEOUT: "5",
  ADD_SHARE: "6",
  EDIT_SHARE: "7",
}

const logFormat = {
  UNKNOWN: "1",
  MUSIC_MASTER: "2",
  SELECTOR: "3",
  EXCEL:"4",
  WORD: "5",
  OTHER: "6"
}

const distributionStatuses = {
  PENDING: 1,
  READY: 2,
  DISTRIBUTED: 3
}

const mandateType = {
  FEATURED: 1,
  NON_FEATURED: 2,
  ANY: 3
}

const realPaymentsAction = {
  INITIAL_STATUS: 1,
  RELEASED: 2,
  REFUND_UNDISTRIBUTED: 3
}

const realPaymentsSyncStatus = {
  PENDING: 1,
  READY_FOR_SYNC: 2,
  WAITING_FOR_SYNC: 3,
  SUCCESS: 4,
  Failed: 5
}

const xlsxMaxCount = 200000

const highlightDuration = 5000

const openYears = 6

const reproductionLogsTariffs = {
  EXCLUSIVE_LICENCE_AUDIO: "LicA",
  EXCLUSIVE_LICENCE_VIDEO: "LicV",
  BROADCAST_MECHANICAL: "BMT",
  CBC_REPRODUCTION: "CBCR",
}

const payeeTypes = {
  DA: "direct_assignor",
  SS: "sister_society",
  MC: "member_collective",
}

const activityTypes = {
  PD: "payment_due",
  PH: "payment_hold",
  DH: "distribution_hold",
}

const userActionNotifications = {
  MANDATES: {
    type: "user_action.mandate.status",
    action_identifier: "rights_holder_id"
  },
  USER_ACTION: {
    type: "user_action.status",
    action_identifier: "user_action_id"
  },
  LINEUP: {
    type: "user_action.lineup.status",
    action_identifier: "recording_id"
  },
  UNIQUE_USAGE: {
    type: "user_action.unique_usage.status",
    action_identifier: "unique_usage_id"
  },
  OWNERSHIP: {
    type: "user_action.ownership.status",
    action_identifier: "recording_id"
  },
  ELIGIBILITY_CONFLICT: {
    type: "user_action.recording_eligibility_conflict.status",
    action_identifier: "conflict_id"
  },
  LINEUP_ADJUSTMENT: {
    type: "user_action.lineup_negative_adjustment.status",
    action_identifier: "recording_id"
  },
  OWNERSHIP_ADJUSTMENT: {
    type: "user_action.ownership_negative_adjustment.status",
    action_identifier: "recording_id"
  },
  ISRC_CONFLICT: {
    type: "user_action.isrc.status",
    action_identifier: "recording_id"
  },
  IPN_CONFLICT: {
    type: "user_action.ipn.status",
    action_identifier: "performer_id"
  },
}

const userActionNotificationStatuses = {
  OPEN: "open",
  RELEASE: "release",
  SOLVED: "resolve",
}

const commercialRadioTariff = "1A"

const genreTypes = {
  CLASSICAL: "C",
  POP: "P",
  JAZZ: "J",
}

const linkTypes = {
  RECORDING: "recording",
  RIGHTS_HOLDER: "rights-holder"
}

const tokenCookieName = "accessToken"

const matchingTypes = {
  REPERTOIRE_MATCHING: "1",
  USAGE_MATCHING: "2",
}

const matchingActionTypes = {
  REVIEW: "1",
  MATCH: "2",
  CREATE: "3",
}

const filterTypes = {
  TEXT: "text",
  SELECT: "select",
  MULTI_SELECT: "multi-select",
  SELECT_SEARCH: "select-search",
  DATE_RANGE: "date-range",
  MONTH_YEAR: "month-year",
  YEAR_RANGE: "year-range",
  TIME_RANGE: "time-range",
  NUMBER_RANGE: "number-range",
  CUSTOM: "custom",
  BOOLEAN: "boolean",
  NUMBER_RANGE_NO_SLIDER: "number-range-no-slider",
}

const eligibilityLevels = [
  {
    text: "All",
    value: null
  },
  {
    text: "One",
    value: 1
  },
  {
    text: "Two",
    value: 2
  },
  {
    text: "Three",
    value: 3
  }
]


const distributionEligibilitySchemes = {
  ROME: "ROME",
  WPPT: "WPPT",
  CUSMA: "CUSMA",
}

const eligibilityOptions = [
  {
    text: "ROME",
    value: distributionEligibilitySchemes.ROME
  },
  {
    text: "WPPT",
    value: distributionEligibilitySchemes.WPPT
  },
  {
    text: "CUSMA",
    value: distributionEligibilitySchemes.CUSMA
  }
]
const eligibilityParams = [
  {
    text: "NR",
    value: "NR"
  },
  {
    text: "WPPT",
    value: "WPPT"
  },
  {
    text: "CUSMA",
    value: "CUSMA"
  },
  {
    text: "NR & WPPT",
    value: "NR_WPPT"
  }
  // {
  //   text: "PC Maker",
  //   value: "PC_MAKER"
  // },
  // {
  //   text: "PC Performer",
  //   value: "PC_PERFORMER"
  // }
]

const performerCategoryTypes = {
  FEATURED: "featured",
  NON_FEATURED: "non featured",
}

const userPreferenceNames = {
  MATCHING_ACTIONS_SHOW_LANDING_DATA: "matchingActionsShowLandingData",
  MATCHING_ACTIONS_HIGHLIGHT_DIFFERENCES: "matchingActionsHighlightDifferences",
  MERGE_REQUESTS_HIGHLIGHT_DIFFERENCES: "mergeRequestsHighlightDifferences",
  MERGE_REQUESTS_SHOW_PARENT_DATA: "mergeRequestsShowParentData",
  PAYMENTS_HISTORY_FILTER: "payment-history",
  LANDING_RECORDINGS_LIST_FILTER: "landing-recordings",
  RECORDINGS_LIST_FILTER: "recordings",
  USAGES_FILTER: "recording-usages",
  REPERTOIRE_FILES_LIST_FILTER: "repertoire",
  CONSOLIDATED_USAGES_FILTER: "consolidated-usages",
  USAGE_MATCHING_FILTER: "usage-matching",
  USAGE_FILE_LIST_FILTER: "usagesfiles",
  USAGE_LIST_FILTER: "usages",
  RECORDING_MERGE_REQUESTS_FILTER: "recording-merge-requests",
  REPERTOIRE_RECORDING_FILTER: "repertoireRecordingTable",
  REPERTOIRE_PERFORMER_FILTER: "repertoirePerformerTable",
  REPERTOIRE_MAKER_FILTER: "repertoireMakerTable",
  RIGHTSHOLDER_PERFORMER_FILTER: "rightsholderPerformerTable",
  RIGHTSHOLDER_MAKER_FILTER: "rightsholderMakerTable",
  PAYMENT_RECORDING_FILTER: "paymentRecordingTable",
  PAYMENT_PERFORMER_FILTER: "paymentPerformerTable",
  PAYMENT_MAKER_FILTER: "paymentMakerTable",
  SRA_OWNER_FILTER: "sraOwnerTable",
  DISTRIBUTION_LIST_FILTER: "distributionListTable",
}

const lineupConflictReasons = {
  INCONSISTENT_GENRES: "inconsistent_genres",
  DUPLICATE_PERFORMER: "duplicate_performer",
  INCONSISTENT_NUMBER_OF_PERFORMERS: "inconsistent_number_of_performers",
  EXTRA_PERFORMERS: "extra_performers"
}

const mergeRequestsTypes = {
  RECORDING: "recording",
  RIGHTS_HOLDER: "rightsHolder",
}

const distributionCollegeTypes = {
  BOTH: "maker_and_performer",
  PERFORMER: "performer",
  MAKER: "maker",
}

const distributionReserveValueTypes = {
  PERCENT: "percent",
  MONEY: "amount",
}

const distributionMethodologies = {
  STANDARD: "standard",
  INTERNATIONAL: "international",
  CONNECT: "connect",
}

const connectDistributionMethodologies = [
  "track_specific",
  "open",
  "one_time_payment"
]

const distributionVppTypes = {
  OFFICIAL: "official",
  PLACEHOLDER: "placeholder",
}

const canadaAlpha3Code = "CAN"

const canadaCurrencyCode = "CAD"

const documentationLinks = {
  RESOUND_USER_GUIDE_URL: "https://docs.google.com/document/d/1BRQqPAi0noLBRZCFiGim5UTKawr6FV9VC7xA-bKe1D4/edit?tab=t.0#heading=h.shkiie88g862",
  MC_USER_GUIDE_URL: "https://docs.google.com/document/d/1WYPZVceWKcbkcJSWe9llRMWL-4TeNzoA5E2Dv4TV-f8/edit#heading=h.shkiie88g862",
  TECHNICAL_DOCUMENTATION_URL: "https://www-internal-dev.crescendo.resound.ca/api/docs/index.html"
}

const helpDeskMailbox = "mailto:helpdesk@resound.ca"

export {
  CONNECT,
  RESOUND,
  CHUNK_SIZE_UUIDS,
  reconciliationStatuses,
  UserActionTypeCodes,
  actionsTabs,
  activationStatuses,
  activityTypes,
  adjustmentTypes,
  canadaAlpha3Code,
  canadaCurrencyCode,
  commercialRadioTariff,
  conflictModes,
  conflictTypeCodes,
  connectDistributionMethodologies,
  connectUserRole,
  distributionCollegeTypes,
  distributionEligibilitySchemes,
  distributionMethodologies,
  distributionReserveValueTypes,
  distributionVppTypes,
  distributionStatuses,
  documentationLinks,
  duplicatedTypes,
  eligibilityOptions,
  eligibilityParams,
  eligibilityLevels,
  exportFormats,
  fileStatuses,
  filterTypes,
  invalidatedStatus,
  getActionTypeDisplay,
  getActivationStatusClass,
  getActivationStatusIcon,
  getCleanDate,
  getMergedRightsholderFieldList,
  genreTypes,
  getRecordingActionTypeDisplay,
  getRecordingTypeDisplay,
  getUserActionStatusDisplay,
  helpDeskMailbox,
  highlightDuration,
  inlineRowStatus,
  landingDataStatuses,
  landingRecordingReconciliationStatuses,
  landingStatuses,
  latestPeriods,
  lineupConflictReasons,
  linkTypes,
  logActionTypes,
  logFormat,
  mandateScopes,
  mandateType,
  metadataConflictSourceTypes,
  metadataConflictTypeCodes,
  matchingActionTypes,
  matchingTypes,
  mergeRequestsTypes,
  openYears,
  partyNameTypes,
  partyTypes,
  payeeTypes,
  paymentRightsholderTypes,
  performerCategoryTypes,
  perPageItemsSm,
  perPageItemsMd,
  perPageItemsLg,
  getPerPageItemDefaults,
  realPaymentsAction,
  realPaymentsSyncStatus,
  recordingDetailTabs,
  reproductionLogsTariffs,
  resoundUserRoles,
  rhBatchTypes,
  rightsholderDetailTabs,
  rightsholderTypes,
  salesforceCommunitiesUserRole,
  sisterSocietyUserRole,
  soundRecordingTypes,
  sourceTypes,
  statementConflictTypeCodes,
  tokenCookieName,
  junkStatuses,
  usageMatchingStatuses,
  usageModalTypes,
  usageTypes,
  userActionStatuses,
  userActionTypes,
  userTypes,
  userActionNotificationStatuses,
  userActionNotifications,
  userPreferenceNames,
  workSources,
  xlsxMaxCount,
}
