<template>
  <b-nav class="menu" @click="collapse(false)">
    <b-navbar-nav v-show="canOverrideUserRole" class="justify-content-center">
      <b-nav-item v-if="isCollapsed">
        <div class="sidebar-item mb-2 justify-content-center">
          <div class="icon-wrapper">
            <b-img :src="require('@/assets/change_user.svg')"/>
          </div>
        </div>
      </b-nav-item>
      <b-nav-form v-else class="form ml-3 mr-3 mb-1">
        <b-form-select
            class="mb-3"
            v-model="selectedMember"
            :options="mcCollectivesOptions"
        ></b-form-select>
        <b-form-select
            class="mb-3"
            v-model="selectedRole"
            :options="userRoleOptions"
        ></b-form-select>
        <b-button class="btn-outline-red" @click="setSelectedMember">{{ $t("apply") | capitalize }}</b-button>
      </b-nav-form>
      <div class="split-line mb-3"></div>
    </b-navbar-nav>
    
    <!-- User Menu -->
    <b-nav-item  v-if="userFetched" @click="toggleExpanded('userMenu', $event)" class="mb-2">
      <div class="sidebar-item" :class="{'justify-content-center':isCollapsed}">
        <div class="icon-wrapper" :class="{ active: isActiveSection() && isCollapsed, 'ml-3 mr-3': isCollapsed }">
          <fa-icon size="lg" :icon="['far', 'user-circle']" />
        </div>
        <div :class="{hide : isCollapsed}" class="action">
          <span>{{ user.email }}</span>
          <div class="icon-wrapper pl-1 mr-1">
            <fa-icon v-if="expanded === 'userMenu'" size="lg" :icon="['fa', 'angle-right']" />
            <fa-icon v-else size="lg" :icon="['fa', 'angle-down']" />
          </div>
        </div>
      </div>
      <b-collapse :visible="expanded === 'userMenu' ? true : false" :class="{'ml-3 mt-3': !isCollapsed}">
        <b-nav-item class="mb-2" :to="{
            name: 'user-profile',
            params: {sidebar: true},
          }">
          <div class="sidebar-item" :class="{ active: $route.name === 'user-profile' }">
            <div class="icon-wrapper">
              <fa-icon size="lg" :icon="['fa', 'id-card']"/>
            </div>
            <span class="ml-2 section-name">My Profile</span>
          </div>
        </b-nav-item>
        <b-nav-item class="mb-2" v-if="permissions.pages[Pages.UsersList].canRead" :to="{
            name: 'users',
            params: {sidebar: true},
            query: { 'member': user.selectedMember ? user.selectedMember.uuid : user.memberCollective.uuid }
          }">
          <div class="sidebar-item" :class="{ active: $route.name === 'users' }">
            <div class="icon-wrapper">
              <fa-icon size="lg" :icon="['fas', 'users']"/>
            </div>
            <span class="ml-2 section-name">People</span>
          </div>
        </b-nav-item>
        <b-nav-item class="mb-2" @click="logout()">
          <div class="sidebar-item">
            <div class="icon-wrapper">
              <fa-icon size="lg" :icon="['fas', 'power-off']"/>
            </div>
            <span class="ml-2 section-name">Logout</span>
          </div>
        </b-nav-item>
      </b-collapse>
    </b-nav-item>
  </b-nav>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Pages } from "@/utils/pages"
import { sisterSocietyUserRole } from "@/constants"

export default {
  name: "NavUserMenu",
  data () {
    return {
      selectedMember: null,
      selectedRole: null,
      Pages,
    }
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    expanded: {
      type: String,
    },
    collapse: {
      type: Function,
    },
    toggleExpanded: {
      type: Function,
    }
  },
  methods: {
    ...mapActions("user", ["logout"]),
    ...mapMutations("alert", ["success", "error"]),
    ...mapActions("consts", ["getUserRoles"]),
    setSelectedMember () {
      let data = {
        selected_member: this.selectedMember,
        selected_role: this.selectedRole,
      }
      this.$api.users.usersSetMember(data).then(response => {
        let msg = `Success setting member.${response.statusText}`
        this.success(msg)
        this.$router.go()
      }).catch((error) => {
        let msg = `Error setting member. ${error.response}`
        this.error(msg)
      })
    },
    isActiveSection () {
      return (this.$route.name === "user-profile" || this.$route.name === "users") ? true : false
    }
  },
  computed: {
    ...mapState("consts", ["memberCollectives"]),
    ...mapGetters("user", ["canOverrideUserRole"]),
    ...mapState("user", ["user", "permissions"]),
    ...mapGetters("user", ["userFetched"]),
    ...mapState("consts", ["userRoles"]),
    mcCollectivesOptions () {
      return this.memberCollectives.filter(d => d.country === "CA").map(x => ({ "text": x.name, "value": x.uuid }))
    },
    userRoleOptions () {
      return this.userRoles.filter(r => r.name !== sisterSocietyUserRole).map(r => ({ text: r.name, value: r.id }))
    }
  },
  mounted () {
    if (this.user.email && this.canOverrideUserRole) {
      this.selectedMember = this.user.selectedMember ? this.user.selectedMember.uuid : this.user.memberCollective.uuid
      this.selectedRole = this.user.selectedRole || this.user.role
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu {
    flex-direction: column !important;
    flex-wrap: nowrap;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    .form-inline{
      flex-direction: column;
      padding-top: 3px;
      padding-bottom: 5px;
      select {
        width: 250px;
      }
      button {
        align-self: flex-end;
      }
    }
    .sidebar-item {
      display: flex;
      align-items: center;
      font-size: 16px;
      .section-name {
        font-size: 14px !important;
      }
      &:hover {
        color: $gray-light;
      }
    }
    .action {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .icon-wrapper {
      min-width: 30px;
      text-align: center;
      position: relative;
    }
    .nav-link {
      padding: 0.5rem;
    }
    .split-line {
      border: 1px solid $gray-darker;
      margin: 0 1rem;
    }
    .custom-select {
      font-size: 11px;
      border: 1px solid $gray-light;
      background-color: $gray;
      // background-color: #EFF0F1;
      color: $primary;
      box-shadow: none;
    }
    .btn-outline-red{
      font-size: 11px;
    }
    .active {
      color: $red !important;
    }
    .hide {
      display: none;
    }
  }
</style>