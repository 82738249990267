<template>
  <b-form-group
      :label-cols="label ? labelCols : null"
      :label="label"
      class="mb-0"
  >
    <div v-if="!selectedObject">
      <form-field
        :list="`search-list-${id}`"
        :id="`search-${id}`"
        :placeholder="placeholder"
        v-model="realSearchValue"
        v-bind="$attrs"
        debounce="500"
        autocomplete="off"
        v-if="!selectedObject"
        :rules="rules"
        :disabled="disabled"
        :spinner="true"
        :loading="loading"
        ref="search"
      />
      <b-form-datalist :id="`search-list-${id}`" :value-field="displayField" :options="searchOptions"/>
      <b-tooltip :target="`search-${id}`" triggers="manual" :show.sync="resultsTooltip" placement="bottom">No results found</b-tooltip>
    </div>
    <div v-else class="d-flex align-items-center">
      <b-input-group>
        <b-form-input readonly v-if="showValue" :value="`(${selectedObject.value}) ${selectedObject.text}`"/>
        <b-form-input readonly v-else :value="`${selectedObject.text}`"/>
        <b-input-group-append>
          <span class="input-group-text" v-if="!disabled">
            <fa-icon
                class="mr-0 clickable"
                @click="deleteSelected()"
                :icon="['fa', 'times']"
            />
          </span>
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-form-group>
</template>

<script>

export default {
  name: "SelectSearch",
  props: {
    searchValue: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    listEndpoint: {
      type: Function,
      required: true,
    },
    searchName: {
      type: String,
      required: true,
    },
    excludeName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      required: true
    },
    exclude: {
      type: Array,
      default: null,
    },
    params: {
      type: Object,
    },
    textField: {
      type: String,
    },
    textFunction: {
      type: Function,
    },
    valueField: {
      type: String,
    },
    displayField: {
      type: String,
      default: "value",
    },
    labelCols: {
      type: String,
      default: "auto",
    },
    showValue: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // must be included in props
    value: {
      type: null
    }
  },
  methods: {
    focus () {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    },
    deleteSelected () {
      this.searchOptions = []
      this.selectedObject = null
      this.realSearchValue = ""
    },
    searchBackend (value) {
      if (value) {
        this.loading = true
        this.listEndpoint({ ...this.params, [this.searchName]: value }).then(result => {
          this.loading = false
          this.searchOptions = result.data.results
            .filter(r => this.exclude && this.exclude.length ? !this.exclude.includes(r[this.excludeName]) : true)
            .map(r => { return { "text": this.textFunction ? this.textFunction(r) : r[this.textField], "value": r[this.valueField], "id": r.id, "resoundId": r.resoundId }})
          this.selectedObject = this.searchOptions.length === 1 && this.matchesResult(value, this.searchOptions[0]) ? this.searchOptions[0] : null
          this.resultsTooltip = this.searchOptions.length === 0
        }).catch(
          this.loading = false
        )
      } else {
        this.searchOptions = []
        this.resultsTooltip = false
      }
    },
    matchesResult (value, searchOption) {
      return (value === searchOption.text || value === searchOption.value || value === searchOption.resoundId || value === searchOption.id)
    }
  },
  computed: {
    realSearchValue: {
      get () {
        return this.internalSearchValue !== null ? this.internalSearchValue : this.searchValue
      },
      set (newVal) {
        this.internalSearchValue = newVal
        return newVal
      }
    }
  },
  data () {
    return {
      resultsTooltip: false,
      internalSearchValue: null,
      selectedObject: null,
      searchOptions: [],
      loading: false,
      id: crypto.randomUUID()
    }
  },
  watch: {
    internalSearchValue () {
      this.searchBackend(this.internalSearchValue)
    },
    // Handles internal model changes.
    selectedObject (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.selectedObject = newVal
      if (!newVal) {
        this.internalSearchValue = ""
      }
    }
  },
}
</script>
