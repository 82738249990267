<!-- Based On: https://codesandbox.io/s/validation-components-bootstrapvue-usdwv -->
<template>
  <ValidationProvider :vid="$attrs.name" :name="$attrs.name" :rules="rules" v-slot="{ errors, classes }">
    <transition name="fade" mode="out-in">
      <b-form-group
        v-bind="$attrs"
        :label="groupLabel"
      >
        <label :for="$attrs.name" v-if="$attrs.label">
          {{ $attrs.label | capitalize }}: <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <date-picker
          v-model="innerValue"
          v-bind="$attrs"
          value-type="format"
          :class="classes"
          input-class="form-control"
        ></date-picker>
        <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </transition>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 11pt;
  }

  ::v-deep .mx-datepicker {
    width: 100%;
    &.invalid { .mx-input { border-color: var(--danger); } }
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "DateField",
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    groupLabel: {
      type: String,
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  computed: {
    isRequired () {
      return this.rules ? this.rules.split("|").includes("required") : false
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
