import { render, staticRenderFns } from "./TableAction.vue?vue&type=template&id=f280dcde&scoped=true&"
import script from "./TableAction.vue?vue&type=script&lang=js&"
export * from "./TableAction.vue?vue&type=script&lang=js&"
import style0 from "./TableAction.vue?vue&type=style&index=0&id=f280dcde&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f280dcde",
  null
  
)

export default component.exports