<template>
  <div class="h-100">
    <div class="d-flex h-100">
      <side-bar id="sidebar" class="fixed" :class="this.isCollapsed ? 'sb-collapsed' : 'sb-expanded'"
        @collapsed="onCollapsed" />
      <b-container fluid :class="this.isCollapsed ? 'content-collapsed' : 'content-expanded'">
        <slot></slot>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoggedInLayout",
  data () {
    return {
      isCollapsed: true,
    }
  },
  methods: {
    onCollapsed (value) {
      this.isCollapsed = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-collapsed {
    margin: 0 0 0 60px;
    width: calc(100% - 60px);
    padding: 2px 15px 8px 10px !important;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .content-expanded {
    margin: 0 0 0 310px;
    width: calc(100% - 310px);
    padding: 2px 15px 8px 10px !important;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .sb-expanded {
  width: 306px !important;
  }

  .sb-collapsed {
    width: 59px !important;
  }
  
  .fixed {
    position: fixed;
  }
</style>
