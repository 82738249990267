<template>
  <validation-observer ref="observer" tag="fieldset">
    <b-card-body>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="calculateVpp"
            ref="calculateVpp"
            :label="$t('choose_vpp_method')"
            rules="required"
            >
              <b-form-radio-group required v-model="distribution.calculateVpp">
                <b-form-radio :value="false">{{ $t("manual") | capitalize }}</b-form-radio>
                <b-form-radio :value="true">{{ $t("automatic") | capitalize }}</b-form-radio>
              </b-form-radio-group>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.calculateVpp === false && (distribution.college === distributionCollegeTypes.MAKER || distribution.college === distributionCollegeTypes.BOTH)">
        <b-col>
          <distribution-form-group
            vid="vppMaker"
            ref="vppMaker"
            :label="$t('maker_vpp')"
            label-cols="2"
            rules="double|positive"
            v-slot="{ state }"
            >
            <b-form-input number v-model="distribution.vppMaker" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="distribution.calculateVpp === false && (distribution.college === distributionCollegeTypes.PERFORMER || distribution.college === distributionCollegeTypes.BOTH)">
        <b-col>
          <distribution-form-group
            vid="vppPerformer"
            ref="vppPerformer"
            :label="$t('performer_vpp')"
            label-cols="2"
            rules="double|positive"
            v-slot="{ state }"
            >
            <b-form-input number v-model="distribution.vppPerformer" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="vppStatus"
            ref="vppStatus"
            :label="$t('vpp_type')"
            rules="required"
            label-cols="2"
            v-slot="{ state }"
            >
            <b-form-select v-model="distribution.vppStatus" :options="vppTypeOptions" :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </validation-observer>
</template>

<script>
import { distributionCollegeTypes, distributionVppTypes } from "@/constants"
import DistributionFormGroup from "@/components/DistributionFormGroup"
import { ValidationObserver } from "vee-validate"
import { capitalize } from "lodash"
import { distributionSectionMixin } from "@/utils/mixins"

export default {
  name: "VPPSection",
  components: { DistributionFormGroup, ValidationObserver },
  mixins: [ distributionSectionMixin ],
  props: {
    distribution: Object
  },
  data () {
    return {
      distributionCollegeTypes,
      vppTypeOptions: [
        {
          text: capitalize(this.$t("official")),
          value: distributionVppTypes.OFFICIAL,
        },
        {
          text: capitalize(this.$t("placeholder")),
          value: distributionVppTypes.PLACEHOLDER,
        },
      ]
    }
  },
  computed: {
    requiredFields () {
      return ["calculateVpp"]
    }
  }
}
</script>
