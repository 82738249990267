<template>
  <div class="d-flex flex-column align-items-center justify-content-center h-100">
    <fa-icon size="5x" class="text-muted" :icon="['fas', 'ban']"/>
    <h1 class="mt-4 text-muted">Forbidden</h1>
  </div>
</template>
  
<script>
export default {
  name: "Forbidden",
}
</script>
