<template>
  <div>
    <b-row>
      <div class="parent">
        <div class="check-label">
          <p :class="getLabelClass()">inf</p>
          <b-form-checkbox
            :class="getCheckboxClass()"
            v-model="selected"
            @change="checkedInfinite()"
            :disabled="disabled"
          ></b-form-checkbox>
        </div>

        <b-form-group class="elig-date">
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            :placeholder="formattedDate"
            v-model="dateValue"
            locale="en-ZA"
            :max="getMaxDate()"
            :min="getMinDate()"
            :disabled="disabled"
          ></b-form-datepicker>
        </b-form-group>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import moment from "moment"

export default {
  name: "ParamDates",
  computed: {
    ...mapState("copyrights", ["adding", "tariffs"]),
    ...mapGetters("copyrights", ["getParamData"]),
    currentParam () {
      return this.getParamData({
        tariffId: this.param.tariffId,
        paramId: this.param.paramId,
      })
    },
    formattedDate () {
      let currentDate = moment
        .utc(this.currentParam[this.dateType])
        .format("YYYY/MM/DD")
      if (currentDate === "Invalid date") {
        return "No date selected"
      } else if (this.selected) {
        return "No date selected"
      } else {
        return currentDate
      }
    },
  },
  methods: {
    ...mapMutations("copyrights", [
      "setChangeFlag",
      "setInfinite",
      "setParamDate",
    ]),
    checkedInfinite () {
      if (this.dataLoaded) {
        this.selected = !this.selected
        let newDate = this.selected ? null : this.dateValue
        this.dateValue = newDate

        this.setParamDate({
          tariffId: this.param.tariffId,
          paramId: this.param.paramId,
          dateType: this.dateType,
          dateValue: newDate,
        })
      }
    },
    getCheckboxClass () {
      if (this.dateType === "startDate") {
        return "infinite-checkbox-start"
      } else {
        return "infinite-checkbox-end"
      }
    },
    getLabelClass () {
      if (this.dateType === "startDate") {
        return "inf-label-start"
      } else {
        return "inf-label-end"
      }
    },
    setDate () {
      if (this.dataLoaded) {
        this.selected = false
        this.setParamDate({
          tariffId: this.param.tariffId,
          paramId: this.param.paramId,
          dateType: this.dateType,
          dateValue: this.dateValue,
        })
      }
    },
    getMaxDate () {
      switch (this.dateType) {
      case "startDate":
        return moment(this.currentParam.endDate).subtract(1, "days").toDate()  
      case "endDate":
        null
      }
    },
    getMinDate () {
      switch (this.dateType) {
      case "startDate":
        return moment("1998-01-01").add(1, "days").toDate()  
      case "endDate":
        return moment(this.currentParam.startDate).add(1, "days").toDate()
      }
    },
  },
  data () {
    return {
      dateValue: "",
      initialValue: "",
      selected: false,
      dataLoaded: false,
      resetting: false,
    }
  },
  props: {
    param: Object,
    tariffId: Number,
    paramId: Number,
    dateType: String,
    disabled: Boolean
  },
  mounted () {
    let parameter = this.getParamData({
      tariffId: this.param.tariffId,
      paramId: this.param.paramId,
    })

    if (parameter[this.dateType] == null || parameter[this.dateType] === "") {
      this.selected = true
    }

    let date = moment.utc(parameter[this.dateType]).toISOString()
    let cleanDate = date === "Invalid value" ? "" : date
    this.dateValue = cleanDate
    this.initialValue = cleanDate

    this.$nextTick().then(() => {
      this.dataLoaded = true
    })
  },
  watch: {
    param: {
      handler () {
        if (this.dataLoaded && !this.adding) {
          let parameter = this.getParamData({
            tariffId: this.param.tariffId,
            paramId: this.param.paramId,
          })

          this.dateValue = parameter[this.dateType]
          this.selected = parameter[this.dateType] == null
        }
      },
      deep: true,
    },
    dateValue: {
      handler () {
        if (this.dataLoaded) {
          this.setChangeFlag()
          this.setDate()
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.parent {
  position: relative;

  .infinite-checkbox-start {
    position: absolute;
    left: -45px;
    top: 7px;
  }

  .infinite-checkbox-end {
    position: absolute;
    right: -32px;
    top: 7px;
  }
}

.elig-date {
  width: 200px;
}

.check-label {
  position: relative;

  .inf-label-start {
    position: absolute;
    top: 7px;
    left: -25px;
  }

  .inf-label-end {
    position: absolute;
    top: 7px;
    right: -47px;
  }
}
</style>
