<template>
  <list-layout
      :no-counts="true"
      :no-search="true"
  >
    <template v-slot:beforeFilters>
      <b-row>
        <b-col>
          <filter-builder
            :id="userPreferenceNames.PAYMENTS_HISTORY_FILTER"
            :apply-filters="applyFilters"
            :reset-filters="resetFilters"
            :filters-pending="filtersPending"
            :filters-applied="filtersApplied"
            :filters="getFiltersWithOptions()"
            @filter="onFilter"
          />
        </b-col>
      </b-row>
    </template>

    <template v-slot:table>
      <t-table
        ref="payments"
        :id="recordingUUID ? 'recording-payments' : 'rights-holder-payments'"
        :columns="columns"
        :options="options"
        :limit="limit"
        @loaded="onLoaded"
        @loading="onLoading"
        @pagination="onPagination"
        @sorted="onSorted"
      >
        <template v-slot:counts>
          <pagination
            class="smaller p-0"
            :page="page"
            :count="count"
            :loading="loading"
            reference="payments"
            v-model="limit"
            @limit="onLimit"
          />
        </template>

        <template v-slot:merge>
          <b-form-group v-if="rightsholderId">
            <b-form-radio-group
              v-model="filters.aggregated.value"
              buttons
              button-variant="outline-primary"
              name="radios-btn-outline"
            >
              <b-form-radio :value="false">
                {{ $t("by_transaction") | capitalize }}
              </b-form-radio>
              <b-form-radio :value="true">
                {{ $t("by_distribution") | capitalize }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>

        <template v-slot:distributionPeriod="{row}">
          {{ row.distributionPeriodStart + " - " + row.distributionPeriodEnd }}
        </template>
        <template v-slot:activityType="{row}">
          {{ capitalize($t(activityTypes[row.activityType])) }}
        </template>
        <template v-slot:recordingResoundId="{ row }">
          <database-link :type="linkTypes.RECORDING" :item-id="row.recordingId">{{ row.recordingResoundId }}</database-link>
        </template>
      </t-table>
    </template>
  </list-layout>
</template>
<script>
import { activityTypes, filterTypes, linkTypes, payeeTypes, perPageItemsSm, userPreferenceNames } from "@/constants"
import { capitalize, get } from "lodash"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import DatabaseLink from "@/components/DatabaseLink.vue"
import FilterBuilder from "@/components/FilterBuilder"
import Pagination from "@/components/Pagination"
import { mapState } from "vuex"
import moment from "moment"

export default {
  name: "PaymentsHistory",
  components: {
    FilterBuilder,
    DatabaseLink,
    Pagination,
  },
  mixins: [datePickerMixin, listRouteMixin],
  props: {
    recordingUUID: String,
    rightsholderId: String,
  },
  computed: {
    ...mapState("consts", ["memberCollectives"]),
    ...mapState("consts", ["tariffs"]),
    payeeTypeOptions () {
      return Object.keys(this.payeeTypes).map(key => {
        return {
          text: capitalize(this.$t(this.payeeTypes[key])),
          value: key,
        }
      })
    },
    tariffOptions () {
      let tariffOptions = this.tariffs.map(c => {
        return {
          text: c.text,
          value: c.code,
        }
      })
      return tariffOptions
    },
    memberCollectiveOptions () {
      return this.memberCollectives.map(x => ({
        text: x.name,
        value: x.uuid,
      }))

    },
    distributionDate: {
      get () {
        return [
          moment(this.filters.distributionFrom.value, this.$data.filters.distributionFrom.format, true).toDate(),
          moment(this.filters.distributionTo.value, this.$data.filters.distributionTo.format, true).toDate()
        ]
      },
      set (val) {
        this.changeFilter("distributionFrom", this.parseDate(val[0]))
        this.changeFilter("distributionTo", this.parseDate(val[1]))
        if (val[0] || val[1]) {
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
          this.changeFilter("userTimezone", userTimezone)
        } else {
          this.changeFilter("userTimezone", this.filters.userTimezone.defaultValue)
        }
      }
    }
  },
  methods: {
    recomputeColumns () {
      let rightsHolderColumns = [
        "rightsHolderType",
        "rightsHolder",
      ]
      let recordingColumns = [
        "recordingResoundId",
        "recordingArtist",
        "recordingTitle",
        "recordingIsrc",
      ]
      if (!this.filters || !this.filters.aggregated.value) {
        return [
          "payeeType",
          "payee",
          "sisterSociety",
          "tariff",
          ...this.recordingUUID ? rightsHolderColumns : recordingColumns,
          "activityType",
          "distribution",
          "distributionDate",
          "distributionPeriod",
          "holdReason",
          "split",
          "amount",
        ]
      } else {
        return [
          "payeeType",
          "payee",
          "activityType",
          "distribution",
          "distributionPeriod",
          "distributionDate",
          "tariff",
          "amount",
        ]
      }
    },
    getData () {
      this.$refs.payments.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.loading = false
      this.columns = this.recomputeColumns()
    },
    onFilter (name, value) {
      if (name === "dateRange") {
        this.dateRange = value ? value : ["", ""]
      } else if (name === "distributionPeriod") {
        this.distributionDate = value ? value : ["", ""]
      } else if (name === "rightsHolderId") {
        if (value) {
          this.changeFilter(name, value.id)
          this.changeFilter("rightsholder_rs_id", value.resoundId)
        } else {
          this.changeFilter(name, null)
          this.changeFilter("rightsholder_rs_id", null)
        }
        if (!this.causeFiltersPending) {
          this.filtersPending = false
          this.causeFiltersPending = true
        }
      } else if (name === "recordingId") {
        if (value) {
          this.changeFilter(name, value.id)
        } else {
          this.changeFilter(name, null)
        }
      }
    },
    onPagination (page) {
      this.page = page
      this.updateRouterPagination("page", page)
    },
    capitalize,
    getFiltersWithOptions () {
      this.filters.memberCollective.options = this.memberCollectiveOptions
      this.filters.payeeType.options = this.payeeTypeOptions
      this.filters.rightsHolderType.options = this.rightsholderOptions
      this.filters.tariff.options = this.tariffOptions
      this.filters.sisterSociety.options = this.memberCollectiveOptions
      this.filters.amount.min = this.minAmount
      this.filters.amount.max = this.maxAmount
      return this.filters
    }
  },
  data () {
    return {
      columns: this.recomputeColumns(),
      columnMode: "default",
      page: 1,
      count: 0,
      loading: false,
      limit: perPageItemsSm,
      payeeTypes: payeeTypes,
      linkTypes,
      userPreferenceNames,
      minAmount: null,
      maxAmount: null,
      filters: {
        payeeType: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("payee_type")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        memberCollective: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("payee")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        rightsHolderType: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("rights_holder_type")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          hidden: !!this.rightsholderId,
          options: [],
        },
        tariff: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("tariff")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        distributionPeriod: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("distribution_date")),
          type: filterTypes.DATE_RANGE,
          format: "YYYY-MM-DD",
          noWatch: true
        },
        dateRange: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("distribution_period")),
          type: filterTypes.DATE_RANGE,
          format: "YYYY-MM-DD",
          noWatch: true
        },
        distributionFrom: { value: null, defaultValue: null, hidden: true },
        distributionTo: { value: null, defaultValue: null, hidden: true },
        distribution: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("distribution"))
        },
        rightsHolderId: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          label: capitalize(this.$t("rights_holder")),
          placeholder: capitalize(this.$t("search_rightsholder")),
          excludeName: "id",
          valueField: "resoundId",
          textField: "name",
          searchName: "search",
          listEndpoint: this.$api.rightsholders.rightsholdersList,
          hidden: !!this.rightsholderId,
          noWatch: true,
        },
        activityType: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT,
          label: capitalize(this.$t("activity_type")),
          options: Object.entries(activityTypes).map(([k, v]) => { return { "value": k, "text": capitalize(this.$t(v)) }}),
          emptyValue: capitalize(this.$t("all"))
        },
        sisterSociety: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT,
          label: capitalize(this.$t("sister_society")),
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        holdReason: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("hold_reason")),
        },
        split: {
          value: null,
          defaultValue: null,
          type: filterTypes.NUMBER_RANGE,
          label: capitalize(this.$t("share")),
          min: 0,
          max: 1,
          step: 0.001,
        },
        amount: {
          value: null,
          defaultValue: null,
          type: filterTypes.NUMBER_RANGE,
          label: capitalize(this.$t("amount")),
          min: 0,
          max: 0,
          step: 0.01,
        },
        recordingId: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          label: capitalize(this.$t("recording")),
          placeholder: capitalize(this.$t("search_recording")),
          excludeName: "id",
          valueField: "resoundId",
          textFunction: (r => `${r.artist} - ${r.title}`),
          searchName: "fuzzy_search",
          listEndpoint: this.$api.repertoire.recordingsList,
          hidden: !!this.recordingUUID,
          noWatch: true,
        },
        aggregated: { value: false, defaultValue: false, hidden: true },
      },
      options: {
        sortable: [
          "payeeType",
          "sisterSociety",
          "rightsHolderType",
          "rightsHolder",
          "recordingResoundId",
          "recordingTitle",
          "recordingArtist",
          "recordingIsrc",
          "tariff",
          "activityType",
          "distribution",
          "holdReason",
          "split",
          "amount",
          "payee",
          "distributionDate",
          "distributionPeriod",
        ],
        headings: {
          payeeType: capitalize(this.$t("payee_type")),
          payee: capitalize(this.$t("payee")),
          sisterSociety: capitalize(this.$t("sister_society")),
          rightsHolderType: capitalize(this.$t("rights_holder_type")),
          rightsholder: capitalize(this.$t("rightsholder")),
          recordingResoundId: this.$t("resound_id"),
          recordingTitle: capitalize(this.$t("title")),
          recordingArtist: capitalize(this.$t("artist")),
          recordingIsrc: this.$t("isrc"),
          rightsHolder: capitalize(this.$t("rights_holder")),
          tariff: capitalize(this.$t("tariff")),
          activityType: capitalize(this.$t("activity_type")),
          distribution: capitalize(this.$t("distribution")),
          distributionDate: capitalize(this.$t("distribution_date")),
          distributionPeriod: capitalize(this.$t("distribution_period")),
          holdReason: capitalize(this.$t("hold_reason")),
          split: capitalize(this.$t("share")),
          amount: capitalize(this.$t("amount")),
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          this.$parent.$parent.minAmount = data.minAmount
          this.$parent.$parent.maxAmount = data.maxAmount
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          const recording_id = this.$parent.$parent.recordingUUID
          const rights_holder_id = this.$parent.$parent.rightsholderId
          const id_filter = recording_id ? { recording_id: recording_id, for: "recording" } : { rights_holder_id: rights_holder_id, for: "rights_holder" }
          queryParams = { ...id_filter, ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.payments.paymentsHistory(queryParams)
        }
      },
      activityTypes: activityTypes,
      causeFiltersPending: true,
    }
  },
  watch: {
    dateRange (newVal) {
      this.filters.dateRange.value = newVal
    },
    distributionDate (newVal) {
      this.filters.dateRange.value = newVal
    },
    "filters.aggregated.value": function (newValue) {
      this.changeFilter("aggregated", newValue, true)
    }
  },
  mounted () {
    let rightsHolderId = get(this.$route.query, "rightsholder_rs_id")
    if (rightsHolderId) {
      this.filters.rightsHolderId.searchValue = rightsHolderId
      this.causeFiltersPending = false
    }
    let recordingId = get(this.$route.query, "recording_id")
    if (recordingId) {
      this.filters.recordingId.searchValue = recordingId
    }
    this.updateTableSortIcons(this.$refs.payments.$children[0], true)
  }
}
</script>
