<template>
  <div>
    <b-row class="heading-container">
      <h3 class="ml-2">
        {{ $t("recording_merge_request") | startcase }}
      </h3>
    </b-row>
    <recording-merge-request-detail :merge-request-id="uuid" :always-show-parent-data="true"/>
  </div>
</template>
<script>
import RecordingMergeRequestDetail from "@/pages/UserActions/RecordingMergeRequestDetail.vue"

export default {
  name: "RecordingMergeRequestPage",
  components: { RecordingMergeRequestDetail },
  props: {
    uuid: String
  }
}
</script>
