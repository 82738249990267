<template>
  <list-layout
      :isChild="true"
      :title="$t('performer_lineups') | startcase"
      :count="userActionCounts.lineupConflictsCount"
      :apply-filters="applyFilters"
      :filters-pending="filtersPending"
      :reset-filters="resetFilters"
      :filters-applied="filtersApplied"
  >
    <template v-slot:search>
      <ValidationObserver ref="fuzzySearchForm">
        <form-field
          class="file-search"
          :placeholder="$t('search') | capitalize"
          v-model="filters.fuzzySearch.value"
          :enter-action="applyFilters"
          :icon="['fas', 'search']"
          name="fuzzySearch"
        />
      </ValidationObserver>
    </template>

    <template v-slot:filters>
      <b-row align-h="end">
        <b-col cols="4">
          <b-form-group
            class="mb-0"
            label-cols="auto"
            :label="$t('created_on') | capitalize"
          >
            <date-field
              v-model="filters.created.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="pt-2">
          <b-form-checkbox
              v-show="!permissions.actions.hasAllRhPermission"
              v-model="filters.financialInterest.value"
              class="ml-2"
              switch
          >
            {{ $t("with_financial_interest") | capitalize }}
          </b-form-checkbox>
        </b-col>
        <b-col cols="3" class="pt-2">
          <b-form-checkbox v-model="actionStatus" class="ml-2" switch>
            {{ $t("show_resolved") | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>

    <template v-slot:counts>
      <pagination
          class="smaller p-0 mb-3 mt-3"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="perPage"
          @limit="onLimit"
      />
    </template>
    <b-card no-body v-for="(recording, index) in data" :key="index" :class="{ 'blink': shouldBlink(recording.id), 'd-none': isHidden(recording.id)}">
      <modal
        :id="`confirmation-modal-solved-${recording.id}`"
        :customTitle="$t('user_action_solved') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="md"
        centered
        hide-footer
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
      >
        <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
        <b-row class="float-right modal-footer mt-4">
          <b-button @click="blinkAndHide(recording.id, `conflict-${recording.id}`, getData)" class="btn-outline-red">
            {{ $t("accept") | capitalize }}
          </b-button>
        </b-row>
      </modal>
      <b-card-header class="first-level">
        <b-row align-v="center">
          <b-col md="6" class="d-flex justify-content-start">
            <fa-icon
              v-if="isInProgressFrontend(recording.id) || (isInProgressBackend(recording.userActionsStatuses) && !isReleasedFrontend(recording.id))"
              :icon="['fa', 'user-edit']"
               color="red"
            />
            <collapse-button class="collapse-button" :toggle="`conflict-${recording.id}`" :expanded="expanded[index]" :disabled="loading" />
            {{ truncate(recording.title, { length: 100 }) }}
          </b-col>
          <b-col md="6" class="d-flex justify-content-end">
            <b-row class="w-100">
              <b-col cols="2">
                {{ $t('created_on') | capitalize }}:
                {{ parseDate(recording.userActionCreated, $config.DATE_FORMAT) }}
              </b-col>
              <b-col cols="2">
                {{ $t('playcount') | capitalize }}:
                {{ recording.playCount }}
              </b-col>
              <b-col cols="2">
                {{ $t('weighted_playcount') | capitalize }}:
                {{ recording.weightedPlayCount }}
              </b-col>
              <b-col cols="2">
                {{ $t('royalties_paid') | capitalize }}:
                <span class="smaller">
                  ${{ recording.royaltiesPaid }}
                </span>
              </b-col>
              <b-col cols="2">
                {{ $t('estimated_track_value') | capitalize }}:
                <span class="smaller">
                  ${{ recording.estimatedTrackValue }}
                </span>
              </b-col>
              <b-col cols="2">
                <status
                  v-if="(
                    isInProgressFrontend(recording.id) ||
                    (isInProgressBackend(recording.userActionsStatuses) && !isReleasedFrontend(recording.id))
                  ) && !isSolved(recording.id)"
                  :text="$t('in_progress') | capitalize"
                  :icon="['fas', 'question-circle']"
                  color="orange"
                />
                <status
                  v-else-if="(isPending(recording.userActionsStatuses) || isReleasedFrontend(recording.id)) && !isSolved(recording.id)"
                  :text="$t('conflict') | capitalize"
                  :icon="['fas', 'exclamation-circle']"
                  color="red"
                />
                <status
                  v-else
                  :text="$t('resolved') | capitalize"
                  :icon="['fas', 'check-circle']"
                  color="green"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse
        accordion="lineup-conflicts-list"
        :id="'conflict-' + recording.id"
        v-model="expanded[index]"
        role="tabpanel"
        @hide="onConflictClosed(recording)"
        @show="onConflictOpened(recording)"
      >
        <b-card-body v-if="expanded[index]">
          <b-table
            id="general-details"
            :items="[recording]"
            :fields="fields"
            fixed
            small
          >
            <!-- Width handling -->
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
              />
            </template>
            <!-- Genre Type -->
            <template v-slot:cell(genreType)="row">
              <b-form-select
                v-if="editModePerformers"
                size="sm"
                v-model="genre"
                :options="getFieldOptions('genreType')"
              ></b-form-select>
              <span v-else>
                {{ getGenreType(row.item.genreType) }}
              </span>
            </template>
            <template v-slot:cell(resoundId)="row">
              <database-link :type="linkTypes.RECORDING" :item-id="row.item.id">{{ row.item.resoundId }}</database-link>
            </template>
          </b-table>
          <performers
              :recordingUUID="recording.id"
              :genre-type="recording.genreType"
              :sub-genre="recording.subGenre"
              :categoryTypes="recording.categoryTypes"
              :solved-manually="solvedManually"
              :view-mode="viewMode"
              :show-highlights="true"
              :onDone="onDone"
              :resound-id="recording.resoundId"
          >
          </performers>
          <div>
            <h3 class="text-red">{{ $t("comments") | startcase }}</h3>
            <comments
              :createEndpoint="$api.comments.lineupConflictCommentCreate"
              :listEndpoint="$api.comments.lineupConflictCommentsList"
              :uuid="recording.id"
              :editing=false
              :deleting=false
            />
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="count"
      :per-page="perPage"
    />
  </list-layout>
</template>
<script>
import {
  UserActionTypeCodes,
  actionsTabs,
  conflictModes,
  genreTypes,
  getPerPageItemDefaults,
  linkTypes,
  userActionNotifications, userActionStatuses
} from "@/constants"
import { capitalize, get, startCase, truncate } from "lodash"
import { getGenreType, getSubGenre } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import Pagination from "@/components/Pagination"
import { ValidationObserver } from "vee-validate"
import { listRouteMixin } from "@/utils/mixins"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "LineupConflictsList",
  mixins: [listRouteMixin, websocketsMixin],
  computed: {
    ...mapState("repertoire", ["editModePerformers", "performersUpdate"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    ...mapGetters("repertoire", ["getFieldOptions"]),
    ...mapGetters("user", ["isInternal"]),
    ...mapState("user", ["permissions"]),
    viewMode: function () {
      if (!this.permissions.actionsTabs[actionsTabs.LINEUP_CONFLICTS].canWrite) {
        return conflictModes.VIEW
      }
      return this.actionStatus ? conflictModes.VIEW : conflictModes.SOLVE
    },
    actionStatus: {
      get () {
        return this.filters.status.value.length === 1 && this.filters.status.value[0] === userActionStatuses.DONE
      },
      set (value) {
        this.filters.status.value = value ? [userActionStatuses.DONE] : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
      },
    },
    genre: {
      get () {
        let genre = ""
        if (this.currentRecording) {
          genre = this.currentRecording.genreType
          if ([genreTypes.CLASSICAL, genreTypes.JAZZ].includes(genre)) {
            genre = `${genre}_${this.currentRecording.subGenre}`
          }
        }
        return genre
      },
      set (value) {
        if ([genreTypes.CLASSICAL, genreTypes.JAZZ].includes(value[0])) {
          this.currentRecording.genreType = value[0]
          this.currentRecording.subGenre = value.substring(2)
        } else {
          this.currentRecording.genreType = value
          this.currentRecording.subGenre = null
        }
      }
    },
    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
  },
  data () {
    return {
      linkTypes,
      currentRecording: null,
      loading: true,
      recordingData: {},
      fields: [
        { key: "resoundId", label: startCase(this.$t("crescendo_id")), width: "20%" },
        { key: "title", label: startCase(this.$t("title")), width: "40%" },
        { key: "artist", label: startCase(this.$t("artist")), width: "20%" },
        { key: "isrc", label: startCase(this.$t("isrc")), width: "20%" },
        { key: "genreType", label: startCase(this.$t("genre")), width: "20%" },
      ],
      currentGenreType: "",
      count: 0,
      page: 1,
      data: [],
      expanded: [],
      perPage: Number(getPerPageItemDefaults(this.$route)),
      filters: {
        fuzzySearch: { value: "", defaultValue: "" },
        created: { value: "", defaultValue: "" },
        status: { value: "", defaultValue: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS] },
        action_types: { value: "", defaultValue: [UserActionTypeCodes.RECORDING_LINEUP_CONFLICT] },
        userTimezone: { value: "", defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone },
        financialInterest: { value: true, defaultValue: true },
      },
      conflictModes,
      // WS values
      userActionNotification: userActionNotifications.LINEUP,
      storeStatusesKey: "wsLineupConflictStatuses",
    }
  },
  components: {
    DatabaseLink,
    Performers: () => import("../Repertoire/Recordings/RecordingDetail/Performers/Performers"),
    Pagination,
    ValidationObserver,
  },
  watch: {

    page (value) {
      this.onPagination(value)
      this.getData()
    },
    perPage () {
      if (this.page === 1) {
        this.getData()
      } else {
        this.page = 1
      }
    },
  },
  methods: {
    ...mapActions("userActions", ["notifyUserAction"]),
    ...mapMutations("actionTabs", ["setUserActionCounts"]),
    ...mapMutations("alert", ["success", "error"]),
    getGenreType,
    truncate,
    onDone () {
      this.setUserActionCounts({
        ...this.userActionCounts,
        lineupConflictsCount: --this.userActionCounts.lineupConflictsCount
      })
      this.getData()
    },
    responseAdapter (resp) {
      if (resp) {
        this.data = resp.data.results
        this.count = resp.data.count
      }
      this.loading = false
    },
    getData () {
      this.loading = true
      this.$api.actions.userActionsLineupConflicts({
        ...{ "financial_interest": this.filters.financialInterest.value },
        ...this.$route.query,
      })
        .then((resp) => {
          this.responseAdapter(resp)
        })
        .catch((e) => {
          if (e.response.data.fuzzySearch) {
            this.error(capitalize(this.$t("wrong_filters")))
          }
          this.$refs.fuzzySearchForm.setErrors(e.response.data)
        })
        .finally(() => {
          this.expanded = []
        })
    },
    onConflictOpened (recording) {
      this.currentRecording = recording
      this.onOpened(recording.id)
    },
    onConflictClosed (recording) {
      this.currentRecording = null
      this.onClosed(recording.id)
    },
    parseDate,
    getGenre (recording) {
      let genre = getGenreType(recording.genreType)
      if ([genreTypes.CLASSICAL, genreTypes.JAZZ].includes(recording.genreType)) {
        genre = `${genre} (${getSubGenre(recording.subGenre)})`
      }
      return genre
    }
  },
  mounted () {
    this.getData()
    this.page = Number(get(this.$route.query, "page", 1))
    this.perPage = Number(get(this.$route.query, "limit", this.perPage))
  },
}
</script>
<style lang="scss">
  .form-group {
    margin-bottom: 0;
  }
  .card {
    margin-bottom: 10px;
  }
  ::v-deep .smaller {
    font-size: 10pt;
  }
</style>
