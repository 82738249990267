import { startCase, upperCase } from "lodash"
import { rightsholderTypes } from "@/constants"

export default {
  data: function () {
    return {
      fields: [
        {
          key: "name",
          label: startCase(this.$t("rightsholder_name")),
          width: "14%",
          source: true,
        },
        {
          key: "resoundId",
          label: startCase(this.$t("resound_id")),
          width: "10%",
        },
        {
          key: "ipn",
          label: upperCase(this.$t("ipn")),
          performerOnly: true,
          width: "10%",
          source: true,
        },
        {
          key: "aliases",
          label: startCase(this.$t("aliases")),
          width: "15%",
          source: true,
        },
        {
          key: "dateOfBirth",
          label: startCase(this.$t("date_of_birth")),
          performerOnly: true,
          width: "8%",
          source: true,
        },
        {
          key: "dateOfDeath",
          label: startCase(this.$t("date_of_death")),
          performerOnly: true,
          width: "10%",
          source: true,
        },
        {
          key: "gender",
          label: startCase(this.$t("gender")),
          performerOnly: true,
          width: "5%",
          source: true,
        },
        {
          key: "mandates",
          label: startCase(this.$t("mandates")),
          secondary: true,
          width: "10%",
        },
        {
          key: "type",
          label: startCase(this.$t("type")),
          secondary: true,
          width: "7%",
        },
        {
          key: "nationalities",
          label: startCase(this.$t("nationality")),
          secondary: true,
          width: "9%",
          source: true,
        },
      ],
      rightsholderTypes,
    }
  },
  computed: {
    primaryFields () {
      if(this.rightsholderData && this.rightsholderData.type === rightsholderTypes.MAKER) {
        return this.fields.filter(field => !field.performerOnly)
      }
      return this.fields.filter(field => !field.secondary)
    },
    secondaryFields () {
      return this.fields.filter(field => field.secondary)
    },
  }
}
