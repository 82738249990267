<template>
  <div>
    <h3 class="ml-2 text-red">
      {{ data.header | capitalize }}
    </h3>
    <b-table
      striped
      id="merged-rightsholders-detail"
      :items="data.rightsHolderData.mergedChildren"
      :fields="fieldsByType"
    >
      <template v-slot:cell(nationalities)="row">
        <div v-for="(country, index) in row.item.nationalities" :key="`country-${index}`">
          {{ country.name }}
        </div>
      </template>
      <!-- merge date -->
       <template v-slot:cell(dateOfMerge)="row">
          {{ getCleanDate(row.item.mergeLog.actionTime) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { getCleanDate, getMergedRightsholderFieldList } from "@/constants"
import { startCase, upperCase } from "lodash"

export default {
  name: "MergedRightsholders",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getCleanDate: getCleanDate
  },
  computed: {
    fieldsByType () {
      return this.fields.filter(f => {
        return getMergedRightsholderFieldList(this.data.rightsHolderData.type).includes(f.key)
      })
    },
  },
  data () {
    return {
      fields: [
        { key: "resoundId", thClass: "text-gray", label: this.$t("resound_id") },
        { key: "name", thClass: "text-gray", label: startCase(this.$t("rightsholder_name")) },
        { key: "ipn", thClass: "text-gray", label: upperCase(this.$t("ipn")) },
        { key: "dateOfBirth", thClass: "text-gray", label: startCase(this.$t("date_of_birth")) },
        { key: "dateOfMerge", thClass: "text-gray", label: startCase(this.$t("date_of_merge")) },
        { key: "nationalities", thClass: "text-gray", label: startCase(this.$t("nationalities")) },
      ],
    }
  }
}
</script>
