<template>
  <list-layout
    :title="$t('merge_requests') | capitalize"
    :count="userActionCounts.mergeRequestsCount"
    :no-counts="true"
    :no-search="true"
    :search-filter="filters.fuzzySearch"
    :apply-filters="applyFilters"
    :no-apply-btn="true"
  >
    <template v-slot:beforeFilters>
      <div class="d-flex justify-content-center">
        <div class="">
            <slot name="typeSelector"/>
        </div>
      </div>
      <b-row>
        <b-col>
          <filter-builder
            :id="userPreferenceNames.RECORDING_MERGE_REQUESTS_FILTER"
            :filters="filtersWithOptions()"
            :filters-pending="filtersPending"
            :apply-filters="applyFilters"
            :reset-filters="resetFilters"
            :filters-applied="filtersApplied"
            @filter="onFilter"
          />
        </b-col>
      </b-row>
    </template>

    <template v-slot:table>
      <crescendo-split-panes>
        <template v-slot:top>
          <t-table
            ref="recordingMergeRequest"
            id="recording-merge-requests"
            :columns="columns"
            :options="options"
            :limit="limit"
            @loaded="onLoaded"
            @loading="onLoading"
            @pagination="onPagination"
            @sorted="onSorted"
            @row-click="onRowClicked"
          >
            <template v-slot:counts>
              <pagination
                class="smaller p-0"
                :page="page"
                :count="count"
                :loading="loading"
                v-model="limit"
                @limit="onLimit"
              />
            </template>
            <template v-slot:status="{ row }">
              <modal
                :id="`confirmation-modal-solved-${row.id}`"
                :customTitle="$t('user_action_solved') | capitalize"
                modal-class="custom-modal edit"
                modalType="edit"
                size="md"
                centered
                hide-footer
                hide-header-close
                no-close-on-esc
                no-close-on-backdrop
              >
                <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
                <b-row class="float-right modal-footer mt-4">
                  <b-button @click="blinkAndHide(row.id, false, getData)" class="btn-outline-red">
                    {{ $t("accept") | capitalize }}
                  </b-button>
                </b-row>
              </modal>
              <fa-icon
                v-if="isInProgressFrontend(row.id) || (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))"
                :icon="['fa', 'user-edit']"
                color="red"
              />
            </template>
            <template v-slot:resoundId="{ row }">
              <database-link :type="linkTypes.RECORDING" :item-id="row.parentId">{{ row.parentResoundId }}</database-link>
            </template>
            <template v-slot:firstMakers="{ row }">
              <names-list-modal :values="getFirstMakerNames(row)"/>
            </template>
            <template v-slot:firstMakerNationalities="{ row }">
              <names-list-modal :values="getFirstMakerNationalities(row)"/>
            </template>
            <template v-slot:releases="{ row }">
              <names-list-modal :values="row.releases"/>
            </template>
            <template v-slot:owners="{ row }">
              <names-list-modal :values="row.owners"/>
            </template>
            <template v-slot:raisedBy="{ row }">
              {{ row.raisedBy }} ({{ row.raisedByMemberCollective }})
            </template>
            <template v-slot:raisedAt="{ row }">
              {{ parseDate(row.raisedAt, $config.DATETIME_FORMAT) }}
            </template>
          </t-table>
        </template>

        <template v-slot:bottom>
          <recording-merge-request-detail
            v-if="selectedRow"
            :merge-request-id="selectedRow.id"
            @beforeSolved="onBeforeSolved"
            @solved="onSolved"
          />
        </template>
      </crescendo-split-panes>
    </template>

  </list-layout>
</template>

<script>
import { capitalize, has } from "lodash"
import {
  filterTypes,
  getPerPageItemDefaults,
  linkTypes,
  userActionNotifications,
  userActionStatuses,
  userPreferenceNames
} from "@/constants"
import { mapActions, mapState } from "vuex"
import CrescendoSplitPanes from "@/components/CrescendoSplitPanes.vue"
import DatabaseLink from "@/components/DatabaseLink.vue"
import FilterBuilder from "@/components/FilterBuilder"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import Pagination from "@/components/Pagination"
import RecordingMergeRequestDetail from "@/pages/UserActions/RecordingMergeRequestDetail.vue"
import { listRouteMixin } from "@/utils/mixins"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "MergeRequestList",
  mixins: [ listRouteMixin, websocketsMixin ],
  components: { DatabaseLink, RecordingMergeRequestDetail, CrescendoSplitPanes, NamesListModal, FilterBuilder, Pagination },
  data () {
    return {
      page: 1,
      count: 0,
      loading: false,
      userPreferenceNames,
      columns: [
        "status",
        "resoundId",
        "title",
        "artist",
        "isrc",
        "fixationCountry",
        "fixationYear",
        "firstMakerNationalities",
        "firstMakers",
        "releases",
        "owners",
        "raisedBy",
        "raisedAt",
      ],
      options: {
        sortable: ["title", "artist", "isrc", "fixationCountry", "fixationYear", "raisedBy", "raisedAt"],
        headings: {
          "resoundId": this.$t("resound_id"),
          "status": "",
          "title": capitalize(this.$t("title")),
          "artist": capitalize(this.$t("artist")),
          "isrc": this.$t("isrc"),
          "fixationCountry": capitalize(this.$t("country_of_fixation")),
          "fixationYear": capitalize(this.$t("year_of_fixation")),
          "firstMakerNationalities": capitalize(this.$t("first_makers_nationalities")),
          "firstMakers": capitalize(this.$t("first_makers")),
          "releases": capitalize(this.$t("releases")),
          "owners": capitalize(this.$t("member_collectives")),
          "raisedBy": capitalize(this.$t("raised_by")),
          "raisedAt": capitalize(this.$t("requested_on"))
        },
        width: {
          "status": 2
        },
        requestFunction () {
          let query = this.$route.query
          if (!has(query, "status")) {
            query.status = userActionStatuses.PENDING
          }
          return this.$api.actions.recordingMergeRequests(this.$route.query)
        },
        rowClasses: (row) => {
          let classes = []
          if (row.status !== userActionStatuses.DONE) {
            classes.push("clickable")
          }
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          if (this.selectedRow && this.selectedRow.id === row.id) {
            classes.push("tabulator-selected")
          }
          return classes
        }
      },
      filters: {
        title: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("title"))
        },
        artist: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("artist"))
        },
        isrc: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: this.$t("isrc")
        },
        fuzzySearch: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("search")),
        },
        fixationYear: {
          value: null,
          defaultValue: null,
          type: filterTypes.YEAR_RANGE,
          label: capitalize(this.$t("year_of_fixation")),
          noWatch: true
        },
        fixationYearFrom: {
          value: null,
          defaultValue: null,
          hidden: true,
        },
        fixationYearTo: {
          value: null,
          defaultValue: null,
          hidden: true,
        },
        fixationCountry: {
          value: null,
          defaultValue: null,
          type: filterTypes.MULTI_SELECT,
          label: capitalize(this.$t("country_of_fixation")),
          options: [],
          placeholder: capitalize(this.$t("select_country_of_fixation"))
        },
        release: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("releases")),
        },
        firstMakerNationality: {
          value: null,
          defaultValue: null,
          type: filterTypes.MULTI_SELECT,
          label: capitalize(this.$t("first_maker_nationality")),
          options: [],
          placeholder: capitalize(this.$t("select_first_maker_nationalities"))
        },
        firstMakerName: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("first_maker"))
        },
        owners: {
          value: null,
          defaultValue: null,
          type: filterTypes.MULTI_SELECT,
          label: capitalize(this.$t("member_collectives")),
          placeholder: capitalize(this.$t("select_member_collectives")),
          options: []
        },
        requestedOn: {
          value: null,
          defaultValue: null,
          type: filterTypes.DATE_RANGE,
          label: capitalize(this.$t("requested_on")),
          format: this.$config.ISO_DATE_FORMAT,
          valueType: "format",
          noWatch: true
        },
        requestedOnMin: {
          value: null,
          defaultValue: null,
          hidden: true,
        },
        requestedOnMax: {
          value: null,
          defaultValue: null,
          hidden: true,
        },
        raisedBy: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          label: capitalize(this.$t("raised_by")),
          listEndpoint: this.$api.users.usersSearch,
          excludeName: "id",
          textField: "name",
          valueField: "email",
          placeholder: capitalize(this.$t("search_user")),
          searchName: "search",
          noWatch: true,
        },
        raisedByMc: {
          value: null,
          defaultValue: null,
          type: filterTypes.MULTI_SELECT,
          label: capitalize(this.$t("raised_by_member_collective")),
          placeholder: capitalize(this.$t("select_member_collectives")),
          options: [],
        }
      },
      limit: Number(getPerPageItemDefaults(this.$route)),
      selectedRow: null,
      mergeRequestData: null,
      linkTypes,
      parseDate,
      // WS values
      userActionNotification: userActionNotifications.USER_ACTION,
      storeStatusesKey: "wsUserActionStatuses",

    }
  },
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
    ...mapState("consts", ["countries", "memberCollectives"]),
    countryOptions () {
      return this.countries.map(c => ({ name: c.text, value: c.alpha2 }))
    },
    memberCollectiveOptions () {
      return this.memberCollectives.map(mc => ({ name: mc.name, value: mc.uuid }))
    },
    shownUuidList () {
      return this.$refs["recordingMergeRequest"].data.map(c => c.id)
    },
  },
  methods: {
    ...mapActions("consts", ["getCountries"]),
    ...mapActions("alert", ["error", "success"]),
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    onFilter (name, value) {
      switch (name) {
      case "fixationYear":
        if (value) {
          this.filters.fixationYearFrom.value = value[0]
          this.filters.fixationYearTo.value = value[1]
        } else {
          this.filters.fixationYearFrom.value = null
          this.filters.fixationYearTo.value = null
        }
        break
      case "requestedOn":
        if (value) {
          this.filters.requestedOnMin.value = value[0]
          this.filters.requestedOnMax.value = value[1]
        } else {
          this.filters.requestedOnMin.value = null
          this.filters.requestedOnMax.value = null
        }
        break
      case "raisedBy":
        if (value) {
          this.changeFilter(name, value.value)
        } else {
          this.changeFilter(name, null)
        }
      }
    },
    onRowClicked (row) {
      this.selectedRow = row
    },
    filtersWithOptions () {
      this.filters.fixationCountry.options = this.countryOptions
      this.filters.firstMakerNationality.options = this.countryOptions
      this.filters.owners.options = this.memberCollectiveOptions
      this.filters.raisedByMc.options = this.memberCollectiveOptions
      return this.filters
    },
    getData () {
      this.selectedRow = null
      this.$refs.recordingMergeRequest.getData()
    },
    getFirstMakerNames (row) {
      return row.firstMakers ? row.firstMakers.map(fm => fm.name) : []
    },
    getFirstMakerNationalities (row) {
      return row.firstMakers ? [...new Set(row.firstMakers.map(fm => fm.nationalities).flat())] : []
    },
    updateWsStatus (newRow, oldRow) {
      if (oldRow && newRow && oldRow.id === newRow.id) {
        return
      }
      if (newRow) {
        this.onOpened(newRow.id)
      }
      if (oldRow) {
        this.onClosed(oldRow.id)
      }
    },
    onBeforeSolved () {
      this.solvedManually.push(this.selectedRow.id)
    },
    onSolved () {
      this.getData()
    },
  },
  watch: {
    selectedRow (newVal, oldVal) {
      this.updateWsStatus(newVal, oldVal)
    },
  },
  mounted () {
    if (this.filters.fixationYearFrom.value && this.filters.fixationYearTo.value) {
      this.filters.fixationYear.value = [
        this.filters.fixationYearFrom.value.toString(),
        this.filters.fixationYearTo.value.toString()
      ]
    }
    if (this.filters.requestedOnMin.value && this.filters.requestedOnMax.value) {
      this.filters.requestedOn.value = [
        this.filters.requestedOnMin.value.toString(),
        this.filters.requestedOnMax.value.toString()
      ]
    }
    if (!this.countries.length) {
      this.getCountries()
    }
  }
}
</script>
