<template>
  <modal
    id="edit-albums"
    :customTitle="$t('update_albums') | capitalize"
    modal-class="custom-modal edit"
    modalType="edit"
    size="xl"
    centered 
    @ok="saveAlbums"
    @show="showModal"
    :okTitle="$t('save') | capitalize"
    footer-class="pl-5 pr-5"
  >
    <div>
      <b-table
        :fields="fields"
        :items="items"
        striped
        fixed
      >
        <!-- Width handling -->
        <template v-slot:table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
          />
        </template>
        <template v-slot:cell(promoted)="row">
          <b-form-radio v-model="promoted" :value="row.item.title">

          </b-form-radio>
        </template>
        <template v-slot:cell(title)="row">
          <b-input-group v-if="row.item.new">
            <b-form-input
              v-model="row.item.title"
            />
          </b-input-group>
          <span v-else>
            {{ row.item.title }}
          </span>
        </template>
        <template v-slot:cell(remove)="row">
          <fa-icon
            :icon="['fas', 'trash']"
            role="button"
            :title="$t('delete') | lowercase"
            class="clickable"
            @click="removeAlbum(row.index)"
          />
        </template>
      </b-table>
      <!--table-pagination
        class="mt-5"
        v-model="page"
        :total-rows="albums.length"
        :per-page="1"
      /-->
      <b-button class="ml-3 btn-outline-red"
        @click="addAlbum">
        {{ $t("add_album") | capitalize }}
      </b-button>
    </div>
  </modal>
</template>

<script>
import { perPageItemsSm } from "@/constants"
import { startCase } from "lodash"

export default {
  name: "EditAlbums",
  props: {
    albums: {
      type: Array,
      required: true,
    }
  },
  data () {
    return {
      perPage: perPageItemsSm,
      page: 1,
      promoted: "",
      items: [],
      fields: [
        {
          key: "promoted",
          label: startCase((this.$t("promoted"))),
          width: "10%"
        },
        {
          key: "title",
          label: startCase(this.$t("title")),
          width: "80%"
        },
        {
          key: "remove",
          label: "",
          width: "10%"
        }
      ]
    }
  },
  methods: {
    showModal () {
      this.items = JSON.parse(JSON.stringify(this.albums)).map( i => {
        if (i.promoted) {
          this.promoted = i.title
        }
        return { ...i, ...{ "new": false } }
      })
    },
    removeAlbum (index) {
      this.items.splice(index, 1)
    },
    addAlbum () {
      this.items.push({
        promoted: null,
        title: null,
        new: true,
      })
    },
    saveAlbums () {
      this.$emit("ok", this.items.filter(fm => fm.title).map(album => {
        return {
          title: album.title,
          promoted: album.title === this.promoted
        }
      }))
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-modal {
  .modal-body {
    .table-striped {
      border: none;
      ::v-deep tr{
        background-color: $modal-gray !important;
      }
    }
  }
}
.btn-outline-red {
  min-width: 108px;
}
</style>
