import { render, staticRenderFns } from "./RightsHolderMandates.vue?vue&type=template&id=91b727a0&scoped=true&"
import script from "./RightsHolderMandates.vue?vue&type=script&lang=js&"
export * from "./RightsHolderMandates.vue?vue&type=script&lang=js&"
import style0 from "./RightsHolderMandates.vue?vue&type=style&index=0&id=91b727a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91b727a0",
  null
  
)

export default component.exports