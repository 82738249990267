<template>
  <div>
    <b-card class="parent">
      <b-row>
        <b-col cols="4">
          <div>
            <b-row>
              <div>
                <collapse-button
                  :toggle="`child-${notification.id}`"
                  :expanded="expanded"
                />
              </div>
              <span class="notification-date">
                {{ getDate(notification.created) }}
              </span>
              <span class="subject">
                {{ notification.subject }}
              </span>
            </b-row>
          </div>
        </b-col>
        <b-col cols="3">
          <a class="url" :href="notification.redirectUrl">{{
            notification.redirectUrl
          }}</a>
        </b-col>
      </b-row>
      <b-collapse v-model="expanded" :id="`child-${notification.id}`">
        <b-card class="child">
          <div v-html="notification.body"></div>
        </b-card>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import moment from "moment"
export default {
  name: "Notification",
  data () {
    return {
      expanded: false,
    }
  },
  props: {
    notification: Object,
  },
  methods: {
    getDate (date) {
      return moment(date).format("YYYY-MM-DD HH:mm")
    },
  },
}
</script>

<style language="scss" scoped>
.parent {
  background: #f1f1f1;
  padding: 5px;
  margin: 10px 0;
}

.child {
  background: lightgrey;
  margin: 10px;
}

tr {
  background: red !important;
}

.subject {
  padding-left: 15px;
}

.url {
  text-decoration: none;
  color: var(--blue);
}

.notification-date {
  color: var(--gray);
  padding-left: 15px;
}
</style>
