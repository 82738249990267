// Styles
import "@/styles/project.scss"

// Forms validators
import "@/utils/form-validation"

/* eslint-disable sort-imports */
// Components
import App from "./App"
import {
  CollapseButton,
  Comments,
  DateField,
  DateTimeCell,
  Filters,
  FormField,
  GoBack,
  IncludeExcludeSelector,
  ItemCount,
  MergeButton,
  MergeSummary,
  Modal,
  MultiSelectField,
  NavUserMenu,
  NotificationCenter,
  NoData,
  PageSection,
  RowExtraInfo,
  SelectSearch,
  SideBar,
  Status,
  SubmitButton,
  TableAction,
  TablePagination,
  TabulatorTable,
} from "./components"

// Layouts
import {
  ListLayout,
  LoggedInLayout,
  NotLoggedInLayout,
  TabsLayout,
  WorkDetailLayout,
} from "./layouts"

// Utils
import { API } from "./services/api"
import { store } from "./store"
import { router, i18n } from "./utils"
import Authentication from "@/utils/auth"
import { VueWatchers } from "@/utils/vue-watchers"

// Third-parties
import { capitalize, get, lowerCase, startCase, toInteger, toLower, toUpper, upperCase } from "lodash"
import moment from "moment"
import {
  faAnalytics,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleRight,
  faArrowUp,
  faBan,
  faBarcode,
  faBell,
  faBlender,
  faBullhorn,
  faBroadcastTower,
  faCalendarAlt,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronUp,
  faChevronDown,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCodeMerge,
  faCompactDisc,
  faCopyright,
  faDatabase,
  faDollarSign,
  faDumpster,
  faEye,
  faExclamationCircle,
  faExternalLink,
  faFileAlt,
  faFileDownload,
  faFileImport,
  faFileUpload,
  faFilter,
  faFunnelDollar,
  faGripLines,
  faHandHolding,
  faHandHoldingUsd,
  faHandHoldingMagic,
  faHandHoldingMedical,
  faHandPaper,
  faHistory,
  faIcons,
  faIdCard,
  faInfoCircle,
  faLevelUpAlt,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faMicrophoneAlt,
  faMinus,
  faMusic,
  faPen,
  faPercent,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQuestionCircle,
  faReply,
  faSackDollar,
  faSearch,
  faServer,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faSort,
  faSortUp,
  faSortDown,
  faStar,
  faSuitcase,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUpload,
  faUser,
  faUserEdit,
  faUserCircle,
  faUserChart,
  faUserLock,
  faUsersCog,
  faUsers
} from "@fortawesome/pro-solid-svg-icons"
import {
  faArrowAltSquareDown,
  faArrowAltSquareUp,
  faCircle,
  faCommentLines,
  faDotCircle,
  faStar as faStarUnfilled,
  faSync,
  faTimes as faCancel,
  faTrashAlt,
  faUserCircle as farUserCircle,
  faQuestionCircle as farQuestionCircle,
  faQuestionSquare
} from "@fortawesome/pro-regular-svg-icons"
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import DatePicker from "vue2-datepicker"
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome"
import Multiselect from "vue-multiselect"
import { ServerTable } from "vue-tables-2"
import { ClientTable } from "vue-tables-2"
import Vue from "vue"
import VueHotkey from "v-hotkey"
import VueCookies from "vue-cookies"
import VueNativeSock from "vue-native-websocket"
import VueRouter from "vue-router"
import PortalVue from "portal-vue"
import SortControl from "@/components/SortControl"
import { TabulatorFull as Tabulator } from "tabulator-tables"

// FontAwesome Icons
import { library } from "@fortawesome/fontawesome-svg-core"

import { perPageItemsSm } from "@/constants"
import vClickOutside from "v-click-outside"

// Sentry
import * as Sentry from "@sentry/vue"

/* eslint-enable */

// Configuration from Environment Variables
Vue.prototype.$config = {
  VERSION: get(process.env, "VUE_APP_VERSION"),
  DATE_FORMAT: get(process.env, "VUE_APP_DATE_FORMAT", "DD-MM-YYYY"),
  DATETIME_FORMAT: get(process.env, "VUE_APP_DATETIME_FORMAT", "DD-MM-YYYY HH:mm"),
  ISO_DATE_FORMAT: "YYYY-MM-DD",
  MONTH_DATE_FORMAT: "MM-YYYY",
  TIME_SECOND_FORMAT: "HH:mm:ss",
  TIME_FORMAT: get(process.env, "VUE_APP_TIME_FORMAT", "HH:mm"),
  MANDATES_TEMPLATE_URL: get(process.env, "VUE_APP_MANDATES_TEMPLATE_URL"),
  MAX_WS_ERRORS: toInteger(get(process.env, "VUE_APP_MAX_WS_ERRORS", 100)),
  WS_RECONNECTION_DELAY: toInteger(get(process.env, "VUE_APP_WS_RECONNECTION_DELAY", 20)),
}

/** Third-party plugins and components **/

Vue.use(BootstrapVue) // We encourage to use VueBootstrap components versus classes.
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter)
Vue.use(vClickOutside)
Vue.use(PortalVue)
Vue.use(VueCookies, { secure: true, sameSite: "Strict" })
Vue.use(VueHotkey)


let options = {
  perPage: perPageItemsSm,
  filterable: true,
  skin: "table table-striped",
  texts: {
    filterPlaceholder: "search...",
  },
  pagination: {
    dropdown: false,
  }
}
Vue.use(ServerTable, options, false, "bootstrap4", {
  sortControl: SortControl
})
Vue.use(ClientTable, options, false, "bootstrap4", {
  sortControl: SortControl
})

// FontAwesome Icons. Read the docs, specially:
// https://github.com/FortAwesome/vue-fontawesome#why-use-the-concept-of-a-library
// Check available icons: https://fontawesome.com/icons?d=gallery&s=regular,solid
library.add(
  faArrowAltSquareDown,
  faArrowAltSquareUp,
  faAnalytics,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleRight,
  faArrowUp,
  faBan,
  faBarcode,
  faBell,
  faBlender,
  faBullhorn,
  faBroadcastTower,
  faCalendarAlt,
  faCancel,
  faCircle,
  faCheck,
  faCaretDown,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCodeMerge,
  faCommentLines,
  faCompactDisc,
  faCopyright,
  faDatabase,
  faDollarSign,
  faDotCircle,
  faDumpster,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faFileAlt,
  faFileDownload,
  faFileImport,
  faFileUpload,
  faFilter,
  faFunnelDollar,
  faGripLines,
  faHandHolding,
  faHandHoldingUsd,
  faHandHoldingMagic,
  faHandHoldingMedical,
  faHandPaper,
  faHistory,
  faIcons,
  faIdCard,
  faInfoCircle,
  faLevelUpAlt,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faMicrophoneAlt,
  faMinus,
  faMusic,
  faPen,
  faPercent,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQuestionCircle,
  faQuestionSquare,
  farUserCircle,
  farQuestionCircle,
  faReply,
  faSackDollar,
  faSearch,
  faServer,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faSort,
  faSortUp,
  faSortDown,
  faStar,
  faStarUnfilled,
  faSuitcase,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUnlink,
  faUpload,
  faUser,
  faUserEdit,
  faUserCircle,
  faUserChart,
  faUserLock,
  faUsersCog,
  faUsers
)
Vue.component("fa-icon", FontAwesomeIcon)
Vue.component("fa-layers", FontAwesomeLayers)

// https://github.com/mengxiong10/vue2-datepicker
Vue.component("date-picker", DatePicker)
// https://vue-multiselect.js.org/
Vue.component("multi-select", Multiselect)

/** Our plugins and components **/

// Authentication plugin. Exposes this.$auth.
Vue.use(Authentication)
// API plugin. Exposes this.$api.
Vue.use(API)
// WebSocket
Vue.use(VueNativeSock, process.env.VUE_APP_API_WS_HOST, {
  format: "json",
  store: store,
  reconnection: true,
  reconnectionDelay: 3000,
  reconnectionAttempts: 5,
  connectManually: true,
})
// Components
Vue.component("collapse-button", CollapseButton)
Vue.component("comments", Comments)
Vue.component("date-field", DateField)
Vue.component("date-time-cell", DateTimeCell)
Vue.component("go-back", GoBack)
Vue.component("include-exclude-selector", IncludeExcludeSelector)
Vue.component("item-count", ItemCount)
Vue.component("form-field", FormField)
Vue.component("filters", Filters)
Vue.component("merge-button", MergeButton)
Vue.component("merge-summary", MergeSummary)
Vue.component("multi-select-field", MultiSelectField)
Vue.component("row-extra-info", RowExtraInfo)
Vue.component("side-bar", SideBar)
Vue.component("select-search", SelectSearch)
Vue.component("status", Status)
Vue.component("submit-button", SubmitButton)
Vue.component("table-action", TableAction)
Vue.component("nav-user-menu", NavUserMenu)
Vue.component("modal", Modal)
Vue.component("page-section", PageSection)
Vue.component("no-data", NoData)
Vue.component("table-pagination", TablePagination)
Vue.component("t-table", TabulatorTable)
// User actions components
Vue.component("notification-center", NotificationCenter)

// Layouts
Vue.component("tabs-layout", TabsLayout)
Vue.component("list-layout", ListLayout)
Vue.component("logged-in-layout", LoggedInLayout)
Vue.component("not-logged-in-layout", NotLoggedInLayout)
Vue.component("work-detail-layout", WorkDetailLayout)

/** GLOBAL FILTERS **/

Vue.filter("capitalize", capitalize)
Vue.filter("get", get)
Vue.filter("lowercase", lowerCase)
Vue.filter("uppercase", upperCase)
Vue.filter("startcase", startCase)
Vue.filter("tolower", toLower)
Vue.filter("toupper", toUpper)
Vue.filter("toDuration", function (value) {
  return moment.utc(value*1000).format(value < 3600 ? "mm:ss" : "hh:mm:ss")
})
Vue.filter("date", function (value, format) {
  return moment(value).format(format)
})

Tabulator.registerModule(VueWatchers)

const environment = get(process.env, "VUE_APP_ENVIRONMENT")

if (environment === "pro" && get(process.env, "VUE_APP_SENTRY_DSN")) {
  Sentry.init({
    Vue,
    dsn: get(process.env, "VUE_APP_SENTRY_DSN"),
    integrations: [
      new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    environment: environment,
    release: Vue.prototype.$config.VERSION.split("-")[0],
  })
}

export const app = new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: h => h(App)
})
