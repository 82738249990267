<template>
  <div>
    <div class="row">
      <div class="col d-flex align-items-center">
        <h4 class="mt-2 mb-2">{{  title !== null ? title : $t("ownership") | startcase }}</h4>
      </div>
      <modal
        id="comment-modal"
        :customTitle="$t('make_comment') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="lg"
        centered
        hide-footer >
        <b-form-group class="mb-4" v-if="viewMode === conflictModes.ADJUSTMENT"
          :label="`${permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canWrite ? $t('effective_date') : $t('suggested_effective_date')}` | capitalize"
        >
          {{ effectiveDate }}
        </b-form-group>
        <ValidationObserver ref="commentForm" v-slot="{ handleSubmit }">
          <ValidationProvider vid="comment" name="comment" rules="required" v-slot="{ errors }">
            <b-form @submit.prevent="handleSubmit(saveOwners)">
              <b-form-textarea
                size="lg"
                class="mb-4"
                debounce="500"
                name="comment"
                :placeholder="$t('make_comment') | capitalize"
                v-model="updateComment"
                rows="5"
              />
              <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
              <b-row class="modal-footer mt-4">
                <b-button class="btn-outline-dark" @click="cancel">
                  {{ $t("cancel") | capitalize }}
                </b-button>
                <b-button class="btn-outline-red" type="submit" >
                  {{ saveButtonCaption }}
                </b-button>
              </b-row>
            </b-form>
          </ValidationProvider>
        </ValidationObserver>
      </modal>
    </div>
    <div>
      <ReconciledOwnersList
        v-if="this.recordingOwners[this.recordingUUID]"
        :owner-data="ownersData"
        :countries-options="countries"
        :recording-uuid="recordingUUID"
        :resound-id="resoundId"
        :hide-invalidated="hideInvalidatedInternal"
        :hide-without-mandates="hideWithoutMandates"
        @invalidate="refresh"
        @delete:share="onDelete">
          <template v-slot:mergeExtended>
            <div class="d-flex ml-2" v-if="viewMode !== conflictModes.VIEW">
              <div v-if="viewMode === conflictModes.EDIT && canEditOwners()">
                <b-button @click="addOwnership()" v-if="!editModeOwners" class="btn-outline-red">
                  {{ $t("add_ownership") | startcase }}
                </b-button>
                <b-button class="ml-2 btn-outline-red" v-if="!editModeOwners" @click="editOwners()">
                  {{ $t("edit") | startcase }}
                </b-button>
              </div>
              <div v-if="editModeOwners">
                <b-button class="btn-outline-red" @click="manageSave()" v-if="canEditOwners()" :disabled="waiting">
                  {{ saveButtonCaption }}
                </b-button>
                <b-button
                class="ml-2 btn-outline-dark"
                @click="cancelEdit()"
                v-if="viewMode === conflictModes.EDIT"
                :disabled="waiting"
                >
                {{ $t("cancel") | startcase }}
              </b-button>
              </div>
              <div class="col d-flex justify-content-end align-items-center" v-if="viewMode === conflictModes.ADJUSTMENT">
                <date-field
                  label-cols="5"
                  :group-label="$t('effective_date') | capitalize"
                  name="effectiveDate"
                  v-model="effectiveDate"
                  class="ml-4"
                  :format="$config.DATE_FORMAT"
                />
                <b-button
                  class="ml-4 btn-outline-red"
                  v-if="viewMode === conflictModes.ADJUSTMENT"
                  :disabled="!effectiveDate"
                  @click="solveAdjustment()"
                >
                  {{ permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canWrite ? $t('apply_effective_date') : $t('suggest_effective_date') | startcase }}
                </b-button>
              </div>
            </div>
            <span class="d-flex align-items-center">
              <b-form-checkbox class="ml-2" v-model="hideInvalidatedInternal" switch>
                {{ $t("hide_invalidated") | capitalize }}
              </b-form-checkbox>
              <b-form-checkbox class="ml-3" v-model="hideWithoutMandates" switch>
                {{ $t("hide_without_mandates") | capitalize }}
              </b-form-checkbox>
            </span>
          </template>
      </ReconciledOwnersList>
      <div v-if="this.recordingOwners[this.recordingUUID] && this.recordingOwners[this.recordingUUID].owners === null" class="mb-4">
        <fa-icon size="lg" icon="exclamation-circle" class="mr-2" />
        <span>{{ $t("no_permissions_ownership") }}</span>
      </div>
      <modal
        id="ownership-create-form"
        customTitle="New ownership"
        modal-class="custom-modal edit"
        modalType="edit"
        size="lg"
        centered
        hide-footer
      >
        <ownership-create-form
            :recording-uuid="this.recordingUUID"
            @submit="onSubmit"
            modal-id="ownership-create-form"
        />
      </modal>
      <b-modal
        id="sourcesModal" 
        ref="sourcesModal" 
        modal-class="custom-modal info"
        size="xl"
        centered
        hide-footer>
        <template #modal-title>
          <h4 class="d-flex align-items-center mb-0">
            <fa-icon :icon="['fas', 'exclamation-circle']" class="icon-blue mr-2"/>
            {{ $t('sources') | capitalize }}
          </h4>
        </template>
        <SourcesList
          :sources="currentSources"
        />
      </b-modal>
    </div>
    <template v-if="showSource">
      <div class="d-flex mt-3 mb-1 justify-content-between" v-if="this.hasAnySources()">
        <h4 class="mb-0">{{ $t("source_metadata") | startcase }}</h4>
        <b-form-checkbox
          v-if="user.selectedMember.name === RESOUND"
          class="mt-2 ml-3"
          switch
          v-model="showRepTracks"
        >
          {{ $t("show_rep_tracks") }}
        </b-form-checkbox>
      </div>
      <div class="card-wrapper" v-if="filteredOwnerSources.length">
      <b-card
        class="source mb-0"
        v-for="(source, sourceIndex) in this.filteredOwnerSources"
        :key="`source-${sourceIndex}`"
      >
        <b-row align-v="center" class="m-0 source-row" :class="{'text-muted': source.reconciliationStatus === landingRecordingReconciliationStatuses.NOT_READY}">
          <b-col cols="11">
            <b>{{ source.memberCollectiveName }}</b><br/>
            <span>{{ source.proprietaryId }}</span>
          </b-col>
          <b-col cols="1">
            <div class="d-flex justify-content-end">
              <span class="blue" v-if="source.repertoireFiles.length">
                {{ source.repertoireFiles.length }}
                <fa-icon
                  :title="$t('expand_all_sources') | capitalize"
                  @click="showSources(source.repertoireFiles)"
                  class="blue clickable ml-2 more-values-icon"
                  size="sm"
                  :icon="['fas', 'plus-circle']"
                >
                </fa-icon>
              </span>
              <collapse-button
                class="ml-3"
                :toggle="'row-' + source.id"
                :expanded="expanded[sourceIndex]"
              />
            </div>
          </b-col>
        </b-row>
        <b-collapse :id="'row-' + source.id" v-model="expanded[sourceIndex]">
          <OwnersList
            v-if="source.landingShares && source.landingShares.length"
            :owner-data="source.landingShares"
            :is-reconciled-data="false"
            :recording-uuid="recordingUUID"
            :resound-id="resoundId"
            :source-index="sourceIndex"
            :is-rep-track="source.reconciliationStatus === landingRecordingReconciliationStatuses.NOT_READY"
          />
        </b-collapse>
      </b-card>
    </div>
    </template>
  </div>
</template>
<script>
import { RESOUND, actionsTabs, conflictModes, landingRecordingReconciliationStatuses } from "@/constants"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { capitalize, startCase } from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import OwnersList from "@/pages/Repertoire/Recordings/RecordingDetail/Owners/OwnersList"
import OwnershipCreateForm from "@/pages/Repertoire/Recordings/RecordingDetail/Owners/OwnershipCreateForm"
import { Pages } from "@/utils/pages"
import ReconciledOwnersList from "@/pages/Repertoire/Recordings/RecordingDetail/Owners/ReconciledOwnersList"
import SourcesList from "@/components/SourcesList"
import moment from "moment"

export default {
  name: "Owners",
  components: {
    SourcesList,
    OwnersList,
    OwnershipCreateForm,
    ValidationProvider,
    ValidationObserver,
    ReconciledOwnersList
  },
  props: {
    title: {
      type: String,
    },
    recordingUUID: String,
    resoundId: String,
    viewMode: {
      type: String,
      default: conflictModes.EDIT,
    },
    solvedManually: {
      type: Array,
      required: false,
    },
    showSource: {
      type: Boolean,
      default: true,
    },
    onDone: {
      type: Function,
      default: () => {}
    },
    hideInvalidated: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    effectiveDate (value) {
      this.$emit("effectiveDate", value)
    },
    viewMode (val) {
      if (val === conflictModes.SOLVE) {
        this.editOwners()
      } else if (val === conflictModes.VIEW) {
        this.cancelEdit()
      }
    },
    recordingOwners () {
      if (this.viewMode === conflictModes.SOLVE) {
        this.editOwners()
      } else if (this.viewMode === conflictModes.VIEW) {
        this.resetUpdateDict()
      }
    },
    hideInvalidated (newVal) {
      this.hideInvalidatedInternal = newVal
    }
  },
  computed: {
    ...mapState("user", ["waiting", "permissions", "user"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    ...mapState("consts", ["countries", "countriesHash", "tariffsHash"]),
    ...mapState("repertoire", [
      "recordingOwners",
      "ownersUpdate",
      "editModeOwners",
      "selecting",
    ]),
    saveButtonCaption: function () {
      return this.viewMode === conflictModes.SOLVE
        ? capitalize(this.$t("solve"))
        : capitalize(this.$t("save"))
    },
    ownersData () {
      return this.editModeOwners ? this.updateDict : this.recordingOwners[this.recordingUUID].owners
    },
    updateDict: {
      get () {
        return this.ownersUpdate
      },
      set (value) {
        this.setOwnersUpdate(value)
      },
    },
    filteredOwnerSources: function () {
      if (this.hasAnySources()) {
        return this.recordingOwners[this.recordingUUID].sources.filter(s => this.showRepTracks || s.reconciliationStatus === landingRecordingReconciliationStatuses.READY)
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions("userActions", ["getUserActionsSummary"]),
    ...mapActions("consts", ["getCountries"]),
    ...mapMutations("alert", ["error", "success"]),
    ...mapMutations("repertoire", [
      "setOwnersUpdate",
      "setEditModeOwners",
    ]),
    canEditOwners () {
      return this.permissions.pages[Pages.RepertoireRecordingDetail].canWrite
    },
    solveAdjustment () {
      this.$bvModal.show("comment-modal")
    },
    showSources (sources) {
      this.currentSources = sources
      this.$refs.sourcesModal.show()
    },
    hasAnySources () {
      return this.recordingOwners[this.recordingUUID] && this.recordingOwners[this.recordingUUID].sources && this.recordingOwners[this.recordingUUID].sources.length
    },
    updateOwners (modal=null, validator=null, comment="") {
      let updateData = this.formatData()
      this.cleanNull(updateData)
      let data = {
        shares: updateData,
        comment: comment
      }
      if (this.solvedManually) {
        this.solvedManually.push(this.recordingUUID)
      }
      const owners = this.recordingOwners[this.recordingUUID] ? this.recordingOwners[this.recordingUUID].owners : []
      const hasConflictBeforeRequest = this.hasConflict(owners)
      this.$api.repertoire.recordingOwnersUpdate(this.recordingUUID, data)
        .then((resp) => {
          if (modal) {
            this.$bvModal.hide(modal)
          }
          this.refresh()
          if (this.viewMode !== conflictModes.SOLVE) {
            this.cancelEdit()
          } else {
            this.resetUpdateDict()
          }

          const resolvedAllConflicts = !this.hasConflict(resp.data.owners)

          if (hasConflictBeforeRequest && resolvedAllConflicts) {
            this.success(capitalize(this.$t("saved_changes_solved")))
            this.onDone()
          } else {
            this.success(capitalize(this.$t("saved_changes")))
          }
          this.getUserActionsSummary({ partial: true })
        })
        .catch(error => {
          const { data, status } = error.response
          let msg = `${capitalize(this.$t("error_occurred"))}.`
          if (status === 400) {
            if (Array.isArray(data.shares)) {
              for (let share in data.shares) {
                let errors = data.shares[share]
                Object.keys(errors).forEach(k => {
                  msg += `\nShare ${parseInt(share) + 1} - ${startCase(k)}: ${errors[k]}`
                })
              }
            } else {
              if (typeof data.shares === "object") {
                Object.keys(data.shares).forEach(k => {
                  msg += `\n${startCase(k)}: ${data.shares[k]}`
                })
              }
            }
          } else {
            Object.keys(data).forEach(k => {
              msg += `\n${startCase(k)}: ${data[k]}`
            })
          }
          this.error(msg)
          if (status === 400 && validator) {
            validator.setErrors(data)
          }
        })
    },
    cancelEdit () {
      this.setEditModeOwners(false)
      this.resetUpdateDict()
    },
    editOwners () {
      this.setEditModeOwners(true)
      this.resetUpdateDict()
    },
    resetUpdateDict () {
      this.updateDict = JSON.parse(JSON.stringify(this.recordingOwners[this.recordingUUID] ? this.recordingOwners[this.recordingUUID].owners : {})) // Deep cloning
      this.updateComment = null
    },
    ...mapActions("repertoire", ["getRecordingOwners"]),
    addOwnership () {
      this.$bvModal.show("ownership-create-form")
    },
    createOwner () {
      this.addingOwnership = false
    },
    cancelAddOwner () {
      this.addingOwnership = false
    },
    hasConflict (owners) {
      return owners.some(d => d.conflict === true)
    },
    cleanNull (data) {
      data.forEach(d => {
        Object.keys(d).forEach(k => {
          if (d[k] === undefined || d[k] === null) {
            delete d[k]
          }
        })
      })
    },
    formatData () {
      return this.updateDict.filter(d => {
        let existingShare = this.recordingOwners[this.recordingUUID].owners.find(x => x.id === d.id)
        return JSON.stringify(d) !== JSON.stringify(existingShare)
      }).map(d => {
        let data = {
          resoundId: d.resoundId,
          split: d.split,
          broadcastStartDate: d.broadcastStartDate,
          broadcastEndDate: d.broadcastEndDate,
          startDate: d.startDate,
          endDate: d.endDate,
          deleted: d.toDelete !== undefined ? new Date(moment.utc().format()) : d.deleted
        }
        if (d.id) {
          data["id"] = d.id
        } else {
          data["tariffs"] = d.tariffs
          data["excludeTariffs"] = d.excludeTariffs
          data["countries"] = d.countries
          data["excludeCountries"] = d.excludeCountries
        }
        return data
      })
    },
    manageSave () {
      if ((JSON.stringify(this.updateDict) !== JSON.stringify(this.recordingOwners[this.recordingUUID].owners))) {
        this.$bvModal.show("comment-modal")
      } else {
        if (this.viewMode === conflictModes.SOLVE) {
          this.error(capitalize(this.$t("no_changes_were_made")))
        } else {
          this.cancelEdit()
        }
      }
    },
    saveOwners () {
      this.$bvModal.hide("comment-modal")
      if (this.viewMode === conflictModes.ADJUSTMENT) {
        this.$emit(
          "solveAdjustment",
          this.updateComment,
          this.$api.actions.resolveOwnershipAdjustmentAction,
          this.$api.comments.ownershipNegativeAdjustmentsCommentsCreate
        )
      } else {
        this.updateOwners(null, this.$refs.commentForm, this.updateComment)
      }
    },
    onSubmit (data, validator) {
      this.updateDict = []
      let comment = ""
      if (data["comment"] != null) {
        comment = data["comment"]
        delete data["comment"]
      }
      this.updateDict.push(data)
      this.updateOwners("ownership-create-form", validator, comment)
    },
    refresh () {
      this.getRecordingOwners(this.recordingUUID)
    },
    onDelete () {
      this.manageSave()
    },
    cancel () {
      for (let index in this.recordingOwners[this.recordingUUID].owners) {
        this.updateDict[index].deleted = this.recordingOwners[this.recordingUUID].owners[index].deleted
      }
      this.$bvModal.hide("comment-modal")
    },
  },
  data () {
    return {
      actionsTabs,
      landingRecordingReconciliationStatuses,
      RESOUND,
      showRepTracks: false,
      effectiveDate: null,
      currentSources: [],
      expanded: [],
      addingOwnership: false,
      conflictModes,
      updateComment: null,
      hideInvalidatedInternal: this.hideInvalidated,
      hideWithoutMandates: true,
    }
  },
  mounted () {
    this.getRecordingOwners(this.recordingUUID)
    this.setEditModeOwners(this.viewMode === conflictModes.SOLVE)
    if (this.countries && !this.countries.length) {
      this.getCountries()
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
    border: 1px solid $gray;
    border-radius: 4px;
  }

.source {
  .card-body {
    padding: 0;
  }

  .source-row {
    padding: 15px;
  }
}

.source:nth-child(even) {
  background-color: $row-shade;
}

.source-row:hover {
  background-color: $table-hover;
}

.green {
  color: $green-darker;
}
</style>
