<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="p-0">
          <label>{{ $t("format_file") | startcase }}</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pl-0 pr-3">
          <export-format-card
              :selected-format="exportFormat"
              :export-format="exportFormats.EXCEL"
              @click.native="setExportFormat(exportFormats.EXCEL)"
            >
          </export-format-card>
        </b-col>
        <b-col class="pl-3 pr-0">
          <export-format-card
              :selected-format="exportFormat"
              :export-format="exportFormats.CSV"
              @click.native="setExportFormat(exportFormats.CSV)"
            >
          </export-format-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="p-0">
          <p class="text-justify mt-3">
            {{ $t("depending_of_how_big_the_report_is") | capitalize  }}.
            <br>
            {{ $t("we_will_send_you_an_email_with_a_link") | capitalize }}.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" class="p-0">
          <b-form-group :label="$t('email') | capitalize">
            <b-form-input v-model="email"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-row class="modal-footer mt-4">
      <b-button class="btn-outline-dark" @click="cancel">
        {{ $t("cancel") | capitalize }}
      </b-button>
      <b-button :disabled="!isValid" class="btn-outline-red" @click="exportData">
        {{ $t("export") | capitalize }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { exportFormats, xlsxMaxCount } from "@/constants"
import {  mapMutations, mapState } from "vuex"
import ExportFormatCard from "@/components/ExportFormatCard"

export default {
  name: "DistributionsExportForm",
  components: { ExportFormatCard },
  props: {
    count: {
      required: true,
      type: Number
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    isValid () {
      return this.email && this.exportFormat
    },
    query () {
      const parsedUrl = new URL(window.location.href)
      return parsedUrl.searchParams.toString()
    },
    xlsxAvailable () {
      return this.count < xlsxMaxCount ? true : false
    },
  },
  data () {
    return {
      email: null,
      exportFormat: null,
      exportFormats,
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    setExportFormat (format) {
      this.exportFormat = format === exportFormats.EXCEL ? (this.xlsxAvailable ? format : null) : format
    },
    exportData () {
      let data = {
        email: this.email,
        query: this.query,
        exportFormat: this.exportFormat,
        user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      return data
    },
    cancel () {
      this.$bvModal.hide("distributions-export-modal")
    },
  },
  mounted () {
    this.email = this.user.email
  },
}
</script>
