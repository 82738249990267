<template>
  <div>
    <list-layout
      ref="listLayout"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
      :apply-filters="getData"
      :search-filter="filters.fuzzySearch"
      :no-apply-btn="true" 
      no-search
    >
      <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              :page="page"
              :count="count"
              :loading="loading"
              reference="recordings"
              v-model="limit"
              @limit="onLimit"
            />
          </div>
        </div>
      </template>
      <!-- Table -->
      <template v-slot:table>
        <v-server-table
          :columns="columns"
          :options="options"
          ref="recordings"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <!-- ResoundID row -->
          <template v-slot:resoundId="{ row }">
            <div class="d-flex">
              <database-link :type="linkTypes.RECORDING" :item-id="row.id" target="_blank">{{ row.resoundId }}</database-link>
            </div>
          </template>

          <!-- Album Names -->
          <template v-slot:albumNames="{ row }">
            <NamesListModal class-names="table-meta mb-2" :values="row.albumNames || []" />
          </template>
        </v-server-table>
      </template>
    </list-layout>
  </div>
</template>

<script>
import { capitalize, upperCase } from "lodash"
import { cartMixin, listRouteMixin, unclaimedMixin } from "@/utils/mixins"
import { linkTypes, perPageItemsSm } from "@/constants"
import DatabaseLink from "@/components/DatabaseLink"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Pagination from "@/components/Pagination"
import { mapMutations } from "vuex"

export default {
  name: "PopupRecordingsList",
  mixins: [cartMixin, listRouteMixin, unclaimedMixin],
  components: {
    DatabaseLink,
    NamesListModal,
    Pagination,
  },
  props: {
    rightsHolderUUID: String,
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    refresh () {
      this.$refs.recordings.getData()
    },
    getData () {
      this.filtersPending = false
      this.$refs.recordings.getData()
    },
  },
  data () {
    return {
      linkTypes,
      updateRouterOnPagination: false,
      loading: false,
      page: 1,
      limit: perPageItemsSm,
      pageNumber: 1,
      count: 0,
      columns: [
        "resoundId",
        "title",
        "artist",
        "isrc",
        "type",
        "albumNames",
      ],
      sortableColumns: ["title", "artist", "isrc"],
      filters: {
        fuzzySearch: {
          value: "",
          defaultValue: "",
        },
      },
      options: {
        allSelected: false,
        sortable: ["title", "artist", "isrc"],
        serverMultiSorting: true,
        headings: {
          resoundId: this.$t("resound_id"),
          title: capitalize(this.$t("title")),
          artist: capitalize(this.$t("artist")),
          isrc: upperCase(this.$t("isrc")),
          albumNames: capitalize(this.$t("album_names")),
        },
        columnsClasses: {
          resoundId: "width-small",
          title: "width-large",
          artist: "width-medium",
          isrc: "width-small",
          albumNames: "width-medium",
        },
        responseAdapter ({ data }) {
          let componentData = this.$parent.$parent.$parent
          if (!this.$parent.options.sortable.length) {
            this.$parent.options.sortable = componentData.sortableColumns
          }
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent
          componentData.pageNumber = this.page
          queryParams["rights_holder_uuids"] = componentData.rightsHolderUUID
          queryParams["fuzzy_search"] = componentData.filters.fuzzySearch.value
          return this.$api.repertoire.recordingsList(queryParams)
        },
      },
    }
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.recordings.$children[0])
  },
}
</script>
<style lang="scss" scoped>
.flex-fixed {
  flex-shrink: 0;
  flex-grow: 0;
}

::v-deep .nav-link {
  padding: 0;
}

.filter-buttons {
  ::v-deep .btn-secondary {
    min-width: 108px;
  }
}
</style>
