<template>
  <div>
    <v-client-table
      :data="sources"
      ref="sourcesTable"
      :columns="columns"
      :options="options"
    >
      <!-- Name -->
      <template v-slot:name="{ row }">
        <router-link
          class="text-info"
          :to="{ name: 'landing-recordings', params: { repFileId: row.id } }"
        >
          {{ row.filename }}
        </router-link>
      </template>

      <!-- Name -->
      <template v-slot:uploader="{ row }">
        <router-link
          class="text-info"
          :to="{ name: 'users-update', params: { uuid: row.uploader.uuid } }"
        >
          {{ row.uploader.firstName + " " + row.uploader.lastName }}
        </router-link>
      </template>

      <!-- Upload date -->
      <template v-slot:uploadDate="{ row }">
        <date-time-cell :dateTime="row.uploadDate" />
      </template>
    </v-client-table>
    <b-row class="modal-footer float-right mt-4">
      <b-button @click="closeModal()" class="btn-outline-red">{{ $t("cancel") | capitalize }}</b-button>
    </b-row>
  </div>
</template>

<script>
import { capitalize } from "lodash"
import { mapGetters } from "vuex"

export default {
  name: "SourcesList",
  data () {
    return {
      options: {
        skin: "table table-striped modal-table",
        headings: {
          name: capitalize(this.$t("name")),
          memberCollective: capitalize(this.$t("mc")),
          daName: this.$t("da_name"),
          daType: this.$t("da_type"),
          "uploader.daAgent": this.$t("da_agent"),
          uploader: capitalize(this.$t("uploader")),
          uploadDate: capitalize(this.$t("upload_date")),
        },
        sortable: [],
        perPage: 10,
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isMC"]),
    columns () {
      let cols = [
        "name",
        "memberCollective",
        "daName",
        "daType",
        "uploader.daAgent",
        "uploader",
        "uploadDate",
      ]
      if (this.isMC) {
        cols.splice(2, 1)
        cols.splice(3, 1)
      }
      return cols
    }
  },
  methods: {
    closeModal () {
      this.$parent.$parent.hide()
    }
  },
  props: {
    sources: {
      type: Array,
      required: true
    }
  }
}
</script>
