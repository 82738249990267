<template>
  <div class="select-group">
    <div class="select-label">
      <span v-if="!deleteMode">{{ `No ${paramIndex + 1}` }}</span>
      <span
        :class="getTrashClass()"
        @click="deleteParameter()"
        v-if="deleteMode"
      >
        <fa-icon size="lg" :icon="['fa', 'trash']" />
      </span>
    </div>
    <b-form-group label-cols="auto" class="mr-3 param-select">
      <b-form-select
        :change="setParam()"
        v-model="selectedValue"
        :options="selectOptions"
        :disabled="disabled"
      ></b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"

export default {
  name: "ParamSelect",
  computed: {
    ...mapState("copyrights", ["tariffs", "deleteMode", "deleted"]),
    ...mapGetters("copyrights", ["getParamData"]),
    currentParam () {
      return this.getParamData({
        tariffId: this.param.tariffId,
        paramId: this.param.paramId,
      })
    },
    paramIsDeleted () {
      return this.deleted.filter((d) => {
        return d.tariffId === this.param.tariffId && d.paramId === this.param.paramId
      }).length !== 0
    },
  },
  data () {
    return {
      dataLoaded: false,
      selectedValue: "",
      selectOptions: ["ROME", "CUSMA", "PC", "WPPT"],
    }
  },
  methods: {
    ...mapActions("copyrights", ["deleteParam"]),
    ...mapMutations("copyrights", ["setParamValue"]),
    ...mapMutations("alert", ["success", "error"]),
    setParam () {
      if (this.dataLoaded) {
        this.setParamValue({
          tariffId: this.param.tariffId,
          paramId: this.param.paramId,
          paramValue: this.selectedValue,
        })
      }
    },
    deleteParameter () {
      let data = {
        tariffId: this.param.tariffId,
        paramId: this.currentParam.id,
      }

      this.deleteParam(data).then((res) => {
        if (res) {
          if (res.success) {
            this.success(res.message)
          } else {
            this.error(res.message)
          }
        }
      })
    },
    getTrashClass () {
      if (this.paramIsDeleted) {
        return "deleted"
      }
      if (this.deleteMode) {
        return "delete"
      }
    },
  },
  props: {
    param: Object,
    paramIndex: Number,
    disabled: Boolean
  },
  watch: {
    param: {
      handler () {
        if (this.dataLoaded) {
          this.selectedValue = this.currentParam.eligibilityParam
        }
      },
      deep: true,
    },
  },
  mounted () {
    this.selectedValue = this.currentParam.eligibilityParam
    this.$nextTick().then(() => {
      this.dataLoaded = true
    })
  },
}
</script>

<style language="scss" scoped>
.param-select {
  padding-right: 35px;
}

.select-group {
  position: relative;
}

.select-label {
  position: absolute;
  top: 6px;
  left: -30px;
  color: grey;
}

.deleted {
  color: red;
}

.delete:hover {
  color: #f78985;
}
</style>
