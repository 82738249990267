<template>
  <div id="title" class="select-meta">
    <!-- Read mode -->
    <span v-if="!editMode">{{ getFormattedValue(fieldValue) }}</span>

    <!-- Input Mode -->
    <div v-else-if="inputActive">
      <!-- YEARS -->
      <div v-if="isYear">
        <ValidationProvider rules="year" v-slot="{ errors, classes }">
          <b-form-input
            :ref="fieldName"
            placeholder="Enter year"
            size="sm"
            :state="classes.valid"
            v-model="fieldValue"
            type="text"
          />
          <span class="text-danger">{{ errors[0] | capitalize }}</span>
        </ValidationProvider>
      </div>
      <!-- ISRC  -->
      <div v-else-if="isISRC">
        <ValidationProvider rules="isrc" v-slot="{ errors, classes }">
          <b-form-input
            :ref="fieldName"
            placeholder="Eg. CAK401400212"
            size="sm"
            :state="classes.valid"
            v-model="fieldValue"
            type="text"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <!-- OTHERS -->
      <div v-else>
        <b-form-input
          :ref="fieldName"
          debounce="500"
          size="sm"
          v-model="fieldValue"
        ></b-form-input>
      </div>
    </div>

    <!-- Select Mode -->
    <div v-else>
      <b-input-group>
        <b-form-select
          v-if="isCountry"
          size="sm"
          v-model="currentCountry"
          :options="countryOptions"
          @change="editSelect()"
        ></b-form-select>
        <b-form-select
          v-else-if="isGenreType"
          size="sm"
          v-model="genre"
          :options="options"
        ></b-form-select>
        <b-form-select
          v-else
          size="sm"
          v-model="fieldValue"
          :options="options"
          @change="editSelect()"
        ></b-form-select>
        <b-input-group-append>
          <span class="add clickable pl-2" v-if="inputAllowed" @click="edit()"
            ><fa-icon size="sm" :icon="['fa', 'pen']"
          /></span>
        </b-input-group-append>
      </b-input-group>
    </div>

    <!-- Cancel icon -->
    <div class="save-cancel mb-3" v-if="editing">
      <fa-icon
        v-if="!isFirstMaker"
        @click="undo()"
        class="cancel"
        size="sm"
        :icon="['fa', 'trash']"
      />
    </div>
  </div>
</template>

<script>
import { genreTypes, rightsholderTypes } from "@/constants"
import { getGenreType, getSubGenre, getType } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import { mapGetters, mapMutations, mapState } from "vuex"
import { ValidationProvider } from "vee-validate"
import { debounce } from "lodash"


export default {
  name: "EditField",
  props: ["fieldName"],
  components: { ValidationProvider },
  data () {
    return {
      firstMakersLoading: false,
      firstMakerOptions: [],
      selectedFirstMakers: [],
      initiallySelectedMakers: [],
    }
  },
  computed: {
    ...mapState("consts", ["countries"]),
    ...mapState("repertoire", [
      "editEnabled",
      "editMode",
      "initialRecordingData",
      "recordingData",
    ]),
    isFirstMaker () {
      return this.fieldName === "firstMakers"
    },
    isCountry () {
      return this.fieldName === "fixationCountry"
    },
    isISRC () {
      return this.fieldName === "isrc"
    },
    isYear () {
      return ["fixationYear", "releaseYear"].includes(this.fieldName)
    },
    isGenreType () {
      return this.fieldName === "genreType"
    },
    genre: {
      get () {
        let genre = this.recordingData[this.fieldName]
        if ([genreTypes.CLASSICAL, genreTypes.JAZZ].includes(genre)) {
          genre = `${genre}_${this.recordingData["subGenre"]}`
        }
        return genre
      },
      set (value) {
        if ([genreTypes.CLASSICAL, genreTypes.JAZZ].includes(value[0])) {
          this.recordingData[this.fieldName] = value[0]
          this.recordingData["subGenre"] = value.substring(2)
          this.setEditField({
            field: "genreType",
            value: true
          })
        } else {
          this.recordingData[this.fieldName] = value
          this.recordingData["subGenre"] = null
        }
      }
    },
    currentCountry: {
      get () {
        return this.recordingData[this.fieldName]
          ? this.recordingData[this.fieldName].id
          : null
      },
      set (val) {
        let newCountry = {
          id: val,
        }

        this.modifyRecordingData({
          field: this.fieldName,
          value: newCountry,
        })
      },
    },
    ...mapGetters("repertoire", ["getFieldOptions"]),
    options () {
      return this.getFieldOptions(this.fieldName)
    },
    countryOptions () {
      return this.countries ? this.countries : null
    },
    fieldValue: {
      get () {
        return this.recordingData[this.fieldName]
      },
      set (val) {
        this.modifyRecordingData({
          field: this.fieldName,
          value: val,
        })
      },
    },
    editing () {
      return this.editEnabled[this.fieldName]
    },
    isEditing () {
      return this.editing && this.editMode
    },
    inputAllowed () {
      return !["type", "fixationCountry", "genreType", "firstMakers"].includes(
        this.fieldName
      )
    },
    inputActive () {
      return this.editing && this.inputAllowed
    },
  },
  created () {
    this.debounceMakers = debounce((query) => this.searchMakers(query), 500)
  },
  methods: {
    ...mapMutations("repertoire", [
      "modifyRecordingData",
      "modifyRecordingDataOptions",
      "setEditField",
      "setEditMode",
    ]),
    searchMakers (query) {
      if (query) {
        this.firstMakersLoading = true
        this.$api.rightsholders.rightsholdersList(
          {
            type: rightsholderTypes.MAKER,
            search: query,
          }
        ).then(response => {
          this.firstMakerOptions = response.data.results.map(fm => { return { "name": fm.name, "id": fm.id }})
          this.firstMakersLoading = false
        })
      } else {
        this.firstMakerOptions = this.initiallySelectedMakers
      }
    },
    getFormattedValue (val) {
      let genre
      switch (this.fieldName) {
      case "type":
        return getType(val)
      case "genreType":
        genre = getGenreType(val)
        if ([genreTypes.CLASSICAL, genreTypes.JAZZ].includes(val)) {
          let subGenre = this.recordingData["subGenre"]
          genre = `${genre} (${getSubGenre(subGenre)})`
        }
        return genre
      case "fixationCountry":
        if (this.countries && val) {
          let country = this.countries.find((c) => c.value == val.id)
          return country ? country.text : val
        } else {
          return val
        }
      default:
        return val
      }
    },
    edit () {
      this.setEditField({
        field: this.fieldName,
        value: true,
      })

      this.modifyRecordingData({
        field: this.fieldName,
        value: null,
      })

      this.$nextTick().then(() => {
        this.$refs[this.fieldName].focus()
      })
    },
    editSelect () {
      this.setEditField({
        field: this.fieldName,
        value: true,
      })

      this.getFieldOptions(this.fieldName)
    },
    undo () {
      this.setEditField({
        field: this.fieldName,
        value: false,
      })

      this.modifyRecordingData({
        field: this.fieldName,
        value: this.initialRecordingData[this.fieldName],
      })
    },
  },
  watch: {
    selectedFirstMakers (val) {
      this.modifyRecordingData({
        field: this.fieldName,
        value: val,
      })
    },
  },
  mounted () {
    if (this.fieldName === "firstMakers") {
      this.selectedFirstMakers = this.recordingData[this.fieldName].map(fm => { return { "name": fm.name, "id": fm.id }})
      this.initiallySelectedMakers = this.selectedFirstMakers
      this.firstMakerOptions = this.selectedFirstMakers
    }
  },
}
</script>

<style lang="scss" scoped>
.select-meta {
  width: 80%;
  position: relative;

  div {
    .add {
      &:hover {
        color: $green-darker;
      }
    }
  }

  .input-group {
    align-items: center;
  }

  .input-group-append {
    min-width: 2rem;
  }
  
  .custom-select {
    border-radius: 4px !important;
    font-size: 1rem;
  }

  .save-cancel {
    position: absolute;
    right: -25px;
    top: 3px;
    cursor: pointer;

    .cancel {
      margin: 6px;

      &:hover {
        color: $red;
      }
    }
  }
}
</style>
