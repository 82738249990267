<template>
  <div>
    <b-row class="heading-container">
      <b-col>
        <h4>
          {{ $t("notification_center") | startcase }}
          <template v-if="userActionCounts.notificationActionsCount">
            ({{ userActionCounts.notificationActionsCount.toLocaleString() }})
          </template>
        </h4>
      </b-col>
    </b-row>
    <b-row align-h="start">
      <b-col cols="4">
        <form-field
          :placeholder="$t('search') | capitalize"
          v-model="search"
          debounce="1000"
          :icon="['fas', 'search']"
        />
      </b-col>
    </b-row>
    <div v-for="(notification, index) in notificationsList" :key="`notification-${index}`">
      <Notification :notification="notification" />
    </div>
    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="userActionCounts.notificationActionsCount"
      :per-page="perPage"
    />
  </div>
</template>

<script>
import Notification from "./Notification"
import { getPerPageItemDefaults } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"
import { mapState } from "vuex"

export default {
  name: "NotificationCenter",
  mixins: [listRouteMixin],
  components: { Notification },
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
  },
  methods: {
    responseAdapter (data) {
      this.notificationsList = data.data.results
    },
    async getData () {
      this.expanded = []
      let response = await this.$api.notifications.notificationsList({ ...this.$route.query })
      this.responseAdapter(response)
    },
  },
  data () {
    return {
      search: "",
      notificationsList: [],
      page: 1,
      perPage: Number(getPerPageItemDefaults(this.$route)),
      filters: {
        search: { value: "", defaultValue: "" },
      }
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    search (value) {
      this.changeFilter("search", value)
      this.applyFilters()
    },
    page (value) {
      this.onPagination(value)
      this.getData()
    },
    perPage: {
      handler (value) {
        this.updateRouterPagination("limit", value)
      },
      immediate: true
    },
  },
}
</script>
