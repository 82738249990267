<template>
  <div>
    <b-container>
      <b-row class="accordion p-0 mb-2">
        <b-col cols="12" class="p-0">
          <b-card no-body class="mb-0">
            <b-card-header class="p-2">
              <b-form-checkbox v-model="simpleExport">{{ $t("simple_repertoire_export") | capitalize }}</b-form-checkbox>
            </b-card-header>
            <b-collapse id="option-1" v-model="simpleExport" accordion="accordion"></b-collapse>
          </b-card>
        </b-col>
        <b-col cols="12" class="p-0">
          <b-card no-body class="mb-1">
            <b-card-header class="p-2">
              <b-form-checkbox v-model="sisterSocietyExport">{{ $t("export_repertoire_for_ss") | capitalize }}</b-form-checkbox>
            </b-card-header>
            <b-collapse id="option-2" v-model="sisterSocietyExport" accordion="accordion">
              <b-card-body>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                      :label="$t('sister_society') | startcase"
                      label-class="font-weight-bold"
                  >
                    <b-form-select v-model="agreement" :options="sisterSocietyAgreements">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group
                      :disabled="rightsholderTypeDisabled"
                      :label="$t('rightsholder') | startcase"
                      label-class="font-weight-bold"
                      class="mb-0"
                      v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio-group
                        class="pt-1 btn-group-vertical"
                        :options="rhTypes"
                        v-model="rightsholderType"
                        :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                      :label="$t('period') | startcase"
                      label-class="font-weight-bold"
                      v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio v-model="selectedPeriod" :aria-describedby="ariaDescribedby" name="period"
                                  :value="periods[0]">{{ periods[0] | startcase }}
                    </b-form-radio>
                    <b-form-radio v-model="selectedPeriod" :aria-describedby="ariaDescribedby" name="period"
                                  :value="periods[1]">{{ periods[1] | startcase }}
                    </b-form-radio>
                    <b-form-group v-if="selectedPeriod === periods[1]">
                      <date-picker
                          class="w-90 ml-4"
                          v-model="dateRange"
                          range
                          :format="$config.DATE_FORMAT"
                      />
                    </b-form-group>
                    <div v-if="lastRepertoireExport">
                      <b-form-radio v-model="selectedPeriod" :aria-describedby="ariaDescribedby" name="period"
                                    :value="periods[2]">{{ periods[2] | startcase }}
                      </b-form-radio>
                      <p class="small ml-4">
                        {{ getDateTime(lastRepertoireExport.created) }} ~ {{ $t("today") | capitalize }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="p-0">
          <label>{{ $t("format_file") | startcase }}</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" class="pl-0 pr-3">
          <export-format-card
              :selected-format="exportFormat"
              :export-format="exportFormats.EXCEL"
              @click.native="setExportFormat(exportFormats.EXCEL)"
            >
          </export-format-card>
        </b-col>
        <b-col cols="4" class="pl-3 pr-3">
          <export-format-card
              :selected-format="exportFormat"
              :export-format="exportFormats.CSV"
              @click.native="setExportFormat(exportFormats.CSV)"
            >
          </export-format-card>
        </b-col>
        <b-col cols="4" class="pl-3 pr-0">
          <!--          TODO: It should be used in the future release 6-->
          <!--          <export-format-card v-if="!exportUnclaimed"-->
          <!--              :selected-format="exportFormat"-->
          <!--              :export-format="exportFormats.DDEX"-->
          <!--              @click.native="setExportFormat(exportFormats.DDEX)"-->
          <!--           >-->
          <!--            <template v-slot:title>DDEX</template>-->
          <!--            <template v-slot:text>-->
          <!--              DDEX Standard Format-->
          <!--            </template>-->
          <!--          </export-format-card>-->
          <export-format-card
            v-if="!exportUnclaimed"
            :selected-format="exportFormat"
            :export-format="exportFormats.DDEX"
            disabled
           >
          </export-format-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="p-0">
          <p class="text-justify mt-3">
            {{ $t("depending_of_how_big_the_repertoire_is") | capitalize  }}.
            <br>
            {{ $t("we_will_send_you_an_email_with_a_link") | capitalize }}.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" class="p-0">
          <b-form-group :label="$t('email') | capitalize">
            <b-form-input v-model="email"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-row class="modal-footer mt-4">
      <b-button class="btn-outline-dark" @click="cancel">
        {{ $t("cancel") | capitalize }}
      </b-button>
      <b-button :disabled="isValid" class="btn-outline-red" @click="exportReport">
        {{ $t("export") | capitalize }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { exportFormats, rightsholderTypes } from "@/constants"
import { mapActions, mapMutations, mapState } from "vuex"
import ExportFormatCard from "@/components/ExportFormatCard"
import { capitalize } from "lodash"

export default {
  name: "RecordingsExportForm",
  components: { ExportFormatCard },
  props : {
    exportUnclaimed: {
      required: true
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("consts", ["sisterSocietyAgreements"]),
    isValid () {
      if (this.exportUnclaimed) {
        return !this.email || !this.exportFormat
      }
      return !this.agreementId || !this.rightsholderType || !this.email || !this.exportFormat
    },
    agreementId: {
      get: function () {
        return this.agreement
      },
      set: function (val) {
        if (val) {
          this.agreement = val}
      },
    },
    query () {
      const parsedUrl = new URL(window.location.href)
      return parsedUrl.searchParams.toString()
    },
  },
  data () {
    return {
      simpleExport: true,
      sisterSocietyExport: false,
      lastRepertoireExport: null,
      agreement: null,
      email: null,
      rhTypes: Object.values(rightsholderTypes).map(rt => {
        return { value: rt, text: capitalize(rt) }
      }),
      rightsholderType: rightsholderTypes.PERFORMER,
      rightsholderTypeDisabled: false,
      dateRange: [
        new Date(),
        new Date(),
      ],
      periods: [
        "entire_period",
        "custom_dates",
        "last_export",
      ],
      selectedPeriod: "entire_period",
      startDate: null,
      endDate: null,
      exportFormat: null,
      exportFormats,
    }
  },
  methods: {
    ...mapActions("consts", ["getSisterSocietyAgreements"]),
    ...mapMutations("alert", ["success", "error"]),
    setExportFormat (format) {
      this.exportFormat = format
    },
    setLastExport () {
      this.$api.repertoire.repertoireExportLast({
        agreement_id: this.agreementId,
        rightsholder_type: this.rightsholderType,
      }).then((response) => {
        this.lastRepertoireExport = response.data
      })
    },
    getDateTime (value) {
      return value ? new Date(value).toLocaleString() : ""
    },
    exportSisterSocietyRepertoire () {
      let data = {
        agreementId: this.agreementId,
        email: this.email,
        exportFormat: this.exportFormat,
        rightsholderType: this.rightsholderType,
      }
      let startDate = null
      let endDate = null
      if (this.selectedPeriod === this.periods[1]) {
        startDate = new Date(this.dateRange[0])
        endDate = new Date(this.dateRange[1])
        endDate.setHours(24,0,0,0)
        startDate.setHours(0,0,0,0)
      } else if (this.selectedPeriod === this.periods[2]) {
        startDate = this.lastRepertoireExport.created ? new Date(this.lastRepertoireExport.created) : null
      }
      if (startDate) {
        data.startDate = startDate.toISOString()
      }
      if (endDate) {
        data.endDate = endDate.toISOString()
      }

      if (startDate || endDate) {
        data.user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      }

      this.$api.repertoire.sisterSocietyExport(data)
        .then(response => {
          let msg = `Repertoire export started: ${response.statusText}`
          this.success(msg)
          this.$bvModal.hide("repertoire-export-modal")
        })
        .catch(error => {
          let msg = `Error repertoire export. ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    exportUnclaimedRecordings () {
      let data = {
        email: this.email,
        query: this.query,
        exportFormat: this.exportFormat,
      }
      this.$api.repertoire.unclaimedRecordingExport(data)
        .then(response => {
          let msg = `Unclaimed recordings export started: ${response.statusText}`
          this.success(msg)
          this.$bvModal.hide("repertoire-export-modal")
        })
        .catch(error => {
          let msg = `Export error: ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    exportRepertoire () {
      let data = {
        email: this.email,
        query: this.query,
        exportFormat: this.exportFormat,
      }
      this.$api.repertoire.export(data)
        .then(response => {
          let msg = `Recordings export started: ${response.statusText}`
          this.success(msg)
          this.$bvModal.hide("repertoire-export-modal")
        })
        .catch(error => {
          let msg = `Export error: ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    exportReport () {
      if (this.exportUnclaimed) {
        this.exportUnclaimedRecordings()
      } else if (this.sisterSocietyExport) {
        this.exportSisterSocietyRepertoire()
      } else if (this.simpleExport) {
        this.exportRepertoire()
      }
    },
    cancel () {
      this.$bvModal.hide("repertoire-export-modal")
    },
  },
  watch: {
    selectedPeriod: function (val) {
      if (val === this.periods[2] && this.lastRepertoireExport) {
        this.startDate = this.lastRepertoireExport.startDate
        this.endDate = this.lastRepertoireExport.endDate
        this.exportFormat = this.lastRepertoireExport.exportFormat
        this.agreementId = this.lastRepertoireExport.agreement
        this.rightsholderType = this.lastRepertoireExport.rightsholderType
        this.email = this.lastRepertoireExport.email
      }
    },
    agreement: function (val) {
      let agreementObj = this.sisterSocietyAgreements.find(ag => ag.value === val)
      this.rightsholderTypeDisabled = agreementObj.isMakers !== agreementObj.isPerformers
      this.rightsholderType = agreementObj.isMakers && !agreementObj.isPerformers ? rightsholderTypes.MAKER : rightsholderTypes.PERFORMER
    },
    sisterSocietyAgreements: function (val) {
      if (val.length) {
        this.agreement = val[0].value
        this.setLastExport()
      }
    },
    rightsholderType: function (val) {
      if (this.agreementId && val) {
        this.setLastExport()
      }
    },
    lastRepertoireExport: function (val) {
      this.exportFormat = val.exportFormat
    },
  },
  mounted () {
    if (! this.exportUnclaimed) {
      this.getSisterSocietyAgreements()
    }
    this.email = this.user.email
  },
}
</script>
