<template>
  <div>
    <template v-if="!!dateTime">
      <div>
        {{ parseDate(dateTime, this.$config.DATE_FORMAT) }}
      </div>
      <div class="text-gray">
        {{ parseDate(dateTime, 'h:mm') }}
      </div>
    </template>
  </div>
</template>

<script>
import parseDate from "@/utils/date-parser"

export default {
  name: "DateTimeCell",
  methods: {
    parseDate
  },
  props: ["dateTime"]
}
</script>