import { API } from "./utils"


export const users = {
  usersList: (params=null) => API.get("/parties/users/",
    { params: params }),
  usersSearch: (params=null) => API.get("/parties/users/search/",
    { params: params }),
  userRetrieve: (uuid) => API.get(`/parties/users/${uuid}/`),
  userActivate: (uuid) => API.post(`/parties/users/${uuid}/activate/`),
  userDeactivate: (uuid) => API.post(`/parties/users/${uuid}/deactivate/`),
  userUpdate: (uuid, data) => API.patch(`/parties/users/${uuid}/`, data),
  usersDelete: (uuid) => API.delete(`/parties/users/${uuid}/`),
  usersCreate: (data) => API.post("/parties/users/", data),
  usersRoles: (params=null) => API.get("/users/roles/", { params }),
  permissionOptions: (params=null) => API.get("/users/roles/permissions/", { params }),
  usersSetLandingPage: (data) => API.put("/parties/users/set_preferred_landing_page/", data),
  usersSetMember: (data) => API.post("/parties/users/set_selected_member/", data),
  updatePermissions: (data) => API.put("/users/roles/update_permissions/", data),
  createUserRole: (data) => API.post("/users/roles/", data),
  updateUserRole: (uuid, data) => API.put(`/users/roles/${uuid}/`, data),
  deleteUserRole: (uuid) => API.delete(`/users/roles/${uuid}/`),
  copyUserRole: (uuid, data) => API.post(`/users/roles/${uuid}/copy/`, data)
}
