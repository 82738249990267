var render = function render(){var _vm=this,_c=_vm._self._c;return _c('list-layout',{attrs:{"no-search":true,"no-counts":true},scopedSlots:_vm._u([{key:"beforeFilters",fn:function(){return [_c('b-row',[_c('b-col',[_c('filter-builder',{attrs:{"id":_vm.userPreferenceNames.USAGES_FILTER,"filters":_vm.getFiltersWithOptions(),"apply-filters":_vm.applyFilters,"reset-filters":_vm.resetFilters,"filters-pending":_vm.filtersPending,"filters-applied":_vm.filtersApplied}})],1)],1)]},proxy:true},{key:"table",fn:function(){return [_c('t-table',{ref:"usages",attrs:{"id":"recording-usages","columns":_vm.columns,"options":_vm.options,"limit":_vm.limit},on:{"loaded":_vm.onLoaded,"loading":_vm.onLoading,"pagination":_vm.onPagination,"sorted":_vm.onSorted},scopedSlots:_vm._u([{key:"counts",fn:function(){return [_c('pagination',{staticClass:"smaller p-0",attrs:{"reference":"repertoire","page":_vm.page,"count":_vm.count,"loading":_vm.loading},on:{"limit":_vm.onLimit},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})]},proxy:true},{key:"copyright",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.copyrights.find(c => c.code === row.copyright).text)+" ")]}},{key:"tariff",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.tariffs.find(t => t.code === row.tariff).text)+" ")]}},{key:"title",fn:function({ row }){return [(row.pendingChallenge)?_c('router-link',{staticClass:"text-red small mr-2",attrs:{"to":{
            name: 'user-actions',
            params: { type: _vm.actionsTabs.USAGE_CHALLENGES },
            query: { fuzzy_search: _vm.resoundId },
          }}},[_c('fa-icon',{staticClass:"text-red",attrs:{"icon":['fas', 'exclamation-circle'],"title":_vm._f("capitalize")(_vm.$t('usage_challenge'))}})],1):_vm._e(),_c('router-link',{staticClass:"text-info clickable",attrs:{"to":{
          name: 'consolidated-usage-list',
          query: {
            year: null,
            fuzzy_search: row.uniqueUsageUuid
          }
        }}},[_vm._v(_vm._s(row.title))])]}}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }