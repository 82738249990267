<template>
  <div>
    <b-modal 
      id="challengeModal"
      ref="challengeModal"
      modal-class="custom-modal edit"
      size="lg"
      centered
      hide-footer>
      <template #modal-title>
        <h4 class="d-flex align-items-center mb-0">
          <b-img height="30" :src="require('@/assets/pen_circle.svg')" class="pr-2"/>
          {{ $t('make_comment') | capitalize }}
        </h4>
      </template>
      <ValidationObserver ref="commentForm" v-slot="{ handleSubmit }">
        <ValidationProvider vid="comment" name="comment" rules="required" v-slot="{ errors }">
          <b-form @submit.prevent="handleSubmit(challengeRightsHolder)">
            <b-form-textarea
              size="lg"
              class="mb-4"
              debounce="500"
              name="comment"
              :placeholder="$t('make_comment') | capitalize"
              v-model="challengeComment"
              rows="5"
            />
            <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
            <b-row class="modal-footer mt-4">
              <b-button class="btn-outline-dark" @click="$bvModal.hide('challengeModal')">
                {{ $t("cancel") | capitalize }}
              </b-button>
              <b-button class="btn-outline-red" type="submit">
                {{ $t("challenge") | capitalize }}
              </b-button>
            </b-row>
          </b-form>
        </ValidationProvider>
      </ValidationObserver>
    </b-modal>

    <b-table
      class="className"
      :items="data"
      :fields="fields"
      fixed
    >
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>
      <!-- resoundId -->
      <template v-slot:cell(resoundId)="row">
        <database-link :type="linkTypes.RIGHTS_HOLDER" :item-id="row.item.id">{{ row.item.resoundId }}</database-link>
      </template>
      <template v-slot:cell(memberCollectives)="row">
        {{ row.item.memberCollectives.map(mc => mc.name).join(", ") }}
      </template>
      <template v-slot:cell(nationalities)="row">
        {{ row.item.nationalities ? row.item.nationalities.map(n => isObject(n) ? n.alpha2 : n).join(", ") : "" }}
      </template>
      <template v-slot:cell(aliases)="row">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="row.item.aliases || []"
          :max-items=3
        />
      </template>
      <template v-slot:cell(dateOfBirth)="row">
        {{ row.item.dateOfBirth ? parseDate(row.item.dateOfBirth, $config.DATE_FORMAT) : "" }}
      </template>
      <template v-slot:cell(dateOfDeath)="row">
        {{ row.item.dateOfDeath ? parseDate(row.item.dateOfDeath, $config.DATE_FORMAT) : "" }}
      </template>
      <template v-slot:cell(mandates)="row">
        {{ row.item.membership ? sum(row.item.membership.map(m => m.mandates.length)) : "-" }}
      </template>
      <template v-slot:cell(proprietaryId)="row">
        {{ row.item.proprietaryId }}
          <div v-if="row.item.challengeId && rightsholderType">
            <router-link class="text-red small"
              :to="{ name: 'rightsholder-challenge', params: { uuid: row.item.challengeId, challengeType: rightsholderType } }"
            >
            {{ $t("challenge_pending") | capitalize }}
            </router-link>
          </div>

      </template>
      <template v-slot:cell(actions)="row">
          <b-button v-if="permissions.actions.canChallengeRightsHolder" :disabled="!challengeAvailable(row)" @click="challengeRightsHolderPopup(row)">{{ $t("challenge") | capitalize }}</b-button>
      </template>
      <template v-slot:cell(sources)="row">
        <sources :sources="row.item.sources" :sourceType="row.item.type"></sources>
      </template>
    </b-table>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { capitalize, isObject, sum } from "lodash"
import { linkTypes, rightsholderTypes } from "@/constants"
import { mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Sources from "@/components/Sources.vue"
import parseDate from "@/utils/date-parser"

export default {
  name: "RightsholderTable",
  components: {
    DatabaseLink,
    NamesListModal,
    Sources,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    className: {
      type: String,
    },
    fields: {
      type: Array,
      required: true,
    },
    rightsholderType: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      linkTypes,
      rhToChallenge: null,
      challengeComment: "",
    }
  },
  computed: {
    ...mapState("user", ["permissions"])
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    parseDate,
    isObject,
    sum,
    challengeAvailable (row) {
      return !row.challengeId && this.data.filter(r => !r.challengeId).length > 1
    },
    challengeRightsHolderPopup (row) {
      this.rhToChallenge = row.item
      this.$refs.challengeModal.show()
    },
    challengeRightsHolder () {
      if (this.rhToChallenge) {
        let meth = this.rightsholderType === rightsholderTypes.PERFORMER ? this.$api.repertoire.performerChallengesCreate : this.$api.repertoire.makerChallengesCreate
        meth({
          [this.rightsholderType === rightsholderTypes.PERFORMER ? "performerId" : "makerId"]: this.rhToChallenge.id,
          comments: [{ comment: this.challengeComment }]
        }).then((response) => {
          this.success(capitalize(this.$t("saved_changes")))
          this.$refs.challengeModal.hide()
          this.challengeComment = ""
          this.rhToChallenge.challengeId = response.data.id
          this.rhToChallenge = null
        }).catch(error => {
          this.error(`Error creating challenge. ${JSON.stringify(error.response.data)}`)
        })
      }

    }
  }
}
</script>
