import { render, staticRenderFns } from "./RecordingsList.vue?vue&type=template&id=444b91b8&scoped=true&"
import script from "./RecordingsList.vue?vue&type=script&lang=js&"
export * from "./RecordingsList.vue?vue&type=script&lang=js&"
import style0 from "./RecordingsList.vue?vue&type=style&index=0&id=444b91b8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "444b91b8",
  null
  
)

export default component.exports