function arrayToHash (array, key="id") {
  return array.reduce((hash, item) => {
    hash[item[key]] = item
    return hash
  }, {})
}

const state = {
  // Use Types
  useTypes: [],
  useTypesHash: {},
  // Countries
  countries: [],
  countriesHash: {},
  // Languages
  languages: [],
  languagesHash: {},
  // Contributors
  contributors: [],
  contributorsHash: {},
  // Publishers
  publishers: [],
  publishersHash: {},
  // Tariffs
  tariffs: [],
  tariffsHash: {},
  // Stations
  stations: [],
  stationsHash: {},
  // Copyrights
  copyrights: [],
  copyrightsHash: {},
  // Member Collectives
  memberCollectives: [],
  memberCollectivesHash: {},
  // User roles
  userRoles: [],
  userRolesHash: {},
  // Sister Societies Agreements
  sisterSocietyAgreements: [],
  sisterSocietyAgreementsHash: {},
}

const mutations = {
  setUseTypes (state, useTypes) {
    state.useTypes = useTypes
    state.useTypesHash = arrayToHash(useTypes, "code")
  },
  setCountries (state, countries) {
    state.countries = countries.sort((a,b) => {
      return a.text.localeCompare(b.text)
    })
    state.countriesHash = arrayToHash(countries, "text")
  },
  setTariffs (state, tariffs) {
    state.tariffs = tariffs
    state.tariffsHash = arrayToHash(tariffs, "text")
  },
  setStations (state, stations) {
    state.stations = stations
    state.stationsHash = arrayToHash(stations, "text")
  },
  setCopyrights (state, copyrights) {
    state.copyrights = copyrights
    state.copyrightsHash = arrayToHash(copyrights, "text")
  },
  setLanguages (state, languages) {
    state.languages = languages
    state.languagesHash = arrayToHash(languages)
  },
  setContributors (state, contributors) {
    state.contributors = contributors
    state.contributorsHash = arrayToHash(contributors)
  },
  setPublishers (state, publishers) {
    state.publishers = publishers
    state.publishersHash = arrayToHash(publishers)
  },
  setMemberCollectives (state, memberCollectives) {
    state.memberCollectives = memberCollectives
    state.memberCollectivesHash = arrayToHash(memberCollectives)
  },
  setUserRoles (state, userRoles) {
    state.userRoles = userRoles
    state.userRolesHash = arrayToHash(userRoles, "label")
  },
  setSisterSocietyAgreements (state, sisterSocietyAgreements) {
    state.sisterSocietyAgreements = sisterSocietyAgreements
    state.sisterSocietyAgreementsHash = arrayToHash(sisterSocietyAgreements)
  }
}

const actions = {
  getStations (state) {
    return this._vm.$api.broadcasters.stationsList().then((d) => {
      state.commit(
        "setStations",
        d.data.results.map((c) => {
          return {
            text: c.name,
            value: c.id,
          }
        })
      )
    })
  },
  getCountries (state, params=null) {
    return this._vm.$api.repertoire.countries(params).then((d) => {
      state.commit(
        "setCountries",
        d.data.map((c) => {
          return {
            text: c.name,
            value: c.id,
            code: c.alpha2,
            alpha2: c.alpha2,
            alpha3: c.alpha3,
          }
        })
      )
    })
  },
  getTariffs (state) {
    return this._vm.$api.copyrights.tariffsList().then((d) => {
      state.commit(
        "setTariffs",
        d.data.map((c) => {
          return {
            text: c.name,
            value: c.id,
            code: c.code,
            copyright: c.copyright,
            proxies: c.proxies,
          }
        })
      )
    })
  },
  getCopyrights (state) {
    return this._vm.$api.copyrights.copyrightsList().then((d) => {
      state.commit(
        "setCopyrights",
        d.data.map((c) => {
          return {
            text: c.name,
            value: c.id,
            code: c.code,
          }
        })
      )
    })
  },
  getSisterSocietyAgreements (state) {
    return this._vm.$api.members.sisterSocietyAgreementsList().then((d) => {
      state.commit(
        "setSisterSocietyAgreements",
        d.data.map((ssa) => {
          return {
            text: ssa.name,
            value: ssa.id,
          }
        })
      )
    })
  },
  getUserRoles (state) {
    return this._vm.$api.users.usersRoles().then(response => {
      state.commit("setUserRoles", response.data)
    })
  }
}

export const consts = {
  namespaced: true,
  state,
  mutations,
  actions
}
