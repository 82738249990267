import { render, staticRenderFns } from "./LandingRightsHoldersList.vue?vue&type=template&id=a79c3c76&scoped=true&"
import script from "./LandingRightsHoldersList.vue?vue&type=script&lang=js&"
export * from "./LandingRightsHoldersList.vue?vue&type=script&lang=js&"
import style0 from "./LandingRightsHoldersList.vue?vue&type=style&index=0&id=a79c3c76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a79c3c76",
  null
  
)

export default component.exports