<template>
  <list-layout
      :isChild="true"
      :title="($t('eligibility_conflicts')) | startcase"
      :count="(!userAction ? '' : userActionCounts.eligibilityActionsCount)"
      ref="listLayout"
      :apply-filters="applyFilters"
      :reset-filters="customResetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"

  >
    <!-- Search -->
    <template v-slot:search>
      <form-field
          :placeholder="$t('search') | capitalize"
          :enter-action="applyFilters"
          v-model="filters.fuzzy_search.value"
          :icon="['fas', 'search']"
      />
    </template>

    <template v-slot:filters>
      <b-row align-h="end">
        <b-col>
          <b-form-group :label="$t('level') | capitalize" label-class="pb-0" label-cols="4">
            <b-form-select v-model="filters.level.value" :options="eligibilityLevels"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('parameters') | capitalize" label-class="pb-0" label-cols="auto">
            <multi-select
              v-model="selectedParameters"
              :close-on-select="false"
              :multiple="true"
              :options="eligibilityParams"
              label="text"
              track-by="value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="pt-2">
          <b-form-checkbox v-model="actionStatus" switch>
            {{ $t("show_resolved") | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2 mr-2">
          <b-col>
            <b-form-group :label="filters.proprietaryId.label" label-cols="4">
              <b-form-input v-model="filters.proprietaryId.value" ref="proprietaryId" :enter-action="applyFilters"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="permissions.actions.hasAllRhPermission" class="pl-1 pr-0">
            <b-form-group :label="$t('member_collective') | capitalize" label-cols="5" class="pl-2">
              <b-form-select
                v-model="filters.proprietaryIdMemberCollective.value"
                :options="memberCollectives.map(x => ({ text: x.name, value: x.uuid }))"
                :disabled="!filters.proprietaryId.value"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">
                    {{ $t("all_member_collectives") | capitalize }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3"></b-col>
      </b-row>
    </template>

    <!-- Counts -->
    <template v-slot:counts>
      <div class="row align-items-center">
        <div class="col d-flex">
          <pagination
              class="smaller p-0"
              :page="page"
              :count="count"
              :loading="loading"
              reference="usages"
              v-model="limit"
              @limit="onLimit"
          />
        </div>
      </div>
    </template>
    <!-- Table -->
    <template v-slot:table>
      <crescendo-split-panes ref="panes">
        <template v-slot:top>
          <v-server-table
              :columns="columns"
              :options="options"
              ref="conflicts"
              @row-click="setSelectedEligibilityConflict"
              @loaded="onLoaded"
              @loading="onLoading"
              @pagination="onPagination"
          >
            <template v-slot:inProgress="{ row }">
              <modal
                :id="`confirmation-modal-solved-${row.id}`"
                :customTtitle="$t('user_action_solved') | capitalize"
                modal-class="custom-modal edit"
                modalType="edit"
                size="md"
                centered
                hide-footer
                hide-header-close
                no-close-on-esc
                no-close-on-backdrop
              >
                <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
                <b-row class="float-right modal-footer mt-4">
                  <b-button @click="blinkAndHide(row.id, null, getData)" class="btn-outline-red">
                    {{ $t("accept") | capitalize }}
                  </b-button>
                </b-row>
              </modal>
              <fa-icon
                v-if="isInProgressFrontend(row.id) || (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))"
                :icon="['fa', 'user-edit']"
                color="red"
              />
            </template>
            <!-- Status -->
            <template v-slot:status="{ row }">
              <status
                v-if="(isInProgressFrontend(row.id) ||
                (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))
                ) && !isSolved(row.id)"
                :text="$t('in_progress') | capitalize"
                :icon="['fas', 'question-circle']"
                color="orange"
              />
              <status
                v-else-if="(isPending(row.status) || isReleasedFrontend(row.id)) && !isSolved(row.id)"
                :text="$t('conflict') | capitalize"
                :icon="['fas', 'exclamation-circle']"
                color="red"
              />
              <status
                v-else
                :text="$t('resolved') | capitalize"
                :icon="['fas', 'check-circle']"
                color="green"
              />
            </template>
            <!-- RS ID -->
            <template v-slot:relatedObject.resoundId="{ row }">
              <database-link :type="linkTypes.RECORDING" :item-id="row.relatedObject.id">
                {{ row.relatedObject.resoundId }}
              </database-link>
            </template>
          </v-server-table>
        </template>

        <template v-slot:bottom>
          <div v-if="bottomPaneVisible" class="overflow-auto h-100 overflow-x-hidden">
            <recording-detail
                v-if="recordingUuid"
                :recordingId="recordingUuid"
                :solved-manually="solvedManually"
                type="general-details"
                :conflict-uuid="conflictUuid"
                :eligibility-conflict-resolved="getActionStatus() || filtersPending"
                :recording-eligibility-conflict="true"
                @resolved="updateEligibilityConflictInfo"
            />
            <h3 v-if="conflictUuid" class="text-red">{{ $t("comments") | startcase }}</h3>
            <comments
              v-if="conflictUuid"
              :createEndpoint="$api.comments.recordingEligibilityConflictCommentCreate"
              :listEndpoint="$api.comments.recordingEligibilityConflictCommentsList"
              :uuid="conflictUuid"
              :editing=false
              :deleting=false
            />
          </div>
        </template>
      </crescendo-split-panes>
    </template>
  </list-layout>
</template>
<script>
import {
  actionsTabs,
  eligibilityLevels,
  eligibilityParams,
  filterTypes,
  getPerPageItemDefaults,
  linkTypes,
  usageMatchingStatuses,
  userActionNotifications,
  userActionStatuses
} from "@/constants"
import { capitalize, findIndex, get, startCase, upperCase } from "lodash"
import { cartMixin, listRouteMixin } from "@/utils/mixins"
import { mapActions, mapMutations, mapState } from "vuex"
import CrescendoSplitPanes from "@/components/CrescendoSplitPanes"
import DatabaseLink from "@/components/DatabaseLink.vue"
import Pagination from "@/components/Pagination"
import RecordingDetail from "@/pages/Repertoire/Recordings/RecordingDetail/RecordingDetail"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "EligibilityConflictList",
  mixins: [cartMixin, listRouteMixin, websocketsMixin],
  components: {
    DatabaseLink,
    CrescendoSplitPanes,
    Pagination,
    RecordingDetail,
  },
  props: {
    userAction: {
      default: true,
    },
  },
  computed: {
    ...mapState("user", ["permissions"]),
    ...mapState("consts", ["countries", "memberCollectives"]),
    ...mapState("userActions", ["wsRecordingEligibilityConflictStatuses"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    selectedParameters: {
      get () {
        return this.internalSelectedParameters
      },
      set (value) {
        this.internalSelectedParameters = value
        this.filters.parameters.value = value.map(p => { return p.value }).join()
      }
    },
    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
    actionStatus: {
      get () {
        return this.getActionStatus()
      },
      set (value) {
        this.filters.status.value = value ? userActionStatuses.DONE : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
      },
    },
    bottomPaneVisible () {
      return this.recordingUuid || this.conflictUuid
    },
  },
  watch: {
    conflictUuid (newVal, oldVal) {
      if(this.permissions.actionsTabs[actionsTabs.ELIGIBILITY_CONFLICTS].canWrite) {
        if (oldVal) {
          this.onClosed(oldVal)
        }
        if (newVal) {
          this.onOpened(newVal)
        }
      }
    },
    "filters.proprietaryId.value": function (newVal) {
      if (newVal === null) {
        this.filters.proprietaryIdMemberCollective.value = null
      }
    },
  },
  data () {
    return {
      linkTypes,
      eligibilityLevels,
      eligibilityParams,
      filterTypes,
      recordingUuid: null,
      conflictUuid: null,
      manualSize: 0,
      loading: false,
      page: 1,
      data: [],
      usedFilters: [],
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      usageMatchingStatuses: usageMatchingStatuses,
      internalSelectedParameters: [],
      columns: [
        "inProgress",
        "relatedObject.resoundId",
        "relatedObject.title",
        "relatedObject.artist",
        "relatedObject.isrc",
        "relatedObject.playCount",
        "relatedObject.weightedPlayCount",
        "relatedObject.royaltiesPaid",
        "relatedObject.estimatedTrackValue",
        "status",
      ],
      commentsTableColumns: [
        "created",
        "createdBy",
        "createdBy.memberCollective",
        "comment",
        "suggestedMatchId",
      ],
      commentsTableHeadings: {
        created: capitalize(this.$t("when")),
        createdBy: capitalize(this.$t("reviewer")),
        "createdBy.memberCollective": startCase(this.$t("mc")),
        comment: capitalize(this.$t("comment")),
        suggestedMatchId: this.$t("suggested_match_id"),
      },
      filters: {
        fuzzy_search: { value: "", defaultValue: "" },
        status: {
          value: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS],
          defaultValue: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
        },
        level: {
          value: null,
          defaultValue: 3,
        },
        parameters: {
          value: null,
          defaultValue: null,
        },
        proprietaryId: {
          value: null,
          defaultValue: null,
          label: this.$t("proprietary_id"),
          type: filterTypes.CUSTOM,
        },
        proprietaryIdMemberCollective: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          hidden: true,
        },
      },
      options: {
        sortable: [],
        headings: {
          "inProgress": "",
          "relatedObject.resoundId": this.$t("resound_id"),
          "relatedObject.title": capitalize(this.$t("title")),
          "relatedObject.artist": capitalize(this.$t("artist")),
          "relatedObject.isrc": upperCase(this.$t("isrc")),
          "relatedObject.playCount": capitalize(this.$t("playcount")),
          "relatedObject.weightedPlayCount": capitalize(this.$t("weighted_playcount")),
          "relatedObject.royaltiesPaid": capitalize(this.$t("royalties_paid")),
          "relatedObject.estimatedTrackValue": capitalize(this.$t("estimated_track_value")),
          status: capitalize(this.$t("status")),
        },
        columnsClasses: {
          "inProgress": "width-2",
          "relatedObject.resoundId": "width-mini",
          "relatedObject.title": "width-large",
          "relatedObject.artist": "width-large",
          "relatedObject.isrc": "width-small",
          "relatedObject.playCount": "width-small",
          "relatedObject.weightedPlayCount": "width-small",
          "relatedObject.royaltiesPaid": "width-small",
          "relatedObject.estimatedTrackValue": "width-small",
          status: "width-mini",
        },
        rowClassCallback: (row) => {
          let classes = ["hover-highlight"]
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          return classes.join(" ")
        },
        responseAdapter ({ data }) {
          let componentData = this.$parent.$parent.$parent.$parent.$parent.$parent
          componentData.data = data.results
          componentData.count = data.count
          if (data.results.length === 1) {
            componentData.selectedConflictId = data.results[0].id
            componentData.recordingUuid = data.results[0].relatedObject.id
            componentData.conflictUuid = data.results[0].id
          }
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent.$parent.$parent.$parent
          componentData.recordingUuid = null
          componentData.conflictUuid = null
          queryParams = {
            ...queryParams,
            ...this.$route.query,
            status: componentData.getActionStatus() ? [userActionStatuses.DONE] : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
          }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.actions.recordingEligibilityConflictActions(queryParams)
        },
      },
      userActionStatuses,
      // WS values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.ELIGIBILITY_CONFLICT,
      storeStatusesKey: "wsRecordingEligibilityConflictStatuses",
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries"]),
    ...mapMutations("alert", ["success", "error"]),
    customResetFilters (applyDefaults) {
      this.selectedParameters = []
      this.resetFilters(applyDefaults)
    },
    getData () {
      this.$refs.conflicts.getData()
    },
    onClick (event) {
      event.stopPropagation()
    },
    setSelectedEligibilityConflict (item) {
      this.recordingUuid = item.row.relatedObject.id
      this.conflictUuid = item.row.id
    },
    onLoaded ({ data }) {
      this.$refs.panes.setHeight()
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    updateEligibilityConflictInfo () {
      this.$refs.conflicts.getData()
      this.recordingUuid = null
      this.conflictUuid = null
    },
    getActionStatus () {
      return this.filters.status.value.length === 1 && this.filters.status.value[0] === userActionStatuses.DONE
    },
    parseDate,
    findIndex,

  },
  mounted () {
    if (!this.countries.length) {
      this.getCountries()
    }
    if (this.$route.query.parameters) {
      let parameters = this.$route.query.parameters.split(",")
      this.selectedParameters = this.eligibilityParams.filter(p => parameters.includes(p.value))
    }
  },
}
</script>
<style lang="scss" scoped>
/* stylelint-disable */
.VueTables::v-deep {
  &.VueTables--server,
  &.VueTables--client {
    .table-responsive {
      margin-bottom: 0.5em;

      .table {
        table-layout: fixed;

        thead {
          th {
            padding-left: 0;

            &.select-header {
              padding-bottom: 1.2em;
              padding-left: 0;

              .custom-header {
                padding-left: 0;
                position: relative;
                top: 1.4em;
              }
            }
          }

          tr {
            &.hover-highlight {
              &:hover {
                cursor: pointer;
                background-color: $red-light;
              }
            }

            .sequence-number {
              width: 2rem;
            }
          }
        }
      }

      tr {
        &.hover-highlight {
          &:hover {
            cursor: pointer;
            background-color: $red-light;
          }
        }

        .sequence-number {
          width: 2rem;
        }

        td {
          &,
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          padding: 0;
        }
      }
    }
  }
}

::v-deep .multiselect__tags {
  min-height: 35px;
  border: 1px solid #d8d8d8;
}

::v-deep .multiselect__tag {
  margin-bottom: 0;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 0;
}

.no-border-right {
  border-right: none;
  border-radius: 0
}
::v-deep .no-border-radius-left > .input-group-text {
  border-radius: 0;
}
/* stylelint-enable */
</style>
