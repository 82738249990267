<template>
  <div class="h-100">
    <div class="d-flex h-75">
      <b-container fluid id="content">
        <slot></slot>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #content {
    margin: 60px 20px 20px 70px;
  }
</style>

<script>
export default {
  name: "NotLoggedInLayout"
}
</script>