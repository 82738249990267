<template>
  <b-card>
    <h3 class="text-red">
      {{ $t("repertoire") | startcase }}
    </h3>
    <pagination
      v-model="limit"
      :page="page"
      :count="count"
      :loading="loading"
      @limit="onLimit"
    />
    <v-server-table
      ref="rightsholderRepertoire"
      :columns=columns
      :options=options
      @loaded="onLoaded"
      @loading="onLoading"
      @pagination="onPagination"
    >
      <!-- resoundId-->
      <template v-slot:resoundId="{ row }">
        <database-link class="text-nowrap" :type="linkTypes.RECORDING" :item-id="row.id">{{ row.resoundId }}</database-link>
      </template>

      <!-- Album Names -->
      <template v-slot:albums="{ row }">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="row.albums"
        />
      </template>

      <!-- Proprietary ids -->
      <template v-slot:proprietaryIds="{ row }">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="row.proprietaryIds.map(
              p => isCanViewAllProprietaryIds ? p.proprietaryId + ' - ' + p.memberCollective : p.proprietaryId
            )"
        />
      </template>

      <!-- Sources -->
      <template v-slot:repertoireFiles="{ row }">
        <sources :sources="row.repertoireFiles" :source-type="sourceTypes.REPERTOIRE_FILE"></sources>
      </template>
    </v-server-table>
  </b-card>
</template>
<script>

import { get, startCase, upperCase } from "lodash"
import { linkTypes, perPageItemsSm, sourceTypes } from "@/constants"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Pagination from "@/components/Pagination"
import Sources from "@/components/Sources.vue"
import { listRouteMixin } from "@/utils/mixins"
import { mapGetters } from "vuex"


export default {
  name: "RightsHolderRepertoire",
  components: {
    DatabaseLink,
    NamesListModal,
    Sources,
    Pagination,
  },
  props: {
    rightsholderId: String,
  },
  mixins: [listRouteMixin],
  methods: {
    onLoaded (data) {
      this.count = data.data.count
      this.loading = false
    },
    getData () {
      this.$refs.rightsholderRepertoire.getData()
    }
  },
  computed: {
    ...mapGetters("user", ["isCanViewAllProprietaryIds"]),
  },
  data () {
    return {
      linkTypes,
      sourceTypes,
      data: [],
      page: 1,
      count: 0,
      loading: false,
      limit: perPageItemsSm,
      columns: [
        "resoundId",
        "title",
        "artist",
        "isrc",
        "type",
        "albums",
        "effectivePlaycount",
        "weightedPlaycount",
        "proprietaryIds",
        "repertoireFiles",
      ],
      options: {
        serverMultiSorting: true,
        sortable: [
          "title",
          "artist",
          "isrc",
        ],
        headings: {
          resoundId: this.$t("resound_id"),
          title: startCase(this.$t("title")),
          artist: startCase(this.$t("artist")),
          isrc: upperCase(this.$t("isrc")),
          type: startCase(this.$t("type")),
          albums: startCase(this.$t("albums")),
          effectivePlaycount: startCase(this.$t("playcount")),
          weightedPlaycount: startCase(this.$t("weighted_playcount")),
          proprietaryIds: startCase(this.$t("proprietary_ids")),
          repertoireFiles: startCase(this.$t("sources")),
        },
        columnsClasses: {
          "resoundId": "width-small ",
          "title": "width-large ",
          "artist": "width-medium ",
          "isrc": "width-small ",
          "type": "width-mini ",
          "albums": "width-medium ",
          "effectivePlaycount": "width-mini ",
          "weightedPlaycount": "width-mini ",
          "proprietaryIds": "width-medium ",
          "repertoireFiles": "width-mini ",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          const rights_holder_id = this.$parent.$parent.rightsholderId
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.rightsholders.rightsholdersRepertoireList(
            rights_holder_id,
            queryParams
          )
        },
      },
    }
  },
}
</script>
