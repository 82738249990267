<template>
  <div>
    <b-row v-if="!conflictUuid" class="heading-container">
      <go-back />
      <h4 class="ml-2">
        {{ recordingData.resoundId }} -
        {{ recordingData.title }}
        <b-badge variant="light" v-if="recordingData.reconciliationStatus === reconciliationStatuses.PENDING">
          {{ $t("reconciliation_pending") | capitalize }}
        </b-badge>
      </h4>
    </b-row>
    <div>
      <modal
        id="history"
        :customTitle="$t('history') | capitalize"
        modal-class="custom-modal info"
        modalType="info"
        size="xl"
        centered
        hide-footer
      >
        <SrHistory :recordingUUID="recordingId" />
        <b-row class="modal-footer float-right mt-4">
          <b-button class="btn-outline-red" @click="$bvModal.hide('history')">Close</b-button>
        </b-row>
      </modal>
    </div>
    <div class="position-relative pt-1">
      <ValidationObserver v-slot="{ invalid }">
        <b-row>
          <b-col cols=12 v-if="conflictUuid" class="text-right mb-3">
            <b-button class="btn-outline-red" v-b-modal.history>{{
              $t("see_sr_history")
            }}</b-button>
            <b-button
                v-if="canEditRecording() && !editMode && !eligibilityConflictResolved"
                class="ml-2 btn-outline-red"
                @click="resolveEligibilityConflict"
            >
              {{ $t("resolve") | capitalize }}
            </b-button>
          </b-col>
        </b-row>

        <b-card class="recording-details">
          <b-table
            class="mb-4"
            :items="[recordingData]"
            :fields="generalDetails"
            fixed
            small
          >
            <!-- RS ID -->
            <template v-slot:cell(resoundId)="row">
              <div v-if="!recordingEligibilityConflict" class="mw-85">{{ row.item.resoundId }}</div>
              <div v-else>
                <database-link :type="linkTypes.RECORDING" :item-id="row.item.id">{{ row.item.resoundId }}</database-link>
              </div>
            </template>

            <!-- Title -->
            <template v-slot:cell(title)="row">
              <div id="title">
                <span v-if="conflictUuid">{{ row.item.title }}</span>
                <EditField v-else fieldName="title" />
              </div>
            </template>

            <!-- artist -->
            <template v-slot:cell(artist)="row">
              <span v-if="conflictUuid">{{ row.item.artist }}</span>
              <EditField v-else fieldName="artist" />
            </template>

            <!-- isrc -->
            <template v-slot:cell(isrc)="row">
              <span v-if="conflictUuid">{{ row.item.isrc }}</span>
              <EditField v-else fieldName="isrc" />
            </template>

            <!-- type -->
            <template v-slot:cell(type)="row">
              <span v-if="conflictUuid">{{ row.item.type }}</span>
              <EditField v-else fieldName="type" />
            </template>

            <!-- Duration -->
            <template v-slot:cell(duration)>
              <DurationField />
            </template>

          </b-table>
          <b-table
            class="mb-4"
            :items="[recordingData]"
            :fields="eligibility"
            fixed
            small
          >
            <!-- Albums -->
            <template v-slot:cell(albums)="">
              <div class="d-flex w-85">
                <NamesListModal
                  class-names="table-meta mb-2"
                  :values="getTrackAlbums() || []"
                />
                <edit-albums
                  v-if="recordingData.releases"
                  :albums="recordingData.releases"
                  @ok="updateAlbums"
                />
                <span class="add clickable pl-2 d-flex align-items-center" v-if="editMode" @click="editAlbums()">
                  <fa-icon size="sm" :icon="['fa', 'pen']"/>
                </span>
              </div>
            </template>

            <!-- Genre Type -->
            <template v-slot:cell(genreType)>
              <EditField fieldName="genreType" />
            </template>

            <!-- Composers -->
            <template v-slot:cell(composers)="row">
              <NamesListModal
                class-names="table-meta mb-2"
                :values="getTrackComposers(row.item) || []"
              />
            </template>

            <!-- Year Of Fixation -->
            <template v-slot:cell(fixationYear)>
              <EditField fieldName="fixationYear" />
            </template>

            <!-- Year Of Release -->
            <template v-slot:cell(releaseYear)>
              <EditField fieldName="releaseYear" />
            </template>

            <!-- Country Of Fixation -->
            <template v-slot:cell(fixationCountry)>
              <EditField fieldName="fixationCountry" />
            </template>

          </b-table>
          <b-table :items="[recordingData]" :fields="firstMakersFields" fixed small>

            <!-- Is ROME Eligible-->
            <template v-slot:cell(isRomeEligible)="row">
              <boolean-status :is-true="row.item.eligibility && row.item.eligibility.isRomeEligible"/>
            </template>

            <!-- Is WPPT Eligible-->
            <template v-slot:cell(isWpptEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isWpptEligible"/>
            </template>

            <!-- Is ROME + WPPT Eligible-->
            <template v-slot:cell(isRomeWpptEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isRomeWpptEligible"/>
            </template>

            <!-- Is CUSMA Eligible-->
            <template v-slot:cell(isCusmaEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isCusmaEligible"/>
            </template>

            <!-- PC fields deleted in RT2-687 -->

            <!-- First maker -->
            <template v-slot:cell(firstMakers)="row">
              <div v-for="firstMaker in row.item.firstMakers" :key="firstMaker.id" class="mw-85">
                <div>
                    {{ firstMaker.name }}
                </div>
              </div>
            </template>

            <!-- First Maker Nationality -->
            <template v-slot:cell(firstMakerNationalities)="row">
              <div v-for="firstMaker in row.item.firstMakers" :key="firstMaker.id" class="mw-85">
                <div>
                  {{ firstMaker.nationalities && firstMaker.nationalities.length ? firstMaker.nationalities.map((n) => getCountryName(n)).join(", ") : "" }}
                </div>
              </div>
            </template>
          </b-table>
          <edit-first-makers
              v-if="recordingData.firstMakers"
              :first-makers="recordingData.firstMakers"
              @ok="updateFirstMakers"
          />
          <b-row>
            <b-col cols="6">
              <b-button @click="editFirstMakers()" v-if="editMode" class="btn-outline-red">
                {{ $t("update_first_makers") | capitalize }}
              </b-button>
            </b-col>
            <b-col cols="6" class="text-right">
              <div id="edit-controls" v-if="canEditRecording()">
                <b-button v-if="!editMode && !eligibilityConflictResolved" class="btn-outline-red" @click="setEditMode(true)">Edit</b-button>
                <div v-else-if="editMode">
                  <b-button class="mr-3 btn-outline-dark" @click="cancel()">Cancel</b-button>
                  <b-button :disabled="invalid" class="btn-outline-red" @click="save()">Save</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </ValidationObserver>
    </div>

    <tabs-layout
      @change="onTabChange"
      :tabs="visibleSections"
      v-model="tabIndex"
      v-if="userFetched && !conflictUuid"
    >
      <!-- Tab Title -->
      <template v-slot:tabTitle="{ tab }">
        <fa-icon v-if="tab.tabIcon && tab.paymentsBlocked" color="#c23d5c" class="mr-1" :icon="tab.tabIcon" />
        <h5 class="mb-0">{{ tab.tabTitle | startcase }}</h5>
        <span class="ml-2 text-red" v-show="tab.actionsCount">
          {{ tab.actionsCount }}
        </span>
      </template>

      <template v-slot:static-content> </template>
      <template v-slot:content="{ tab }">
        <component
          v-if="recordingData.resoundId"
          :recordingUUID="recordingId"
          :resoundId="recordingData.resoundId"
          :hasEligibilityConflict="recordingData.hasEligibilityConflict"
          :data="recordingData[tab.data]"
          :is="tab.component"
          class="tab-content"
          v-bind="tab.extra"
        ></component>
      </template>
    </tabs-layout>
    <general-details
        v-else-if="userFetched && conflictUuid && !isEmpty(recordingData)"
        :is-meta-data="true"
        :recording-u-u-i-d="recordingId"
        :is-challenge-button-visible="!recordingEligibilityConflict">
    </general-details>
  </div>
</template>

<script>
import { actionsTabs, linkTypes, reconciliationStatuses, recordingDetailTabs } from "@/constants"
import { capitalize, isEmpty, startCase, upperCase } from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import ActionsHistory from "@/pages/Repertoire/Recordings/RecordingDetail/History/ActionsHistory"
import BooleanStatus from "@/components/BooleanStatus"
import ChallengeHistory from "@/pages/Repertoire/Recordings/RecordingDetail/History/ChallengeHistory"
import DatabaseLink from "@/components/DatabaseLink"
import DurationField from "@/pages/Repertoire/Recordings/RecordingDetail/DurationField"
import EditAlbums from "@/pages/Repertoire/Recordings/RecordingDetail/EditAlbums"
import EditField from "@/pages/Repertoire/Recordings/RecordingDetail/EditField"
import EditFirstMakers from "@/pages/Repertoire/Recordings/RecordingDetail/EditFirstMakers"
import GeneralDetails from "@/pages/Repertoire/Recordings/RecordingDetail/GeneralDetails"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Owners from "@/pages/Repertoire/Recordings/RecordingDetail/Owners/Owners"
import { Pages } from "@/utils/pages"
import PaymentsHistory from "@/pages/Payment/PaymentsHistory"
import Performers from "@/pages/Repertoire/Recordings/RecordingDetail/Performers/Performers"
import RecordingComments from "@/pages/Repertoire/Recordings/RecordingDetail/RecordingComments"
import SrHistory from "./SrHistory.vue"
import Usages from "@/pages/Repertoire/Recordings/RecordingDetail/Usages"
import { ValidationObserver } from "vee-validate"
import { rightsholderDetailTabs } from "@/constants"
import { values } from "lodash"

export default {
  name: "RecordingDetail",
  components: {
    DatabaseLink,
    RecordingComments,
    BooleanStatus,
    ActionsHistory,
    ChallengeHistory,
    DurationField,
    EditAlbums,
    EditField,
    EditFirstMakers,
    GeneralDetails,
    Owners,
    Performers,
    SrHistory,
    Usages,
    PaymentsHistory,
    NamesListModal,
    ValidationObserver,
  },
  props: {
    recordingId: {
      type: String,
    },
    conflictUuid: {
      type: String,
    },
    type: {
      type: String,
    },
    eligibilityConflictResolved: {
      type: Boolean,
      default: false,
    },
    solvedManually: {
      type: Array,
      required: false,
    },
    recordingEligibilityConflict: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      actionsTabs,
      linkTypes,
      reconciliationStatuses,
      tabIndex: 0,
      tabs: values(recordingDetailTabs),
      historyType: "actions",
      rightsholderDetailTabs,
      generalDetails: [
        { key: "resoundId", label: this.$t("resound_id") },
        { key: "title", label: capitalize(this.$t("title")), selected: null },
        { key: "artist", label: capitalize(this.$t("artist")) },
        { key: "isrc", label: upperCase(this.$t("isrc")) },
        { key: "type", label: capitalize(this.$t("type")) },
        { key: "duration", label: capitalize(this.$t("duration")) },
      ],
      eligibility: [
        { key: "albums", label: capitalize(this.$t("albums")) },
        { key: "genreType", label: startCase(this.$t("genre_type")) },
        { key: "composers", label: capitalize(this.$t("composers")) },
        { key: "fixationYear", label: startCase(this.$t("year_of_fixation")) },
        { key: "releaseYear", label: startCase(this.$t("year_of_release")) },
        { key: "fixationCountry", label: startCase(this.$t("country_of_fixation")) },
      ],
      firstMakersFields: [
        { key: "isRomeEligible", label: upperCase(this.$t("nr")) },
        { key: "isWpptEligible", label: upperCase(this.$t("wppt")) },
        { key: "isRomeWpptEligible", label: `${upperCase(this.$t("nr"))}+${upperCase(this.$t("wppt"))}` },
        { key: "isCusmaEligible", label: upperCase(this.$t("cusma")) },
        { key: "firstMakers", label: startCase(this.$t("first_makers")) },
        { key: "firstMakerNationalities", label: startCase(this.$t("first_maker_nationality")) },
      ],
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", "getTariffs"]),
    ...mapActions("repertoire", [
      "getRecordingData",
      "getRecordingMetadataOptions",
      "postRecordingData",
    ]),
    ...mapMutations("alert", ["error", "success"]),
    ...mapMutations("repertoire", [
      "cancelAllEdits",
      "saveRecordingMetadata",
      "resetEditIcon",
      "resetState",
      "setEditMode",
    ]),
    ...mapActions("userActions", ["getUserActionsSummary"]),

    canEditRecording () {
      if (this.$router.currentRoute.name === Pages.RepertoireRecordingDetail) {
        return this.permissions.pages[Pages.RepertoireRecordingDetail].canWrite
      }
      return this.permissions.actionsTabs[actionsTabs.ELIGIBILITY_CONFLICTS].canWrite
    },
    getExtras (tag) {
      return this.recordingData.extras
        ? this.recordingData.extras
          .filter((x) => x.tag === tag)
          .map((x) => x.value)
        : null
    },
    getTrackAlbums () {
      return this.recordingData.releases && this.recordingData.releases.length ? this.recordingData.releases.toSorted((current) => (current.promoted) ? -1 : 1).map(j => j.title) : []
    },
    getTrackComposers () {
      return this.getExtras("composer")
    },
    editFirstMakers () {
      this.$bvModal.show("edit-first-makers")
    },
    editAlbums () {
      this.$bvModal.show("edit-albums")
    },
    cancel () {
      this.setEditMode(false)
      this.cancelAllEdits()
    },
    save () {
      this.postRecordingData()
        .then(() => {
          this.success(capitalize(this.$t("saved_changes")))
          this.saveRecordingMetadata()
          this.refresh()
        })
        .catch((err) => {
          if (err.response.data.detail) {
            let msg = `Error update recording. ${err.response.data.detail}`
            this.error(msg)
          } else {
            let errors = Object.keys(err.response.data)
            this.error(
              `There were errors in the following fields: ${errors}. Please check the input data`
            )
          }
          this.cancelAllEdits()
        })

      this.setEditMode(false)
      this.resetEditIcon()
    },
    updateFirstMakers (value) {
      this.recordingData.firstMakers = value
    },
    updateAlbums (value) {
      this.recordingData.releases = value
    },
    async refresh () {
      await this.getRecordingData(this.recordingId)
      await this.getRecordingMetadataOptions(this.recordingId)
    },
    onTabChange ({ index }) {
      const clearParams = this.tabIndex !== index
      this.tabIndex = index
      this.$router.replace(this.getRoute(index, clearParams)).catch(() => {})
    },
    getRoute (index, clearParams) {
      const query = { component: this.component }
      return {
        name: "repertoire-recording-detail",
        params: { type: this.sections[index].type },
        query: clearParams ? query : { ...query, ...this.$route.query },
      }
    },
    resolveEligibilityConflict () {
      this.$api.repertoire.recordingEligibilityConflictResolve(this.recordingId)
        .then((resp) => {
          if (resp.data.resolved) {
            this.solvedManually.push(this.conflictUuid)
            this.success(capitalize(this.$t("recording_eligibility_conflict_resolved")))
          } else {
            this.success(capitalize(this.$t("saved_changes")))
          }
          this.$emit("resolved")
          this.getUserActionsSummary({ partial: true })
        })
        .catch((error) => {
          let msg = `Error. ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    isEmpty,
    getCountryName (code) {
      let country = this.countries.find(c => c.alpha2 === code)
      return country ? country.text : code
    }
  },
  computed: {
    visibleSections () {
      return this.sections.filter((section) => section.visible)
    },
    ...mapState("repertoire", [
      "recordingData",
      "editEnabled",
      "editMode",
    ]),
    ...mapState("consts", ["countries"]),
    ...mapGetters("user", ["isInternal", "hasMakers", "hasPerformers", "userFetched"]),
    ...mapState("user", ["permissions"]),

    makerActionsCount () {
      if ( this.recordingData.actionsInfo ) {
        return this.recordingData.actionsInfo.makerMatchingActions + this.recordingData.actionsInfo.makerOwnershipConflicts
      }
      return 0
    },
    makerPaymentsBlocked () {
      return this.recordingData.actionsInfo && this.recordingData.actionsInfo.makerOwnershipConflicts > 0
    },
    performerActionsCount () {
      if ( this.recordingData.actionsInfo ) {
        return this.recordingData.actionsInfo.performerMatchingActions + this.recordingData.actionsInfo.performerLineupConflicts
      }
      return 0
    },
    performerPaymentsBlocked () {
      return this.recordingData.actionsInfo && this.recordingData.actionsInfo.performerLineupConflicts > 0
    },
    generalDetailActionsCount () {
      if ( this.recordingData.actionsInfo ) {
        return this.recordingData.actionsInfo.recordingActions
      }
      return 0
    },
    usageActionsCount () {
      if ( this.recordingData.actionsInfo ) {
        return this.recordingData.actionsInfo.usageActions
      }
      return 0
    },
    sections () {
      return [
        {
          actionsCount: this.generalDetailActionsCount,
          paymentsBlocked: false,
          tabTitle: this.$t("general_details"),
          component: "GeneralDetails",
          extra: {
            isMetaData: true,
            id: "recording-sources"
          },
          visible: true,
          type: recordingDetailTabs.GENERAL_DETAILS,
        },
        {
          actionsCount: this.usageActionsCount,
          paymentsBlocked: false,
          tabTitle: this.$t("usage"),
          component: "Usages",
          type: recordingDetailTabs.USAGE,
          visible: true,
        },
        {
          tabTitle: this.$t("payments"),
          component: "PaymentsHistory",
          type: recordingDetailTabs.PAYMENTS,
          visible: true,
        },
        {
          actionsCount: this.makerActionsCount,
          paymentsBlocked: this.makerPaymentsBlocked,
          tabTitle: this.$t("owners"),
          tabIcon: ["fa", "ban"],
          component: "Owners",
          type: recordingDetailTabs.OWNERS,
          visible: this.hasMakers
        },
        {
          actionsCount: this.performerActionsCount,
          paymentsBlocked: this.performerPaymentsBlocked,
          tabTitle: this.$t("performers"),
          tabIcon: ["fa", "ban"],
          component: "Performers",
          type: recordingDetailTabs.PERFORMERS,
          visible: this.hasPerformers
        },
        {
          tabTitle: this.$t("comments"),
          component: "RecordingComments",
          type: recordingDetailTabs.COMMENTS,
          visible: this.isInternal
        },
        {
          tabTitle: this.$t("merged_srs"),
          component: "GeneralDetails",
          data: "children",
          extra: {
            isMetaData: false,
            id: "merged-recordings"
          },
          type: recordingDetailTabs.MERGED_RECORDINGS,
          visible: true,
        },
        {
          tabTitle: this.$t("sr_history"),
          component: "SrHistory",
          type: recordingDetailTabs.SR_HISTORY,
          visible: true,
        },
      ]
    },
  },
  mounted () {
    this.getRecordingData(this.recordingId).then(() => this.getRecordingMetadataOptions(this.recordingId))
    this.getCountries()
    this.getTariffs()

    let defaultRoute = {
      name: "repertoire-recording-detail",
      params: { type: recordingDetailTabs.GENERAL_DETAILS },
    }

    if (!this.tabs.includes(this.type)) {
      this.$router.replace(defaultRoute)
    } else {
      this.tabIndex = this.sections.findIndex(
        (section) => section.type === this.type
      )
    }
  },
  destroyed () {
    this.resetState()
  },
  watch: {
    recordingId (val) {
      this.getRecordingData(val)
    }
  },
}
</script>

<style lang="scss" scoped>
.static-data {
  margin-top: 20px;
  padding: 15px;
}

.tab-content {
  border: 0;
}

.recording-details {
  border: none;
  background: $white-dark;

  .add {
    &:hover {
      color: $green-darker;
    }
  }

  ::v-deep table {
    thead {
      background: transparent !important;

      th {
        font-weight: bold;
        background: transparent !important;
        padding: 0;
      }
    }

    tbody {
      td {
        vertical-align: top !important;
        padding: 0;
        .values-text {
          max-width: 80%;
          white-space: normal;
        }
        
      }
    }
  }

  .meta-data-row {
    padding: 20px;
  }

  ::v-deep table td th {
    padding: 0;
  }
}
::v-deep .radio-buttons{
  .btn-outline-secondary {
    min-width: 108px;
    color: $primary !important;
    border-color: $gray-light !important;
    &:hover {
      color: #FFFFFF !important;
      background-color: $gray-light !important;
    }
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active {
    color: #FFFFFF !important;
    background-color: $gray-light !important;
  }
}
</style>
