<template>
  <div class="d-flex align-items-center justify-content-start h-100 w-100">
    <span v-if="textPlural && list && list.length > maxItems">
      {{ `${exception ? textException + ' ' + $t('except') : ''} ${values.length} ${textPlural}` }}
    </span>
    <span v-else-if="list && list.length" class="values-text" :title="titleValue">
      {{ `${exception ? textException + ' ' + $t('except') : ''} ${normalizedValue}` }}
    </span>
    <span v-else-if="textNone">
      {{ `${textNone} ${textPlural}` }}
    </span>
    <fa-icon
      @click="showModal"
      v-if="list && list.length > maxItems"
      class="blue clickable ml-2 more-values-icon"
      size="sm"
      :icon="['fas', 'plus-circle']"
    />
    <b-modal
      id="valuesModal"
      ref="valuesModal"
      modal-class="custom-modal info"
      size="lg"
      centered
      hide-footer>
      <template #modal-title>
        <h4 class="d-flex align-items-center mb-0">
          <fa-icon :icon="['fas', 'exclamation-circle']" class="icon-blue mr-2"/>
          {{ $t('name') | capitalize }}
        </h4>
      </template>
      <div class="header">
        <span>{{ $t('name') | capitalize }}</span>
      </div>
      <div class="list">
        <div v-for="(value, i) in list" :key="i" class="medium list-row">
          <span class="medium-bold pl-2">{{ value }}</span>
        </div>
      </div>
      <b-row class="modal-footer float-right mt-4">
        <b-button class="btn-outline-red" @click="$bvModal.hide('valuesModal')">
          {{ $t("cancel") | capitalize }}
        </b-button>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { truncate } from "lodash"

export default {
  name: "NamesListModal",
  props: {
    classNames: {
      type: String,
    },
    values: {
      type: Array,
      required: true,
    },
    textPlural: {
      type: String,
    },
    exception: {
      type: Boolean,
    },
    textException: {
      type: String,
    },
    textNone: {
      type: String,
    },
    sort: {
      type: Boolean,
      default: false,
    },
    sortByLength: {
      type: Boolean,
      default: false
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 1
    }
  },
  computed: {
    list () {
      let result = [...this.values]

      if (this.sort) {
        return result.sort()
      }

      if (this.sortByLength) {
        return result.sort((a, b) => {
          return a.length - b.length
        })
      }

      return result
    },
    titleValue () {
      if (!this.values || this.values.length > this.maxItems) {
        return null
      } else {
        return this.values.slice(0, this.maxItems).join("\n")
      }
    },
    normalizedValue () {
      let value = this.values ? this.values.slice(0, this.maxItems).join("\n") : ""
      if (this.truncate) {
        return truncate(value, { length: 50 })
      }
      return value
    }
  },
  methods: {
    showModal () {
      this.$refs.valuesModal.show()
    },
  }
}
</script>
<style lang="scss" scoped>
.values-text {
  display: inline-block;
  white-space: pre-line;
}

span.values-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.more-values-icon {
  max-width: 25%;
  display: inline-block;
}

.header {
  border-bottom: 1px solid $gray-light;
  font-weight: bold;
}

.list-row {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.list > .list-row:not(:last-child) {
  border-bottom: 1px solid $gray-dark;
}
</style>
