<template>
  <div>
    <div>
      <b-row class="heading-container">
        <go-back />
        <h4 class="ml-2">
          {{ file ? file.fileName : "" }}
        </h4>
      </b-row>
      <b-row class="mb-3 p-15">
        <span class="text-gray">
          {{
            file && file.rightHolderType && file.sisterSociety
              ? `${file.sisterSociety.name} - ${file.rightHolderType
                  .map(t => capitalize($t(paymentRightsholderTypes[t])))
                  .join(", ")}`
              : ""
          }}
        </span>
      </b-row>
    </div>
    <list-layout
        :apply-filters="applyFilters"
        :reset-filters="resetFilters"
        :filters-pending="filtersPending"
        :filters-applied="filtersApplied"
    >
      <!-- Search -->
      <template v-slot:search>
        <form-field
          :placeholder="$t('search') | capitalize"
          :enter-action="applyFilters"
          v-model="filters.search.value"
          :icon="['fas', 'search']"
        />
      </template>

      <!-- Filters -->
      <template v-slot:filters>
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <b-form-group
                :label="$t('rightsholder_type') | capitalize"
                label-class="pb-0"
              >
                <b-form-select
                  v-model="filters.rightHolderType.value"
                  :options="
                    Object.keys(rightholderTypes).map(k => {
                      return { text: capitalize(k), value: k[0] }
                    })
                  "
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">{{
                      $t("all") | capitalize
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('recording_matching_status') | capitalize"
                label-class="pb-0"
              >
                <b-form-select
                  v-model="filters.recordingStatus.value"
                  :options="statusOptions"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('rightsholder_matching_status') | capitalize"
                label-class="pb-0"
              >
                <b-form-select
                  v-model="filters.rightHolderStatus.value"
                  :options="statusOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="4" offset-md="8">
              <b-button block @click="syncPayments()"
                v-if="permissions.pages[Pages.PaymentFilesList].canWrite"
              >
                {{ $t("sync_payments") | startcase }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>

      </template>

      <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              reference="statements"
              :page="page"
              :count="count"
              :loading="loading"
              v-model="limit"
              @limit="onLimit"
            />
          </div>
        </div>
      </template>

      <template v-slot:table>
        <v-server-table
          ref="statements"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <!-- Title -->
          <template v-slot:title="{ row }">
            <database-link
              v-if="row.recordingUuid"
              :type="linkTypes.RECORDING"
              :item-id="row.recordingUuid"
            >
              <span :title="row.title">{{ row.title }}</span>
            </database-link>
            <span v-else :title="row.title">{{ row.title }}</span>
          </template>

          <!-- Rightsholder name -->
          <template v-slot:rightsHolderName="{ row }">
            <fa-icon
              v-if="row.rightHolderType === 'P'"
              class="icon text-gray"
              size="sm"
              :icon="['fas', 'microphone-alt']"
            />
            <fa-icon
              v-else
              class="icon text-gray"
              size="sm"
              :icon="['fas', 'compact-disc']"
            />
            <database-link
              v-if="row.rightsHolderUuid"
              :type="linkTypes.RIGHTS_HOLDER"
              :item-id="row.rightsHolderUuid"
            >
              <span :title="row.name">{{ row.name }}</span>
            </database-link>
            <span v-else :title="row.name">{{ row.name }}</span>

          </template>

          <!-- Amount -->
          <template v-slot:amount="{ row }">
            <span class="smaller">{{ row.currency }}</span> {{ row.amount }}
          </template>

          <!-- Status -->
          <template v-slot:rightHolderStatus="{ row }">
            <status
              v-if="isMatch(row.rightHolderStatus)"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="!isMatch(row.rightHolderStatus)"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
          </template>

          <!-- Recording Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="isMatch(row.status)"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="!isMatch(row.status)"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
          </template>

          <!-- Validation Status -->
          <template v-slot:isValid="{ row }">
            <status
              v-if="row.isValid === true"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.isValid === false"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
            <status
              v-if="row.isValid === null"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
          </template>

          <!-- Real action -->
          <template v-slot:realAction="{ row }">
            <status
              v-if="row.realAction === realPaymentsAction.RELEASED || (
                  row.realAction === realPaymentsAction.INITIAL_STATUS && row.isValid)"
              :icon="['fas', 'check-circle']"
              color="green"
              :title="$t('released') | lowercase"
            />
            <status
              v-else-if="row.realAction === realPaymentsAction.INITIAL_STATUS"
              :icon="['fas', 'question-circle']"
              color="gray"
              :title="$t('initial_status') | lowercase"

            />
            <status
              v-else-if="row.realAction === realPaymentsAction.REFUND_UNDISTRIBUTED"
              :icon="['fas', 'exclamation-circle']"
              color="red"
              :title="$t('refund_undistributed') | lowercase"
            />
          </template>

          <!-- Real Sync Status -->
          <template v-slot:syncStatus="{ row }">
          <status
            v-if="row.syncStatus === realPaymentsSyncStatus.READY_FOR_SYNC || (
              row.syncStatus === realPaymentsSyncStatus.PENDING && row.isValid)"
            :icon="['fas', 'question-circle']"
            color="blue"
            :title="$t('ready_for_sync') | lowercase"
            />
            <status
              v-else-if="row.syncStatus === realPaymentsSyncStatus.PENDING"
              :icon="['fas', 'question-circle']"
              color="gray"
              :title="$t('pending') | lowercase"
            />
            <status
              v-else-if="row.syncStatus === realPaymentsSyncStatus.WAITING_FOR_SYNC"
              :icon="['fas', 'question-circle']"
              color="orange"
              :title="$t('waiting_for_sync') | lowercase"
            />
            <status
              v-else-if="row.syncStatus === realPaymentsSyncStatus.SUCCESS"
              :icon="['fas', 'check-circle']"
              color="green"
              :title="$t('success') | lowercase"
            />
            <status
              v-else-if="row.syncStatus === realPaymentsSyncStatus.Failed"
              :icon="['fas', 'exclamation-circle']"
              color="red"
              :title="$t('failed') | lowercase"
            />
          </template>
          <template v-slot:option="{ row }">
              <div
                v-if="permissions.pages[Pages.PaymentFilesList].canWrite"
              >
                <b-dropdown variant="link" right no-caret>
                  <template #button-content>
                    <b-icon icon="three-dots-vertical" ></b-icon>
                  </template>
                  <b-dropdown-item
                      :disabled="isWaitingForSync(row.syncStatus) || row.isValid"
                      @click="releasePayment(row)"
                  >
                    {{ $t('release_payment') | startcase }}
                  </b-dropdown-item>
                  <b-dropdown-item
                      :disabled="isWaitingForSync(row.syncStatus)"
                      @click="refundPayment(row)"
                  >
                    {{ $t('refund_payment') | startcase }}
                  </b-dropdown-item>
                  <b-dropdown-item
                      :disabled="!isChanged(row.syncStatus)"
                      @click="reset(row)"
                  >
                    {{ $t('reset') | startcase }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
          </template>
        </v-server-table>
      </template>
    </list-layout>
  </div>
</template>
<script>
import { capitalize, get, startCase, toUpper } from "lodash"
import {
  linkTypes,
  paymentRightsholderTypes,
  perPageItemsSm,
  realPaymentsAction,
  realPaymentsSyncStatus,
  rightsholderDetailTabs,
  rightsholderTypes,
  usageMatchingStatuses

} from "@/constants"
import { mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink"
import { Pages } from "@/utils/pages"
import Pagination from "@/components/Pagination"
import { listRouteMixin } from "@/utils/mixins"


export default {
  name: "PaymentFileDetails",
  mixins: [listRouteMixin],
  components: {
    DatabaseLink,
    Pagination
  },
  computed: {
    statusOptions () {
      let result = Object.keys(this.usageMatchingStatuses).map(key => {
        return {
          text: capitalize(this.$t(key.toLowerCase())),
          value: this.usageMatchingStatuses[key]
        }
      })
      result.splice(0, 0, {
        text: capitalize(this.$t("all")),
        value: ""
      })
      return result
    },
    ...mapState("user", ["permissions"]),
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    capitalize,
    getData () {
      this.$refs.statements.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    onPagination (page) {
      this.page = page
      this.updateRouterPagination("page", page)
    },
    syncPayments () {
      let source_filter = {
        source: this.$route.query.source
      }
      this.$api.payments.pushToReal(source_filter)
        .then(() => {
          this.success(capitalize(this.$t("sent_for_sync")))
          this.getData()
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.error(error.response.data[0])
          }
        })
    },
    releasePayment (row) {
      this.$api.payments.pushToRealInvalidReleased(row.id)
        .then(() => {
          row.realAction = realPaymentsAction.RELEASED
          row.syncStatus = realPaymentsSyncStatus.READY_FOR_SYNC
          this.success(capitalize(this.$t("real_released_for_sync")))
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.error(error.response.data[0])
          }
        })
    },
    refundPayment (row) {
      this.$api.payments.pushToRealInvalidRefund(row.id)
        .then((result) => {
          row.realAction = result.data.realAction
          row.syncStatus = realPaymentsSyncStatus.READY_FOR_SYNC
          this.success(capitalize(this.$t("real_refunded_for_sync")))
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.error(error.response.data[0])
          }
        })
    },
    reset (row) {
      this.$api.payments.pushToRealReset(row.id)
        .then((result) => {
          row.realAction = result.data.realAction
          row.syncStatus = result.data.syncStatus
          this.success(capitalize(this.$t("reset_changes")))
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.error(error.response.data[0])
          }
        })
    },
    isMatch (status) {
      return status ===  usageMatchingStatuses.MANUALLY_MATCHED || status === usageMatchingStatuses.AUTOMATCHED
    },
    isWaitingForSync (status) {
      return status ===  realPaymentsSyncStatus.WAITING_FOR_SYNC
    },
    isChanged (status) {
      return status ===  realPaymentsSyncStatus.READY_FOR_SYNC
    },
    isRefundUndistributed (status) {
      return status ===  realPaymentsAction.REFUND_UNDISTRIBUTED
    },
  },
  data () {
    return {
      linkTypes,
      paymentRightsholderTypes: paymentRightsholderTypes,
      usageMatchingStatuses: usageMatchingStatuses,
      realPaymentsAction: realPaymentsAction,
      realPaymentsSyncStatus: realPaymentsSyncStatus,
      rightholderTypes: rightsholderTypes,
      page: 1,
      count: 0,
      loading: false,
      limit: perPageItemsSm,
      file: "",
      columns: [
        "proprietaryId",
        "title",
        "artist",
        "isrc",
        "rightHolderProprietaryId",
        "rightsHolderName",
        "ipn",
        "typeOfRight",
        "amount",
        "distributionYear",
        "rightHolderStatus",
        "status",
        "isValid",
        "realAction",
        "syncStatus",
        "option"
      ],
      filters: {
        search: { value: "", defaultValue: "" },
        rightHolderType: { value: null, defaultValue: null },
        recordingStatus: { value: "", defaultValue: "" },
        rightHolderStatus: { value: "", defaultValue: "" },
      },
      options: {
        sortable: [
          "title",
          "rightsHolderName",
          "proprietaryId",
          "artist",
          "isrc",
          "rightHolderProprietaryId",
          "ipn",
          "typeOfRight",
          "amount",
          "distributionYear",
        ],
        headings: {
          proprietaryId: startCase(this.$t("proprietary_id")),
          title: startCase(this.$t("title")),
          artist: startCase(this.$t("artist")),
          isrc: toUpper(this.$t("isrc")),
          rightHolderProprietaryId: toUpper(this.$t("rh_id")),
          rightsHolderName: this.$t("rh_name"),
          ipn: toUpper(this.$t("ipn")),
          typeOfRight: startCase(this.$t("type_of_right")),
          amount: startCase(this.$t("raw")),
          distributionYear: `${startCase(this.$t("dis"))}.`,
          status: `${startCase(this.$t("rec"))}.`,
          rightHolderStatus: `${startCase(this.$t("rig"))}.`,
          realAction: `${startCase(this.$t("real_action"))}.`,
          syncStatus: `${startCase(this.$t("real_sync_status"))}.`,
          option: ""
        },
        responseAdapter ({ data }) {
          this.$parent.$parent.$options.parent.file = data.context
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.payments.paymentFileDetails(queryParams)
        }
      },
      rightsholderDetailTabs,
      Pages,
    }
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.statements.$children[0])
  }
}
</script>
<style lang="scss" scoped>
::v-deep .smaller {
  font-size: 10pt;
}
.p-15 {
  padding-left: 15px;
}
</style>
