import { render, staticRenderFns } from "./RecordingDetail.vue?vue&type=template&id=51c48e0d&scoped=true&"
import script from "./RecordingDetail.vue?vue&type=script&lang=js&"
export * from "./RecordingDetail.vue?vue&type=script&lang=js&"
import style0 from "./RecordingDetail.vue?vue&type=style&index=0&id=51c48e0d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c48e0d",
  null
  
)

export default component.exports