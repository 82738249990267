<template>
  <div>
    <modal 
      id="user-role-form" 
      :customTitle="editRole.id ? $t('edit_role') : $t('create_role') | capitalize" 
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg" 
      centered
      hide-footer>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(saveRole)">
          <ValidationProvider :name="$t('name') | capitalize" :rules="{ required: true }" v-slot="{ errors }">
            <b-form-group :label="$t('name') | capitalize" label-cols="3" class="pb-3">
              <b-form-input :state="errors[0] ? false : null" v-model="editRole.name"/>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <b-form-group :label="$t('lead_role') | capitalize" label-cols="3">
            <b-form-select :options="roleOptions.filter(o => editRole.id !== o.value)" v-model="editRole.leadRole">
              <template v-slot:first>
                <b-form-select-option :value="null">{{ $t("none") | capitalize }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <div class="d-flex mt-4 modal-footer">
            <b-button :disabled="submitInProgress" @click="cancelEdit" class="btn-outline-dark" size="md">{{ $t("cancel") | capitalize }}</b-button>
            <b-button :disabled="submitInProgress" type="submit" class="btn-outline-red" size="md">{{ $t("save") | capitalize }}</b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </modal>
    <modal 
      id="user-role-copy-form" 
      :customTitle="$t('copy_role') | capitalize" 
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg" 
      centered
      hide-footer>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(copyRole)">
          <ValidationProvider :name="$t('from_role') | capitalize" :rules="{ required: true }" v-slot="{ errors }">
            <b-form-group :label="$t('from_role') | capitalize" label-cols="3" class="pb-3">
              <b-form-select :options="roleOptions" v-model="parentRole" :state="errors[0] ? false : null">
              </b-form-select>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider :name="$t('name') | capitalize" :rules="{ required: true }" v-slot="{ errors }">
            <b-form-group :label="$t('name') | capitalize" label-cols="3">
              <b-form-input :state="errors[0] ? false : null" v-model="copiedRoleName"/>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <div class="d-flex mt-4 modal-footer">
            <b-button :disabled="submitInProgress" @click="$bvModal.hide('user-role-copy-form')" class="btn-outline-dark" size="md">{{ $t("cancel") | capitalize }}</b-button>
            <b-button :disabled="submitInProgress" type="submit" class="btn-outline-red" size="md">{{ $t("copy") | capitalize }}</b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </modal>
    <b-row class="heading-container mb-1">
      <b-col cols="6">
        <div class="heading">
          <h3 class="title">{{ $t("user_roles") | startcase }}</h3>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="permissions.pages[Pages.UserRoles].canWrite">
      <b-col>
        <b-button variant="secondary" @click="openCreateDialog">{{ $t("create_role") | capitalize }}</b-button>
        <b-button class="ml-2" variant="secondary" @click="openCopyDialog">{{ $t("copy_role") | capitalize }}</b-button>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end">
        <b-button :disabled="!changePending" variant="outline-secondary" class="mr-2" @click="calculateItems">{{ $t("cancel") | capitalize }}</b-button>
        <b-button :disabled="!changePending" variant="secondary" @click="savePermissions">{{ $t("save") | capitalize }}</b-button>
      </b-col>
    </b-row>
    <div class="overflow-auto">
      <b-table
        v-if="this.permissionGroups"
        :fields="fields"
        :items="items"
        striped
        sticky-header="calc(100vh - 170px)"
        :no-local-sorting="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:cell()="{ field, value, item }">
          <span v-if="field.key === 'role'" class="text-info clickable" @click="openEditDialog(item.id)">
            {{ value }}
          </span>
          <span v-else-if="field.key === 'actions'" class="clickable" :title="$t('delete') | capitalize">
            <fa-icon v-if="item.deletable" :icon="['fa', 'trash']" style="color: var(--secondary)" @click="deleteRole(item.id)"/>
            <fa-icon v-else class="text-muted" :icon="['fa', 'lock']" style="cursor: not-allowed;"/>
          </span>
          <b-form-select
            v-else-if="permissions.pages[Pages.UserRoles].canWrite"
            :options="permissionOptions[field.key].options"
            v-model="item[field.key]"
            @change="onPermissionChange"
            :disabled="isPermissionReadOnly(field.key, item)"
          >
          </b-form-select>
          <span v-else>{{ permissionOptions[field.key].options.find(o => o.value === item[field.key]).text }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { capitalize, intersection } from "lodash"
import { mapActions, mapState } from "vuex"
import { Pages } from "@/utils/pages"
import { sisterSocietyUserRole } from "@/constants"

export default {
  name: "UserRoles",
  components: { ValidationProvider, ValidationObserver },
  data () {
    return {
      Pages,
      permissionGroups: [],
      items: [],
      editRole: {},
      submitInProgress: false,
      parentRole: null,
      copiedRoleName: "",
      changePending: false,
      permissionsWithReadOnlyOptions: ["User roles"],
      sortBy: null,
      sortDesc: null
    }
  },
  methods: {
    ...mapActions("consts", ["getUserRoles"]),
    ...mapActions("alert", ["success", "error"]),
    calculateItems () {
      this.items = this.userRoles.filter(r => r.name !== sisterSocietyUserRole).map(role => ({
        id: role.id,
        role: role.name,
        deletable: role.deletable,
        readOnlyPermissions: role.readOnlyPermissions,
        ...this.permissionGroups.reduce((obj, group) => Object.assign(obj, {
          [group.label]: intersection(role.permissions, group.options.map(o => o.value))[0] || null
        }), {})
      }))
      this.changePending = false
      this.sortChanged()
    },
    savePermissions () {
      let permissions = this.items.map(role => ({
        id: role.id,
        permissions: this.permissionGroups.map(g => role[g.label]).filter(p => p)
      }))
      this.$api.users.updatePermissions(permissions).then(() => {
        this.success("User roles updated successfully")
        this.getData()
      })
    },
    cancelEdit () {
      this.$bvModal.hide("user-role-form")
      this.editRole = {}
    },
    saveRole () {
      this.submitInProgress = true
      let promise = this.editRole.id ? this.$api.users.updateUserRole(this.editRole.id, this.editRole) : this.$api.users.createUserRole(this.editRole)
      promise.then(() => {
        this.submitInProgress = false
        this.$bvModal.hide("user-role-form")
        if (this.editRole.id)
          this.success("User role updated successfully")
        else
          this.success("User role created successfully")
        this.getData()
      }).catch((error) => {
        this.submitInProgress = false
        this.error(error.response.data)
      })
    },
    openCreateDialog () {
      this.editRole = {}
      this.$bvModal.show("user-role-form")
    },
    openEditDialog (roleId) {
      let role = this.userRoles.find(r => r.id === roleId)
      this.editRole = {
        id: role.id,
        name: role.name,
        leadRole: role.leadRole
      }
      this.$bvModal.show("user-role-form")
    },
    openCopyDialog () {
      this.parentRole = null
      this.copiedRoleName = ""
      this.$bvModal.show("user-role-copy-form")
    },
    getData () {
      this.getUserRoles().then(() => this.calculateItems())
    },
    deleteRole (id) {
      this.$api.users.deleteUserRole(id).then(() => {
        this.success("User role deleted")
        this.getData()
      }).catch(error => {
        this.error(error.response.data[0])
      })
    },
    copyRole () {
      this.$api.users.copyUserRole(this.parentRole, { name: this.copiedRoleName }).then(() => {
        this.$bvModal.hide("user-role-copy-form")
        this.success("User role copied")
        this.getData()
      })
    },
    onPermissionChange () {
      this.changePending = true
    },
    isPermissionReadOnly (key, role) {
      return role.readOnlyPermissions && role.readOnlyPermissions.includes(role[key])
    },
    sortChanged () {
      if (this.sortBy) {
        this.items.sort((a, b) => {
          let res = 0
          let valA = a[this.sortBy]
          let valB = b[this.sortBy]
          if (!valA && valB) {
            res = -1
          } else if (!valB && valA) {
            res = 1
          } else if (valA < valB) {
            res = -1
          } else if (valA > valB) {
            res = 1
          }
          if (this.sortDesc) {
            res = res * -1
          }
          return res
        })
      }
    }
  },
  computed: {
    ...mapState("consts", ["userRoles"]),
    ...mapState("user", ["permissions", "user"]),
    fields () {
      return [
        {
          key: "role",
          stickyColumn: true,
          tdClass: "text-nowrap",
          sortable: true,
        },
        ...this.permissionGroups.map(group => ({ key: group.label, sortable: true, thClass: "text-nowrap" })),
        {
          key: "actions",
          label: ""
        }
      ]
    },
    permissionOptions () {
      return this.permissionGroups.reduce(
        (obj, group) => Object.assign(obj, {
          [group.label]: {
            options: [{ text: capitalize(this.$t("no_access")), value: null }, ...group.options]
          }
        }), {}
      )
    },
    roleOptions () {
      return  this.userRoles.filter(r => r.name !== sisterSocietyUserRole).map(r => ({ text: r.name, value: r.id }))
    }
  },
  watch: {
    parentRole (value) {
      let role = this.userRoles.find(r => r.id === value)
      this.copiedRoleName = `Copy of ${role.name}`
    },
    sortBy () {
      this.sortChanged()
    },
    sortDesc () {
      this.sortChanged()
    }
  },
  mounted () {
    let requests = []
    if (!this.userRoles.length) {
      requests.push(this.getUserRoles())
    }
    requests.push(this.$api.users.permissionOptions().then(response => this.permissionGroups = response.data))
    Promise.all(requests).then(() => this.calculateItems())
  }
}
</script>
