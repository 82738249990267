import Vue from "vue"
import Vuex from "vuex"

import { actionTabs } from "./actions.module"
import { alert } from "./alert.module"
import { consts } from "./consts.module"
import { copyrights } from "./copyrights.module"
import { errors } from "./errors.module"
import { filters } from "./filters.module"
import { mergers } from "./mergers.module"
import { repertoire } from "./repertoire.module"
import { requests } from "./requests.module"
import { rightsholders } from "./rightsholders.module"
import { user } from "./user.module"
import { userActions } from "./user_actions.module"
import { websocket } from "./websocket.module"

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    actionTabs,
    alert,
    consts,
    copyrights,
    errors,
    filters,
    mergers,
    repertoire,
    requests,
    rightsholders,
    user,
    userActions,
    websocket,
  }
})
