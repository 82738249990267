<template>
  <div>
    <modal
      id="approve-modal"
      :customTitle="$t('approve_merge') | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg"
      centered
      hide-footer
    >
      <ValidationObserver ref="approveMerge" v-slot="{ handleSubmit }">
        <ValidationProvider vid="comment" name="comment" rules="" v-slot="{ errors }">
          <b-form @submit.prevent="handleSubmit(approveMerge)">
            <b-form-textarea
              size="lg"
              class="mb-4"
              debounce="500"
              name="comment"
              :placeholder="$t('comment') | capitalize"
              v-model="approveComment"
              rows="5"
            />
            <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
            <b-row class="modal-footer mt-4">
              <b-button @click="$bvModal.hide('approve-modal')" class="btn-outline-dark">
                {{ $t("cancel") | capitalize }}
              </b-button>
              <b-button type="submit" class="btn-outline-red">
                {{ $t("approve_merge") | capitalize }}
              </b-button>
            </b-row>
          </b-form>
        </ValidationProvider>
      </ValidationObserver>
    </modal>
    <modal
      id="reject-modal"
      :customTitle="$t('reject_merge') | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg"
      centered
      hide-footer
    >
      <ValidationObserver ref="rejectMerge" v-slot="{ handleSubmit }">
        <ValidationProvider vid="comment" name="comment" rules="required" v-slot="{ errors }">
          <b-form @submit.prevent="handleSubmit(rejectMerge)">
            <b-form-textarea
              size="lg"
              class="mb-4"
              debounce="500"
              name="comment"
              :placeholder="$t('comment') | capitalize"
              v-model="rejectComment"
              rows="5"
            />
            <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
            <b-row class="modal-footer mt-4">
              <b-button @click="$bvModal.hide('reject-modal')" class="btn-outline-dark">
                {{ $t("cancel") | capitalize }}
              </b-button>
              <b-button type="submit" :disabled="!rejectComment" class="btn-outline-red">
                {{ $t("reject_merge") | capitalize }}
              </b-button>
            </b-row>
          </b-form>
        </ValidationProvider>
      </ValidationObserver>
    </modal>

    <div v-if="mergeRequestData">
      <div class="mb-3">
        <b-row>
          <b-col cols="4">
            <b-button v-if="canBeResolved()" variant="outline-success" @click="$bvModal.show('approve-modal')">{{ $t("approve_merge") | capitalize }}</b-button>
            <b-button v-if="canBeResolved()" class="ml-3" variant="outline-secondary" @click="$bvModal.show('reject-modal')">{{ $t("reject_merge") | capitalize }}</b-button>
          </b-col>
          <b-col cols="2" offset="4">
            <b-form-checkbox v-if="!alwaysShowParentData" v-model="showParentData" variant="outline-secondary" switch>
              {{ $t("show_parent_data") | capitalize }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="2">
            <b-form-checkbox class="ml-5" v-model="highlightDifferences" variant="outline-secondary" switch>
              {{ $t("highlight_differences") | capitalize }}
            </b-form-checkbox>
          </b-col>
        </b-row>

      </div>
      <h3 class="text-red" v-if="showParentData">{{ $t("parent_recording") | capitalize }}</h3>
      <t-table
        v-if="showParentData"
        id="recordingMergeRequestParent"
        :local-data="[mergeRequestData.parentRecording]"
        :columns="columns"
        :options="options"
      >
        <template v-slot:id="{ row }">
          <database-link :type="linkTypes.RECORDING" :item-id="row.id" target="_blank">{{ row.resoundId }}</database-link>
        </template>
        <template v-slot:firstMakerNationalities="{ row }">
          <names-list-modal :values="getFirstMakerNationalities(row)"/>
        </template>
        <template v-slot:firstMakers="{ row }">
          <names-list-modal :values="getFirstMakerNames(row)"/>
        </template>
        <template v-slot:releases="{ row }">
          <names-list-modal :values="row.releases"/>
        </template>
        <template v-slot:duration="{ row }">
          {{ row.duration | toDuration }}
        </template>
        <template v-slot:owners="{ row }">
          <names-list-modal :values="row.owners"/>
        </template>
      </t-table>
      <h3 class="text-red">{{ $t("child_recordings_to_merge") | capitalize }}</h3>
      <t-table
        id="recordingMergeRequestChildren"
        :local-data="mergeRequestData.childRecordings"
        :columns="columns"
        :options="options"
        :limit="limit"
        @pagination="onPagination"
      >
        <template v-slot:counts>
          <pagination
            :loading="false"
            :count="mergeRequestData.childRecordings.length"
            :page="page"
            :per-page-selector="false"
            v-model="limit"
          />
        </template>
        <template v-slot:id="{ row }">
          <database-link :type="linkTypes.RECORDING" :item-id="row.id" target="_blank">{{ row.resoundId }}</database-link>
        </template>
        <template v-slot:title="{ row }">
          <span :class="compareField(row, 'title')">
            {{ row.title }}
          </span>
        </template>
        <template v-slot:artist="{ row }">
          <span :class="compareField(row, 'artist')">
            {{ row.artist }}
          </span>
        </template>
        <template v-slot:isrc="{ row }">
          <span :class="compareField(row, 'isrc')">
            {{ row.isrc }}
          </span>
        </template>
        <template v-slot:fixationCountry="{ row }">
          <span :class="compareField(row, 'fixationCountry')">
            {{ row.fixationCountry }}
          </span>
        </template>
        <template v-slot:fixationYear="{ row }">
          <span :class="compareField(row, 'fixationYear')">
            {{ row.fixationYear }}
          </span>
        </template>
        <template v-slot:firstMakerNationalities="{ row }">
          <span :class="compareField(row, 'firstMakerNationalities')">
            <names-list-modal :values="getFirstMakerNationalities(row)"/>
          </span>
        </template>
        <template v-slot:firstMakers="{ row }">
          <span :class="compareField(row, 'firstMakers')">
            <names-list-modal :values="getFirstMakerNames(row)"/>
          </span>
        </template>
        <template v-slot:releases="{ row }">
          <span :class="compareField(row, 'releases')">
            <names-list-modal :values="row.releases"/>
          </span>
        </template>
        <template v-slot:duration="{ row }">
          <span :class="compareField(row, 'duration')">
            {{ row.duration | toDuration }}
          </span>
        </template>
        <template v-slot:owners="{ row }">
          <span :class="compareField(row, 'owners')">
            <names-list-modal :values="row.owners"/>
          </span>
        </template>
      </t-table>
      <h3 class="text-red">{{ $t("comments") | startcase }}</h3>
      <comments
        :list-endpoint="$api.comments.recordingMergeRequestCommentsList"
        :create-endpoint="$api.comments.recordingMergeRequestCommentsCreate"
        :uuid="mergeRequestId"
        :editing=false
        :deleting=false
      />
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { actionsTabs, linkTypes, perPageItemsSm, userActionStatuses, userPreferenceNames } from "@/constants"
import { capitalize, difference, toUpper } from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Pagination from "@/components/Pagination.vue"
import { unaccent } from "@/utils/strings"

export default {
  name: "RecordingMergeRequestDetail",
  components: { Pagination, DatabaseLink, NamesListModal, ValidationProvider, ValidationObserver },
  props: {
    mergeRequestId: {
      required: true,
      type: String
    },
    alwaysShowParentData: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      columns: [
        "id",
        "title",
        "artist",
        "isrc",
        "fixationCountry",
        "fixationYear",
        "firstMakerNationalities",
        "firstMakers",
        "releases",
        "owners",
        "duration",
      ],
      options: {
        sortable: ["title", "artist", "isrc", "fixationCountry", "fixationYear", "duration"],
        headings: {
          "id": this.$t("resound_id"),
          "title": capitalize(this.$t("title")),
          "artist": capitalize(this.$t("artist")),
          "isrc": this.$t("isrc"),
          "fixationCountry": capitalize(this.$t("country_of_fixation")),
          "fixationYear": capitalize(this.$t("year_of_fixation")),
          "firstMakerNationalities": capitalize(this.$t("first_makers_nationalities")),
          "firstMakers": capitalize(this.$t("first_makers")),
          "releases": capitalize(this.$t("releases")),
          "duration": capitalize(this.$t("duration")),
          "owners": capitalize(this.$t("member_collectives")),
        },
      },
      mergeRequestData: null,
      linkTypes,
      showParentData: this.alwaysShowParentData,
      highlightDifferences: true,
      comparisonTransformations: {
        firstMakerNationalities: (recording) => {
          return this.getFirstMakerNationalities(recording)
        },
        firstMakers: (recording) => {
          return this.getFirstMakerNames(recording)
        }
      },
      approveComment: "",
      rejectComment: "",
      page: 1,
      limit: perPageItemsSm
    }
  },
  computed: {
    ...mapGetters("user", ["getPreference"]),
    ...mapState("user", ["permissions"]),
  },
  methods: {
    ...mapActions("alert", ["error", "success"]),
    ...mapMutations("user", ["setPreference"]),
    ...mapActions("userActions", ["getUserActionsSummary"]),

    async getData () {
      try {
        let response = await this.$api.actions.recordingMergeRequest(this.mergeRequestId)
        this.mergeRequestData = response.data
      } catch (e) {
        this.error(e.response.data)
      }
    },
    compareField (child, fieldName) {
      if (!this.highlightDifferences) {
        return ""
      }
      let childValue = this.getComparisonValue(child, fieldName)
      let parentValue = this.getComparisonValue(this.mergeRequestData.parentRecording, fieldName)

      if (childValue && parentValue) {
        if (Array.isArray(parentValue)) {
          if (parentValue.length > 0 && childValue.length > 0) {
            return this.compareArrayValues(parentValue, childValue) ? "green" : "red"
          }
        } else {
          return this.compareStringValues(parentValue, childValue) ? "green" : "red"
        }
      }
    },
    getComparisonValue (item, fieldName) {
      if (fieldName in this.comparisonTransformations) {
        return this.comparisonTransformations[fieldName](item)
      } else {
        return item[fieldName]
      }
    },
    compareArrayValues (value1, value2) {
      let c1 = value1.map(v => toUpper(unaccent(v)))
      let c2 = value2.map(v => toUpper(unaccent(v)))
      return difference(c1, c2).length === 0
    },
    compareStringValues (value1, value2) {
      return toUpper(unaccent(value1)) === toUpper(unaccent(value2))
    },
    canBeResolved () {
      return this.permissions.actionsTabs[actionsTabs.MERGE_REQUESTS].canWrite && !this.isResolved()
    },
    async approveMerge () {
      this.$emit("beforeSolved")
      try {
        await this.$api.actions.recordingMergeRequestApprove(this.mergeRequestId, { comment: this.approveComment })
        this.markAsSolved()
        this.success(capitalize(this.$t("recordings_linked")))
        this.$bvModal.hide("approve-modal")
        this.$emit("solved")
        this.getUserActionsSummary({ partial: true })
      } catch (e) {
        this.error(e.response.data)
        if (e.response.status === 400) {
          this.error(e.response.data[0])
        }
      }
    },
    async rejectMerge () {
      this.$emit("beforeSolved")
      try {
        await this.$api.actions.recordingMergeRequestReject(this.mergeRequestId, { comment: this.rejectComment })
        this.markAsSolved()
        this.success(capitalize(this.$t("recordings_merge_request_rejected")))
        this.$bvModal.hide("reject-modal")
        this.$emit("solved")
        this.getUserActionsSummary({ partial: true })
      } catch (e) {
        this.error(e.response.data)
      }
    },
    markAsSolved () {
      this.mergeRequestData.status = userActionStatuses.DONE
    },
    isResolved () {
      return this.mergeRequestData.status === userActionStatuses.DONE
    },
    getFirstMakerNames (row) {
      return row.firstMakers ? row.firstMakers.map(fm => fm.name) : []
    },
    getFirstMakerNationalities (row) {
      return row.firstMakers ? [...new Set(row.firstMakers.map(fm => fm.nationalities).flat())] : []
    },
    onPagination (page) {
      this.page = page
    }
  },
  watch: {
    mergeRequestId () {
      this.getData()
    },
    highlightDifferences (newVal) {
      this.setPreference({ preference: userPreferenceNames.MERGE_REQUESTS_HIGHLIGHT_DIFFERENCES, value: newVal })
    },
    showParentData (newVal) {
      this.setPreference({ preference: userPreferenceNames.MERGE_REQUESTS_SHOW_PARENT_DATA, value: newVal })
    }

  },
  mounted () {
    this.getData()
    if (!this.alwaysShowParentData) {
      this.showParentData = this.getPreference(userPreferenceNames.MERGE_REQUESTS_SHOW_PARENT_DATA, false)
    }
    this.highlightDifferences = this.getPreference(userPreferenceNames.MERGE_REQUESTS_HIGHLIGHT_DIFFERENCES, true)

  }
}
</script>

<style lang="scss">
  .tabulator-cell {
    &:has(span.red) {
      background-color: $red-light;
      &:hover > span {
        background-color: $red-light !important;
      }
    }

    &:has(span.green) {
      background-color: $green;
      &:hover > span {
        background-color: $green !important;
      }
    }
  }
</style>
