<template>
  <ValidationObserver ref="usersUpdateForm" v-slot="{ handleSubmit }" v-if="userBeforeUpdate">
    <b-form @submit.prevent="handleSubmit(onSubmit)" class="m-2">
      <b-row class="heading-container">
        <go-back/>
        <h4 class="pl-3">{{ formSaved.firstName + ' ' + formSaved.lastName }}</h4>
        <submit-button
          class="float-right ml-auto mr-3"
          variant="secondary"
          v-if="permissions.pages[Pages.UsersUpdate].canWrite"
        >
          {{ $t('save_back') | capitalize }}
        </submit-button>
      </b-row>
      <b-row>
        <b-col>
          <!-- First Name -->
          <form-field
            name="first name"
            label="First name"
            type="text"
            rules="required"
            v-model="form.firstName"
            :disabled="!permissions.pages[Pages.UsersUpdate].canWrite"
          />
        </b-col>
        <b-col>
          <!-- Last Name -->
          <form-field
            name="last name"
            label="Last name"
            type="text"
            rules="required"
            v-model="form.lastName"
            :disabled="!permissions.pages[Pages.UsersUpdate].canWrite"
          />
        </b-col>
        <b-col>
          <!-- Status -->
          <multi-select-field
            v-model="form.status"
            label="Status"
            track-by="display"
            :options="statusOptions"
            :searchable="false"
            :show-labels="false"
            :disabled="!permissions.pages[Pages.UsersUpdate].canWrite"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <fa-icon class="mr-2" :class="props.option.class" :icon="props.option.icon"/>
              <span>{{ props.option.display }}</span>
            </template>
            <template slot="option" slot-scope="props">
              <fa-icon class="mr-2" :class="props.option.class" :icon="props.option.icon"/>
              <span>{{ props.option.display }}</span>
            </template>
          </multi-select-field>
        </b-col>
      </b-row>
      <b-row>
        <!-- Email -->
        <b-col>
          <form-field
            name="email"
            label="E-mail"
            type="email"
            rules="required|email"
            v-model="form.email"
            disabled
          />
        </b-col>
        <!-- Phone Number -->
        <b-col>
          <form-field
            name="contact-number"
            label="Phone number"
            type="text"
            v-model="form.contactNumber"
            :disabled="!permissions.pages[Pages.UsersUpdate].canWrite"
          />
        </b-col>
        <b-col>
          <!-- Role -->
          <multi-select-field
            v-model="form.role"
            name="role"
            :options="roleOptions"
            rules="required"
            label="User Role"
            choiceLabel="display"
            track-by="value"
            :showLabels="false"
            class="w-50"
            :disabled="!permissions.pages[Pages.UsersUpdate].canWrite"
          />
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>
<script>

import {
  activationStatuses,
  getActivationStatusClass, getActivationStatusIcon, sisterSocietyUserRole
} from "@/constants"
import { mapActions, mapMutations, mapState } from "vuex"
import { Pages } from "@/utils/pages"
import { ValidationObserver } from "vee-validate"
import { capitalize } from "lodash"

export default {
  name: "UsersUpdateForm",
  components: {
    ValidationObserver
  },
  methods: {
    ...mapActions("consts", ["getUserRoles"]),
    ...mapMutations("alert", ["success", "error"]),
    ...mapMutations("user", ["setUser"]),
    async onSubmit () {
      const data = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        contactNumber: this.form.contactNumber,
        role: this.form.role.value,
      }
      const status = this.form.status.value
      // Update user info
      this.$api.users.userUpdate(this.userUuid, data)
        .then((response) => {
          const user = response.data
          // Update user status
          if (status !== this.userBeforeUpdate.status) {
            if (status === activationStatuses.ACTIVE) {
              this.$api.users.userActivate(this.userUuid)
                .then(() => {
                  this.successForm(user)
                })
                .catch((error) => {
                  let msg = `Error activating user. ${error.response.data.detail}`
                  this.error(msg)
                })
            } else if (status === activationStatuses.INACTIVE) {
              this.$api.users.userDeactivate(this.userUuid)
                .then(() => {
                  this.successForm(user)
                })
                .catch((error) => {
                  let msg = `Error deactivating user. ${error.response.data.detail}`
                  this.error(msg)
                })
            }
          } else {
            this.successForm(user)
          }
        })
        .catch((error) => {
          const { data, status } = error.response

          if (status === 400) {
            let msg = capitalize(this.$t("error_updating_user"))
            this.error(msg)
            this.$refs.usersUpdateForm.setErrors(data)
          }
        })
    },
    initForm (data) {
      this.form.uuid = data.uuid
      this.form.firstName = data.firstName
      this.form.lastName = data.lastName
      this.form.email = data.email
      this.form.contactNumber = data.contactNumber
      this.form.role = this.roleOptions.find(item => item.value === data.role)
      this.form.status = this.statusOptions.find(
        option => option.value === data.status
      )
      this.formSaved = Object.assign({}, this.form)
    },
    successForm (user) {
      this.success("User updated successfully")
      if (this.userUuid === this.user.uuid) {
        this.setUser(user)
      }
      this.goBack()
    },
    cancel () {
      this.form = Object.assign({}, this.formSaved)
      this.goBack()
    },
    goBack () {
      this.$router.go(-1)
    },
    async getRoles () {
      if (!this.userRoles.length) {
        this.getUserRoles()
      }
    },
  },
  data () {
    return {
      form: {
        uuid: "",
        firstName: "",
        lastName: "",
        status: "",
        email: "",
        contactNumber: "",
        role: "",
      },
      userUuid: null,
      formSaved: {
        firstName: "",
        lastName: "",
      },
      userBeforeUpdate: null,
      Pages,
    }
  },
  computed: {
    ...mapState("consts", ["memberCollectives", "userRoles"]),
    ...mapState("user", ["user", "permissions"]),
    roleOptions () {
      let roles = this.userRoles
        .filter(
          element => {
            let memberCollective = this.memberCollectives.find(mc => mc.uuid === this.userBeforeUpdate.memberCollective.uuid)
            if (memberCollective.isSisterSociety) {
              return element.label === sisterSocietyUserRole
            }
            return element
          }
        ).map(element => {
          return {
            display: element.name,
            value: element.id
          }
        })
      return roles.length ? roles : [{ display: this.userBeforeUpdate.roleName, value: this.userBeforeUpdate.role }]
    },
    statusOptions () {
      let statusOptions = Object.values(activationStatuses).map(item => {
        return {
          display: this.$t(item),
          value: item,
          icon: getActivationStatusIcon(item),
          class: getActivationStatusClass(item)
        }
      })
      if (this.userBeforeUpdate.status === activationStatuses.PENDING) {
        return statusOptions
      }
      return statusOptions.filter(option => option.value !== activationStatuses.PENDING)
    }
  },
  mounted () {
    let { uuid } = this.$route.params
    this.userUuid = uuid ? uuid : this.user.uuid
    if (this.userUuid) {
      this.$api.users.userRetrieve(this.userUuid)
        .then(response => {
          this.userBeforeUpdate = response.data
          this.initForm(this.userBeforeUpdate)
        })
    }
  },
}
</script>
<style scoped>
h3 {
  display: inline-block;
}
</style>
