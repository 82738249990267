<template>
  <b-link v-b-toggle="toggle" class="ml-1" :disabled="disabled">
    <fa-icon size="lg" :icon="['fa', 'angle-down']" v-if="!expanded"/>
    <fa-icon size="lg" :icon="['fa', 'angle-up']" v-else/>
  </b-link>
</template>

<script>
export default {
  name: "CollapseButton",
  props: {
    toggle: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
