import Vue from "vue"

import { actions } from "./actions.service"
import { agents } from "./agents.service"
import { auth } from "./auth.service"
import { broadcasters } from "./broadcasters.service"
import { comments } from "./comments.service"
import { copyrights } from "./copyrights.service"
import { distributions } from "./distributions.service"
import { members } from "./members.service"
import { notifications } from "./notifications.service"
import { payments } from "./payments.service"
import { repertoire } from "./repertoire.service"
import { reporting } from "./reporting.service"
import { rightsholders } from "./rightsholders.service"
import { usages } from "./usages.service"
import { users } from "./users.service"

let api = new Vue({
  data () {
    return {
      actions,
      agents,
      auth,
      broadcasters,
      comments,
      copyrights,
      distributions,
      members,
      notifications,
      payments,
      repertoire,
      reporting,
      rightsholders,
      usages,
      users,
    }
  },
})

export const API = {
  install (Vue) {
    Vue.prototype.$api = api
  }
}
