<template>
  <div>
    <b-container class="p-0">
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('comment') | capitalize">
            <b-form-textarea rows="7" v-model="comment"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-row class="modal-footer mt-4">
      <b-button class="btn-outline-dark" @click="cancel">
        {{ $t("cancel") | capitalize }}
      </b-button>
      <b-button :disabled="!comment" class="btn-outline-red" @click="usagesReviewed">
        {{ $t("apply") | capitalize }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "UsagesReviewForm",
  props : {
    id: String,
  },
  data () {
    return {
      comment: "",
    }
  },
  methods: {
    usagesReviewed () {
      let data = {
        comment: this.comment,
      }
      this.$emit("reviewed", data)
    },
    cancel () {
      this.$bvModal.hide(this.id)
    },
  }
}
</script>
