import { isArray, isObject } from "lodash"
import Vue from "vue"
import axios from "axios"
import camelcaseKeys from "camelcase-keys"
import serializeParams from "@/utils/params-serializer"
import snakecaseKeys from "snakecase-keys"

import { store } from "../store"



export const host = process.env.VUE_APP_API_HOST
export const API = axios.create({ baseURL: host })

API.defaults.headers.post["Content-Type"] = "application/json"
API.defaults.headers.patch["Content-Type"] = "application/json"
API.defaults.headers.put["Content-Type"] = "application/json"
API.defaults.headers.common["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone

// Global Request Handling
API.interceptors.request.use(
  async request => {
    store.commit("requests/removePendingRequest", request)
    store.commit("requests/addPendingRequest", request)
    // Set waiting to true
    store.commit("user/waiting", true)
    // Add autorization header
    await setToken(request)
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

const setToken = async (request) => {
  if (Vue.prototype.$auth.isAuthenticated()) {
    const accessToken = await Vue.prototype.$auth.getAuthResponse()
    request.headers.common["Authorization"] = `Bearer ${accessToken}`
  }
}

// Global Response Handling
const successHandler = (response) => {
  store.commit("requests/removePendingRequest", response)
  store.commit("user/waiting", false)
  return response
}

const errorHandler = (error) => {
  // Request was cancelled
  if (error.message && error.message === "canceled") {
    return
  }
  let message = null
  const { status } = error.response
  const { config } = error.response
  // Set waiting to false
  store.commit("user/waiting", false)

  // 401 Unauthorized -> Logout
  if (status === 401) {
    store.dispatch("user/logout")
  // 404 Not Found -> Set Page Not Found
  } else if (status === 404 || status === 403) {
    store.commit("user/setPageNotFound", true)
  // 500 Internal Error -> Display alert
  } else if (status === 500) {
    if (!config.suppressAlert) {
      message = "Something went wrong. Please contact the Administrator."
      store.dispatch("alert/error", message)
    }
  }
  return Promise.reject({ ...error })
}

API.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

// Transform all requests to snake_case and responses to camelCase
// Source: https://github.com/lmiller1990/axios-transform-example/
API.defaults.transformResponse = [(data, headers) => {
  if (data && headers["content-type"] == "application/json") {
    data = JSON.parse(data)
    if (isArray(data)) {
      return data.map(i => isObject(i) ? camelcaseKeys(i, { deep: true }) : i)
    } else {
      return camelcaseKeys(data, { deep: true })
    }
  }
  return data
}]

API.defaults.transformRequest = [(data, headers) => {
  if (data && headers.post["Content-Type"] == "application/json") {
    return JSON.stringify(snakecaseKeys(data, { deep: true }))
  }
  return data
}]

API.defaults.paramsSerializer = serializeParams
