<template>
  <list-layout
    no-counts
    no-search>
    <!-- Filters -->
    <template v-slot:beforeFilters>
      <b-row>
        <b-col>
          <filter-builder
            :id="userPreferenceNames.SRA_OWNER_FILTER"
            :filters="getFiltersWithOptions()"
            :apply-filters="applyFilters"
            :filters-pending="filtersPending"
            :filters-applied="filtersApplied"
            :reset-filters="resetFilters"
            >
            <template v-slot:territories>
              <b-form-group :label="filters.territories.label" label-cols="12">
                <b-form-select v-model="filters.territories.value" :options="filters.territories.options">
                </b-form-select>
              </b-form-group>
            </template>
            <template v-slot:country>
              <b-form-group :label="filters.country.label" label-cols="12">
                <multi-select
                  v-model="filters.country.value"
                  :options="filters.country.options"
                  label="name"
                  :multiple="true"
                  track-by="value"
                  :closeOnSelect="false"
                  :placeholder="$t('select_countries') | capitalize"
                  class="font-small"
                  :maxHeight="250"
              />
              </b-form-group>
            </template>
          </filter-builder>
        </b-col>
      </b-row>
    </template>

    <!-- Table -->
    <template v-slot:table>
    <t-table
        :columns="columns"
        :options="options"
        :local-data="items"
        id="recording-reconciled-owners"
        ref="owners"
    >
      <template v-slot:merge>
        <span class="d-flex align-items-center">
          <slot name="mergeExtended"/>
        </span>
      </template>
      <template v-slot:status="{ row }">
        <fa-icon v-if="!row.hasMandate"
          :icon="['fa', 'info-circle']"
          :title="$t('no_mandates') | capitalize"
          class="text-orange"
        />
        <router-link v-else-if="row.conflict"
          class="text-red small mr-2"
          :to="{
            name: 'user-actions',
            params: { type: actionsTabs.RIGHTS_OWNERSHIP_CONFLICT },
            query: { fuzzy_search: resoundId },
          }"
        >
          <fa-icon
            :icon="['fas', 'exclamation-circle']"
            :title="$t('ownership_conflicts') | capitalize"
            class="text-red"
          />
        </router-link>
      </template>
      <!-- Name -->
      <template v-slot:resoundId="{ row }">
        <database-link
          :type="linkTypes.RIGHTS_HOLDER"
          :item-id="row.ownerId"
        >
          {{ row.resoundId }}
        </database-link>
        <div v-if="row.mandateConflict">
          <router-link
            class="text-red small"
            :to="{
              name: 'user-actions',
              params: { type: actionsTabs.MANDATES },
              query: { fuzzy_search: row.resoundId },
            }"
          >
            {{ $t("mandate_conflict") | capitalize }}
          </router-link>
        </div>
      </template>
      <!-- Tariff -->
      <template v-slot:tariffs="{ row }">
        <NamesListModal
          class-names="table-meta mb-2"
          :text-plural="$t('tariffs')"
          :text-exception="$t('all') | capitalize"
          :text-none="$t('all') | capitalize"
          :exception="row.excludeTariffs && !!row.excludeTariffs.length"
          :values="getTariffs(row)"
        />
      </template>

      <!-- Countries -->
      <template v-slot:countries="{ row }">
        <NamesListModal
          class-names="table-meta mb-2"
          :text-plural="$t('countries')"
          :text-exception="$t('ww')"
          :text-none="$t('all') | capitalize"
          :sort="true"
          :exception="!!row.excludeCountries ? row.excludeCountries && !!row.excludeCountries.length : false"
          :values="getCountries(row)"
        />
      </template>

      <!-- Representative -->
      <template v-slot:representative="{ row }">
        <NamesListModal v-if="row.collectives.length"
            class-names="table-meta mb-2"
            :text-plural="$t('collectives')"
            :values="row.collectives && row.collectives.length ? row.collectives.flatMap(c => c.name) : []"
        />
        <span v-else>{{ $t("no_mandates") | capitalize }}</span>
      </template>

      <!-- Date Start -->
      <template v-slot:broadcastStartDate="{ row }">
        <span v-if="!editModeOwners">
          {{ row.broadcastStartDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.broadcastStartDate"
        />
      </template>

      <!-- Date End -->
      <template v-slot:broadcastEndDate="{ row }">
        <span v-if="!editModeOwners">
          {{ row.broadcastEndDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.broadcastEndDate"
        />
      </template>

      <!-- Date Bought -->
      <template v-slot:startDate="{ row }">
        <span v-if="!editModeOwners">
          {{ row.startDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.startDate"
        />
      </template>

      <!-- Date Sold -->
      <template v-slot:endDate="{ row }">
        <span v-if="!editModeOwners">
          {{ row.endDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.endDate"
        />
      </template>

      <!-- Split -->
      <template v-slot:split="{ row }">
        <span v-if="!editModeOwners">{{ `${row.split}%` }}</span>
        <div v-else class="mx-input-wrapper">
          <b-input
            type="number"
            min="0.01"
            max="100"
            step="0.01"
            v-model="row.split"
          />
        </div>
      </template>

      <!-- Invalidate -->
      <template v-slot:deleted="{ row }">
          <span v-if="row.deleted">{{ getDate(row.deleted) }}</span>
          <fa-icon class="clickable" v-else-if="editModeOwners" :title="$t('invalidate') | capitalize" :icon="['fa', 'trash']" @click="deleteShare(row)"/>
      </template>

    </t-table>
    </template>
  </list-layout>
</template>
<script>
import { actionsTabs, filterTypes, landingDataStatuses, linkTypes, metadataConflictTypeCodes, rightsholderTypes, userActionStatuses, userPreferenceNames } from "@/constants"
import { capitalize, set, startCase } from "lodash"
import { mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import FilterBuilder from "@/components/FilterBuilder"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import moment from "moment"
import { rightsholderDetailTabs } from "@/constants"

export default {
  name: "ReconciledOwnersList",
  props: {
    recordingUuid: {
      type: String,
      required: true,
    },
    resoundId: {
      type: String,
      required: true,
    },
    ownerData: {
      type: Array,
      required: true,
    },
    countriesOptions: {
      type: Array,
    },
    hideInvalidated: {
      type: Boolean,
      default: false,
    },
    hideWithoutMandates: {
      type: Boolean,
      default: false,
    },
    selectedElements: {
      type: Number,
    },
    sourceIndex: {
      type: Number,
    },
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    ...mapMutations("repertoire", [
      "setSelectActive",
      "setComments",
      "checkOwnersChallengeButton",
    ]),
    getFiltersWithOptions () {
      this.filters.country.options = this.countriesOptions.map(c => { return { "name": c.text, "value": c.value }})
      this.filters.territories.options = [
        { text: capitalize(this.$t("ww_except")), value: true },
        { text: capitalize(this.$t("in")), value: false }
      ]
      return this.filters
    },
    applyFilters () {
      this.countriesFilter.except = this.filters.territories.value
      this.countriesFilter.values = this.filters.country.value
      this.filtersPending = false
    },
    resetFilters () {
      this.filters.territories.value = this.filters.territories.defaultValue
      this.filters.country.value = this.filters.country.defaultValue
      this.$nextTick(() => {
        this.applyFilters()
      })
      
    },
    noChallengesPending (row) {
      return ![
        userActionStatuses.PENDING,
        userActionStatuses.IN_PROGRESS,
      ].includes(row.matchingChallengeStatus) && row.status !== landingDataStatuses.PENDING
    },
    rowChecked (row) {
      let added = true
      if (this.selected.includes(row.resoundId)) {
        added = false
        this.selected = this.selected.filter(s => s !== row.resoundId)
      }
      this.setSelectActive({
        identifier: row.resoundId,
        data: `recordingOwners[${this.recordingUuid}].sources[${this.sourceIndex}].landingShares`,
        fieldIdentifier: "resoundId",
        added: added,
      })
      // This event will update the parent, and thus it will update all OwnersList siblings
      this.$emit("selected", added)
    },
    relatedOwner (row) {
      const sourceData = this.updateDict ? this.updateDict : this.recordingOwners[this.recordingUuid].owners
      const relatedOwner = sourceData.find(o => row.resoundId && o.resoundId === row.resoundId)
      return relatedOwner ? relatedOwner : null
    },
    isChallenging (identifier) {
      let landingShare = this.ownerData.find(r => {
        return r.resoundId === identifier
      })

      return landingShare ? landingShare.selectActive : false
    },
    challengeUuid (row) {
      return row.matchingChallengeId
    },
    matchingConflictUuid (row) {
      return row.matchingConflictId
    },
    availableToChallenge (row) {
      let ownerLinkedTo = this.relatedOwner(row)
      if (ownerLinkedTo) {
        let landingShares = this.recordingOwners[this.recordingUuid].sources.flatMap(s =>
          s.landingShares.filter(ls => ownerLinkedTo.resoundId === ls.resoundId)
        )
        let challengedSources = []
        if (this.selectedElements) {
          const challengedLandingOwners = landingShares.filter(
            ls => ls.selectActive || !this.noChallengesPending(ls)
          )
          if (challengedLandingOwners.length) {
            challengedSources.push(challengedLandingOwners)
          }
        }
        return (
          row.unchallengedLandingMakers - challengedSources.length > 1 ||
          this.isChallenging(row.resoundId)
        )
      } else {
        return false
      }
    },
    getDate (date) {
      return date ? moment(date).format(this.$config.ISO_DATE_FORMAT) : ""
    },
    getTariffs (row) {
      if (!row.tariffs) {
        return []
      }
      return row.tariffs.length ? row.tariffs : row.excludeTariffs
    },
    getCountries (row) {
      return row.countries && row.countries.length ? (row.countries || []) : (row.excludeCountries || [])
    },
    challengeButtonActive () {
      this.setComments({
        comments: this.comments,
        data: `recordingOwners[${this.recordingUuid}].sources[${this.sourceIndex}].landingShares`,
        fieldIdentifier: "resoundId",
      })
      this.checkOwnersChallengeButton(this.recordingUuid)
    },
    deleteShare (item) {
      item.toDelete = true
      this.$emit("delete:share")
    }
  },
  components: {
    DatabaseLink,
    NamesListModal,
    FilterBuilder,
  },
  data () {
    return {
      linkTypes,
      rightsholderTypes,
      actionsTabs,
      landingDataStatuses,
      rightsholderDetailTabs,
      metadataConflictTypeCodes,
      userPreferenceNames,
      filtersPending: false,
      set,
      columns: [
        "status",
        "resoundId",
        "name",
        "tariffs",
        "countries",
        "representative",
        "broadcastStartDate",
        "broadcastEndDate",
        "startDate",
        "endDate",
        "split",
        "deleted",
      ],
      options: {
        width: {
          "status": "10px",
        },
        sortable: ["resoundId", "name", "broadcastStartDate", "broadcastEndDate", "startDate", "endDate", "split", "deleted"],
        headings: {
          "resoundId": startCase(this.$t("resound_id")),
          "name": startCase(this.$t("name")),
          "tariffs": startCase(this.$t("tariff")),
          "countries": startCase(this.$t("territory")),
          "representative": startCase(this.$t("representative")),
          "broadcastStartDate": startCase(this.$t("broadcast_start")),
          "broadcastEndDate": startCase(this.$t("broadcast_end")),
          "startDate": startCase(this.$t("date_start")),
          "endDate": startCase(this.$t("date_end")),
          "split": startCase(this.$t("split")),
          "deleted": startCase(this.$t("invalidated")),
        }
      },
      countriesFilter: {
        except: false,
        values: [],
      },
      filters: {
        territories: {
          value: false,
          defaultValue: false,
          label: capitalize(this.$t("territories")),
          type: filterTypes.CUSTOM,
        },
        country: {
          value: [],
          defaultValue: [],
          label: capitalize(this.$t("countries")),
          type: filterTypes.CUSTOM,
          options: [],
        },
      }
    }
  },
  computed: {
    ...mapState("repertoire", [
      "editModeOwners",
      "recordingOwners",
      "selecting",
      "selectCancelled",
    ]),
    items () {
      let data = []
      if (this.countriesFilter.values.length) {
        const values = this.countriesFilter.values.map(c => c.name)
        if (this.countriesFilter.except) {
          data = this.ownerData.filter(o => {
            if (o.countries.length) {
              return o.countries.every(c => !values.includes(c))
            } else {
              return values.every(v => o.excludeCountries.includes(v))
            }
          })
        } else {
          data = this.ownerData.filter(o => {
            if (o.countries.length) {
              return o.countries.some(c => values.includes(c))
            } else {
              return values.some(v => !o.excludeCountries.includes(v))
            }
          })
        }
      } else {
        data = this.ownerData
      }
      if (this.hideInvalidated) {
        data = data.filter(d => d.deleted === null && (d.endDate === null || new Date(d.endDate) > new Date()))
      }
      if (this.hideWithoutMandates) {
        data = data.filter(d => d.hasMandate)
      }

      return data
    },
    filtersApplied () {
      if( this.countriesFilter.except === false && this.countriesFilter.values.length === 0) {
        return false
      }
      return true
    }
  },
  watch: {
    selectCancelled () {
      this.selected = []
      this.comments = {}
    },
    comments: {
      handler () {
        this.challengeButtonActive()
      },
      deep: true,
    },
    selected: {
      handler () {
        this.challengeButtonActive()
      },
      deep: true,
    },
    "filters.country.value": function () {
      this.filtersPending = true
    },
    "filters.territories.value": function () {
      this.filtersPending = true
    },
  }
}
</script>

<style lang="scss" scoped>
.very-small {
  font-size: 9pt;
}

::v-deep .filter-input-container {
  .multiselect__content-wrapper {
    position: relative;
    max-width: 280px !important;
  }
}
</style>
