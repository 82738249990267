<template>
  <div class="mx-2 d-flex" :class="{disabled: disabled}">
    <fa-icon class="icon" :icon="icon" @click="$emit('click')" :title="title"/>
  </div>
</template>

<style lang="scss" scoped>
  .icon {
    cursor: pointer;
    position: relative;
    color: var(--gray-dark);
    height: auto;

    &:active {
      top: 3px;
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>

<script>
export default {
  name: "TableAction",
  props: {
    title: String,
    icon: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>