<template>
  <div>
    <b-row v-if="header">
      <h4 class="ml-3 mt-3">
        {{ $t("comments") | startcase }}
      </h4>
    </b-row>
    <modal
      :id="usageModalTypes.COMMENT_CHALLENGE"
      :customTitle="$t('add_comment') | startcase"
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered
      hide-footer
    >
      <usage-challenge-form
        :id="usageModalTypes.COMMENT_CHALLENGE"
        :recording="recording"
        :usage="usage"
        :challenge-id="uuid"
        :refresh-handle="getComments"
      />
    </modal>
    <modal
      id="new-comment-modal"
      :customTitle="$t('add_comment') | startcase"
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg"
      centered
      hide-footer
    >
      <b-form @submit.prevent="makeComment('new-comment-modal')">
        <b-form-group
          :label="`${$t('comment')}` | capitalize"
          class="mt-4"
        >
          <b-form-textarea
            size="lg"
            class="mb-4"
            debounce="500"
            name="comment"
            :placeholder="$t('make_comment') | capitalize"
            v-model="newComment"
            rows="5"
          />
        </b-form-group>
        <b-row class="modal-footer mt-4">
          <b-button @click="$bvModal.hide('new-comment-modal')" class="btn-outline-dark">
            {{ $t("cancel") | capitalize }}
          </b-button>
          <b-button type="submit" :disabled="!newComment" class="btn-outline-red">
            {{ $t("save") | capitalize }}
          </b-button>
        </b-row>
      </b-form>
    </modal>
    <v-server-table
        :columns="columns"
        :options="options"
        ref="commentsTable"
        @loaded="sendLatestSuggestedMatches"
    >
      <template v-slot:createdBy="{ row }">
        <span>{{ row.createdBy.firstName }} {{ row.createdBy.lastName }}</span>
      </template>

      <template v-slot:created="{ row }">
        <div class="d-inline-flex align-middle">
          <span>
            {{ parseDate(row.created, $config.DATE_FORMAT) }}
          </span>
          <span class="text-gray ml-2 standard-font-size">
            {{ parseDate(row.created, $config.TIME_FORMAT) }}
          </span>
        </div>
      </template>

      <template v-slot:comment="{ row } ">
        <span v-if="!editing || editing !== row.id"> {{ row.comment }} </span>
        <b-form-textarea
          v-else-if="row.id === editing"
          size="xs"
          v-model="editCommentData.comment"
          :value="editCommentData.comment"
        />
      </template>

      <template v-slot:suggestedEffectiveDate="{ row } ">
        <span v-if="!editing || editing !== row.id"> {{ row.suggestedEffectiveDate ? parseDate(row.suggestedEffectiveDate, $config.DATE_FORMAT) : "" }} </span>
        <date-picker
          v-else-if="row.id === editing && editCommentData.suggestedEffectiveDate"
          class="w-100"
          v-model="editCommentData.suggestedEffectiveDate"
          :format="$config.DATE_FORMAT"
        />
      </template>

      <template v-slot:actions="{ row }">
        <div v-if="permissions.actions.canModifyAnyComment || isCurrentUser(row.createdBy.uuid)">
          <span v-if="editEndpoint">
            <fa-icon
              v-if="editing && editing === row.id"
              size="1x"
              :title="$t('save') | lowercase"
              :icon="['fa', 'check']"
              class="mr-1 clickable"
              @click="editCommentHandler(row)"
            />
            <fa-icon
              v-if="editing && editing === row.id"
              size="1x"
              :title="$t('cancel') | lowercase"
              :icon="['fa', 'reply']"
              class="mr-2 clickable"
              @click="cancelEditMode()"
            />
            <fa-icon
              v-if="!editing"
              size="1x"
              :title="$t('edit') | lowercase"
              :icon="['fa', 'pen']"
              class="view mr-2 clickable"
              @click="editMode(row, {comment: row.comment, suggestedEffectiveDate: row.suggestedEffectiveDate ? moment(row.suggestedEffectiveDate).toDate() : null })"
            />
          </span>
          <span v-if="deleteEndpoint && !editing">
            <fa-icon
              role="button"
              size="1x"
              :title="$t('delete') | lowercase"
              :icon="['fa', 'trash']"
              class="clickable"
              @click="onDelete(row.id)"
            />
            <!-- Deletion Confirmation Modal -->
            <modal
              :id="'confirmation-modal-' + row.id"
              :customTitle="$t('delete_comment') | capitalize"
              modal-class="custom-modal edit"
              modalType="edit"
              size="md"
              centered 
              @ok="onConfirmDelete(row.id)"
              okTitle="Confirm"
            >
              <div>
                {{ $t("do_you_really_want_to_delete_comment") | capitalize }}?
              </div>
            </modal>
          </span>
        </div>
      </template>
    </v-server-table>
    <b-row>
      <b-button
        variant="outline-secondary"
        class="mt-4 ml-3"
        @click="openChallengeModal"
      >
        {{ $t("add_comment") | startcase }}
      </b-button>
    </b-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import UsageChallengeForm from "@/pages/Usages/Forms/UsageChallengeForm"
import { commentMixin } from "@/utils/mixins"
import moment from "moment"
import parseDate from "@/utils/date-parser"
import { uniq } from "lodash"
import { usageModalTypes } from "@/constants"

export default {
  name: "CommentsTable",
  mixins: [commentMixin],
  components: { UsageChallengeForm },
  props: {
    listEndpoint: {
      type: Function,
      required: true,
    },
    editEndpoint: {
      type: Function,
    },
    deleteEndpoint: {
      type: Function,
    },
    createEndpoint: {
      type: Function,
    },
    uuid: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    headings: {
      type: Object,
      required: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    createModalId: {
      type: String,
    },
    recording: Object,
    usage: Object,
  },
  data () {
    return {
      options: {
        headings: this.headings,
        columnsClasses: {
          "actions": "width-nano-big",
        },
        sortable: [],
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (params) {
          return this.$parent.$parent.listEndpoint(this.$parent.$parent.uuid, params)
        },
      },
      usageModalTypes,
    }
  },
  computed: {
    ...mapGetters("user", ["isCurrentUser"]),
    ...mapState("user", ["permissions"])
  },
  watch: {
    uuid () {
      this.getComments()
    },
  },
  methods: {
    moment,
    ...mapMutations("alert", ["error", "success"]),
    parseDate,
    editCommentHandler (row) {
      let data = { comment: this.editCommentData.comment }
      if (this.editCommentData.suggestedEffectiveDate) {
        data["suggestedEffectiveDate"] = moment(this.editCommentData.suggestedEffectiveDate).format(this.$config.ISO_DATE_FORMAT)
      }
      this.editComment(row, data)
    },
    openChallengeModal () {
      this.$bvModal.show(this.createModalId ? this.createModalId : "comment-modal")
    },
    getComments () {
      this.$refs.commentsTable.getData()
    },
    sendLatestSuggestedMatches () {
      if (this.$refs.commentsTable.data.length > 0) {
        const latestSuggestedMatches = this.$refs.commentsTable.data.map(c => c.suggestedMatchPublicId).filter(id => id)
        this.$emit("fetched", uniq(latestSuggestedMatches))
      }
    }
  },
}
</script>
