<template>
    <inline-conflict-table
        :tableId="tableId"
        :panes-height-fn="panesHeightFn"
        :headings="headings"
        :columns="columns"
        :action-types="actionTypes"
        :filters="filters"
        :action-status="actionStatus"
        :solved-manually-parent="solvedManually"
        :defaultPerPageItemsLimit="defaultPerPageItemsLimit"
        ref="userActionConflictsTable"
        @selected="propagateSelected"
        @pagination="onPagination"
        @limit="onLimit"
        />
</template>

<script>

import InlineConflictTable from "@/pages/UserActions/InlineConflictTable"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "UserActionConflictTable",
  components: { InlineConflictTable },
  mixins: [listRouteMixin],
  props: {
    panesHeightFn: {
      type: Function,
    },
    headings: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    actionStatus: {
      type: Boolean,
      required: true,
    },
    actionTypes: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    sourceId: {
      type: String,
    },
    solvedManually: {
      type: Array,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    defaultPerPageItemsLimit: {
      type: Number,
      required: true
    },
  },
  methods: {
    onPagination (page) {
      this.updateRouterPagination("tablePage", page)
    },
    onLimit (limit) {
      this.updateRouterPagination("tablePerPage", limit)
    },
    propagateSelected (conflict) {
      this.$emit("selected", conflict)
    },
    getData () {
      this.$refs.userActionConflictsTable.getData()
    },
    selectNext () {
      this.$refs.userActionConflictsTable.selectNext()
    }
  },
  created () {
    this.updateRouterPagination("tablePage", 1)
  }
}
</script>
