<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h4 class="ml-2">{{ memberCollective }}</h4>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <sources v-if="sources.length > 0" :sources="sources" :source-type="sourceTypes.REPERTOIRE_FILE"/>
          <sources v-if="inputSources.length > 0" :sources="inputSources" :source-type="sourceTypes.RIGHTSHOLDER_BATCH"/>
        </b-col>
      </b-row>
    </b-card>
    <b-table :fields="fields()" :items="items" fixed striped>
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>

      <template v-slot:cell(showDetails)="row" >
        <collapse-button v-if="row.item.tariffs.length > 1" :toggle="'row-' + row.item.id" :expanded="expanded[row.index]"/>
      </template>

      <template v-slot:cell(tariffs)="row">
        <b-row align-v="start">
          <b-col>
            <span v-if="showTariffLabel(row)">{{ getTariffLabel(row.item.tariffs) }}</span>
            <b-collapse v-if="row.item.tariffs.length > 1" :id="'row-' + row.item.id" v-model="expanded[row.index]">
             <b-row v-for="tariff in getFullCopyrights(row.item.tariffs)" :key="tariff.value">
               <b-col>{{ tariff.text }} <span class="text-gray">({{ tariff.copyright }})</span></b-col>
             </b-row>
            </b-collapse>
          </b-col>
        </b-row>
      </template>
    </b-table>
  </div>
</template>

<script>
import { capitalize, isEmpty, uniq } from "lodash"
import MandateFieldsMixin from "@/pages/Rightsholders/RightsHolderDetail/MandateFieldsMixin"
import Sources from "@/components/Sources"
import { mapState } from "vuex"
import { sourceTypes } from "@/constants"

export default {
  name: "RightsHolderMandateMetadata",
  components: { Sources },
  props: {
    rightsHolderType: String,
    memberCollective: String,
    mandates: Array,
    sources: Array,
    inputSources: Array,
    selectedMandate: Object
  },
  mixins: [MandateFieldsMixin],
  data () {
    return {
      isSource: true,
      expanded: [],
      sourceTypes: sourceTypes
    }
  },
  computed: {
    ...mapState("consts", ["countries", "tariffs"]),
    items () {
      let id = 0
      let nextId = function () { id = id + 1; return id}
      return this.mandates.map(function (m){
        return {
          id: `${this.memberCollective}-m-${nextId()}`,
          tariffs: m.tariffs,
          territory: this.getMandateTerritory(m),
          memberCollective: m.membership,
          indirectMC: m.indirectMembership,
          agent: m.agent ? m.agent.name : "-",
          startDate: m.startDate,
          endDate: m.endDate,
          category: this.getCategory(m),
          _rowVariant: this.isSelected(m)
        }
      }, this)
    }
  },
  methods: {
    getCountriesList (countries, exclude=false) {
      if (exclude) {
        return this.countries.filter(c => !countries.includes(c.alpha2)).map(c => c.text)
      } else {
        return this.countries.filter(c => countries.includes(c.alpha2)).map(c => c.text)
      }
    },
    getTariffLabel (tariffs) {
      if (tariffs.length === 1) {
        return this.tariffs.find(t => (t.code === tariffs[0])).text
      } else if (tariffs.length > 1) {
        let copyrights = uniq(tariffs.map(t => this.tariffs.find(tt => tt.code === t).copyright))
        let copyrightsLabel = copyrights.length === 1 ? `in ${copyrights[0]}`:`in ${copyrights.length} ${this.$t("copyrights")}`
        return `${tariffs.length} ${capitalize(this.$t("tariffs"))} ${copyrightsLabel}`
      }
    },
    getFullCopyrights (tariffs) {
      return this.tariffs.filter(t => tariffs.includes(t.code))
    },
    showTariffLabel (row) {
      return row.item.tariffs.length === 1 || !this.expanded[row.index]
    },
    isSelected (mandate) {
      let m = this.selectedMandate
      if (!isEmpty(m)
          && m.membership.memberCollective.name === mandate.membership
          && m.startDate === mandate.startDate
          && m.endDate === mandate.endDate) {
        let tariffs = m.tariffs.filter((t) => mandate.tariffs.includes(t.code))
        return tariffs.length > 0 && this.compareTerritory(mandate) ? "info" : ""
      }
      return ""
    },
    compareTerritory (mandate) {
      let m = this.selectedMandate
      if (m.scope !== mandate.scope) {
        return false
      }
      if (mandate.scope === "domestic") {
        return true
      } else {
        let countries = this.countries.filter((c) => m.countries.includes(c.id))
        return countries.filter((c) => mandate.countries.includes(c.alpha2)).length > 0
      }
    }
  }
}
</script>

<style scoped>

::v-deep tbody > tr > td {
  vertical-align: top !important;
}

::v-deep .table-striped th {
  background-color: #ededed;
}

::v-deep .table-striped tbody tr:nth-of-type(2n) {
  background-color: #fafafa;
}

::v-deep .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: white;
}
</style>
