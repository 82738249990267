<template>
  <list-layout
      :isChild="true"
      :title="(!userAction ? $t('consolidated_usages') : $t('usage_challenge_actions')) | startcase"
      :count="(!userAction ? '' : userActionCounts.usageChallengeActionsCount)"
      ref="listLayout"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
  >
    <!-- Search -->
    <template v-slot:search>
      <form-field
          :placeholder="$t('search') | capitalize"
          v-model="filters.fuzzy_search.value"
          :enter-action="applyFilters"
          :icon="['fas', 'search']"
      />
    </template>

    <template v-slot:filters>
      <b-row align-h="end">
        <b-col cols="3">
          <b-form-checkbox v-model="actionStatus" switch>
            {{ $t("show_resolved") | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>

    <!-- Counts -->
    <template v-slot:counts>
      <div class="row align-items-center">
        <div class="col d-flex">
          <pagination
              class="smaller p-0"
              :page="page"
              :count="count"
              :loading="loading"
              reference="usages"
              v-model="limit"
              @limit="onLimit"
          />
        </div>
      </div>
    </template>
    <!-- Table -->
    <template v-slot:table>
      <crescendo-split-panes ref="panes">
        <template v-slot:top>
          <v-server-table
              :columns="columns"
              :options="options"
              ref="usages"
              @row-click="setMatchedRecording"
              @loaded="onLoaded"
              @loading="onLoading"
              @pagination="onPagination"
              @sorted="onSorted"
          >
            <template v-slot:inProgress="{ row }">
              <modal
                :id="`confirmation-modal-solved-${row.id}`"
                :customTitle="$t('user_action_solved') | capitalize"
                modal-class="custom-modal edit"
                modalType="edit"
                size="md"
                centered
                hide-footer
                hide-header-close
                no-close-on-esc
                no-close-on-backdrop
              >
                <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
                <b-row class="float-right modal-footer mt-4">
                  <b-button @click="blinkAndHide(row.id, false, getData)" class="btn-outline-red">
                    {{ $t("accept") | capitalize }}
                  </b-button>
                </b-row>
              </modal>
              <fa-icon
                v-if="isInProgressFrontend(row.id) || (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))"
                :icon="['fa', 'user-edit']"
                 color="red"
              />
            </template>

            <template v-slot:usage.artist="{ row }">
              <span :title="row.usage.artist">{{ row.usage.artist }}</span>
            </template>

            <template v-slot:usage.title="{ row }">
              <router-link
                  v-if="row.usage.recordingUuid"
                  class="text-info"
                  :to="{
                    name: 'repertoire-recording-detail',
                    params: {
                      recordingId: row.usage.recordingUuid,
                      type: 'general-details',
                    },
                  }"
              >
                <span :title="row.usage.title">{{ row.usage.title }}</span>
              </router-link>
              <span v-else :title="row.usage.title">{{ row.usage.title }}</span>
            </template>

            <template v-slot:usage.release="{ row }">
              <span :title="row.usage.release">{{ row.usage.release }}</span>
            </template>

            <template v-slot:usage.stationNames="{ row }">
              <NamesListModal
                  :values="row.usage.stationNames ? row.usage.stationNames : []"
                  :title="row.usage.stationNames ? row.usage.stationNames.join(', ') : ''"
              />
            </template>

            <template v-slot:usage.sourceNames="{ row }">
              <NamesListModal :values="row.usage.sourceNames" :title="row.usage.sourceNames"/>
            </template>

            <!-- Status -->
            <template v-slot:status="{ row }">
              <status
                v-if="(
                  isInProgressFrontend(row.id) ||
                  (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))
                ) && !isSolved(row.id)"
                :text="$t('in_progress') | capitalize"
                :icon="['fas', 'question-circle']"
                color="orange"
              />
              <status
                v-else-if="(isPending([row.status]) || isReleasedFrontend(row.id)) && !isSolved(row.id)"
                :text="$t('challenged') | capitalize"
                :icon="['fas', 'exclamation-circle']"
                color="red"
              />
              <status
                v-else
                :text="$t('resolved') | capitalize"
                :icon="['fas', 'check-circle']"
                color="green"
              />
            </template>
          </v-server-table>
        </template>

        <!-- Suggested candidates section -->
        <template v-slot:bottom>
          <div v-if="bottomPaneVisible" class="overflow-auto h-100 overflow-x-hidden">
            <usage-matched-recording
                ref="matchedRecording"
                v-if="recordingUuid"
                :usage-id="selectedUsageId"
                :uuid="recordingUuid"
                :challenge-uuid="actionStatus ? null : challengeUuid"
                :challenge-view="!actionStatus"
                :solved-manually="solvedManually"
                @challenged="updateMatchingInfo"
                @resolved="updateMatchingInfo"
                @loaded="setMatchedRecordingObj"
            />
            <usage-recording-candidates
                v-if="recordingUuid && !actionStatus"
                :uuids="[recordingUuid]"
                :candidates="candidatesData"
                :challenge-view="!actionStatus"
                :challenge-uuid="challengeUuid"
                :solved-manually="solvedManually"
                @resolved="updateMatchingInfo"
            />
            <comments-table
                v-if="recordingUuid && challengeUuid"
                :createEndpoint="$api.comments.usageChallengeCommentCreate"
                :listEndpoint="$api.comments.usageChallengeCommentsList"
                :uuid="challengeUuid"
                :columns="commentsTableColumns"
                :headings="commentsTableHeadings"
                :create-modal-id="usageModalTypes.COMMENT_CHALLENGE"
                :recording="matchedRecording"
                @fetched="setLatestSuggestedMatches"
            />
          </div>
        </template>
      </crescendo-split-panes>
    </template>
  </list-layout>
</template>
<script>
import {
  actionsTabs,
  getPerPageItemDefaults,
  usageMatchingStatuses,
  usageModalTypes,
  userActionNotifications,
  userActionStatuses
} from "@/constants"
import { capitalize, findIndex, get, startCase, upperCase } from "lodash"
import { cartMixin, listRouteMixin, unclaimedMixin } from "@/utils/mixins"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import CommentsTable from "@/components/CommentsTable"
import CrescendoSplitPanes from "@/components/CrescendoSplitPanes"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Pagination from "@/components/Pagination"
import UsageMatchedRecording from "@/pages/Usages/UsageMatchedRecording"
import UsageRecordingCandidates from "@/pages/Usages/UsageRecordingCandidates"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "UsageChallengeList",
  mixins: [cartMixin, listRouteMixin, unclaimedMixin, websocketsMixin],
  components: {
    CrescendoSplitPanes,
    CommentsTable,
    UsageMatchedRecording,
    NamesListModal,
    Pagination,
    UsageRecordingCandidates,
  },
  props: {
    userAction: {
      default: true,
    },
  },
  computed: {
    ...mapGetters("user", ["isInternal"]),
    ...mapState("consts", ["countries"]),
    ...mapState("user", ["permissions"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    columns () {
      let columns = [
        "inProgress",
        "usage.artist",
        "usage.title",
        "usage.release",
        "usage.isrc",
        "usage.effectivePlaycount",
        "usage.weightedPlaycount",
        "usage.stationNames",
        "usage.sourceNames",
        "status",
      ]
      if (!this.isInternal) {
        columns.splice(-3, 2)
      }
      return columns
    },
    actionStatus: {
      get () {
        return this.filters.status.value.length === 1 && this.filters.status.value[0]  === userActionStatuses.DONE
      },
      set (value) {
        this.filters.status.value = value ? userActionStatuses.DONE : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
      },
    },
    // WS values
    shownUuidList () {
      return this.challenges.map(c => c.id)
    },
    bottomPaneVisible () {
      return (this.recordingUuid) || 
        (this.recordingUuid && !this.actionStatus) || 
        (this.recordingUuid && this.challengeUuid)
    },
  },
  watch: {
    challengeUuid (newValue, oldValue) {
      if (oldValue) {
        this.onClosed(oldValue)
      }
      this.onOpened(newValue)
    },
    latestSuggestedMatches (value) {
      if (value.length) {
        return this.$api.repertoire
          .recordingsList({ uuids: value })
          .then(res => {
            this.candidatesData = res.data.results
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.error(capitalize(this.$t("wrong_uuids_specified")))
            }
          })
      } else {
        this.candidatesData = []
      }
    },
  },
  data () {
    return {
      usageModalTypes,
      recordingUuid: null,
      selectedUsageId: null,
      challengeUuid: null,
      challengeView: false,
      latestSuggestedMatches: [],
      candidatesData: [],
      manualSize: 0,
      loading: false,
      page: 1,
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      usageMatchingStatuses: usageMatchingStatuses,
      matchedRecording: null,
      commentsTableColumns: [
        "created",
        "createdBy",
        "createdBy.memberCollective",
        "comment",
        "suggestedMatchId",
      ],
      commentsTableHeadings: {
        created: capitalize(this.$t("when")),
        createdBy: capitalize(this.$t("reviewer")),
        "createdBy.memberCollective": startCase(this.$t("mc")),
        comment: capitalize(this.$t("comment")),
        suggestedMatchId: this.$t("suggested_match_id"),
      },
      filters: {
        fuzzy_search: { value: "", defaultValue: "" },
        status: {
          value: "",
          defaultValue: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS],
        },
      },
      challenges: [],
      options: {
        sortable: [],
        headings: {
          "inProgress": "",
          "usage.title": capitalize(this.$t("title")),
          "usage.artist": capitalize(this.$t("artist")),
          "usage.release": capitalize(this.$t("album")),
          "usage.isrc": upperCase(this.$t("isrc")),
          "usage.effectivePlaycount": capitalize(this.$t("playcount")),
          "usage.weightedPlaycount": capitalize(this.$t("weighted_playcount")),
          "usage.stationNames": capitalize(this.$t("stations")),
          "usage.sourceNames": capitalize(this.$t("sources")),
          status: capitalize(this.$t("status")),
        },
        columnsClasses: {
          "inProgress": "width-2",
          "usage.artist": "short-text",
          "usage.title": "short-text",
          "usage.release": "short-text",
          "usage.sources": "short-text",
        },
        rowClassCallback: (row) => {
          let classes = []
          if (row.status !== usageMatchingStatuses.UNMATCHED) {
            classes.push("hover-highlight")
          }
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          return classes.join(" ")
        },
        responseAdapter ({ data }) {
          let componentData = this.$parent.$parent.$parent.$parent.$parent.$parent
          if (data.results.length === 1) {
            componentData.selectedUsageId = data.results[0].usageId
            componentData.recordingUuid = data.results[0].usage.recordingUuid
            componentData.challengeUuid = data.results[0].id
          }
          componentData.challenges = data.results
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent.$parent.$parent.$parent
          componentData.recordingUuid = null
          componentData.challengeUuid = null
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.actions.usageChallengeActions(queryParams)
        },
      },
      userActionStatuses,
      // WS values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.USER_ACTION,
      storeStatusesKey: "wsUserActionStatuses",
      actionsTabs,
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries"]),
    ...mapMutations("alert", ["success", "error"]),
    getData () {
      this.$refs.usages.getData()
    },
    onClick (event) {
      event.stopPropagation()
    },
    setMatchedRecordingObj (recording) {
      this.matchedRecording = recording
    },
    mergeCandidates (data) {
      let candidates = []
      data.forEach(item => {
        item.candidates.forEach(c => {
          let included = candidates.find(ic => ic.id === c.id)
          if (included) {
            included.rank = Math.max(included.rank, c.rank)
          } else {
            candidates.push(c)
          }
        })
      })

      return candidates
    },
    setMatchedRecording (item) {
      if (item.row.usage.recordingUuid) {
        this.selectedUsageId = item.row.usageId
        this.recordingUuid = item.row.usage.recordingUuid
        this.challengeUuid = item.row.id
      }
    },
    onLoaded ({ data }) {
      this.$refs.panes.setHeight()
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    updateMatchingInfo () {
      this.$refs.usages.getData()
      this.recordingUuid = null
      this.challengeUuid = null
    },
    setLatestSuggestedMatches (uuids) {
      this.latestSuggestedMatches = uuids
    },
    parseDate,
    findIndex,
  },
  mounted () {
    if (!this.countries.length) {
      this.getCountries()
    }
    this.updateTableSortIcons(this.$refs.usages.$children[0])
  },
}
</script>
<style lang="scss" scoped>
/* stylelint-disable */
.VueTables::v-deep {
  &.VueTables--server,
  &.VueTables--client {
    .table-responsive {
      margin-bottom: 0.5em;

      .table {
        table-layout: fixed;

        thead {
          th {
            padding-left: 0;

            &.select-header {
              padding-bottom: 1.2em;
              padding-left: 0;

              .custom-header {
                padding-left: 0;
                position: relative;
                top: 1.4em;
              }
            }
          }

          tr {
            &.hover-highlight {
              &:hover {
                cursor: pointer;
                background-color: $red-light;
              }
            }

            .sequence-number {
              width: 2rem;
            }
          }
        }
      }

      tr {
        &.hover-highlight {
          &:hover {
            cursor: pointer;
            background-color: $red-light;
          }
        }

        .sequence-number {
          width: 2rem;
        }

        td {
          &,
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          padding: 0;
        }
      }
    }
  }
}

/* stylelint-enable */
</style>
