<template>
  <list-layout 
    :isChild="true"
    :title="$t('manual_matching') | startcase"
    :count="userActionCounts.repertoireActionsCount"
    :search-filter="filters.fuzzySearch"
    :apply-filters="applyFilters"
    :no-apply-btn="true"
  >
    <template v-slot:search>
      <div class="mt-2" v-if="repertoireFile">
        Repertoire file: {{ repertoireFile.fileName }}
        <fa-icon :icon="['fa', 'trash']" @click="filters.repertoireFile.value = null" class="clickable"/>
      </div>
    </template>

    <template v-slot:beforeFilters>
      <b-row>
        <b-col>
          <filter-builder
            ref="manualMatchingFilter"
            :id="activeTable"
            :filters="getFiltersWithOptions()"
            :filters-pending="filtersPending"
            :apply-filters="applyFilters"
            :reset-filters="resetFilters"
            :filters-applied="filtersApplied"
            @filter="onFilter"
          />
        </b-col>
      </b-row>
    </template>

    <template v-slot:tabFilters>
      <b-row align-h="center" style="column-gap: 5rem">
        <b-form-group>
          <b-form-radio-group
            v-model="filters.source_type.value"
            buttons
            button-variant="outline-secondary"
            name="radios-btn-outline"
            class="radio-buttons"
          >
            <b-form-radio :value="sourceTypes.REPERTOIRE_FILE">
              {{ $t("repertoire") | capitalize }}
            </b-form-radio>
            <b-form-radio :value="sourceTypes.RIGHTSHOLDER_BATCH">
              {{ $t("rightsholders") | capitalize }}
            </b-form-radio>
            <b-form-radio v-if="isInternal" :value="sourceTypes.PAYMENT_FILE">
              {{ $t("payments") | capitalize }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group>
          <b-form-radio-group
            v-model="filters.action_types.value"
            buttons
            button-variant="outline-secondary"
            name="radios-btn-outline"
            class="radio-buttons"
          >
            <b-form-radio v-if="filters.source_type.value !== sourceTypes.RIGHTSHOLDER_BATCH" 
              :value="[conflict_types.RECORDING_CONFLICT]">
              {{ $t("recording") | capitalize }}
            </b-form-radio>
            <b-form-radio v-if="hasPerformers" :value="[conflict_types.PERFORMERS_CONFLICT]">
              {{ $t("performer") | capitalize }}
            </b-form-radio>
            <b-form-radio v-if="hasMakers" :value="[conflict_types.MAKERS_CONFLICT]">
              {{ $t("maker") | capitalize }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-row>
    </template>

    <template v-slot:counts>
      <div v-show="filters.show_by_file.value">
        <pagination
          class="smaller p-0 mt-3"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="limit"
          @limit="onLimit"
        />
      </div>
    </template>

    <template v-slot:merge>
      <div v-show="filters.show_by_file.value">
        <b-row align-h="end" class="mr-2">
          <b-col cols="auto" v-if="filters.source_type.value === sourceTypes.PAYMENT_FILE">
            <b-form-checkbox v-model="actionStatus" class="ml-2" switch>
              {{ $t("show_resolved") | capitalize }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="auto">
            <b-form-checkbox v-if="permissions.actions.canUndoMatching" v-model="filters.undo_available.value" class="ml-2" :disabled="filters.repertoireFile.value || filters.show_by_file.value" switch>
              {{ $t("show_available_unmatch") | capitalize }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="auto">
            <b-form-checkbox v-model="filters.show_by_file.value" class="ml-2" :disabled="filters.repertoireFile.value || filters.undo_available.value" switch>
              {{ $t("show_by_file") | capitalize }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </template>
    
    <crescendo-split-panes ref="panes">
      <template v-slot:top>
        <div class="file-list-wrapper" v-if="filters.show_by_file.value">
          <div class="file-list">
            <b-card no-body v-for="(source, index) in data" :key="index">
              <b-card-header class="first-level">
                <b-row align-v="center">
                  <b-col cols="6">
                    <collapse-button
                      class="collapse-button"
                      :toggle="'source-' + source.id"
                      :expanded="expanded[index]"
                    />
                    <span>{{
                        $t(filters.source_type.value === sourceTypes.RIGHTSHOLDER_BATCH
                            ? "rightsholders"
                            : "repertoire"
                        ) | capitalize
                      }}:
                    </span>
                    <router-link class="text-info" :to="getSourceLinkParams(source.id)">
                      {{ getSourceName(source) }}
                    </router-link>
                  </b-col>
                  <b-col cols="6">
                    <b-row class="w-100">
                      <b-col cols="4">
                        <span>
                          {{ $t("uploaded_on") | capitalize }}:
                          {{ parseDate(source.uploadDate, $config.DATE_FORMAT) }}
                        </span>
                      </b-col>
                      <b-col cols="5">
                        <span>
                          {{ $t("by") | capitalize }}:
                          {{ getUploader(source.uploader) }}
                        </span>
                      </b-col>
                      <b-col cols="3">
                        <span
                          class="pending-matches-count-orange"
                          v-show="!actionStatus"
                        >
                          {{ $t("pending_matches") | capitalize }}:
                          {{ source.actionsCount }}
                        </span>
                        <span
                          class="resolved-matches-count-green"
                          v-show="actionStatus"
                        >
                          {{ $t("resolved_matches") | capitalize }}:
                          {{ source.actionsCount }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-collapse
                accordion="sources-list"
                :id="'source-' + source.id"
                v-model="expanded[index]"
                role="tabpanel"
              >
                <b-card-body v-if="expanded[index]">
                  <inline-conflict-table
                    :tableId="activeTable"
                    :headings="tableHeadings"
                    :columns="tableColumns"
                    :action-types="selectedActionTypes"
                    :filters="filtersWithoutSearch"
                    :sourceId="source.id"
                    :action-status="actionStatus"
                    :solved-manually-parent="solvedManually"
                    :ref="`inlineConflictTable-${source.id}`"
                    @loaded="setPanesHeight"
                    @selected="setConflictDetails"
                  />
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <table-pagination
            v-model="page"
            :total-rows="count"
            :per-page="limit"
          />
        </div>
        <user-action-conflict-table
            v-else
            class="h-100"
            :tableId="activeTable"
            :panes-height-fn="setPanesHeight"
            :headings="tableHeadings"
            :columns="tableColumns"
            :action-types="selectedActionTypes"
            :filters="filters"
            :action-status="actionStatus"
            :solved-manually="solvedManually"
            :defaultPerPageItemsLimit="limit"
            ref="conflictsTable"
            @selected="setConflictDetails"
        />
      </template>
      <template v-slot:bottom>
        <metadata-conflict-detail
          v-if="conflict"
          :conflict="conflict"
          :solved-manually="solvedManually"
          @matched="onMatched"
          @notMatched="onNotMatched"
        />
      </template>
    </crescendo-split-panes>
  </list-layout>
</template>

<script>
import { capitalize, get, upperCase } from "lodash"
import {
  conflictTypeCodes, filterTypes, getPerPageItemDefaults,
  highlightDuration, inlineRowStatus, metadataConflictTypeCodes, 
  rightsholderTypes, sourceTypes, statementConflictTypeCodes, 
  userActionStatuses, userPreferenceNames
} from "@/constants"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import { mapActions, mapGetters, mapState } from "vuex"
import CrescendoSplitPanes from "@/components/CrescendoSplitPanes"
import FilterBuilder from "@/components/FilterBuilder"
import InlineConflictTable from "@/pages/UserActions/InlineConflictTable"
import MetadataConflictDetail from "@/pages/UserActions/MetadataConflictDetail"
import Pagination from "@/components/Pagination"
import UserActionConflictTable from "@/pages/UserActions/UserActionConflictTable"
import parseDate from "@/utils/date-parser"

export default {
  name: "MetadataConflictFilesList",
  mixins: [datePickerMixin, listRouteMixin],
  components: {
    CrescendoSplitPanes,
    FilterBuilder,
    InlineConflictTable,
    MetadataConflictDetail,
    Pagination,
    UserActionConflictTable
  },
  methods: {
    ...mapActions("actionTabs", ["decrementUserActionCount"]),
    responseAdapter (data, expanded) {
      this.data = data.data.results
      this.count = data.data.count
      this.loading = false
      this.expanded = expanded
    },
    onFilter (name,value) { 
      if (name === "fixationYear") {
        if (value) {
          this.filters.fixationYearFrom.value = value[0]
          this.filters.fixationYearTo.value = value[1]
        } else {
          this.filters.fixationYearFrom.value = null
          this.filters.fixationYearTo.value = null
        }
      } else if (name === "dateRange") {
        this.dateRange = value ? value : ["", ""]
      }
    },
    getFiltersWithOptions () {
      this.activeTable = this.tableId()
      this.filters.title.hidden = !(["repertoireRecordingTable","paymentRecordingTable"].includes(this.activeTable))
      this.filters.artist.hidden = !(["repertoireRecordingTable","paymentRecordingTable"].includes(this.activeTable))
      this.filters.isrc.hidden = !(["repertoireRecordingTable","paymentRecordingTable"].includes(this.activeTable))
      this.filters.type.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.performers.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.firstMakers.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.fixationYear.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.fixationCountry.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.firstMakerNationalities.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.releaseNames.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.owners.hidden = !(this.activeTable === "repertoireRecordingTable")
      this.filters.name.hidden = (["repertoireRecordingTable","paymentRecordingTable"].includes(this.activeTable))
      this.filters.ipn.hidden = !(["repertoirePerformerTable","rightsholderPerformerTable"].includes(this.activeTable))
      this.filters.dateRange.hidden = !(["repertoirePerformerTable","rightsholderPerformerTable"].includes(this.activeTable))
      this.filters.gender.hidden = !(["repertoirePerformerTable","rightsholderPerformerTable"].includes(this.activeTable))
      this.filters.nationalities.hidden = !(["repertoirePerformerTable","repertoireMakerTable","rightsholderPerformerTable","rightsholderMakerTable"].includes(this.activeTable))
      this.filters.collective.hidden = !(["repertoirePerformerTable","repertoireMakerTable","rightsholderPerformerTable","rightsholderMakerTable"].includes(this.activeTable))
      this.filters.sisterSociety.hidden = !(["paymentRecordingTable","paymentPerformerTable","paymentMakerTable"].includes(this.activeTable))
      this.filters.grossAmount.hidden = !(["paymentRecordingTable","paymentPerformerTable","paymentMakerTable"].includes(this.activeTable))
      this.filters.paymentStatus.hidden = !(["paymentRecordingTable","paymentPerformerTable","paymentMakerTable"].includes(this.activeTable))
      this.filters.sisterSociety.options = this.memberCollectiveOptions
      this.filters.collective.options = this.memberCollectiveOptions
      this.filters.status.showInitially = !(this.filters.source_type.value === sourceTypes.PAYMENT_FILE)
      this.filters.status.hidden = this.filters.source_type.value === sourceTypes.PAYMENT_FILE
      this.filters.status.options = this.statusOptions
      return this.filters
    },
    resetFiltersOnActionChange () {
      Object.keys(this.filters).filter(f => this.filters[f].hidden === false).forEach(f => {
        if (f !== "status") {
          this.$refs.manualMatchingFilter.removeFilter(f, false)
        } else if (this.filters.source_type.value === sourceTypes.PAYMENT_FILE && f === "status") {
          this.$refs.manualMatchingFilter.removeFilter(f)
        }
      })
    },
    setPanesHeight () {
      if (this.$refs.panes) {
        this.$nextTick(() => this.$refs.panes.setHeight())
      }
    },
    getSourceLinkParams (uuid) {
      let linkParams = {
        name: "landing-recordings",
        params: { repFileId: uuid }
      }
      if (
        this.filters.source_type.value ===
          sourceTypes.RIGHTSHOLDER_BATCH
      ) {
        linkParams = {
          name: "landing-rightsholders-list",
          params: { batchId: uuid },
          query: { rh_type: this.filters.action_types.value.includes(this.conflict_types.PERFORMERS_CONFLICT) ? rightsholderTypes.PERFORMER : rightsholderTypes.MAKER }
        }
      }
      return linkParams
    },
    getUploader (uploader) {
      if (uploader && typeof uploader === "object") {
        return uploader.firstName + " " + uploader.lastName
      }
      return uploader
    },
    getSourceName (source) {
      return source.fileName
        ? source.fileName
        : `${upperCase(this.$t("sf"))} ${capitalize(
          this.$t("import")
        )} ${this.$t("on")} ${parseDate(source.uploadDate)}`
    },
    onDone (uuid) {
      this.$root.$emit("bv::toggle::collapse", "source-" + uuid)
      this.$api.repertoire
        .repertoireFilesList({
          ...this.$route.query,
          action_statuses: [this.filters.status.value],
          action_types: this.selectedActionTypes,
        })
        .then(response => {
          this.responseAdapter(response)
        })
    },
    onMatched () {
      const uuid = this.conflict.id
      let status = ""
      this.conflict = null
      let expandedSource = this.getExpandedSource()
      if (expandedSource && this.filters.show_by_file.value) {
        this.$refs[`inlineConflictTable-${expandedSource.id}`][0].selectNext()
        this.$refs[`inlineConflictTable-${expandedSource.id}`][0].data
          .filter(u => u.id === uuid)
          .map(u => {
            status = u.userActionStatus
            u.userActionStatus = inlineRowStatus.SUCCESS
          })
        setTimeout(() => {
          this.$refs[`inlineConflictTable-${expandedSource.id}`][0].data
            .filter(u => u.id === uuid)
            .map(u => {
              u.userActionStatus = status
            })
        }, highlightDuration)
        if (typeof expandedSource.actionsCount !== "undefined")
          expandedSource.actionsCount--
      } else {
        this.$refs.conflictsTable.selectNext()
        this.$refs.conflictsTable.$children[0].data
          .filter(u => u.id === uuid)
          .map(u => {
            status = u.userActionStatus
            u.userActionStatus = inlineRowStatus.SUCCESS
          })
        setTimeout(() => {
          this.$refs.conflictsTable.$children[0].data
            .filter(u => u.id === uuid)
            .map(u => {
              u.userActionStatus = status
            })
        }, highlightDuration)
      }
      this.decrementUserActionCount("repertoireActionsCount")
    },
    onNotMatched () {
      const uuid = this.conflict.id
      let status = ""
      let expandedSource = this.getExpandedSource()
      if (expandedSource && this.filters.show_by_file.value) {
        this.$refs[`inlineConflictTable-${expandedSource.id}`][0].data
          .filter(u => u.id === uuid)
          .map(u => {
            status = u.userActionStatus
            u.userActionStatus = inlineRowStatus.ERROR
          })
        setTimeout(() => {
          this.$refs[`inlineConflictTable-${expandedSource.id}`][0].data
            .filter(u => u.id === uuid)
            .map(u => {
              u.userActionStatus = status
            })
        }, highlightDuration)
      } else {
        this.$refs.conflictsTable.$children[0].data
          .filter(u => u.id === uuid)
          .map(u => {
            status = u.userActionStatus
            u.userActionStatus = inlineRowStatus.ERROR
          })
        setTimeout(() => {
          this.$refs.conflictsTable.$children[0].data
            .filter(u => u.id === uuid)
            .map(u => {
              u.userActionStatus = status
            })
        }, highlightDuration)
      }
    },
    onLimit (limit) {
      this.updateRouterPagination("limit", limit)
      this.updateRouterPagination("tablePerPage", limit)
      this.getData()
    },
    getActionTypes (selectedActionTypes) {
      const selectedSourceType = this.filters ?
        this.filters.source_type.value :
        get(this.$route.query, "source_type", sourceTypes.REPERTOIRE_FILE)
      if (selectedSourceType === sourceTypes.PAYMENT_FILE) {
        selectedActionTypes = selectedActionTypes.map(code => {
          if (code === conflictTypeCodes.RECORDING_CONFLICT)
            return statementConflictTypeCodes.STATEMENT_RECORDING_MATCHING
          if (code === conflictTypeCodes.PERFORMERS_CONFLICT)
            return statementConflictTypeCodes.STATEMENT_PERFORMER_MATCHING
          if (code === conflictTypeCodes.MAKERS_CONFLICT)
            return statementConflictTypeCodes.STATEMENT_MAKER_MATCHING
        })
      } else {
        selectedActionTypes = selectedActionTypes.map(code => {
          if (code === conflictTypeCodes.RECORDING_CONFLICT)
            return metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT
          if (code === conflictTypeCodes.PERFORMERS_CONFLICT)
            return metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT
          if (code === conflictTypeCodes.MAKERS_CONFLICT)
            return metadataConflictTypeCodes.MAKERS_METADATA_CONFLICT
        })
      }
      return selectedActionTypes
    },

    async getData () {
      if (!this.filters.show_by_file.value) {
        return
      }
      this.loading = true
      const expanded = this.expanded
      this.expanded = []
      let params = { ...this.$route.query }
      delete params.status
      params.action_types = this.selectedActionTypes
      params.action_statuses = this.actionStatus ? [userActionStatuses.DONE] : [userActionStatuses.IN_PROGRESS, userActionStatuses.PENDING]
      if (params.fixation_year_min && params.fixation_year_max) {
        params.fixation_year_from = params.fixation_year_min
        params.fixation_year_to = params.fixation_year_max
        delete params["fixation_year_min"]
        delete params["fixation_year_max"]
      }
      let requestFn = this.$api.repertoire.repertoireFilesList
      if (this.selectedActionTypes) {
        switch (this.filters.source_type.value) {
        case sourceTypes.PAYMENT_FILE:
          requestFn = this.$api.payments.paymentFiles
          break
        case sourceTypes.RIGHTSHOLDER_BATCH:
          requestFn = this.$api.rightsholders.rightsholderBatchesList
          break
        case sourceTypes.REPERTOIRE_FILE:
          requestFn = this.$api.repertoire.repertoireFilesList
          if (params.fuzzySearch) {
            params.filename = params.fuzzySearch
          }
          delete params.fuzzySearch
          if (this.filters.status.value) {
            params.action_statuses = [this.filters.status.value]
          } else {
            delete params.action_statuses
          }
        }

        let response = await requestFn({
          ...params,
        })
        this.responseAdapter(response, expanded)
        this.$nextTick().then(() => {
          this.setPanesHeight()
        })
      }
    },
    setConflictDetails (conflict) {
      if (conflict) {
        if (this.filters.source_type.value !== sourceTypes.PAYMENT_FILE) {
          this.conflict = {
            "id": conflict.id,
            "type": this.getActionTypes(this.filters.action_types.value)[0],
          }
        } else {
          this.conflict = conflict
        }
      } else {
        this.conflict = null
      }
    },
    getExpandedSource () {
      const sourceIndex = this.expanded.findIndex(el => el)
      if (sourceIndex >= 0) {
        return this.data[sourceIndex]
      }
    },
    tableId () {
      const sourceType = get(this.$route.query, "source_type", sourceTypes.REPERTOIRE_FILE)
      const actionType =  get(this.$route.query, "action_types", conflictTypeCodes.RECORDING_CONFLICT)
      switch (sourceType) {
      case sourceTypes.REPERTOIRE_FILE: {
        if (actionType[0] === conflictTypeCodes.RECORDING_CONFLICT) return userPreferenceNames.REPERTOIRE_RECORDING_FILTER
        if (actionType[0] === conflictTypeCodes.PERFORMERS_CONFLICT) return userPreferenceNames.REPERTOIRE_PERFORMER_FILTER
        if (actionType[0] === conflictTypeCodes.MAKERS_CONFLICT) return userPreferenceNames.REPERTOIRE_MAKER_FILTER
      } break
      case sourceTypes.RIGHTSHOLDER_BATCH: {
        if (actionType[0] === conflictTypeCodes.PERFORMERS_CONFLICT) return userPreferenceNames.RIGHTSHOLDER_PERFORMER_FILTER
        if (actionType[0] === conflictTypeCodes.MAKERS_CONFLICT) return userPreferenceNames.RIGHTSHOLDER_MAKER_FILTER
      } break
      case sourceTypes.PAYMENT_FILE: {
        if (actionType[0] === conflictTypeCodes.RECORDING_CONFLICT) return userPreferenceNames.PAYMENT_RECORDING_FILTER
        if (actionType[0] === conflictTypeCodes.PERFORMERS_CONFLICT) return userPreferenceNames.PAYMENT_PERFORMER_FILTER
        if (actionType[0] === conflictTypeCodes.MAKERS_CONFLICT) return userPreferenceNames.PAYMENT_MAKER_FILTER
      } break
      }
    },
    parseDate
  },
  data () {
    return {
      count: 0,
      data: [],
      expanded: [],
      page: Number(get(this.$route.query, "page", 1)),
      metadata_types: metadataConflictTypeCodes,
      statements_types: statementConflictTypeCodes,
      conflict_types: conflictTypeCodes,
      inlineRowStatus,
      userPreferenceNames,
      statusOptions: [
        {
          text: capitalize(this.$t("all")),
          value: null
        },
        {
          text: capitalize(this.$t("pending")),
          value: userActionStatuses.PENDING
        },
        {
          text: capitalize(this.$t("resolved")),
          value: userActionStatuses.DONE
        }
      ],
      sourceTypes,
      limit: Number(getPerPageItemDefaults(this.$route)),
      loading: true,
      loadingConflicts: true,
      actionStatus: get(this.$route.query, "action_statuses", userActionStatuses.PENDING) === userActionStatuses.DONE,
      showSecondarySources: Boolean(get(this.$route.query, "filter_by_uploader_type", false)),
      conflict: null,
      typeOptions: [
        {
          text: capitalize(this.$t("recording")),
          value: metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT
        }, {
          text: capitalize(this.$t("performer")),
          value: metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT
        }, {
          text: capitalize(this.$t("maker")),
          value: metadataConflictTypeCodes.MAKERS_METADATA_CONFLICT
        },
      ],
      instantFilters: false,
      activeTable:"",
      filters: {
        source_type: { 
          value: get(this.$route.query, "source_type", sourceTypes.REPERTOIRE_FILE), 
          defaultValue: sourceTypes.REPERTOIRE_FILE, hidden: true
        },
        action_types: {
          value: [get(this.$route.query, "action_types", conflictTypeCodes.RECORDING_CONFLICT)],
          defaultValue: [conflictTypeCodes.RECORDING_CONFLICT], hidden: true
        },
        filter_by_uploader_type: { value: true, defaultValue: true, hidden: true },
        show_by_file: { value: Boolean(get(this.$route.query, "show_by_file", false)), defaultValue: false, hidden: true },
        undo_available: { value: false, defaultValue: false, hidden: true },
        repertoireFile: { value: null, defaultValue: null, hidden: true },
        //column filters
        fuzzySearch: { value: null, defaultValue: null, label: capitalize(this.$t("search")), type: filterTypes.TEXT },
        status: { value: userActionStatuses.PENDING, defaultValue:  userActionStatuses.PENDING, label: capitalize(this.$t("status")),
          type: filterTypes.SELECT, options: [], hidden: false, disable: false },
        //rep-rec
        title: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("title")), hidden: false },
        artist: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("artist")), hidden: false },
        isrc: { value: null, defaultValue: null, type: filterTypes.TEXT, label: this.$t("isrc"), hidden: false },
        type: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("type")), 
          options: [
            {
              value: "SR",
              text: capitalize(this.$t("sound_recording"))
            },
            {
              value: "MV",
              text: capitalize(this.$t("music_video"))
            }
          ], 
          emptyValue: capitalize(this.$t("all")), hidden: false },
        performers: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("performers")), hidden: false },
        firstMakers: { value: null, defaultValue: null, label: capitalize(this.$t("first_makers")), type: filterTypes.TEXT, hidden: false },
        fixationYear: { value: null, defaultValue: null, label: capitalize(this.$t("fixation_year")), type: filterTypes.YEAR_RANGE, noWatch: true, hidden: false },
        fixationYearFrom: { value: null, defaultValue: null, hidden: true },
        fixationYearTo: { value: null, defaultValue: null, hidden: true },
        fixationCountry: { value: null, defaultValue: null, label: capitalize(this.$t("fixation_country")), type: filterTypes.TEXT, hidden: false },
        firstMakerNationalities: { value: null, defaultValue: null, label: capitalize(this.$t("first_maker_nationality")), type: filterTypes.TEXT, hidden: false },
        releaseNames: { value: null, defaultValue: null, label: capitalize(this.$t("releases")), type: filterTypes.TEXT, hidden: false },
        owners: { value: null, defaultValue: null, label: capitalize(this.$t("owners")), type: filterTypes.TEXT, hidden: false },
        //rep-per
        name: { value: null, defaultValue: null, label: capitalize(this.$t("name")), type: filterTypes.TEXT, hidden: false },
        ipn: { value: null, defaultValue: null, label: capitalize(this.$t("ipn")), type: filterTypes.TEXT, hidden: false },
        dateRange: { value: null, defaultValue: null, type: filterTypes.DATE_RANGE, format: "YYYY-MM-DD", label: capitalize(this.$t("date_of_birth")), noWatch: true, hidden: false },
        gender: { value: null, defaultValue: null, label: capitalize(this.$t("gender")), type: filterTypes.SELECT, 
          options: [
            {
              value: "M",
              text: capitalize(this.$t("male"))
            },
            {
              value: "F",
              text: capitalize(this.$t("female"))
            }
          ], hidden: false },
        nationalities: { value: null, defaultValue: null, label: capitalize(this.$t("nationalities")), type: filterTypes.TEXT, hidden: false },
        collective: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("collective")), emptyValue: capitalize(this.$t("all")), options: [], hidden: false },   
        //pay-rec
        sisterSociety: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("sister_society")), emptyValue: capitalize(this.$t("all")), options: [], hidden: false },
        grossAmount: { value: null, defaultValue: null, type: filterTypes.NUMBER_RANGE, label: capitalize(this.$t("gross_amount")), min: 0, max: 1000000000, step: 1, hidden: false },
        paymentStatus: { value: null, defaultValue: null, label: capitalize(this.$t("payment_status")), type: filterTypes.TEXT, hidden: false },
      },
      selectedActionTypes: this.getActionTypes([get(this.$route.query, "action_types", conflictTypeCodes.RECORDING_CONFLICT)]),
      solvedManually: [],
      repertoireFile: null,
    }
  },
  watch: {
    page (value) {
      this.onPagination(value)
    },
    dateRange (newVal) {
      this.filters.dateRange.value = newVal
    },
    actionStatus (value) {
      this.filters.action_statuses.value = value
        ? [userActionStatuses.DONE]
        : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
    },
    showSecondarySources (value) {
      this.filters.filter_by_uploader_type.value = value
        ? "false"
        : "true"
    },
    "filters.show_by_file.value": function (value) {
      this.filters.fuzzySearch.value = ""
      this.filters.undo_available.value = false
      this.changeFilter("show_by_file", value, true)
      this.updateRouterPagination("show_by_file", value)
      this.limit = Number(getPerPageItemDefaults(this.$route))
      this.changeFilter("limit", this.limit)
      this.conflict = null
    },
    "filters.source_type.value": function (value) {
      this.resetFiltersOnActionChange()
      this.changeFilter("source_type", value, true)
      if (value === sourceTypes.RIGHTSHOLDER_BATCH && this.filters.action_types.value.includes(conflictTypeCodes.RECORDING_CONFLICT)) {
        this.filters.action_types.value = [conflictTypeCodes.PERFORMERS_CONFLICT]
        this.changeFilter("action_types", conflictTypeCodes.PERFORMERS_CONFLICT)
      }
      this.changeFilter("limit", this.limit)
      this.selectedActionTypes = this.getActionTypes(this.filters.action_types.value)
      this.activeTable = this.tableId()
    },
    "filters.action_types.value": function (newVal, oldVal) {
      if (newVal[0] !== oldVal[0]) {
        this.resetFiltersOnActionChange()
      }
      this.selectedActionTypes = this.getActionTypes(newVal)
      this.changeFilter("action_types", newVal, true)
      this.changeFilter("limit", this.limit)
      this.activeTable = this.tableId()
    },
    "filters.repertoireFile.value": function (newValue) {
      if (! newValue) {
        this.repertoireFile = null
      } else {
        this.$api.repertoire.repertoireFile(this.filters.repertoireFile.value).then(response => {
          this.repertoireFile = response.data
        })
      }
    },
    "filters.undo_available.value": function (value) {
      this.filters.status.disable = value
      this.changeFilter("undo_available", value, true)
    }
  },
  mounted () {
    this.selectedActionTypes = this.getActionTypes(
      [get(this.$route.query, "action_types", conflictTypeCodes.RECORDING_CONFLICT)]
    )
    if (this.filters.fixationYearFrom.value && this.filters.fixationYearTo.value) {
      this.filters.fixationYear.value = [
        this.filters.fixationYearFrom.value.toString(),
        this.filters.fixationYearTo.value.toString()
      ]
    }
    this.getData()
  },
  created () {
    this.activeTable = this.tableId()
  },
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
    ...mapState("user", ["permissions"]),
    ...mapState("consts", ["memberCollectives"]),
    ...mapGetters("user", ["hasMakers", "hasPerformers", "isInternal"]),

    memberCollectiveOptions () {
      return this.memberCollectives.map(x => ({
        text: x.name,
        value: x.uuid,
      }))
    },
    tableHeadings () {
      return {
        "actionStatus": "",
        "inProgress": "",
        "landingObject.title": capitalize(this.$t("title")),
        "landingObject.name": capitalize(this.$t("name")),
        "landingObject.artist": capitalize(this.$t("artist")),
        "landingObject.isrc": upperCase(this.$t("isrc")),
        "title": capitalize(this.$t("title")),
        "artist": capitalize(this.$t("artist")),
        "isrc": upperCase(this.$t("isrc")),
        "name": capitalize(this.$t("name")),
        "ipn": upperCase(this.$t("ipn")),
        "landingObject.sources": capitalize(this.$t("source")),
        "landingObject.amountGross": capitalize(this.$t("amount_gross")),
        "landingObject.sisterSociety": capitalize(this.$t("sister_society")),
        "landingObject.statementsFile": capitalize(this.$t("source")),
        "status": capitalize(this.$t("status")),
        "fixationCountry": capitalize(this.$t("fixation_country")),
        "fixationYear": capitalize(this.$t("fixation_year")),
        "firstMakerNationality": this.$t("fm_nationality"),
        "firstMakers": capitalize(this.$t("first_maker")),
        "releases": capitalize(this.$t("releases")),
        "owners": capitalize(this.$t("owners")),
        "performers": capitalize(this.$t("performers")),
        "nationalities": capitalize(this.$t("nationalities")),
        "aliases": capitalize(this.$t("aliases")),
        "collective": capitalize(this.$t("collective")),
        "dateOfBirth": "DOB",
        "gender": capitalize(this.$t("gender")),
        "type": capitalize(this.$t("type")),
        "matchedStatus": capitalize(this.$t("status")),
        "actions": "",
      }
    },
    tableColumns () {
      if (this.filters.source_type.value !== sourceTypes.PAYMENT_FILE) {
        if (this.filters.action_types.value.includes(conflictTypeCodes.RECORDING_CONFLICT)) {
          let columns = [
            "actionStatus",
            "title",
            "artist",
            "isrc",
            "fixationCountry",
            "fixationYear",
            "firstMakerNationality",
            "firstMakers",
            "releases",
            "type",
            "owners",
            "performers",
            "matchedStatus",
          ]
          if (this.filters.undo_available.value) {
            columns.push("actions")
          }
          return columns
        } else if (this.filters.action_types.value.includes(conflictTypeCodes.MAKERS_CONFLICT)) {
          let columns = [
            "actionStatus",
            "name",
            "aliases",
            "nationalities",
            "collective",
            "matchedStatus",
          ]
          if (this.filters.undo_available.value) {
            columns.push("actions")
          }
          return columns
        } else {
          let columns = [
            "actionStatus",
            "name",
            "ipn",
            "dateOfBirth",
            "gender",
            "aliases",
            "nationalities",
            "collective",
            "matchedStatus",
          ]
          if ( this.filters.undo_available.value) {
            columns.push("actions")
          }
          return columns
        }
      } else {
        let columns = [
          "inProgress"
        ]
        if (this.filters.action_types.value.includes(conflictTypeCodes.RECORDING_CONFLICT)) {
          columns.push(
            "landingObject.title",
            "landingObject.artist",
            "landingObject.isrc",
          )
        } else if (this.filters.action_types.value.includes(conflictTypeCodes.PERFORMERS_METADATA_CONFLICT)) {
          columns.push(
            "landingObject.name",
            "landingObject.ipn"
          )
        } else {
          columns.push(
            "landingObject.name",
          )
        }
        columns.push(
          "landingObject.amountGross",
          "landingObject.sisterSociety",
          "landingObject.statementsFile",
          "landingObject.sources",
          "status"
        )
        return columns
      }
    },
    filtersWithoutSearch () {
      let filters = JSON.parse(JSON.stringify(this.filters))
      filters.fuzzySearch.value = ""
      return filters
    },
    bottomPaneVisible () {
      return this.conflict
    },
  }
}
</script>

<style lang="scss">
.container {
  .row {
    &.filters {
      .custom-control {
        margin-top: 0.5rem;
      }
    }
  }
}

.radio-buttons{
  .btn-outline-secondary {
    min-width: 108px;
    color: $primary !important;
    border-color: $gray-light !important;
    &:hover {
      color: #FFFFFF !important;
      background-color: $gray-light !important;
    }
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active {
    color: #FFFFFF !important;
    background-color: $gray-light !important;
  }
}

.source-search {
  fieldset {
    max-width: 300px;
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.file-list-wrapper {
  height: 100%;
}

.file-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  max-height: 95%;
}

.first-level {
  .collapse-button {
    margin-right: 5px;
  }

  .pending-matches-count-orange {
    color: var(--orange);
  }

  .resolved-matches-count-green {
    color: var(--green);
  }
}
</style>
