<template>
  <div class="mt-3">
    <modal
      id="rightsholders-link-modal"
      :customTitle="$t('merge_performers') | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered
      hide-footer
    >
      <RightsHoldersLinkForm :selectedObjects="rightsHolderMergeCart" @linked="onLinked"/>
    </modal>
    <modal
        id="comment-modal"
        :customTitle="$t('make_comment') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="lg"
        centered
        hide-footer
    >
      <b-form-group
        :label="`${permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canWrite ? $t('effective_date') : $t('suggested_effective_date')}` | capitalize"
      >
        {{ effectiveDate }}
      </b-form-group>
      <ValidationObserver ref="commentForm" v-slot="{ handleSubmit }">
        <ValidationProvider vid="comment" name="comment" rules="required" v-slot="{ errors }">
          <b-form @submit.prevent="handleSubmit(applyAdjustment)">
            <b-form-group
              :label="`${$t('comment')}` | capitalize"
              class="mt-4"
            >
              <b-form-textarea
                size="lg"
                class="mb-4"
                debounce="500"
                name="comment"
                :placeholder="$t('make_comment') | capitalize"
                v-model="adjustmentComment"
                rows="5"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
            <b-row class="modal-footer mt-4">
              <b-button @click="$bvModal.hide('comment-modal')" class="btn-outline-dark">
                {{ $t("cancel") | capitalize }}
              </b-button>
              <b-button type="submit" class="btn-outline-red">
                {{ saveButtonCaption }}
              </b-button>
            </b-row>
          </b-form>
        </ValidationProvider>
      </ValidationObserver>
    </modal>
    <b-modal 
      id="sourcesModal" 
      ref="sourcesModal" 
      modal-class="custom-modal info"
      size="xl"
      centered
      hide-footer>
      <template #modal-title>
        <h4 class="d-flex align-items-center mb-0">
          <fa-icon :icon="['fas', 'exclamation-circle']" class="icon-blue mr-2"/>
          {{ $t('sources') | capitalize }}
        </h4>
      </template>
      <SourcesList
        :sources="currentSources"
      />
    </b-modal>
    <div>
      <div class="d-flex">
        <h4>{{ $t("reconciled_lineup") | startcase }}</h4>
        <merge-button
          v-if="permissions.actions.canMergeRightsHolders"
          :selectedObjects="rightsHolderMergeCart"
          :merge="toggleMerge"
          :remove="setMergeCart"
          :cart="'rightsHolderMergeCart'"
          :buttonText="$t('merge_performers') | capitalize"
          badgeDisplayField="name"
          class="merge-button"
        ></merge-button>
        <div class="ml-auto d-flex" v-if="viewMode !== conflictModes.VIEW">
          <b-button
            class="ml-2 btn-outline-dark"
            v-if="editModePerformers && viewMode === conflictModes.EDIT"
            @click="cancelEdit()"
            >Cancel
          </b-button>
          <b-button
            v-if="editModePerformers"
            @click="updatePerformers(categoryTypes)"
            class="ml-2 btn-outline-red">
            {{ saveButtonCaption }}
          </b-button>
          <div
            v-else-if="
              !addingFeaturedPerformer &&
              !addingNonFeaturedPerformer &&
              viewMode === conflictModes.EDIT
            "
          >
            <b-button v-if="canEditPerformers()" class="ml-2 btn-outline-red" @click="editPerformers()">
            {{ $t("edit") | startcase }}
            </b-button>
          </div>
          <div class="d-flex">
            <date-field
              v-if="viewMode === conflictModes.ADJUSTMENT"
              label-cols="4"
              :group-label="$t('effective_date') | capitalize"
              name="effectiveDate"
              v-model="effectiveDate"
              class="ml-4"
              :format="$config.DATE_FORMAT"
            />
            <b-button
              v-if="viewMode === conflictModes.ADJUSTMENT"
              :disabled="!effectiveDate"
              @click="solveAdjustment()"
              class="btn-outline-red ml-2"
            >
              {{ permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canWrite ? $t('apply_effective_date') : $t('suggest_effective_date') | startcase }}
            </b-button>
          </div>
        </div>
      </div>
      <div>
        <div v-if="!categoryTypes.length || categoryTypes.includes('F')">
          <div class="d-flex mt-2 mb-2 align-items-center">
            <h4 class="m-0 font-weight-bold">
              <router-link v-if="hasLineupConflict(this.recordingPerformers, 'F')"
                class="text-red mr-2"
                :to="{
                  name: 'user-actions',
                  params: { type: actionsTabs.LINEUP_CONFLICTS },
                  query: { fuzzy_search: resoundId, financial_interest: false },
                }"
              >
                <fa-icon
                  :icon="['fas', 'exclamation-circle']"
                  :title="conflictReason('F')"
                  class="text-red"
                  size="md"
                />
              </router-link>
              {{ $t("featured") | startcase }}
              <span v-if="!editModePerformers" class="text-small">
              (<b>{{ numberOfPerformers() === null ? "-" : numberOfPerformers() }}</b>)
              </span>
            </h4>
            <div
              class="w-5 ml-2"
              v-if="editModePerformers && updateDict.lineupCategories"
            >
              <b-input
                size="sm"
                type="number"
                :min="updateDict.lineupCategories.find((c) => c.type === 'F').lineup.length"
                v-model="
                  updateDict.lineupCategories.find((c) => c.type === 'F')
                    .numberOfPerformers
                "
                number
              />
            </div>
            <div class="ml-2 mt-1" v-if="!editModePerformers">
              <span v-if="defaultNumberOfPerformers()">
                <fa-icon
                  class="icon green"
                  size="sm"
                  :icon="['fas', 'check-circle']"
                />
                {{ $t("default_split") | startcase }}
              </span>
              <fa-icon
                size="lg"
                :icon="['fa', 'lock']"
                v-if="featuredLineUpComplete()"
              />
            </div>
            <div class="ml-auto">
              <div
                v-if="
                  viewMode === conflictModes.EDIT &&
                  !editModePerformers &&
                  !addingFeaturedPerformer &&
                  !addingNonFeaturedPerformer
                "
              >
                <b-button
                  v-if="
                    permissions.pages[Pages.RepertoireRecordingList].canWrite &&
                    !featuredLineUpComplete() &&
                    lineupPerformers().length < numberOfPerformers()
                  "
                  @click="addPerformer(true)"
                  class="btn-outline-red"
                >
                  {{ $t("add_featured_performer") | startcase }} ({{
                    numberOfPerformers() - lineupPerformers().length
                  }})
                </b-button>
              </div>
            </div>
          </div>
          <PerformersList
            v-if="lineupPerformers().length"
            :performerData="lineupPerformers()"
            :recordingId="recordingUUID"
            :resoundId="resoundId"
            :categoryTypes="categoryTypes"
            :viewMode="viewMode"
            :categoryText="$t('featured') | startcase"
            :isLineUp="true"
            category="F"
            :checkboxReq="true"
          />
          <div v-else class="mb-4">
            <fa-icon size="lg" icon="exclamation-circle" class="mr-2" />
            <span v-if="isCategoryAvailable()">{{ $t("no_performers_featured") }}</span>
            <span v-else>{{ $t("no_access_featured") }}</span>
          </div>
        </div>
        <div
          class="p-2 d-flex align-items-center input-wrapper"
          v-if="addingFeaturedPerformer"
        >
          <div class="w-50">
            <select-search
              :list-endpoint="this.$api.rightsholders.rightsholdersList"
              :label="$t('performer') | capitalize"
              :placeholder="$t('search_performer') | capitalize"
              search-name="search"
              :exclude="
                this.recordingPerformers[this.recordingUUID].lineupCategories.flatMap((c) =>
                  c.lineup.map((l) => l.id)
                )
              "
              exclude-name="id"
              :params="{ type: 'performer' }"
              text-field="name"
              value-field="resoundId"
              v-model="selectedPerformer"
            />
          </div>
          <div class="w-40 pl-5">
            <b-form-group
              label-cols="auto"
              :label="$t('role') | capitalize"
              class="mr-3 mb-0"
            >
              <b-form-input v-model="performerRole" />
            </b-form-group>
          </div>
          <div class="w-10 text-right">
            <fa-icon
              @click="createPerformer()"
              :icon="['fas', 'check']"
              class="mr-2 clickable"
            />
            <fa-icon
              @click="cancelAddPerformer()"
              :icon="['fas', 'times']"
              class="clickable"
            />
          </div>
        </div>

        <div v-if="!categoryTypes.length || categoryTypes.includes('N')">
          <div class="d-flex mt-2 mb-2 align-items-center">
            <h4 class="m-0 font-weight-bold">
              <router-link v-if="hasLineupConflict(this.recordingPerformers, 'N')"
                class="text-red mr-2"
                :to="{
                  name: 'user-actions',
                  params: { type: actionsTabs.LINEUP_CONFLICTS },
                  query: { fuzzy_search: resoundId, financial_interest: false  },
                }"
              >
                <fa-icon
                  :icon="['fas', 'exclamation-circle']"
                  :title="conflictReason('N')"
                  class="text-red"
                  size="md"
                />
              </router-link>
              {{ $t("non_featured") | startcase }}
              <span v-if="!editModePerformers" class="text-small">
                (<b>{{ numberOfPerformers(false) === null ? "-" : numberOfPerformers(false) }}</b>)
              </span>
            </h4>
            <div
              class="w-5 ml-2"
              v-if="editModePerformers && updateDict.lineupCategories"
            >
              <b-input
                size="sm"
                type="number"
                :min="updateDict.lineupCategories.find((c) => c.type === 'N').lineup.length"
                v-model="
                  updateDict.lineupCategories.find((c) => c.type === 'N')
                    .numberOfPerformers
                "
                number
              />
            </div>
            <div class="ml-2 mt-1" v-if="!editModePerformers">
              <span v-if="defaultNumberOfPerformers(false)">
                <fa-icon
                  class="icon green"
                  size="sm"
                  :icon="['fas', 'check-circle']"
                />
                {{ $t("default_split") | startcase }}
              </span>
              <fa-icon
                size="lg"
                :icon="['fa', 'lock']"
                v-if="nonFeaturedLineUpComplete()"
              />
            </div>
            <div class="ml-auto">
              <div
                v-if="
                  viewMode === conflictModes.EDIT &&
                  !editModePerformers &&
                  !addingFeaturedPerformer &&
                  !addingNonFeaturedPerformer
                "
              >
                <b-button
                  v-if="
                    permissions.pages[Pages.RepertoireRecordingList].canWrite &&
                    !nonFeaturedLineUpComplete() &&
                    lineupPerformers(false).length < numberOfPerformers(false)
                  "
                  @click="addPerformer(false)"
                  class="btn-outline-red"                  
                >
                  {{ $t("add_non_featured_performer") | startcase }} ({{
                    numberOfPerformers(false) - lineupPerformers(false).length
                  }})
                </b-button>
              </div>
            </div>
          </div>
          <PerformersList
            v-if="lineupPerformers(false).length"
            :performerData="lineupPerformers(false)"
            :recordingId="recordingUUID"
            :resoundId="resoundId"
            :viewMode="viewMode"
            :categoryTypes="categoryTypes"
            :isLineUp="true"
            category="N"
            :checkboxReq="true"
          />
          <div v-else class="mb-4">
            <fa-icon size="lg" icon="exclamation-circle" class="mr-2" />
            <span v-if="isCategoryAvailable(false)">{{ $t("no_performers_non_featured") }}</span>
            <span v-else>{{ $t("no_access_non_featured") }}</span>
          </div>
        </div>

        <div
          class="p-2 d-flex align-items-center input-wrapper"
          v-if="addingNonFeaturedPerformer"
        >
          <div class="w-50">
            <select-search
              :list-endpoint="this.$api.rightsholders.rightsholdersList"
              :label="$t('performer') | capitalize"
              :placeholder="$t('search_performer') | capitalize"
              search-name="search"
              :exclude="
                this.recordingPerformers[this.recordingUUID].lineupCategories.flatMap((c) =>
                  c.lineup.map((l) => l.id)
                )
              "
              exclude-name="id"
              :params="{ type: 'performer' }"
              text-field="name"
              value-field="resoundId"
              v-model="selectedPerformer"
            />
          </div>
          <div class="w-40 pl-5">
            <b-form-group
              label-cols="auto"
              :label="$t('role') | capitalize"
              class="mr-3 mb-0"
            >
              <b-form-input v-model="performerRole" />
            </b-form-group>
          </div>
          <div class="w-10 text-right">
            <fa-icon
              @click="createPerformer()"
              :icon="['fas', 'check']"
              class="mr-2 clickable"
            />
            <fa-icon
              @click="cancelAddPerformer()"
              :icon="['fas', 'times']"
              class="clickable"
            />
          </div>
        </div>
      </div>
      <template v-if="showSource">
        <div class="mt-4" v-if="this.filteredPerformerSources.length">
          <div>
            <div class="row">
              <h4 class="col-4">{{ $t("consolidated_metadata") | startcase }}</h4>
            </div>
            <performer-sources
              :sources="filteredPerformerSources"
              :category-types="categoryTypes"
              :recordingId="recordingUUID"
              @showRepTracks="repTracks"
            />
          </div>
          <div>
            <div class="row mt-3">
              <h4 class="col-4">{{ $t("source_metadata") | startcase }}</h4>
              <b-form-checkbox class="col-8 mt-1 text-right" switch v-model="oldSourceMetadata">{{ $t("show_source_metadata") | capitalize }}</b-form-checkbox>
            </div>
            <div class="card-wrapper" v-if="oldSourceMetadata">
              <b-card
                class="source mb-0"
                v-for="(source, sourceIndex) in this.filteredRepTracks"
                :key="`source-${sourceIndex}`"
                >
                <div>
                  <b-row align-v="center" class="m-0 source-row">
                    <b-col cols="6">
                      <b>{{ source.memberCollectiveName }}</b
                      ><br />
                      <span>{{ source.proprietaryId }}</span>
                    </b-col>
                    <b-col cols="2">
                      <span
                        :class="{
                          'bg-light-red': showHighlights && hasGenreConflict(source)
                        }"
                      >{{ $t("genre") | startcase }}: {{ getSourceGenres(source).join(", ") }}</span>
                    </b-col>
                    <b-col cols="2">
                      <span
                        :class="{
                          'bg-light-red': showHighlights && source.lineupInfo.numberOfFeaturedPerformers != null && differentCategoryPerformersCount(true),
                        }"
                        >{{
                          source.lineupInfo.numberOfFeaturedPerformers != null ? source.lineupInfo.numberOfFeaturedPerformers : "-"
                        }}
                        {{ $t("featured") | startcase }}</span
                      >
                      /
                      <span
                        :class="{
                          'bg-light-red': showHighlights && source.lineupInfo.numberOfNonFeaturedPerformers != null && differentCategoryPerformersCount(false),
                        }"
                        >{{
                          source.lineupInfo.numberOfNonFeaturedPerformers != null ? source.lineupInfo.numberOfNonFeaturedPerformers : "-"
                        }}
                        {{ $t("non_featured") | startcase }}</span
                      >
                    </b-col>
                    <b-col cols="1">
                      <b-button
                        v-if="showAddButton(source)"
                        @click="addAll(source.landingPerformers)"
                        class="btn-outline-red"
                      >
                        {{ $t("add_all") | capitalize }}
                      </b-button>
                    </b-col>
                    <b-col cols="1">
                      <div class="d-flex justify-content-end">
                        <span class="blue" v-if="source.repertoireFiles.length">
                          {{ source.repertoireFiles.length }}
                          <fa-icon
                            :title="$t('expand_all_sources') | capitalize"
                            @click="showSources(source.repertoireFiles)"
                            class="blue clickable ml-2 more-values-icon"
                            size="sm"
                            :icon="['fas', 'plus-circle']"
                          >
                          </fa-icon>
                        </span>
                        <collapse-button
                          class="ml-3"
                          :toggle="'row-' + source.id"
                          :expanded="expanded[sourceIndex]"
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <b-collapse
                    :id="'row-' + source.id"
                    v-model="expanded[sourceIndex]"
                  >
                    <PerformersList
                      v-if="source.landingPerformers.length"
                      :performerData="sortPerformers(source.landingPerformers)"
                      :recordingId="recordingUUID"
                      :resoundId="resoundId"
                      :isLineUp="false"
                      :categoryTypes="categoryTypes"
                      :viewMode="viewMode"
                      :sourceIndex="sourceIndex"
                      :selectedElements="selectedElements"
                      :checkboxReq="false"
                    />
                  </b-collapse>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { actionsTabs, conflictModes, fileStatuses, genreTypes, landingRecordingReconciliationStatuses, lineupConflictReasons } from "@/constants"
import { capitalize, chain } from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import { Pages } from "@/utils/pages"
import PerformerSources from "@/pages/Repertoire/Recordings/RecordingDetail/Performers/PerformerSources"
import SourcesList from "@/components/SourcesList"
import { getSubGenre } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import moment from "moment"
import parseDate from "@/utils/date-parser"

export default {
  name: "Performers",
  components: {
    ValidationProvider,
    ValidationObserver,
    PerformersList: () => import("./PerformersList"),
    RightsHoldersLinkForm: () => import("@/pages/Rightsholders/RightsHoldersLinkForm"),
    PerformerSources,
    SourcesList,
  },
  props: {
    title: {
      type: String,
    },
    recordingUUID: {
      type: String,
    },
    resoundId: {
      type: String,
    },
    genreType: {
      type: String,
    },
    subGenre: {
      type: String,
    },
    viewMode: {
      type: String,
      default: conflictModes.EDIT,
    },
    categoryTypes: {
      type: Array,
      default: () => [],
    },
    solvedManually: {
      type: Array,
      required: false,
    },
    showSource: {
      type: Boolean,
      default: true,
    },
    showHighlights: {
      type: Boolean,
      default: false,
    },
    onDone: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState("repertoire", [
      "editModePerformers",
      "performersUpdate",
      "recordingData",
      "recordingPerformers",
      "selecting",
    ]),
    ...mapState("mergers", ["rightsHolderMergeCart"]),
    ...mapState("user", ["permissions"]),
    filteredPerformerSources: function () {
      if (this.recordingPerformers[this.recordingUUID] && this.recordingPerformers[this.recordingUUID].sources && this.recordingPerformers[this.recordingUUID].sources.length) {
        return this.recordingPerformers[this.recordingUUID].sources
      } else {
        return []
      }
    },
    filteredRepTracks: function () {
      if (!this.showRepTracks && this.filteredPerformerSources.length) {
        let filteredRepTracks = []
        this.filteredPerformerSources.forEach((row) => {
          if (row.fromPerformerSource && row.reconciliationStatus === landingRecordingReconciliationStatuses.READY) filteredRepTracks.push(row)
        })
        return filteredRepTracks
      } else {
        return this.filteredPerformerSources
      }
    },
    updateDict: {
      get () {
        return this.performersUpdate
      },
      set (value) {
        this.setPerformersUpdate(value)
      },
    },
    saveButtonCaption: function () {
      return this.viewMode === conflictModes.SOLVE
        ? capitalize(this.$t("solve"))
        : capitalize(this.$t("save"))
    },
    getMCSources () {
      function reduceSenders (acc, value) {
        acc.has(value.id) ? acc.get(value.id).push(value.sender) : acc.set(value.id, [value.sender])
        return acc
      }

      return this.recordingPerformers[this.recordingUUID] ? chain(this.recordingPerformers[this.recordingUUID].sources)
        .map((s) => s.landingPerformers)
        .flatten()
        .map(function (lp) { return  { id: lp.performer ? lp.performer.id : null , sender: lp.sender }})
        .reduce(reduceSenders, new Map())
        .value() : new Map()
    },
  },
  watch: {
    recordingPerformers: function () {
      if (this.viewMode === conflictModes.SOLVE) {
        this.editPerformers()
      } else if (this.viewMode === conflictModes.VIEW) {
        this.resetUpdateDict()
      }
    },
    effectiveDate (value) {
      this.$emit("effectiveDate", value)
    }
  },
  methods: {
    parseDate,
    ...mapMutations("repertoire", [
      "toggleSelecting",
      "restartComments",
      "setEditModePerformers",
      "setPerformersUpdate",
      "setActivateMerge",
    ]),
    ...mapMutations("alert", ["success", "error"]),
    ...mapMutations("mergers", ["setMergeCart", "resetMergeCart"]),
    ...mapActions("repertoire", [
      "getRecordingPerformers",
    ]),
    ...mapActions("userActions", ["getUserActionsSummary"]),
    canEditPerformers () {
      return this.permissions.pages[Pages.RepertoireRecordingDetail].canWrite
    },
    repTracks (value) {
      this.showRepTracks = value
    },
    showSources (sources) {
      this.currentSources = sources
      this.$refs.sourcesModal.show()
    },
    applyAdjustment () {
      this.$bvModal.hide("comment-modal")
      this.$emit(
        "solveAdjustment",
        this.adjustmentComment,
        this.$api.actions.resolveOwnershipAdjustmentAction,
        this.$api.comments.lineupNegativeAdjustmentsCommentsCreate
      )
    },
    solveAdjustment () {
      this.$bvModal.show("comment-modal")
    },
    onLinked () {
      this.getRecordingPerformers(this.recordingUUID).then(() => {
        this.setEditModePerformers(false)
        this.resetMergeCart()
      })
    },
    toggleMerge () {
      this.$bvModal.show("rightsholders-link-modal")
    },
    updatePerformers (categoryTypes) {
      if (this.genreType) {
        this.updateDict.genreType = this.genreType
        this.updateDict.subGenre = this.subGenre
      }
      if (categoryTypes && categoryTypes.length) {
        this.updateDict.lineupCategories = this.updateDict.lineupCategories.filter(
          (c) => categoryTypes.includes(c.type)
        )
      }
      if (this.solvedManually) {
        this.solvedManually.push(this.recordingUUID)
      }
      this.$api.rightsholders
        .rightsholdersUpdate(this.recordingUUID, this.updateDict)
        .then(() => {
          this.success(capitalize(this.$t("saved_changes")))
          this.cancelEdit()
          this.cancelAddPerformer()
          this.getRecordingPerformers(this.recordingUUID)
          this.onDone()
          this.getUserActionsSummary({ partial: true })
        })
        .catch((error) => {
          this.error(
            `${capitalize(this.$t("error_occurred"))}: ${JSON.stringify(
              error.response.data
            )}`
          )
        })
    },
    createPerformer () {
      this.resetUpdateDict()
      this.formatLineUp()
      const type = this.addingFeaturedPerformer ? "F" : "N"
      let category = this.updateDict.lineupCategories.find(
        (l) => l.type === type
      )
      category.lineup.push({
        role: this.performerRole,
        id: this.selectedPerformer.id,
      })
      this.updatePerformers()
    },
    cancelAddPerformer () {
      this.performerRole = ""
      this.selectedPerformer = null
      this.addingFeaturedPerformer = false
      this.addingNonFeaturedPerformer = false
    },
    addPerformer (featured = false) {
      if (featured) {
        this.addingFeaturedPerformer = true
      } else {
        this.addingNonFeaturedPerformer = true
      }
    },
    cancelEdit () {
      this.setEditModePerformers(false)
      this.resetUpdateDict()
      this.resetMergeCart("rightsHolderMergeCart")
    },
    resetUpdateDict () {
      this.updateDict = {
        lineupCategories: JSON.parse(
          JSON.stringify(this.recordingPerformers[this.recordingUUID] ? this.recordingPerformers[this.recordingUUID].lineupCategories : {})
        ), // Deep cloning
      }
    },
    featuredLineUpComplete () {
      if (this.editModePerformers) {
        return this.numberOfPerformers() === this.lineupPerformers().length
      } else {
        return this.recordingPerformers[this.recordingUUID] && this.recordingPerformers[this.recordingUUID].lineupCategories
          ? this.recordingPerformers[this.recordingUUID].lineupCategories.find(
            (c) => c.type === "F"
          ).lineupComplete
          : false
      }
    },
    nonFeaturedLineUpComplete () {
      if (this.editModePerformers) {
        return (
          this.numberOfPerformers(false) === this.lineupPerformers(false).length
        )
      } else {
        return this.recordingPerformers[this.recordingUUID] && this.recordingPerformers[this.recordingUUID].lineupCategories
          ? this.recordingPerformers[this.recordingUUID].lineupCategories.find(
            (c) => c.type === "N"
          ).lineupComplete
          : false
      }
    },
    defaultNumberOfPerformers (featured = true) {
      const value = featured ? "F" : "N"
      return this.recordingPerformers[this.recordingUUID] && this.recordingPerformers[this.recordingUUID].lineupCategories
        ? this.recordingPerformers[this.recordingUUID].lineupCategories.find(
          (c) => c.type === value
        ).defaultSplit
        : false
    },
    numberOfPerformers (featured = true) {
      const value = featured ? "F" : "N"
      const sourceData = this.editModePerformers
        ? this.updateDict
        : this.recordingPerformers[this.recordingUUID]
      return sourceData && sourceData.lineupCategories
        ? sourceData.lineupCategories.find(
          (c) => c.type === value
        ).numberOfPerformers
        : 0
    },
    formatLineUp () {
      const type = this.addingFeaturedPerformer ? "F" : "N"
      let category = this.updateDict.lineupCategories.find(
        (l) => l.type === type
      )
      category.lineup = category.lineup.map((l) => {
        return { role: l.role, id: l.id }
      })
    },
    fullSourceInLineup (landingPerformers, featured = null) {
      if (!this.categoryTypes.length) return
      let categories = this.updateDict.lineupCategories
      if (featured !== null) {
        categories = categories.filter(c => c.type === (featured ? "F" : "N"))
        landingPerformers = landingPerformers.filter(lp => lp.featured === featured)
      }
      return landingPerformers.every(
        (lp) =>
          !lp.performer || categories.flatMap(c => c.lineup).some((p) => lp.performer.id === p.id)
      )
    },
    showAddButton (source) {
      return (
        this.editModePerformers &&
        this.viewMode === conflictModes.SOLVE &&
        !this.fullSourceInLineup(source.landingPerformers) &&
        (
          (
            !this.fullSourceInLineup(source.landingPerformers, true) &&
            this.categoryTypes.includes("F") &&
            this.numberOfPerformers() &&
            this.lineupPerformers().length < this.numberOfPerformers()
          ) ||
          (
            !this.fullSourceInLineup(source.landingPerformers, false) &&
            this.categoryTypes.includes("N") &&
            this.numberOfPerformers(false) &&
            this.lineupPerformers(false).length < this.numberOfPerformers(false)
          )
        )
      )
    },
    sortPerformers (performers) {
      return chain(performers).sortBy("name").sortBy("featured").value()
    },
    editPerformers () {
      this.setEditModePerformers(true)
      this.resetUpdateDict()
      this.resetMergeCart("rightsHolderMergeCart")
    },
    commonSourcePerformers (categoryTypes) {
      const sources = this.recordingPerformers[this.recordingUUID].sources
      let commonPerformers = sources[0].landingPerformers.filter((lp) => {
        return lp.performer
      })
      if (categoryTypes.length === 1) {
        const isFeatured = categoryTypes.includes("F")
        commonPerformers.filter(lp => lp.featured === isFeatured)
      }
      if (sources.length > 1) {
        const otherSourcesLandingPerformers = sources
          .slice(1, sources.length)
          .map((s) => s.landingPerformers)
        commonPerformers = commonPerformers.filter((cp) =>
          otherSourcesLandingPerformers.every((oslp) =>
            oslp.some(
              (lp) =>
                lp.performer &&
                cp.performer &&
                lp.performer.id === cp.performer.id
            )
          )
        )
      }
      return commonPerformers
    },
    isCategoryAvailable (featured = true) {
      if (!this.recordingPerformers[this.recordingUUID] || !this.recordingPerformers[this.recordingUUID].lineupCategories) {
        return false
      }
      const value = featured ? "F" : "N"
      let category = this.recordingPerformers[this.recordingUUID].lineupCategories.find(c => c.type === value)
      return category.isAvailable
    },
    lineupPerformers (featured = true) {
      const value = featured ? "F" : "N"
      const sourceData = this.editModePerformers
        ? this.updateDict
        : this.recordingPerformers[this.recordingUUID]
      if (!sourceData || !sourceData.lineupCategories) {
        return []
      }
      let lineup = sourceData.lineupCategories.find((c) => c.type === value)
        .lineup.filter(p => p.mergedToId == null)

      if (
        this.categoryTypes.length &&
        this.initialLoad &&
        this.viewMode === conflictModes.SOLVE
      ) {
        const commonPerformers = this.commonSourcePerformers(this.categoryTypes)
        commonPerformers.forEach((cp) => {
          if (!lineup.some((p) => p.id === cp.performer.id)) {
            lineup.push({ ...cp.performer, role: cp.role })
          }
        })
      }
      this.initialLoad = false
      let s = this.getMCSources
      return s ? lineup.map(function (o) {o.sender = s.get(o.id); return o}) : []
    },
    addAll (landingPerformers) {
      let errors = {}
      this.categoryTypes.forEach(categoryType => {
        let actualCategory = this.updateDict.lineupCategories.find(
          (c) => c.type === categoryType
        )
        landingPerformers.forEach((lp) => {
          let featured = categoryType === "F"
          if (
            lp.performer && lp.featured === featured &&
            !actualCategory.lineup.some((p) => p.id === lp.performer.id)
          ) {
            if (
              this.numberOfPerformers(lp.featured) &&
              this.lineupPerformers(lp.featured).length < this.numberOfPerformers(lp.featured)
            ) {
              actualCategory.lineup.push({ ...lp.performer, role: lp.role })
            } else if (!errors[categoryType]) {
              let categoryText = featured ? this.$t("featured") : this.$t("non_featured")
              errors[categoryType] = `${capitalize(this.$t("not_enough_number_of_performers"))} ${this.$t("in")} ${categoryText} ${this.$t("category")}`
            }
          }
        })
      })
      if (Object.values(errors).length) {
        this.error(Object.values(errors).join("\n"))
      }
    },
    differentCategoryPerformersCount (featured = true) {
      const counts = this.recordingPerformers[this.recordingUUID].sources.map(
        (source) =>
          featured ? source.lineupInfo.numberOfFeaturedPerformers : source.lineupInfo.numberOfNonFeaturedPerformers
      ).filter((c) => c !== undefined && c !== null)
      return !counts.every((count) => count === counts[0])
    },
    specialToggleSelecting () {
      // deactivate all selecting for each source
      if (this.selecting) {
        this.recordingPerformers[this.recordingUUID].sources.forEach((v, i) =>
          this.restartComments({
            data: `recordingPerformers.sources[${i}].landingPerformers`,
            toggleShowDetails: false,
          })
        )
      }
      this.toggleSelecting()
    },
    getDate (date) {
      return moment(date).format("YYYY-MM-DD")
    },
    getSourceGenres (source) {
      return source.genres.map((genreData) => {
        let genre = genreData.genre
        if (genreData.subGenre) {
          let subGenre = getSubGenre(genreData.subGenre) || genreData.subGenre
          genre = `${genre} (${subGenre})`
        }
        return genre
      })
    },
    hasGenreConflict (source) {
      const genreType = this.recordingData.genreType ? this.recordingData.genreType : this.genreType
      if (genreType) {
        for (let genreInfo of source.genres) {
          if (genreInfo.genreType && genreInfo.genreType !== genreType) {
            return true
          }
          if ([genreTypes.CLASSICAL, genreTypes.JAZZ].includes(this.recordingData.genreType)) {
            const subGenre = this.recordingData.subGenre ? this.recordingData.subGenre : this.subGenre
            if (genreInfo.subGenre && genreInfo.subGenre !== subGenre) {
              return true
            }
          }
        }
      } else {
        // No genre in the recording means there was genre conflict in the first reconciliation
        return true
      }
      return false
    },
    hasLineupConflict (recordingPerformers, category = "F") {
      let hasConflict = false

      if (recordingPerformers && recordingPerformers[this.recordingUUID]) {
        const performersData = recordingPerformers[this.recordingUUID] 
        const lineupCategories = performersData.lineupCategories

        for (let lineupCategory of lineupCategories) {
          if (lineupCategory.hasConflict && lineupCategory.type === category) {
            hasConflict = true
          }
        }
      }

      return hasConflict
    },
    conflictReason (category) {
      let categoryData = this.recordingPerformers[this.recordingUUID].lineupCategories.find(c => c.type === category)
      let reason = categoryData ? categoryData.conflictReason : null
      if (reason) {
        switch (reason) {
        case lineupConflictReasons.INCONSISTENT_GENRES:
          return capitalize(this.$t("inconsistent_genres"))
        case lineupConflictReasons.INCONSISTENT_NUMBER_OF_PERFORMERS:
          return capitalize(this.$t("inconsistent_number_of_performers"))
        case lineupConflictReasons.EXTRA_PERFORMERS:
          return capitalize(this.$t("more_performers_than_specified"))
        case lineupConflictReasons.DUPLICATE_PERFORMER:
          return capitalize(this.$t("performer_in_both_categories"))
        }
      } else {
        return capitalize(this.$t("lineup_conflict"))
      }
    },
  },
  data () {
    return {
      actionsTabs,
      adjustmentComment: null,
      currentSources: [],
      effectiveDate: null,
      performersListNonFeature: 0,
      performerRole: "",
      addingFeaturedPerformer: false,
      addingNonFeaturedPerformer: false,
      selectedPerformer: null,
      selectedElements: 0,
      expanded: [],
      initialLoad: true,
      fileStatuses,
      conflictModes,
      oldSourceMetadata: false,
      showRepTracks: false,
      Pages,
    }
  },
  mounted () {
    this.getRecordingPerformers(this.recordingUUID)
    this.setEditModePerformers(this.viewMode === conflictModes.SOLVE)
  },
  destroyed () {
    if (this.selecting){
      this.specialToggleSelecting()
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  border: 1px solid $gray;
  border-radius: 4px;
}

.source {
  .card-body {
    padding: 0;
  }

  .source-row {
    padding: 15px;
  }
}

.source:nth-child(even) {
  background-color: $row-shade;
}

.source-row:hover {
  background-color: $table-hover;
}

.green {
  color: $green-darker;
}

.merge-button {
  padding-left: 15px;
}

.text-small {
  font-size: 16px;
}

.input-wrapper {
  background-color: $table-header;
  border-radius: 4px;
}
</style>
