import { actionsTabs, filterTypes, getPerPageItemDefaults } from "@/constants"
import  { capitalize, get, isArray, isEmpty, snakeCase } from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import _ from "lodash"
import moment from "moment"

const commentMixin = {
  data () {
    return {
      newComment: "",
      commenting: false,
      editing: null,
      editCommentData: {},
    }
  },
  methods: {
    makeComment (modalHide=null, extraContent= null) {
      let data = { comment: this.newComment }
      if (extraContent) {
        data = { ...data, ...extraContent }
      }
      this.createEndpoint(this.uuid, data)
        .then(() => {
          this.success(this.$t("comment_created_successfully"))
          if (!this.hideAddCommentBtn) {
            this.$root.$emit("bv::toggle::collapse", "commentInput")
          }
          if (modalHide) {
            this.$bvModal.hide(modalHide)
          }
          this.newComment = ""
          this.commenting = false
          this.page = 1
          this.getComments()
        })
        .catch((error) => {
          let msg = `Error creating comment. ${error.response.data.detail}`
          this.error(msg)
        })
    },
    onDelete (id) {
      this.$bvModal.show(`confirmation-modal-${id}`)
    },
    onConfirmDelete (commentId) {
      this.deleteEndpoint(this.uuid, commentId)
        .then(() => {
          this.success(this.$t("comment_deleted_successfully"))
          this.getComments()
        })
        .catch((error) => {
          let msg = `Error deleting comment. ${error.response.data.detail}`
          this.error(msg)
        })
    },
    editComment (comment, data) {
      this.editEndpoint(this.uuid, comment.id, data)
        .then(() => {
          this.getComments()
          this.cancelEditMode()
          this.success(this.$t("comment_edited_successfully"))
        })
        .catch((error) => {
          let msg = `Error editing comment. ${error.response.data.detail}`
          this.error(msg)
        })
    },
    cancelEditMode () {
      this.editing = null
      this.editCommentData = {}
    },
    editMode (comment, attrs) {
      this.editing = comment.id
      this.editCommentData = attrs
    },
  }
}

const cartMixin = {
  methods: {
    toggleCart () {
      this.viewingCart = !this.viewingCart
      this.getData()
    },
    clearCart (askData=true) {
      this.viewingCart = false
      this.selectedData = []
      this.resetState()
      if (askData) {
        this.getData()
      }
    },
    onLinked () {
      this.viewingCart = false
      this.selectedData = []
      this.getData()
      this.resetState()
    },
    filterCartItems (type) {
      let typeSummary = {}
      let statusSummary = {}

      // recording
      if (type === "recording") {
        typeSummary = {
          mv: 0,
          sr: 0,
          all: 0
        }

        this.selectedData.forEach(sr => {
          typeSummary[sr.type.toLowerCase()] += 1
        })

        typeSummary["all"] = this.selectedData.length
      }

      // rightsholders
      if (type === "rightsholder") {
        let status = this.rightsHolderMergeCart.map(r => {
          return r.membership ? r.membership.map(m => m.status) : []
        })

        statusSummary = status.reduce((status, count) => {
          status[count] = (status[count] || 0) + 1
          return status
        }, {})

        typeSummary = {
          maker: 0,
          performer: 0,
          all: 0
        }

        this.rightsHolderMergeCart.forEach(rh => {
          typeSummary[rh.type] += 1
        })

        typeSummary["all"] = this.rightsHolderMergeCart.length
      }

      // response
      let responseData = {
        results: JSON.parse(JSON.stringify(this.selectedData)),
        count: this.selectedData.length,
        summary: type === "recording" ? typeSummary : statusSummary
      }

      if (type === "rightsholder"){
        responseData["typeSummary"] = typeSummary
      }

      let cartData = new Promise((res, rej) => {
        res({
          data: responseData,
        })
        rej("There is nothing in the merge cart")
      })
      return cartData
    },
    removeSelected (id) {
      this.selectedData.splice(this.selectedData.map(r => r.id).indexOf(id), 1)
    },
    onLoaded ({ data }) {
      this.recomputeAllSelected()
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    onLoading () {
      this.loading = true
    },
    selectPage (tableRef) {
      if (!this.options.allSelected) {
        this.selectedData.push(
          ...tableRef.data
            .filter(d => !this.selectedData.map(r => r.id).includes(d.id) && !d.recordingId)
            .map(d => this.selectedRowData(d))
        )
      } else {
        tableRef.data.forEach(d => this.removeSelected(d.id))
      }
    },
    recomputeAllSelected (tableRef, newVal = false) {
      let selectedData = newVal || this.selectedData
      if (tableRef && tableRef.data.length) {
        this.options.allSelected = tableRef.data.every(d => {
          return selectedData.map(r => r.id).includes(d.id)
        })
      } else {
        this.options.allSelected = false
      }
    },
  }
}

const listRouteMixin = {
  props: {
    "defaultFilters": {
      type: Object
    }
  },
  data: function () {
    return {
      sortOptions: [],
      updateRouterOnPagination: true,
      filtersPending: false,
      instantFilters: false,
      skipFirstRequest: false,
      skipNextRequest: false,
    }
  },
  methods: {
    onSorted (data) {
      if (Array.isArray(data)) {
        let values = data.reduce((current, next) => {

          let already_in = current.map(d => d.startsWith("-") ? d.substring(0) : d)

          if (!already_in.includes(next.column.startsWith("-") ? next.column.substring(0) : next.column)) {
            current.push(next.ascending ? next.column : `-${next.column}`)
          }
          return current
        }, [])
        this.sortOptions = values
        this.updateRouterPagination("sorting", values)
      } else if (typeof(data) === "object") {
        this.updateRouterPagination("sorting", data.ascending ? data.column : `-${data.column}`)
      } else {
        this.updateRouterPagination("sorting", null)
      }
    },
    updateTableSortIcons (RLServerTableRef, tabulatorTable = false) {
      let order = this.calculateOrder()
      if (order.length) {
        if (tabulatorTable) {
          RLServerTableRef.$parent.setSorting(order)
        }
        else {
          RLServerTableRef.orderBy = order[0]
          if (order.length > 1) {
            order.splice(0, 1)
            RLServerTableRef.userMultiSorting = {
              [RLServerTableRef.orderBy.column]: this.calculateOrder(true)
            }
          }
        }
      }
    },
    calculateOrder () {
      let order = get(this.$route.query, "sorting", [])

      if (!Array.isArray(order)) {
        order = [order]
      }

      return order.map(o => { if (o) return {
        column: o.startsWith("-") ? o.substring(1) : o,
        ascending: !o.startsWith("-")
      }})
    },
    cleanFilterField (field, value=null) {
      const rangeFiltersFromTo = [
        filterTypes.YEAR_RANGE,
        filterTypes.DATE_RANGE,
        filterTypes.TIME_RANGE,
      ]
      const rangeFiltersSingleParam = [
        filterTypes.NUMBER_RANGE,
        filterTypes.NUMBER_RANGE_NO_SLIDER,
      ]

      const field_snake_case = snakeCase(field)
      const isFromField = field.endsWith("From")
      const isToField = field.endsWith("To")
      let cleanField = {}
      let filterType = this.filters[field]?.type
      let parentFilter = null

      // Check if parent filter needs to be set
      if (filterType === undefined) {
        // Case when we do a field 'fieldName' and 2 hidden fields `fieldNameFrom` & `fieldNameTo`
        // without specifying the filter.type for hidden fields
        if (isFromField) parentFilter = this.filters[field.replace(/From$/, "")]
        if (isToField) parentFilter = this.filters[field.replace(/To$/, "")]
        if (parentFilter) filterType = parentFilter.type
        else cleanField = { [field_snake_case]: value }
      }

      if (rangeFiltersFromTo.includes(filterType)) {
        const empty_value = parentFilter === null ? "" : null
        if (isFromField) cleanField[field_snake_case.replace(/from$/, "min")] = value || empty_value
        if (isToField) cleanField[field_snake_case.replace(/to$/, "max")] = value || empty_value
      } else if (rangeFiltersSingleParam.includes(filterType)) {
        // Case when min and max have the same name in query params (?track_val=1&track_val=10)
        value = (value && value.length === 2) ? value : [null, null]
        cleanField[`${field_snake_case}_min`] = value[0]
        cleanField[`${field_snake_case}_max`] = value[1]
      }
      cleanField = _.isEmpty(cleanField) ? { [field_snake_case]: value } : cleanField || ""
      return cleanField

    },
    changeFilter (field, value, instant=false) {
      this.filtersPending = true
      const { query } = this.$route
      if (typeof this.queryParams === "undefined") {
        this.queryParams = { ...query }
      }
      this.queryParams = { ...this.queryParams, ...this.cleanFilterField(field, value) }
      if (this.instantFilters || instant) {
        this.$nextTick(() => {
          this.applyFilters()
        })
      }
    },
    applyFilters () {
      if (typeof this.queryParams === "undefined") {
        const { query } = this.$route
        this.queryParams = { ...query }
      }
      const { name } = this.$route
      for(let item in this.queryParams) {
        if (this.queryParams[item] === null) {
          if (!this.defaultFilters || typeof this.defaultFilters[item] === "undefined")
            delete this.queryParams[item]
          else
            this.queryParams[item] = ""
        }
      }
      this.skipNextRequest = false
      this.filtersPending = false
      this.$router.replace({
        name,
        query: { ...this.queryParams, page: 1 }
      }).catch(() => {})
    },
    updateRouterPagination (fieldName, value) {
      const { name, params, query } = this.$route
      // ideally we shouldn't pass context in params
      // this hack is done here to prevent duplicate api requests
      // should be revisited when a proper fix is found
      if (this.updateRouterOnPagination) {
        this.$router.push({ name, params, query: { ...query, ...{ [fieldName]: value } } })
          .catch(() => {})
      } else {
        if (typeof this.queryParams !== "undefined") {
          fieldName = snakeCase(fieldName)
          this.queryParams = { ...this.queryParams, [fieldName]: value }
        }
        this.getData()
      }
    },
    onPagination (page) {
      this.page = page
      this.updateRouterPagination("page", page)
    },
    onLimit (limit, reference) {
      this.limit = limit
      if (this.queryParams) {
        this.queryParams.limit = limit
      }
      this.updateRouterPagination("limit", limit)
      if (reference) {
        this.$refs[reference].setLimit(limit)
      }
    },
    onLoading () {
      this.loading = true
    },
    resetFilters (applyDefaults = false) {
      this.page = Number(get(this.$route.query, "page", 1))
      this.limit = Number(get(this.$route.query, "limit", getPerPageItemDefaults(this.$route)))
      if(this.$data.filters) {
        Object.keys(this.$data.filters).forEach(key => {
          let value = this.filters[key].defaultValue
          if (!applyDefaults) {
            let cleanKey = Object.keys(this.cleanFilterField(key, value))
            cleanKey = cleanKey.length === 1 ? cleanKey[0] : cleanKey
            if (isArray(cleanKey)) {
              value = cleanKey.map(k => get(this.$route.query, k, null))
              value = value.every(e => e === null) ? this.$data.filters[key].defaultValue : value
            } else {
              value = get(this.$route.query, cleanKey, this.$data.filters[key].defaultValue)
            }

            if (Array.isArray(this.$data.filters[key].defaultValue)) {
              value = !Array.isArray(value) ? [value] : value
            } else if (this.$data.filters.type === Date) {
              value = moment(value, this.$data.filters.format, true).isValid() ? value : ""
            } else if (value && typeof value === "boolean") {
              value = Boolean(value)
            } else if (value && typeof this.$data.filters[key].defaultValue === "boolean") {
              value = value === "true"
            } else if (value && !isNaN(value) && this.$data.filters[key].dataType !== String) {
              value = Number(value)
            } else if (this.filters[key].type === filterTypes.SELECT_SEARCH) {
              this.$data.filters[key].searchValue = value
              return
            }
          }
          this.$data.filters[key].value = value
        })
      }
      if (!this.skipNextRequest) {
        this.skipNextRequest = false
        this.$nextTick(() => {
          this.applyFilters()
          this.filtersPending = false
        })
      } else {
        if (!isEmpty(this.$route.query)) {
          this.$router.replace(this.$route.name, null)
        }
      }
    },
    registerWatchers () {
      if(this.$data.filters){
        Object.keys(this.$data.filters).filter(f => !this.$data.filters[f].noWatch).forEach(key => this.$watch("filters." + key + ".value", function (newValue) {
          this.changeFilter(key, newValue)
        }))
      }
    }
  },
  watch: {
    $route (to) {
      if (to.name === this.$route.name && to.params.sidebar) {
        this.skipNextRequest = this.skipFirstRequest
        this.resetFilters()
      }
      if (!this.skipNextRequest) {
        this.getData()
      }
    },
  },
  mounted () {
    this.skipNextRequest = this.skipFirstRequest

    this.registerWatchers()
    if (this.$data.filters) {
      this.resetFilters()
    }
    if (this.defaultFilters) {
      for (let [key, val] of Object.entries(this.defaultFilters)) {
        if (this.filters[key] && typeof this.$route.query[key] === "undefined") {
          this.filters[key].value = val
        }
      }
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    rightsholderOptions () {
      const makerOption = { text: capitalize(this.$t("maker")), value: "maker" }
      const performerOption = { text: capitalize(this.$t("performer")), value: "performer" }
      if (this.user.isInternal) {
        return [makerOption, performerOption]
      } else {
        let result = []
        if (this.user.selectedMember.isPerformers) {
          result.push(performerOption)
        }
        if (this.user.selectedMember.isMakers) {
          result.push(makerOption)
        }
        return result
      }
    },
    filtersApplied () {
      if(this.$data.filters) {
        for (const key of Object.keys(this.$data.filters)) {
          let cleanField = Object.keys(this.cleanFilterField(key, this.filters[key].value))[0]
          let value = get(this.$route.query, cleanField, this.$data.filters[key].defaultValue)
          if (Array.isArray(this.$data.filters[key].defaultValue)) {

            value = !Array.isArray(value) ? [value] : value
            if (value.length !== this.filters[key].defaultValue.length) {
              return true
            }
            for (let i = 0; i < value.length; i++) {
              if (value[i] !== this.filters[key].defaultValue[i]) {
                return true
              }
            }
            continue
          } else if (this.$data.filters.type === Date) {
            value = moment(value, this.$data.filters.format, true).isValid() ? value : ""
          } else if (value && typeof value === "boolean") {
            value = Boolean(value)
          } else if (value && !isNaN(value)) {
            value = Number(value)
          }
          if (value !== this.filters[key].defaultValue) {
            return true
          }
        }
      }
      return false
    },
  }
}

const unclaimedMixin = {
  data () {
    return {
      viewUnclaimedFirstTime: true,
      unclaimedPreviousFilters: null,
    }
  },
  watch: {
    "filters.rightsholderType.value": function (newVal, oldVal) {
      if (newVal) {
        if(!oldVal){
          this.unclaimedPreviousFilters = JSON.parse(
            JSON.stringify(this.filters)
          ) // Deep cloning
        }

        this.filters.tariff.value = "1A"
        this.filters.year.value = new Date().getFullYear()
        this.filters.country.value = "CA"
      } else {
        this.filters.tariff.value = this.unclaimedPreviousFilters.tariff.value
        this.filters.year.value = this.unclaimedPreviousFilters.year.value
        this.filters.country.value = null
      }
    }
  },
  computed: {
    ...mapState("consts", ["countries"]),
    viewUnclaimed () {
      return Boolean(this.filters.rightsholderType.value)
    },
    countryOptions () {
      return [{ text: capitalize(this.$t("canada")), value: "CA" }].concat(this.countries.map(c => { return { text: c.text, value: c.alpha2 }}))
    },
    exportUnclaimed () {
      return Boolean(this.filters.rightsholderType.value)
    },
    exportUnclaimedTitle () {
      return capitalize(this.$t("export_unclaimed_recordings"))
    },
    exportTitle () {
      if (this.exportUnclaimed) {
        return this.exportUnclaimedTitle
      } else {
        return this.exportBaseTitle
      }
    },
  },
  mounted () {
    if (!this.countries.length) {
      this.getCountries({ with_sister_agreement: 1 })
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries"]),
  },
}

const datePickerMixin = {
  data () {
    return {
      filters: {
        userTimezone: { value: "", defaultValue: null, hidden: true },
        to: { value: "", defaultValue:"", type: Date, format: "YYYY-MM-DD", hidden: true },
        from: { value: "", defaultValue:"", type: Date, format: "YYYY-MM-DD", hidden: true },
      }
    }
  },
  computed: {
    dateRange: {
      get () {
        return [
          this.filters.from.value ? moment(this.filters.from.value, this.$data.filters.from.format, true).toDate() : "",
          this.filters.to.value ? moment(this.filters.to.value, this.$data.filters.to.format, true).toDate() : ""
        ]
      },
      set (val) {
        this.filters.from.value = this.parseDate(val[0])
        this.filters.to.value = this.parseDate(val[1])
        if (val[0] || val[1]) {
          this.filters.userTimezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone
        } else {
          this.filters.userTimezone.value = this.filters.userTimezone.defaultValue
        }
      }
    }
  },
  methods: {
    parseDate (val) {
      return val ? moment(val).format(this.$config.ISO_DATE_FORMAT) : ""
    }
  },
  mounted () {
    if (this.filters.from.defaultValue || this.filters.to.defaultValue) {
      this.filters.userTimezone.defaultValue = Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }
}

const solveAdjustmentMixin = {
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    solveAdjustment (comment, resolveEndpoint, commentEndpoint) {
      let data = {
        comment: comment,
      }
      let effectiveDate = moment(this.selectedEffectiveDate, this.$config.DATE_FORMAT).format(this.$config.ISO_DATE_FORMAT)
      if (this.permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canWrite) {
        data["effective_date"] = effectiveDate
        resolveEndpoint(this.selectedRow.id, data)
          .then(() => {
            this.success(capitalize(this.$t("negative_adjustment_resolved")))
            this.$emit("resolved")
          })
          .catch((error) => {
            let msg = `Error resolving the negative adjustment. ${JSON.stringify(error.response.data)}`
            this.error(msg)
          })
      } else {
        data["suggested_effective_date"] = effectiveDate
        commentEndpoint(this.selectedRow.userActionsIds[0], data)
          .then(() => {
            this.success(capitalize(this.$t("negative_adjustment_suggested")))
            this.selectedEffectiveDate = null
            this.$refs.performers.effectiveDate = null
            this.$refs.comments.getComments()
          })
          .catch((error) => {
            let msg = `Error suggesting a negative adjustment. ${JSON.stringify(error.response.data)}`
            this.error(msg)
          })
      }
    },
  }
}

const advancedSearchMixin = {
  computed: {
    advancedSearchText () {
      const params = Object.values(this.advancedSearchParams)
        .filter(p => p.value)
        .map(p => `${capitalize(this.$t(p.prefix))}:${p.value}`)

      return params.length > 0 ? params.join(" ") : null
    },
    advancedSearchModel: {
      get () {
        if (this.advancedSearchText === null) {
          return this.filters.fuzzySearch.value
        } else {
          return this.advancedSearchText
        }
      },
      set (val) {
        if (this.advancedSearchText === null) {
          this.filters.fuzzySearch.value = val
        }
      },
    },
  },
  methods: {
    showAdvancedSearch () {
      this.$bvModal.show("advanced-search-modal")
    },
    advancedSearchOk () {
      const params = Object.values(this.advancedSearchParams)
        .filter(p => p.fuzzy && p.value)
        .map(p => `${p.prefix}:${p.value}`)
      this.filters.fuzzySearch.value = params.join(" ")
      Object.entries(this.advancedSearchParams)
        .filter(([, p]) => !p.fuzzy && p.value)
        .forEach(([k, p]) => {
          this.filters[k].value = p.value
        })
      this.$bvModal.hide("advanced-search-modal")
    },
    cancelAdvancedSearch () {
      for (const key in this.advancedSearchParams) {
        this.advancedSearchParams[key].value = null
      }
      this.filters.fuzzySearch.value = this.filters.fuzzySearch.defaultValue
      Object.entries(this.advancedSearchParams)
        .filter(([, p]) => !p.fuzzy)
        .forEach(([k,]) => {
          this.filters[k].value = this.filters[k].defaultValue
        })
      this.$bvModal.hide("advanced-search-modal")
    },
  },
  mounted () {
    Object.entries(this.advancedSearchParams)
      .filter(([, p]) => !p.fuzzy)
      .forEach(([k,]) => {
        this.advancedSearchParams[k].value = this.filters[k].value
        this.$watch(`filters.${k}.value`, function (newVal) {
          this.advancedSearchParams[k].value = newVal
        })
      })
  }
}

const distributionSectionMixin = {
  computed: {
    requiredFields () {
      return []
    },
    readonlyFields () {
      return []
    },
    errors () {
      return this.$refs.observer.errors
    },
    valid () {
      return this.$refs.observer.flags.valid
    }
  },
  methods: {
    setErrors (errors) {
      for (const error of errors) {
        let key = _.camelCase(error.field)
        if (typeof this.$refs[key] !== "undefined") {
          this.$refs[key].setErrors([error.message])
        }
      }
    },
    async validate () {
      return await this.$refs.observer.validate()
    }
  },
  mounted () {
    this.$watch("errors", () => {
      let fields = Object.entries(this.errors).filter(([k, v]) => v.length && !this.readonlyFields.includes(k))
      let failedOptionalFields = fields.filter(([k,]) => !this.requiredFields.includes(k)).length
      let totalFieldsCount = this.requiredFields.length + failedOptionalFields
      let failedFieldsCount = fields.length
      this.$emit(
        "validated",
        {
          valid: this.valid,
          errors: Object.fromEntries(
            Object.entries(this.errors).map(([k, v]) => [this.$refs[k].label, v])
          ),
          totalFieldsCount,
          failedFieldsCount
        }
      )
    })
  }
}

export {
  advancedSearchMixin,
  cartMixin,
  commentMixin,
  datePickerMixin,
  distributionSectionMixin,
  listRouteMixin,
  solveAdjustmentMixin,
  unclaimedMixin
}
