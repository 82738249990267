import { API } from "./utils"

export const comments = {
  recordingChallengeCommentCreate: (uuid, data) => API.post(`/user-actions/recording-challenges/${uuid}/comments/`, data),
  recordingCommentCreate: (uuid, data) => API.post(`/repertoire/recordings/${uuid}/comments/`, data),
  recordingCommentDelete: (uuid, commentUuid) => API.delete(`/repertoire/recordings/${uuid}/comments/${commentUuid}/`),
  recordingCommentEdit: (uuid,commentUuid, data) => API.patch(`/repertoire/recordings/${uuid}/comments/${commentUuid}/`, data),
  recordingCommentsList: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/comments/`, { params }),
  recordingChallengeCommentsList: (uuid, params = null) => API.get(`/user-actions/recording-challenges/${uuid}/comments/`, { params }),
  performerChallengeCommentCreate: (uuid, data) => API.post(`/user-actions/performer-challenges/${uuid}/comments/`, data),
  performerChallengeCommentsList: (uuid, params = null) => API.get(`/user-actions/performer-challenges/${uuid}/comments/`, { params }),
  makerChallengeCommentCreate: (uuid, data) => API.post(`/user-actions/maker-challenges/${uuid}/comments/`, data),
  makerChallengeCommentList: (uuid, params = null) => API.get(`/user-actions/maker-challenges/${uuid}/comments/`, { params }),
  lineupConflictCommentCreate: (uuid, data) => API.post(`/user-actions/lineup-conflicts/${uuid}/comments/`, data),
  lineupConflictCommentsList: (uuid, params = null) => API.get(`/user-actions/lineup-conflicts/${uuid}/comments/`, { params }),
  ownershipConflictCommentCreate: (uuid, data) => API.post(`/user-actions/ownership-conflicts/${uuid}/comments/`, data),
  ownershipConflictCommentsList: (uuid, params = null) => API.get(`/user-actions/ownership-conflicts/${uuid}/comments/`, { params }),
  usageChallengeCommentsList: (uuid, params = null) => API.get(`/user-actions/usage-challenges/${uuid}/comments/`, { params }),
  usageChallengeCommentCreate: (uuid, data) => API.post(`/user-actions/usage-challenges/${uuid}/comments/`, data),
  recordingEligibilityConflictCommentsList: (uuid, params = null) => API.get(`/user-actions/recording-eligibility-conflicts/${uuid}/comments/`, { params }),
  recordingEligibilityConflictCommentCreate: (uuid, data) => API.post(`/user-actions/recording-eligibility-conflicts/${uuid}/comments/`, data),
  lineupNegativeAdjustmentsCommentsCreate: (uuid, data) => API.post(`/user-actions/lineup-negative-adjustments/${uuid}/comments/`, data),
  lineupNegativeAdjustmentsCommentsDelete: (uuid, commentUuid) => API.delete(`/user-actions/lineup-negative-adjustments/${uuid}/comments/${commentUuid}/`),
  lineupNegativeAdjustmentsCommentsEdit: (uuid,commentUuid, data) => API.put(`/user-actions/lineup-negative-adjustments/${uuid}/comments/${commentUuid}/`, data),
  lineupNegativeAdjustmentsCommentsList: (uuid, params = null) => API.get(`/user-actions/lineup-negative-adjustments/${uuid}/comments/`, { params }),
  ownershipNegativeAdjustmentsCommentsCreate: (uuid, data) => API.post(`/user-actions/ownership-negative-adjustments/${uuid}/comments/`, data),
  ownershipNegativeAdjustmentsCommentsDelete: (uuid, commentUuid) => API.delete(`/user-actions/ownership-negative-adjustments/${uuid}/comments/${commentUuid}/`),
  ownershipNegativeAdjustmentsCommentsEdit: (uuid,commentUuid, data) => API.put(`/user-actions/ownership-negative-adjustments/${uuid}/comments/${commentUuid}/`, data),
  ownershipNegativeAdjustmentsCommentsList: (uuid, params = null) => API.get(`/user-actions/ownership-negative-adjustments/${uuid}/comments/`, { params }),
  usageNegativeAdjustmentsCommentsCreate: (uuid, data) => API.post(`/user-actions/usage-adjustment-conflicts/${uuid}/comments/`, data),
  usageNegativeAdjustmentsCommentsDelete: (uuid, commentUuid) => API.delete(`/user-actions/usage-adjustment-conflicts/${uuid}/comments/${commentUuid}/`),
  usageNegativeAdjustmentsCommentsEdit: (uuid,commentUuid, data) => API.put(`/user-actions/usage-adjustment-conflicts/${uuid}/comments/${commentUuid}/`, data),
  usageNegativeAdjustmentsCommentsList: (uuid, params = null) => API.get(`/user-actions/usage-adjustment-conflicts/${uuid}/comments/`, { params }),
  usageIsrcCommentCreate: (uuid, data) => API.post(`/user-actions/isrc-conflicts/${uuid}/comments/`, data),
  usageIsrcCommentList: (uuid, params = null) => API.get(`/user-actions/isrc-conflicts/${uuid}/comments/`, { params }),
  usageIpnCommentCreate: (uuid, data = null) => API.post(`/user-actions/ipn-conflicts/${uuid}/comments/`, data),
  usageIpnCommentList: (uuid, params = null) => API.get(`/user-actions/ipn-conflicts/${uuid}/comments/`, { params }),
  recordingMergeRequestCommentsList: (uuid, params = null) => API.get(`/user-actions/recording-merge-requests/${uuid}/comments/`, { params }),
  recordingMergeRequestCommentsCreate: (uuid, data = null) => API.post(`/user-actions/recording-merge-requests/${uuid}/comments/`, data),
  recordingMergeRequestCommentsEdit: (uuid, commentUuid, data = null) => API.put(`/user-actions/recording-merge-requests/${uuid}/comments/${commentUuid}/`, data),
  recordingMergeRequestCommentsDelete: (uuid, commentUuid, params = null) => API.delete(`/user-actions/recording-merge-requests/${uuid}/comments/${commentUuid}/`, { params })

}
