<template>
  <div>
    <list-layout
        :title="$t('uploads') | capitalize"
        :apply-filters="applyFilters"
        :reset-filters="resetFilters"
        :filters-pending="filtersPending"
        :filters-applied="filtersApplied"
    >
      <template v-slot:search>
        <b-form-group>
          <form-field
            :placeholder="$t('search') | capitalize"
            :enter-action="applyFilters"
            v-model="filters.search.value"
            :icon="['fas', 'search']"
          />
        </b-form-group>
        <b-button v-if="hasPerformers" class="mt-3" :href="templateUrl" variant="primary">
          <fa-icon size="lg" :icon="['fa', 'file-download']" />
          {{ $t("download_template") | startcase }}
        </b-button>
      </template>

      <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              reference="rhbatches"
              :page="page"
              :count="count"
              :loading="loading"
              v-model="limit"
              @limit="onLimit"
            />
          </div>
        </div>
      </template>

      <template v-slot:filters>
        <b-container>
          <b-row>
            <b-col>
              <b-form-group :label="$t('status') | capitalize" label-class="pb-0">
                <b-form-select
                  v-model="filters.status.value"
                  :options="
                    Object.keys(fileStatuses).map(k => {
                      return { text: capitalize(k), value: fileStatuses[k] };
                    })
                  "
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">{{
                      $t("all") | capitalize
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('mc') | startcase" label-class="pb-0">
                <b-form-select v-model="filters.member.value" :options="memberCollectiveOptions">
                  <template v-slot:first>
                    <b-form-select-option :value="null">{{
                      $t("all") | capitalize
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('select_date_range') | capitalize" label-class="pb-0">
                <date-picker
                  class="w-100"
                  v-model="dateRange"
                  range
                  :format="$config.DATE_FORMAT"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols=3>
              <b-form-group class="float-right" :label="$t('source') | capitalize" label-class="pb-0 pt-2">
                <b-form-radio-group
                  class="w-100"
                  v-model="filters.source.value"
                  name="radios-btn-outline"
                  :options="sourceOptions"
                >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <!-- Table -->
      <template v-slot:table>
        <v-server-table
          :columns="columns"
          :options="options"
          ref="rhbatches"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <template v-slot:fileName="{ row }">
            <router-link
              class="text-info"
              :to="{
                name: 'landing-rightsholders-list',
                params: {
                  batchId: row.id,
                },
                query: {
                  rh_type: row.hasMakers ? rightsholderTypes.MAKER : rightsholderTypes.PERFORMER,
                },
              }"
            >
              {{ row.fileName }}
            </router-link>
          </template>

          <!-- Upload date -->
          <template v-slot:uploadDate="{ row }">
            <date-time-cell :dateTime="row.uploadDate" />
          </template>

          <!-- Ingestion stats -->
          <template v-slot:ingestionStats="{ row }">
            <div class="d-flex" v-if="row.stats && row.stats.landingDbIngestion">
              <div
                v-for="k in landingDbIngestionKeys"
                :key="k"
                class="table-sub-class text-sm mr-3"
              >
                <div>
                  <status
                    @click="showErrors(row, false, k)"
                    :clickable="hasErrors(k, row)"
                    :active="hasErrors(k, row)"
                    v-if="getStatsDisplay[k].icon"
                    :text="String(row.stats.landingDbIngestion[k])"
                    :icon="['fas', getStatsDisplay[k].icon]"
                    :name="getStatsDisplay[k].name"
                    :color="getStatsDisplay[k].color"
                  />
                  <status v-else
                   :text="`Total: ${row.stats.landingDbIngestion[k]}`"
                  />
                </div>
              </div>
            </div>
          </template>

          <!-- Matching stats -->
          <template v-slot:matchingStats="{ row }">
            <div class="d-flex" v-if="row.stats && (row.stats.performersIngestion || row.stats.makersIngestion)">
              <div v-for="k in matchingKeys" :key="k" class="table-sub-class text-sm mr-3">
                <div>
                  <status
                    v-if="getStatsDisplay[k].icon"
                    :text="String(matchingStats(row)[k])"
                    :icon="['fas', getStatsDisplay[k].icon]"
                    :name="getStatsDisplay[k].name"
                    :color="getStatsDisplay[k].color"
                  />
                  <status v-else
                   :text="`Total: ${matchingStats(row)[k]}`"
                  />
                </div>
              </div>
            </div>
          </template>

          <!-- Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="row.status === fileStatuses.PENDING"
              :text="$t('pending')"
              name="pending"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            <status
              v-if="row.status === fileStatuses.PROCESSING"
              :text="$t('processing')"
              name="processing"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            <status
              v-if="row.status === fileStatuses.LANDED"
              :text="$t('landed')"
              name="landed"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === fileStatuses.INGESTED"
              :text="$t('ingested')"
              name="ingested"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === fileStatuses.MATCHED"
              :text="$t('matched')"
              name="matched"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === fileStatuses.ERROR"
              name="error"
              :text="$t('error')"
              :icon="['fas', 'exclamation-circle']"
              color="red"
              :active="true"
              @click="showErrors(row, true, 'error')"
              clickable
            />
          </template>
        </v-server-table>
        <modal 
          id="errorsModal" 
          ref="errorsModal" 
          :customTitle="$t('errors') | capitalize"
          modal-class="custom-modal error"
          modalType="error"
          size="xl"
          centered 
          hide-footer>
          <FilesErrorsList
            :key="currentFile | get('id')"
            :item="currentFile"
            module="repertoirefiles"
            :onlyShowFileLevelErrors="onlyShowFileLevelErrors"
            :file-errors-endpoint="$api.rightsholders.rightsholderBatchErrorsList"
            :extra-columns="['sheetName']"
          />
        </modal>
      </template>
    </list-layout>
  </div>
</template>
<script>
import { capitalize, get, mergeWith, startCase } from "lodash"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import { fileStatuses, rhBatchTypes, rightsholderTypes } from "@/constants"
import { mapGetters, mapMutations, mapState } from "vuex"
import FilesErrorsList from "@/components/FilesErrorsList"
import Pagination from "@/components/Pagination"
import { perPageItemsSm } from "@/constants"

export default {
  name: "RightsHolderBatchesList",
  mixins: [datePickerMixin, listRouteMixin],
  components: {
    Pagination,
    FilesErrorsList,
  },
  computed: {
    ...mapState("consts", ["memberCollectives"]),
    ...mapGetters("user", ["hasPerformers"]),
    memberCollectiveOptions () {
      return this.memberCollectives.map(x => ({ text: x.name, value: x.uuid }))
    },
    sourceOptions () {
      return [
        { text: `${capitalize(this.$t("all"))}`, value: null },
        {
          text: `${capitalize(this.$t("file"))}`,
          value: rhBatchTypes.CRESCENDO_FILE,
        },
        {
          text: `${capitalize(this.$t("soundexchange"))}`,
          value: rhBatchTypes.SOUNDEXCHANGE,
        },
        {
          text: `${capitalize(this.$t("batch"))}`,
          value: rhBatchTypes.ENDPOINT,
        },
      ]
    },
    getStatsDisplay () {
      const mapToDisplay = {
        errorCount: { name: "error", icon: "exclamation-circle", color: "red" },
        ingestedCount: {
          name: "ingested",
          icon: "check-circle",
          color: "green",
        },
        totalCount: { name: "total", icon: null, color: null },
        createdCount: { name: "created", icon: "plus-circle", color: "green" },
        linkedCount: { name: "linked", icon: "link", color: "light-blue" },
        manuallyCreatedCount: {
          name: "manually_created",
          icon: "hand-holding-medical",
          color: "green",
        },
        manuallyLinkedCount: {
          name: "manually_linked",
          icon: "hand-holding-magic",
          color: "light-blue",
        },
        pendingForValidationCount: {
          name: "pending_for_validation",
          icon: "question-circle",
          color: "orange",
        },
        totalUniqueCount: { name: "total_unique", icon: null, color: null },
      }
      return mapToDisplay
    },
    templateUrl () {
      return this.$config.MANDATES_TEMPLATE_URL
    },
  },
  data () {
    return {
      rightsholderTypes,
      currentFile: null,
      onlyShowFileLevelErrors: false,
      fileStatuses: fileStatuses,
      columns: [
        "fileName",
        "memberCollective",
        "source",
        "daType",
        "uploader",
        "uploadDate",
        "ingestionStats",
        "matchingStats",
        "status",
      ],
      landingDbIngestionKeys: ["totalCount", "ingestedCount", "errorCount"],
      matchingKeys: [
        "totalUniqueCount",
        "linkedCount",
        "createdCount",
        "manuallyLinkedCount",
        "manuallyCreatedCount",
        "pendingForValidationCount",
      ],
      loading: false,
      page: 1,
      count: 0,
      limit: perPageItemsSm,
      filters: {
        status: { value: null, defaultValue: null },
        search: { value: "", defaultValue: "" },
        member: { value: "", defaultValue: null },
        source: { value: null, defaultValue: null },
      },
      options: {
        sortable: ["fileName"],
        headings: {
          fileName: startCase(this.$t("filename")),
          memberCollective: startCase(this.$t("mc")),
          source: startCase(this.$t("source")),
          daType: this.$t("da_type"),
          uploader: startCase(this.$t("upload_by")),
          uploadDate: startCase(this.$t("upload_date")),
          ingestionStats: startCase(this.$t("ingestion_stats")),
          matchingStats: startCase(this.$t("matching_stats")),
          status: startCase(this.$t("status")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          componentData.pageNumber = this.page
          return this.$api.rightsholders.rightsholderBatchesList(queryParams)
        },
      },
    }
  },
  methods: {
    ...mapMutations("alert", ["error"]),
    matchingStats (row) {
      let matchingStats = this.matchingKeys.reduce((obj,key)=> (obj[key]=0, obj),{})
      if (row.stats.performersIngestion) {
        matchingStats = mergeWith(matchingStats, row.stats.performersIngestion, (a, b) => a + b)
      }
      if (row.stats.makersIngestion) {
        matchingStats = mergeWith(matchingStats, row.stats.makersIngestion, (a, b) => a + b)
      }
      return matchingStats
    },
    onLimit (limit, reference) {
      this.limit = limit
      this.updateRouterPagination("limit", limit)
      this.$refs[reference].setLimit(limit)
    },
    showErrors (row, onlyShowFileLevelErrors = false, key) {
      if (onlyShowFileLevelErrors || this.hasErrors(key, row)) {
        this.currentFile = row
        this.onlyShowFileLevelErrors = onlyShowFileLevelErrors
        this.$bvModal.show("errorsModal")
      }
    },
    getData () {
      this.$refs.rhbatches.getData().catch(error => {
        if (error.response.status === 400) {
          this.error(error.response.data[0])
        }
      })
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    hasErrors (key, row) {
      return key.includes("error") && row.stats.landingDbIngestion[key] > 0
    },
    capitalize,
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.rhbatches.$children[0])
  }
}
</script>
<style lang="scss">
.download-button {
  border: 1px solid $light;
  color: $gray-darker;
  font-size: 1rem;
}

.input-size,
.mx-input-wrapper input {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  height: calc(1.7em + 0.75rem + 2px);
}
</style>
