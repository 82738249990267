<template>
  <div>
    <b-table
      id="general-details"
      :items="this.selectedRow ? [this.selectedRow] : []"
      :fields="fields"
      fixed
      small
    >
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>
      <template v-slot:cell(resoundId)="row">
        <database-link :type="linkTypes.RECORDING" :item-id="row.item.id">{{ row.item.resoundId }}</database-link>
      </template>
    </b-table>
    <performers
      ref="performers"
      :recordingUUID="selectedRow.id"
      :view-mode="status.includes(userActionStatuses.DONE) ? conflictModes.VIEW : conflictModes.ADJUSTMENT"
      :key="selectedRow.id"
      @effectiveDate="onEffectiveDate"
      @solveAdjustment="solveAdjustment"
    />

    <!-- PAYMENTS -->
    <list-layout class="mt-3" :isChild="true" :title="$t('payments') | startcase">
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              :page="page.payments"
              :count="count.payments"
              :loading="loading.payments"
              reference="payments"
              v-model="limit.payments"
              @limit="onLimitPayments"
              :key="selectedRow.id"
            />
          </div>
        </div>
      </template>
      <template v-slot:filters>
        <b-row align-h="end">
          <b-col cols="3">
            <b-form-group class="m-0">
              <b-form-checkbox v-model="onlyAffectedPayments" switch>
                {{ $t("only_affected_rights_holders") | capitalize }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group class="m-0">
              <b-form-checkbox v-model="filterByEffectiveDate" switch>
                {{ $t("affected_payments_by_effective_date") | capitalize }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-slot:table>
        <v-server-table
          ref="payments"
          :columns="columns.payments"
          :options="options"
          @loaded="onLoadedPayments"
          @loading="onLoading('payments')"
          @pagination="onPaginationPayments"
          :key="selectedRow.id"
        >
          <template v-slot:distributionPeriod="{row}">
            {{ parseDate(row.distributionPeriodStart, $config.DATE_FORMAT) + " - " + parseDate(row.distributionPeriodEnd, $config.DATE_FORMAT) }}
          </template>
          <template v-slot:distributionDate="{row}">
            <span>
              {{ parseDate(row.distributionDate, $config.DATE_FORMAT) }}
            </span>
          </template>
        </v-server-table>
      </template>
      <h3 class="text-red">{{ $t("comments") | startcase }}</h3>
      <comments-table
        ref="comments"
        :createEndpoint="this.$api.comments.lineupNegativeAdjustmentsCommentsCreate"
        :listEndpoint="this.$api.comments.lineupNegativeAdjustmentsCommentsList"
        :columns="columns.comments"
        :headings="headings"
        :uuid="this.selectedRow && this.selectedRow.userActionsIds ? this.selectedRow.userActionsIds[0] : null"
        :editing=false
        :deleting=false
        :header="false"
        createModalId="new-comment-modal"
      />
    </list-layout>
  </div>
</template>

<script>
import { activityTypes, conflictModes, linkTypes, perPageItemsSm, rightsholderTypes, userActionStatuses } from "@/constants"
import { capitalize, startCase } from "lodash"
import CommentsTable from "@/components/CommentsTable"
import DatabaseLink from "@/components/DatabaseLink"
import Pagination from "@/components/Pagination"
import Performers from "@/pages/Repertoire/Recordings/RecordingDetail/Performers/Performers"
import { mapMutations } from "vuex"
import moment from "moment"
import parseDate from "@/utils/date-parser"
import { solveAdjustmentMixin } from "@/utils/mixins"

export default {
  name: "NegativeAdjustmentLineupDetail",
  mixins: [solveAdjustmentMixin],
  components: {
    DatabaseLink,
    CommentsTable,
    Pagination,
    Performers,
  },
  methods : {
    ...mapMutations("alert", ["success", "error"]),
    parseDate,
    onEffectiveDate (value) {
      this.selectedEffectiveDate = value
      this.$refs.payments.getData()
    },
    onLoading (ref) {
      this.loading[ref] = true
    },
    onLoadedPayments ({ data }) {
      this.count.payments = data.count
      this.loading.payments = false
    },
    onLimitPayments (limit) {
      this.limit.payments = limit
      this.$refs.payments.setLimit(limit)
    },
    onPaginationPayments (page) {
      this.page.payments = page
    },
  },
  props: {
    selectedRow: {
      type: Object,
      required: false,
    },
    status: {
      type: Array,
      required: false,
    }
  },
  data () {
    return {
      userActionStatuses,
      conflictModes,
      linkTypes,
      page: {
        "payments": 1,
      },
      count: {
        "payments": 0
      },
      loading: {
        "payments": false
      },
      limit: {
        "payments": perPageItemsSm
      },
      sorting: {
        "payments": [],
      },
      headings: {
        created: capitalize(this.$t("when")),
        createdBy: capitalize(this.$t("reviewer")),
        "createdBy.memberCollective": startCase(this.$t("mc")),
        comment: capitalize(this.$t("comment")),
        suggestedEffectiveDate: capitalize(this.$t("suggested_effective_date")),
        actions: "",
      },
      columns: {
        payments: [
          "payeeType",
          "rightsHolder",
          "payee",
          "activityType",
          "distribution",
          "distributionPeriod",
          "distributionDate",
          "tariff",
          "split",
          "amount",
        ],
        comments: [
          "created",
          "createdBy",
          "createdBy.memberCollective",
          "comment",
          "suggestedEffectiveDate",
          "actions",
        ],
      },
      fields: [
        { key: "title", label: startCase(this.$t("title")), width: "40%" },
        { key: "artist", label: startCase(this.$t("artist")), width: "20%" },
        { key: "isrc", label: startCase(this.$t("isrc")), width: "20%" },
        { key: "resoundId", label: startCase(this.$t("crescendo_id")), width: "20%" },
      ],
      options: {
        sortable: ["rightsHolder", "amount", "payee", "distributionDate"],
        headings: {
          payeeType: capitalize(this.$t("payee_type")),
          payee: capitalize(this.$t("payee")),
          sisterSociety: capitalize(this.$t("sister_society")),
          rightsHolderType: capitalize(this.$t("rights_holder_type")),
          rightsholder: capitalize(this.$t("rightsholder")),
          recordingResoundId: this.$t("resound_id"),
          recordingTitle: capitalize(this.$t("title")),
          recordingArtist: capitalize(this.$t("artist")),
          recordingIsrc: this.$t("isrc"),
          rightsHolder: capitalize(this.$t("rights_holder")),
          tariff: capitalize(this.$t("tariff")),
          activityType: capitalize(this.$t("activity_type")),
          distribution: capitalize(this.$t("distribution")),
          distributionDate: capitalize(this.$t("distribution_date")),
          distributionPeriod: capitalize(this.$t("distribution_period")),
          holdReason: capitalize(this.$t("hold_reason")),
          split: capitalize(this.$t("share")),
          amount: capitalize(this.$t("amount")),
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          const component = this.$parent.$parent.$parent
          const idFilter = component.selectedRow ? { recording_id: component.selectedRow.id } : {}
          let effectiveDate = component.selectedEffectiveDate ? moment(component.selectedEffectiveDate, this.$config.DATE_FORMAT).format(this.$config.ISO_DATE_FORMAT) : null
          const effectiveDateFilter = effectiveDate && component.filterByEffectiveDate ? { from_date: effectiveDate } : {}
          const filters = {
            rights_holder_type: rightsholderTypes.PERFORMER,
            negative_adjustments_only: component.onlyAffectedPayments,
            ...idFilter,
            ...effectiveDateFilter,
          }
          queryParams = { ...filters, ...queryParams }
          return this.$api.payments.paymentsHistory(queryParams)
        }
      },
      memberCollectiveOptions: [],
      activityTypes: activityTypes,
      onlyAffectedPayments: true,
      filterByEffectiveDate: false,
      selectedEffectiveDate: null,
    }
  },
  watch: {
    onlyAffectedPayments () {
      this.$refs.payments.getData()
    },
    filterByEffectiveDate () {
      this.$refs.payments.getData()
    }
  }
}
</script>
