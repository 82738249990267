<!-- Copied from https://matanya.gitbook.io/vue-tables-2/custom-template#font-awesome-sort-icon -->

<template>
    <fa-icon v-b-tooltip="tooltipData" v-if="props.sortable" :icon="icon" class="fa-pull-right"/>
</template>

<script>
export default {
  name: "SortControl",
  props: ["props"],
  computed: {
    icon () {
      // sortStatus = { sorted: Boolean, asc: Boolean }

      // if not sorted return base icon
      if (!this.props.sortStatus.sorted) return "sort"

      // return sort direction icon
      return this.props.sortStatus.asc ? "sort-up" : "sort-down"
    }
  },
  data: function () {
    return {
      tooltipData: {
        title: this.$t("multi_sorting_help_text"),
        disabled: !this.props.opts.serverMultiSorting
      }
    }
  }
}
</script>
