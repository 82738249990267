<template>
  <div v-if="userFetched">
    <component
      :is="activeUserAction.component"
      v-bind="activeUserAction.props"
    ></component>
  </div>
</template>

<script>
import { actionsTabs, usageMatchingStatuses } from "@/constants"
import { mapActions, mapGetters, mapState } from "vuex"
import ConsolidatedUsageList from "@/pages/Usages/ConsolidatedUsageList"
import DuplicateConflictsList from "@/pages/UserActions/DuplicateConflicts/DuplicateConflictsList"
import EligibilityConflictList from "@/pages/UserActions/EligibilityConflictList"
import LineupConflictList from "@/pages/UserActions/LineupConflictList"
import MandateConflictList from "@/pages/UserActions/MandateConflictList"
import MergeRequestList from "@/pages/UserActions/MergeRequestList"
import MetadataConflictFilesList from "@/pages/UserActions/MetadataConflictFilesList"
import NegativeAdjustmentsTab from "@/pages/UserActions/NegativeAdjustmentsTab"
import NotificationCenter from "@/components/UserActions/NotificationCenter.vue"
import OwnershipConflictList from "@/pages/UserActions/OwnershipConflictList"
import RecordingChallengeList from "@/pages/UserActions/RecordingChallengeList"
import RepertoireErrorsList from "@/pages/UserActions/RepertoireErrorsList"
import RightsholdersChallengeList from "@/pages/UserActions/RightsholdersChallengeList"
import UsageChallengeList from "@/pages/UserActions/UsageChallengeList"

export default {
  name: "UserActions",
  data: () => {
    return {
      tabIndex: 0,
      activeUserAction : {},
      userAction: [
        {
          type: actionsTabs.NOTIFICATIONS,
          component: "NotificationCenter"
        },
        {
          type: actionsTabs.MANDATES,
          component: "MandateConflictList"
        },
        {
          type: actionsTabs.MATCHING_CONFLICTS,
          component: "MetadataConflictFilesList"
        },
        {
          type: actionsTabs.REPERTOIRE_ERRORS,
          component: "RepertoireErrorsList"
        },
        {
          type: actionsTabs.RECORDING_CHALLENGES,
          component: "RecordingChallengeList"
        },
        {
          type: actionsTabs.RIGHTSHOLDER_CHALLENGES,
          component: "RightsholdersChallengeList"
        },
        {
          type: actionsTabs.LINEUP_CONFLICTS,
          component: "LineupConflictList"
        },
        {
          type: actionsTabs.RIGHTS_OWNERSHIP_CONFLICT,
          component: "OwnershipConflictList"
        },
        {
          type: actionsTabs.USAGE_MATCHING,
          component: "ConsolidatedUsageList",
          props: {
            userAction: true,
            defaultFilters: {
              status: usageMatchingStatuses.UNMATCHED.toString(),
              year: new Date().getFullYear() - 1,
            }
          },
        },
        {
          type: actionsTabs.USAGE_CHALLENGES,
          component: "UsageChallengeList"
        },
        {
          type: actionsTabs.ELIGIBILITY_CONFLICTS,
          component: "EligibilityConflictList"
        },
        {
          type: actionsTabs.NEGATIVE_ADJUSTMENTS,
          component: "NegativeAdjustmentsTab"
        },
        {
          type: actionsTabs.DUPLICATED_CONFLICTS,
          component: "DuplicateConflictsList"
        },
        {
          type: actionsTabs.MERGE_REQUESTS,
          component: "MergeRequestList"
        }
      ]
    }
  },
  components: {
    RecordingChallengeList,
    RepertoireErrorsList,
    MandateConflictList,
    MetadataConflictFilesList,
    RightsholdersChallengeList,
    LineupConflictList,
    OwnershipConflictList,
    ConsolidatedUsageList,
    UsageChallengeList,
    EligibilityConflictList,
    NegativeAdjustmentsTab,
    DuplicateConflictsList,
    NotificationCenter,
    MergeRequestList,
  },
  methods: {
    ...mapActions("userActions", ["subscribeUserActions", "unsubscribeUserActions"]),
    setActiveUserAction () {
      let type = this.$route.params.type
      this.activeUserAction = this.userAction.find(ua => ua.type === type)
    }
  },
  computed: {
    ...mapGetters("user", ["isUsageMatcher", "userFetched", "hasMakers", "hasPerformers"]),
    ...mapState("user", ["permissions"]),
  },
  mounted () {
    this.setActiveUserAction()
    this.subscribeUserActions()
  },
  destroyed () {
    this.unsubscribeUserActions()
  },
  watch: {
    "$route": {
      handler: function () {
        this.setActiveUserAction()
      }
    }
  },
  props: {
    type: {
      type: String,
      required: false,
    },
  },
}
</script>
