<template>
  <div>
    <list-layout
        :isChild="true"
        :title="$t('system_users') | startcase"
        :apply-filters="applyFilters"
        :reset-filters="resetFilters"
        :filters-pending="filtersPending"
    >
      <modal 
        id="user-create-modal" 
        modal-class="custom-modal edit"
        customTitle="New system user"
        modalType="edit"
        size="lg" 
        centered
        hide-footer>
        <UsersCreateForm @submit="refresh" />
      </modal>
      <template v-slot:filters>
        <b-row align-h="end" class="pb-1" v-if="permissions.pages[Pages.UsersList].canWrite">
          <b-col cols="4">
            <b-form-group :label="`${$t('filter_by_role')}:`" label-class="pb-0">
              <b-form-select v-model="filters.role.value" :options="userRoleOptions">
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="`${$t('display_by')}:` | capitalize" label-class="pb-0">
              <b-form-radio-group
                v-model="filters.type.value"
                buttons
                button-variant="outline-primary"
                name="radios-btn-outline"
              >
                <b-form-radio :value="null">
                  {{ $t("all") | capitalize }}
                </b-form-radio>
                <b-form-radio value="internal">
                  {{ $t("internal") | capitalize }}
                </b-form-radio>
                <b-form-radio value="external">
                  {{ $t("external") | capitalize }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end" align-v="center">
          <b-col cols="4">
            <b-form-group
              :label="`${$t('filter_by_collective_society')}:`"
              label-class="pb-0"
              v-show="permissions.pages[Pages.UsersList].canWrite"
            >
              <b-form-select v-model="filters.member.value" :options="memberCollectiveOptions">
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <!-- Status filter -->
          <b-col cols="3">
            <div class="mt-3">
              <b-form-group>
                <b-form-checkbox-group stacked class="mx-auto" v-model="filters.status.value">
                  <b-form-checkbox value="active">
                    <status
                      :text="$t('active') | capitalize"
                      :clickable="true"
                      :count="summary.active"
                      :icon="['fas', 'check-circle']"
                      color="green"
                    />
                  </b-form-checkbox>
                  <b-form-checkbox value="pending">
                    <status
                      :text="$t('pending') | capitalize"
                      :clickable="true"
                      :count="summary.pending"
                      :icon="['fas', 'question-circle']"
                      color="orange"
                    />
                  </b-form-checkbox>
                  <b-form-checkbox value="inactive">
                    <status
                      :text="$t('inactive') | capitalize"
                      :clickable="true"
                      :count="summary.inactive"
                      :icon="['fas', 'exclamation-circle']"
                      color="red"
                    />
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </template>

      <!-- Actions -->
      <template  v-if="permissions.pages[Pages.UsersList].canWrite" v-slot:actions>
        <b-button :to="{ name: 'users-create' }" variant="outline-primary" class="ml-3">
          <fa-icon class="mr-2" :icon="['fas', 'plus-circle']" />
          Create
        </b-button>
      </template>

      <!-- Search -->
      <template v-slot:search>
        <form-field
          :placeholder="$t('search') | capitalize"
          :enter-action="applyFilters"
          v-model="filters.search.value"
          :icon="['fas', 'search']"
        />
        <b-row class="mt-4">
          <b-col v-if="permissions.pages[Pages.UsersList].canWrite">
            <b-button @click="createUser">
              <fa-icon class="mr-2" :icon="['fas', 'plus-circle']" />
              Create new user
            </b-button>
          </b-col>
        </b-row>
      </template>

      <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              reference="users"
              :page="page"
              :count="count"
              :loading="loading"
              v-model="limit"
              @limit="onLimit"
            />
          </div>
        </div>
      </template>

      <!-- Table -->
      <template v-slot:table>
        <v-server-table
          ref="users"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <!-- Name -->
          <template v-slot:name="{ row }">
            <router-link
              v-if="permissions.pages[Pages.UsersList].canWrite"
              class="text-info"
              :to="{ name: 'users-update', params: { uuid: row.uuid } }"
            >
              {{ row.name }}
            </router-link>
            <span v-else>
              {{ row.name }}
            </span>
          </template>

          <!-- Role -->
          <template v-slot:role="{ row }">
            <span class="text-gray">
              {{
                userRoleOptions.find(x => x.value === row.role)
                  ? userRoleOptions.find(x => x.value === row.role)["text"]
                  : ""
              }}
            </span>
          </template>

          <!-- Type -->
          <template v-slot:type="{ row }">
            <status
              v-if="row.type === 'internal'"
              text="Int"
              :icon="['fas', 'sign-in-alt']"
              color="blue"
            />
            <status v-else text="Ext" :icon="['fas', 'sign-out-alt']" color="green" />
          </template>

          <!-- Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="row.status === 'active'"
              :text="$t('active') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === 'pending'"
              :text="$t('pending') | capitalize"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            <status
              v-if="row.status === 'inactive'"
              :text="$t('inactive') | capitalize"
              :icon="['fas', 'exclamation-circle']"
              color="red"
            />
          </template>

          <!-- Actions -->
          <template v-slot:actions="{ row }">
            <div class="d-flex justify-content-end">
              <table-action
                :title="$t('delete') | lowercase"
                v-if="permissions.pages[Pages.UsersList].canWrite && !isCurrentUser(row.uuid)"
                @click="onDelete(row.uuid)"
                :icon="['fas', 'trash']"
              />

              <!-- Deletion Confirmation Modal -->
              <modal
                :id="'confirmation-modal-' + row.uuid"
                :customTitle="$t('delete_user') | capitalize"
                modal-class="custom-modal edit"
                modalType="edit"
                size="md" 
                centered
                @ok="onConfirmDelete(row.uuid)"
                okTitle="Confirm" >
                <div>{{ $t("do_you_really_want_to_delete_user") | capitalize }}?</div>
              </modal>
            </div>
          </template>
        </v-server-table>
      </template>
      <router-view name="modal"></router-view>
    </list-layout>
  </div>
</template>

<script>
import { capitalize, get } from "lodash"
import { mapActions, mapGetters, mapState } from "vuex"
import { Pages } from "@/utils/pages"
import Pagination from "@/components/Pagination"
import UsersCreateForm from "./UsersCreateForm"
import { getPerPageItemDefaults } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"
import { mapMutations } from "vuex"

export default {
  name: "UsersList",
  mixins: [listRouteMixin],
  components: {
    UsersCreateForm,
    Pagination,
  },
  methods: {
    ...mapActions("consts", ["getUserRoles"]),
    ...mapMutations("alert", ["error"]),
    onDelete (id) {
      this.$bvModal.show(`confirmation-modal-${id}`)
    },
    onConfirmDelete (id) {
      this.$api.users
        .usersDelete(id)
        .then(() => {
          this.refresh()
        })
        .catch(error => {
          let msg = `Error deleting user. ${error.response.data.detail}`
          this.error(msg)
        })
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.memberCollectiveOptions = this.memberCollectives.map(x => ({
        text: x.name,
        value: x.uuid,
      }))
      this.userRoleOptions = this.userRoles.map(x => ({ text: x["name"], value: x["id"] }))
      this.$emit("loaded", data.count)
      this.loading = false
    },
    getData () {
      this.$refs.users.getData()
    },
    refresh () {
      this.$refs.users.refresh()
    },
    createUser () {
      this.$bvModal.show("user-create-modal")
    },
    async getRoles () {
      if (!this.userRoles.length) {
        this.getUserRoles()
      }
    },
  },
  data () {
    return {
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      page: 1,
      loading: false,
      summary: { active: 0, pending: 0, inactive: 0, internal: 0, external: 0 },
      columns: ["name", "memberCollective.name", "role", "type", "status", "actions"],
      options: {
        sortable: ["name"],
        headings: {
          name: capitalize(this.$t("user_name")),
          "memberCollective.name": this.$t("collective_society"),
          role: capitalize(this.$t("user_role")),
          type: capitalize(this.$t("internal_or_external")),
          status: capitalize(this.$t("status")),
          actions: "",
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.users.usersList(queryParams)
        },
      },
      memberCollectiveOptions: [],
      userRoleOptions: [],
      filters: {
        search: { value: "", defaultValue: "" },
        status: { value: [], defaultValue: [] },
        type: { value: "", defaultValue: "" },
        member: { value: "", defaultValue: "" },
        role: { value: "", defaultValue: "" },
      },
      Pages,
    }
  },
  computed: {
    ...mapGetters("user", ["isCurrentUser"]),
    ...mapState("consts", ["memberCollectives", "userRoles"]),
    ...mapState("user", ["permissions"]),
  },
  mounted () {
    this.getRoles()
    this.updateTableSortIcons(this.$refs.users.$children[0])
  },
}
</script>
