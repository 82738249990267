<template>
  <list-layout
      :title="$t('payment_uploads') | startcase"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
  >
    <template v-slot:search>
      <b-form-group>
        <form-field
          :placeholder="$t('search') | capitalize"
          :enter-action="applyFilters"
          v-model="filters.search.value"
          :icon="['fas', 'search']"
        />
      </b-form-group>
    </template>

    <template v-slot:filters>
      <b-container>
        <b-row>
          <b-col>
            <b-form-group :label="$t('rightsholder_type') | capitalize" label-class="pb-0">
              <b-form-select
                v-model="filters.rightsHolderType.value"
                :options="
                  Object.keys(rightholderTypes).map(k => {
                    return { text: capitalize(k), value: k[0] };
                  })
                "
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <!-- Society filter -->
            <b-form-group :label="`${$t('collective_society')}`" label-class="pb-0">
              <b-form-select
                v-model="filters.sisterSociety.value"
                :options="memberCollectiveOptions"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('upload_date') | capitalize" label-class="pb-0">
              <date-picker class="w-100" v-model="dateRange" range :format="$config.DATE_FORMAT" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="`${$t('status')}:` | capitalize" label-class="pb-0">
              <b-form-select
                v-model="filters.status.value"
                :options="
                  Object.keys(fileStatuses).map(k => {
                    return { text: capitalize(k), value: fileStatuses[k] };
                  })
                "
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <!-- Counts -->
    <template v-slot:counts>
      <div class="row align-items-center">
        <div class="col d-flex">
          <pagination
            class="smaller p-0"
            reference="paymentfiles"
            :page="page"
            :count="count"
            :loading="loading"
            v-model="limit"
            @limit="onLimit"
          />
        </div>
      </div>
    </template>

    <template v-slot:table>
      <v-server-table
        :columns="columns"
        :options="options"
        ref="paymentfiles"
        @loaded="onLoaded"
        @loading="onLoading"
        @pagination="onPagination"
        @sorted="onSorted"
      >
        <template v-slot:fileName="{ row }">
          <router-link
            class="text-info"
            :to="{
              name: 'payment-file-details',
              query: { source: row.id },
              params: { fileName: row.fileName },
            }"
          >
            {{ row.fileName }}
          </router-link>
        </template>

        <!-- Rightsholder type -->
        <template v-slot:rightHolderType="{ row }">
          <NamesListModal
            class-names="table-meta mb-2"
            :values="row.rightHolderType.map(t => capitalize($t(paymentRightsholderTypes[t])))"
          />
        </template>

        <!-- Distribution -->
        <template v-slot:distribution="{ row }">
          {{ row.distributionStartYear + " - " + row.distributionEndYear }}
        </template>

        <!-- Upload date -->
        <template v-slot:uploadDate="{ row }">
          <date-time-cell :dateTime="row.uploadDate" />
        </template>

        <!-- Period Description -->
        <template v-slot:periodDescription="{ row }">
          {{ row.periodDescription }}
        </template>

        <!-- Statement Period -->
        <template v-slot:statementPeriod="{ row }">
          {{ row.statementPeriod }}
        </template>

        <!-- Uploader -->
        <template v-slot:uploader="{ row }">
          <div v-if=row.uploader>{{ row.uploader.firstName + " " + row.uploader.lastName }}</div>
          <div v-else></div>
        </template>

        <!-- Ingestion Stats -->
        <template v-slot:ingestionStats="{ row }">
          <div class="d-flex" v-if="row.stats && row.stats.landingDbIngestion">
            <div v-for="k in landingDbIngestionKeys" :key="k" class="table-sub-class text-sm mr-3">
              <div>
                <status
                  v-if="getStatsDisplay[k] && getStatsDisplay[k].icon"
                  @click="showErrors(row, false, k)"
                  :clickable="hasErrors(k, row)"
                  :active="hasErrors(k, row)"
                  :text="String(row.stats.landingDbIngestion[k])"
                  :icon="['fas', getStatsDisplay[k].icon]"
                  :name="getStatsDisplay[k].name"
                  :color="getStatsDisplay[k].color"
                />
              </div>
            </div>
          </div>
        </template>

        <!-- Recording matching stats -->
        <template v-slot:recordingMatchingStats="{ row }">
          <div class="d-flex align-items-center" v-if="row.stats && row.stats.recordingMatching">
            <div v-for="k in recordingMatchingKeys" :key="k" class="table-sub-class text-sm mr-2">
              <status
                v-if="getMatchRecordingStatsDisplay[k]"
                :text="getMatchRecordingStatsDisplay[k].icon ? String(row.stats.recordingMatching[k]) : `Total: ${String(row.stats.recordingMatching[k])}`"
                :icon="(getMatchRecordingStatsDisplay[k].icon ? ['fas', getMatchRecordingStatsDisplay[k].icon] : null)"
                :name="getMatchRecordingStatsDisplay[k].name"
                :color="getMatchRecordingStatsDisplay[k].color"
                :bold="getMatchRecordingStatsDisplay[k].bold"
              />
            </div>
          </div>
        </template>

        <!-- Rights holder matching stats -->
        <template v-slot:rightsHolderMatchingStats="{ row }">
          <div class="d-flex align-items-center" v-if="row.stats && row.stats.rightsHolderMatching">
            <div v-for="k in rightsHolderMatchingKeys" :key="k" class="table-sub-class text-sm mr-2">
              <status
                v-if="getMatchRightsHolderStatsDisplay[k]"
                :text="getMatchRecordingStatsDisplay[k].icon ? String(row.stats.rightsHolderMatching[k]) : `Total: ${String(row.stats.rightsHolderMatching[k])}`"
                :icon="(getMatchRecordingStatsDisplay[k].icon ? ['fas', getMatchRightsHolderStatsDisplay[k].icon] : null)"
                :name="getMatchRightsHolderStatsDisplay[k].name"
                :color="getMatchRightsHolderStatsDisplay[k].color"
                :bold="getMatchRightsHolderStatsDisplay[k].bold"
              />
            </div>
          </div>
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status
            v-if="row.status === fileStatuses.PENDING"
            :text="$t('pending')"
            name="pending"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.PROCESSING"
            :text="$t('processing')"
            name="processing"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.LANDED"
            :text="$t('landed')"
            name="landed"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.INGESTED"
            :text="$t('ingested')"
            name="ingested"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.MATCHED"
            :text="$t('matched')"
            name="matched"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.ERROR"
            name="error"
            :text="$t('error')"
            :icon="['fas', 'exclamation-circle']"
            color="red"
            :active="true"
            @click="showErrors(row, true, 'error')"
            clickable
          />
        </template>
      </v-server-table>
      
      <modal 
        id="errorsModal" 
        ref="errorsModal"
        :customTitle="$t('errors') | capitalize"
        modal-class="custom-modal error"
        modalType="error"
        size="xl"
        centered 
        hide-footer>
        <FilesErrorsList
          :key="currentFile | get('id')"
          :item="currentFile"
          module="repertoirefiles"
          :onlyShowFileLevelErrors="onlyShowFileLevelErrors"
          :file-errors-endpoint="$api.payments.paymentFileErrorsList"
        />
      </modal>
    </template>
  </list-layout>
</template>
<script>
import { capitalize, get } from "lodash"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import {
  fileStatuses,
  getPerPageItemDefaults,
  paymentRightsholderTypes,
  rightsholderTypes,
} from "@/constants"
import FilesErrorsList from "@/components/FilesErrorsList"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import Pagination from "@/components/Pagination"
import { mapState } from "vuex"

export default {
  name: "PaymentFilesList",
  mixins: [datePickerMixin, listRouteMixin],
  components: {
    Pagination,
    NamesListModal,
    FilesErrorsList,
  },
  computed: {
    ...mapState("consts", ["memberCollectives"]),
    memberCollectiveOptions () {
      return this.memberCollectives.map(x => ({ text: x.name, value: x.uuid }))
    },
    getStatsDisplay () {
      return {
        totalStatementLines: {
          name: "total",
          icon: "check-circle",
          color: "green",
        },
        ingestedStatementLines: {
          name: "ingested",
          icon: "plus-circle",
          color: "green",
        },
        errorCount: { name: "error", icon: "exclamation-circle", color: "red" },
      }
    },
    getMatchRecordingStatsDisplay () {
      return {
        totalCount: {
          name: "total",
          icon: "",
          color: "",
          bold: true,
        },
        automatchedCount: {
          name: "auto matched",
          icon: "check-circle",
          color: "green",
          bold: false,
        },
        manualmatchedCount: {
          name: "manual matched",
          icon: "plus-circle",
          color: "green",
          bold: false,
        },
        unmatchedCount: {
          name: "unmatched",
          icon: "exclamation-circle",
          color: "red",
          bold: false,
        },
      }
    },
    getMatchRightsHolderStatsDisplay () {
      return {
        totalCount: {
          name: "total",
          icon: "",
          color: "",
          bold: true,
        },
        automatchedCount: {
          name: "auto matched",
          icon: "check-circle",
          color: "green",
          bold: false,
        },
        manualmatchedCount: {
          name: "manual matched",
          icon: "plus-circle",
          color: "green",
          bold: false,
        },
        unmatchedCount: {
          name: "unmatched",
          icon: "exclamation-circle",
          color: "red",
          bold: false,
        },
      }
    },
  },
  methods: {
    capitalize,
    hasErrors (key, row) {
      return key.includes("error") && row.stats.landingDbIngestion[key] > 0
    },
    showErrors (row, onlyShowFileLevelErrors = false, key) {
      if (onlyShowFileLevelErrors || this.hasErrors(key, row)) {
        this.currentFile = row
        this.onlyShowFileLevelErrors = onlyShowFileLevelErrors
        this.$bvModal.show("errorsModal")
      }
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    getData () {
      this.$refs.paymentfiles.getData()
    },
  },
  data () {
    return {
      filters: {
        search: { value: "", defaultValue: "" },
        rightsHolderType: { value: null, defaultValue: null },
        sisterSociety: { value: null, defaultValue: null },
        uploadDate: { value: null, defaultValue: null },
        status: { value: null, defaultValue: null },
      },
      currentFile: null,
      rightholderTypes: rightsholderTypes,
      fileStatuses: fileStatuses,
      onlyShowFileLevelErrors: false,

      landingDbIngestionKeys: ["totalStatementLines", "ingestedStatementLines", "errorCount"],
      recordingMatchingKeys: ["totalCount", "automatchedCount", "manualmatchedCount", "unmatchedCount"],
      rightsHolderMatchingKeys: ["totalCount", "automatchedCount", "manualmatchedCount", "unmatchedCount"],
      count: 0,
      page: 1,
      loading: false,
      limit: Number(getPerPageItemDefaults(this.$route)),
      options: {
        sortable: ["fileName"],
        headings: {
          fileName: capitalize(this.$t("filename")),
          "sisterSociety.name": capitalize(this.$t("sister_society")),
          rightHolderType: capitalize(this.$t("rightsholder_type")),
          distribution: capitalize(this.$t("distribution")),
          uploader: capitalize(this.$t("uploader")),
          uploadDate: capitalize(this.$t("upload_date")),
          periodDescription: capitalize(this.$t("period_description")),
          statementPeriod: capitalize(this.$t("statement_period")),
          ingestionStats: capitalize(this.$t("ingestion_stats")),
          recordingMatchingStats: this.$t("recording_matching_stats"),
          rightsHolderMatchingStats: this.$t("rightsholder_matching_stats"),
          status: capitalize(this.$t("status")),
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.payments.paymentFiles(queryParams)
        },
      },
      columns: [
        "fileName",
        "sisterSociety.name",
        "rightHolderType",
        "distribution",
        "uploader",
        "uploadDate",
        "periodDescription",
        "statementPeriod",
        "ingestionStats",
        "recordingMatchingStats",
        "rightsHolderMatchingStats",
        "status",
      ],
      paymentRightsholderTypes,
    }
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.paymentfiles.$children[0])
  }
}
</script>
