<template>
  <div>
    <b-row>
      <h3 class="text-red">
        {{ this.$t("royalty_details") | capitalize }}
      </h3>
    </b-row>
    <b-row>
      <b-table
        id="stations"
        :items="data"
        :fields="weightingFields"
        striped
        fixed
      >
        <template v-slot:cell(thresholdReached)="row">
          <boolean-status :is-true="row.item.aboveThreshold" />
        </template>
      </b-table>
    </b-row>
  </div>
</template>

<script>

import { BooleanStatus } from "@/components"
import { capitalize } from "lodash"

export default {
  name: "StationGeneralDetails",
  components: {
    BooleanStatus,
  },
  props: {
    data: Array,
  },
  data () {
    return {
      weightingFields: [
        {
          key: "year",
          label: capitalize(this.$t("year")),
        },
        {
          key: "province",
          label: capitalize(this.$t("province")),
        },
        {
          key: "provinceStationCount",
          label: capitalize(this.$t("province_station_count")),
        },
        {
          key: "provincialPopulation",
          label: capitalize(this.$t("provincial_population")),
        },
        {
          key: "royaltiesPaid",
          label: capitalize(this.$t("royalties_paid")),
        },
        {
          key: "revenue",
          label: capitalize(this.$t("revenue")),
        },
        {
          key: "thresholdReached",
          label: capitalize(this.$t("threshold_reached")),
        },
      ]
    }
  },
}
</script>
