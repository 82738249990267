import { UsersList, UsersUpdateForm } from "@/pages/Users"
import ConsolidatedUsageList from "@/pages/Usages/ConsolidatedUsageList"
import CopyrightsAndTariffs from "../pages/CopyrightsAndTariffs/CopyrightsAndTariffs"
import CreateDistributionPage from "@/pages/Distributions/CreateDistributionPage"
import EligibilityConflictList from "@/pages/UserActions/EligibilityConflictList"
import Forbidden from "@/pages/Forbidden"
import LandingRecordingsList from "../pages/Repertoire/LandingRecordingsList"
import { LandingRightsHoldersList } from "@/pages/Rightsholders"
import ListDistributionsPage from "@/pages/Distributions/ListDistributionsPage"
import { Login } from "@/pages/Login"
import MandateConflictList from "@/pages/UserActions/MandateConflictList"
import MatchingStatistics from "@/pages/Reporting/MatchingStatistics"
import MetadataConflictDetailPage from "@/pages/UserActions/MetadataConflictDetailPage"
import PageNotFound from "@/pages/PageNotFound"
import { Pages } from "@/utils/pages"
import PaymentFileDetails from "@/pages/Payment/PaymentFileDetails"
import PaymentFilesList from "../pages/Payment/PaymentFilesList"
import RecordingChallengeDetail from "../pages/UserActions/RecordingChallengeDetail"
import { RecordingDetail } from "@/pages/Repertoire/Recordings"
import RecordingMergeRequestPage from "@/pages/UserActions/RecordingMergeRequestPage.vue"
import { RecordingsList } from "@/pages/Repertoire/Recordings"
import { RepertoireFilesList } from "@/pages/Repertoire/RepertoireFiles"
import { RightsHolderBatchesList } from "@/pages/Rightsholders"
import RightsHolderChallengeDetail from "../pages/UserActions/RightsHolderChallengeDetail"
import RightsHolderDetail from "@/pages/Rightsholders/RightsHolderDetail/RightsHolderDetail"
import { RightsHoldersList } from "@/pages/Rightsholders"
import StationDetail from "@/pages/Broadcasters/StationDetail"
import { StationsList } from "@/pages/Broadcasters"
import UsageFileList from "@/pages/Usages/UsageFileList"
import UserActions from "../pages/UserActions/UserActions"
import UserRoles from "@/pages/Users/UserRoles.vue"
import Vue from "vue"
import VueRouter from "vue-router"
import { actionsTabs } from "@/constants"
import { get } from "lodash"
import { store } from "@/store"


export const router = new VueRouter({
  mode: "history",
  routes: [
    // Public Paths
    {
      path: "/",
      name: Pages.Login,
      component: Login,
    },
    // Private Paths
    // Users
    {
      path: "/user-profile",
      name: "user-profile",
      component: UsersUpdateForm,
      props: true,
      meta: { defaultPrevious: "users-list" }
    },
    {
      path: "/users",
      name: "users-list",
      component: UsersList
    },
    {
      path: "/users",
      name: "users",
      component: UsersList
    },
    {
      path: "/user-roles",
      name: "user-roles",
      component: UserRoles
    },
    {
      path: "/users/:uuid/update",
      name: "users-update",
      component: UsersUpdateForm,
      props: true,
      meta: { defaultPrevious: "users-list" }
    },
    // Repertoire
    {
      path: "/repertoire/repertoire-files",
      name: "repertoire-files-list",
      component: RepertoireFilesList
    },
    {
      path: "/repertoire-files/:repFileId/landing-recordings",
      name: "landing-recordings",
      component: LandingRecordingsList,
      props: true,
      meta: { defaultPrevious: "repertoire-files-list" }
    },
    {
      path: "/repertoire/recordings",
      name: "repertoire-recordings-list",
      component: RecordingsList
    },
    {
      path: "/repertoire/recordings/:recordingId/recording-detail/:type",
      name: "repertoire-recording-detail",
      component: RecordingDetail,
      props: true,
      meta: { defaultPrevious: "repertoire-recordings-list" }
    },
    {
      path: "/user-actions/:type",
      name: "user-actions",
      props: true,
      component: UserActions,
    },
    {
      path: "/user-actions/mandate-conflicts",
      name: "mandate-conflicts",
      props: true,
      component: MandateConflictList,
    },
    {
      path: "/user-actions/eligibility-conflicts",
      name: "eligibility-conflicts",
      props: true,
      component: EligibilityConflictList,
    },
    // Specific user action details
    {
      path: "/repertoire/matching-challenges/:uuid",
      name: "recording-challenge",
      props: true,
      component: RecordingChallengeDetail,
      meta: { defaultPrevious: "user-actions", params: { type: "matching-challenges" } }
    },
    {
      path: "/user-actions/matching-conflicts/:uuid",
      name: "manual-matching-detail",
      props: route => ({ uuid: route.params.uuid, type: route.query.type, status: route.query.status }),
      component: MetadataConflictDetailPage,
      meta: { defaultPrevious: "user-actions", params: { type: "matching-conflicts" } }
    },
    {
      path: "/user-actions/merge-requests/recording/:uuid",
      name: "recording-merge-request-detail",
      props: route => ({ uuid: route.params.uuid }),
      component: RecordingMergeRequestPage,
      meta: { defaultPrevious: "user-actions", params: { type: "merge-requests" } }
    },

    {
      path: "/repertoire/rightsholder-challenges/:challengeType/:uuid",
      name: "rightsholder-challenge",
      props: true,
      component: RightsHolderChallengeDetail,
      meta: { defaultPrevious: "user-actions", params: { type: "rightsholders-challenges" } }
    },
    {
      path: "/rightsholder-batches",
      name: "rightsholder-batches-list",
      component: RightsHolderBatchesList
    },
    {
      path: "/rightsholder-batches/:batchId/landing-rightsholders",
      name: "landing-rightsholders-list",
      component: LandingRightsHoldersList,
      props: route => ({ rhType: route.query.rh_type, batchId: route.params.batchId }),
      meta: { defaultPrevious: "rightsholder-batches-list" }
    },
    {
      path: "/rightsholders",
      name: "rightsholders-list",
      component: RightsHoldersList
    },
    {
      path: "/stations",
      name: "stations",
      component: StationsList
    },
    {
      path: "/stations/:stationId",
      name: "station-detail",
      component: StationDetail,
      props: true,
      meta: { defaultPrevious: "stations" }
    },
    {
      path: "/rightsholders/:rightsholderId/rightsholder-detail/:type",
      name: "rightsholder-detail",
      component: RightsHolderDetail,
      props: true,
      meta: { defaultPrevious: "rightsholders" }
    },
    // Copyrights
    {
      path: "/copyrights-and-tariffs",
      name: "copyrights",
      component: CopyrightsAndTariffs
    },
    // Payments
    {
      path: "/payment-files",
      name: "payment-files",
      component: PaymentFilesList
    },
    {
      path: "/payment-file-details",
      name: "payment-file-details",
      props: true,
      component: PaymentFileDetails
    },
    // Usages
    {
      path: "/usage-files",
      name: "usage-files-list",
      component: UsageFileList
    },
    {
      path: "/consolidated-usages",
      name: "consolidated-usage-list",
      component: ConsolidatedUsageList,
      props: {
        defaultFilters: {
          year: new Date().getFullYear() - 1
        },
        consolidatedUsage: true,
      }
    },
    {
      path: "/usages-list",
      name: "usages-list",
      component: ConsolidatedUsageList,
      props: {
        usageList: true,
      },
    },
    // Reporting
    {
      path: "/reporting/matching-statistics",
      name: "matching-statistics",
      component: MatchingStatistics,
    },
    // Distributions
    {
      path: "/distributions/",
      name: "distributions-list",
      component: ListDistributionsPage,
    },
    {
      path: "/distributions/create",
      name: "distribution-create",
      component: CreateDistributionPage,
    },
    {
      path: "/forbidden",
      name: Pages.Forbidden,
      component: Forbidden
    },
    // Catch all: Page Not Found
    {
      path: "*",
      component: PageNotFound,
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  const { name, params, query } = to

  // Prepare previous page for back buttons when loading a new page
  if (to.name !== from.name) {
    store.commit("requests/clearPendingRequests")

    const excludedFlows = [
      { current: "repertoire-recording-detail", previous: "recording-challenge" }
    ]
    const currentFlow = { current: to.name, previous: from.name }
    let fullPath = from.fullPath

    if (excludedFlows.some(flow => flow.current === currentFlow.current && flow.previous === currentFlow.previous)) {
      fullPath = "/"
    }
    store.commit("user/setPreviousPage", fullPath)
  }

  // Reset the status of Page Not Found
  store.commit("user/setPageNotFound", false)

  // Redirect to welcome page if not logged in and trying to access a private page
  const publicPages = ["login"]

  const loggedIn = router.app && router.app.$auth.isAuthenticated()

  // A user that IS NOT logged in cannot view the private pages
  const notAllowedPrivate = !publicPages.includes(name) && !loggedIn

  // A user that IS logged in cannot view the public pages
  const notAllowedPublic = publicPages.includes(name) && loggedIn

  // Redirections
  let redirect = to.fullPath !== "/" ? to.fullPath : undefined
  if (notAllowedPrivate) {
    localStorage.setItem("redirect", redirect)
    return next({ name: "login", query: { redirect } })
  }

  if (notAllowedPublic) {
    let landingPage = localStorage.getItem("landingPage")
    if (landingPage) {
      landingPage = JSON.parse(landingPage)
      return next({ name: landingPage.name })
    } else {
      return next({ name: "user-actions", params: { type: actionsTabs.NOTIFICATIONS } })
    }
  }
  // Add ?page=1 query if "page" not present in list pages
  const page = get(query, "page")
  const listPages = []

  if (!page && listPages.includes(name)) {
    return next({ name, params, query: { ...query, page: 1 } })
  }

  next()
})

router.beforeResolve(async (to, from, next) => {
  // avoid loop redirect
  if (to.name === Pages.Login || to.name === Pages.Forbidden) {
    return next()
  }

  if (!Vue.prototype.$auth.isAuthenticated()) {
    return next({ name: "login" })
  }

  if (!store.state.user.userFetched) {
    try {
      await store.dispatch("user/getUser")
    } catch (e) {
      return next({ name: "login" })
    }
  }

  const pagePermission = store.state.user.permissions.pages[to.name]

  // not all pages are included in user permissions for now
  // so the first verification is necessary
  if (!pagePermission || pagePermission.canRead) {
    return next()
  }

  next({ name: Pages.Forbidden })
})
