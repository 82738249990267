<template>
  <div v-hotkey="shortcuts">
    <modal
      id="shortcuts-modal"
      ref="shortcutsModal"
      :customTitle="$t('hotkeys') | capitalize"
      modal-class="custom-modal info"
      modalType="info"
      size="md"
      centered
      hide-footer
    >
      <b-table :items="Object.values(shortcutDescriptions)" :fields="['key', 'description']">
        <template v-slot:cell(key)="row">
          <fa-icon v-if="row.item.icon" :icon="row.item.icon" size="lg"/>
          <span class="shortcut-key" v-else>{{ row.item.label }}</span>
        </template>

      </b-table>
    </modal>

    <t-table
      ref="conflictTable"
      :id="tableId"
      :key="tableId"
      :columns="columns"
      :options="options"
      :limit="tablePerPage"
      @loaded="onLoaded"
      @loading="onLoading"
      @row-click="selectConflict"
      @pagination="onPagination"
      @sorted="onSorted"
      nonWatchedColumns
    >
    <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0 mt-3 text-gray"
              reference="conflictsTable"
              ref="tablePagination"
              :page="tablePage"
              :count="count"
              :loading="loading"
              :go-to-page="true"
              v-model="tablePerPage"
              @limit="onLimit"
              @page="onPagination"
              >
              <template v-slot:buttons>
                <fa-icon :icon="['far', 'question-circle']" @click="$bvModal.show('shortcuts-modal')" class="clickable ml-2 text-light"/>
                <fa-icon v-if="!loading" :icon="['far', 'sync']" @click="getData" class="clickable ml-2 text-light"/>
              </template>
            </pagination>
          </div>
        </div>
      </template>

      <template v-slot:merge v-if="!filters.show_by_file.value">
        <b-row align-h="end" class="mr-2">
          <b-col cols="auto" v-if="filters.source_type.value === sourceTypes.PAYMENT_FILE">
            <b-form-checkbox v-model="actionStatus" class="ml-2" switch>
              {{ $t("show_resolved") | capitalize }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="auto">
            <b-form-checkbox v-if="permissions.actions.canUndoMatching" v-model="filters.undo_available.value" class="ml-2" :disabled="filters.repertoireFile.value || filters.show_by_file.value" switch>
              {{ $t("show_available_unmatch") | capitalize }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="auto">
            <b-form-checkbox v-model="filters.show_by_file.value" class="ml-2" :disabled="filters.repertoireFile.value || filters.undo_available.value" switch>
              {{ $t("show_by_file") | capitalize }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </template>

      <template v-slot:actions="{ row }">
        <b-button :disabled="loading" v-if="row.status == 'ML' && permissions.actions.canUndoMatching" variant="secondary" @click="undo(row.id)" class="mr-3">
          {{ $t("undo_match") | capitalize }}
        </b-button>
        <b-button :disabled="loading" v-else-if="row.status == 'MC' && permissions.actions.canUndoMatching" variant="secondary" @click="undo(row.id)" class="mr-3">
          {{ $t("undo_create") | capitalize }}
        </b-button>
      </template>
      <template v-slot:inProgress="{ row }">
        <modal
          :id="`confirmation-modal-solved-${row.id}`"
          :customTitle="$t('user_action_solved') | capitalize"
          modal-class="custom-modal edit"
          modalType="edit"
          size="md"
          centered
          hide-footer
          hide-header-close
          no-close-on-esc
          no-close-on-backdrop
        >
          <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
          <b-row class="float-right modal-footer mt-4">
            <b-button @click="blinkAndHide(row.id, null, getData)" class="btn-outline-red">
              {{ $t("accept") | capitalize }}
            </b-button>
          </b-row>
        </modal>
        <fa-icon
          v-if="isInProgressFrontend(row.id) || (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))"
          :icon="['fa', 'user-edit']"
          color="red"
        />
      </template>

      <template v-slot:actionStatus="{ row }">
        <status
          v-if="row.userActionId && (
            isInProgressFrontend(row.userActionId) ||
            (isInProgressBackend([row.userActionStatus]) && !isReleasedFrontend(row.userActionId))
          ) && !isSolved(row.userActionId)"
          :icon="['fa', 'user-edit']"
          color="red"
        />
      </template>

      <template v-slot:matchedStatus="{ row }">
        <status
          v-if="row.userActionStatus === inlineRowStatus.ERROR"
          :text="$t('inline_error') | capitalize"
          :icon="['fas', 'exclamation-circle']"
          inline
          color="red"
        />
        <status
          v-else-if="row.userActionStatus === inlineRowStatus.SUCCESS"
          :text="$t('inline_success') | capitalize"
          :icon="['fas', 'check-circle']"
          inline
          color="green"
        />
        <status
          v-else-if="row.userActionId && (isPending([row.userActionStatus]) || isReleasedFrontend(row.userActionId)) && !isSolved(row.userActionId)"
          :text="$t('pending') | capitalize"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-else
          :text="$t('resolved') | capitalize"
          :icon="['fas', 'check-circle']"
          color="green"
        />
      </template>
      <template v-slot:title=" { row }">
        <span :title="row.title">{{ row.title }}</span>
      </template>
      <template v-slot:artist=" { row }">
        <span :title="row.artist">{{ row.artist }}</span>
      </template>
      <template v-slot:isrc=" { row }">
        <span :title="row.isrc">{{ row.isrc }}</span>
      </template>
      <template v-slot:firstMakerNationality="{ row }">
        {{ row.firstMakers ? flatten(row.firstMakers.map(fm => fm.nationalities)).join(", ") : "" }}
      </template>

      <template v-slot:firstMakers="{ row }">
        <names-list-modal v-if="row.firstMakers" :values="row.firstMakers.map(fm => fm.name)"/>
      </template>
      <template v-slot:releases="{ row }">
        <names-list-modal v-if="row.releases" :values="row.releases" :max-items="3"/>
      </template>
      <template v-slot:owners="{ row }">
        <names-list-modal v-if="row.owners" :values="row.owners" :max-items="3"/>
      </template>
      <template v-slot:performers="{ row }">
        <names-list-modal v-if="row.performers" :values="row.performers" :max-items="3"/>
      </template>

      <template v-slot:dateOfBirth="{ row }">
        {{ row.dateOfBirth ? parseDate(row.dateOfBirth, $config.DATE_FORMAT) : "" }}
      </template>
      <template v-slot:collective="{ row }">
        {{ row.memberCollectives ? row.memberCollectives[0].name : "" }}
      </template>

      <template v-slot:landingObject.sources="{ row }">
        <sources :sources="getSources(row)" :source-type="filters.source_type.value"/>
      </template>
      <template v-slot:landingObject.repertoireFiles="{ row }">
        <sources :sources="row.landingObject.repertoireFiles" :source-type="filters.source_type.value"/>
      </template>
      <template v-slot:landingObject.statementsFile="{ row }">
        <sources :sources="[row.landingObject.statementsFile]" :source-type="filters.source_type.value"/>
      </template>
      <!-- Status -->
      <template v-slot:status="{ row }">
        <status
          v-if="row.userActionStatus === inlineRowStatus.ERROR"
          :text="$t('inline_error') | capitalize"
          :icon="['fas', 'exclamation-circle']"
          inline
          color="red"
        />
        <status
          v-else-if="row.userActionStatus === inlineRowStatus.SUCCESS"
          :text="$t('inline_success') | capitalize"
          :icon="['fas', 'check-circle']"
          inline
          color="green"
        />
        <status
          v-else-if="(
            isInProgressFrontend(row.id) ||
            (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))
          ) && !isSolved(row.id)"
          :text="$t('in_progress') | capitalize"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-else-if="(isPending([row.status]) || isReleasedFrontend(row.id)) && !isSolved(row.id)"
          :text="$t('conflict') | capitalize"
          :icon="['fas', 'exclamation-circle']"
          color="red"
        />
        <status
          v-else
          :text="$t('resolved') | capitalize"
          :icon="['fas', 'check-circle']"
          color="green"
        />
      </template>
    </t-table>
  </div>
</template>

<script>
import { capitalize, flatten, get } from "lodash"
import {
  conflictTypeCodes,
  inlineRowStatus,
  metadataConflictTypeCodes,
  perPageItemsSm,
  sourceTypes,
  statementConflictTypeCodes,
  userActionNotifications,
  userActionStatuses
} from "@/constants"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import Pagination from "@/components/Pagination"
import Sources from "@/components/Sources"
import { listRouteMixin } from "@/utils/mixins"
import { mapMutations } from "vuex"
import { mapState } from "vuex"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "InlineConflictTable",
  components: { NamesListModal, Sources, Pagination },
  mixins: [websocketsMixin, listRouteMixin],
  props: {
    panesHeightFn: {
      type: Function,
    },
    headings: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    actionStatus: {
      type: Boolean,
      required: true,
    },
    actionTypes: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    sourceId: {
      type: String,
    },
    solvedManuallyParent: {
      type: Array,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    defaultPerPageItemsLimit: {
      type: Number,
      required: false,
      default: perPageItemsSm,
    },
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    async undo (uuid) {
      this.loading = true

      try {
        if (this.actionTypes.includes(metadataConflictTypeCodes.MAKERS_METADATA_CONFLICT) || this.actionTypes.includes(metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT)) {
          await this.$api.actions.undoRightsHolderMatchingAction(uuid)
        } else {
          await this.$api.actions.undoRecordingMatchingAction(uuid)
        }
        try {
          this.success(this.$t("undone_success"))
          this.getData()
        } catch (e) {
          this.error(capitalize(this.$t("error_occurred")))
        }
      } catch (error) {
        if (error.response) {
          this.error(`${this.$t("undone_error")}: ${JSON.stringify(error.response.data)}`)
        } else {
          this.error(capitalize(this.$t("error_occurred")))
        }
        this.loading = false
      }
    },
    selectNext () {
      let conflict = this.$refs.conflictTable.nextRow()
      this.selectConflict(conflict)
    },
    selectPrevious () {
      let conflict = this.$refs.conflictTable.prevRow()
      this.selectConflict(conflict)
    },
    getSources (row) {
      if (this.actionTypes.includes(metadataConflictTypeCodes.MAKERS_METADATA_CONFLICT) || this.actionTypes.includes(metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT)) {
        if (this.filters.source_type.value === sourceTypes.REPERTOIRE_FILE && row.landingObject.sources) {
          return row.landingObject.sources.files
        }
        if (this.filters.source_type.value === sourceTypes.RIGHTSHOLDER_BATCH) {
          return row.landingObject.sources ? row.landingObject.sources.inputSources : []
        }
      }
    },
    onPagination (page) {
      this.tablePage = Number(page)
      this.$refs.conflictTable.page = Number(page)
      this.$emit("pagination", Number(page))
      this.getData()
    },
    onLimit (limit) {
      this.tablePerPage = limit
      this.$emit("limit", limit)
      this.getData()
    },
    onLoaded () {
      this.loading = false
      if (this.panesHeightFn) {
        this.panesHeightFn()
      }
      this.$emit("loaded")
    },
    onLoading () {
      this.loading = true
      this.$emit("selected", null)
      this.selectedConflictUuid = null
      this.selectedRowId = null
    },
    getData () {
      this.$refs.conflictTable.getData()
    },
    selectConflict (conflict) {
      if (conflict && conflict.id === this.selectedRowId) {
        this.$emit("selected", null)
        this.selectedRowId = null
        this.selectedConflictUuid = null
      } else if (this.filters.source_type.value === sourceTypes.PAYMENT_FILE) {
        this.$emit("selected", conflict ? conflict : null)
        this.selectedRowId = conflict ? conflict.id : null
      } else if (!conflict || this.selectedRowId !== conflict.id) {
        this.$emit("selected", conflict ? conflict : null)
        this.selectedConflictUuid = conflict ? conflict.userActionId : null
        this.selectedRowId = conflict ? conflict.id : null
      }
    },
  },
  data () {
    return {
      flatten,
      parseDate,
      tablePage: Number(get(this.$route.query, "tablePage", 1)),
      loading: false,
      count: 0,
      data: [],
      selectedConflictUuid: null,
      selectedRowId: null,
      tablePerPage: Number(get(this.$route.query, "tablePerPage", perPageItemsSm)),
      userActionStatuses,
      inlineRowStatus,
      sourceTypes,
      // WS Values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.USER_ACTION,
      storeStatusesKey: "wsUserActionStatuses",
      solvedManually: this.solvedManuallyParent,
      shortcuts: {
        "down": this.selectNext,
        "up": this.selectPrevious,
        "r": this.getData,
        "shift+/": () => this.$bvModal.show("shortcuts-modal"),
      },
      shortcutDescriptions: {
        "up": {
          "icon": ["far", "arrow-alt-square-up"],
          "description": this.$t("matching_conflicts_up_key")
        },
        "down": {
          "icon": ["far", "arrow-alt-square-down"],
          "description": this.$t("matching_conflicts_down_key")
        },
        "r": {
          "label": "R",
          "description": this.$t("matching_conflicts_r_key")
        },
        "?": {
          "icon": ["far", "question-square"],
          "description": this.$t("matching_conflicts_question_key")
        }
      }
    }
  },
  computed: {
    ...mapState("user", ["permissions"]),
    isRecordingRelated () {
      return [
        metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT,
        statementConflictTypeCodes.STATEMENT_RECORDING_MATCHING
      ].filter(v => this.actionTypes.includes(v)).length > 0
    },
    sortable () {
      return [
        "title","artist","isrc","fixationCountry","fixationYear","type","name","ipn",
        "landingObject.title", "landingObject.name","landingObject.artist","landingObject.isrc","landingObject.ipn","status",
        "landingObject.sources", "landingObject.amountGross","landingObject.sisterSociety","landingObject.statementsFile",
      ]
    },
    options () {
      return {
        sortable: this.sortable,
        headings: this.headings,
        width: {
          actionStatus: 40,
        },
        requestKeys: {},
        rowClasses: (row) => {
          let classes = ["clickable"]
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          if (this.selectedRowId === row.id) {
            classes.push("tabulator-selected")
          }
          if (row.userActionStatus === inlineRowStatus.ERROR) {
            classes.push("row-error")
          }
          if (row.userActionStatus === inlineRowStatus.SUCCESS) {
            classes.push("row-success")
          }
          return classes
        },
        perPage: this.tablePerPage,
        initialPage: this.tablePage,
        responseAdapter ( { data } ) {
          let parentComponent = this.$parent
          parentComponent.selectConflict(null)
          parentComponent.count = data.count
          parentComponent.data = data.results
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction () {
          let parentComponent = this.$parent
          if (parentComponent.$refs.conflictsTable) {
            parentComponent.$refs.conflictsTable.$refs.table.data = []
            parentComponent.$refs.conflictsTable.$refs.table.count = 0
            parentComponent.$refs.conflictsTable.$refs.table.loading = true
          }

          let params = { ...this.$route.query }          
          params.page = parentComponent.tablePage,
          params.limit = parentComponent.tablePerPage,
          params.type = parentComponent.actionTypes,
          params.status = parentComponent.filters.status.value

          if (params.fixation_year_min && params.fixation_year_max) {
            params.fixation_year_from = params.fixation_year_min
            params.fixation_year_to = params.fixation_year_max
            delete params["fixation_year_min"]
            delete params["fixation_year_max"]
          }
          if (parentComponent.filters.source_type.value === sourceTypes.PAYMENT_FILE) {
            params.status = parentComponent.actionStatus ? [userActionStatuses.DONE] : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
          } else {
            if (parentComponent.filters.status.value) {
              params.status = parentComponent.filters.status.value
            }
          }
          if (parentComponent.filters.repertoireFile.value) {
            params.repertoire_file = parentComponent.filters.repertoireFile.value
          }

          if (parentComponent.sourceId) {
            params[parentComponent.filters.source_type.value] = parentComponent.sourceId
          } else {
            params["input_source_type"] = parentComponent.filters.source_type.value
          }
          if (parentComponent.filters.undo_available.value) {
            params.undo_available = parentComponent.filters.undo_available.value
            params.status = [userActionStatuses.DONE]
          }
          delete params["tablePerPage"]
          delete params["source_type"]
          if (params.status === null) {
            delete params["status"]
          }
          let requestFn = this.$api.actions.userActionsMetadataConflicts
          if (parentComponent.filters.source_type.value !== sourceTypes.PAYMENT_FILE) {
            if (parentComponent.filters.action_types.value.includes(conflictTypeCodes.RECORDING_CONFLICT)) {
              params.type = parentComponent.filters.type.value
              if (params.type === null){
                delete params["type"]
              }
              requestFn = this.$api.actions.repertoireMatchingActions
            } else if (
              parentComponent.filters.action_types.value.includes(conflictTypeCodes.PERFORMERS_CONFLICT) ||
              parentComponent.filters.action_types.value.includes(conflictTypeCodes.MAKERS_CONFLICT)
            ) {
              requestFn = this.$api.actions.rightsHolderMatchingActions
            }
          }
          return requestFn(params)
        },
      }
    },
    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
  },
  watch: {
    selectedConflictUuid (newVal, oldVal) {
      if (oldVal) {
        this.onClosed(oldVal)
      }
      if (newVal) {
        this.onOpened(newVal)
      }
    },
    actionTypes () {
      this.tablePage = 1
      this.getData()
    },
    "filters.source_type.value": function () {
      this.getData()
    },
    actionStatus () {
      this.getData()
    },
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.conflictTable.$children[0], true)
  },
  created () {
    this.tablePage = 1
    this.tablePerPage = this.defaultPerPageItemsLimit
  }
}
</script>
<style lang="scss">
.shortcut-key {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 2px black solid;
  border-radius: 2px;
  line-height: 17px;
  padding-left: 2px;
}
.pagination-count .clickable:hover {
  color: var(--gray) !important;
}
.table-responsive {
  .table {
    tr {
      &.hover-highlight {
        &:hover {
          cursor: pointer;
          background-color: $red-light;
        }
      }
      &.highlight {
        background-color: $red-light;
      }
    }
  }
}
</style>
