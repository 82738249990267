function getGenreType (genreType) {
  switch (genreType) {
  case "P":
    return "Popular"
  case "J":
    return "Jazz"
  case "C":
    return "Classical"
  }
}

function getSubGenre (subGenre) {
  switch (subGenre) {
  case "S":
    return "small"
  case "M":
    return "medium"
  case "L":
    return "large"
  case "XL":
    return "very large"
  }
}

function getType (type) {
  return type === "SR" ? "Sound Recording" : "Music Video"
}

function getMetadata (extras, tag) {
  if (extras) {
    return extras.reduce((current, next) => {
      if (next.tag === tag) {
        current.push(next.value)
      }
      return current.sort()
    }, [])
  }
  return []
}

export { getGenreType, getSubGenre, getType, getMetadata }
