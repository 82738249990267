import { some } from "lodash"
const state = {
  adding: false,
  deleted: [],
  deleteMode: false,
  existChanges: false,
  validationError: false,
  postValues: [],
  tariffs: [],
  thresholds: [],
}

const mutations = {
  setThresholds (state, data) {
    state.thresholds = data
  },
  setTariffs (state, data) {
    // first, reset
    this.tariffs = []

    var resultSet = []
    data.forEach((copyright) => {
      copyright.tariffs.forEach((tariff) => {
        resultSet.push({
          copyrightName: copyright.name,
          tariffName: tariff.name,
          tariffCode: tariff.code,
          tariffId: tariff.id,
          eligibilityPeriods: tariff.eligibilityPeriods.map((d) => {
            return {
              id: d.id,
              eligibilityParam: d.eligibilityParameter,
              startDate: d.span.lower,
              endDate: d.span.upper,
            }
          }),
          dirty: false,
        })
      })
    })
    state.tariffs = resultSet
  },
  setChangeFlag (state, val) {
    state.existChanges = val
  },
  deleteParam (state, data) {
    state.deleted.push(data)
  },
  setDeleteMode (state, val) {
    if (val) {
      state.deleteMode = val
    } else {
      state.deleteMode = !state.deleteMode
    }

    if (!state.deleteMode) {
      state.deleted = []
      state.existChanges = false
    }
  },
  setParamValue (state, data) {
    const { tariffId, paramId, paramValue } = data
    state.tariffs.forEach((tariff) => {
      tariff.eligibilityPeriods.forEach((period) => {
        if (tariff.tariffId === tariffId && period.id === paramId) {
          period.eligibilityParam = paramValue
        }
      })
    })
  },
  setParamDate (state, data) {
    const { tariffId, paramId, dateType, dateValue } = data
    // find tariff and param that needs changing
    state.tariffs.forEach((tariff) => {
      tariff.eligibilityPeriods.forEach((period) => {
        if (tariff.tariffId === tariffId && period.id === paramId) {         
          period[dateType] = dateValue

          if (dateValue == null) {
            state.existChanges = true
          }
        }
      })
    })
  },
  addNewParameter (state, tariffId) {
    state.adding = true
    state.deleteMode = false
    state.tariffs.forEach((tariff) => {
      let maxParamId = 0

      tariff.eligibilityPeriods.forEach((ep) => {
        if (ep.id > maxParamId) {
          maxParamId = ep.id
        }
      })

      if (tariff.tariffId === tariffId) {
        tariff.eligibilityPeriods.push({
          id: maxParamId + 1,
          eligibilityParam: "",
          startDate: "",
          endDate: "",
        })
      }
    })
  },
  setAddingMode (state, data) {
    state.adding = data
  },
  setErrors (state, data) {
    state.errors.push(data)
  },
}
const actions = {
  getCopyrights ({ commit }) {
    this._vm.$api.copyrights.tariffThresholdsList().then(response => {
      commit("setThresholds", response.data)
    })
    return this._vm.$api.copyrights.copyrightsTariffs().then((data) => {
      commit("setTariffs", data.data)
    })
  },
  deleteParam ({ commit }, data) {
    // check if already deleted so we don't
    // push duplicates to the delete cart
    if (!some(state.deleted, data)) {
      commit("deleteParam", data)
      state.existChanges = false
    }
  },
  postCopyrights () {
    let postValues = []
    let emptyEligibilityParameterErrors = []
    state.tariffs.forEach((tariff) => {
      const postValue = {
        id: tariff.tariffId,
        eligibilityPeriods: [],
      }
      tariff.eligibilityPeriods.forEach((ep) => {
        if (!ep.eligibilityParam && !emptyEligibilityParameterErrors.includes(tariff.tariffCode)) {
          emptyEligibilityParameterErrors.push(tariff.tariffCode)
        }
        if (!state.deleted.some(del => tariff.tariffId === del.tariffId && ep.id === del.paramId)) {
          postValue.eligibilityPeriods.push({
            eligibility_parameter: ep.eligibilityParam,
            span: {
              lower: ep.startDate ? ep.startDate : null,
              upper: ep.endDate ? ep.endDate : null,
            }
          })
        }
      })
      postValues.push(postValue)
    })
    state.postValues = postValues
    if (emptyEligibilityParameterErrors.length > 0) {
      this.setErrors({
        success: false,
        message: emptyEligibilityParameterErrors.join(", ") + "contains periods with empty eligibility parameter.",
      })
    }
    return this._vm.$api.copyrights.createTariffEligibilityPeriods(postValues)
  },
}

const getters = {
  getParamData (state) {
    return function (data) {
      const { tariffId, paramId } = data

      let tariff = state.tariffs.filter((t) => {
        return t.tariffId === tariffId
      })[0]

      let param = tariff.eligibilityPeriods.filter((ep) => {
        return ep.id === paramId
      })[0]

      return param
    }
  },
}
export const copyrights = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
