<template>
  <div>
    <list-layout
        :title="$t('rightsholders') | capitalize"
        :apply-filters="applyFilters"
        :reset-filters="resetRhFilters"
        :filters-pending="filtersPending"
        :filters-applied="filtersApplied"
    >
      <!-- Search -->
      <template v-slot:search>
        <form-field
          :placeholder="$t('search') | capitalize"
          :enter-action="applyFilters"
          v-model="filters.search.value"
          :icon="['fas', 'search']"
          :disabled="viewingCart"
        />
      </template>
      <template v-slot:filters>
        <modal
          id="rightsholders-link-modal"
          :customTitle="mergeButtonText | startcase"
          modal-class="custom-modal edit"
          modalType="edit"
          size="xl"
          centered
          hide-footer
        >
          <RightsHoldersLinkForm :selectedObjects="selectedData" @linked="onLinked" />
        </modal>
        <b-row align-v="end" align-h="end">
          <b-col cols="5" class="text-right">
            <!-- Type filter -->
            <b-form-group>
              <b-form-radio-group
                v-model="filters.type.value"
                buttons
                button-variant="outline-primary"
                name="radios-btn-outline"
                :disabled="viewingCart"
              >
                <b-form-radio :value="null">
                  {{ $t("all") | capitalize }} ({{ typeCounts.all }})
                </b-form-radio>
                <b-form-radio value="performer">
                  {{ $t("performer") | capitalize }} ({{ typeCounts.performer }})
                </b-form-radio>
                <b-form-radio value="maker">
                  {{ $t("maker") | capitalize }} ({{ typeCounts.maker }})
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <!--Collective filter-->
            <b-form-group
              :label="`${$t('filter_by_collective_society')}`"
              label-class="pb-0"
              v-show="permissions.actions.hasAllRhPermission"
              :disabled="viewingCart"
            >
              <b-form-select v-model="filters.member.value" :options="memberCollectiveOptions">
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <!--Status filter-->
            <b-form-group
              :label="`${$t('status')}` | capitalize"
              label-class="pb-0"
              class="mr-3"
              :disabled="viewingCart"
            >
              <b-form-select v-model="filters.status.value" :options="statusOptions">
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              reference="rightsholders"
              :page="page"
              :count="count"
              :loading="loading"
              :per-page-selector="!viewingCart"
              v-model="limit"
              @limit="onLimit"
            />
            <merge-summary
              class="ml-2"
              v-if="selectedData.length || viewingCart"
              :selectedObjects="selectedData"
              :clear="clearCart"
              :switchView="toggleCart"
              :buttonText="mergeButtonText"
              badgeDisplayField="name"
            ></merge-summary>
          </div>
        </div>
      </template>

      <!-- Merge Details -->
      <template v-slot:merge v-if="permissions.actions.canMergeRightsHolders">
        <div class="row align-items-center">
          <div class="col d-flex justify-content-end">
            <b-button
              size="sm"
              :disabled="selectedData.length < 2"
              variant="outline-primary"
              @click="mergeRightsHolders()"
              class="ml-2"
            >
              <fa-icon size="sm" :icon="['fa', 'blender']" />
              {{ $t("merge") | capitalize }}
            </b-button>
          </div>
        </div>
      </template>

      <!-- Table -->
      <template v-slot:table>
        <v-server-table
          ref="rightsholders"
          :columns="getColumns()"
          :options="options"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <!-- ResoundID header -->
          <template slot="h__resoundId" v-if="!viewingCart">
            <div class="d-flex">
              <b-checkbox
                v-model="options.allSelected"
                @change="selectPage"
                :disabled="!options.allSameType"
                v-if="permissions.actions.canMergeRightsHolders"
              />
              <span>{{ $t("resound_id") }}</span>
            </div>
          </template>
          <!-- ResoundID -->
          <template v-slot:resoundId="{ row }">
            <div class="d-flex">
              <b-checkbox
                :disabled="preventMerge(row)"
                v-if="permissions.actions.canMergeRightsHolders"
                v-model="selectedData"
                :value="row"
                @change="mergeChecked(row)"
              >
              </b-checkbox>
              <database-link
                :type="linkTypes.RIGHTS_HOLDER"
                :item-id="row.id"
              >
                {{ row.resoundId }}
              </database-link>
            </div>
          </template>

          <!-- Type -->
          <template v-slot:type="{ row }">
            {{ row.type | capitalize }}
          </template>

          <!-- Collective -->
          <template v-slot:membership="{ row }">
            <div v-if="!row.membership">Unrepresented</div>
            <div v-else>
              <div v-for="(col, i) in row.membership" :key="`col-${i}`">
                {{ col.memberCollective.name }}
              </div>
            </div>
          </template>

          <!-- Status -->
          <template v-slot:membershipStatus="{ row }">
            <div v-for="(col, i) in row.membership" :key="`col-${i}`">
              <status
                v-if="col.status === 'active'"
                :text="$t('active') | capitalize"
                :icon="['fas', 'check-circle']"
                color="green"
              />
              <status
                v-if="col.status === 'pending'"
                :text="$t('pending') | capitalize"
                :icon="['fas', 'question-circle']"
                color="orange"
              />
              <status
                v-if="col.status === 'inactive'"
                :text="$t('inactive') | capitalize"
                :icon="['fas', 'exclamation-circle']"
                color="red"
              />
            </div>
          </template>

          <!-- Country of Residency -->
          <template v-slot:countryOfResidency="{ row }">
            <div v-if="row.countryOfResidency">{{ row.countryOfResidency.name }}</div>
          </template>
        </v-server-table>
      </template>
      <router-view name="modal"></router-view>
    </list-layout>
  </div>
</template>

<script>
import { capitalize, get, upperCase } from "lodash"
import { cartMixin, listRouteMixin } from "@/utils/mixins"
import { getPerPageItemDefaults, linkTypes, rightsholderDetailTabs } from "@/constants"
import { mapGetters, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink"
import Pagination from "@/components/Pagination"
import RightsHoldersLinkForm from "./RightsHoldersLinkForm"
import { mapMutations } from "vuex"

export default {
  name: "RightsHoldersList",
  mixins: [cartMixin, listRouteMixin],
  components: {
    DatabaseLink,
    RightsHoldersLinkForm,
    Pagination,
  },
  methods: {
    ...mapMutations("consts", ["setMemberCollectives"]),
    ...mapMutations("mergers", ["resetState", "setMergeCart"]),
    recomputeAllSelected (newVal = false) {
      let selectedData = newVal || this.selectedData
      if (this.$refs.rightsholders && this.$refs.rightsholders.data.length) {
        this.options.allSelected = this.$refs.rightsholders.data.every(d => {
          return selectedData.map(r => r.id).includes(d.id)
        })
      } else {
        this.options.allSelected = false
      }
    },
    recomputeAllSameType () {
      if (this.$refs.rightsholders && this.$refs.rightsholders.data.length) {
        let listSameType =
          [...new Set(this.$refs.rightsholders.data.map(d => d.type))].length === 1
        let differentFromSelected = this.selectedData.length
          ? this.$refs.rightsholders.data.some(d => d.type !== this.selectedData[0].type)
          : false
        this.options.allSameType = listSameType && !differentFromSelected
      } else {
        this.options.allSameType = false
      }
    },
    selectPage () {
      if (!this.options.allSelected) {
        this.selectedData.push(
          ...this.$refs.rightsholders.data.filter(
            d => !this.selectedData.map(r => r.id).includes(d.id)
          )
        )
      } else {
        this.$refs.rightsholders.data.forEach(d => this.removeSelected(d.id))
      }
    },
    mergeChecked (row) {
      this.setMergeCart({
        cart: "rightsHolderMergeCart",
        data: row,
      })
    },
    preventMerge (row) {
      return this.selectedData.length > 0
        ? this.selectedData.some(rh => rh.type !== row.type)
        : false
    },
    onLoaded ({ data }) {
      this.recomputeAllSelected()
      this.recomputeAllSameType()
      this.count = data.count
      this.summary = data.summary
      this.memberCollectiveOptions = this.memberCollectives.map(x => ({
        text: x.name,
        value: x.uuid,
      }))
      this.loading = false

      this.memberCollectiveOptions.push({
        text: "Unrepresented",
        value: "unrepresented",
      })
    },
    getData () {
      this.$refs.rightsholders.getData()
    },
    mergeRightsHolders () {
      this.$bvModal.show("rightsholders-link-modal")
    },
    getColumns () {
      let columns =  ["resoundId", "name", "type", "membership", "membershipStatus"]
      const type = this.filtersPending ? this.prevRHType : this.filters.type.value
      if (type !== "maker") {
        columns.splice(2, 0, "ipn", "dateOfBirth", "countryOfResidency")
      }
      return columns
    },
    resetRhFilters (applyDefaults = false) {
      this.prevRHType = null
      this.resetFilters(applyDefaults)
    }
  },
  data () {
    return {
      linkTypes,
      page: 1,
      loading: false,
      viewingCart: false,
      pageNumber: 1,
      selectedData: [],
      statusOptions: [
        { value: "active", text: capitalize(this.$t("active")) },
        { value: "pending", text: capitalize(this.$t("pending")) },
        { value: "inactive", text: capitalize(this.$t("inactive")) },
      ],
      typeCounts: {},
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      summary: { active: 0, pending: 0, inactive: 0, internal: 0, external: 0 },
      prevRHType: null,
      options: {
        allSelected: false,
        allSameType: false,
        sortable: ["name"],
        headings: {
          resoundId: capitalize(this.$t("resound_id")),
          name: capitalize(this.$t("rightsholder_name")),
          ipn: upperCase(this.$t("ipn")),
          dateOfBirth: capitalize(this.$t("date_of_birth")),
          countryOfResidency: capitalize(this.$t("country_of_residency")),
          "membership.memberCollective.name": this.$t("collective_society"),
          type: capitalize(this.$t("type")),
          "membership.status": capitalize(this.$t("status"))
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          let componentData = this.$parent.$parent.$parent
          componentData.typeCounts = data.typeSummary
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent

          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          componentData.pageNumber = this.page
          let status = get(queryParams, "status", null)
          if (typeof status === "string") {
            queryParams["status"] = [status]
          }
          if (componentData.viewingCart) {
            return componentData.filterCartItems("rightsholder")
          }
          return this.$api.rightsholders.rightsholdersList(queryParams)
        },
      },
      filters: {
        search: { value: "", defaultValue: "" },
        type: { value: null, defaultValue: null },
        status: { value: null, defaultValue: null },
        member: { value: null, defaultValue: null },
      },
      memberCollectiveOptions: [],
      rightsholderDetailTabs,
    }
  },
  computed: {
    ...mapGetters("user", ["isCurrentUser"]),
    ...mapState("consts", ["memberCollectives"]),
    ...mapState("mergers", ["rightsHolderMergeCart"]),
    ...mapState("user", ["permissions"]),
    makerMerging () {
      return this.selectedData.every(rh => rh.type === "maker")
    },
    mergeButtonText () {
      return this.makerMerging ? this.$t("merge_makers") : this.$t("merge_performers")
    },
  },
  watch: {
    selectedData (newVal) {
      this.recomputeAllSelected(newVal)
    },
    "filters.type.value": function (newVal, oldVal){
      if (!this.filtersPending && this.filtersApplied) {
        this.prevRHType = oldVal
      }
    },
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.rightsholders.$children[0])
  }
}
</script>
