const unaccent = function (str) {
  if (typeof str === "string") {
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  } else {
    return str
  }
}

export {
  unaccent
}
