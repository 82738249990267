import { Module } from "tabulator-tables"

class VueWatchers extends Module {
  constructor (table) {
    super(table)
  }
  initialize () {
    this.registerComponentFunction("cell", "setWatcher", this.setCellWatcher)
    this.registerComponentFunction("cell", "hasWatcher", this.hasCellWatcher)
    this.registerComponentFunction("row", "setRowClassWatcher", this.setRowClassWatcher)
    this.registerComponentFunction("row", "hasRowClassWatcher", this.hasRowClassWatcher)
    this.subscribe("cell-init", this.initializeCell)
    this.subscribe("row-init", this.initializeRow)
    this.subscribe("cell-delete", this.deleteCell)
    this.subscribe("row-delete", this.deleteRow)
  }

  initializeCell (cell) {
    cell.modules.vueWatchers = {
      watcher: null
    }
  }

  initializeRow (row) {
    row.modules.vueWatchers = {
      rowClassWatcher: null
    }
  }

  deleteCell (cell) {
    if (cell.modules.vueWatchers.watcher) {
      cell.modules.vueWatchers.watcher()
    }
  }

  deleteRow (row) {
    if (row.modules.vueWatchers.rowClassWatcher) {
      row.modules.vueWatchers.rowClassWatcher()
    }
  }

  setCellWatcher (cell, watcher) {
    if (cell.modules.vueWatchers.watcher) {
      cell.modules.vueWatchers.watcher()
    }
    cell.modules.vueWatchers.watcher = watcher
  }

  hasCellWatcher (cell) {
    return cell.modules.vueWatchers.watcher !== null
  }

  hasRowClassWatcher (row) {
    return row.modules.vueWatchers.rowClassWatcher !== null
  }

  setRowClassWatcher (row, watcher) {
    row.modules.vueWatchers.rowClassWatcher = watcher
  }

}


VueWatchers.moduleName = "vueWatchers"


export {
  VueWatchers
}
