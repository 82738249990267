<template>
  <div>
    <div
        v-for="performer_data in data"
        :key="performer_data.performer.id"
    >
      <h2 class="ml-2 text-red">
        {{ performer_data.performer.resoundId }} -
        {{ performer_data.performer.name }}
      </h2>
      <b-card class="rightsholder-details">
        <rightsholder-table
            :data="[performer_data.performer]"
            :fields="primaryFields">
        </rightsholder-table>
        <rightsholder-table
          :data="[performer_data.performer]"
          :fields="secondaryFields"
        >
        </rightsholder-table>
      </b-card>
      <h3 class="ml-2 text-red"> Source Metadata </h3>
      <rightsholder-table
        class="rightsholder-sources"
        :data="duplicateDifferentSourcesData(
            getSources(performer_data.sources)
        )"
        :fields="sourceFields"
      />
      <hr style="background-color: var(--secondary);">
    </div>
    <h2 class="text-red">{{ $t("comments") | startcase }}</h2>
    <comments
      :extra-content="{conflicted_ipn: selectedRow.conflictedIpn}"
      :createEndpoint="$api.comments.usageIpnCommentCreate"
      :listEndpoint="$api.comments.usageIpnCommentList"
      :uuid="selectedRow.id"
      :editing=false
      :deleting=false
    />
  </div>
</template>

<script>

import { startCase, upperCase } from "lodash"
import RightsholderTable from "@/pages/Rightsholders/RightsHolderDetail/RightsholderTable"
import { sourceTypes } from "@/constants"

export default {
  name: "DuplicateIpnConflictsDetail",
  components: {
    RightsholderTable
  },
  mixins: [],
  props: {
    selectedRow: {
      type: Object,
      required: false,
    },
    onlyConflictedSource: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      data: [],
      primaryFields: [
        {
          key: "resoundId",
          label: startCase(this.$t("resound_id")),
          width: "10%",
        },
        {
          key: "name",
          label: startCase(this.$t("rightsholder_name")),
          width: "15%",
          source: true,
        },
        {
          key: "ipn",
          label: upperCase(this.$t("ipn")),
          performerOnly: true,
          width: "10%",
          source: true,
        },
        {
          key: "aliases",
          label: startCase(this.$t("aliases")),
          width: "15%",
          source: true,
        },
        {
          key: "dateOfBirth",
          label: startCase(this.$t("date_of_birth")),
          performerOnly: true,
          width: "10%",
          source: true,
        },
        {
          key: "dateOfDeath",
          label: startCase(this.$t("date_of_death")),
          performerOnly: true,
          width: "10%",
          source: true,
        },
        {
          key: "gender",
          label: startCase(this.$t("gender")),
          performerOnly: true,
          width: "5%",
          source: true,
        },
      ],
      secondaryFields: [
        {
          key: "mandates",
          label: startCase(this.$t("mandates")),
          secondary: true,
          width: "10%",
        },
        {
          key: "type",
          label: startCase(this.$t("type")),
          secondary: true,
          width: "7%",
        },
        {
          key: "nationalities",
          label: startCase(this.$t("nationality")),
          secondary: true,
          width: "10%",
          source: true,
        },
      ],
      sourceFields: [
        {
          key: "memberCollectives",
          tdClass: "blue",
          label: startCase(this.$t("mc")),
          width: "15%",
        },
        {
          key: "proprietaryId",
          label: startCase(this.$t("proprietary_id")),
          width: "9%",
        },
        {
          key: "name",
          label: startCase(this.$t("rightsholder_name")),
          width: "14%",
          source: true,
        },
        {
          key: "ipn",
          label: upperCase(this.$t("ipn")),
          performerOnly: true,
          width: "10%",
          source: true,
        },
        {
          key: "aliases",
          label: startCase(this.$t("aliases")),
          performerOnly: true,
          width: "15%",
          source: true,
        },
        {
          key: "dateOfBirth",
          label: startCase(this.$t("date_of_birth")),
          performerOnly: true,
          width: "8%",
          source: true,
        },
        {
          key: "dateOfDeath",
          label: startCase(this.$t("date_of_death")),
          performerOnly: true,
          width: "10%",
          source: true,
        },
        {
          key: "gender",
          label: startCase(this.$t("gender")),
          secondary: true,
          width: "5%",
          source: true,
        },
        {
          key: "nationalities",
          label: startCase(this.$t("nationality")),
          secondary: true,
          width: "9%",
          source: true,
        },
        {
          key: "sources",
          label: "",
          width: "15%",
        }
      ]
    }
  },
  methods: {
    getData () {
      if (this.selectedRow) {
        let queryParams = { conflicted_ipn: this.selectedRow.conflictedIpn }
        this.$api.actions.duplicatedIpnDetail(
          this.selectedRow.id,
          queryParams
        ).then(response => {
          this.data = response.data
        })
      }
    },
    getSources (sources) {
      if (this.onlyConflictedSource) {
        return sources.filter(s => s.ipn === this.selectedRow.conflictedIpn)
      }
      return sources
    },
    duplicateDifferentSourcesData (sources) {
      let landingRightsholders = []
      if (sources) {
        sources.forEach(item => {
          let duplicatedItems = []
          if (item.sources.files.length > 0) {
            duplicatedItems.push({ ...item, sources: item.sources.files, type: sourceTypes.REPERTOIRE_FILE })
          }
          if (item.sources.inputSources.length > 0) {
            duplicatedItems.push({ ...item, sources: item.sources.inputSources, type: sourceTypes.RIGHTSHOLDER_BATCH })
          }
          landingRightsholders = landingRightsholders.concat(duplicatedItems)
        })
      }
      return landingRightsholders
    },
  },
  mounted () {
    this.getData()
  },
  watch: {
    selectedRow () {
      this.getData()
    }
  },
}
</script>

<style lang="scss" scoped>
.rightsholder-details {
  margin-top: 20px;
  border: none;
  background: #fafafa;

  ::v-deep table {
    thead {
      background: transparent !important;

      th {
        font-weight: bold;
        background: transparent !important;
        padding: 0;
        color: #2b2b2b !important;
      }
    }
    tbody {
      td {
        padding: 0;
      }
    }
  }
  .meta-data-row {
    padding: 20px;
  }

  ::v-deep table td th {
    padding: 0;
  }
}

.rightsholder-sources {
  :deep thead {
    background: #ededed !important;
  }
  :deep th {
    background: transparent !important;
    color: #2b2b2b !important;
  }
  :deep th, :deep td {
    padding: 10px !important;
  }
}
</style>