import { get } from "lodash"

const state = {
  challengeButtonEnabled: false,
  editEnabled: {},
  editMode: false,
  editModePerformers: false,
  editModeOwners: false,
  firstMakerSelected: {},
  initialRecordingData: {},
  performersUpdate: {},
  ownersUpdate: {},
  recordingData: {},
  recordingDataCount: 0,
  recordingMetadata: [],
  recordingPerformers: {},
  recordingOwners: {},
  recordingMetadataOptions: {},
  selectCancelled: false,
  selecting: false,
}
const mutations = {
  resetState () {
    Object.assign(state, {
      initialRecordingData: {},
      recordingData: {},
      recordingMetadata: [],
      recordingPerformers: {},
      recordingOwners: {},
      selecting: false,
      selectCancelled: false,
      challengeButtonEnabled: false,
      editMode: false,
      editModePerformers: false,
      editModeOwners: false,
      editEnabled: {},
      performersUpdate: {},
      ownersUpdate: {},
    })
  },
  restartComments (state, payload) {
    get(state, payload.data, []).map((r) => {
      r.selectActive = false
      if (payload.toggleShowDetails) {
        r._showDetails = false
      }
      r.comment = null
    })
  },
  toggleSelecting () {
    state.selectCancelled = state.selecting ? !state.selectCancelled : false
    state.selecting = !state.selecting
  },
  setEditMode (state, value) {
    state.editMode = value
  },
  setEditModePerformers (state, value) {
    state.editModePerformers = value
  },
  setEditModeOwners (state, value) {
    state.editModeOwners = value
  },
  setSelectActive (state, payload) {
    get(state, payload.data, [])
      .filter((r) => {
        return get(r, payload.fieldIdentifier) === payload.identifier
      })
      .map((r) => {
        r.selectActive = !r.selectActive
        if (payload.added != null) {
          r._showDetails = payload.added
        }
      })
  },
  // Specific mutations for checking if all sources selected are also commented
  checkPerformersChallengeButton (state, recordingId) {
    let selectedSources = state.recordingPerformers[recordingId].sources
      .map((s) => s.landingPerformers.filter((lp) => lp.selectActive))
      .flat()
    let commentedSources = state.recordingPerformers[recordingId].sources
      .map((s) => s.landingPerformers.filter((lp) => lp.comment))
      .flat()
    state.challengeButtonEnabled = selectedSources.length
      ? selectedSources.length === commentedSources.length
      : false
  },
  checkOwnersChallengeButton (state, recordingId) {
    let selectedSources = state.recordingOwners[recordingId].sources
      .map((s) => s.landingShares.filter((ls) => ls.selectActive))
      .flat()
    let commentedSources = state.recordingOwners[recordingId].sources
      .map((s) => s.landingShares.filter((ls) => ls.comment))
      .flat()
    state.challengeButtonEnabled = selectedSources.length
      ? selectedSources.length === commentedSources.length
      : false
  },
  setChallengeButton (state, value) {
    state.challengeButtonEnabled = value
  },
  setPerformersUpdate (state, value) {
    state.performersUpdate = value
  },
  setOwnersUpdate (state, value) {
    state.ownersUpdate = value
  },
  setComments (state, payload) {
    let identifier = Object.keys(payload.comments)
    identifier.forEach((id) => {
      get(state, payload.data, []).filter((d) => get(d, payload.fieldIdentifier) === id).forEach((row) => {
        row.comment = row.selectActive ? payload.comments[id] : null
      })
    })
  },
  setEditField (state, data) {
    let { field, value } = data
    state.editEnabled[field] = value
  },
  resetEditIcon (state) {
    state.editEnabled = { ...state.recordingData }
    Object.keys(state.editEnabled).map((e) => (state.editEnabled[e] = false))
  },
  cancelAllEdits (state) {
    state.editEnabled = { ...state.recordingData }
    Object.keys(state.editEnabled).map((e) => (state.editEnabled[e] = false))

    state.recordingData = { ...state.initialRecordingData }
  },
  modifyRecordingData (state, data) {
    let { field, value } = data
    state.recordingData[field] = value
  },
  setRecordingData (state, value) {
    state.recordingData = value
    state.recordingData.options = {}
    state.initialRecordingData = { ...value }
    state.initialRecordingData.options = {}

    // Populate editing array
    state.editEnabled = { ...state.recordingData }
    Object.keys(state.editEnabled).map((e) => (state.editEnabled[e] = false))
  },
  modifyRecordingDataOptions (state, data) {
    let { field, value } = data
    state.recordingData.options[field].unshift(value)
  },
  saveRecordingMetadata (state) {
    // Adds whatever option the user manually inserts
    // into the options list
    let keys = Object.keys(state.recordingData)
    keys.forEach((key) => {
      let fieldOptions = state.recordingData.options[key]
      let newValue = state.recordingData[key]

      if (fieldOptions && !fieldOptions.includes(newValue)) {
        Object.assign(
          state.recordingData.options[key],
          [newValue, ...fieldOptions].sort()
        )
      }
    })
  },
  setRecordingMetadata (state, data) {
    let value = data.results
    state.recordingDataCount = data.count
    state.recordingMetadata = value
    state.recordingMetadata.map((r) => {
      r.selectActive = false
      r._showDetails = true
    })
  },
  setRecordingMetadataOptions (state, value) {
    state.recordingMetadataOptions = value
    // For fields that have options available in the source
    // metadata, populate the state with these options and
    // save the initial values in case the user cancels
    let fieldsWithOptions = [
      "title",
      "artist",
      "isrc",
      "type",
      "duration",
      "fixationYear",
      "releaseYear",
      "fixationCountry",
    ]
    fieldsWithOptions.map((f) => {
      state.recordingData.options[f] = Array.from(
        new Set([state.recordingData[f], ...state.recordingMetadataOptions[f]])
      )
      state.initialRecordingData.options[f] = Array.from(
        new Set([state.recordingData[f], ...state.recordingMetadataOptions[f]])
      )
    })
  },
  setRecordingPerformers (state, value) {
    state.recordingPerformers[value.recordingId] = {
      lineupCategories: value.lineupCategories,
      sources: value.sources.filter((v) => {
        return v.landingPerformers && v.landingPerformers.length
      }),
    }
    state.recordingPerformers = JSON.parse(
      JSON.stringify(state.recordingPerformers)
    ) // Deep cloning
    state.recordingPerformers[value.recordingId].sources.map((s) => {
      s._showDetails = true
      s.landingPerformers.map((lp) => {
        lp._showDetails = true
        lp.selectActive = false
        lp.dateOfBirth = lp.dateAndPlaceOfBirth
          ? lp.dateAndPlaceOfBirth.date
          : null
        lp.sourceId = s.id
        lp.publicId = lp.id
        lp.id = lp.proprietaryId
      })
    })
  },
  setRecordingOwners (state, value) {
    state.recordingOwners[value.recordingId] = {
      owners: value.owners,
      sources: value.sources.filter((v) => {
        return v.landingShares && v.landingShares.length
      }),
    }
    state.recordingOwners = JSON.parse(
      JSON.stringify(state.recordingOwners)
    ) // Deep cloning
    state.recordingOwners[value.recordingId].sources.map((s) => {
      s._showDetails = true
      s.landingShares.map((ls) => {
        ls._showDetails = false
        ls.selectActive = false
      })
    })
  },
}
const actions = {
  // Proxy for two different actions: restarting comments and active records, and toggle select
  toggleChallenging (state, metadata) {
    if (!state.selecting) {
      state.commit("restartComments", metadata)
    }
    state.commit("toggleSelecting", metadata)
  },
  toggleSelecting (state, metadata) {
    state.commit("toggleSelecting", metadata)
  },
  getRecordingData (state, recordingId) {
    return this._vm.$api.repertoire.recordings(recordingId).then((d) => {
      state.commit("setRecordingData", d.data)
    })
  },
  postRecordingData ({ state }) {
    let data = state.recordingData
    return this._vm.$api.repertoire.recordingUpdate(data.id, {
      isrc: data.isrc ? data.isrc : null,
      title: data.title,
      artist: data.artist,
      duration: data.duration,
      type: data.type,
      fixation_country: data.fixationCountry ? data.fixationCountry.id : null,
      first_makers: data.firstMakers,
      genre_type: data.genreType,
      sub_genre: data.subGenre,
      fixation_year: data.fixationYear,
      release_year: data.releaseYear,
      releases: data.releases,
    })
  },
  async getRecordingMetadataOptions (state, recordingId) {
    let response = await this._vm.$api.repertoire.recordingMetadataOptions(recordingId)
    state.commit("setRecordingMetadataOptions", response.data)
  },
  getRecordingMetadata (state, recordingId) {
    this._vm.$api.repertoire.recordingMetadata(recordingId).then((d) => {
      state.commit("setRecordingMetadata", d.data.sources)
    })
  },
  getRecordingMetadataPagination (state, params) {
    let queryParams = { page: params.page }
    this._vm.$api.repertoire.recordingMetadata(params.recordingId, queryParams).then((d) => {
      state.commit("setRecordingMetadata", d.data)
    })
  },
  getRecordingPerformers (state, recordingId) {
    return this._vm.$api.repertoire.recordingPerformers(recordingId).then((d) => {
      d.data.recordingId = recordingId
      state.commit("setRecordingPerformers", d.data)
    })
  },
  getRecordingOwners (state, recordingId) {
    this._vm.$api.repertoire.recordingOwners(recordingId).then((d) => {
      d.data.recordingId = recordingId
      state.commit("setRecordingOwners", d.data)
    })
  },
  postRecordingChallenges () {
    let challenges = state.recordingMetadata.filter((r) => r.comment)
    let data = challenges.map((c) => {
      return {
        landing_recording_id: c.id,
        comments: [{ comment: c.comment }],
      }
    })

    let responses = []
    data.forEach((challenge) => {
      responses.push(
        this._vm.$api.repertoire.recordingChallengesCreate(challenge)
      )
    })

    return Promise.all(responses)
      .then((apiResponses) => {
        return apiResponses.map((r) => {
          return {
            status: r.status,
            recordingId: r.data.landingRecordingId,
          }
        })
      })
      .catch((e) => {
        return [
          {
            status: e.response.status,
            detail: e.response.data.detail,
            recordingId: JSON.parse(e.response.config.data).landingRecordingId,
          },
        ]
      })
  },
  postPerformerChallenges (state, recordingId) {
    let challenges = state.state.recordingPerformers[recordingId].sources
      .map((s) => s.landingPerformers.filter((lp) => lp.comment))
      .flat()
    // take only unique performer ids
    let data = [...new Map(
      challenges.map((c) => {
        return [
          c.publicId,
          {
            performer_id: c.publicId,
            comments: [{ comment: c.comment }],
            related_recording: recordingId,
          }
        ]
      })
    ).values()]

    let responses = []
    data.forEach((challenge) => {
      responses.push(
        this._vm.$api.repertoire.performerChallengesCreate(challenge)
      )
    })

    return Promise.all(responses)
      .then((apiResponses) => {
        return apiResponses.map((r) => {
          return {
            status: r.status,
            performerId: r.data.performerId,
          }
        })
      })
      .catch((e) => {
        return [
          {
            status: e.response.status,
            detail: e.response.data.detail,
            performerId: JSON.parse(e.response.config.data).performerId,
          },
        ]
      })
  },
  postOwnerChallenges ({ state }, recordingId) {
    let challenges = state.recordingOwners[recordingId].sources
      .map((s) => s.landingShares.filter((lp) => lp.comment))
      .flat()
    // Take only unique maker ids
    let data = [...new Map(
      challenges.map((c) => {
        return [
          c.landingMakerId,
          {
            maker_id: c.landingMakerId,
            comments: [{ comment: c.comment }],
            related_recording: recordingId,
          }
        ]
      })
    ).values()]

    let responses = []
    data.forEach((challenge) => {
      responses.push(
        this._vm.$api.repertoire.makerChallengesCreate(challenge)
      )
    })

    return Promise.all(responses)
      .then((apiResponses) => {
        return apiResponses.map((r) => {
          return {
            status: r.status,
            makerId: r.data.makerId,
          }
        })
      })
      .catch((e) => {
        return [
          {
            status: e.response.status,
            detail: e.response.data.detail,
            makerId: JSON.parse(e.response.config.data).makerId,
          },
        ]
      })
  },
}
const getters = {
  recordingDataCount (state) {
    return state.recordingDataCount
  },

  getFieldOptions (state) {
    return function (fieldName) {
      if (fieldName === "type") {
        return [
          { value: "SR", text: "Sound Recording" },
          { value: "MV", text: "Music Video" },
        ]
      } else if (fieldName === "genreType") {
        return [
          { value: "P", text: "Popular" },
          { value: "J_S", text: "Jazz (small)" },
          { value: "J_M", text: "Jazz (medium)" },
          { value: "J_L", text: "Jazz (large)" },
          { value: "J_XL", text: "Jazz (very large)" },
          { value: "C_S", text: "Classical (small)" },
          { value: "C_M", text: "Classical (medium)" },
          { value: "C_L", text: "Classical (large)" },
          { value: "C_XL", text: "Classical (very large)" },
        ]
      } else if (fieldName === "duration") {
        if (state.recordingData.options) {
          return state.recordingData.options.duration.map((d) => {
            return {
              value: d,
              text: this.$options.filters.toDuration(d),
            }
          })
        }
      } else {
        return fieldName in state.recordingData.options ? state.recordingData.options[fieldName]
          .filter(r => r != null)
          .sort() : []
      }
    }
  },
}
export const repertoire = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
