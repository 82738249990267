<template>
  <status
    v-if="isTrue"
    :icon="['fas', 'check-circle']"
    color="green"
  />
  <status
    v-else-if="isTrue === false"
    :icon="['fas', 'times-circle']"
    color="red"
  />
  <status
    v-else
    :icon="['fas', 'question-circle']"
    color="orange"
  />
</template>

<script>

export default {
  name: "BooleanStatus",
  props: {
    "isTrue": Boolean,
  },
}
</script>
