<template>
  <ValidationObserver ref="usersCreateForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" class="form-container">
      <!-- First Name -->
      <form-field
        name="first-name"
        label="First name"
        type="text"
        rules="required"
        v-model="form.firstName"
      />

      <!-- Last Name -->
      <form-field
        name="last-name"
        label="Last name"
        type="text"
        rules="required"
        v-model="form.lastName"
      />

      <!-- Status -->
      <multi-select-field
        v-model="form.status"
        name="status"
        :options="statusOptions"
        rules="required"
        label="Status"
        choiceLabel="display"
        track-by="value"
        :showLabels="false"
        class="w-50"
      />

      <!-- Email -->
      <form-field
        name="email"
        label="E-mail"
        type="email"
        rules="required|email"
        v-model="form.email"
      />
        
      <!-- Phone Number -->
      <form-field
        name="contact-number"
        label="Phone number"
        type="text"
        v-model="form.contactNumber"
      />

      <!-- Role -->
      <multi-select-field
        v-model="form.role"
        name="role"
        :options="roleOptions"
        rules="required"
        label="User Role"
        choiceLabel="display"
        track-by="value"
        :showLabels="false"
        class="w-50"
      />

      <!-- Member Collective -->
      <multi-select-field
        v-model="form.memberCollective"
        name="member-collective"
        :options="memberCollectiveOptions"
        :label="`${$t('collective_society')}`"
        choiceLabel="display"
        track-by="value"
        rules="required"
        :showLabels="false"
        class="w-50"
      />

      <date-field
        label="Start Date"
        name="startDate"
        v-model="form.startDate"
      />
      <b-row class="modal-footer mt-3">
          <b-button class="float-left btn-outline-dark" size="md" @click.prevent="cancel">Cancel</b-button>
        <div>
          <submit-button class="float-right mr-3 btn-outline-red" size="md">Save</submit-button>
          <b-button class="float-right mr-3 btn-outline-red" size="md" @click.prevent="saveAsADraft">Save as draft</b-button>
        </div>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { RESOUND, activationStatuses, sisterSocietyUserRole, userTypes } from "@/constants"
import { ValidationObserver } from "vee-validate"
import { capitalize } from "lodash"
import { mapMutations } from "vuex"
import { mapState } from "vuex"

const {
  INTERNAL,
  EXTERNAL
} = userTypes

export default {
  name: "UsersCreateForm",
  components: {
    ValidationObserver,
  },
  data () {
    return {
      form: {
        firstName: "",
        lastName: "",
        status: "",
        type: "",
        email: "",
        contactNumber: "",
        role: "",
        memberCollectiveBool: "true",
        memberCollective: ""
      },
    }
  },
  watch: {
    "form.memberCollective": function (memberCollective) {
      if (memberCollective) {
        if (memberCollective.isSisterSociety) {
          this.form.role = this.roleOptions.find(role => role.display === sisterSocietyUserRole)
        }
      }
    }
  },
  computed: {
    ...mapState("consts", ["memberCollectives", "userRoles"]),
    roleOptions () {
      let roleOptions = []
      this.userRoles.forEach(element => {
        roleOptions.push({
          display: element.name,
          value: element.id
        })
      })
      if (this.form.memberCollective) {
        if (this.form.memberCollective.isSisterSociety) {
          roleOptions = roleOptions.filter(role => role.display === sisterSocietyUserRole)
        }
      }
      return roleOptions
    },
    statusOptions () {
      return Object.values(activationStatuses).map(item => {
        return {
          display: this.$t(item),
          value: item
        }
      })
    },
    typeOptions () {
      return Object.values(userTypes).map(item => {
        return {
          display: this.$t(item),
          value: item
        }
      })
    },
    memberCollectiveOptions () {
      let memberCollectives = this.memberCollectives.map(item => {
        return {
          display: item.name,
          value: item.uuid,
          isSisterSociety: item.isSisterSociety
        }
      })
      return memberCollectives
    },
    userType () {
      let roleResound = this.form.role && (this.form.role.display.includes(RESOUND) || this.form.role.value === "AD")
      let memberCollectiveResound = this.form.memberCollectiveBool === "true" && this.form.memberCollective && this.form.memberCollective.display.includes(RESOUND)
      if (roleResound || memberCollectiveResound) {
        return this.typeOptions.find(item => item.value === INTERNAL)
      }
      return this.typeOptions.find(item => item.value === EXTERNAL)
    }
  },
  mounted () {
    let formAsADraft = localStorage.getItem("UsersCreateForm")
    if (formAsADraft) {
      formAsADraft = JSON.parse(formAsADraft)
      this.form = formAsADraft
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    onSubmit () {
      let memberCollective
      if (this.form.memberCollectiveBool && this.form.memberCollective) {
        let memberCollectiveUUID
        if (this.userType.value === INTERNAL) {
          memberCollectiveUUID = this.memberCollectiveOptions.find(item => item.display === RESOUND).value
        } else {
          memberCollectiveUUID = this.form.memberCollective.value
        }
        memberCollective = {
          "uuid": memberCollectiveUUID
        }
      }
  
      const data = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        status: this.form.status.value,
        email: this.form.email,
        contactNumber: this.form.contactNumber,
        role: this.form.role.value,
        memberCollective,
        startDate: this.form.startDate
      }

      this.$api.users.usersCreate(data)
        .then(() => {
          this.success(capitalize(this.$t("created_successfully")))
          this.$bvModal.hide("user-create-modal")
          this.$emit("submit")
          localStorage.removeItem("UsersCreateForm")
        })
        .catch(error => {
          const { data, status } = error.response

          if (status === 400) {
            this.error(capitalize(this.$t("form_contains_errors")))
            this.$refs.usersCreateForm.setErrors(data)
          }
        })
    },
    saveAsADraft () {
      localStorage.setItem("UsersCreateForm", JSON.stringify(this.form))
      this.success(capitalize(this.$t("saved_as_draft")))
      this.$bvModal.hide("user-create-modal")
    },
    cancel () {
      this.$bvModal.hide("user-create-modal")
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .form-group {
  padding-bottom: 10px;
  > div {
    label {
      width: 20%;
      margin: auto;
      font-weight: 450;
      .text-danger {
        color: $red !important;
      }
    }
    .input-group, .multiselect, .mx-datepicker {
      display: inline-flex;
      width: 80%;
    }
    .multiselect__tags, .mx-input-wrapper {
      width: 100%;
    }
    .invalid-feedback {
      text-align: end;
    }
  }
}
</style>