<template>
  <div>
    <b-table :items="rows" :fields="fields" class="mb-0" thead-class="d-none" bordered striped small fixed responsive>
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width, 'min-width': field.minWidth }"
        />
      </template>
      <template #cell()="row">
        <div class="text-center" v-if="row.value && row.value.uuid">
          <strong>{{ row.field.label }}</strong>: <database-link  target="_blank" :type="linkTypes.RECORDING" :item-id="row.value.uuid">{{ row.value.resoundId }}</database-link>
        </div>
        <div class="text-center" v-else-if="Object.keys(row.value).length !== 0">
          <span v-if="row.field.key == 'field'">
            {{ $t(row.value) | startcase }}
          </span>
          <span v-else>
            {{ row.value }}
          </span>
        </div>
        <div class="text-center" v-else-if="row.value">
          <strong>{{ $t("landing_recording") | startcase }}: {{ proprietaryId }}</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import DatabaseLink from "@/components/DatabaseLink"
import { linkTypes } from "../constants"
import parseDate from "@/utils/date-parser"

export default {
  name: "PerformerComparison",
  components: { DatabaseLink, },
  props: {
    uuid: {
      required: true,
      type: String,
    },
    proprietaryId: {
      required: true,
      type: String,
    },
    candidates: Array
  },
  data () {
    return {
      linkTypes,
      data: [],
    }
  },
  computed: {
    fields () {
      let candidates_amount = Object.keys(this.rows[0]).length - 2
      let fields = [
        {
          key: "field",
          width: "10%",
          minWidth: "30px",
        },
        {
          key: "landingRecording",
          width: `${90 / (Object.keys(this.rows[0]).length - 1)}%`,
          tdClass: this.landingRecordingClass,
          minWidth: "200px",
        }
      ]
      let array = [...Array(candidates_amount).keys()]
      array.forEach((i) => {
        fields.push({
          key: `candidate${i+1}`,
          width: `${90 / (Object.keys(this.rows[0]).length - 1)}%`,
          tdClass: this.candidateClass,
          minWidth: "200px",
        })
      })
      return fields
    },
    rows () {
      return this.data
    }
  },
  methods: {
    parseDate,
    landingRecordingClass (value) {
      if (typeof value === "object" && value !== null) {
        return "bg-secondary text-white"
      }
    },
    candidateClass (value) {
      if (typeof value === "object"  && value !== null) {
        return "bg-gray"
      }
    },
    onLoaded () {
      this.loading = false
    },
    onLoading () {
      this.loading = true
    },
    responseAdapter (data) {
      this.data = data
    },
    getData () {
      this.loading = true
      this.$api.actions.performerComparisonActionRetrieve(this.uuid, { "candidates": this.candidates })
        .then((resp) => {
          this.responseAdapter(resp.data)
        })
        .catch((error) => {
          this.error(error)
        })
    },
  },
  mounted () {
    this.getData()
  },
}
</script>
<style scoped>
  ::v-deep .table-bordered {
  border: 1px solid lightgray;
}
  ::v-deep .table-bordered td {
  border: 1px solid lightgray !important;
}
</style>
