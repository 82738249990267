import { capitalize, startCase } from "lodash"
import { rightsholderTypes } from "@/constants"

export default {
  data: function () {
    return  {
      allFields: [
        { key: "showDetails", label: "", width: "4%" },
        {
          key: "tariffs",
          label: startCase(this.$t("tariff")),
          width: "27%",
        },
        {
          key: "territory",
          label: startCase(this.$t("territory")),
          width: "11%",
        },
        {
          key: "memberCollective",
          label: startCase(this.$t("mc")),
          width: "12%",
        },
        {
          key: "indirectMC",
          label: startCase(this.$t("indirect_mc")),
          width: "12%",
        },
        {
          key: "agent",
          label: startCase(this.$t("agent")),
          width: "12%",
        },
        {
          key: "startDate",
          label: startCase(this.$t("start_date")),
          width: "8%",
        },
        {
          key: "endDate",
          label: startCase(this.$t("end_date")),
          width: "8%",
        },
        {
          key: "category",
          label: startCase(this.$t("category")),
          width: "10%",
        },
      ]
    }
  },
  methods: {
    fields () {
      let excludeFields = new Set()
      if (this.isMaker()) {
        excludeFields.add("category")
      }
      return  this.allFields.filter((f) => !excludeFields.has(f.key))
    },
    isMaker () {
      return this.rightsHolderType === rightsholderTypes.MAKER
    },
    getCategory (mandate) {
      if (mandate.featured && mandate.nonFeatured) {
        return capitalize(this.$t("featured_and_non_featured"))
      } else if (mandate.featured) {
        return capitalize(this.$t("featured"))
      } else {
        return capitalize(this.$t("non_featured"))
      }
    },
    getMandateTerritory (mandate) {
      if (mandate.scope === "worldwide" && mandate.countries.length === 0) {
        return capitalize(this.$t("worldwide"))
      } else if (mandate.scope === "worldwide" && mandate.countries.length > 0) {
        return `${capitalize(this.$t("worldwide_except"))} ${this.getCountriesList(mandate.countries).join(", ")}`
      } else if (mandate.scope === "domestic" && mandate.countries.length > 0) {
        return this.getCountriesList(mandate.countries).join(", ")
      }
    }
  }
}
