<template>
  <div class="d-flex flex-column">
    <splitpanes horizontal class="default-theme" @resize="manualSize = $event[0].size">
      <pane
        ref="topPane"
        class="top-pane"
        min-size="20"
        :size="topPaneSize"
        max-size="100"
      >
        <slot name="top"></slot>
      </pane>
      <pane v-if="bottomSlotHasContent" max-size="70" :size="bottomPaneSize" min-size="20" class="bottom-pane">
        <slot name="bottom"></slot>
      </pane>
    </splitpanes>
    <!-- Default -->
    <slot></slot>
  </div>
</template>

<style lang="scss">
/* stylelint-disable */
.splitpanes.default-theme {
  .VueTables, .t-table {
    height: 100%;

    .table-responsive {
      height: 100%;
    }
  }

  .splitpanes__splitter {
    border-top: 2px solid $gray-light;
    margin: 10px 0;
  }

  .splitpanes__splitter::before {
    top: -50%;
    height: 2px;
    margin-top: -4px;
    background-color: $gray-light;
  }

  .splitpanes__splitter::after {
    top: -50%;
    height: 2px;
    margin-top: 4px;
    background-color: $gray-light;
  }

  .splitpanes__pane {
    background-color: var(--white);

    &.bottom-pane {
      overflow-y: auto !important;
      .overflow-auto {
        scrollbar-width: thin;
      }
    }

    table {
      th,
      td {
        padding: 0 0.25em;
      }
    }

    .table {
      table-layout: fixed;
      overflow-y: auto !important;

      thead {
        th {
          color: var(--gray);
          background: var(--white);
          position: sticky !important;
          top: 0;
          border-top: none !important;
          z-index: 5;

          &.select-header {
            background-color: var(--white);
            padding-bottom: 0.2em;

            .custom-header {
              position: relative;
              top: 1.4em;
            }
          }
        }
      }

      .short-text,
      .values-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

/* stylelint-enable */
</style>

<script>
import "splitpanes/dist/splitpanes.css"
import { Pane, Splitpanes } from "splitpanes"

export default {
  name: "CrescendoSplitPanes",
  components: { Pane, Splitpanes },
  props: {
    tableView: {
      type: Boolean,
      default: true,
    }
  },
  data: () => {
    return {
      manualSize: 0,
      topPaneSize: 65,
      bottomPaneSize: 35,
    }
  },
  methods: {
    getElementOuterHeight (el) {
      let style = window.getComputedStyle(el)
      let height = el.clientHeight
      return height + ["top", "bottom"].map(attr => parseInt(style[`margin-${attr}`])).reduce((total, side) => total + side, 0)
    },
    setHeight () {
      // Calculate and set panes element height
      const panesHeight = window.innerHeight - this.$el.getBoundingClientRect().top
      this.$el.style.height = `${panesHeight}px`

      // Calculate and set top pane height
      const topPane = this.$refs.topPane
      let table = topPane.$el.querySelector(".file-list")
      let topPaneBottomPadding = parseInt(
        window.getComputedStyle(
          topPane.$el, null
        ).getPropertyValue("padding-bottom")
      )
      let pagination = topPane.$el.querySelector(".VuePagination")
      let table_view = topPane.$el.querySelector(".VueTables, .t-table")
      if (this.tableView && table_view) {
        table = table_view
        topPaneBottomPadding = parseInt(
          window.getComputedStyle(
            topPane.$el.firstElementChild, null
          ).getPropertyValue("padding-bottom")
        )
      }

      if (!this.manualSize) {
        let topPaneSizePX = table.clientHeight
        if (this.bottomSlotHasContent)
          this.topPaneSize = (topPaneSizePX + topPaneBottomPadding) * 100 / panesHeight

        let fixedHeight = 1
        if (pagination) {
          fixedHeight += this.getElementOuterHeight(pagination)
        }
        let topPagination = topPane.$el.querySelector(".counts-container")
        if (topPagination) {
          fixedHeight += this.getElementOuterHeight(topPagination)
        }
        // Add additional space for pagination
        if (fixedHeight) {
          if (this.tableView) {
            topPane.$el.style.paddingBottom = "0em"
            table.style.paddingBottom = fixedHeight + "px"
          } else {
            topPane.$el.style.paddingBottom = fixedHeight + "px"
          }
        } else {
          topPane.$el.style.paddingBottom = "0em"
          table.style.paddingBottom = "0em"
        }
      }
    }
  },
  computed: {
    bottomSlotHasContent () {
      const bottomPaneNodes = this.$slots.bottom
      return (bottomPaneNodes && 
        bottomPaneNodes.length > 0
      )
    },
  },
}
</script>
