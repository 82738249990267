const state = {
  rightsholderData: null,
  rightsholderMetaData: {
    data: null,
    count: 0,
  },
}

const mutations = {
  setRightsholderData (state, data) {
    state.rightsholderData = data
  },
  setRightsholderMetaData (state, data) {
    state.rightsholderMetaData.data = data.results
    state.rightsholderMetaData.count = data.count
  },
}

const actions = {
  getRightsholderData (state, rightsholderId) {
    return this._vm.$api.rightsholders.rightsholdersDetail(rightsholderId).then((d) => {
      if (d) {
        state.commit("setRightsholderData", d.data)
      }
    })
  },
  getRightsholderMetaData (state, params) {
    let queryParams = { page: params.page }
    return this._vm.$api.rightsholders.landingRightsholderDetail(params.recordingId, queryParams).then((d) => {
      if (d) {
        state.commit("setRightsholderMetaData", d.data)
      }
    })
  },
}

const getters = {}
export const rightsholders = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
