<template>
    <b-card>
      <h3 class="text-red">
        {{ $t("source_metadata") | startcase }}
      </h3>
      <rightsholder-table
        :data="duplicateDifferentSourcesData()"
        :fields="data.fields"
        :rightsholder-type="data.rightsholderType"
      />
      <table-pagination
        class="mt-3"
        v-model="page"
        :total-rows="rightsholderMetaData.count"
        :per-page="perPageItemsSm"
      />
    </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { perPageItemsSm, sourceTypes } from "@/constants"
import RightsholderTable from "./RightsholderTable"



export default {
  name: "GeneralDetails",
  computed: {
    ...mapState("rightsholders", ["rightsholderMetaData"]),
  },
  data () {
    return {
      perPageItemsSm,
      page: 1,
      count: 0,
    }
  },
  methods: {
    ...mapActions("rightsholders", ["getRightsholderMetaData"]),
    getData () {
      let params = { recordingId: this.rightsholderId, page: this.page }
      this.getRightsholderMetaData(params)
    },
    duplicateDifferentSourcesData () {
      let landingRightsholders = []
      if (this.rightsholderMetaData.data) {
        this.rightsholderMetaData.data.forEach(item => {
          let duplicatedItems = []
          if (item.sources.files.length > 0) {
            duplicatedItems.push({ ...item, sources: item.sources.files, type: sourceTypes.REPERTOIRE_FILE })
          }
          if (item.sources.inputSources.length > 0) {
            duplicatedItems.push({ ...item, sources: item.sources.inputSources, type: sourceTypes.RIGHTSHOLDER_BATCH })
          }
          landingRightsholders = landingRightsholders.concat(duplicatedItems)
        })
      }
      return landingRightsholders
    },
  },
  mounted () {
    this.getData(this.rightsholderId)
  },
  props: {
    data: Object,
    fields: Array,
    rightsholderId: String,
  },
  components: {
    RightsholderTable,
  },
  watch: {
    page () {
      this.getData()
    },
  },
}
</script>
