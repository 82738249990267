<template>
  <list-layout
    :title="$t('tariff_periods') | startcase"
    no-counts
    no-search
    no-apply-btn
  >
    <modal
      id="distributions-export-modal"
      :customTitle="$t('export_tariff_period_list') | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered
      hide-footer>
        <distributions-export-form :count="count"/>
    </modal>
    <template v-slot:heading-right>
      <b-button variant="secondary" :to="{'name': 'distribution-create'}">{{ $t("create_new_tariff_period") | capitalize }}</b-button>
    </template>
    <template v-slot:beforeFilters>
      <b-row>
        <b-col>
          <filter-builder
            :id="userPreferenceNames.DISTRIBUTION_LIST_FILTER"
            :filters="getFiltersWithOptions()"
            :apply-filters="applyFilters"
            :reset-filters="resetFilters"
            :filters-pending="filtersPending"
            :filters-applied="filtersApplied"
            @sorted="onSorted"
            @filter="onFilter"
          />
        </b-col>
      </b-row>
    </template>

    <template v-slot:table>
        <t-table
          :columns="columns"
          :options="options"
          :limit="limit"
          ref="distributions"
          id="distributions"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <template v-slot:filters>
            <b-row>
              <b-col>
                <b-form-radio-group
                  v-model="filters.distributionMethodology.value"
                  buttons
                  button-variant="outline-primary"
                  name="radios-btn-outline"
                >
                  <b-form-radio :value="distributionMethodologies.STANDARD">
                    {{ $t("standard") | capitalize }}
                  </b-form-radio>
                  <b-form-radio :value="distributionMethodologies.INTERNATIONAL">
                    {{ $t("international") | capitalize }}
                  </b-form-radio>
                  <b-form-radio :value="distributionMethodologies.CONNECT">
                    {{ $t("connect_reproduction") }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
          </template>
          <template v-slot:merge>
            <span>
              <b-button size="sm" variant="outline-primary" @click="exportData" class="ml-2">
                <fa-icon size="sm" :icon="['fa', 'cloud-upload-alt']" />
                {{ $t("export") | capitalize }}
              </b-button>
            </span>
          </template>
          <template v-slot:counts>
            <div class="d-flex">
              <pagination
                class="smaller p-0"
                :page="page"
                :count="count"
                :loading="loading"
                v-model="limit"
                @limit="onLimit"
              />
            </div>
          </template>
          <!-- College -->
          <template v-slot:college="{ row }">
            {{ swappedCollegeValues[row.college] | capitalize }}
          </template>
          <template v-slot:actions="{ row }">
            <div class="d-flex justify-content-end">
              <table-action
                  :title="$t('delete') | lowercase"
                  v-if="permissions.pages[Pages.DistributionList].canWrite"
                  @click="onDelete(row.id)"
                  :icon="['fas', 'trash']"
                  :disabled="row.computedConsumerFields && !row.computedConsumerFields.deletionAllowed"
              />

              <!-- Deletion Confirmation Modal -->
              <modal
                  :id="'confirmation-modal-' + row.id"
                  :customTitle="$t('delete_tariff_period') | capitalize"
                  modal-class="custom-modal edit"
                  modalType="edit"
                  size="md"
                  centered
                  @ok="onConfirmDelete(row.id)"
                  okTitle="Confirm" >
                <div>{{ $t("do_you_really_want_to_delete_tariff_period") | capitalize }}?</div>
              </modal>
            </div>
          </template>
        </t-table>
    </template>
  </list-layout>

</template>

<script>
import { capitalize, get, snakeCase } from "lodash"
import { connectDistributionMethodologies, distributionCollegeTypes, distributionMethodologies, eligibilityOptions, filterTypes, perPageItemsLg, userPreferenceNames } from "@/constants"
import { mapActions, mapMutations, mapState } from "vuex"
import DistributionsExportForm from "./DistributionsExportForm.vue"
import FilterBuilder from "@/components/FilterBuilder"
import { Pages } from "@/utils/pages"
import Pagination from "@/components/Pagination"
import { listRouteMixin } from "@/utils/mixins"
import parseDate from "@/utils/date-parser"

export default {
  name: "ListDistributionsPage",
  mixins: [listRouteMixin],
  components: {
    DistributionsExportForm,
    FilterBuilder,
    Pagination,
  },
  computed: {
    ...mapState("consts", ["tariffs"]),
    ...mapState("user", ["user", "permissions"]),
    tariffOptions () {
      return this.tariffs.map(c => {
        return { text: c.text, value: c.code }
      })
    },
  },
  methods: {
    ...mapActions("consts", ["getTariffs"]),
    ...mapMutations("alert", ["error"]),
    parseDate,
    onDelete (id) {
      this.$bvModal.show(`confirmation-modal-${id}`)
    },
    onConfirmDelete (id) {
      this.$api.distributions
        .deleteDistribution(id)
        .then(() => {
          this.getData()
        })
        .catch(error => {
          let msg = `Error deleting distribution. ${error.response.data.nonFieldErrors[0].message}`
          this.error(msg)
        })
    },
    onFilter (name, value) {
      if (["periodStart", "periodEnd"].includes(name)) {
        const range = (value) ?
          [
            this.parseDate(value[0], this.$config.ISO_DATE_FORMAT),
            this.parseDate(value[1], this.$config.ISO_DATE_FORMAT)
          ] : []

        if (range.filter(r => r).length) {
          this.filters.userTimezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone
        } else {
          if (name === "periodStart") {
            this.filters.periodStart.value = this.filters.periodStart.defaultValue
          } else {
            this.filters.periodEnd.value = this.filters.periodEnd.defaultValue
          }
          this.filters.userTimezone.value = this.filters.userTimezone.defaultValue
        }
        this.changeFilter(name, range)
      }
    },
    exportData () {
      this.$bvModal.show("distributions-export-modal")
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    getFiltersWithOptions () {
      this.filters.college.options = this.rightsholderOptions
      if (!this.filters.college.options.map(c => c.value).includes("maker_and_performer")) {
        this.filters.college.options.push({ text: capitalize(this.$t("both")), value: "maker_and_performer" })
      }
      this.filters.tariff.options = this.tariffOptions
      this.filters.proxyTariff.options = this.tariffOptions
      return this.filters
    },
    getData () {
      this.$refs.distributions.getData()
    },
    onSorted (data) {
      this.queryParams = {
        ...this.queryParams,
        ["sorting"]: data.map(item => item.ascending ? snakeCase(item.column) : `-${snakeCase(item.column)}`)
      }
      this.applyFilters()
    },
  },
  watch: {
    "filters.distributionMethodology.value": function (value) {
      if (value === distributionMethodologies.CONNECT) {
        value = connectDistributionMethodologies
      }
      this.changeFilter("distributionMethodology", value, true)
    },
  },
  data () {
    return {
      loading: false,
      page: 1,
      count: 0,
      limit: perPageItemsLg,
      pageNumber: 1,
      userPreferenceNames,
      distributionMethodologies,
      queryParams: {},
      swappedCollegeValues: Object.keys(distributionCollegeTypes).reduce((ret, key) => {
        ret[distributionCollegeTypes[key]] = key
        return ret
      }, {}),
      columns: [
        "id",
        "description",
        "tariff",
        "periodStart",
        "periodEnd",
        "college",
        "proxyTariff",
        "primaryEligibilityScheme",
        "secondaryEligibilityScheme",
        "actions"
      ],
      options: {
        allSelected: false,
        sortable: [
          "id",
          "description",
          "tariff",
          "periodStart",
          "periodEnd",
          "college",
          "proxyTariff",
        ],
        headings: {
          "id": capitalize(this.$t("id")),
          "description": capitalize(this.$t("description")),
          "tariff": capitalize(this.$t("tariff")),
          "periodStart": capitalize(this.$t("date_start")),
          "periodEnd": capitalize(this.$t("date_end")),
          "college": capitalize(this.$t("college")),
          "proxyTariff": capitalize(this.$t("proxy_tariff")),
          "primaryEligibilityScheme": capitalize(this.$t("eligibility_scheme")),
          "secondaryEligibilityScheme": capitalize(this.$t("backup_eligibility_scheme")),
          "actions": ""
        },
        width: {
        },
        columnsClasses: {
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          queryParams.distribution_methodology = get(this.$route.query, "distribution_methodology", distributionMethodologies.STANDARD)
          if (queryParams.distribution_methodology === distributionMethodologies.CONNECT) {
            queryParams.distribution_methodology = connectDistributionMethodologies
          }
          if (queryParams.college) {
            if (queryParams.college === distributionCollegeTypes.MAKER || queryParams.college === distributionCollegeTypes.PERFORMER) {
              queryParams.college = [queryParams.college, distributionCollegeTypes.BOTH]
            }
          }
          return this.$api.distributions.distributionsList(queryParams)
        },
      },
      filters: {
        distributionMethodology: {
          value: get(this.$route.query, "distributionMethodology", distributionMethodologies.STANDARD),
          defaultValue: distributionMethodologies.STANDARD, hidden: true
        },
        userTimezone: { value: null, defaultValue: null, hidden: true },
        periodStart: {
          value: null,
          defaultValue: null,
          type: filterTypes.DATE_RANGE,
          format: "YYYY-MM-DD",
          label: capitalize(this.$t("date_start")),
          noWatch: true,
        },
        periodEnd: {
          value: null,
          defaultValue: null,
          type: filterTypes.DATE_RANGE,
          format: "YYYY-MM-DD",
          label: capitalize(this.$t("date_end")),
          noWatch: true,
        },
        college: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("college")),
          emtpyValue: capitalize(this.$t("both")),
          type: filterTypes.SELECT,
          options: [],
        },
        tariff: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("tariff")),
          options: [],
          emptyValue: capitalize(this.$t("all")),
          type: filterTypes.SELECT,
        },
        proxyTariff: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("proxy_tariff")),
          options: [],
          emptyValue: capitalize(this.$t("all")),
          type: filterTypes.SELECT,
        },
        primaryEligibilityScheme: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("primary_eligibility_scheme")),
          options: eligibilityOptions,
          emptyValue: capitalize(this.$t("all")),
          type: filterTypes.SELECT,
        },
        secondaryEligibilityScheme: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("backup_eligibility_scheme")),
          options: eligibilityOptions,
          emptyValue: capitalize(this.$t("all")),
          type: filterTypes.SELECT,
        },
      },
      Pages
    }
  },
  mounted () {
    if (!this.tariffs.length) {
      this.getTariffs()
    }
  }
}
</script>

