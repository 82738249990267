<template>
  <list-layout
    ref="listLayout"
    :title="getTitle() | startcase"
    :count="(userAction ? userActionCounts.usageMatchingConflicts : '')"
    :isChild="false"
    :search-filter="filters.fuzzySearch"
    :apply-filters="applyFilters"
    :no-apply-btn="true"
    no-counts
    no-search
  >
    <template v-slot:title>
      <span v-if="usageList && file">
        <b-row class="heading-container">
          <go-back/>
          <h4 class="ml-2">
            {{ file.filename | tolower }}
          </h4>
        </b-row>
        <b-row class="mb-3 p-15">
          <div>
            <span v-if="file.copyright">
              {{ `${file.copyright} - ${file.tariff}` }}
            </span>
            <span class="text-gray">
              {{
                `${file.uploader} ${$t("on")} ${parseDate(
                    file.uploadDate,
                    $config.DATETIME_FORMAT
                )}`
              }}
            </span>
          </div>
        </b-row>
      </span>
    </template>
    <modal
      id="junk-modal"
      :customTitle="(markingAsJunk ? $t('mark_as_junk') : $t('mark_as_not_junk')) | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg"
      centered
      hide-footer
    >
      <ValidationObserver ref="junkForm" v-slot="{ handleSubmit }">
        <ValidationProvider vid="comment" name="comment" :rules="!markingAsJunk ? 'required' : ''" v-slot="{ errors }">
          <b-form @submit.prevent="handleSubmit(markAsJunk)">
            <b-form-textarea
              size="lg"
              class="mb-4"
              debounce="500"
              name="comment"
              :placeholder="$t('comment') | capitalize"
              v-model="junkComment"
              rows="5"
            />
            <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
            <b-row class="modal-footer mt-4">
              <b-button @click="$bvModal.hide('junk-modal')" class="btn-outline-dark">
                {{ $t("cancel") | capitalize }}
              </b-button>
              <b-button type="submit" class="btn-outline-red">
                {{ $t("apply") | capitalize }}
              </b-button>
            </b-row>
          </b-form>
        </ValidationProvider>
      </ValidationObserver>
    </modal>
    <modal
      id="junk-history"
      :customTitle="junkModalTitle"
      modal-class="custom-modal info"
      modalType="info"
      size="xl"
      centered
      hide-footer>
      <junk-history-list :usage-uuid="junkModalUsageId"/>
    </modal>

    <modal
      id="usages-export-modal"
      :customTitle="exportTitle"
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered
      hide-footer>
      <usages-export-form :count="count" :export-unclaimed="exportUnclaimed"/>
    </modal>
    <modal
      id="usages-rematch-modal"
      :customTitle="$t('launch_rematch_process') | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="md"
      centered
      @ok="launchRematching()">
      <div>{{ $t("do_you_want_to_launch_rematching_process") | capitalize }}?</div>
    </modal>
    <template v-slot:beforeFilters>
      <filter-builder
        :id="getComponentId()"
        :filters="getFiltersWithOptions()"
        :apply-filters="applyFilters"
        :reset-filters="resetFilters"
        :filters-pending="filtersPending"
        :filters-applied="filtersApplied"
        @filter="onFilter"
        />
    </template>
    <!-- Table -->
    <template v-slot:table>
      <crescendo-split-panes ref="panes">
        <template v-slot:top>
          <t-table
              :columns="columns"
              :options="options"
              ref="consolidatedUsages"
              :id="getComponentId()"
              :selectable="unmatchedView"
              :selected-data="selectedData"
              :column-mode="getColumnsMode()"
              @row-click="setMatchedRecording"
              @loaded="onLoaded"
              @loading="onLoading"
              @pagination="onPagination"
              @sorted="onSorted"
          >
            <template v-slot:counts>
              <div class="row align-items-center">
                <div class="col d-flex">
                  <pagination
                    class="smaller p-0"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :per-page-selector="!viewingCart"
                    v-model="limit"
                    @limit="onLimit"
                  />
                  <merge-summary
                    v-if="(!challengeView && selectedData.length) || viewingCart"
                    class="ml-2 smaller"
                    :selectedObjects="selectedData"
                    :clear="clearCart"
                    :switchView="toggleCart"
                    buttonText=""
                    badgeDisplayField="title"
                  />
                </div>
              </div>
            </template>
            <template v-slot:merge>
              <div class="d-flex align-items-center">
                <b-form-group v-if="!usageList">
                  <b-form-checkbox v-model="viewQA" switch>
                    {{ $t("view_qa") }}
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group class="ml-2" v-if="userAction">
                  <b-form-checkbox v-model="myMatches" switch>
                    {{ $t("my_matches") | capitalize }}
                  </b-form-checkbox>
                </b-form-group>
                <b-button v-if="permissions.actions.canExportUsages"
                  size="sm" variant="outline-primary" @click="exportUsages" class="ml-2"
                >
                  <fa-icon size="sm" :icon="['fa', 'cloud-upload-alt']" />
                  {{ exportButtonTitle }}
                </b-button>
                <b-button
                  v-if="permissions.actions.canRematchUsages && !usageList"
                  size="sm"
                  variant="outline-primary"
                  @click="rematchUsages"
                  class="ml-2"
                >
                  <fa-icon size="sm" :icon="['fa', 'code-merge']" />
                  {{ $t("rematch_usages") | capitalize }}
                </b-button>
                <b-button-group
                  v-if="permissions.actions.canMarkAsJunk"
                >
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="markAsJunkModal(markingAsJunk)"
                    class="ml-2"
                    :disabled="!selectedData.length"
                  >
                    <fa-icon size="sm" :icon="['fa', 'dumpster']" />
                    {{ (markingAsJunk ? $t('mark_as_junk') : $t('mark_as_not_junk')) | capitalize }}
                  </b-button>
                  <b-dropdown
                    right
                    variant="outline-primary"
                    size="sm"
                    :disabled="!selectedData.length"
                  >
                    <b-dropdown-item @click="markAsJunkModal(true)" >
                      Mark as junk
                    </b-dropdown-item>
                    <b-dropdown-item @click="markAsJunkModal(false)">
                      Mark as NOT junk
                    </b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </template>

            <template v-slot:sequenceNumber="{ row }">
              <div class="d-flex">
                <modal
                  :id="`confirmation-modal-solved-${row.id}`"
                  :customTitle="$t('user_action_solved') | capitalize"
                  modal-class="custom-modal edit"
                  modalType="edit"
                  size="md"
                  centered
                  hide-footer
                  hide-header-close
                  no-close-on-esc
                  no-close-on-backdrop
                >
                  <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
                  <b-row class="float-right modal-footer mt-4">
                    <b-button @click="blinkAndHide(row.id, false, getData)" class="btn-outline-red">
                      {{ $t("accept") | capitalize }}
                    </b-button>
                  </b-row>
                </modal>
                <div>
                  <fa-icon
                    v-if="row.actionStatus === userActionNotificationStatuses.OPEN || (isInProgressBackend(row.matchingStatuses) && row.actionStatus !== userActionNotificationStatuses.RELEASE)"
                    :icon="['fa', 'user-edit']"
                    color="red"
                    class="mr-2"
                  />
                </div>
                <span>{{ row.index ? row.index : "" }}</span>
              </div>
            </template>

            <template v-slot:recordingArtist="{ row }">
              <div :title="row.recordingArtist">
                {{ truncate(row.recordingArtist, { length : 100 }) }}
              </div>
            </template>

            <template v-slot:title="{ row }">
              <database-link
                  v-if="row.recordingId"
                  :type="linkTypes.RECORDING"
                  :item-id="row.recordingId"
              >
                {{ row.title }}
              </database-link>
              <span v-else>{{ row.title }}</span>
            </template>

            <template v-slot:release="{ row }">
              <div :title="row.release">{{ truncate(row.release, { length: 100 }) }}</div>
            </template>

            <template v-slot:composers="{ row }">
              <NamesListModal
                :values="row.composers"
                :title="row.composers"
                :truncate="true"
              />
            </template>

            <template v-slot:recordingReleases="{ row }">
              <NamesListModal
                  :title="row.recordingReleases ? row.recordingReleases.join(', ') : ''"
                  :values="row.recordingReleases ? row.recordingReleases : []"
                  :truncate="true"
                  :sortByLength="true"
                  class-names="table-meta"
              />
            </template>

            <template v-slot:stations="{ row }">
              <NamesListModal
                  :values="row.stationNames ? row.stationNames : []"
                  :title="row.stationNames ? row.stationNames.join(', ') : ''"
              />
            </template>

            <template v-slot:sources="{ row }">
              <div class="ml-3">
                <NamesListModal
                  :values="row.sourceNames"
                  :title="row.sourceNames"
                  :sortByLength="true"
                  :truncate="true"
                />
              </div>
            </template>

            <template v-slot:matchingInfo.matchedBy="{ row }">
              <span v-if="row.matchingInfo" :title="row.matchingInfo.matchedBy">
                {{ truncate(row.matchingInfo.matchedBy, { length: 30 }) }}
              </span>
            </template>

            <template v-slot:matchingInfo.matchedOn="{ row }">
                {{ getMatchOn(row) }}

            </template>

            <template v-slot:latestReview="{ row }">
              <span
                  v-if="row.latestReview"
                  :title="parseDate(row.latestReview, $config.DATE_FORMAT)"
              >
                {{ parseDate(row.latestReview, $config.DATE_FORMAT) }}
              </span>
            </template>

            <!-- Status -->
            <template v-slot:status="{ row }">
              <status
                v-if="row.status === usageMatchingStatuses.MANUALLY_MATCHED"
                :text="$t('manually_matched') | capitalize"
                :icon="['fas', 'check-circle']"
                :title="getMatchingInfo(row)"
                inline
                color="green"
              />
              <status
                v-if="row.status === usageMatchingStatuses.AUTOMATCHED"
                :text="$t('automatched') | capitalize"
                :icon="['fas', 'check-circle']"
                :title="getMatchingInfo(row)"
                inline
                color="green"
              />
              <status
                v-if="row.status === usageMatchingStatuses.UNMATCHED"
                :text="getUnmatchedText(row)"
                :icon="['fas', 'exclamation-circle']"
                inline
                color="red"
              />
              <status
                v-if="row.status === inlineRowStatus.ERROR"
                :text="$t('inline_error') | capitalize"
                :icon="['fas', 'exclamation-circle']"
                inline
                color="red"
              />
              <status
                v-if="row.status === inlineRowStatus.SUCCESS"
                :text="$t('inline_success') | capitalize"
                :icon="['fas', 'check-circle']"
                inline
                color="green"
              />
              <span class="ml-2" v-if="row.isJunk">
                <fa-icon
                  :title="$t('junked_usage') | capitalize"
                  size="sm"
                  :icon="['fa', 'dumpster']"
                />
              </span>
            </template>

            <!-- History -->
            <template v-slot:history="{ row }">
              <div>
                <fa-icon
                  v-if="row.junkHistoryCount"
                  class="clickable"
                  size="sm"
                  :title="$t('junk_history') | capitalize"
                  :icon="['fa', 'history']"
                  @click="showHistory(row)"
                />
              </div>
            </template>
          </t-table>
        </template>
        <!-- Suggested candidates section -->
        <template v-slot:bottom>
          <div v-if="bottomPaneVisible" class="overflow-auto h-100 overflow-x-hidden">
            <usage-matched-recording
              ref="matchedRecording"
              v-if="recordingUuid"
              :usage="selectedUsage"
              :uuid="recordingUuid"
              :challenge-uuid="challengeUuid"
              :challenge-view="challengeView"
              :solved-manually="solvedManually"
              @challenged="updateDetails"
              @resolving-challenge="setChallengeView"
              @resolved="updateMatchingInfo"
              @loaded="setMatchedRecordingObj"
            />
            <usage-recording-candidates
              v-if="uuids.length && (unmatchedView || challengeView)"
              :uuids="uuids"
              :reviewedUsages="reviewedUsages"
              :candidates="candidatesData"
              :challenge-view="challengeView"
              :challenge-uuid="challengeUuid"
              :solved-manually="solvedManually"
              :recording-type="recordingType"
              :showFinancialInterest="filters.financialInterest.value"
              :candidatesLoading="candidatesLoading"
              @resolved="updateMatchingInfo"
              @matched="onCandidateMatched"
              @notMatched="onCandidateNotMatched"
              @reviewed="onReviewed"
              @candidates-search-changed="searchCandidates"
            />
            <comments-table
              v-if="recordingUuid && challengeView && challengeUuid && (typeof challengeUuid !== 'boolean')"
              :createEndpoint="$api.comments.usageChallengeCommentCreate"
              :listEndpoint="$api.comments.usageChallengeCommentsList"
              :uuid="challengeUuid"
              :columns="commentsTableColumns"
              :headings="commentsTableHeadings"
              :create-modal-id="usageModalTypes.COMMENT_CHALLENGE"
              :recording="matchedRecording"
              :usage="selectedUsage"
              @fetched="setLatestSuggestedMatches"
            />
          </div>
        </template>
      </crescendo-split-panes>
    </template>
  </list-layout>
</template>
<script>
import {
  CHUNK_SIZE_UUIDS,
  actionsTabs,
  filterTypes,
  getPerPageItemDefaults,
  highlightDuration,
  inlineRowStatus,
  junkStatuses,
  linkTypes,
  openYears,
  reproductionLogsTariffs,
  usageMatchingStatuses, 
  usageModalTypes, 
  userActionNotificationStatuses,
  userActionNotifications,
  userPreferenceNames
} from "@/constants"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { capitalize, chunk, findIndex, get, range, startCase, truncate, upperCase } from "lodash"
import { cartMixin, listRouteMixin, unclaimedMixin } from "@/utils/mixins"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import CommentsTable from "@/components/CommentsTable"
import CrescendoSplitPanes from "@/components/CrescendoSplitPanes"
import DatabaseLink from "@/components/DatabaseLink"
import FilterBuilder from "@/components/FilterBuilder"
import JunkHistoryList from "@/pages/Usages/JunkHistoryList"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import { Pages } from "@/utils/pages"
import Pagination from "@/components/Pagination"
import UsageMatchedRecording from "@/pages/Usages/UsageMatchedRecording"
import UsageRecordingCandidates from "@/pages/Usages/UsageRecordingCandidates"
import UsagesExportForm from "./Forms/UsagesExportForm"
import { getMetadata } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import moment from "moment"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "ConsolidatedUsageList",
  mixins: [cartMixin, listRouteMixin, unclaimedMixin, websocketsMixin],
  components: {
    FilterBuilder,
    DatabaseLink,
    CrescendoSplitPanes,
    CommentsTable,
    UsageMatchedRecording,
    NamesListModal,
    Pagination,
    UsagesExportForm,
    UsageRecordingCandidates,
    ValidationObserver,
    ValidationProvider,
    JunkHistoryList,
  },
  props: {
    userAction: {
      default: false,
    },
    consolidatedUsage: {
      default: false,
    },
    usageList: {
      default: false,
    },
  },
  watch: {
    watchableSelectedData (newVal, oldVal) {
      if (newVal.length) {
        this.updateCandidates(newVal.map(item => item.id))
      } else {
        this.candidatesData = []
      }
      this.markingAsJunk = newVal.filter(d => !!d.isJunk).length * 2 <= newVal.length
      let oldIds = oldVal.map(item => item.id)
      let newIds = newVal.map(item => item.id)

      oldIds.forEach(id => {
        if (!newIds.includes(id) && this.openedUserActions.find(ua => ua.uuid === id)) {
          this.onClosed(id)
        }
      })
      newIds.forEach(id => {
        if (!oldIds.includes(id)) {
          this.onOpened(id)
        }
      })
      newVal.filter(s => s.status !== usageMatchingStatuses.MANUALLY_MATCHED).forEach((s, index) => {
        s.index = index + 1
      })
      oldVal.forEach(s => {
        if (!newIds.includes(s.id)) {
          s.index = null
        }
      })
    },
    challengeView (value) {
      if (value && this.latestSuggestedMatches.length) {
        this.updateUsageRecordingCandidates({
          uuids: this.latestSuggestedMatches,
        })
      } else {
        this.candidatesData = []
      }
    },
    latestSuggestedMatches (value) {
      if (value.length && this.challengeView) {
        this.updateUsageRecordingCandidates({ uuids: value })
      } else {
        this.candidatesData = []
      }
    },
    selectedUsageId (newVal) {
      if (newVal) {
        this.updateCandidates([newVal])
      } else {
        this.candidatesData = []
      }
    },
    "filters.user.value": function (newVal) {
      if (newVal) {
        if (this.user.email === newVal && this.statusesList.some(v => v === usageMatchingStatuses.MANUALLY_MATCHED)) {
          this.myMatches = true
        }
      } else {
        this.myMatches = false
      }
    },

    "filters.status.value": function (newValue) {
      if (newValue) {
        let newStatuses = newValue.split(",").map(s => s !== this.matchingStatuses.REVIEWED ? parseInt(s) : s)
        if (newStatuses.includes(usageMatchingStatuses.UNMATCHED) || newStatuses.includes(this.matchingStatuses.REVIEWED)) {
          this.changeFilter("hasCandidates", this.hasCandidatesValue)
          this.unmatchedView = true
        } else if (!newStatuses.includes(usageMatchingStatuses.UNMATCHED)) {
          this.unmatchedView = false
          this.hasCandidatesValue = this.filters.hasCandidates.value
          this.changeFilter("hasCandidates", "")
        }
      } else {
        this.unmatchedView = false
      }
    },
    "filters.copyright.value": function () {
      if (
        this.filters.tariff.value &&
          !this.tariffOptions.filter(t => t.value === this.filters.tariff.value).length
      ) {
        this.filters.tariff.value = this.filters.tariff.defaultValue
      }
    },
    "filters.reproductionLogs.value": function (newVal) {
      if (newVal) {
        this.filters.copyright.value = null
      }
    },
    myMatches (newVal) {
      if (newVal) {
        this.previousFilters = JSON.parse(JSON.stringify(this.filters)) // Deep cloning

        this.filters.user.value = {
          value: this.user.email,
          text: `${this.user.firstName} ${this.user.lastName}`,
        }
        this.filters.user.searchValue = this.user.email
        this.filters.status.value = this.filters.status.options.filter(v => v.value === usageMatchingStatuses.MANUALLY_MATCHED).join(",")
      } else {
        this.filters.user.value = this.previousFilters.user.value
        this.filters.status.value = this.previousFilters.status.value
        this.previousFilters = null
      }
    },
    wsUniqueUsageMatchingStatuses (newVal) {
      this.data.forEach(u => {
        u.actionStatus = u.id in newVal ? newVal[u.id].status : null
      })
    }
  },
  computed: {
    ...mapGetters("user", ["isInternal", "memberCollectiveUuid"]),
    ...mapState("consts", ["countries", "copyrights", "tariffs", "stations"]),
    ...mapState("user", ["user", "permissions", ]),
    ...mapState("actionTabs", ["userActionCounts"]),
    watchableSelectedData () {
      return [...this.selectedData]
    },
    tariffOptions () {
      let options = this.tariffs
      if (this.filters.copyright.value) {
        options = options.filter(t => t.copyright === this.filters.copyright.value)
      }

      if (this.filters.reproductionLogs.value) {
        options = options.filter(t => Object.values(reproductionLogsTariffs).includes(t.code) && !t.proxies.length)
      } else {
        options = options.filter(t => !Object.values(reproductionLogsTariffs).includes(t.code) || (t.proxies.length && this.isInternal))
      }

      options = options.map(c => {
        return { text: c.text, value: c.code }
      })
      return options
    },
    stationOptions () {
      return this.stations.map(c => ({ name: c.text, value: c.value }))
    },
    yearOptions () {
      let currentYear = new Date().getFullYear()
      return range(currentYear, currentYear - openYears, -1).map(y => {
        return { text: y, value: y }
      })
    },
    junkStatusOptions () {
      let result = Object.keys(junkStatuses).map(key => {
        return {
          text: capitalize(this.$t(key.toLowerCase())),
          value: junkStatuses[key],
        }
      })
      result.splice(0, 0, {
        text: capitalize(this.$t("all")),
        value: "",
      })
      return result
    },
    statusOptions () {
      return Object.keys(this.matchingStatuses).map(k => {
        return { name: capitalize(this.$t(k.toLowerCase())), value: this.matchingStatuses[k].toString() }
      })
    },
    columns () {
      let columns = []
      columns.push(
        "sequenceNumber",
        "uuid",
        "artist",
        "recordingArtist",
        "title",
        "recordingTitle",
        "release",
        "recordingReleases",
        "composers",
        "isrc",
        "recordingIsrc",
        "uniqueUsageRecordingType",
        "recordingType",
        "effectivePlaycount",
        "weightedPlaycount",
        "stations",
        "sources",
        "status",
        "history",
        "latestReview",
        "matchingInfo.matchedBy",
        "matchingInfo.matchedOn",
      )
      if (!this.isInternal) {
        columns = columns.filter(c => c !== "sources")
      }
      return columns
    },
    uuids () {
      if (this.statusesList.some(v => v === usageMatchingStatuses.UNMATCHED || v   === this.matchingStatuses.REVIEWED)) {
        return this.selectedData
          .filter(s => s.status !== usageMatchingStatuses.MANUALLY_MATCHED)
          .map(d => d.id)
      }
      return this.selectedUsageId ? [this.selectedUsageId] : []
    },
    recordingType () {
      if (this.statusesList.includes(usageMatchingStatuses.UNMATCHED) || this.statusesList.includes(this.matchingStatuses.REVIEWED)) {
        let types = this.selectedData
          .filter(s => s.status !== usageMatchingStatuses.MANUALLY_MATCHED)
          .map(d => d.uniqueUsageRecordingType)
        if (types.every(val => val === types[0])) {
          return types[0]
        }
      }
      return null
    },
    exportButtonTitle () {
      if (this.exportUnclaimed) {
        return capitalize(this.$t("export_repertoire"))
      } else {
        return capitalize(this.$t("export_usages"))
      }
    },
    exportBaseTitle () {
      return capitalize(this.$t("export_usages"))
    },
    dateRange: {
      get () {
        if (this.filters.matchingDateRange.value) {
          return [
            moment(
              this.filters.matchingDateRange.value[0],
              this.$data.filters.matchingDateRange.format,
              true
            ).toDate(),
            moment(
              this.filters.matchingDateRange.value[1],
              this.$data.filters.matchingDateRange.format,
              true
            ).toDate(),
          ]
        }
        return ["", ""]
      },
      set (val) {
        const range = (val[0] && val[1]) ?
          [
            this.parseDate(val[0], this.$config.ISO_DATE_FORMAT),
            this.parseDate(val[1], this.$config.ISO_DATE_FORMAT)
          ] : []

        if (range.length) {
          this.filters.userTimezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone
        } else {
          this.filters.userTimezone.value = this.filters.userTimezone.defaultValue
        }
        this.changeFilter("matchingDateRange", range)
      },
    },
    reviewedUsages () {
      return this.data.filter(u => u.latestReview !== null).map(u => ({
        ...u,
        uuid: u.id,
      }))
    },
    cancelIcon () {
      return this.advancedSearchText !== null ? ["fas", "times"] : null
    },
    shownUuidList () {
      return this.data.map(c => c.id)
    },
    statusesList () {
      return this.filters.status.value ? this.filters.status.value.split(",").map(s => s !== this.matchingStatuses.REVIEWED ? parseInt(s) : s) : []
    },
    bottomPaneVisible () {
      return (this.recordingUuid) || 
        (this.uuids.length && (this.unmatchedView || this.challengeView)) || 
        (this.recordingUuid && this.challengeView && this.challengeUuid && (typeof this.challengeUuid !== "boolean"))
    },
  },
  data () {
    return {
      actionsTabs,
      usageModalTypes,
      linkTypes,
      userActionNotificationStatuses,
      inlineRowStatus,
      usageMatchingStatuses,
      userPreferenceNames,
      data: [],
      candidatesLoading: false,
      recordingUuid: null,
      selectedUsageId: null,
      selectedUsage: null,
      challengeUuid: null,
      challengeView: false,
      viewingCart: false,
      selectedData: [],
      candidatesData: [],
      latestSuggestedMatches: [],
      loading: false,
      file: null,
      previousFilters: null,
      viewQA: false,
      myMatches: false,
      markingAsJunk: true,
      matchedRecording: null,
      junkComment: "",
      junkModalTitle: "",
      junkModalUsageId: "",
      unmatchedView: false,
      page: 1,
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      matchingStatuses: { ...usageMatchingStatuses, REVIEWED: "reviewed" },
      junkStatuses: junkStatuses,
      commentsTableColumns: [
        "created",
        "createdBy",
        "createdBy.memberCollective",
        "comment",
        "suggestedMatchId",
      ],
      commentsTableHeadings: {
        created: capitalize(this.$t("when")),
        createdBy: capitalize(this.$t("reviewer")),
        "createdBy.memberCollective": startCase(this.$t("mc")),
        comment: capitalize(this.$t("comment")),
        suggestedMatchId: this.$t("suggested_match_id"),
      },
      hasCandidatesValue: null,
      usageFilters: [
        "artist",
        "isrc",
        "title",
        "release",
        "status",
        "fuzzySearch",
      ],
      filters: {
        fuzzySearch: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("search")),
          type: filterTypes.TEXT,
        },
        artist: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("artist")),
          type: filterTypes.TEXT
        },
        title: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("title")),
          type: filterTypes.TEXT
        },
        release: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("album")),
          type: filterTypes.TEXT
        },
        stations: {
          value: "",
          defaultValue: "",
          dataType: String,
          type: filterTypes.MULTI_SELECT,
          label: capitalize(this.$t("stations")),
          options: [],
          placeholder: capitalize(this.$t("select_stations")),
        },
        isrc: {
          value: null,
          defaultValue: null,
          label: this.$t("isrc"),
          type: filterTypes.TEXT
        },
        file: {
          value: null,
          defaultValue: null,
          label: this.$t("file"),
          hidden: true
        },
        recordingArtist: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("recording_artist")),
          type: filterTypes.TEXT,
          hidden: true
        },
        recordingTitle: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("recording_title")),
          type: filterTypes.TEXT,
          hidden: true,
        },
        recordingRelease: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("recording_albums")),
          type: filterTypes.TEXT,
          hidden: true,
        },
        recordingIsrc: {
          value: null,
          defaultValue: null,
          label: this.$t("recording_isrc"),
          type: filterTypes.TEXT,
          hidden: true,
        },
        status: {
          value: null,
          defaultValue: null,
          dataType: String,
          label: capitalize(this.$t("status")),
          type: filterTypes.MULTI_SELECT,
          options: [],
          placeholder: capitalize(this.$t("select_status")),
        },
        user: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("user")),
          placeholder: capitalize(this.$t("search_user")),
          type: filterTypes.SELECT_SEARCH,
          searchName: "search",
          excludeName: "id",
          textField: "name",
          valueField: "email",
          listEndpoint: this.$api.users.usersSearch,
          params: {},
          noWatch: true,
        },
        copyright: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("copyright")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        tariff: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("tariff")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        hasCandidates: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("has_candidates")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [
            {
              text: capitalize(this.$t("with_candidates")),
              value: "true",
            },
            {
              text: capitalize(this.$t("without_candidates")),
              value: "false",
            },
          ],
          hidden: !this.unmatchedView,
        },
        year: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("year")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
        },
        reproductionLogs: {
          value: false,
          defaultValue: false,
          label: capitalize(this.$t("view_reprod_usages")),
          type: filterTypes.BOOLEAN,
          hidden: !this.isInternal,
        },
        rightsholderType: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("unclaimed_for")),
          type: filterTypes.SELECT,
          children: ["tariff", "year", "country"],
        },
        country: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("country")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          hidden: true,
        },
        artistPrefix: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("artist_prefix")),
          type: filterTypes.TEXT,
        },
        userTimezone: { value: null, defaultValue: null, hidden: true },
        matchingDateRange: {
          value: null,
          defaultValue: null,
          type: filterTypes.DATE_RANGE,
          format: "YYYY-MM-DD",
          label: capitalize(this.$t("matching_date")),
          noWatch: true
        },
        isJunk: {
          value: null,
          defaultValue: junkStatuses.IS_NOT_JUNK,
          label: capitalize(this.$t("junk_status")),
          type: filterTypes.SELECT,
          options: Object.keys(junkStatuses).map(key => {
            return {
              text: capitalize(this.$t(key.toLowerCase())),
              value: junkStatuses[key],
            }
          })
        },
        financialInterest: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT,
          label: capitalize(this.$t("with_financial_interest")),
          options: [
            {
              "text": capitalize(this.$t("have_financial_interest")),
              "value": "true",
            },
            {
              "text": capitalize(this.$t("dont_have_financial_interest")),
              "value": "false",
            }
          ],
          emptyValue: capitalize(this.$t("any")),
        },
        recording: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("recording")),
          type: filterTypes.SELECT_SEARCH,
          placeholder: capitalize(this.$t("search_recording")),
          excludeName: "id",
          valueField: "resoundId",
          textFunction: (r => `${r.artist} - ${r.title}`),
          searchName: "fuzzy_search",
          params: { light: true },
          listEndpoint: this.$api.repertoire.recordingsList,
          noWatch: true,
        },
        type: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("type")),
          type: filterTypes.SELECT,
          options: [
            {
              "text": capitalize(this.$t("sound_recording")),
              "value": "SR",
            },
            {
              "text": capitalize(this.$t("music_video")),
              "value": "MV",
            }
          ],
          emptyValue: capitalize(this.$t("all")),
        },
        recordingType: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("recording_type")),
          type: filterTypes.SELECT,
          options: [
            {
              "text": capitalize(this.$t("sound_recording")),
              "value": "SR",
            },
            {
              "text": capitalize(this.$t("music_video")),
              "value": "MV",
            }
          ],
          emptyValue: capitalize(this.$t("all")),
        }
      },
      selectedUser: "",
      options: {
        sortable: [
          "artist",
          "title",
          "release",
          "isrc",
          "effectivePlaycount",
          "weightedPlaycount",
          "status",
          "uniqueUsageRecordingType",
          "recordingTitle",
          "recordingArtist",
          "recordingIsrc",
          "recordingType",
          "matchingInfo.matchedOn",
          "matchingInfo.matchedBy"
        ],
        headings: {
          sequenceNumber: "",
          uuid: this.$t("usage_id"),
          title: capitalize(this.$t("title")),
          recordingTitle: capitalize(this.$t("recording_title")),
          artist: capitalize(this.$t("artist")),
          recordingArtist: capitalize(this.$t("recording_artist")),
          release: capitalize(this.$t("album")),
          composers: capitalize(this.$t("composers")),
          recordingReleases: capitalize(this.$t("recording_albums")),
          isrc: upperCase(this.$t("isrc")),
          recordingIsrc: this.$t("recording_isrc"),
          uniqueUsageRecordingType: capitalize(this.$t("type")),
          recordingType: capitalize(this.$t("recording_type")),
          effectivePlaycount: capitalize(this.$t("playcount")),
          weightedPlaycount: capitalize(this.$t("weighted_playcount_short")),
          stations: capitalize(this.$t("stations")),
          latestReview: capitalize(this.$t("latest_review")),
          sources: capitalize(this.$t("sources")),
          "matchingInfo.matchedBy": capitalize(this.$t("matched_by")),
          "matchingInfo.matchedOn": capitalize(this.$t("matched_on")),
          status: capitalize(this.$t("status")),
          history: "",
        },
        columnConfigurations: {
          qa: [
            "sequenceNumber",
            "uuid",
            "artist",
            "recordingArtist",
            "title",
            "recordingTitle",
            "release",
            "composers",
            "recordingReleases",
            "isrc",
            "recordingIsrc",
            "uniqueUsageRecordingType",
            "recordingType",
            "matchingInfo.matchedBy",
            "matchingInfo.matchedOn",
          ],
          default: [
            "sequenceNumber",
            "uuid",
            "artist",
            "title",
            "release",
            "composers",
            "isrc",
            "uniqueUsageRecordingType",
            "effectivePlaycount",
            "weightedPlaycount",
            "stations",
            "sources",
            "status",
            "history",
          ],
          usage: [
            "sequenceNumber",
            "uuid",
            "artist",
            "title",
            "release",
            "composers",
            "isrc",
            "uniqueUsageRecordingType",
            "effectivePlaycount",
            "weightedPlaycount",
            "stations",
            "status",
            "history",
          ]
        },
        selectableCheck: (row => !row.recordingId),
        width: {
          sequenceNumber: 2,
          history: 2,
        },
        hiddenColumns: ["latestReview"],
        rowClasses: (row) => {
          let classes = []
          if (row.status === usageMatchingStatuses.MANUALLY_MATCHED || row.status === usageMatchingStatuses.AUTOMATCHED) {
            classes.push("clickable")
          }
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          if (this.selectedUsageId === row.id) {
            classes.push("tabulator-selected")
          }
          if (row.status === inlineRowStatus.ERROR) {
            classes.push("row-error")
          }
          if (row.status === inlineRowStatus.SUCCESS) {
            classes.push("row-success")
          }
          return classes
        },
        responseAdapter ({ data }) {
          let componentData = this.$parent.$parent.$parent.$parent.$parent
          componentData.file = data.context
          componentData.data = data.results
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          componentData.options.showAllCheckbox = !componentData.viewingCart
          componentData.recordingUuid = null
          componentData.challengeUuid = null
          componentData.challengeView = false
          componentData.options.allSelected = false
          if (componentData.viewingCart) {
            return componentData.filterCartItems()
          }

          return this.$api.usages
            .consolidatedUsagesList(queryParams)
            .then(response => {
              response.data.results = response.data.results.map(r => {
                r.index = null
                r.actionStatus = null
                return r
              })
              return response
            })
            .catch(error => {
              if (error.response.data.fuzzySearch) {
                this.error(capitalize(this.$t("wrong_filters")))
              }
              componentData.$refs.fuzzySearchForm.setErrors(error.response.data)
            })
        },
      },
      // WS values
      userActionNotification: userActionNotifications.UNIQUE_USAGE,
      storeStatusesKey: "wsUniqueUsageMatchingStatuses",
      reloadMethod: this.getData,
      reloadMethodAfterHide: this.getData,
      Pages,
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", "getCopyrights", "getTariffs", "getStations"]),
    ...mapActions("userActions", ["subscribeUserActions", "unsubscribeUserActions"]),
    ...mapMutations("alert", ["success", "error"]),
    capitalize,
    truncate,
    getColumnsMode () {
      if (this.usageList) {
        return "usage"
      }
      return this.viewQA ? "qa" : "default"
    },
    getTitle () {
      if (this.userAction) {
        return this.$t("usage_matching")
      }
      if (this.consolidatedUsage) {
        return this.$t("consolidated_usages")
      }
      return ""
    },
    getComponentId () {
      if (this.userAction) {
        return userPreferenceNames.USAGE_MATCHING_FILTER
      }
      if (this.consolidatedUsage) {
        return userPreferenceNames.CONSOLIDATED_USAGES_FILTER
      }
      if (this.usageList) {
        return userPreferenceNames.USAGE_LIST_FILTER
      }
    },
    onFilter (name, value) {
      if (name === "recording") {
        if (value) {
          this.changeFilter(name, value.id)
        } else {
          this.changeFilter(name, null)
        }
      } else if (name === "matchingDateRange") {
        const range = (value) ?
          [
            this.parseDate(value[0], this.$config.ISO_DATE_FORMAT),
            this.parseDate(value[1], this.$config.ISO_DATE_FORMAT)
          ] : []

        if (range.length) {
          this.filters.userTimezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone
        } else {
          this.filters.userTimezone.value = this.filters.userTimezone.defaultValue
        }
        this.changeFilter("matchingDateRange", range)
      } else if (name === "user") {
        if (value) {
          this.changeFilter(name, value.value)
        } else {
          this.changeFilter(name, null)
        }
      }
    },
    getFiltersWithOptions () {
      for (const [key, data] of Object.entries(this.filters)) {
        if (key === "file" || key === "userTimezone") {
          continue
        }
        data.hidden = false
        if (this.usageList) {
          if (!this.usageFilters.includes(key)) {
            data.hidden = true
          }
        }
      }
      this.filters.hasCandidates.hidden = !this.unmatchedView
      this.filters.status.options = this.statusOptions
      if (!this.usageList) {
        this.filters.tariff.options = this.tariffOptions
        this.filters.year.options = this.yearOptions
        this.filters.stations.options = this.stationOptions
        this.filters.rightsholderType.options = this.rightsholderOptions
        this.filters.country.options = this.countryOptions
        this.filters.copyright.options = this.copyrights.map(c => {
          return { text: c.text, value: c.code }
        })
        this.filters.reproductionLogs.hidden = !this.isInternal
        this.filters.user.params = { member: this.memberCollectiveUuid }
        this.filters.recording.hidden = this.unmatchedView
        this.filters.recordingArtist.hidden = !this.viewQA
        this.filters.recordingTitle.hidden = !this.viewQA
        this.filters.recordingIsrc.hidden = !this.viewQA
        this.filters.recordingRelease.hidden = !this.viewQA
        this.filters.recordingType.hidden = !this.viewQA
      }
      return this.filters
    },
    showHistory (row) {
      this.junkModalUsageId = row.id
      this.junkModalTitle = `${capitalize(this.$t("junk_history"))} | ${row.artist} - ${row.title}`
      this.$bvModal.show("junk-history")
    },
    setMatchedRecordingObj (recording) {
      this.matchedRecording = recording
    },
    markAsJunk () {
      let data = {
        unique_usages: this.selectedData.map(s => s.id),
        is_junk: this.markingAsJunk,
        comment: this.junkComment,
      }
      this.$api.usages.markAsJunkConsolidatedUsages(data).then(() => {
        this.junkComment = ""
        this.selectedData = []
        this.success(capitalize(this.$t("usages_updated")))
        this.getData()
        this.$bvModal.hide("junk-modal")
      }).catch((error) => {
        let msg = `Error. ${JSON.stringify(error.response.data)}`
        this.error(msg)
      })
    },
    markAsJunkModal (junk=true) {
      this.markingAsJunk = junk
      this.$bvModal.show("junk-modal")
    },
    // Overwrite for a better control on websocket usage
    selectPage (tableRef) {
      if (!this.options.allSelected) {
        this.selectedData.push(
          ...tableRef.data
            .filter(d => !this.selectedData.map(r => r.id).includes(d.id) && !d.recordingId)
            .map(d => this.selectedRowData(d))
        )
        this.selectedData.forEach(d => {
          this.onOpened(d.id)
        })
      } else {
        this.selectedData = this.selectedData.filter(d => !tableRef.data.map(r => r.id).includes(d.id))
        this.releaseAll()
      }
    },
    getData () {
      this.$refs.consolidatedUsages.getData()
    },
    updateDetails (challenge) {
      let challengedUsage = this.$refs.consolidatedUsages.data.find(d => d.id === challenge.usageId)
      if (challengedUsage) {
        challengedUsage.pendingChallenge = challenge.id
      }
      this.challengeUuid = challenge.id
    },
    mergeCandidates (data) {
      let candidates = []
      data.forEach(item => {
        item.candidates.forEach(c => {
          let included = candidates.find(ic => ic.id === c.id)
          if (included) {
            included.rank = Math.max(included.rank, c.rank)
          } else {
            candidates.push(c)
          }
        })
      })
      return candidates
    },
    getMatchingInfo (row) {
      let matchingInfo = []
      if (row.status === usageMatchingStatuses.AUTOMATCHED) {
        matchingInfo = [
          capitalize(this.$t("automatched")),
          this.$t("on"),
          parseDate(row.matchingResultsUpdated, this.$config.ISO_DATE_FORMAT),
          this.$t("at"),
          parseDate(row.matchingResultsUpdated, this.$config.TIME_FORMAT),
        ]
      } else {
        matchingInfo = [
          capitalize(this.$t("matched_by")),
          row.matchingInfo.matchedBy,
          this.$t("on"),
          parseDate(row.matchingInfo.matchedOn, this.$config.ISO_DATE_FORMAT),
          this.$t("at"),
          parseDate(row.matchingInfo.matchedOn, this.$config.TIME_FORMAT),
        ]
      }
      return matchingInfo.join(" ")
    },
    getMatchOn (row) {
      let matchingInfo = []
      if (row.status === usageMatchingStatuses.AUTOMATCHED) {
        matchingInfo = [
          parseDate(row.matchingResultsUpdated, this.$config.ISO_DATE_FORMAT),
          parseDate(row.matchingResultsUpdated, this.$config.TIME_FORMAT),
        ]
      } else {
        matchingInfo = [
          parseDate(row.matchingInfo.matchedOn, this.$config.ISO_DATE_FORMAT),
          parseDate(row.matchingInfo.matchedOn, this.$config.TIME_FORMAT),
        ]
      }
      return matchingInfo.join(" ")
    },
    setMatchedRecording (item) {
      if (this.selectedUsageId === item.id) {
        this.selectedUsageId = null
        this.selectedUsage = null
        this.recordingUuid = null
      } else if (item.recordingId) {
        this.challengeView = false
        this.selectedUsageId = item.id
        this.selectedUsage = item
        this.recordingUuid = item.recordingId
        this.challengeUuid = item.pendingChallenge
      } else {
        this.recordingUuid = null
      }
    },
    selectedRowData (row) {
      return  {
        ...row,
      }
    },
    resetState () {
    },
    onLoaded ({ data }) {
      this.$nextTick(() => { this.$refs.panes.setHeight() })
      this.count = data.count
      this.summary = data.summary
      this.loading = false
      // we need this workaround to render selected checkboxes correctly
      const ids = this.selectedData.map(s => s.id)
      this.selectedData = data.results.filter(e => ids.includes(e.id) )
    },
    exportUsages () {
      this.$bvModal.show("usages-export-modal")
    },
    launchRematching () {
      this.$api.usages
        .usagesRematch()
        .then(() => this.success(capitalize(this.$t("rematching_launched"))))
        .catch(() => this.error(capitalize(this.$t("process_already_running"))))
    },
    rematchUsages () {
      this.$bvModal.show("usages-rematch-modal")
    },
    updateMatchingInfo (recording) {
      let activeUsage = this.$refs.consolidatedUsages.data.find(u => u.id === this.selectedUsageId)
      activeUsage.recordingId = recording.id
      this.recordingUuid = recording.id
      this.challengeUuid = null
      this.challengeView = false
    },
    setChallengeView () {
      this.challengeView = true
    },
    onCandidateMatched (recording) {
      const uuids = this.selectedData.map(s => s.id)
      this.$refs.consolidatedUsages.data
        .filter(u => uuids.includes(u.id))
        .map(u => {
          u.status = inlineRowStatus.SUCCESS
          u.matchingInfo = {}
          u.recording = recording
          u.recordingId = recording.id
        })
      setTimeout(() => {
        this.$refs.consolidatedUsages.data
          .filter(u => uuids.includes(u.id))
          .map(u => {
            u.status = usageMatchingStatuses.MANUALLY_MATCHED
          })
      }, highlightDuration)
      this.selectedData = []
      this.releaseAll()
    },
    onCandidateNotMatched () {
      const uuids =  this.selectedData.map(s => s.id)
      this.$refs.consolidatedUsages.data
        .filter(u => uuids.includes(u.id))
        .map(u => {
          u.status = inlineRowStatus.ERROR
        })
      setTimeout(() => {
        this.$refs.consolidatedUsages.data
          .filter(u => uuids.includes(u.id))
          .map(u => {
            u.status = usageMatchingStatuses.UNMATCHED
          })
      }, highlightDuration)
    },
    onReviewed () {
      const uuids = this.selectedData.map(s => s.id)
      this.$api.usages
        .usagesReviewHistoryList({ uuids: uuids })
        .then(response => {
          this.$refs.consolidatedUsages.data
            .filter(u => uuids.includes(u.id))
            .map(u => (u.latestReview = response.data.filter(r => r.usageId === u.id)[0].reviewDate || null))
          this.selectedData = []
        })
        .catch(error => {
          let msg = `Error. ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
      this.releaseAll()
    },
    getUnmatchedText (row) {
      const txt = capitalize(this.$t("unmatched"))
      if (row.latestReview !== null) {
        if (row.matchingResultsUpdated === null || (moment(row.latestReview) > moment(row.matchingResultsUpdated))) {
          return txt + " " + capitalize(this.$t("reviewed"))
        }
      }
      return txt
    },
    updateCandidates (uuids, params = {}) {
      this.candidatesLoading = true
      let requests = []
      const chunks = chunk(uuids, CHUNK_SIZE_UUIDS)
      for (const chunk of chunks) {
        requests.push(this.$api.usages.usagesCandidatesList({ uuids: chunk, ...params }))
      }
      Promise.all(requests)
        .then((values) => {
          const candidatesData = values.reduce((data, response) => [...data, ...response.data], [])
          this.candidatesData = this.mergeCandidates(candidatesData)
          this.candidatesLoading = false
        })
        .catch((e) => {
          if (e.response.data.fuzzySearch) {
            this.error(capitalize(this.$t("wrong_filters")))
          }
          else {
            this.error(capitalize(this.$t("wrong_uuids_specified")))
          }
        })
    },
    searchCandidates (data) {
      this.updateCandidates(this.uuids, data)
    },
    setLatestSuggestedMatches (uuids) {
      this.latestSuggestedMatches = uuids
    },
    updateUsageRecordingCandidates (params) {
      this.$api.repertoire
        .recordingsList(params)
        .then(res => {
          this.candidatesData = res.data.results
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.error(capitalize(this.$t("wrong_uuids_specified")))
          }
        })
    },
    parseDate,
    findIndex,
    getMetadata,
  },
  mounted () {
    if (!this.copyrights.length) {
      this.getCopyrights()
    }
    if (!this.tariffs.length) {
      this.getTariffs()
    }
    if (!this.stations.length) {
      this.getStations()
    }
    if (!this.countries.length) {
      this.getCountries()
    }
    this.updateTableSortIcons(this.$refs.consolidatedUsages.$children[0], true)

    this.subscribeUserActions()
    let userId = get(this.$route.query, "user", "")
    if (userId) {
      this.filters.user.searchValue = userId
    }
    let recordingId = get(this.$route.query, "recording")
    if (recordingId) {
      this.filters.recording.searchValue = recordingId
    }
    let dateRange = get(this.$route.query, "matching_date_range")
    if (dateRange) {
      this.filters.matchingDateRange.value = [
        moment(
          dateRange[0],
          this.$data.filters.matchingDateRange.format,
          true
        ).toDate(),
        moment(
          dateRange[1],
          this.$data.filters.matchingDateRange.format,
          true
        ).toDate(),
      ]
    }
  },
  destroyed () {
    this.unsubscribeUserActions()
  },
}
</script>
<style lang="scss" scoped>
@import "tabulator-tables/src/scss/themes/bootstrap/tabulator_bootstrap4.scss";

/* stylelint-disable */
.VueTables::v-deep {
  &.VueTables--server,
  &.VueTables--client {
    .table-responsive {
      height: 100%;
      margin-bottom: 0.5em;

      .table {
        min-width: 2100px;
        table-layout: auto !important;
      }

      tr {
        &.hover-highlight {
          &:hover {
            cursor: pointer;
            background-color: $red-light;
          }
        }

        .sequence-number {
          width: 2rem;
        }

        th {
          padding-left: 0;

          span {
            padding: 0;
          }
        }

        td {
          padding: 12px 1px;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1920px) {
  .VueTables::v-deep {
    &.VueTables--server,
    &.VueTables--client {
      max-width: 91vw;
      margin: auto;
    }
  }
}

::v-deep .tabulator-unselectable:hover {
  background-color: $rowHoverBackground;
}

/* stylelint-enable */

.tab-content {
  height: 100vh;
}

.p-15 {
  padding-left: 15px;
}
</style>
