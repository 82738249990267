<template>
  <list-layout
      :isChild="true"
      :title="$t('recording_challenges_actions') | startcase"
      :count="userActionCounts.matchingChallengeActionsCount"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
  >

    <template v-slot:search>
      <form-field
        class="file-search"
        :placeholder="$t('search') | capitalize"
        v-model="filters.fuzzySearch.value"
        :enter-action="applyFilters"
        :icon="['fas', 'search']"
      />
    </template>
    <template v-slot:filters>
      <b-row align-v="center" align-h="end">
        <b-col cols="4">
          <b-form-group
            label-cols="auto"
            :label="$t('challenged_on') | capitalize"
          >
            <date-field
              v-model="filters.date.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-checkbox
            switch
            v-model="filters.showSystemRaised.value"
          >
            {{ $t('show_system_challenges') | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>

    <template v-slot:counts>
      <pagination
          class="smaller p-0 mb-3 mt-3"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="perPage"
          @limit="onLimit"
      />
    </template>

    <b-card no-body v-for="(challenge, index) in data" :key="index" :class="{ 'blink': shouldBlink(challenge.id), 'd-none': isHidden(challenge.id)}">
      <modal
        :id="`confirmation-modal-solved-${challenge.id}`"
        :customTitle="$t('user_action_solved') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="md"
        centered
        hide-footer
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
      >
        <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
        <b-row class="float-right modal-footer mt-4">
          <b-button @click="blinkAndHide(challenge.id, `landing-${challenge.id}`, getData)" class="btn-outline-red">
            {{ $t("accept") | capitalize }}
          </b-button>
        </b-row>
      </modal>
      <b-card-header class="first-level">
        <b-row align-v="center">
          <b-col cols="6">
            <fa-icon
              v-if="isInProgressFrontend(challenge.id) || (isInProgressBackend([challenge.status]) && !isReleasedFrontend(challenge.id))"
              :icon="['fa', 'user-edit']"
               color="red"
            />
            <collapse-button class="collapse-button" :toggle="`landing-${challenge.id}`" :expanded="expanded[index]" :disabled="loading" />
            {{ challenge.title }}
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <b-row class="w-100">
              <b-col cols="2">
                {{ $t('playcount') | capitalize }}: {{ challenge.playCount }}
              </b-col>
              <b-col cols="3">
                {{ $t('royalties_paid') | capitalize }}:
                <span class="smaller">
                  CAD
                </span>
                {{ challenge.royaltiesPaid }}
              </b-col>
              <b-col cols="3">
                {{ $t('estimated_track_value') | capitalize }}:
                <span class="smaller">
                  CAD
                </span>
                {{ challenge.estimatedTrackValue }}
              </b-col>
              <b-col cols="4">
                {{ $t('challenged_on') | capitalize }}:
                {{ parseDate(challenge.created, $config.DATE_FORMAT) }}
              </b-col>
              <b-col cols="4">
                {{ $t('by') | capitalize }}:
                {{ challenge.raisedBy ? challenge.raisedBy.memberCollective : $t("crescendo_system") }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse accordion="landing-recordings-list" :id="'landing-' + challenge.id" v-model="expanded[index]" role="tabpanel">
        <b-card-body v-if="expanded[index]">
          <recording-challenge-detail
            :challenge="challenge"
            :solved-manually="solvedManually"
            :onDone="onDone"
            @done="onDone"
            @opened="onOpened"
            @closed="onClosed"
          />
        </b-card-body>
      </b-collapse>
    </b-card>
    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="count"
      :per-page="perPage"
    />
  </list-layout>
</template>
<script>
import { getPerPageItemDefaults, userActionNotifications, userActionStatuses } from "@/constants"
import { mapActions, mapState } from "vuex"
import Pagination from "@/components/Pagination"
import { get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "RecordingChallengeList",
  mixins: [listRouteMixin, websocketsMixin],
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
  },
  data () {
    return {
      count: 0,
      page: 1,
      loading: true,
      data: [],
      expanded: [],
      perPage: Number(getPerPageItemDefaults(this.$route)),
      filters: {
        fuzzySearch: { value: "", defaultValue: "" },
        date: { value: "", defaultValue: "" },
        showSystemRaised: { value: false, defaultValue: false },
      },
      // WS values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.USER_ACTION,
      storeStatusesKey: "wsUserActionStatuses",
    }
  },
  components: {
    RecordingChallengeDetail: () => import("./RecordingChallengeDetail"),
    Pagination,
  },
  watch: {
    page (value) {
      this.onPagination(value)
      this.getData()
    },
    perPage () {
      if (this.page === 1) {
        this.getData()
      } else {
        this.page = 1
      }
    },
  },
  methods: {
    ...mapActions("actionTabs", ["decrementUserActionCount"]),
    onDone () {
      this.decrementUserActionCount("matchingChallengeActionsCount")
      this.getData()
    },
    responseAdapter (resp) {
      if (resp) {
        this.data = resp.data.results
        this.count = resp.data.count
      }
      this.loading = false
    },
    getData () {
      this.loading = true
      this.$api.actions.recordingChallengeActions({
        ...this.$route.query, status: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS],
      })
        .then((resp) => {
          this.responseAdapter(resp)
        })
        .finally(() => {
          this.expanded = []
        })
      
    },
    parseDate
  },
  mounted () {
    this.getData()
    this.page = Number(get(this.$route.query, "page", 1))
    this.perPage = Number(get(this.$route.query, "limit", this.perPage))
  },
}
</script>
<style lang="scss" scoped>
  ::v-deep .smaller {
    font-size: 10pt;
  }
  .card {
    margin-bottom: 10px;
  }
</style>
