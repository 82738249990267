<template>
  <div>
    <b-container class="p-0">
      <t-table
        id="usage-challenge-info"
        :columns="['title', 'artist', 'isrc', 'release']"
        :local-data="[usage]"
        :options="usageInfoOptions"
      >
        <template v-slot:counts>
          <span class="text-black">
            {{ $t("usage_info") | capitalize }}
          </span>
        </template>
      </t-table>

      <div v-if="matchIdDisabled" class="row d-flex align-items-center">
        <div class="col">
          <b-form-group :label="$t('current_matched_recording') | capitalize">
            <b-form-input disabled v-model="suggestedMatchId"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div v-else class="row d-flex align-items-center">
        <div class="col">
          <b-form-group :label="$t('search_suggested_match_id')">
            <form-field
                :placeholder="$t('search') | capitalize"
                v-model="recordingsSearch"
                debounce="1000"
                :icon="['fas', 'search']"
            />
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group :label="$t('challenge_without_rs_id')">
            <b-form-radio v-model="suggestedMatchId" value="" name="selected-recording" @change="onChange">{{ this.id === usageModalTypes.COMMENT_CHALLENGE ? $t('comment_without_recording') : $t('challenge_without_recording')  | capitalize }}</b-form-radio>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group :label="$t('recording_type') | capitalize">
            <b-form-checkbox v-if="recording.type !== null" v-model="showSameType" variant="outline-secondary" switch>
              {{ $t("show_same_type") | capitalize }}
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
      <b-row>
        <b-col>
          <t-table
            v-if="recordingsSearch"
            ref="recordingsChallengeSearch"
            id="recordingsChallengeSearch"
            :columns="recordingsColumns"
            :options="options"
            :limit="limit"
            @loaded="onLoaded"
            @loading="onLoading"
            @pagination="onPagination"
            @sorted="onSorted"
          >
            <template v-slot:counts>
              <div class="d-flex">
                <pagination
                  class="smaller p-0"
                  :page="page"
                  :count="count"
                  :loading="loading"
                  v-model="limit"
                  @limit="onLimit"
                />
              </div>
            </template>
            <!-- RS ID -->
            <template v-slot:resoundId="{ row }">
              <div class="d-flex ml-1">
                <b-form-radio v-model="suggestedMatchId" :value="row.resoundId" name="selected-recording" @change="onChange"/>
                <database-link :type="linkTypes.RECORDING" :item-id="row.id" target="_blank">
                  {{ row.resoundId }}
                </database-link>
              </div>
            </template>
            <!-- Album Names -->
            <template v-slot:albumNames="{ row }">
              <NamesListModal class-names="table-meta mb-2" :values="row.albumNames || []" />
            </template>
            <!-- Proprietary ids -->
            <template v-slot:localIds="{ row }">
              <NamesListModal
                class-names="table-meta mb-2"
                :values="row.localIds || []"
              />
            </template>
          </t-table>
        </b-col>

        
        <t-table
          class="h-75 pb-0"
          ref="candidatesTable"
          id="candidates"
          :columns="candidatesColumns"
          :options="candidatesOptions"
          :local-data="suggestedRecordingsData"
          :limit="perPage"
          @pagination="onPage"
          @sorted="onSorted"
        >
          <template v-slot:counts>
            <div class="row mb-2 d-flex align-items-center">
              <div class="col d-flex justify-content-start title">
                <h3>{{ $t("suggested_recordings") | startcase }}</h3>
                <b-spinner class="ml-2" v-if="candidatesLoading" variant="light"/>
                <fa-icon v-if="candidateRecordingsSearch && !candidatesLoading" class="ml-2 mt-1" :icon="['fas', 'search']" size="lg"/>
              </div>
            </div>
          </template>

          <template v-slot:filters>
            <div class="row mb-2 d-flex align-items-center">
              <div class="col d-flex justify-content-end">
                <div class="bg-white" v-if="uuids.length">
                    <div class="row mb-2 d-flex align-items-end">
                      <div class="col d-flex justify-content-end">
                        <b-form-group class="m-0" v-if="!challengeView">
                          <form-field
                              :placeholder="$t('search') | capitalize"
                              v-model="candidateRecordingsSearch"
                              debounce="1000"
                              :icon="['fas', 'search']"
                          />
                          <b-form-checkbox v-model="candidateRecordingsSearchSameType" variant="outline-secondary" switch :disabled="!!candidatesRecordingsSearchInProgress">
                            {{ $t("show_same_type") | capitalize }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </template>

          <!-- RS ID -->
          <template v-slot:resoundId="{ row }">
            <div class="d-flex ml-1">
              <b-form-radio v-model="suggestedMatchId" :value="row.resoundId" name="selected-recording" @change="onChange"/>
              <database-link :type="linkTypes.RECORDING" :item-id="row.id" target="_blank">
                {{ row.resoundId }}
              </database-link>
            </div>
          </template>
          <!-- Artist -->
          <template v-slot:artist="{ row }">
            <span :title="row.artist">{{ row.artist }}</span>
          </template>
          <!-- Title -->
          <template v-slot:title="{ row }">
            <span class="short-text" :title="row.title">{{ row.title }}</span>
          </template>
          <!-- Albums -->
          <template v-slot:album="{ row }">
            <span :title="getMetadata(row.extras, 'release').join(', ')">{{ getMetadata(row.extras, 'release').join(", ") }}</span>
          </template>
          <!-- Composers -->
          <template v-slot:composer="{ row }">
            <span :title="getMetadata(row.extras, 'composer').join(', ')">{{ getMetadata(row.extras, 'composer').join(", ") }}</span>
          </template>
          <!-- First Maker Name -->
          <template v-slot:firstMakerNames="{ row }">
            <span :title="row.firstMakers ? row.firstMakers.map(f => f.name) : ''">
              {{ row.firstMakers && row.firstMakers.map(f => f.name).join(", ") }}
            </span>
          </template>
          <!-- Nationality -->
          <template v-slot:nationality="{ row }">
            {{ row.firstMakerNationalityIds ? countries.filter(c => row.firstMakerNationalityIds.includes(c.value)).map(n => n.alpha2).join(", ") : "" }}
          </template>
          <!-- Fixation Country -->
          <template v-slot:fixationCountry="{ row }">
            <span :title="row.fixationCountry ? row.fixationCountry.name : ''">
              {{ row.fixationCountry ? row.fixationCountry.name : "" }}
            </span>
          </template>
          <!-- Duration -->
          <template v-slot:duration="{ row }">
            <span>
              {{ row.duration ? $options.filters.toDuration(row.duration) : "" }}
            </span>
          </template>
          <!-- Performers Owners FinancialInterest -->
          <template v-slot:hasPerformersOwnersFinancialInterest="{row}">
            <div class="d-flex">
              <div v-if="row.hasPerformers" class="icon-wrapper icon-width" >
                <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'microphone-alt']"/>
              </div>
              <div v-if="row.hasOwners" class="icon-wrapper icon-width" >
                <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'compact-disc']"/>
              </div>
              <div v-if="showFinancialInterest && row.hasFinancialInterest" class="icon-wrapper icon-width" >
                <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'dollar-sign']"/>
              </div>
            </div>
          </template>
        </t-table>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12">
          <b-form-group :label="$t('comment') | capitalize">
            <b-form-textarea v-model="comment" rows="5"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <div class="d-flex modal-footer mt-4">
      <b-button class="btn-outline-dark" @click="cancel">
        {{ $t("cancel") | capitalize }}
      </b-button>
      <b-button
        :disabled="!comment || isLoading"
        class="btn-outline-red"
        @click="sendItem">
        {{ $t("apply") | capitalize }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { capitalize, startCase, upperCase } from "lodash"
import { cartMixin, listRouteMixin } from "@/utils/mixins"
import { linkTypes, perPageItemsSm, usageModalTypes } from "@/constants"
import { mapActions, mapMutations } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Pagination from "@/components/Pagination"
import { getMetadata } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"

export default {
  name: "UsageChallengeForm",
  mixins: [cartMixin, listRouteMixin],
  components: {
    DatabaseLink,
    NamesListModal,
    Pagination,
  },
  props : {
    id: String,
    usage: Object,
    challengeId: String,
    recording: Object,
    refreshHandle: Function,
    
    uuids: {
      type: Array,
      default: () => [],
    },
    reviewedUsages: {
      type: Array,
      default: () => [],
    },
    candidates: {
      type: Array,
      default: () => [],
    },
    challengeUuid: {
      type: String,
      default: null,
    },
    challengeView: {
      type: Boolean,
      default: false,
    },
    recordingType: {
      type: String,
      default: null,
    },
    showFinancialInterest: {
      type: Boolean,
      default: false,
    },
    candidatesLoading: {
      type: Boolean,
      default: false,
    },
    suggestedRecordingsData: {
      type: Array,
      default: () => []
    },
    solvedManually: {
      type: Array,
      required: false,
    }
  },
  data () {
    return {
      showSameType: true,
      sortOptions: [],
      updateRouterOnPagination: false,
      limit: perPageItemsSm,
      perPage: perPageItemsSm,
      count: 0,
      loading: false,
      page: 1,
      linkTypes,
      comment: "",
      isLoading: false,
      suggestedMatchId: this.recording && ![usageModalTypes.CHALLENGE_MATCH, usageModalTypes.COMMENT_CHALLENGE].includes(this.id) ? this.recording.resoundId : "",
      usageModalTypes,
      recordingsSearch: "",
      skipFirstRequest: true,
      getMetadata,
      recordingsColumns: [
        "resoundId",
        "title",
        "artist",
        "type",
        "isrc",
        "fixationYear",
        "localIds",
      ],
      usageInfoOptions: {
        sortable: [],
        headings: {
          title: capitalize(this.$t("title")),
          artist: capitalize(this.$t("artist")),
          isrc: this.$t("isrc"),
          release: capitalize(this.$t("album")),
        }
      },
      options: {
        allSelected: false,
        sortable: ["resoundId", "title", "artist", "isrc", "type", "fixationYear"],
        headings: {
          "resoundId": this.$t("resound_id"),
          "title": capitalize(this.$t("title")),
          "artist": capitalize(this.$t("artist")),
          "isrc": this.$t("isrc"),
          "type": capitalize(this.$t("type")),
          "fixationYear": capitalize(this.$t("fixation_year")),
          "albumNames": capitalize(this.$t("albums")),
          "localIds": this.$t("proprietary_ids"),
        },
        rowClasses: (row) => {
          if (row.resoundId === this.suggestedMatchId) {
            return ["selected"]
          }
          return []
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          const componentData = this.$parent
          queryParams = {
            fuzzy_search: componentData.recordingsSearch,
            page: componentData.page,
            limit: componentData.limit,
            type: componentData.showSameType ? componentData.recording.type : "",
          }
          if (componentData.sortOptions) {
            queryParams["sorting"] = componentData.sortOptions
          }
          return this.$api.repertoire.recordingsList(queryParams)
        },
      },
      candidatesColumns: [
        "resoundId",
        "artist",
        "title",
        "album",
        "composer",
        "isrc",
        "firstMakerNames",
        "nationality",
        "fixationCountry",
        "fixationYear",
        "releaseYear",
        "type",
        "duration",
        "genreType",
        "rank",
        "hasPerformersOwnersFinancialInterest",
        "actions",
      ],
      candidatesOptions: {
        sortable: ["resoundId","artist","title","isrc","fixationCountry","fixationYear","releaseYear","type","duration","genreType","rank"],
        sorters: {
          fixationCountry (a, b) {
            if (typeof a.fixationCountry?.name === "undefined") return -1
            if (typeof b.fixationCountry?.name === "undefined") return 1
            return a.fixationCountry?.name.localeCompare(b.fixationCountry?.name)
          },
          isrc: "string",
          genreType: "string",
        },
        headings: {
          "artist": capitalize(this.$t("artist")),
          "title": capitalize(this.$t("title")),
          "album": capitalize(this.$t("album")),
          "composer": capitalize(this.$t("composer")),
          "isrc": upperCase(this.$t("isrc")),
          "resoundId": this.$t("resound_id"),
          "firstMakerNames": startCase(this.$t("first_makers")),
          "nationality": upperCase(this.$t("fmn")),
          "fixationYear": upperCase(this.$t("yof")),
          "releaseYear": upperCase(this.$t("yor")),
          "fixationCountry": upperCase(this.$t("cof")),
          "type": capitalize(this.$t("type")),
          "duration": capitalize(this.$t("dur")),
          "genreType": startCase(this.$t("genre")),
          "rank": capitalize(this.$t("rank")),
          "hasPerformersOwnersFinancialInterest": "",
          "actions": "",
        },
        columnsClasses: {
          artist: "width-mini short-text",
          title: "width-mini short-text",
          album: "width-micro-big short-text",
          composer: "width-micro-big short-text",
          isrc: "width-micro-big",
          resoundId: "width-micro-big",
          firstMakerNames: "width-micro-big short-text",
          nationality: "width-micro short-text",
          fixationYear: "width-nano-big",
          releaseYear: "width-nano-big",
          fixationCountry: "width-micro short-text",
          type: "width-nano-big",
          duration: "width-nano-big",
          genreType: "width-nano-big",
          hasPerformersOwnersFinancialInterest: "pl-0 pr-0 text-right",
          rank: "width-2 pl-0 pr-0 text-right",
        },
        width: {
          hasPerformersOwnersFinancialInterest: 50,
          actions: 60,
        },
      },
    }
  },
  watch: {
    recordingsSearch () {
      if (this.$refs.recordingsChallengeSearch) {
        this.page = 1
        this.$refs.recordingsChallengeSearch.page = 1
        this.$refs.recordingsChallengeSearch.getData()
      }
    },
    showSameType () {
      if (this.$refs.recordingsChallengeSearch) {
        this.page = 1
        this.$refs.recordingsChallengeSearch.page = 1
        this.$refs.recordingsChallengeSearch.getData()
      }
    },
  },
  computed: {
    matchIdDisabled () {
      return [usageModalTypes.KEEP_RECORDING, usageModalTypes.USE_SUGGESTED_RECORDING].includes(this.id)
    }
  },
  methods: {
    onChange (checked) {
      this.suggestedMatchId = checked
    },
    ...mapMutations("alert", ["success", "error"]),
    ...mapActions("userActions", ["getUserActionsSummary"]),
    getData () {
      this.$refs.recordingsChallengeSearch.getData()
    },
    sendItem () {
      switch (this.id) {
      case usageModalTypes.CHALLENGE_MATCH:
        this.challengeUsage()
        break
      case usageModalTypes.COMMENT_CHALLENGE:
        this.createComment()
        break
      case usageModalTypes.KEEP_RECORDING:
      case usageModalTypes.USE_SUGGESTED_RECORDING:
        this.resolveChallenge()
        break
      default:
        this.error(`${this.$t("wrong_modal_type")} ${this.id}`)
      }
    },
    resolveChallenge () {
      let data = {
        comments: [{ comment: this.comment }],
        suggestedMatchId: this.suggestedMatchId,
      }
      if (this.solvedManually) {
        this.solvedManually.push(this.challengeId)
      }
      this.isLoading = true
      this.$api.actions.resolveUsageMatchingChallenge(this.challengeId, data)
        .then((result) => {
          this.isLoading = false
          this.$bvModal.hide(this.id)
          this.success(capitalize(this.$t("usage_challenge_resolved")))
          this.$emit("resolved", result.data.recording)
          this.getUserActionsSummary({ partial: true })
        })
        .catch((error) => {
          this.isLoading = false
          let msg = `Error selecting recording. ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    challengeUsage () {
      let data = {
        comments: [{ comment: this.comment }],
        suggestedMatchId: this.suggestedMatchId,
        usageId: this.usage.id,
      }
      this.isLoading = true
      this.$api.actions.usageChallengeCreate(data)
        .then(response => {
          this.isLoading = false
          let msg = `Usage challenge created: ${response.statusText}`
          this.success(msg)
          this.$emit("challenged", response.data)
          this.$bvModal.hide(this.id)
          this.getUserActionsSummary({ partial: true })
        })
        .catch(error => {
          this.isLoading = false
          let msg = `Challenge error: ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    createComment () {
      let data = {
        comment: this.comment,
      }
      if (this.suggestedMatchId) {
        data.suggestedMatchId = this.suggestedMatchId
      }
      this.isLoading = true
      this.$api.comments.usageChallengeCommentCreate(this.challengeId, data)
        .then(response => {
          this.isLoading = false
          let msg = `Usage challenge comment created: ${response.statusText}`
          this.success(msg)
          this.$bvModal.hide(this.id)
          if (this.refreshHandle) {
            this.refreshHandle()
          }
        })
        .catch(error => {
          this.isLoading = false
          let msg = `Comment error: ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    cancel () {
      this.$bvModal.hide(this.id)
    },
    onPage (page) {
      this.page = page
      this.getData()
    },
    onSorted (sorting) {
      this.sortOptions = sorting
      this.getData()
    },
    candidateRecordingsSearch (newVal) {
      let data ={ fuzzy_search: newVal }
      if (this.candidateRecordingsSearchSameType) {
        data.type = this.recordingType
      }
      this.$emit("candidates-search-changed", data)
    },
    candidateRecordingsSearchSameType (newVal) {
      let data = {}
      if (this.candidateRecordingsSearch) data.fuzzy_search =this.candidateRecordingsSearch
      if (newVal) data.type = this.recordingType
      this.$emit("candidates-search-changed", data)
    },
  },
}
</script>

<style lang="scss" scoped>
  .counts span.text-black {
    color: black !important;
  }
  .t-table {
    padding-bottom: 0;
    ::v-deep .h-100 {
      height: fit-content !important;
    }
  }
  ::v-deep .tabulator-row.selected {
    background-color: $green;

    &:hover {
      background-color: $dark-green !important;

      .tabulator-cell:not(:has(.more-values-icon), :has(input)):hover > span {
        background-color: $dark-green !important;
      }
    }
  }
</style>
