<template>
  <div>
    <list-layout :isChild="false" :title="$t('copyrights_and_tariffs') | startcase">
      <template v-slot:controls>
        <b-row class="w-100">
          <b-col offset="5">
            <b-form-group>
              <b-form-radio-group
                v-model="selectedPanel"
                :options="panelOptions"
                buttons
                button-variant="outline-primary"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col class="pr-0">
            <div class="float-right" v-if="selectedPanel === 'eligibility'">
              <span class="button mr-2"
                v-if="permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
                ><b-button
                  @click="cancel()"
                  :class="getButtonClass('cancel')"
                  :disabled="!deleteMode && !existChanges"
                >
                  <fa-icon class="mr-2" :icon="['fas', 'times']" />
                  Cancel
                </b-button></span
              >
              <span class="button mr-2" 
                v-if="permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
              >
                <b-button
                  :class="getButtonClass('delete')"
                  @click="setDeleteMode()"
                  :disabled="deleteMode || adding"
                >
                  <fa-icon class="mr-2" :icon="['fas', 'trash']" />
                  Delete
                </b-button>
              </span>
              <span class="button"
                v-if="permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
              >
                <b-button
                  :class="getButtonClass('save')"
                  @click="updateEligibility()"
                  :disabled="!existChanges"
                >
                  <fa-icon class="mr-2" :icon="['fas', 'check']" />
                  <span>Save</span>
                </b-button></span
              >
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-slot:table>
        <b-table
          :fields="columns"
          :items="tariffs"
          class="smaller mb-0"
          striped
          fixed
        >
          <template v-slot:cell(eligibilityParameter)="row">
            <ParamSelect
              v-for="(period, index) in row.item.eligibilityPeriods"
              :key="`period-${index}`"
              :param="{ tariffId: row.item.tariffId, paramId: period.id }"
              :paramIndex="index"
              :disabled="!permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
            />
            <span
              class="add clickable"
              @click="addNewParameter(row.item.tariffId)"
              v-if="permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
            >
              <fa-icon size="sm" class="mr-2" :icon="['fa', 'plus-circle']" />{{
                $t("add_new_parameter") | capitalize
              }}
            </span>
          </template>

          <template v-slot:cell(eligibilityStartDate)="row">
            <ParamDates
              :param="{ tariffId: row.item.tariffId, paramId: period.id }"
              :dateType="'startDate'"
              v-for="(period, index) in row.item.eligibilityPeriods"
              :key="`period-${index}`"
              :disabled="!permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
            />
          </template>

          <template v-slot:cell(eligibilityEndDate)="row">
            <ParamDates
              :param="{ tariffId: row.item.tariffId, paramId: period.id }"
              :dateType="'endDate'"
              v-for="(period, index) in row.item.eligibilityPeriods"
              :key="`period-${index}`"
              :disabled="!permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
            />
          </template>

          <template v-slot:cell(threshold)="row">
            {{ thresholds.find(
                  t =>
                    t.tariff === row.item.tariffCode &&
                    t.year === new Date().getFullYear()
                ).threshold
            }}
          </template>

          <template v-slot:cell(year)="row">
            {{ new Date().getFullYear() }}
            <fa-icon
              @click="showModal(row.index)"
              class="clickable ml-4 more-values-icon"
              size="sm"
              :icon="['fas', 'pen']"
              v-if="
                thresholds.filter(t => t.tariff === row.item.tariffCode)
                  .length > 1
              "
            />
            <modal
              :id="`history-${row.index}`"
              :customTitle="row.item.tariffName"
              modal-class="custom-modal edit"
              modalType="edit"
              size="lg"
              centered
              @ok="updateThresholds(row.item.tariffCode)"
              :ok-disabled="!permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
              :okTitle="$t('save') | capitalize"
              footer-class="pl-5 pr-5">
              <b-table
                :items="getHistory(row.item.tariffCode)"
                :fields="historyFields"
                :current-page="page"
                class="smaller mb-0"
                striped
                fixed
              >
                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.width }"
                  />
                </template>
                <template v-slot:cell(threshold)="subrow">
                  <b-input
                    type="number"
                    min="0"
                    step="1"
                    :disabled="!permissions.pages[Pages.CopyrightsAndTariffs].canWrite"
                    v-model="
                      thresholds.find(
                        t =>
                        t.tariff === row.item.tariffCode &&
                        t.year === subrow.item.year
                      ).threshold
                    "
                  />
                </template>
              </b-table>

              <table-pagination
                class="mt-3"
                v-model="page"
                :total-rows="
                  thresholds.filter(t => t.tariff === row.item.tariffCode)
                    .length
                "
                :per-page="perPage"
              />
            </modal>
          </template>
        </b-table>
      </template>
    </list-layout>
  </div>
</template>

<script>
import { capitalize, startCase } from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import { Pages } from "@/utils/pages"
import ParamDates from "./ParamDates"
import ParamSelect from "./ParamSelect"

export default {
  name: "CopyrightsAndTariffs",
  components: { ParamDates, ParamSelect },
  data () {
    return {
      perPage: 6,
      page: 1,
      historyFields: [
        { key: "year", label: startCase(this.$t("year")), width: "25%" },
        { key: "threshold", label: startCase(this.$t("threshold")), width: "75%" }
      ],
      selectedPanel: "eligibility",
      panelOptions: [
        { text: this.capitalize(this.$t("eligibility")), value: "eligibility" },
        { text: this.capitalize(this.$t("threshold")), value: "threshold" }
      ],
      dataLoaded: false,
      title: "Copyrights and Tariffs",
      Pages,
    }
  },
  computed: {
    ...mapState("copyrights", [
      "adding",
      "deleted",
      "deleteMode",
      "existChanges",
      "tariffs",
      "thresholds"
    ]),
    ...mapState("user", ["permissions"]),
    columns () {
      let cols = [
        { key: "copyrightName", label: startCase(this.$t("copyright")) },
        { key: "tariffName", label: startCase(this.$t("tariff")) },
        { key: "tariffCode", label: startCase(this.$t("code")) }
      ]

      if (this.selectedPanel === "eligibility") {
        cols.push({
          key: "eligibilityParameter",
          label: startCase(this.$t("eligibility_parameter"))
        })
        cols.push({
          key: "eligibilityStartDate",
          label: startCase(this.$t("eligibility_start_date"))
        })
        cols.push({
          key: "eligibilityEndDate",
          label: startCase(this.$t("eligibility_end_date"))
        })
      } else {
        cols.push({ key: "threshold", label: startCase(this.$t("threshold")) })
        cols.push({ key: "year", label: startCase(this.$t("year")) })
      }

      return cols
    }
  },
  methods: {
    ...mapActions("copyrights", ["getCopyrights", "postCopyrights"]),
    ...mapMutations("copyrights", [
      "addNewParameter",
      "setAddingMode",
      "resetDeleted",
      "setChangeFlag",
      "setDeleteMode"
    ]),
    ...mapMutations("alert", ["success", "error"]),
    getHistory (tariffCode) {
      let thresholds = this.thresholds.filter(t => t.tariff === tariffCode).toSorted((a, b) => b.year - a.year)
      return thresholds.slice(
        this.perPage * (this.page - 1),
        this.perPage * this.page
      )
    },
    showModal (index) {
      this.page = 1
      this.$bvModal.show(`history-${index}`)
    },
    cancel () {
      if (this.deleteMode) {
        this.setDeleteMode(false)
        this.$nextTick().then(() => {
          this.setChangeFlag(false)
        })
      } else {
        this.getCopyrights().then(() => {
          this.$nextTick().then(() => {
            this.setChangeFlag(false)
            this.setAddingMode(false)
          })
        })
      }
    },
    validate () {
      let badTariffs = []
      this.tariffs.forEach(tariff => {
        if (tariff.eligibilityPeriods.some(ep => !ep.eligibilityParam)) {
          badTariffs.push(tariff.tariffCode)
        }
      })
      return badTariffs
    },
    updateThresholds (tariffCode) {
      let thresholds = this.thresholds.filter(t => t.tariff === tariffCode)
      if (thresholds.filter(t => t.threshold < 0).length) {
        this.error("Can't set thresholds with a value less than 0")
        return
      }
      this.$api.copyrights
        .tariffThresholdsUpdate(thresholds)
        .then(() => {
          this.setChangeFlag(false)
          this.setAddingMode(false)
          this.success(this.$t("saved_changes"))
        })
        .catch(rej => {
          this.error(rej.response.data.detail)
        })
    },
    updateEligibility () {
      let badTariffs = this.validate()
      if (badTariffs.length > 0) {
        this.error(
          `Tariffs ${badTariffs.join(
            ","
          )} contain periods with empty eligibility parameter.`
        )
        return
      }
      this.postCopyrights()
        .then(() => {
          if (this.deleteMode) {
            this.setDeleteMode(false)
          }
          this.success(this.$t("saved_changes"))
        })
        .then(() => {
          this.getCopyrights().then(() => {
            this.setChangeFlag(false)
            this.setAddingMode(false)
          })
        })
        .catch(rej => {
          this.error(rej.response.data.detail)
        })
    },
    getButtonClass (type) {
      switch (type) {
      case "cancel":
        if (!this.deleteMode && !this.existChanges) {
          return "disabled-button"
        }
        break
      case "delete":
        if (this.deleteMode || this.adding) {
          return "disabled-button"
        }
        break
      case "save":
        if (!this.existChanges) {
          return "disabled-button"
        }
        break
      default:
        break
      }
    },
    getRowColor (rowIndex) {
      if (rowIndex % 2) {
        return "odd"
      }
    },
    capitalize
  },
  mounted () {
    this.getCopyrights().then(() => {
      this.$nextTick().then(() => {
        this.dataLoaded = true
      })
    })
  },
  watch: {
    deleted: {
      handler () {
        if (this.dataLoaded) {
          this.setChangeFlag(true)
        }
      },
      deep: true
    },
    tariffs: {
      handler () {
        if (this.dataLoaded) {
          this.setChangeFlag(true)
        }
      },
      deep: true
    },
    thresholds: {
      handler () {
        if (this.dataLoaded) {
          this.setChangeFlag(true)
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>

.custom-modal {
  .modal-body {
    .table-striped {
      border: none;
      ::v-deep tr{
        background-color: $modal-gray !important;
      }
    }
  }
}
</style>
