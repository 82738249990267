<template>
  <validation-observer ref="observer" tag="fieldset">
    <b-card-body>
      <b-row v-if="hasPerformerCollege()">
        <b-col>
          {{ $t("performer") | capitalize }}:
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="hasPerformerCollege()">
        <b-col>
          <distribution-form-group
            vid="openPeriodStartPerformer"
            ref="openPeriodStartPerformer"
            :label="$t('open_period_start_date') | capitalize"
            :description="$t('open_period_start_date_help_text') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <date-picker
              :input-attr="{required: true}"
              type="date"
              v-model="distribution.openPeriodStartPerformer"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="hasPerformerCollege()">
        <b-col>
          <distribution-form-group
            vid="openPeriodEndPerformer"
            ref="openPeriodEndPerformer"
            :label="$t('open_period_end_date') | capitalize"
            :description="$t('open_period_end_date_help_text') | capitalize"
            label-cols="2"
            :rules="`required|after:@openPeriodStartPerformer,${$config.DATE_FORMAT}`"
            v-slot="{ state }"
            >
            <date-picker
              :input-attr="{required: true}"
              type="date"
              v-model="distribution.openPeriodEndPerformer"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="hasMakerCollege()">
        <b-col>
          {{ $t("maker") | capitalize }}:
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="hasMakerCollege()">
        <b-col>
          <distribution-form-group
            vid="openPeriodStartMaker"
            ref="openPeriodStartMaker"
            :label="$t('open_period_start_date') | capitalize"
            :description="$t('open_period_start_date_help_text') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <date-picker
              :input-attr="{required: true}"
              type="date"
              v-model="distribution.openPeriodStartMaker"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="hasMakerCollege()">
        <b-col>
          <distribution-form-group
            vid="openPeriodEndMaker"
            ref="openPeriodEndMaker"
            :label="$t('open_period_end_date') | capitalize"
            :description="$t('open_period_end_date_help_text') | capitalize"
            label-cols="2"
            :rules="`required|after:@openPeriodStartMaker,${$config.DATE_FORMAT}`"
            v-slot="{ state }"
            >
            <date-picker
              :input-attr="{required: true}"
              type="date"
              v-model="distribution.openPeriodEndMaker"
              :class="{invalid: state === false}"
              :format="$config.DATE_FORMAT"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </validation-observer>
</template>

<script>

import DistributionFormGroup from "@/components/DistributionFormGroup"
import { ValidationObserver } from "vee-validate"
import { distributionCollegeTypes } from "@/constants"
import { distributionSectionMixin } from "@/utils/mixins"

export default {
  name: "OpenPeriodSection",
  components: { DistributionFormGroup, ValidationObserver },
  mixins: [ distributionSectionMixin ],
  props: {
    distribution: Object
  },
  data () {
    return {
      distributionCollegeTypes
    }
  },
  methods: {
    hasPerformerCollege () {
      return this.distribution.college === distributionCollegeTypes.PERFORMER || this.distribution.college === distributionCollegeTypes.BOTH
    },
    hasMakerCollege () {
      return this.distribution.college === distributionCollegeTypes.MAKER || this.distribution.college === distributionCollegeTypes.BOTH
    }
  },
  computed: {
    requiredFields () {
      return [
        ...this.hasPerformerCollege() ? ["openPeriodStartPerformer", "openPeriodEndPerformer"] : [],
        ...this.hasMakerCollege() ? ["openPeriodStartMaker", "openPeriodEndMaker"] : []
      ]
    }
  }
}
</script>
