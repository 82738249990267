import { confirmed, double, email, max_value, required } from "vee-validate/dist/rules"
import { extend, setInteractionMode } from "vee-validate"

import { i18n } from "./i18n"
import moment from "moment"

setInteractionMode("eager")

extend("required", {
  ...required,
  message: i18n.t("this_field_is_required"),
})

extend("year", value => {
  let valid = value  && value.match(/^[0-9]{4}$/) && value > 1900
  if (valid != null && valid != false) {
    return true
  }
  return i18n.t("must_be_a_year")
})

extend("email", {
  ...email,
  message: i18n.t("this_field_must_be_a_valid_email"),
})

extend("confirmed", {
  ...confirmed,
  message: i18n.t("this_fields_confirmation_does_not_match"),
})

extend("duration", value => {
  let valid = value && value.match(/^\d{2}:\d{2}$/)
  if (valid != null && valid != false) {
    return true
  }
  return i18n.t("must_be_duration")
})

extend("isrc", value => {
  let valid = value && value.match(/^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/)
  if (valid != null && valid != false) {
    return true
  }
  return i18n.t("must_be_isrc")
})

extend("after", {
  params: ["target", "format"],
  validate (value, { target, format }) {
    if (value && target) {
      return moment(value, format) > moment(target, format)
    } else {
      return true
    }
  },
  message: i18n.t("must_be_before_target")
})

extend("double", {
  ...double,
  message: i18n.t("must_be_a_decimal")
})

extend("positive", {
  validate (value) {
    return value > 0
  },
  message: i18n.t("must_be_positive")
})

extend("max_value", {
  ...max_value,
  message: i18n.t("must_be_less_than")
})
