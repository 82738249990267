<template>
  <div>
    <b-card>
      <b-row align-v="center">
        <b-col cols="2">
          <h3 class="ml-2 text-red">
            {{ $t("mandates") | capitalize }}
          </h3>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox v-model="filters.showHistory.value" switch>
              {{ $t("show_history") | capitalize }}
          </b-form-checkbox>
        </b-col>
        <b-col cols="5">
           <include-exclude-selector
              :options="countries.map(c => { return {'name': c.text, 'value': c.alpha2}})"
              :except-string="$t('ww_except')"
              :include-string="$t('in') | capitalize"
              :placeholder="$t('select_countries') | capitalize"
              :label="$t('territory') | capitalize"
              v-model="filters.territory"
          />
        </b-col>
        <div class="col d-flex justify-content-end align-items-center">
          <b-button @click="$bvModal.show('mandate-edit-form')" 
            v-if="canEdit"
            :disabled="!selectedMandate.id" 
            variant="outline-secondary"
          >
            {{ $t("edit") | startcase }}
          </b-button>
        </div>
      </b-row>
    </b-card>
    <RightsHolderMandatesTable :data="mandates" :rights-holder-type="data.type" @select:mandate="onMandateSelected"/>
    <modal
      id="mandate-edit-form"
      customTitle="Update mandate"
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg"
      centered
      hide-footer
    >
      <mandate-edit-form
        :mandate-data="selectedMandate"
        :rights-holder-type="data.type"
        :rightsholder-id="rightsholderId"
        :solved-manually="solvedManually"
        @mandate:updated="refresh"
        :endpoint-method="$api.rightsholders.rightsholdersMandatesUpdate"
        modal-id="mandate-edit-form"
      />
    </modal>
    <b-card>
      <b-row>
        <b-col>
          <h3 class="ml-2 text-red">
            {{ $t("source_metadata") | startcase }}
          </h3>
        </b-col>
      </b-row>
    </b-card>
    <RightsHolderMandateMetadata
      v-for="(mc, index) in getSources()"
      :key="`meta-${index}`"
      :rights-holder-type="data.type"
      :member-collective="mc[0]"
      :mandates="mc[1].mandates"
      :sources="mc[1].sources"
      :input-sources="mc[1].inputSources"
      :selected-mandate="selectedMandate"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import MandateEditForm from "@/pages/Rightsholders/RightsHolderDetail/MandateEditForm"
import { Pages } from "@/utils/pages"
import RightsHolderMandateMetadata from "@/pages/Rightsholders/RightsHolderDetail/RightsHolderMandateMetadata"
import RightsHolderMandatesTable from "@/pages/Rightsholders/RightsHolderDetail/RightsHolderMandatesTable"

export default {
  name: "RightsHolderMandateList",
  props: {
    rightsholderId: String,
    data: Object,
    solvedManually: {
      type: Array,
      required: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      mandates: [],
      selectedMandate: {},
      metadata: {},
      filters: {
        showHistory: { value: true, defaultValue: true },
        territory: { values: [], except: false }
      },
      Pages,
    }
  },
  components: {
    RightsHolderMandatesTable,
    RightsHolderMandateMetadata,
    MandateEditForm,
  },
  computed: {
    ...mapState("consts", ["countries"]),
    ...mapState("user", ["permissions"]),
  },
  methods: {
    ...mapActions("actionTabs", ["getMandateConflicts"]),
    refresh () {
      if (this.data.isRightsHolderDetail) {
        this.getMandatesData().then(() => {
          let mandate = this.mandates.filter((m) => { return m.id === this.selectedMandate.id })
          if (mandate.length) {
            this.selectedMandate = mandate[0]
          }
        })
      } else {
        this.$root.$emit("bv::toggle::collapse", "conflict-" + this.rightsholderId)
        this.getMandateConflicts({ ...this.$route.query })
      }
    },
    onMandateSelected (mandate) {
      this.selectedMandate = mandate
    },
    getTerritory () {
      const selectedCountries = this.filters.territory.values
      const countryList = countries => countries.map(c => c.value).join(",")
      if (this.filters.territory.except && selectedCountries.length > 0) {
        return `WW- ${countryList(selectedCountries)}`
      } else if (!this.filters.territory.except && selectedCountries.length > 0) {
        return countryList(selectedCountries)
      }
      return null
    },
    getSources () {
      return Object.entries(this.metadata)
    },
    mandatesQueryParams () {
      const territory = this.getTerritory()
      let query_params =  {
        show_history: this.filters.showHistory.value,
      }
      if (territory) {
        query_params["territory"] = territory
      }
      return query_params
    },
    mandatesAdapter (data) {
      this.mandates = data
    },
    metadataAdapter (data) {
      let m = {}
      data.forEach(function (e) {
        e.mandates.forEach(function (mandate) {
          if (mandate.membership in m) {
            m[mandate.membership].mandates.push(mandate)
            m[mandate.membership].sources.push(...e.sources.files)
            m[mandate.membership].inputSources.push(...e.sources.inputSources)
          } else {
            m[mandate.membership] = {
              mandates: [mandate],
              sources:  [...e.sources.files],
              inputSources: [...e.sources.inputSources]
            }
          }
        })
      })
      this.metadata = m
    },
    async getMandatesData () {
      const res = await this.$api.rightsholders.rightsholdersMandatesList(this.rightsholderId, this.mandatesQueryParams())
      this.mandatesAdapter(res.data)
      await this.getMandatesMetadata()
    },
    async getMandatesMetadata () {
      const res = await this.$api.rightsholders.rightsholdersMandatesMetadataList(this.rightsholderId)
      this.metadataAdapter(res.data)
    }
  },
  mounted () {
    this.getMandatesData()
  },
  watch: {
    "filters.showHistory.value": function () {
      this.getMandatesData()
    },
    "filters.territory.values": function () {
      this.getMandatesData()
    },
    "filters.territory.except": function () {
      this.getMandatesData()
    },
  }

}
</script>
<style lang="scss" scoped>
::v-deep thead th {
  font-size: 11pt;
  font-weight: normal;
}
</style>

