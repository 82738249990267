<template>
  <div :class="{compact: configuration.compactMode, 't-table': true, 'h-100': true}" class="d-flex flex-column pb-3">
    <b-row align-v="center" class="mb-2 mt-2 counts-container">
      <b-col cols="4" class="counts">
        <slot name="counts"/>
      </b-col>
      <b-col cols="3" class="d-flex justify-content-center align-items-center">
        <slot name="filters"/>
      </b-col>
      <b-col cols="5" class="col-auto d-flex justify-content-end align-items-center">
        <div class="ml-2 mt-1">
          <slot name="merge"/>
        </div>
        <b-form-checkbox class="d-flex align-items-center mt-1 ml-3" switch v-model="configuration.compactMode">{{ $t("compact_view") | capitalize }}</b-form-checkbox>
      </b-col>
    </b-row>
      <div class="table-responsive h-100 table-border">
        <div ref="table" :id="id"/>
      </div>
      <table-pagination v-if="limit" class="pagination-border" :per-page="limit" :total-rows="totalRows" v-model="page"/>
  </div>
</template>
<script>
import { i18n, router } from "@/utils"
import { isEmpty, isEqual } from "lodash"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import Vue from "vue"
import { mapMutations } from "vuex"
import { perPageItemsSm } from "@/constants"
import { store } from "@/store"

const GRIP_LINES_SVG = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1em\" viewBox=\"0 0 256 512\"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d=\"M96 496V16c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v480c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zm128 0V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v480c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16z\"/></svg>"
const SORT_SVG = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1em\" viewBox=\"0 0 320 512\"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d=\"M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z\"/></svg>"
const SORT_UP_SVG = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1em\" viewBox=\"0 0 320 512\"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d=\"M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z\"/></svg>"
const SORT_DOWN_SVG = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1em\" viewBox=\"0 0 320 512\"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d=\"M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z\"/></svg>"
export default {
  name: "TabulatorTable",
  props: {
    loadData: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: perPageItemsSm,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedData: {
      type: Array,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    columnMode: {
      type: String,
      required: false,
      default: "default",
    },
    filters: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    localData: {
      type: Array,
      required: false,
      default: null,
    },
    nonWatchedColumns: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data () {
    return {
      table: null,
      totalRows: 0,
      page: 1,
      columnConfigurations: {},
      loading: false,
      isCompact: false,
      configuration: {
        compactMode: false,
        columnConfigurations: {},
        columnMode: this.columnMode,
      },
      data: [],
      rowClicked: null
    }
  },
  computed: {
    localMode () {
      return this.localData !== null
    }
  },
  methods: {
    ...mapMutations("alert", ["error"]),
    getRowSelectionColumnDefinition () {
      return {
        field: "rowSelection",
        titleFormatter: "rowSelection",
        formatter: "rowSelection",
        headerSort: false,
        width: 10,
        hozAlign: "center",
        headerHozAlign: "center",
        resizable: false,
      }
    },
    addColumnFix (definition, before, nextToColumn) {
      // Avoid double table redraw in original tabulator-table function
      return new Promise((resolve) => {
        let column = this.table.columnManager._addColumn(definition, before, nextToColumn)

        this.table.columnManager._reIndexColumns()

        this.table.columnManager.dispatch("column-add", definition, before, nextToColumn)

        if(this.table.columnManager.layoutMode() != "fitColumns"){
          column.reinitializeWidth()
        }

        this.table.rowManager.reinitialize()

        resolve(column)
      })
    },
    updateSelectableColumn (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.addColumnFix(this.getRowSelectionColumnDefinition(), true).then(() => {
          this.table.options.columns.unshift(this.getRowSelectionColumnDefinition())
          this.updateSelectableForAllRows()
        })
      } else if (!newVal && oldVal) {
        this.table.deleteColumn("rowSelection").then(() => {
          this.table.options.columns.shift()
        })
      }
    },
    renderVNode (slot, params) {
      let temp = document.createElement("div")
      let temp_app = new Vue({
        router,
        i18n,
        store,
        computed: {
          vnode () {
            return slot(params)
          }
        },
        render: function () {
          return this.$createElement("span", this.vnode)
        },
        parent: this
      })
      temp_app.$mount(temp)
      return temp_app.$el
    },
    getData () {
      this.$emit("loading")
      this.loading = true
      this.options.requestFunction.bind(this)().then(response => {
        if (typeof response === "undefined") {
          return []
        }
        if (this.options.responseAdapter) {
          this.options.responseAdapter.bind(this)(response)
        }
        this.totalRows = response.data.count
        this.data = response.data.results
        this.table.setData(response.data.results).then(() => {
          this.table.getRows().forEach(row => {
            if (this.selectedData && this.selectedData.some(el => isEqual(el, row.getData()))) {
              row.select()
            }
          })
          this.updateSelectableForAllRows()
          if (this.options.selectAllCheck) {
            this.table.modules.selectRow.headerCheckboxElement.disabled = !this.options.selectAllCheck()
          }
          this.loading = false
        })
        this.$emit("loaded", response)
      }).catch(({ response }) => {
        if (response.status === 400) {
          this.error(this.$t("wrong_filters"))
        }
      })
    },
    getColumns () {
      let columns = this.columns.map(col => {
        let colData = {
          title: this.options.headings[col],
          field: col,
          width: this.options.width ? this.options.width[col] : undefined,
          headerSort: this.options.sortable.includes(col),
          headerSortTristate: true,
          // Workaround to make sure visibility is restored from persistent storage
          visible: this.options.hiddenColumns ? !this.options.hiddenColumns.includes(col) : true,
          titleFormatter (cell) {
            return `<span title="${cell.getValue()}">${cell.getValue()}</span>`
          },
        }
        if (! this.localMode) {
          // Workaround for local sorting to be disabled
          colData.sorter = () => {
            return 1
          }
        } else {
          if (this.options.sorters && this.options.sorters[col]) {
            switch (typeof this.options.sorters[col]) {
            case "function":
              colData.sorter = (a, b, rowA, rowB) => this.options.sorters[col](rowA.getData(), rowB.getData())
              break
            case "string":
              colData.sorter = this.options.sorters[col]
            }
          }
        }
        if (typeof this.$scopedSlots[col] !== "undefined") {
          colData.formatter = (cell) => {
            return this.renderVNode(this.$scopedSlots[col], {
              row: cell.getRow().getData(),
              field: col
            })
          }
        } else {
          colData.formatter = (cell) => {
            let span = document.createElement("span")
            span.textContent = cell.getValue()
            return span
          }
        }
        if (typeof this.$slots[`h__${col}`] !== "undefined") {
          colData.titleFormatter = () => {
            let el = this.renderVNode(() => this.$slots[`h__${col}`])
            let template = document.createElement("template")
            template.innerHTML = GRIP_LINES_SVG
            el.appendChild(template.content.firstChild)
            return el
          }
        }
        if ( this.filters && this.filters[col] ) {
          colData.headerFilter = this.filters[col].type
          if ( colData.headerFilter === "list" ) {
            colData.headerFilterParams = {
              values: this.filters[col].options
            }
          }
          colData.headerFilterFunc = () => true
        }
        return colData
      })
      if (this.selectable) {
        columns.splice(0, 0, this.getRowSelectionColumnDefinition())
      }
      columns.push(this.colMenuController())
      return columns
    },
    colMenuController () {
      let vueComponent = this
      let colHeader = {
        width: 2,
        titleFormatter () {
          return ""
        },
        visible: true,
        headerSort: false,
        headerSortTristate: false,
        resizable: false,
        frozen: true,
      }
      colHeader.headerMenu = function () {
        let initiallyVisibleColumns = this.getColumns().filter(cc => cc.getDefinition().title && cc.isVisible()).length
        let options = this.getColumns().filter(c => c.getDefinition().title).map(c => {
          let label = document.createElement("span")
          let title = document.createElement("span")
          title.textContent = c.getDefinition().title
          title.setAttribute("class", "ml-1")
          let icon = document.createElement("input")
          icon.type = "checkbox"
          icon.checked = c.isVisible()
          icon.disabled = icon.checked && initiallyVisibleColumns === 1
          icon.style.verticalAlign = "middle"
          c._menuCheckBox = icon
          label.appendChild(icon)
          label.appendChild(title)
          return {
            label: label,
            action (e) {
              e.stopPropagation()
              let popup = c.getTable().modules?.menu?.rootPopup
              if (popup) popup.blockHide()
              setTimeout(() => {
                if(popup){
                  popup.restoreHide()
                }
              }, 20)
              let visibleColumns = c.getTable().getColumns().filter(cc => cc.getDefinition().title && cc.isVisible()).length
              if (visibleColumns > 1 || !c.isVisible()) {
                visibleColumns = visibleColumns + (c.isVisible() ? -1 : 1)
                c.toggle()
                icon.checked = c.isVisible()
                if (visibleColumns === 1) {
                  // disable checkbox on the latest column
                  let lastColumn = c.getTable().getColumns().find(cc => cc.getDefinition().title && cc.isVisible())
                  lastColumn._menuCheckBox.disabled = true
                } else {
                  // enable checkboxes for all visible columns
                  c.getTable().getColumns().filter(cc => cc.getDefinition().title && cc.isVisible()).forEach(cc => {
                    cc._menuCheckBox.disabled = false
                  })
                }
              }
            }
          }
        })
        options.push({
          label: "Reset",
          action () {
            vueComponent.resetWidths()
          }
        })
        return options
      }
      return colHeader
    },
    updateSelectableForAllRows () {
      if (this.options.selectableCheck && this.selectable) {
        this.table.getRows().forEach(row => {
          if (!this.table.options.selectableCheck(row)) {
            row.getElement().classList.remove("tabulator-selectable")
            row.getElement().classList.add("tabulator-unselectable")
            if (row._row.modules.select.checkboxEl) {
              row._row.modules.select.checkboxEl.disabled = true
            }
          } else {
            row.getElement().classList.remove("tabulator-unselectable")
            row.getElement().classList.add("tabulator-selectable")
            if (row._row.modules.select.checkboxEl) {
              row._row.modules.select.checkboxEl.disabled = false
            }
          }
        })
      }
    },
    extraConfigurationKey () {
      return `extra-table-config-${this.id}`
    },
    loadExtraConfiguration () {
      let configuration = JSON.parse(localStorage.getItem(this.extraConfigurationKey()))
      if (configuration) {
        this.configuration = { ...this.configuration, ...configuration }
      }
    },
    saveExtraConfiguration () {
      localStorage.setItem(this.extraConfigurationKey(), JSON.stringify(this.configuration))
    },
    setColumnMode (newMode) {
      let options = this.options.columnConfigurations
      this.configuration.columnMode = newMode
      if (this.configuration.columnConfigurations[newMode]) {
        this.table.setColumnLayout(this.configuration.columnConfigurations[newMode])
      } else {
        this.table.getColumns().forEach(col => {
          if (options[newMode].includes(col.getField())) {
            col.show()
          } else {
            col.hide()
          }
        })
        this.resetWidths()
        this.$set(this.configuration.columnConfigurations, newMode, this.table.getColumnLayout())
      }
    },
    resetWidths () {
      let layout = this.table.getColumnLayout()
      let sorters = this.table.getSorters()
      layout = layout.map(c => {
        return { ...c, width: this.options.width && this.options.width[c.field] ? this.options.width[c.field] : (c.field === "rowSelection" ? c.width : null) }
      })
      this.table.setColumnLayout(layout)
      // Workaround to make sure that data is persisted normally
      this.refreshLayout(sorters)
    },
    refreshLayout (sorters = null) {
      if (!sorters) {
        sorters = this.table.getSorters()
      }
      sorters = sorters.map(s => { return { dir: s.dir, column: s.field } })
      this.table.setColumnLayout(this.table.getColumnLayout())
      if (sorters && sorters.length) {
        this.table.setSort(sorters)
      }
    },
    setSorting (sorters) {
      sorters = sorters.map(s => { return { column: s.column, dir: s.ascending? "asc" : "desc" } })
      if (sorters && sorters.length) {
        if (this.table.initialized) {
          this.table.setSort(sorters)
        } else {
          this.table.on("tableBuilt", () => this.table.setSort(sorters))
        }
      }
    },
    nextRow () {
      let row = null
      if (!this.rowClicked) {
        row = this.table.getRowFromPosition(1)
      } else {
        row = this.rowClicked.getNextRow() ? this.rowClicked.getNextRow() : this.table.getRowFromPosition(this.table.rowManager.rows.length)
      }
      row.scrollTo("middle", true)
      this.rowClicked = row
      return row.getData()
    },
    prevRow () {
      let row = null
      if (!this.rowClicked) {
        row = this.table.getRowFromPosition(1)
      } else {
        row = this.rowClicked.getPrevRow() ? this.rowClicked.getPrevRow() : this.table.getRowFromPosition(1)
      }
      row.scrollTo("middle", true)
      this.rowClicked = row
      return row.getData()
    },
  },
  mounted () {
    Object.keys(this.configuration).forEach(key => {
      this.$watch(`configuration.${key}`, () => this.saveExtraConfiguration())
    })
    this.loadExtraConfiguration()
    if (this.localMode) {
      this.totalRows = this.localData.length
    }
    this.table = new Tabulator(
      this.$refs.table,
      {
        height: "100%",
        data: this.localMode ? this.localData : undefined,
        pagination: this.localMode,
        paginationSize: this.localMode ? this.limit : undefined,
        sortOrderReverse: this.localMode,
        layout: "fitColumns",
        columns: this.getColumns(),
        selectable: this.options.selectable,
        selectableCheck: this.options.selectableCheck ? row => this.options.selectableCheck(row.getData()) : () => true,
        movableColumns: true,
        initialSort: this.options.initialSort,
        headerVisible: typeof this.options.headerVisible === "undefined" ? true : this.options.headerVisible,
        headerSortElement: function (column, dir) {
          switch (dir) {
          case "asc":
            return SORT_UP_SVG
          case "desc":
            return SORT_DOWN_SVG
          default:
            return SORT_SVG
          }
        },
        rowFormatter: this.options.rowClasses ? (row) => {
          if (!row.hasRowClassWatcher()) {
            row.setRowClassWatcher(
              this.$watch(
                () => this.options.rowClasses(row.getData()),
                (newVal, oldVal) => {
                  let element = row.getElement()
                  if (element) {
                    newVal.forEach(c => element.classList.add(c))
                    element.classList.forEach((cls) => {
                      if (oldVal.includes(cls) && !newVal.includes(cls)) {
                        element.classList.remove(cls)
                      }
                    })
                  }
                }
              )
            )
          }
          const classes = this.options.rowClasses.bind(this)(row.getData())
          classes.every(c => row.getElement().classList.add(c))
        } : undefined,
        persistence: {
          sort: false,
          columns: ["width", "visible"],
        }
      }
    )
    this.table.on("dataSorting", sorters => {
      if (!this.loading && this.table.initialized && !this.localMode) {
        this.$emit("sorted", sorters.map(s => {
          return { ascending: s.dir === "asc", column: s.field }
        }))
      }
    })
    this.table.on("tableBuilt", () => {
      if (this.options.permanentSort) {
        let oldSetSort = this.table.modules.sort.setSort
        let self = this
        this.table.modules.sort.setSort = function (sortList, dir) {
          if (!Array.isArray(sortList)) {
            sortList = [{ column: sortList, dir: dir }]
          }
          if (sortList.length === 0 || sortList[0].column.field !== self.options.permanentSort.column) {
            sortList.unshift({
              column: self.table.getColumn(self.options.permanentSort.column)._column,
              dir: self.options.permanentSort.dir
            })
          }
          oldSetSort.bind(this)(sortList, dir)
        }
      }
      if (!this.loading && !this.localMode && this.loadData) { this.getData() }
      if (!isEmpty(this.options.columnConfigurations)) {
        // Merge latest column definitions with the column mode but only for the last active column mode
        if (!isEmpty(this.configuration.columnConfigurations && this.configuration.columnConfigurations[this.configuration.columnMode])) {
          this.configuration.columnConfigurations[this.configuration.columnMode] = this.table.getColumnLayout()
        }
        this.setColumnMode(this.columnMode)
      }
      this.refreshLayout()
      // Prevent screen from scrolling after modal is opened
      this.table.rowManager.element.removeAttribute("tabindex")
    })
    // Prevent row selection on click
    this.table.on("rowClick", (e, row) => {
      e.stopPropagation()
      this.rowClicked = row
      this.$emit("row-click", row.getData())
    })
    this.table.on("rowSelected", row => {
      if (!this.selectedData.some(el => isEqual(el, row.getData())) && typeof this.selectedData != "undefined") {
        this.selectedData.push(row.getData())
      }
      this.updateSelectableForAllRows()
    })
    this.table.on("rowDeselected", row => {
      let ind = this.selectedData.findIndex(el => isEqual(el, row.getData()))
      this.selectedData.splice(ind, 1)
      this.updateSelectableForAllRows()
    })
    this.table.on("dataFiltering", filters => {
      if ( !this.table.initialized ) {
        return
      }
      for (let [name, filter] of Object.entries(this.filters)) {
        let tableFilter = filters.find(f => f.field === name)
        filter.value = tableFilter ? tableFilter.value : filter.defaultValue
      }
    })
    Object.keys(this.filters).forEach(filter => {
      this.$watch(`filters.${filter}.value`, function (newVal) {
        if (newVal !== this.table.getHeaderFilterValue(filter)) {
          if ( this.table.initialized ) {
            this.table.setHeaderFilterValue(filter, newVal)
          } else {
            this.table.on("tableBuilt", () => this.table.setHeaderFilterValue(filter, newVal))
          }
        }
      })
      this.$watch(`filters.${filter}.options`, function (newVal) {
        let c = this.table.getColumn(filter)
        c.updateDefinition({ "headerFilterParams": { "values": newVal } })
        this.table.setHeaderFilterValue(filter, this.filters.membership.value)
      })
    })
  },
  watch: {
    columns (newVal, oldVal) {
      //avoiding columns overriding to preserve column configuration using nonWatchedColumns prop
      if (!isEqual(newVal, oldVal) && !this.nonWatchedColumns) {
        if (this.table.initialized) {
          let sorters = this.table.getSorters()
          this.table.setColumns(this.getColumns())
          this.table.setSort(sorters.map(s => { return { dir: s.dir, column: s.field }}))
        } else {
          this.table.on("tableBuilt", () => {
            let sorters = this.table.getSorters()
            this.table.setColumns(this.getColumns())
            this.table.setSort(sorters.map(s => { return { dir: s.dir, column: s.field }}))
          })
        }
      }
    },
    selectable (newVal, oldVal) {
      if (this.table.initialized) {
        this.updateSelectableColumn(newVal, oldVal)
      } else {
        this.table.on("tableBuilt", () => {
          this.updateSelectableColumn(newVal, oldVal)
        })
      }
    },
    page (page) {
      this.$emit("pagination", page)
      if (this.localMode) {
        this.table.setPage(page)
      }
    },
    limit (limit) {
      if (this.localMode) {
        this.table.setPageSize(limit)
      }
    },
    "configuration.compactMode": function () {
      if (this.table.initialized) {
        this.$nextTick(() => this.table.redraw(true))
      }
    },
    columnMode (newMode, oldMode) {
      this.configuration.columnConfigurations[oldMode] = this.table.getColumnLayout()
      this.setColumnMode(newMode)
    },
    localData (newVal) {
      this.table.replaceData(newVal)
      this.totalRows = newVal.length
    },
    selectedData (newVal) {
      if (this.selectable) {
        const ids = newVal.map(v => v.id)
        this.table.getRows().forEach((row) => {
          if (ids.includes(row.getData().id)) {
            row.select()
          } else {
            row.deselect()
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
  $textSize: 14px;
  $cellPadding: 5px;
  $rowSelectedBackground: $row-select;
  $rowSelectedBackgroundHover: color-mix(in srgb, $row-select 95%, black);
  @import "tabulator-tables";
  @import "tabulator-tables/src/scss/themes/bootstrap/tabulator_bootstrap4.scss";

  .counts {
    color: var(--gray) !important;
    font-size: 11pt;
  }
  .tabulator-cell {
    cursor: initial;

    & > span {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  /* prevent row to change height when hovered */
  .tabulator-row {
    overflow: hidden;
  }

  .tabulator .tabulator-tableholder {
    scrollbar-width: thin;
  }

  .tabulator .tabulator-header {
    min-height: 3rem;
    border-top: none;
    border-bottom: none;
    .tabulator-headers {
      min-height: 3rem;
      margin-right: 0 !important;
      .tabulator-col {
        height: 100% !important;
        justify-content: center;
        background-color: $table-header !important;
      }
    }
  }

  div.tabulator-cell:not(:has(.more-values-icon),:has(input)):hover {
    overflow: visible;
    z-index: 1000;

    & > span {
      display: inline-block;
      background-color: $table-hover-bg;
      padding-right: 2px;
      position: relative;
    }
    .tabulator-selected & > span {
      background-color: $rowSelectedBackgroundHover;
    }
    .row-success & > span {
      background-color: $green-success !important;
    }
    .row-error & > span {
      background-color: $red-error !important;
    }
    span.values-text {
      overflow: visible !important;
    }
  }

  div.tabulator-col {
    overflow: visible !important;

    div.tabulator-col-title svg {
      fill: var(--light);
      position: absolute;
      right: 0px;
      margin-right: -9px;
      z-index: 100;
      pointer-events: none;
    }
    &[aria-sort="none"] .tabulator-col-sorter svg {
      fill: var(--light);
    }
  }
  .compact .tabulator-row .tabulator-cell {
    padding: 1px;
    .icon-wrapper .badge {
      bottom: 0.5rem;
      font-size: 0.5rem;
      &.lock {
        padding: 0.1rem 0.18rem;
      }
    }
  }

  .tabulator-popup-container {
    max-height: 500px;
  }

  .tabulator-footer {
    display: none;
  }

  html, body {
    height: 100%;
  }

  .t-table {
    overflow: hidden;
  }

  .tabulator {
    .tabulator-row.clickable .tabulator-cell {
      cursor: pointer;
    }
  }

  .row-success {
    background-color: $green-success !important;
  }

  .row-error {
    background-color: $red-error !important;
  }

  .table-responsive {
    margin-bottom: 0;
  }

  .table-border {
    border: 1px solid $table-header;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .pagination-border {
    border: 1px solid $table-header;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .VuePagination__pagination {
      margin-top: 1rem;
    }
  }
</style>
