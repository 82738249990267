<template>
  <b-row class="pl-3">
  <div class="filters d-flex flex-wrap mt-2 mb-2">
    <div v-for="filter in usedFilters" class="d-flex flex-row applied-filter"
      :key="filter.name" 
      v-b-tooltip.hover.bottom.v-light
      :title="filter.label"
    >
      <h6 class="mb-0">{{ filterValue(filter) }}</h6>
      <div class="d-flex cancel" @click="removeFilter(filter.name)">
        <fa-icon size="sm" :icon="['far', 'times']" />
      </div>
    </div>
  </div>
  <div class="mt-2">
    <div class="filter-icon-container" :class="!collapse ? 'icon-expanded' : ''" @click="showModal">
      <div class="icon-wrapper">
        <fa-icon size="lg" :icon="['fa', 'filter']" />
      </div>
    </div>
    <div class="filter-input-container" :class="collapse ? 'collapsed' : 'expanded'">
      <div class="show">
        <header class="d-flex justify-content-between w-100 mb-2">
          <h4>{{ $t('filters') | startcase }}</h4>
          <div class="icon-wrapper" @click="showModal">
            <fa-icon size="lg" :icon="['fa', 'angle-right']" />
          </div>
        </header>
        <div class="filters-list mb-3">
          <div v-for="filter in filtersAvailable" :key="filter.name">
            <b-badge class="d-flex align-items-center h-100" variant="light">
              <b-form-group v-if="filter.type === filterTypes.TEXT" :label="filter.label" label-cols="auto">
                <form-field v-model="filter.value" :ref="filter.name" :enter-action="applyFilters"/>
              </b-form-group>
              <b-form-group v-if="filter.type === filterTypes.MULTI_SELECT" :label="filter.label" label-cols="12">
                <multi-select
                  :ref="filter.name"
                  label="name"
                  v-model="filter.msValue"
                  :options="filter.options"
                  :multiple="true"
                  track-by="value"
                  :closeOnSelect="false"
                  :placeholder="filter.placeholder"
                  class="font-small"
                />
              </b-form-group>
              <b-form-group v-if="filter.type === filterTypes.SELECT" :label="filter.label" label-cols="auto">
                <b-form-select v-model="filter.value" :options="filter.options" :ref="filter.name" :disabled="filter.disable">
                  <template v-slot:first v-if="filter.emptyValue">
                    <b-form-select-option :value="filter.defaultValue">
                      {{ filter.emptyValue }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <select-search
                :ref="filter.name"
                v-if="filter.type === filterTypes.SELECT_SEARCH"
                :label="filter.label"
                :placeholder="filter.placeholder"
                :search-name="filter.searchName"
                :exclude-name="filter.excludeName"
                :text-field="filter.textField"
                :text-function="filter.textFunction"
                :value-field="filter.valueField"
                :list-endpoint="filter.listEndpoint"
                v-model="filter.value"
                :search-value="filter.searchValue"
                :params="filter.params"
              />
              <b-form-group
                :label="filter.label"
                label-cols="auto"
                v-if="filter.type === filterTypes.DATE_RANGE"
              >
                <date-picker
                  :ref="filter.name"
                  class="w-100"
                  v-model="filter.value"
                  range
                  :format="filter.format ? filter.format : $config.DATE_FORMAT"
                  :value-type="filter.valueType ? filter.valueType : 'date'"
                />
              </b-form-group>
              <b-form-group
                :label="filter.label"
                label-cols="auto"
                v-if="filter.type === filterTypes.YEAR_RANGE"
              >
                <date-picker
                  :ref="filter.name"
                  class="w-100"
                  v-model="filter.value"
                  range
                  type="year"
                  value-type="format"
                  format="YYYY"
                />
              </b-form-group>
              <b-form-group
                :label="filter.label"
                label-cols="auto"
                v-if="filter.type === filterTypes.MONTH_YEAR"
              >
                <date-picker
                  :ref="filter.name"
                  class="w-100"
                  v-model="filter.value"
                  type="month"
                  :format="$config.MONTH_DATE_FORMAT"
                  value-type="format"
                />
              </b-form-group>
              <b-form-group
                :label="filter.label"
                label-cols="auto"
                v-if="filter.type === filterTypes.TIME_RANGE"
              >
                <date-picker
                  :ref="filter.name"
                  class="w-100"
                  v-model="filter.value"
                  range
                  type="time"
                  :format="$config.TIME_SECOND_FORMAT"
                  value-type="format"
                />
              </b-form-group>
              <b-form-group
                :label="filter.label"
                label-cols="12"
                align-text="left"
                v-if="filter.type === filterTypes.NUMBER_RANGE"
              >
                <dual-range-slider
                  :ref="filter.name"
                  v-model="filter.value"
                  :min="filter.min"
                  :max="filter.max"
                  :step="filter.step"
                />
              </b-form-group>
              <b-form-group
                :label="filter.label"
                label-cols="12"
                label-align="left"
                v-if="filter.type === filterTypes.NUMBER_RANGE_NO_SLIDER"
              >
                <dual-range
                  :ref="filter.name"
                  v-model="filter.value"
                  label-cols="auto"
                  :single-bound="filter.singleBound"
                />
              </b-form-group>
              <b-form-group v-if="filter.type === filterTypes.BOOLEAN" :label="filter.label" label-cols="8">
                <b-form-checkbox
                  size="md"
                  :ref="filter.name"
                  v-model="filter.value"
                  switch
                  class="boolean-filter"
                >
                </b-form-checkbox>
              </b-form-group>
              <div
                v-if="filter.type === filterTypes.CUSTOM"
                :ref="filter.name"
                class="w-100"
              >
                <slot :name="filter.name"></slot>
              </div>
            </b-badge>
          </div>
        </div>
        <footer class="d-flex justify-content-between mt-3">
          <div class="d-flex flex-column">
            <b-button :disabled="!filtersApplied" class="reset-button" @click="resetFilters(true)">{{ $t("reset") | capitalize }}</b-button>
          </div>
          <b-button class="btn-outline-red" :disabled="!filtersPending" @click="applyFilters(); showModal()">{{ $t("apply") | capitalize }}</b-button>
        </footer>
      </div>
    </div>
  </div>
</b-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import DualRange from "@/components/DualRange"
import DualRangeSlider from "@/components/DualRangeSlider"
import { filterTypes } from "@/constants"
import parseDate from "@/utils/date-parser"

export default {
  name: "FilterBuilder",
  props: {
    id: {
      type: String,
      required: true,
    },
    filters: Object,
    applyFilters: Function,
    resetFilters: Function,
    filtersApplied: Boolean,
    filtersPending: Boolean
  },
  components: {
    DualRangeSlider,
    DualRange,
  },
  data () {
    return {
      usedFilters: [],
      filterTypes,
      collapse: true,
    }
  },
  computed: {
    ...mapGetters("user", ["getPreference"]),
    filtersAvailable () {
      let filters = Object.keys(this.filters).filter(f => !this.filters[f].hidden).sort((a, b) => (this.filters[a].label > this.filters[b].label) ? 1 : -1).map(filter => {
        let filterObj = this.filters[filter]
        this.$watch(() => filterObj.value, (newVal) => {
          this.$emit("filter", filter, newVal)
        })
        return this.filters[filter]
      })
      Object.keys(this.filters).map((filter) => {
        this.filters[filter].name = filter
      })
      return filters.filter(f => f.name !== "fuzzySearch" && f.name !== "search")
    }
  },
  methods: {
    ...mapMutations("user", ["setPreference"]),
    filterValue (filter) {
      if (filter.type === filterTypes.MULTI_SELECT) {
        let filterValue = filter.value.split(",")
        let filterNamesList = filter.options.filter(f => filterValue.includes(f.value)).map(f => {
          return f.name
        })
        return filterNamesList.join(", ")
      } else if (filter.type === filterTypes.SELECT) {
        const filterText = filter.options.filter(f => filter.value == f.value).map(f => {
          return f.text
        })
        return filterText[0]
      } else if (filter.type === filterTypes.SELECT_SEARCH) {
        return filter.value.text
      } else if (filter.type === filterTypes.DATE_RANGE) {
        let startDate = filter.value[0] && parseDate(filter.value[0], this.$config.DATE_FORMAT)
        let endDate = filter.value[1] && parseDate(filter.value[1], this.$config.DATE_FORMAT)
        return (`${startDate} - ${endDate}`)
      } else if (filter.type === filterTypes.YEAR_RANGE || 
        filter.type === filterTypes.TIME_RANGE || 
        filter.type === filterTypes.NUMBER_RANGE_NO_SLIDER ||
        filter.type === filterTypes.NUMBER_RANGE
      ) {
        return (`${filter.value[0]} - ${filter.value[1]}`)
      } else if (filter.type === filterTypes.CUSTOM && Array.isArray(filter.value)) {
        let filterValue = filter.value.map(f => f.name)
        return filterValue.join(", ")
      } else {
        return filter.value
      }
    },
    showModal () {
      this.collapse = !this.collapse
    },
    storeState () {
      let storeFilterData = this.usedFilters.map(f => {
        return {
          name: f.name,
          value: f.value
        }
      })
      this.setPreference({ preference: this.id, value: storeFilterData })
    },
    getStoredState () {
      return this.getPreference(this.id, [])
    },
    addAppliedFilters () {
      Object.keys(this.filters)
        .filter(f => !this.filters[f].hidden || this.filters[f].addHiddenFilter)
        .forEach(filter => {
          const existingFilter = this.usedFilters.find(f => f.name === this.filters[filter].name)
          if (existingFilter) {
            existingFilter.value = this.filters[filter].value
          } else {
            this.usedFilters.unshift(this.filters[filter])
          }
        })
      this.usedFilters = JSON.parse(JSON.stringify(this.usedFilters.filter(f => {
        if(Array.isArray(f.value)){
          return f.value[0] || f.value[1] ? true : false
        }
        else if (f.value) 
          return true
      })))
      this.storeState()
    },
    removeFilter (filter, storeState=true) {
      this.filters[filter].value = this.filters[filter].defaultValue
      if (this.filters[filter].type === filterTypes.MULTI_SELECT) {
        this.filters[filter].msValue = this.filters[filter].defaultValue
      }
      if (this.filters[filter].type === filterTypes.SELECT_SEARCH) {
        this.filters[filter].searchValue = null
      }
      this.$emit("filter", filter, this.filters[filter].defaultValue)
      this.usedFilters = this.usedFilters.filter(f => f.name !== filter)
      this.$nextTick(() => {
        this.applyFilters()
        if (storeState) {
          this.storeState()
        }
      })
    },
    initMultiSelectFilter (filterName) {
      this.$watch(`filters.${filterName}.msValue`, (newVal) => {
        this.convertMultiSelectFilterValue(filterName, newVal)
      })
      this.$watch(`filters.${filterName}.value`, () => {
        this.initMultiSelectFilterValue(filterName)
      })
      this.$watch(`filters.${filterName}.options`, () => {
        this.initMultiSelectFilterValue(filterName)
      })
    },
    initMultiSelectFilterValue (filterName) {
      let options = this.filters[filterName].options
      let value = this.filters[filterName].value
      if (options.length && value && !this.filters[filterName].msValue) {
        let splitValue = value.split(",")
        this.$set(this.filters[filterName], "msValue", options.filter(o => splitValue.includes(o.value)))
      }
    },
    convertMultiSelectFilterValue (filterName, value) {
      if (value.length) {
        this.filters[filterName].value = value.map(k => k.value).join(",")
      } else {
        this.filters[filterName].value = null
      }
    },
    initSelectFilter (filterName) {
      this.$watch(`filters.${filterName}.options`, () => {
        this.usedFilters.filter(f => f.name === filterName).map(f => {
          f.options = this.filters[filterName].options
        })
      })
    },
    addFiltersFromStorage () {
      let storedState = this.getStoredState()
      this.$nextTick(() => {
        storedState && storedState.forEach(f => {
          if (f.name && !this.filters[f.name].value) {
            this.filters[f.name].hidden = false
            this.filters[f.name].value = f.value
          }
        })
        this.$nextTick(() => {
          this.applyFilters()
        })
      })
    }
  },
  created () {
    Object.entries(this.filters).forEach(([k, v]) => {
      if (v.type === filterTypes.MULTI_SELECT) {
        this.initMultiSelectFilter(k)
      }
      if (v.type === filterTypes.SELECT) {
        this.initSelectFilter(k)
      }
    })
  },
  watch: {
    filtersPending () {
      if (!this.filtersPending) {
        this.$nextTick(() => {
          this.addAppliedFilters()
        })
      }
    },
    id () {
      this.addFiltersFromStorage()
    }
  },
  mounted () {
    this.addFiltersFromStorage()
  }
}
</script>

<style scoped lang="scss">
  .filters {
    gap: 5px;
    min-height: 32px;
    width: 97%;
  }
  .filter svg {
    margin-left: 5px;
    cursor: pointer;
  }
  ::v-deep .boolean-filter {
    line-height: 1.5;
    font-weight: normal;
    font-size: 1.12rem;
  }
  ::v-deep .badge legend {
    font-weight: normal;
    width: max-content;
    min-width: 130px;
    padding-left: 10px;
  }

  ::v-deep .mx-input {
    height: auto !important;
  }

  .applied-filter {
    border: 1px solid $gray-light;
    align-items: center; 
    border-radius: 16px; 
    padding-left: 10px;
    background-color: $white-darker;
    .cancel {
      min-width: 2rem;
      height: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
 
  .filter-icon-container {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    height: 2.5rem !important;
    border: 1px solid $red;
    border-right: none;
    border-radius: 4px 0px 0px 4px;
    background-color: $white-dark;
    box-shadow: 0px 4px 4px 0px #00000026;
    transition: 0.3s;
    cursor: pointer;
    .icon-wrapper {
      min-width: 2rem !important;
      padding: 5px;
    }
  }
  .filter-input-container {
    position: absolute;
    right: 0;
    z-index: 10;
    border: 1px solid $red;
    border-radius: 0 4px 4px 4px;
    background-color: $white-dark;
    box-shadow: 0px 4px 4px 0px #00000026;
    transition: 0.3s;
    .show {
      display: flex;
      flex-direction: column;
      margin: 20px;
      max-height: 550px;
      overflow: hidden;
      header > .icon-wrapper {
        min-width: 1rem;
        cursor: pointer;
      }
      .filters-list {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        overflow-y: scroll;
        scrollbar-width: none;
        .badge {
          background-color: $white-darker;
          border: 1px solid $gray-light;
          text-align: left;
          min-height: 40px;
          .form-group {
            width: 100%;
            ::v-deep .form-row {
              align-items: center;
            }
          }
        }
      }
    }
  }
  .expanded {
    width: 330px;
  }
  .collapsed {
    width: 0;
    border: none;
  }
  .icon-expanded {
    margin-right: 330px;
  }
  .btn-outline-red{
    padding: 5px 20px;
  }
  .reset-button {
    background-color: $white-dark !important;
    border: 1px solid $white-dark;
    color: #48545D !important;
  }
  .reset-button:hover {
    font-weight: bold;
  }
</style>
