import { API } from "./utils"


export const broadcasters = {
  stationRetrieve: (uuid, params=null) => API.get(`/broadcasters/stations/${uuid}/`,
    { params: params }),
  stationsList: (params=null) => API.get("/broadcasters/stations/",
    { params: params }),
  broadcastersList: (params=null) => API.get("/broadcasters/broadcasters/",
    { params: params }),
}
