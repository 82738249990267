<template>
  <router-link
    class="text-info"
    :to="getRoute()"
    :target="target"
  >
    <slot/>
  </router-link>
</template>
<script>

import { linkTypes } from "@/constants"

export default {
  name: "DatabaseLink",
  props: {
    itemId: {
      type: String
    },
    type: {
      type: String
    },
    target: {
      type: String,
      default: "_self",
    }
  },
  methods: {
    getRoute () {
      if (this.type === linkTypes.RECORDING) {
        return {
          name: "repertoire-recording-detail",
          params: {
            recordingId: this.itemId,
            type: "general-details"
          }
        }
      } else if (this.type === linkTypes.RIGHTS_HOLDER) {
        return {
          name: "rightsholder-detail",
          params: {
            rightsholderId: this.itemId,
            type: "general-details"
          }
        }
      }
    }
  }
}

</script>
