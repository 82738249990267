<template>
  <div>
    <div v-if="uuid">
      <b-row class="mb-3 heading-container">
        <go-back ref="goback"/>
        <h4 class="pl-2">{{ internalChallenge ? internalChallenge.landingRecording.title : "" }}</h4>
      </b-row>
    </div>
    <b-table class="overflow-break" :fields="fields" :items="landingRecordingData(this.data)" striped fixed>

      <!-- Duration -->
      <template v-slot:cell(duration)="row">
        {{ row.item.duration | toDuration }}
      </template>

      <!-- Album names -->
      <template v-slot:cell(albums)="data">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="data.value"
        />
      </template>

       <!-- Composers -->
      <template v-slot:cell(composers)="data">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="data.value"
        />
      </template>
    </b-table>
    <h3 class="mb-2">
      {{ $t("linked_with_master") | capitalize }}:
    </h3>
    <b-table class="overflow-break" :fields="fields" :items="recordingData(this.data)" striped fixed>
      <!-- RS ID -->
      <template v-slot:cell(id)="row">
        <database-link :type="linkTypes.RECORDING" :item-id="row.item.id">{{ row.item.resoundId }}</database-link>
      </template>
      <!-- Duration -->
      <template v-slot:cell(duration)="row">
        {{ row.item.duration | toDuration }}
      </template>

      <!-- Album names -->
      <template v-slot:cell(albums)="data">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="data.value"
        />
      </template>

      <!-- Composers -->
      <template v-slot:cell(composers)="data">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="data.value"
        />
      </template>
    </b-table>
    <b-row>
      <b-button
          v-if="internalChallenge && permissions.actionsTabs[actionsTabs.RECORDING_CHALLENGES].canWrite"
          :disabled="![userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS].includes(internalChallenge.status) || waiting"
          variant="outline-success"
          class="ml-3"
          @click="confirmMatch(internalChallenge.id)"
      >
        <fa-icon size="sm" :icon="['fa', 'check']"/>
        {{ $t("confirm_the_match") | capitalize }}
      </b-button>
      <b-button
          v-if="internalChallenge && permissions.actionsTabs[actionsTabs.RECORDING_CHALLENGES].canWrite"
          :disabled="![userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS].includes(internalChallenge.status) || waiting"
          variant="outline-warning"
          class="ml-3"
          @click="unlinkSR(internalChallenge.id)"
      >
        <fa-icon size="sm" :icon="['fa', 'unlink']"/>
        {{ $t("unlink_create_sr") }}
      </b-button>
    </b-row>
    <comments
          v-if="internalChallenge"
          :createEndpoint="this.$api.comments.recordingChallengeCommentCreate"
          :listEndpoint="this.$api.comments.recordingChallengeCommentsList"
          :uuid="this.internalChallenge.id"
    />
  </div>
</template>
<script>
import { actionsTabs, linkTypes, userActionStatuses } from "@/constants"
import { mapActions, mapMutations, mapState } from "vuex"
import { startCase, upperCase } from "lodash"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import { capitalize } from "lodash"
import parseDate from "@/utils/date-parser"

export default {
  name: "RecordingChallengeDetail",
  components: { DatabaseLink, NamesListModal },
  props: {
    challenge: Object,
    uuid: String,
    solvedManually: {
      type: Array,
      required: false,
    },
    onDone: {
      type: Function,
      default: () => {}
    },
  },
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
    ...mapState("user", ["permissions", "waiting"]),
  },
  data () {
    return {
      linkTypes,
      userActionStatuses: userActionStatuses,
      data: [],
      internalChallenge: this.challenge,
      fields: [
        { key: "id", label: this.$t("id_resound_id"), width: "20%" },
        { key: "title", label: startCase(this.$t("title")), width: "20%" },
        { key: "artist", label: startCase(this.$t("artist")), width: "20%" },
        { key: "isrc", label: upperCase(this.$t("isrc")), width: "10%" },
        { key: "duration", label: startCase(this.$t("duration")), width: "5%" },
        { key: "type", label: startCase(this.$t("type")), width: "5%" },
        { key: "albums", label: startCase(this.$t("albums")), width: "10%" },
        { key: "composers", label: startCase(this.$t("composers")), width: "10%" },
      ],
      comments: [],
      actionsTabs,
    }
  },
  watch: {
    page (value) {
      this.onPagination(value)
      this.getData()
    },
  },
  methods: {
    ...mapActions("userActions", ["notifyUserAction"]),
    ...mapMutations("alert", ["error", "success"]),
    ...mapActions("actionTabs", ["decrementUserActionCount"]),
    parseDate,
    backOrEmit () {
      if(this.uuid) {
        this.decrementUserActionCount("matchingChallengeActionsCount")
        this.$refs.goback.goBack()
      } else {
        this.onDone()
      }
    },
    confirmMatch (uuid) {
      if (this.solvedManually) {
        this.solvedManually.push(uuid)
      }
      this.$api.actions.confirmLandingRecordingMatchingChallenge(uuid)
        .then(() => {
          this.success(capitalize(this.$t("matched")))
          this.backOrEmit()
        })
        .catch(err => {
          let msg = `Error confirming match. ${err.response.data.detail}`
          this.error(msg)
        })
    },
    unlinkSR (uuid) {
      if (this.solvedManually) {
        this.solvedManually.push(uuid)
      }
      this.$api.actions.unlinkLandingRecordingMatchingChallenge(uuid)
        .then((resp) => {
          this.success(resp.data.detail)
          this.backOrEmit()
        })
        .catch(err => {
          let msg = `Error unlinking recording. ${err.response.data.detail}`
          this.error(msg)
        })
    },
    landingRecordingData (data) {
      return [{
        "id": data.proprietaryId,
        "isrc": data.isrc,
        "title": data.title,
        "artist": data.artist,
        "duration": data.normalizedData ? this.data.normalizedData.duration : "",
        "type": data.normalizedData ? data.normalizedData.type : "",
        "albums": data.normalizedData ? data.normalizedData.releaseNames ? data.normalizedData.releaseNames : [] : [],
        "composers": data.normalizedData ? data.normalizedData.composers ? data.normalizedData.composers : [] : [],
      }]
    },
    recordingData (data) {
      return [{
        "resoundId": data.recording ? data.recording.resoundId : "",
        "id": data.recording ? data.recording.id : "",
        "isrc": data.recording ? data.recording.isrc : "",
        "title": data.recording ? data.recording.title : "",
        "artist": data.recording ? data.recording.artist : "",
        "duration": data.recording ? data.recording.duration : "",
        "type": data.recording ? data.recording.type : "",
        "albums": data.recording && data.recording.extras ? data.recording.extras.filter(e => e.tag === "release").map(x => x.value) : [],
        "composers": data.recording && data.recording.extras ? data.recording.extras.filter(e => e.tag === "composer").map(x => x.value) : [],
      }]
    },
    responseAdapter (data) {
      this.data = data.data
    },
    async getData (){
      // This means it's the standalone page, so we must load the challenge first
      if(this.uuid){
        let response = await this.$api.actions.recordingChallengeActionsRetrieve(this.uuid)
        this.internalChallenge = response.data
        this.internalChallenge.landingRecordingId = this.internalChallenge.landingRecording.id
      }

      this.$api.repertoire.landingRecordingRetrieve(
        this.internalChallenge.landingRecordingId
      ).then(response => {
        this.responseAdapter(response)
      })
    },
  },
  mounted () {
    this.getData()
    if (this.internalChallenge) {
      this.$emit("opened", this.internalChallenge.id)
    }
  },
  destroyed () {
    this.$emit("closed", this.internalChallenge.id)
  },
}
</script>
