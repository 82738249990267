import { API } from "./utils"


export const payments = {
  paymentFiles: (params = null) => API.get("/distributions/statements-files/",{ params: params }),
  paymentFileDetails: (params = null) => API.get("/distributions/statements-recordings/",{ params: params }),
  paymentFileErrorsList: (uuid, params = null) => API.get(`/distributions/statements-files/${uuid}/errors/`,{ params: params }),
  paymentsHistory: (params = null) => API.get("/distributions/payments-history/",{ params: params }),
  pushToReal: (data) => API.post("/distributions/push-payments-to-real/sync_to_real/", data),
  pushToRealInvalidReleased: (uuid, data) => API.post(`/distributions/push-payments-to-real/${uuid}/invalid_released/`, data),
  pushToRealInvalidRefund: (uuid, data) => API.post(`/distributions/push-payments-to-real/${uuid}/invalid_refund/`, data),
  pushToRealReset: (uuid, data) => API.post(`/distributions/push-payments-to-real/${uuid}/reset/`, data),
}
