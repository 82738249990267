<template>
  <list-layout
      :isChild="true"
      :title="$t('rightsholder_challenges') | startcase"
      :count="userActionCounts.performerChallengesCount + userActionCounts.makerChallengesCount"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
  >
    <template v-slot:search>
      <form-field
        class="file-search"
        :placeholder="$t('search') | capitalize"
        v-model="filters.fuzzySearch.value"
        :enter-action="applyFilters"
        :icon="['fas', 'search']"
      />
    </template>

    <template v-slot:filters>
      <b-row align-h="end" align-v="center">
        <b-col cols="3">
          <div>
            <b-form-radio-group
              v-model="filters.rightsholderType.value"
              buttons
              button-variant="outline-primary"
              name="radios-btn-outline"
              :options="typeOptions"
            />
          </div>
        </b-col>
        <b-col cols="5">
          <b-form-group
            label-cols="4"
            :label="$t('challenged_on') | capitalize"
          >
            <date-field
              v-model="filters.date.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-checkbox
            switch
            v-model="filters.showSystemRaised.value"
          >
            {{ $t('show_system_challenges') | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>

    <template v-slot:counts>
      <pagination
          class="smaller p-0 mb-3 mt-3"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="perPage"
          @limit="onLimit"
      />
    </template>

    <b-card no-body v-for="(challenge, index) in data" :key="index" :class="{ 'blink': shouldBlink(challenge.id), 'd-none': isHidden(challenge.id)}">
      <modal
        :id="`confirmation-modal-solved-${challenge.id}`"
        :customTitle="$t('user_action_solved') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="md"
        centered
        hide-footer
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
      >
        <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
        <b-row class="float-right modal-footer mt-4">
          <b-button @click="blinkAndHide(challenge.id, `rightsholder-${challenge.id}`, getData)" class="btn-outline-red">
            {{ $t("accept") | capitalize }}
          </b-button>
        </b-row>
      </modal>
      <b-card-header class="first-level">
        <b-row align-v="center">
          <b-col cols="6">
            <fa-icon
              v-if="isInProgressFrontend(challenge.id) || (isInProgressBackend([challenge.status]) && !isReleasedFrontend(challenge.id))"
              :icon="['fa', 'user-edit']"
               color="red"
            />
            <collapse-button class="collapse-button" :toggle="`rightsholder-${challenge.id}`" :expanded="expanded[index]" :disabled="loading"/>
            {{ challenge.name }} <span v-if="challenge.ipn">- {{ challenge.ipn }}</span>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <b-row class="w-100">
              <b-col cols="3">
                {{ $t('royalties_paid') | capitalize }}:
                {{ `$${challenge.royaltiesPaid}` }}
              </b-col>
              <b-col cols="3">
                {{ $t('estimated_track_value') | capitalize }}:
                {{ `$${challenge.estimatedTrackValue}` }}
              </b-col>
              <b-col cols="3">
                {{ $t('challenged_on') | capitalize }}:
                {{ parseDate(challenge.created, $config.DATE_FORMAT) }}
              </b-col>
              <b-col cols="3">
                {{ $t('by') | capitalize }}:
                {{ challenge.raisedBy ? `${challenge.raisedBy.firstName} ${challenge.raisedBy.lastName} (${challenge.raisedBy.memberCollective})` : $t("crescendo_system") }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse accordion="rightsholders-list" :id="'rightsholder-' + challenge.id" v-model="expanded[index]" role="tabpanel">
        <b-card-body v-if="expanded[index]">
            <rights-holder-challenge-detail
              :uuid="challenge.id"
              :standalone="false"
              :solved-manually="solvedManually"
              :onDone="onDone"
              @opened="onOpened"
              @closed="onClosed"
              :challengeType="type"
            ></rights-holder-challenge-detail>
        </b-card-body>
      </b-collapse>
    </b-card>
    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="count"
      :per-page="perPage"
    />
  </list-layout>
</template>
<script>
import { get, startCase } from "lodash"
import {
  getPerPageItemDefaults,
  rightsholderTypes,
  userActionNotifications,
  userActionStatuses
} from "@/constants"
import { mapGetters, mapMutations, mapState } from "vuex"
import Pagination from "@/components/Pagination"
import { listRouteMixin } from "@/utils/mixins"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "RightsholdersChallengeList",
  mixins: [listRouteMixin, websocketsMixin],
  components: {
    RightsHolderChallengeDetail: () => import("./RightsHolderChallengeDetail"),
    Pagination,
  },
  computed: {
    ...mapState("userActions", ["wsUserActionStatuses"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    ...mapGetters("user", ["hasMakers", "hasPerformers"]),
    typeOptions () {
      return Object.keys(rightsholderTypes).map(k => {
        return {
          text: `${startCase(rightsholderTypes[k])} (${this.userActionCounts[`${k.toLowerCase()}ChallengesCount`]})`,
          value: rightsholderTypes[k]
        }
      }).filter(o => o.value === rightsholderTypes.MAKER ? this.hasMakers : this.hasPerformers)
    },
    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
  },
  data () {
    return {
      count: 0,
      page: 1,
      loading: true,
      data: [],
      expanded: [],
      perPage: Number(getPerPageItemDefaults(this.$route)),
      rightsholderTypes: rightsholderTypes,
      filters: {
        fuzzySearch: { value: "", defaultValue: "" },
        date: { value: "", defaultValue: "" },
        rightsholderType: { value: "", defaultValue: rightsholderTypes.PERFORMER },
        userTimezone: { value: "", defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone },
        showSystemRaised: { value: false, defaultValue: false },
      },
      // WS values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.USER_ACTION,
      storeStatusesKey: "wsUserActionStatuses",
      type: rightsholderTypes.PERFORMER
    }
  },
  watch: {
    page (value) {
      this.onPagination(value)
      this.getData()
    },
    perPage () {
      if (this.page === 1) {
        this.getData()
      } else {
        this.page = 1
      }
    },
  },
  methods: {
    onDone (type) {
      this.setUserActionCounts({
        ...this.userActionCounts,
        [`${type}ChallengesCount`]: --this.userActionCounts[`${type}ChallengesCount`]
      })
      this.getData()
    },
    ...mapMutations("actionTabs", ["setUserActionCounts"]),
    responseAdapter (resp) {
      if (resp) {
        this.data = resp.data.results
        this.count = resp.data.count
      }
      this.loading = false
    },
    getData () {
      this.loading = true
      this.type = this.filters.rightsholderType.value

      let rightsholdersRequest
      if (this.type === rightsholderTypes.PERFORMER) {
        rightsholdersRequest = this.$api.rightsholders.performerChallengesList
      } else {
        rightsholdersRequest = this.$api.rightsholders.makerChallengesList
      }

      rightsholdersRequest({ 
        ...this.$route.query, 
        status: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
      })
        .then((resp) => {
          this.responseAdapter(resp)
        })
        .finally(() => {
          this.expanded = []
        })
    },
    parseDate
  },
  mounted () {
    if (!this.hasPerformers) {
      this.filters.rightsholderType.value = rightsholderTypes.MAKER
      this.filters.rightsholderType.defaultValue = rightsholderTypes.MAKER
    }
    this.getData()
    this.page = Number(get(this.$route.query, "page", 1))
    this.perPage = Number(get(this.$route.query, "limit", this.perPage))
  },
}

</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 10px;
}
</style>
