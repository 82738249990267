import { API } from "./utils"

export const repertoire = {
  actionsHistory: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/history_actions/`, { params }),
  challengeHistory: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/history_challenges/`, { params }),
  countries: (params = null) => API.get("/countries/", { params }),
  downloadErrorFile: (uuid, params = null) => API.get(`/repertoire/repertoire-files/${uuid}/download-error-file/`, { params, }),
  landingRecordingRetrieve: (uuid, params = null) => API.get(`/repertoire/landing-recordings/${uuid}/`, { params }),
  landingRecordings: (params = null) => API.get("/repertoire/landing-recordings/", { params }),
  landingRecordingsList: (uuid, params = null) => API.get(`/repertoire/repertoire-files/${uuid}/landing-recordings/`, { params, }),
  mergeMakers: data => API.post("/parties/merge-makers/", data),
  mergePerformers: data => API.post("/parties/merge-performers/", data),
  performerChallengesCreate: (data) => API.post("/user-actions/performer-challenges/", data),
  makerChallengesCreate: (data) => API.post("/user-actions/maker-challenges/", data),
  recordingChallengesCreate: (data) => API.post("/user-actions/recording-challenges/", data),
  recordingMetadata: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/general_detail/`, { params }),
  recordingMetadataOptions: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/metadata/`, { params }),
  recordingCarveOutInvalidateShares: (data) => API.post("/repertoire/recordings/carveout_invalidate_shares/", data),
  recordingCarveOutModifyShares: (data) => API.post("/repertoire/recordings/carveout_update_shares/", data),
  recordingOwnersUpdate: (uuid, data) => API.put(`/repertoire/recordings/${uuid}/owners/`, data),
  recordingOwners: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/owners/`, { params }),
  recordingPerformers: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/performers/`, { params }),
  recordingUsages: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/usages/`, { params }),
  recordingUpdate: (uuid, data) => API.patch(`/repertoire/recordings/${uuid}/`, data),
  recordings: (uuid, params = null) => API.get(`/repertoire/recordings/${uuid}/`, { params }),
  checkMerge: data => API.post("repertoire/recordings/check_merge/", data),
  recordingsLink: data => API.post("/repertoire/recordings/merge_recordings/", data),
  recordingsList: (params = null) => API.get("/repertoire/recordings-elastic/",
    { params: params }),
  sisterSocietyExport: data => API.post("/repertoire/recordings/sister_society_export/", data),
  export: data => API.post("/repertoire/recordings/export/", data),
  repertoireFileInvalidate: (uuid, data) => API.post(`/repertoire/repertoire-files/${uuid}/invalidate/`, data),
  repertoireFileErrorsList: (uuid, params = null) => API.get(`/repertoire/repertoire-files/${uuid}/errors/`, { params }),
  repertoireFileErrorsListFile: (uuid, params = null) => API.get(`/repertoire/repertoire-files/${uuid}/errors_file/`, { params, responseType: "blob" }),
  repertoireFilesList: (params = null) => API.get("/repertoire/repertoire-files/", { params: params }),
  repertoireFile: (uuid, params = null) => API.get(`/repertoire/repertoire-files/${uuid}/`, { params: params }),
  repertoireExportLast: (params = null) => API.get("/repertoire/repertoire-export-history/last_export/", { params }),
  unclaimedRecordingExport: data => API.post("/repertoire/recordings/export_unclaimed_recordings/", data),
  recordingEligibilityConflictResolve: (uuid, data) => API.post(`/repertoire/recordings/${uuid}/resolve_eligibility_conflict/`, data),
}
