const serializeParams = (params) => {
  let paramsStr = ""
  if (params && params.orderBy) {
    params.sorting = [params.ascending ? params.orderBy : `-${params.orderBy}`]
    if (params.multiSort) {
      params.sorting = params.sorting.concat(params.multiSort.map(column => {
        return column.ascending ? column.column : `-${column.column}`
      }))
      delete params.multiSort
    }
    delete params.orderBy
    delete params.ascending
    delete params.byColumn
  }

  Object.keys(params).forEach((key) => {
    if (!Array.isArray(params[key])) {
      paramsStr += `${key}=${encodeURIComponent(params[key])}&`
    } else {
      params[key].forEach((element) => {
        paramsStr += `${key}=${encodeURIComponent(element)}&`
      })
    }
  })

  return paramsStr ? paramsStr.slice(0, -1) : paramsStr
}

export default serializeParams
