<template>
  <div>
    <component :is="getComponent()">
      <template v-slot:typeSelector>
        <div>
          <b-form-group>
            <b-form-radio-group
              v-model="mergeRequestsType"
              buttons
              button-variant="outline-primary"
              name="radios-btn-outline"
            >
              <b-form-radio :value="mergeRequestsTypes.RECORDING">
                {{ $t("repertoire") | capitalize }}
              </b-form-radio>
              <b-form-radio disabled :value="mergeRequestsTypes.RIGHTS_HOLDER">
                {{ $t("rights_holder") | capitalize }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </template>
    </component>
  </div>
</template>

<script>
import RecordingMergeRequests from "@/pages/UserActions/RecordingMergeRequests"
import { mergeRequestsTypes } from "@/constants"

export default {
  name: "MergeRequestList",
  data () {
    return {
      mergeRequestsTypes,
      mergeRequestsType: mergeRequestsTypes.RECORDING,
    }
  },
  methods: {
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    getComponent () {
      switch (this.mergeRequestsType) {
      case mergeRequestsTypes.RECORDING:
        return RecordingMergeRequests
      case mergeRequestsTypes.RIGHTS_HOLDER:
        // TODO: add component
        return null
      }
    }
  }
}
</script>
