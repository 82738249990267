<!-- Based On: https://codesandbox.io/s/validation-components-bootstrapvue-usdwv -->
<template>
  <ValidationProvider :vid="$attrs.name" :name="$attrs.name" :rules="rules" v-slot="{ errors, classes }">
    <transition name="fade" mode="out-in">
      <b-form-group
        v-bind="$attrs"
        :label="null"
      >
        <label :for="$attrs.name">
          {{ $attrs.label | capitalize }}: <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <multi-select
          v-model="innerValue"
          v-bind="$attrs"
          v-on="$listeners"
          :label="$attrs.choiceLabel" 
          :class="classes"
          placeholder
        >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <fa-icon class="mr-2" :class="props.option.class" :icon="props.option.icon" v-if="props.option.icon"/>
              <span>{{ props.option.display }}</span>
            </template>
            <template slot="option" slot-scope="props">
              <fa-icon class="mr-2" :class="props.option.class" :icon="props.option.icon" v-if="props.option.icon"/>
              <span>{{ props.option.display }}</span>
            </template>
          <template v-slot:noOptions v-if="$attrs.placeholder">
            {{ $attrs.placeholder }}
          </template>
        </multi-select>
        <b-form-text v-if="helpText">{{ helpText }}</b-form-text>
        <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </transition>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 11pt;
  }

  ::v-deep .multiselect {
    width: 100%;
    [class$="multiselect__tags"] { border-color: $input-border-color; }
    &.invalid { [class$="multiselect__tags"] { border-color: var(--danger); } }
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "MultiSelectField",
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    helpText: String
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  computed: {
    isRequired () {
      return this.rules ? this.rules.split("|").includes("required") : false
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
