<template>
  <div class="d-flex align-items-center">
    <b-form-group
      class="mb-0 col-4 pl-0"
      :label-cols="labelCols"
      :label="label"
    >
      <b-form-select v-model="selection.except">
        <b-form-select-option :value="false">{{ includeString }}</b-form-select-option>
        <b-form-select-option :value="true">{{ exceptString }}</b-form-select-option>
      </b-form-select>
    </b-form-group>
      <multi-select
          v-model="selection.values"
          :options="options"
          :group-values="groupValues"
          :group-label="groupLabel"
          :group-select="groupSelect"
          label="name"
          :multiple="true"
          track-by="value"
          :hideSelected="!groupLabel"
          :closeOnSelect="false"
          :placeholder="placeholder"
          class="font-small"
      />
  </div>
</template>
<script>
export default {
  name: "IncludeExcludeSelector",
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    exceptString: {
      type: String,
      required: true,
    },
    includeString: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelCols: {
      type: String,
      default: "auto"
    },
    groupValues: {
      type: String,
      default: null,
    },
    groupLabel: {
      type: String,
      default: null,
    },
    groupSelect: {
      type: Boolean,
      default: false
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data () {
    return {
      selection: {
        except: false,
        values: [],
      },
    }
  },
  watch: {
    // Handles internal model changes.
    selection: {
      handler (newVal) {
        this.$emit("input", newVal)
      },
      deep: true,
    },
    // Handles external model changes.
    value (newVal) {
      this.selection = newVal
    }
  },
}
</script>
