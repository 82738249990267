import { API } from "./utils"

export const copyrights = {
  copyrightsTariffs: (params = null) =>
    API.get("/tariffs/copyrights/", { params }),
  copyrightsList: (params = null) =>
    API.get("/tariffs/copyrights-list/", { params }),
  tariffsList: (params = null) =>
    API.get("/tariffs/tariffs/", { params }),
  tariffThresholdsList: (params = null) =>
    API.get("/tariffs/tariff-thresholds/", { params }),
  tariffThresholdsUpdate: (data) =>
    API.post("/tariffs/tariff-thresholds/", data),
  createTariffEligibilityPeriods: (data) =>
    API.post("/tariffs/tariffs/", data),
}
