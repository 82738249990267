<template>
  <div class="pt-3">
    <comments
        :createEndpoint="this.$api.comments.recordingCommentCreate"
        :listEndpoint="this.$api.comments.recordingCommentsList"
        :editEndpoint="this.$api.comments.recordingCommentEdit"
        :deleteEndpoint="this.$api.comments.recordingCommentDelete"
        :uuid="this.recordingUUID"
        :editing=true
        :deleting=true
        :hideAddCommentBtn="true"
    />
  </div>

</template>

<script>

export default {
  name: "RecordingComments",
  props: {
    recordingUUID: String,
  },
}
</script>
