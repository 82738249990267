<template>
  <div>
    <b-row class="heading-container">
      <go-back />
      <h4 class="ml-2">
        {{ $t("create_new_tariff_period") | startcase }}
      </h4>
    </b-row>
    <div class="mb-3">
      <span>{{ currentProgress }}% {{ $t("complete") }}</span>
      <b-progress :value="currentProgress" :max="100" variant="success"/>
    </div>
    <b-row class="mb-3">
      <b-col class="d-flex justify-content-end">
        <b-button-group>
          <b-button variant="outline-secondary" :title="$t('expand_all') | capitalize" :disabled="isAllExpanded()" @click="expandAll()">
            <fa-layers>
              <fa-icon :icon="['fa', 'chevron-up']" transform="up-5"/>
              <fa-icon :icon="['fa', 'chevron-down']" transform="down-5"/>
            </fa-layers>
            {{ $t("expand_all") | capitalize }}
          </b-button>
          <b-button variant="outline-secondary" :title="$t('collapse_all') | capitalize" :disabled="isAllCollapsed()" @click="collapseAll()">
            <fa-layers>
              <fa-icon :icon="['fa', 'chevron-down']" transform="up-5"/>
              <fa-icon :icon="['fa', 'chevron-up']" transform="down-5"/>
            </fa-layers>
            {{ $t("collapse_all") | capitalize }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <div>
      <b-card
        no-body
        v-for="(section, key) in sections"
        :key="key"
        :border-variant="section.status.variant"
      >
        <b-card-header
            :header-bg-variant="section.status.variant"
        >
          <collapse-button :toggle="key" :expanded="section.open" class="mr-3"/>
          {{ section.title }}
          <span v-if="section.status.showErrors && section.fieldsWithErrors.length" class="text-red">
            Following fields have errors: {{ section.displayFieldsWithErrors() }}. Please complete these fields.
          </span>
          <span class="float-right status">
            {{ $t(section.status.title) | capitalize }}
            <fa-icon :icon="section.status.icon" transform="grow-5" class="ml-3"/>
          </span>
        </b-card-header>
        <b-collapse :visible="section.open" :id="key" @input="visible => section.update(visible)">
          <component :is="section.component" :ref="key" :distribution="distribution" @validated="e => section.validated(e)"/>
        </b-collapse>
      </b-card>
    </div>
    <b-row>
      <b-col>
        <b-button variant="outline-primary" @click="cancel">{{ $t("cancel") | capitalize }}</b-button>
        <b-button
          class="ml-3"
          variant="outline-secondary"
          @click="submit"
          :disabled="!canSubmit"
          :title="(canSubmit ? '' : $t('fill_in_the_missing_fields')) | capitalize"
        >
          {{ $t("create") | capitalize }}
        </b-button>
      </b-col>
    </b-row>
    <modal
      id="cancel-modal"
      cancel-variant="outline-primary"
      ok-variant="outline-secondary"
      :cancel-title="$t('no_continue_editing') | capitalize"
      :ok-title="$t('yes_cancel_editing') | capitalize"
      @ok="realCancel()"
      @cancel="$bvModal.hide('cancel-modal')"
      modal-type="info"
      :custom-title="$t('create_tariff_period') | capitalize"
    >
      {{ $t("cancel_create_tariff_period") }}
    </modal>
    <modal
      id="confirm-modal"
      cancel-variant="outline-primary"
      ok-variant="outline-secondary"
      :cancel-title="$t('cancel') | capitalize"
      :ok-title="$t('confirm') | capitalize"
      @ok="realSubmit()"
      @cancel="$bvModal.hide('confirm-modal')"
      modal-type="info"
      :custom-title="$t('new_tariff_period') | capitalize"
    >
      <p>{{ $t("create_tariff_period_confirm") }}</p>
      <b-row>
        <b-col>
          {{ $t("description") | capitalize }}: {{ distribution.description }}
        </b-col>
        <b-col>
          {{ $t("tariff") | capitalize }}: {{ distribution.tariff }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t("period_start_date") | capitalize }}: {{ distribution.periodStart | date($config.DATE_FORMAT) }}
        </b-col>
        <b-col>
          {{ $t("period_end_date") | capitalize }}: {{ distribution.periodEnd | date($config.DATE_FORMAT) }}
        </b-col>
      </b-row>
      <b-row v-if="distribution.proxyTariff">
        <b-col cols="6">
          {{ $t("proxy_tariff") | capitalize }}: {{ distribution.proxyTariff }}
        </b-col>
      </b-row>
      <b-row v-if="distribution.proxyTariff">
        <b-col>
          {{ $t("proxy_period_start_date") | capitalize }}: {{ distribution.proxyPeriodStart | date($config.DATE_FORMAT) }}
        </b-col>
        <b-col>
          {{ $t("proxy_period_end_date") | capitalize }}: {{ distribution.proxyPeriodEnd | date($config.DATE_FORMAT) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t("country") | capitalize }}: {{ distribution.country }}
        </b-col>
        <b-col>
          {{ $t("college") | capitalize }}: {{ distribution.college }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" v-if="distribution.college === distributionCollegeTypes.PERFORMER || distribution.college === distributionCollegeTypes.BOTH">
          {{ $t("revenue") | capitalize }} ({{ $t("performer") }}): {{ distribution.revenuePerformer }}
        </b-col>
        <b-col cols="6" v-if="distribution.college === distributionCollegeTypes.MAKER || distribution.college === distributionCollegeTypes.BOTH">
          {{ $t("revenue") | capitalize }} ({{ $t("maker") }}): {{ distribution.revenueMaker }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" v-if="distribution.college === distributionCollegeTypes.PERFORMER || distribution.college === distributionCollegeTypes.BOTH">
          {{ $t("reserve") | capitalize }} ({{ $t("performer") }}): {{ distribution.revenueReservePerformer }}
        </b-col>
        <b-col cols="6" v-if="distribution.college === distributionCollegeTypes.MAKER || distribution.college === distributionCollegeTypes.BOTH">
          {{ $t("reserve") | capitalize }} ({{ $t("maker") }}): {{ distribution.revenueReserveMaker }}
        </b-col>
      </b-row>
    </modal>

  </div>
</template>

<script>

import {
  canadaAlpha3Code,
  canadaCurrencyCode,
  distributionCollegeTypes,
  distributionMethodologies,
  distributionReserveValueTypes
} from "@/constants"
import EligibilitySection from "@/pages/Distributions/Sections/EligibilitySection"
import MainSection from "@/pages/Distributions/Sections/MainSection"
import OpenPeriodSection from "@/pages/Distributions/Sections/OpenPeriodSection"
import OtherSection from "@/pages/Distributions/Sections/OtherSection"
import VPPSection from "@/pages/Distributions/Sections/VPPSection"
import { capitalize } from "lodash"
import { mapActions } from "vuex"
import moment from "moment"

class SectionStatus {
  showErrors = false

  opened () {
    return this
  }
  closed () {
    return this
  }

  valid () {
    return new CompletedStatus()
  }

  invalid () {
    return new InProgressStatus()
  }

  validated (valid) {
    if (valid) {
      return this.valid()
    } else {
      return this.invalid()
    }
  }

  get title () {
    return null
  }

  get variant () {
    return "default"
  }

  get icon () {
    return ["far", "circle"]
  }
}

class NotStartedStatus extends SectionStatus {
  opened () {
    return new InProgressStatus()
  }

  validated () {
    return this
  }

  get title () {
    return "not_started"
  }
}

class InProgressStatus extends SectionStatus {
  closed () {
    return new ErrorStatus()
  }

  get title () {
    return "in_progress"
  }

  get variant () {
    return "info"
  }

  get icon () {
    return ["far", "dot-circle"]
  }
}

class ErrorStatus extends SectionStatus {
  showErrors = true

  opened () {
    return new InProgressStatus()
  }

  invalid () {
    return new ErrorStatus()
  }

  get title () {
    return "not_finished"
  }

  get variant () {
    return "danger"
  }

  get icon () {
    return ["fa", "exclamation-circle"]
  }
}

class CompletedStatus extends SectionStatus {
  opened () {
    return this
  }

  closed () {
    return this
  }

  get title () {
    return "complete"
  }
  get variant () {
    return "success"
  }

  get icon () {
    return ["fa", "check-circle"]
  }
}


class Section {
  elem
  totalFieldsCount = 0
  failedFieldsCount = 0

  constructor (title, component = null, open = false) {
    this.title = title
    this.open = open
    this.component = component
    this.status = open ? new InProgressStatus() : new NotStartedStatus()
    this.fieldsWithErrors = []
  }

  update (value) {
    if (value === this.open) return
    if (value) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  validated ({ valid, errors, totalFieldsCount, failedFieldsCount }) {
    this.status = this.status.validated(valid)
    this.fieldsWithErrors = Object.entries(errors).filter(([, v]) => v.length).map(([k, ]) => k)
    this.totalFieldsCount = totalFieldsCount
    this.failedFieldsCount = failedFieldsCount
  }

  expand () {
    this.open = true
    this.status = this.status.opened()
  }

  collapse () {
    this.open = false
    this.elem.validate().then((result) => {
      if (!result) {
        this.status = this.status.closed()
      }
    })
  }

  displayFieldsWithErrors () {
    return this.fieldsWithErrors.map(f => `[${f}]`).join(", ")
  }

  reset () {
    this.open = false
    this.status = new NotStartedStatus()
  }
}

export default {
  name: "CreateDistributionPage",
  components: { MainSection },
  data () {
    return {
      distributionCollegeTypes,
      distribution: {
        description: "",
        tariff: null,
        proxyTariff: null,
        periodStart: null,
        periodEnd: null,
        useProxyTariff: null,
        proxyPeriodStart: null,
        proxyPeriodEnd: null,
        country: canadaAlpha3Code,
        college: null,
        revenueCurrency: canadaCurrencyCode,
        revenueMaker: null,
        revenuePerformer: null,
        revenueReservePerformer: null,
        revenueReserveMaker: null,
        reserveValueTypePerformer: distributionReserveValueTypes.PERCENT,
        reserveValueTypeMaker: distributionReserveValueTypes.PERCENT,
        openPeriodStartPerformer: null,
        openPeriodEndPerformer: null,
        openPeriodStartMaker: null,
        openPeriodEndMaker: null,
        distributionMethodology: distributionMethodologies.STANDARD,
        primaryEligibilityScheme: [],
        secondaryEligibilityScheme: [],
        calculateVpp: null,
        vppMaker: null,
        vppPerformer: null,
        vppStatus: null,
        hold: null,
        calculateWithholding: null,
        notes: "",
      },
      sections: {
        main: new Section(
          capitalize(this.$t("main_details")),
          MainSection,
          true,
        )
        ,
        period: new Section(
          capitalize(this.$t("open_period")),
          OpenPeriodSection,
        ),
        eligibility: new Section(
          capitalize(this.$t("distribution_methodology_and_eligibility")),
          EligibilitySection,
        ),
        vpp: new Section(
          this.$t("vpp"),
          VPPSection,
        ),
        other: new Section(
          capitalize(this.$t("other")),
          OtherSection,
        ),
      }
    }
  },
  methods: {
    ...mapActions("alert", ["success", "error"]),
    isAllCollapsed () {
      return Object.values(this.sections).reduce((acc, section) => acc && !section.open, true)
    },
    isAllExpanded () {
      return Object.values(this.sections).reduce((acc, section) => acc && section.open, true)
    },
    collapseAll () {
      Object.values(this.sections).forEach(section => {
        section.collapse()
      })
    },
    expandAll () {
      Object.values(this.sections).forEach(section => {
        section.expand()
      })
    },
    cancel () {
      this.$bvModal.show("cancel-modal")
    },
    realCancel () {
      this.success(this.$t("tariff_period_creation_cancelled"))
      for (let section of Object.values(this.sections)) {
        section.reset()
      }
      this.$router.go(-1)
    },
    submit () {
      this.$bvModal.show("confirm-modal")
    },
    realSubmit () {
      const dateFields = Object.entries(this.distribution).filter(([, v]) => v instanceof Date).map(([k,]) => k)

      this.$api.distributions.createDistribution({
        ...Object.fromEntries(Object.entries(this.distribution).map(([k, v]) => [k, v === "" ? null : v])),
        ...Object.fromEntries(dateFields.map(f => [f, moment(this.distribution[f]).format(this.$config.ISO_DATE_FORMAT)])),
      }).then(() => {
        this.success(capitalize(this.$t("tariff_period_created")))
      }).catch(e => {
        let message = ""
        if (e.response.status === 422) {
          message = "Failed to create Tariff Period. Please correct the data and try again."
          if (e.response.data.fieldErrors) {
            for (let section of Object.values(this.sections)) {
              section.elem.setErrors(e.response.data.fieldErrors)
            }
          }
          if (e.response.data.nonFieldErrors) {
            message = message + "\n" + e.response.data.nonFieldErrors.map(e => e.message).join("\n")
          }
        } else {
          message = "Failed to create Tariff Period. Please contact administrator or try again."
        }
        this.error(message)
      })
    }
  },
  computed: {
    currentProgress () {
      let totalFields = 0
      let failedFields = 0
      for (let section of Object.values(this.sections)) {
        totalFields += section.totalFieldsCount
        failedFields += section.failedFieldsCount
      }
      if (totalFields > 0)
        return Math.ceil((totalFields - failedFields) / totalFields * 100)
      else
        return 0
    },
    canSubmit () {
      return Object.values(this.sections).reduce((a, s) => a && (s.status instanceof CompletedStatus || (s.totalFieldsCount === 0 && s.failedFieldsCount === 0)), true)
    }
  },
  mounted () {
    for (let [key, section] of Object.entries(this.sections)) {
      section.elem = this.$refs[key][0]
    }
  }
}

</script>

<style lang="scss">
  .card-header {
    &.bg-info {
      background-color: #e6eaf6 !important;
      .status {
        color: #f6950c;
      }
    }
    &.bg-success {
      background-color: #e4eFe7 !important;
      .status {
        color: #0f7a3e;
      }
    }
    &.bg-danger {
      background-color: #faeeee !important;
      .status {
        color: #c13c5b;
      }
    }
    &.bg-default {
      background-color: #e5e5e5 !important;
      .status {
        color: #5d676c;
      }
    }
  }
  .card {
    &.border-info {
      border-color: #808bb6 !important;
    }
    &.border-danger {
      border-color: #d48da1 !important;
    }
  }
  .progress {
    border-radius: 0.5rem;
    .progress-bar {
      border-radius: 0.5rem;
      &.bg-success {
        background-color: #0f7b3f !important;
      }
    }
  }

  #confirm-modal .row {
    margin-top: 0.25rem;
  }

</style>
