<template>
  <validation-observer ref="observer" tag="fieldset">
    <b-card-body>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="distributionMethodology"
            ref="distributionMethodology"
            :label="$t('distribution_methodology') | capitalize"
            label-cols="2"
            rules="required"
            v-slot="{ state }"
            >
            <b-form-select disabled v-model="distribution.distributionMethodology" :options="methodologyOptions" required :state="state"/>
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="primaryEligibilityScheme"
            ref="primaryEligibilityScheme"
            :label="$t('primary_eligibility_scheme') | capitalize"
            label-cols="2"
            rules="required"
            :detect-input="false"
            v-slot="{ state, validate }"
            >
            <multi-select
              v-model="internalPrimaryScheme"
              :options="eligibilityOptions"
              track-by="value"
              label="text"
              multiple
              placeholder=""
              required
              :close-on-select="false"
              @input="validate"
              :class="{'invalid': state === false}"
              @hook:mounted="validate(internalPrimaryScheme)"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <distribution-form-group
            vid="secondaryEligibilityScheme"
            ref="secondaryEligibilityScheme"
            :label="$t('secondary_eligibility_scheme') | capitalize"
            label-cols="2"
            :detect-input="false"
            v-slot="{ state, validate }"
            >
            <multi-select
              v-model="internalSecondaryScheme"
              :options="secondaryEligibilityOptions"
              track-by="value"
              label="text"
              multiple
              placeholder=""
              :close-on-select="false"
              @input="validate"
              :class="{'invalid': state === false}"
              @hook:mounted="validate(internalSecondaryScheme)"
            />
          </distribution-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </validation-observer>
</template>

<script>

import { distributionMethodologies, eligibilityOptions } from "@/constants"
import DistributionFormGroup from "@/components/DistributionFormGroup"
import { ValidationObserver } from "vee-validate"
import { capitalize } from "lodash"
import { distributionSectionMixin } from "@/utils/mixins"

export default {
  name: "EligibilitySection",
  components: { DistributionFormGroup, ValidationObserver },
  mixins: [ distributionSectionMixin ],
  props: {
    distribution: Object
  },
  data () {
    return {
      internalPrimaryScheme: undefined,  // have to use undefined in order for validation-provider to work correctly
      internalSecondaryScheme: [],
      methodologyOptions: [
        {
          text: capitalize(this.$t("standard_usage_based")),
          value: distributionMethodologies.STANDARD
        }
      ],
      eligibilityOptions,
    }
  },
  computed: {
    secondaryEligibilityOptions () {
      return this.eligibilityOptions.filter(o => !this.distribution.primaryEligibilityScheme.includes(o.value))
    },
    requiredFields () {
      return ["primaryEligibilityScheme"]
    },
    readonlyFields () {
      return ["distributionMethodology"]
    }
  },
  watch: {
    internalPrimaryScheme (newVal) {
      this.distribution.primaryEligibilityScheme = newVal.map(e => e.value)
      this.internalSecondaryScheme = this.internalSecondaryScheme.filter(e => !this.distribution.primaryEligibilityScheme.includes(e.value))
    },
    internalSecondaryScheme (newVal) {
      this.distribution.secondaryEligibilityScheme = newVal.map(e => e.value)
    }
  }

}
</script>

<style lang="scss">
  .multiselect__select {
    background: #fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
    &::before {
      content: none
    }
    right: 4px;
  }
  .multiselect--active .multiselect__select {
    transform: none;
  }
  .multiselect.invalid .multiselect__tags {
    border-color: $danger;
  }
</style>
