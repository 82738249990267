<template>
  <list-layout
      :title="$t('mandates') | startcase"
      :count="userActionCounts.mandateActionsCount"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
      :isChild="true"
  >
    <template v-slot:search>
      <form-field
        :placeholder="$t('search') | capitalize"
        v-model="filters.fuzzy_search.value"
        :enter-action="applyFilters"
        :icon="['fas', 'search']"
      />
    </template>
    <template v-slot:filters>
      <b-row align-h="end" align-v="center">
        <b-col cols="3">
          <!-- Type filter -->
          <b-form-group>
            <b-form-radio-group
              v-model="filters.type.value"
              buttons
              button-variant="outline-primary"
              name="radios-btn-outline"
            >
              <b-form-radio :value="null">
                {{ $t("all") | capitalize }}
              </b-form-radio>
              <b-form-radio value="performer">
                {{ $t("performer") | capitalize }}
              </b-form-radio>
              <b-form-radio value="maker">
                {{ $t("maker") | capitalize }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label-cols="auto"
            :label="showResolved ? $t('resolved_on') : $t('created_on') | capitalize"
          >
            <date-field
              v-model="filters.date.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-checkbox v-model="showResolved" switch>
            {{ $t("show_resolved") | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>

    <template v-slot:counts>
      <pagination
        class="smaller p-0 mb-3 mt-3"
        :page="page"
        :count="mandateConflictsCount"
        :loading="loading"
        v-model="perPage"
        @limit="onLimit"
      />
    </template>

    <b-card no-body v-for="(conflict, index) in mandateConflictsData" :key="index" :class="{ 'blink': shouldBlink(conflict.uuid), 'd-none': isHidden(conflict.uuid)}">
      <modal
        :id="`confirmation-modal-solved-${conflict.uuid}`"
        :customTitle="$t('user_action_solved') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="md"
        centered
        hide-footer
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
      >
        <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
        <b-row class="float-right modal-footer mt-4">
          <b-button @click="blinkAndHide(conflict.uuid, `conflict-${conflict.uuid}`, getData)" class="btn-outline-red">
            {{ $t("accept") | capitalize }}
          </b-button>
        </b-row>
      </modal>
      <b-card-header class="first-level">
        <b-row align-v="center">
          <b-col>
            <div class="d-flex">
              <div class="align-self-center">
                <template v-if="isInProgressFrontend(conflict.uuid) || (isInProgressBackend(conflict.userActionsStatuses) && !isReleasedFrontend(conflict.uuid))">
                  <fa-icon :icon="['fa', 'user-edit']" color="red"/>
                </template>
                <collapse-button :toggle="'conflict-' + conflict.uuid" :expanded="expanded[index]" :disabled="loading" />
                <fa-icon v-if="isMaker(conflict)" :icon="['fa', 'compact-disc']" class="ml-2"/>
                <fa-icon v-else :icon="['fas', 'microphone-alt']" class="ml-2"/>
              </div>
              <div class="ml-2">
                <database-link
                  :type="linkTypes.RIGHTS_HOLDER"
                  :item-id="conflict.uuid"
                >
                  {{ conflict.name }}
                </database-link>
                <div>
                  {{ conflict.resoundId }}
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="8" class="d-flex justify-content-end">
            <b-row class="w-100">
              <b-col cols="2">
                {{ $t('royalties_paid') | capitalize }}:
                {{ `$${conflict.royaltiesPaid}` }}
              </b-col>
              <b-col cols="2">
                {{ $t('estimated_track_value') | capitalize }}:
                {{ `$${conflict.estimatedTrackValue}` }}
              </b-col>
              <b-col cols="2">
                {{ $t('created_on') | capitalize }}:
                {{ parseDate(conflict.userActionCreated, $config.DATE_FORMAT) }}
              </b-col>
              <b-col cols="2">
                {{ $t('playcount') | capitalize }}: {{ conflict.playCount }}
              </b-col>
              <b-col cols="2">
                {{ $t('weighted_playcount') | capitalize }}: {{ conflict.weightedPlayCount }}
              </b-col>
              <b-col cols="2">
                <status
                  v-if="(
                    isInProgressFrontend(conflict.uuid) ||
                    (isInProgressBackend(conflict.userActionsStatuses) && !isReleasedFrontend(conflict.uuid))
                  ) && !isSolved(conflict.uuid)"
                  :text="$t('in_progress') | capitalize"
                  :icon="['fas', 'question-circle']"
                  color="orange"
                />
                <status
                  v-else-if="(isPending(conflict.userActionsStatuses) || isReleasedFrontend(conflict.uuid)) && !isSolved(conflict.uuid)"
                  :text="$t('conflict') | capitalize"
                  :icon="['fas', 'exclamation-circle']"
                  color="red"
                />
                <status
                  v-else
                  :text="$t('resolved') | capitalize"
                  :icon="['fas', 'check-circle']"
                  color="green"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse
        accordion="mandate-conflicts-list"
        :id="'conflict-' + conflict.uuid"
        v-model="expanded[index]"
        role="tabpanel"
      >
        <b-card-body v-if="expanded[index]">
          <MandateConflictDetail
            :rightsholder-id="conflict.uuid"
            :type="conflict.type"
            :solved-manually="solvedManually"
            @opened="onOpened"
            @closed="onClosed"
          />
        </b-card-body>
      </b-collapse>
    </b-card>
    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="mandateConflictsCount"
      :per-page="perPage"
    />
  </list-layout>
</template>
<script>
import { getPerPageItemDefaults, linkTypes, userActionNotifications, userActionStatuses } from "@/constants"
import { mapActions, mapGetters, mapState } from "vuex"
import { rightsholderDetailTabs, rightsholderTypes } from "@/constants"
import DatabaseLink from "@/components/DatabaseLink.vue"
import MandateConflictDetail from "@/pages/UserActions/MandateConflictDetail"
import Pagination from "@/components/Pagination"
import { get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import parseDate from "@/utils/date-parser"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "MandateConflictList",
  components: {
    DatabaseLink,
    MandateConflictDetail,
    Pagination,
  },
  mixins: [listRouteMixin, websocketsMixin],
  data () {
    return {
      linkTypes,
      loading: true,
      page: 1,
      perPage: Number(getPerPageItemDefaults(this.$route)),
      filters: {
        fuzzy_search: { value: "", defaultValue: "" },
        status: { value: "", defaultValue: [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS] },
        type: { value: "", defaultValue: null },
        date: { value: "", defaultValue: "" },
      },
      expanded: [],
      rightsholderDetailTabs,
      // WS values
      userActionNotification: userActionNotifications.MANDATES,
      storeStatusesKey: "wsMandateConflictStatuses",
    }
  },
  computed: {
    ...mapGetters("actionTabs", ["mandateConflictsData", "mandateConflictsCount"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    // WS values
    shownUuidList () {
      return this.mandateConflictsData.map(c => c.uuid)
    },
    showResolved: {
      get () {
        return this.filters.status.value.length === 1 && this.filters.status.value[0] === userActionStatuses.DONE
      },
      set (value) {
        this.filters.status.value = value ? [userActionStatuses.DONE] : [userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS]
      }
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", "getCopyrights"]),
    ...mapActions("actionTabs", ["getMandateConflicts"]),
    parseDate,
    getData () {
      this.loading = true
      const query  = { ...this.$route.query }
      this.getMandateConflicts({ ...query })
        .then(() => {
          this.resetState()
        })
        .finally(() => {
          this.expanded = []
          this.loading = false
        })
    },
    isMaker (row) {
      return row.type === rightsholderTypes.MAKER
    },
  },
  watch: {
    page (value) {
      this.onPagination(value)
      this.getData()
    },
    perPage () {
      if (this.page === 1) {
        this.getData()
      } else {
        this.page = 1
      }
    },
  },
  mounted () {
    this.getCountries()
    this.getCopyrights()
    this.getData()
    this.page = Number(get(this.$route.query, "page", 1))
    this.perPage = Number(get(this.$route.query, "limit", this.perPage))
  }
}
</script>
<style lang="scss" scoped>
  .card {
    margin-bottom: 10px;
  }
</style>
