<template>
  <div id="title" class="select-meta">
    <!-- Read mode -->
    <span v-if="!editMode">{{ duration | toDuration }}</span>

    <!-- Input Mode -->
     <ValidationProvider v-else-if="inputActive" rules="duration" v-slot="{ errors, classes }">
      <b-form-input
        ref="input"
        placeholder="mm:ss"
        size="sm"
        :state="classes.valid"
        v-model="inputDuration"
        type="text"
      />
      <span class="text-danger">{{ errors[0] | capitalize }}</span>
    </ValidationProvider>

    <!-- Select Mode -->
    <div v-else>
      <b-input-group>
        <b-form-select
          size="sm"
          v-model="duration"
          :options="getFieldOptions(this.fieldName)"
        ></b-form-select>
        <b-input-group-append>
          <span class="add clickable pl-2" @click="edit()"
            ><fa-icon size="sm" :icon="['fa', 'pen']"
          /></span>
        </b-input-group-append>
      </b-input-group>
    </div>

    <!-- Cancel icon -->
    <div class="save-cancel" v-if="editing">
      <fa-icon
        @click="undo()"
        class="cancel"
        size="sm"
        :icon="['fa', 'trash']"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { ValidationProvider } from "vee-validate"
import moment from "moment"

export default {
  name: "DurationField",
  components: { ValidationProvider },
  data () {
    return {
      fieldName: "duration",
      inputDuration: ""
    }
  },
  computed: {
    ...mapState("repertoire", [
      "editEnabled",
      "editMode",
      "initialRecordingData",
      "recordingData",
    ]),
    currentValue () {
      return this.recordingData.duration
    },
    ...mapGetters("repertoire", ["getFieldOptions"]),
    duration: {
      get () {
        return this.recordingData.duration
      },
      set (val) {
        let formattedValue = this.inputActive
          ? moment.duration(val).asMinutes()
          : val
        this.modifyRecordingData({
          field: this.fieldName,
          value: formattedValue,
        })
      },
    },
    editing () {
      return this.editEnabled[this.fieldName]
    },
    isEditing () {
      return this.editing && this.editMode
    },
    inputActive () {
      return this.editing
    },
  },
  methods: {
    ...mapMutations("repertoire", [
      "modifyRecordingData",
      "setEditField",
      "setEditMode",
    ]),
    edit () {
      this.setEditField({
        field: this.fieldName,
        value: true,
      })

      this.modifyRecordingData({
        field: this.fieldName,
        value: null,
      })

      this.$nextTick().then(() => {
        this.$refs["input"].focus()
      })
    },
    undo () {
      this.setEditField({
        field: this.fieldName,
        value: false,
      })

      this.inputDuration = null

      this.modifyRecordingData({
        field: this.fieldName,
        value: this.initialRecordingData[this.fieldName],
      })
    },
  },
  watch: {
    inputDuration (value) {
      if (value && value.match(/^\d{2}:\d{2}$/) != null) {
        this.modifyRecordingData({
          field: this.fieldName,
          value: moment.duration(value).asMinutes()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-meta {
  width: 80%;
  position: relative;

  div {
    .add {
      &:hover {
        color: $green-darker;
      }
    }
  }

  .input-group {
    align-items: center;
  }

  .input-group-append {
    min-width: 2rem;
  }

  .custom-select {
    border-radius: 4px !important;
    font-size: 1rem;
  }

  .save-cancel {
    position: absolute;
    right: -25px;
    top: 3px;
    cursor: pointer;

    .cancel {
      margin: 6px;

      &:hover {
        color: $red;
      }
    }
  }
}
</style>