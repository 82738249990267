<template>
  <div>
    <t-table
      :columns="columns"
      :options="options"
      :limit="limit"
      :id="id"
      ref="recordingData"
      :column-mode="displayData"
      :local-data="data"
      @loaded="onLoaded"
      @loading="onLoading"
      @pagination="onPagination"
      @sorted="onSorted"
    >
      <template v-slot:counts>
        <pagination
          v-if="!data"
          class="smaller p-0"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="limit"
          @limit="onLimit"
        />
      </template>
      <template v-slot:merge>
        <b-form-checkbox
          v-if="user.selectedMember.name === RESOUND"
          switch
          v-model="showRepTracks"
          class="ml-4"
        >
          {{ $t("show_rep_tracks") }}
        </b-form-checkbox>
      </template>
      <template v-slot:id="{ row }">
        {{ isMetaData ? row.proprietaryId : row.resoundId }}
        <div v-if="isMetaData && !noChallengesPending(row)">
          <router-link class="text-red small"
            :to="{ name: 'recording-challenge', params: { uuid: challengeUuid(row) } }"
          >
          {{ $t("challenge_pending") | capitalize }}
          </router-link>
        </div>
      </template>

      <!-- Member collective -->
      <template v-slot:memberCollective="{ row }">
        <span v-if="row.memberCollectiveName" >
          {{ row.memberCollectiveName }}
        </span>
        <span v-else>{{ row.memberCollective }}</span>
      </template>

      <!-- Proprietary ID -->
      <template v-slot:proprietaryId="{ row }">
        {{ row.proprietaryId }}
      </template>

      <!-- Merge Date -->
      <template v-if="!isMetaData" v-slot:mergeDate="{ row }">
        {{ getCleanDate(row.mergeDate) }}
      </template>

      <template v-slot:firstMakerNationalities="{ row }">
        <div
          v-for="(nationality, index) in row.firstMakerNationalities"
          :key="`nationality-${index}`"
        >
          {{ nationality.name }}
        </div>
      </template>

      <!-- Duration -->
      <template v-slot:duration="{ row }">
        {{ row.duration | toDuration }}
      </template>

      <!-- Albums -->
      <template v-slot:albums="{ row }">
        <NamesListModal
          class-names="table-meta mb-2"
          :values="getAlbums(row)"
        />
      </template>

      <!-- genreType -->
      <template v-slot:genre="{ row }">
        <span>
        {{ getGenres(row.genres).join(", ") }}
        </span>
      </template>

      <!-- First Makers -->
      <template v-slot:firstMakers="{ row }">
        <span v-if="isMetaData && !noChallengesPending(row)">
          <router-link class="text-red small"
            :to="{ name: 'recording-challenge', params: { uuid: challengeUuid(row) } }"
          >
          {{ $t("challenge_pending") | capitalize }}
          </router-link>
        </span>
        <span v-for="firstMaker in row.firstMakers" :key="firstMaker.name">
          {{ firstMaker.name }}
        </span>
      </template>

      <template v-slot:fixationCountry="{ row }">
        {{ isMetaData ? row.fixationCountry : (row.fixationCountry ? row.fixationCountry.name: "") }}
      </template>

      <!-- First Makers Nationalities -->
      <template v-slot:firstMakersNationalities="{ row }">
          <div v-for="firstMaker in row.firstMakers" :key="firstMaker.id">
            {{ firstMaker.nationalities && firstMaker.nationalities.length ? firstMaker.nationalities.join(",") : "-" }}
          </div>
      </template>

      <!-- Is ROME Eligible-->
      <template v-slot:isRomeEligible="{ row }">
        <boolean-status :is-true="row.eligibility.isRomeEligible"/>
      </template>

      <!-- Is WPPT Eligible-->
      <template v-slot:isWpptEligible="{ row }">
        <boolean-status :is-true="row.eligibility.isWpptEligible"/>
      </template>

      <!-- Is ROME + WPPT Eligible-->
      <template v-slot:isRomeWpptEligible="{ row }">
        <boolean-status :is-true="row.eligibility.isRomeWpptEligible"/>
      </template>

      <!-- Is CUSMA Eligible-->
      <template v-slot:isCusmaEligible="{ row }">
        <boolean-status :is-true="row.eligibility.isCusmaEligible"/>
      </template>

      <!-- PC fields deleted in RT2-687 -->

      <!-- Source file -->
      <template v-slot:repertoireFiles="{ row }">
        <span class="blue" v-if="row.repertoireFiles.length">
          {{ row.repertoireFiles.length }}
          <fa-icon
            :title="$t('expand_all_sources') | capitalize"
            @click="showSources(row.repertoireFiles)"
            class="blue clickable ml-2 more-values-icon"
            size="sm"
            :icon="['fas', 'plus-circle']"
          >
          </fa-icon>
        </span>
      </template>

      <template v-slot:ingestedOn="{ row }">
        {{ getIngestedDate(row) }}
      </template>

      <template v-slot:matchedOn="{ row }">
        <span v-if="isManuallyMatched(row)">
          {{ getMatchedDate(row) }}
        </span>
      </template>

      <template v-slot:matchedBy="{ row }">
        <span v-if="isManuallyMatched(row)">
          {{ getMatchedBy(row) }}
        </span>
      </template>

      <template v-slot:matchedStatus="{ row }">
        <span v-if="isManuallyMatched(row)">
          {{ getMatchedStatus(row) }}
        </span>
      </template>

      <template v-slot:actions="{ row }">
        <b-button v-if="row.reconciliationStatus === landingRecordingReconciliationStatuses.READY" size="sm" class="btn-outline-red" @click="challengeRecordingPopup(row)" :disabled="!noChallengesPending(row)">{{ $t("challenge") | capitalize }}</b-button>
      </template>

    </t-table>
    <b-modal
      id="sourcesModal"
      ref="sourcesModal"
      modal-class="custom-modal info"
      size="xl"
      centered
      hide-footer>
      <template #modal-title>
        <h4 class="d-flex align-items-center mb-0">
          <fa-icon :icon="['fas', 'exclamation-circle']" class="icon-blue mr-2"/>
          {{ $t('sources') | capitalize }}
        </h4>
      </template>
      <SourcesList
        :sources="currentSources"
      />
    </b-modal>
    <b-modal
      id="challengeModal"
      ref="challengeModal"
      modal-class="custom-modal edit"
      size="lg"
      centered
      hide-footer>
      <template #modal-title>
        <h4 class="d-flex align-items-center mb-0">
          <b-img height="30" :src="require('@/assets/pen_circle.svg')" class="pr-2"/>
          {{ $t('make_comment') | capitalize }}
        </h4>
      </template>
      <ValidationObserver ref="commentForm" v-slot="{ handleSubmit }">
        <ValidationProvider vid="comment" name="comment" rules="required" v-slot="{ errors }">
          <b-form @submit.prevent="handleSubmit(challengeRecording)">
            <b-form-textarea
              size="lg"
              class="mb-4"
              debounce="500"
              name="comment"
              :placeholder="$t('make_comment') | capitalize"
              v-model="challengeComment"
              rows="5"
            />
            <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
            <b-row class="modal-footer mt-4">
              <b-button class="btn-outline-dark" @click="$bvModal.hide('challengeModal')">
                {{ $t("cancel") | capitalize }}
              </b-button>
              <b-button class="btn-outline-red" type="submit">
                {{ $t("challenge") | capitalize }}
              </b-button>
            </b-row>
          </b-form>
        </ValidationProvider>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { RESOUND, getCleanDate, landingDataStatuses, landingRecordingReconciliationStatuses, landingStatuses, perPageItemsSm, userActionStatuses } from "@/constants"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { capitalize, startCase, upperCase } from "lodash"
import { getGenreType, getMetadata, getType } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import { mapMutations, mapState } from "vuex"
import BooleanStatus from "@/components/BooleanStatus"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import Pagination from "@/components/Pagination"
import SourcesList from "@/components/SourcesList"
import { getSubGenre } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import { listRouteMixin } from "@/utils/mixins"
import moment from "moment"

export default {
  name: "RecordingDetailSourcesList",
  components: { SourcesList, BooleanStatus, NamesListModal, Pagination, ValidationObserver, ValidationProvider },
  mixins: [listRouteMixin],
  computed: {
    ...mapState("user", ["permissions", "user"]),
    columns: function () {
      let mergeColumns = [
        "proprietaryId",
        "mergeDate",
      ]

      let columns = [
        "id",
        "memberCollective",
        "daName",
        "daType",
        "title",
        "artist",
        "isrc",
        "type",
        "duration",
        "albums",
        "genre",
        "firstMakers",
        "firstMakersNationalities",
        "fixationYear",
        "releaseYear",
        "fixationCountry",
        "isRomeEligible",
        "isWpptEligible",
        "isRomeWpptEligible",
        "isCusmaEligible",
      ]
      if (this.isMetaData) {
        columns.push(...[
          "ingestedOn",
          "matchedOn",
          "matchedBy",
          "matchedStatus",
          "repertoireFiles",
        ])
        if (this.challengesEnabled && this.permissions.actions.canChallengeRecordingSource) {
          columns.push("actions")
        }
      }

      if (!this.isMetaData) {
        columns.push(...mergeColumns)
      }
      return columns
    }
  },
  data () {
    return {
      RESOUND,
      landingRecordingReconciliationStatuses,
      recordingToChallenge: null,
      challengeComment: "",
      currentSources: [],
      limit: perPageItemsSm,
      updateRouterOnPagination: false,
      queryParams: {},
      showRepTracks: false,
      count: 0,
      loading: false,
      page: 1,
      options: {
        sortable: ["memberCollective", "id", "proprietaryId", "mergeDate", "daName", "daType", "title", "artist", "isrc", "type", "duration", "ingestedOn"],
        sorters: {
          isrc: "string",
          daName: "string",
          daType: "string",
        },
        rowClasses: (item) => {
          if (item.reconciliationStatus === landingRecordingReconciliationStatuses.NOT_READY) {
            return ["text-muted"]
          }
          return []
        },
        width: {
          repertoireFiles: 2,
          actions: 80
        },
        headings: {
          id:  upperCase(this.$t("id")),
          memberCollective: "MC",
          proprietaryId: capitalize(this.$t("proprietary_id")),
          mergeDate: "Merge date",
          daName: startCase(this.$t("da_name")),
          daType: startCase(this.$t("da_type")),
          title: capitalize(this.$t("title")),
          artist: capitalize(this.$t("artist")),
          isrc: upperCase(this.$t("isrc")),
          type: capitalize(this.$t("type")),
          duration: capitalize(this.$t("duration")),
          albums: capitalize(this.$t("albums")),
          genre: capitalize(this.$t("genre")),
          firstMakers: capitalize(this.$t("first_makers")),
          firstMakersNationalities: capitalize(this.$t("first_makers_nationalities")),
          fixationYear: capitalize(this.$t("fixation_year")),
          releaseYear: capitalize(this.$t("release_year")),
          fixationCountry: capitalize(this.$t("country_of_fixation")),
          isRomeEligible: upperCase(this.$t("nr")),
          isWpptEligible: upperCase(this.$t("wppt")),
          isRomeWpptEligible: `${upperCase(this.$t("nr"))}+${upperCase(this.$t("wppt"))}`,
          isCusmaEligible: upperCase(this.$t("cusma")),
          ingestedOn: capitalize(this.$t("ingested_on")),
          matchedOn: capitalize(this.$t("matched_on")),
          matchedBy: capitalize(this.$t("matched_by")),
          matchedStatus: capitalize(this.$t("matched_status"))
        },
        columnConfigurations: {
          coreMetadata: [
            "id",
            "memberCollective",
            "proprietaryId",
            "mergeDate",
            "daName",
            "daType",
            "title",
            "artist",
            "isrc",
            "type",
            "duration",
            "albums",
            "genre",
            "repertoireFiles",
            "actions",
          ],
          eligibilityMetadata: [
            "firstMakers",
            "firstMakersNationalities",
            "fixationYear",
            "releaseYear",
            "fixationCountry",
            "isRomeEligible",
            "isWpptEligible",
            "isRomeWpptEligible",
            "isCusmaEligible",
            "actions",
          ]
        },
        requestFunction () {
          let params = { ...this.$parent.queryParams,
            limit: this.$parent.limit,
            show_rep_tracks: this.$parent.showRepTracks,
            only_sources_in_conflict: this.$parent.onlyConflictedSource,
            conflicted_isrc: this.$parent.recordingIsrc,
            is_merge_list: !this.$parent.isMetaData
          }
          return this.$api.repertoire.recordingMetadata(this.$parent.recordingId, params)
        }
      }
    }
  },
  props: {
    recordingId: {
      type: String,
    },
    recordingIsrc: String,
    id: String,
    isMetaData: Boolean,
    displayData: String,
    data: Array,
    challengesEnabled: {
      type: Boolean,
      default: true
    },
    onlyConflictedSource: Boolean
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    getData () {
      if (this.isMetaData) {
        this.$refs.recordingData.getData()
      }
    },
    getCleanDate: getCleanDate,
    onLoaded ({ data }) {
      this.loading = false
      this.count = data.count
      this.recordingMetadata = data.results
    },
    showSources (sources) {
      this.currentSources = sources
      this.$refs.sourcesModal.show()
    },
    getAlbums (track) {
      return this.isMetaData ? track.releases : getMetadata(track.extras, "release")
    },
    noChallengesPending (row) {
      return ![
        userActionStatuses.PENDING,
        userActionStatuses.IN_PROGRESS,
      ].includes(row.matchingChallengeStatus) && row.status !== landingDataStatuses.PENDING
    },
    challengeUuid (row) {
      return row.matchingChallengeId
    },
    getIngestedDate (row) {
      return moment(row.repertoireFiles[0].uploadDate).format("DD/MM/YYYY")
    },
    isManuallyMatched (row) {
      // status can be manual in case of challenge resulted in unlinking, so check if there were actual conflicts
      return (row.status === landingDataStatuses.MANUALLY_CREATED || row.status === landingDataStatuses.MANUALLY_LINKED) && row.matchingConflicts.length
    },
    getMatchedDate (row) {
      if (this.isManuallyMatched(row)) {
        return moment(row.matchingConflicts[0].lastUpdated).format("DD/MM/YYYY")
      }
    },
    getMatchedBy (row) {
      if (this.isManuallyMatched(row)) {
        if (row.matchingConflicts[0].user) {
          return `${row.matchingConflicts[0].user.firstName} ${row.matchingConflicts[0].user.lastName}`
        } else {
          return ""
        }
      }
    },
    getGenres (genres) {
      return genres.map((genreData) => {
        let genre = genreData.genre
        if (genreData.subGenre) {
          let subGenre = getSubGenre(genreData.subGenre) || genreData.subGenre
          genre = `${genre} (${subGenre})`
        }
        return genre
      })
    },
    getMatchedStatus (row) {
      return startCase(this.$t(landingStatuses[row.status]))
    },
    challengeRecordingPopup (row) {
      this.recordingToChallenge = row
      this.$refs.challengeModal.show()
    },
    challengeRecording () {
      if (this.recordingToChallenge) {
        this.$api.repertoire.recordingChallengesCreate({
          landingRecordingId: this.recordingToChallenge.id,
          comments: [{ comment: this.challengeComment }]
        }).then(() => {
          this.success(capitalize(this.$t("saved_changes")))
          this.$refs.challengeModal.hide()
          this.challengeComment = ""
          this.recordingToChallenge = null
          this.getData()
        }).catch(error => {
          this.error(`Error creating challenge. ${JSON.stringify(error.response.data)}`)
        })
      }
    },
    getGenreType,
    getType,
  },
  watch: {
    recordingId () {
      this.queryParams = {}
      this.$refs.recordingData.page = 1
      this.getData()
    },
    showRepTracks () {
      this.$refs.recordingData.page = 1
      this.getData()
    },
    onlyConflictedSource (){
      this.$refs.recordingData.page = 1
      this.getData()
    }

  },
}
</script>

<style lang="scss" scoped>
.ingestion-stats {
  color: white;
  background: grey;
}

.comment {
  background: $gray;
}
</style>
