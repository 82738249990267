<template>
  <div>
    <v-server-table
      ref="adjustments"
      :columns="columns"
      :options="options"
      @row-click="selectAdjustment"
      @loaded="onLoaded"
      @loading="onLoading"
      @pagination="onPagination"
      @sorted="onSorted"
    >
      <template v-slot:inProgress="{ row }">
        <modal
          :id="`confirmation-modal-solved-${row.id}`"
          :customTitle="$t('user_action_solved') | capitalize"
          modal-class="custom-modal edit"
          modalType="edit"
          size="md"
          centered 
          hide-footer
          hide-header-close
          no-close-on-esc
          no-close-on-backdrop
        >
          <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
          <b-row class="float-right modal-footer mt-4">
            <b-button @click="blinkAndHide(row.id, null, getData)" class="btn-outline-red">
              {{ $t("accept") | capitalize }}
            </b-button>
          </b-row>
        </modal>
        <div>
          <fa-icon
            v-if="isInProgressFrontend(row.id) || (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))"
            :icon="['fa', 'user-edit']"
            color="red"
            class="mr-2"
          />
        </div>
      </template>

      <template v-slot:uniqueUsage.release="{ row }">
        <span :title="row.uniqueUsage.release">{{ row.uniqueUsage.release }}</span>
      </template>

      <template v-slot:uniqueUsage.stationNames="{ row }">
        <NamesListModal
            :values="row.uniqueUsage.stationNames ? row.uniqueUsage.stationNames : []"
            :title="row.uniqueUsage.stationNames ? row.uniqueUsage.stationNames.join(', ') : ''"
        />
      </template>

      <template v-slot:uniqueUsage.sourceNames="{ row }">
        <NamesListModal :values="row.uniqueUsage.sourceNames" :title="row.uniqueUsage.sourceNames"/>
      </template>
    </v-server-table>
  </div>
</template>
<script>
import { capitalize, get, upperCase } from "lodash"
import { mapMutations, mapState } from "vuex"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import { userActionNotifications } from "@/constants"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "NegativeAdjustmentsUsageList",
  mixins: [websocketsMixin],
  components: {
    NamesListModal,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    adjustmentType: {
      type: String,
    }
  },
  computed: {
    ...mapState("userActions", ["wsUserActionStatuses"]),
    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
  },
  methods: {
    ...mapMutations("alert", ["error"]),
    setLimit (limit) {
      this.$refs.adjustments.setLimit(limit)
    },
    onLoaded (data) {
      this.$emit("onLoaded", data)
    },
    onLoading () {
      this.$emit("onLoading")
    },
    onPagination (page) {
      this.$emit("onPagination", page)
    },
    onSorted (data) {
      this.$emit("onSorted", data)
    },
    selectAdjustment (adjustment) {
      adjustment = adjustment ? adjustment.row || adjustment : null
      this.$emit("selected", adjustment)
      if (adjustment) {
        this.onOpened(adjustment.id)
      }
    },
    getData () {
      this.selectAdjustment(null)
      this.$refs.adjustments.getData().catch(({ error }) => {
        if (error.response.data.fuzzySearch) {
          this.error(this.$t("wrong_filters"))
        }
      })
    },
  },
  data () {
    return {
      data: [],
      columns: [
        "inProgress",
        "uniqueUsage.isrc",
        "uniqueUsage.artist",
        "uniqueUsage.title",
        "uniqueUsage.release",
        "uniqueUsage.effectivePlaycount",
        "uniqueUsage.weightedPlaycount",
        "uniqueUsage.stationNames",
        "uniqueUsage.sourceNames",
      ],
      options: {
        rowClassCallback: (row) => {
          let classes = ["hover-highlight"]
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          return classes.join(" ")
        },
        columnsClasses: {
          inProgress: "width-2",
          "uniqueUsage.isrc": "width-small",
          "uniqueUsage.title": "width-large",
          "uniqueUsage.artist": "width-large",
          "uniqueUsage.release": "width-large",
          "uniqueUsage.effectivePlaycount": "width-small",
          "uniqueUsage.weightedPlaycount": "width-small",
          "uniqueUsage.stationNames": "width-small",
          "uniqueUsage.sourceNames": "width-small",
        },
        sortable: [],
        headings: {
          "inProgress": "",
          "uniqueUsage.isrc": upperCase(this.$t("isrc")),
          "uniqueUsage.title": capitalize(this.$t("title")),
          "uniqueUsage.artist": capitalize(this.$t("artist")),
          "uniqueUsage.release": capitalize(this.$t("album")),
          "uniqueUsage.effectivePlaycount": capitalize(this.$t("playcount")),
          "uniqueUsage.weightedPlaycount": capitalize(this.$t("weighted_playcount")),
          "uniqueUsage.stationNames": capitalize(this.$t("stations")),
          "uniqueUsage.sourceNames": capitalize(this.$t("sources")),
        },
        responseAdapter ({ data }) {
          let componentData = this.$parent.$parent
          componentData.data = data.results
          if (data.results.length === 1) {
            componentData.selectAdjustment(data.results[0])
          }
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          componentData.pageNumber = this.page
          return this.$api.actions.usageAdjustmentActions(queryParams)
        },
      },
      // WS Values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.USER_ACTION,
      storeStatusesKey: "wsUserActionStatuses",
    }
  }
}
</script>
<style lang="scss" scoped>
.table-responsive {
  .table {
    tr {
      &.hover-highlight {
        &:hover {
          cursor: pointer;
          background-color: $red-light;
        }
      }
    }
  }
}
</style>
