<template>
  <div>
    <div>
      <modal
        id="history"
        :customTitle="$t('history') | capitalize"
        modal-class="custom-modal info"
        modalType="info"
        size="xl"
        centered
        hide-footer
      >
        <div class="text-center mb-2">
          <b-form-group label-cols="auto">
            <b-form-radio-group
              v-model="historyType"
              buttons
              button-variant="outline-secondary"
              name="radios-btn-outline"
              class="radio-buttons"
            >
              <b-form-radio value="actions">
                {{ $t("actions") | capitalize }}
              </b-form-radio>
              <b-form-radio value="challenges">
                {{ $t("challenges") | capitalize }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <ChallengeHistory
          v-if="historyType === 'challenges'"
          :recordingId="selectedRecordingId"
        />
        <ActionsHistory
          v-if="historyType === 'actions'"
          :recordingId="selectedRecordingId"
        />
        <b-row class="modal-footer float-right mt-4">
          <b-button class="btn-outline-red" @click="$bvModal.hide('history')">Close</b-button>
        </b-row>
      </modal>
    </div>

    <div
        v-for="recording_data in data"
        :key="recording_data.recording.id"
    >
      <ValidationObserver v-slot="{ invalid }">
        <b-row class="m-auto">
            <h2 class="m-auto text-red">
              {{ recording_data.recording.resoundId }} -
              {{ recording_data.recording.title }}
            </h2>
            <b-col class="mr-auto d-flex justify-content-end">
              <div class="mr-3" id="edit-controls">
                <b-button v-if="editMode[recording_data.recording.id]" :disabled="invalid" class="mr-3" @click="save(recording_data.recording)">Save</b-button>
                <b-button v-else-if="!editMode[recording_data.recording.id]" @click="setEditMode(recording_data.recording, true)">Edit</b-button>
                <b-button v-if="editMode[recording_data.recording.id]" @click="cancel(recording_data.recording.id)">Cancel</b-button>
              </div>

              <div class="mr-3">
                <b-button v-b-modal.history @click="selectedRecordingId = recording_data.recording.id" >{{ $t("see_sr_history") }}</b-button>
              </div>
            </b-col>
        </b-row>

        <b-card class="recording-details">
          <b-table
            class="mb-4"
            :items="[recording_data.recording]"
            :fields="generalDetails"
            fixed
            small
          >
            <!-- Width handling -->
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
              />
            </template>
            <!-- resoundId -->
            <template v-slot:cell(resoundId)="row">
              <database-link :type="linkTypes.RECORDING" :item-id="row.item.id">{{ row.item.resoundId }}</database-link>
            </template>
            <!-- isrc -->
            <template v-slot:cell(isrc)="row">
              <div id="isrc">
                <span v-if="!editMode[recording_data.recording.id]">{{ row.item.isrc }} </span>
                <div v-else>
                  <ValidationProvider rules="isrc" v-slot="{ errors, classes }">
                    <b-form-input
                      ref="isrc"
                      placeholder="Eg. CAK401400212"
                      size="sm"
                      :state="classes.valid"
                      v-model="modifiedData[recording_data.recording.id]['isrc']"
                      type="text"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </template>

            <!-- type -->
            <template v-slot:cell(type)="row">
              <span>
                {{ getType(row.item.type) }}
              </span>
            </template>

            <!-- Duration -->
            <template v-slot:cell(duration)="row">
              {{ row.item.duration | toDuration }}
            </template>

            <!-- Albums -->
            <template v-slot:cell(albums)="row">
              <NamesListModal
                class-names="table-meta mb-2"
                :values="getTrackAlbums(row.item.extras)"
              />
            </template>

            <!-- Genre Type -->
            <template v-slot:cell(genreType)="row">
                {{ getGenreType(row.item.genreType) }}
            </template>
          </b-table>

          <b-table
            class="mb-4"
            :items="[recording_data.recording]"
            :fields="eligibility"
            fixed
            small
          >
            <!-- Country Of Fixation -->
            <template v-slot:cell(fixationCountry)="row">
              <span>{{ row.item.fixationCountry.name }}</span>
            </template>
            <!-- Is ROME Eligible-->
            <template v-slot:cell(isRomeEligible)="row">
              <boolean-status :is-true="row.item.eligibility && row.item.eligibility.isRomeEligible"/>
            </template>

            <!-- Is WPPT Eligible-->
            <template v-slot:cell(isWpptEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isWpptEligible"/>
            </template>

            <!-- Is ROME + WPPT Eligible-->
            <template v-slot:cell(isRomeWpptEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isRomeWpptEligible"/>
            </template>

            <!-- Is CUSMA Eligible-->
            <template v-slot:cell(isCusmaEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isCusmaEligible"/>
            </template>

            <!-- Is PC Maker Eligible-->
            <template v-slot:cell(isPcMakerEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isPcMakerEligible"/>
            </template>

            <!-- Is PC Performer Eligible-->
            <template v-slot:cell(isPcPerformerEligible)="row">
              <boolean-status :is-true="row.item.eligibility &&  row.item.eligibility.isPcPerformerEligible"/>
            </template>
          </b-table>

          <b-table
            :items="[recording_data.recording]"
            :fields="firstMakersFields"
            fixed
            small
          >
            <!-- First maker -->
            <template v-slot:cell(firstMakers)="row">
              <div v-for="firstMaker in row.item.firstMakers" :key="firstMaker.id">
                <div>
                    {{ firstMaker.name }}
                </div>
              </div>
            </template>

            <!-- First Maker Nationality -->
            <template v-slot:cell(firstMakerNationalities)="row">
              <div v-for="firstMaker in row.item.firstMakers" :key="firstMaker.id">
                <div>
                  {{ firstMaker.nationalities && firstMaker.nationalities.length ? firstMaker.nationalities.map((n) => getCountryName(n)).join(", ") : "" }}
                </div>
              </div>
            </template>
          </b-table>
        </b-card>
      </ValidationObserver>
      <div class="d-flex mt-3 mb-3">
        <h3 class="text-red">
          {{ $t("source_metadata") | startcase }}
        </h3>
        <b-form-group class="col d-flex justify-content-center">
          <b-form-radio-group
              v-model="selectedSourceMetadata[recording_data.recording.id]"
              :options="sourceMetadataOptions"
              buttons
              button-variant="outline-secondary"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <RecordingDetailSourcesList
        :collapse="false"
        :isMetaData="true"
        :displayData="selectedSourceMetadata[recording_data.recording.id]"
        :recordingId="recording_data.recording.id"
        :recordingIsrc="recording_data.recording.isrc"
        :onlyConflictedSource="onlyConflictedSource"
        class="recording-sources"
        :challenges-enabled="false"
        id="duplicate-isrc-sources"
      />
      <hr style="background-color: var(--secondary);">
    </div>
    <h2 class="text-red">{{ $t("comments") | startcase }}</h2>
    <comments
      :extra-content="{conflicted_isrc: selectedRow.conflictedIsrc}"
      :createEndpoint="$api.comments.usageIsrcCommentCreate"
      :listEndpoint="$api.comments.usageIsrcCommentList"
      :uuid="selectedRow.id"
      :editing=false
      :deleting=false
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { capitalize, startCase, upperCase } from "lodash"
import { getGenreType, getType } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import { mapActions, mapMutations, mapState } from "vuex"
import ActionsHistory from "@/pages/Repertoire/Recordings/RecordingDetail/History/ActionsHistory"
import BooleanStatus from "@/components/BooleanStatus"
import ChallengeHistory from "@/pages/Repertoire/Recordings/RecordingDetail/History/ChallengeHistory"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import { Pages } from "@/utils/pages"
import RecordingDetailSourcesList from "@/pages/Repertoire/Recordings/RecordingDetail/RecordingDetailSourcesList.vue"
import { linkTypes } from "@/constants"

export default {
  name: "DuplicateIsrcConflictsDetail",
  components: {
    ActionsHistory,
    BooleanStatus,
    ChallengeHistory,
    DatabaseLink,
    NamesListModal,
    RecordingDetailSourcesList,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [],
  props: {
    selectedRow: {
      type: Object,
      required: false,
    },
    onlyConflictedSource: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      linkTypes,
      data: [],
      modifiedData: {},
      editMode: {},

      historyType: "actions",
      selectedRecordingId: null,

      generalDetails: [
        {
          key: "resoundId",
          label: this.$t("resound_id"),
          width: "10%",
        },
        {
          key: "title",
          label: capitalize(this.$t("title")),
          selected: null,
          width: "17%",
        },
        { key: "artist", label: capitalize(this.$t("artist")), width: "20%" },
        { key: "isrc", label: upperCase(this.$t("isrc")), width: "10%" },
        { key: "type", label: capitalize(this.$t("type")), width: "10%" },
        {
          key: "duration",
          label: capitalize(this.$t("duration")),
          width: "6%",
        },
        { key: "albums", label: capitalize(this.$t("albums")), width: "10%" },
        {
          key: "genreType",
          label: startCase(this.$t("genre_type")),
          width: "7%",
        },
      ],
      eligibility: [
        { key: "fixationYear", label: startCase(this.$t("year_of_fixation")) },
        { key: "releaseYear", label: startCase(this.$t("year_of_release")) },
        {
          key: "fixationCountry",
          label: startCase(this.$t("country_of_fixation")),
        },
        { key: "isRomeEligible", label: upperCase(this.$t("nr")) },
        { key: "isWpptEligible", label: upperCase(this.$t("wppt")) },
        { key: "isRomeWpptEligible", label: `${upperCase(this.$t("nr"))}+${upperCase(this.$t("wppt"))}` },
        { key: "isCusmaEligible", label: upperCase(this.$t("cusma")) },
        { key: "isPcMakerEligible", label: this.$t("pc_maker") },
        { key: "isPcPerformerEligible", label: this.$t("pc_performer") },
      ],
      firstMakersFields: [
        { key: "firstMakers", label: startCase(this.$t("first_makers")) },
        {
          key: "firstMakerNationalities",
          label: startCase(this.$t("first_maker_nationality")),
        },
        {
          key: "weightedPlayCount",
          label: startCase(this.$t("weighted_playcount")),
        },
      ],
      // source metadata
      selectedSourceMetadata : {},
      sourceMetadataOptions: [
        {
          text: capitalize(this.$t("core_metadata")),
          value: "coreMetadata"
        },
        {
          text: capitalize(this.$t("eligibility_metadata")),
          value: "eligibilityMetadata"
        }
      ]
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries"]),
    ...mapMutations("alert", ["error", "success"]),
    getData () {
      if (this.selectedRow) {
        let queryParams = { conflicted_isrc: this.selectedRow.conflictedIsrc }
        this.$api.actions.duplicatedIsrcDetail(
          this.selectedRow.id,
          queryParams
        ).then(response => {
          this.data = response.data
          this.selectedSourceMetadata = Object.assign(
            {}, ...this.data.map(r => {return { [r.recording.id]: "coreMetadata" }})
          )
        })
      }
    },
    canEditRecording () {
      return this.permissions.pages[Pages.RepertoireRecordingDetail].canWrite
    },

    setEditMode (recordingData, value) {
      this.$set(this.editMode, recordingData.id, value)
      this.$set(this.modifiedData, recordingData.id, {
        "isrc": recordingData.isrc,
      })
    },

    cancel (blockId) {
      this.$set(this.editMode, blockId, false)
    },
    save (recordingData) {
      let requestData = this.modifiedData[recordingData.id]
      this.$api.repertoire.recordingUpdate(recordingData.id, {
        isrc: requestData.isrc ? requestData.isrc : null,
      })
        .then(response => {
          this.success(capitalize(this.$t("saved_changes")))
          let keys = Object.keys(response.data)
          keys.forEach((key) => {
            recordingData[key] = response.data[key]
          })
        })
        .catch((err) => {
          if (err.response.data.detail) {
            let msg = `Error update recording. ${err.response.data.detail}`
            this.error(msg)
          } else {
            let errors = Object.keys(err.response.data)
            this.error(
              `There were errors in the following fields: ${errors}. Please check the input data`
            )
          }
        })
      this.cancel(recordingData.id)
    },
    getTrackAlbums (extras) {
      return extras ? extras.filter((x) => x.tag === "release").map((x) => x.value) : []
    },
    getCountryName (code) {
      let country = this.countries.find(c => c.alpha2 === code)
      return country ? country.text : code
    },
    getGenreType,
    getType,
  },
  mounted () {
    this.getCountries()
    this.getData()
  },
  watch: {
    selectedRow () {
      this.getData()
    },
  },
  computed: {
    ...mapState("consts", ["countries"]),
    ...mapState("user", ["permissions"]),
  },
}
</script>

<style lang="scss" scoped>
.recording-details {
  margin-top: 20px;
  border: none;
  background: #fafafa;

  ::v-deep table {
    thead {
      background: transparent !important;

      th {
        font-weight: bold;
        background: transparent !important;
        padding: 0;
        color: #2b2b2b !important;
      }
    }
    tbody {
      td {
        padding: 0;
      }
    }
  }
  .meta-data-row {
    padding: 20px;
  }

  ::v-deep table td th {
    padding: 0;
  }
}

.recording-sources {
  :deep thead {
    background: #ededed !important;
  }
  :deep th {
    background: transparent !important;
    color: #2b2b2b !important;
  }
  :deep th, :deep td {
    padding: 10px !important;
  }
}

.radio-buttons{
  .btn-outline-secondary {
    min-width: 108px;
    color: $primary !important;
    border-color: $gray-light !important;
    &:hover {
      color: #FFFFFF !important;
      background-color: $gray-light !important;
    }
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active {
    color: #FFFFFF !important;
    background-color: $gray-light !important;
  }
}
</style>
