<template>
  <div>
    <modal
        id="comment-adjustment-modal"
        :customTitle="$t('make_comment') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="lg"
        centered
        hide-footer
    >
      <b-form-group
        :label="`${permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canWrite ? $t('effective_date') : $t('suggested_effective_date')}` | capitalize"
      >
        {{ selectedEffectiveDate }}
      </b-form-group>
      <ValidationObserver ref="commentForm" v-slot="{ handleSubmit }">
        <ValidationProvider vid="comment" name="comment" rules="required" v-slot="{ errors }">
          <b-form @submit.prevent="handleSubmit(applyAdjustment)">
            <b-form-group
              :label="`${$t('comment')}` | capitalize"
              class="mt-4"
            >
              <b-form-textarea
                size="lg"
                class="mb-4"
                debounce="500"
                name="comment"
                :placeholder="$t('make_comment') | capitalize"
                v-model="adjustmentComment"
                rows="5"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
            <b-row class="modal-footer mt-4">
              <b-button @click="$bvModal.hide('comment-adjustment-modal')" class="btn-outline-dark">
                {{ $t("cancel") | capitalize }}
              </b-button>
              <b-button type="submit" class="btn-outline-red">
                {{ $t("solve") | capitalize }}
              </b-button>
            </b-row>
          </b-form>
        </ValidationProvider>
      </ValidationObserver>
    </modal>
    <div class="mb-4">
      <b-table
        v-if="newRecordingData"
        class="mb-4"
        :items="[newRecordingData]"
        :fields="recordingColumns"
        :caption-top="true"
        fixed
        small
      >
        <template #table-caption>{{ $t("current") | capitalize }}</template>
        <template v-slot:cell(resoundId)="row">
          <database-link
            :type="linkTypes.RECORDING"
            :item-id="row.item.id"
          >
            {{ row.item.resoundId }}
          </database-link>
        </template>
      </b-table>
      <hr>
      <b-table
        v-if="oldRecordingData"
        class="mb-4"
        :items="[oldRecordingData]"
        :fields="recordingColumns"
        :caption-top="true"
        fixed
        small
      >
        <template #table-caption>{{ $t("previous") | capitalize }}</template>
        <template v-slot:cell(resoundId)="row">
          <database-link
            :type="linkTypes.RECORDING"
            :item-id="row.item.id"
          >
            {{ row.item.resoundId }}
          </database-link>
        </template>
      </b-table>
    </div>
    <b-row class="pt-4">
      <b-col cols="8" class="d-flex">
        <h2 class="text-red">{{ $t("usage_adjustment") | startcase }}</h2>
        <date-field
          label-cols="5"
          :group-label="$t('effective_date') | capitalize"
          name="selectedEffectiveDate"
          v-model="selectedEffectiveDate"
          class="ml-4"
          :format="$config.DATE_FORMAT"
        />
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-if="status === conflictModes.ADJUSTMENT"
          :disabled="!selectedEffectiveDate"
          @click="solveAdjustmentModal()"
        >
          {{ permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canWrite ? $t('apply_effective_date') : $t('suggest_effective_date') | startcase }}
        </b-button>
      </b-col>
    </b-row>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <b-form-group>
        <b-form-radio-group
          v-model="rightsHolderType"
          buttons
          button-variant="outline-primary"
          name="radios-btn-outline"
        >
          <b-form-radio :value="rightsholderTypes.MAKER">
            {{ $t("ownership") | capitalize }}
          </b-form-radio>
          <b-form-radio :value="rightsholderTypes.PERFORMER">
            {{ $t("performers") | capitalize }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <div>
      <h3>{{ $t("current") | startcase }}</h3>
      <owners
        v-if="rightsHolderType === rightsholderTypes.MAKER && newRecordingId"
        title=""
        :recordingUUID="newRecordingId"
        :viewMode="conflictModes.VIEW"
        :showSource="false"
      />
      <performers
        v-else-if="newRecordingId"
        title=""
        :recordingUUID="newRecordingId"
        :view-mode="conflictModes.VIEW"
        :showSource="false"
      />
    </div>
    <div>
      <h3>{{ $t("previous") | startcase }}</h3>
      <owners
        v-if="rightsHolderType === rightsholderTypes.MAKER && oldRecordingId"
        title=""
        :recordingUUID="oldRecordingId"
        :viewMode="conflictModes.VIEW"
        :showSource="false"
      />
      <performers
        v-else-if="oldRecordingId"
        title=""
        :recordingUUID="oldRecordingId"
        :view-mode="conflictModes.VIEW"
        :showSource="false"
      />
    </div>
    <div>
    <!-- PAYMENTS -->
      <list-layout class="mt-3" :isChild="true" :title="$t('payments') | startcase">
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              :page="page"
              :count="count"
              :loading="loading"
              reference="payments"
              v-model="limit"
              @limit="onLimit"
              :key="selectedRow.id"
            />
          </div>
        </div>
      </template>
      <template v-slot:filters>
        <b-row align-h="end">
          <b-col cols="3">
            <b-form-group class="m-0">
              <b-form-checkbox v-model="onlyAffectedPayments" switch>
                {{ $t("only_affected_rights_holders") | capitalize }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group class="m-0">
              <b-form-checkbox v-model="filterByEffectiveDate" switch>
                {{ $t("affected_payments_by_effective_date") | capitalize }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-slot:table>
        <v-server-table
          v-if="oldRecordingId"
          ref="payments"
          :columns="columns.payments"
          :options="options"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          :key="selectedRow.id"
        >
          <template v-slot:distributionPeriod="{row}">
            {{ parseDate(row.distributionPeriodStart, $config.DATE_FORMAT) + " - " + parseDate(row.distributionPeriodEnd, $config.DATE_FORMAT) }}
          </template>
          <template v-slot:distributionDate="{row}">
            <span>
              {{ parseDate(row.distributionDate, $config.DATE_FORMAT) }}
            </span>
          </template>
        </v-server-table>
      </template>
    </list-layout>
    </div>
    <div>
      <h3 class="text-red">{{ $t("comments") | startcase }}</h3>
      <comments-table
        ref="comments"
        v-if="this.selectedRow.id"
        :createEndpoint="this.$api.comments.usageNegativeAdjustmentsCommentsCreate"
        :listEndpoint="this.$api.comments.usageNegativeAdjustmentsCommentsList"
        :columns="columns.comments"
        :headings="headings"
        :uuid="this.selectedRow && this.selectedRow.id ? this.selectedRow.id : null"
        :editing=false
        :deleting=false
        :header="false"
        createModalId="new-comment-modal"
      />
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { actionsTabs, conflictModes, linkTypes, perPageItemsSm, rightsholderTypes } from "@/constants"
import { capitalize, startCase, upperCase } from "lodash"
import CommentsTable from "@/components/CommentsTable"
import DatabaseLink from "@/components/DatabaseLink.vue"
import Owners from "@/pages/Repertoire/Recordings/RecordingDetail/Owners/Owners"
import Pagination from "@/components/Pagination"
import Performers from "@/pages/Repertoire/Recordings/RecordingDetail/Performers/Performers"
import { mapState } from "vuex"
import moment from "moment"
import parseDate from "../../utils/date-parser"
import { solveAdjustmentMixin } from "@/utils/mixins"

export default {
  name: "NegativeAdjustmentUsageDetail",
  mixins: [solveAdjustmentMixin],
  components: {
    DatabaseLink,
    CommentsTable,
    Owners,
    Pagination,
    Performers,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapState("user", ["permissions"]),
  },
  props: {
    selectedRow: {
      type: Object,
      required: false,
    },
    status: {
      type: Array,
      required: false,
    }
  },
  methods: {
    parseDate,
    solveAdjustmentModal () {
      this.$bvModal.show("comment-adjustment-modal")
    },
    applyAdjustment () {
      this.solveAdjustment(
        this.adjustmentComment,
        this.$api.actions.resolveUsageAdjustmentAction,
        this.$api.comments.usageNegativeAdjustmentsCommentsCreate
      )
    },
    onLoading () {
      this.loading = true
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    onLimit (limit) {
      this.limit = limit
      this.$refs.payments.setLimit(limit)
    },
    onPagination (page) {
      this.page = page
    },
  },
  data () {
    return {
      conflictModes,
      rightsholderTypes,
      linkTypes,
      actionsTabs,
      loading: false,
      page: 1,
      count: 0,
      limit: perPageItemsSm,
      onlyAffectedPayments: false,
      filterByEffectiveDate: false,
      adjustmentComment: "",
      headings: {
        created: capitalize(this.$t("when")),
        createdBy: capitalize(this.$t("reviewer")),
        "createdBy.memberCollective": startCase(this.$t("mc")),
        comment: capitalize(this.$t("comment")),
        suggestedEffectiveDate: capitalize(this.$t("suggested_effective_date")),
        actions: "",
      },
      options: {
        sortable: ["rightsHolder", "amount", "payee", "distributionDate"],
        headings: {
          payeeType: capitalize(this.$t("payee_type")),
          payee: capitalize(this.$t("payee")),
          sisterSociety: capitalize(this.$t("sister_society")),
          rightsHolderType: capitalize(this.$t("rights_holder_type")),
          rightsholder: capitalize(this.$t("rightsholder")),
          rightsHolder: capitalize(this.$t("rights_holder")),
          tariff: capitalize(this.$t("tariff")),
          activityType: capitalize(this.$t("activity_type")),
          distribution: capitalize(this.$t("distribution")),
          distributionDate: capitalize(this.$t("distribution_date")),
          distributionPeriod: capitalize(this.$t("distribution_period")),
          split: capitalize(this.$t("share")),
          amount: capitalize(this.$t("amount")),
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          const component = this.$parent.$parent.$parent
          const idFilter = component.selectedRow ? { recording_id: component.oldRecordingId } : {}
          let effectiveDate = component.selectedEffectiveDate ? moment(component.selectedEffectiveDate, this.$config.DATE_FORMAT).format(this.$config.ISO_DATE_FORMAT) : null
          const effectiveDateFilter = effectiveDate && component.filterByEffectiveDate ? { from_date: effectiveDate } : {}
          const filters = {
            rights_holder_type: rightsholderTypes.MAKER,
            negative_adjustments_only: component.onlyAffectedPayments,
            ...idFilter,
            ...effectiveDateFilter,
          }
          queryParams = { ...filters, ...queryParams }
          return this.$api.payments.paymentsHistory(queryParams)
        }
      },
      columns: {
        payments: [
          "payeeType",
          "rightsHolder",
          "payee",
          "activityType",
          "distribution",
          "distributionPeriod",
          "distributionDate",
          "tariff",
          "split",
          "amount",
        ],
        comments: [
          "created",
          "createdBy",
          "createdBy.memberCollective",
          "comment",
          "suggestedEffectiveDate",
          "actions",
        ],
      },
      recordingColumns: [
        {
          key: "title",
          label: capitalize(this.$t("title")),
          width: "20%",
        },
        {
          key: "artist",
          label: capitalize(this.$t("artist")),
          width: "20%",
        },
        {
          key: "isrc",
          label: upperCase(this.$t("isrc")),
          width: "20%",
        },
        {
          key: "resoundId",
          label: this.$t("resound_id"),
          width: "20%",
        },
        {
          key: "genre",
          label: capitalize(this.$t("genre")),
          width: "20%",
        },
      ],
      selectedEffectiveDate: null,
      rightsHolderType: rightsholderTypes.MAKER,
      paymentRightsHolderType: null,
      oldRecordingId: null,
      oldRecordingData: null,
      newRecordingId: null,
      newRecordingData: null,
    }
  },
  mounted () {
    this.$api.actions.usageAdjustmentActionsRetrieve(this.selectedRow.id).then(result => {
      this.oldRecordingId = result.data.oldRecordingId
      if (this.oldRecordingId) {
        this.$api.repertoire.recordings(this.oldRecordingId).then((d) => {
          this.oldRecordingData = d.data
        })
      }
      this.newRecordingId = result.data.newRecordingId
      if (this.newRecordingId) {
        this.$api.repertoire.recordings(this.newRecordingId).then((d) => {
          this.newRecordingData = d.data
        })
      }
    })
  },
  watch: {
    onlyAffectedPayments () {
      this.$refs.payments.getData()
    },
    filterByEffectiveDate () {
      this.$refs.payments.getData()
    }
  }
}
</script>
