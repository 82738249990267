<template>
  <pagination
      :per-page="perPage"
      :records="Math.min(totalRows, 10000)"
      v-model="page"
      :options="options"
  />
</template>
<script>

import Pagination from "vue-pagination-2"
export default {
  name: "TablePagination",
  components: { Pagination },
  props: {
    value: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        texts: {
          count: ""
        }
      })
    }
  },
  data: () => ({
    page: 1,
  }),
  watch: {
    value (val) {
      this.page = val
    },
    page (val) {
      this.$emit("input", val)
    }
  }
}
</script>
<style lang="scss">
.page-item .page-link {
  cursor: pointer;
  border: none;
  color: $gray-light;
  border-radius: 50% !important;
  user-select: none;
  min-width: 30px;
  text-align: center;
  vertical-align: middle;
}
</style>
