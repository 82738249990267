<template>
  <list-layout
      :no-search="true"
      :no-counts="true"
  >
    <template v-slot:beforeFilters>
      <b-row>
        <b-col>
          <filter-builder
            :id="userPreferenceNames.USAGES_FILTER"
            :filters="getFiltersWithOptions()"
            :apply-filters="applyFilters"
            :reset-filters="resetFilters"
            :filters-pending="filtersPending"
            :filters-applied="filtersApplied"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:table>
      <t-table
        ref="usages"
        id="recording-usages"
        :columns="columns"
        :options="options"
        :limit="limit"
        @loaded="onLoaded"
        @loading="onLoading"
        @pagination="onPagination"
        @sorted="onSorted"
      >
        <template v-slot:counts>
          <pagination
            class="smaller p-0"
            reference="repertoire"
            :page="page"
            :count="count"
            :loading="loading"
            v-model="limit"
            @limit="onLimit"
          />
        </template>
        <template v-slot:copyright="{ row }">
          {{ copyrights.find(c => c.code === row.copyright).text }}
        </template>

        <template v-slot:tariff="{ row }">
          {{ tariffs.find(t => t.code === row.tariff).text }}
        </template>

        <template v-slot:title="{ row }">
          <router-link v-if="row.pendingChallenge"
            class="text-red small mr-2"
            :to="{
              name: 'user-actions',
              params: { type: actionsTabs.USAGE_CHALLENGES },
              query: { fuzzy_search: resoundId },
            }"
          >
            <fa-icon
              :icon="['fas', 'exclamation-circle']"
              :title="$t('usage_challenge') | capitalize"
              class="text-red"
            />
          </router-link>
          <router-link
            class="text-info clickable"
            :to="{
            name: 'consolidated-usage-list',
            query: {
              year: null,
              fuzzy_search: row.uniqueUsageUuid
            }
          }">{{ row.title }}</router-link>
        </template>
      </t-table>
    </template>
  </list-layout>
</template>
<script>
import { actionsTabs, filterTypes, perPageItemsSm, userPreferenceNames } from "@/constants"
import { capitalize, get } from "lodash"
import { mapActions, mapState } from "vuex"
import FilterBuilder from "@/components/FilterBuilder"
import Pagination from "@/components/Pagination"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "Usages",
  components: { FilterBuilder, Pagination },
  mixins: [listRouteMixin],
  data () {
    return {
      actionsTabs,
      loading: false,
      limit: perPageItemsSm,
      page: 1,
      count: 0,
      maxPlaycount: 0,
      maxWeightedPlaycount: 0,
      userPreferenceNames,
      columns: [
        "title",
        "artist",
        "copyright",
        "tariff",
        "year",
        "playcount",
        "weightedPlaycount",
      ],
      filters: {
        title: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("title")),
        },
        artist: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("artist")),
        },
        year: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("year")),
        },
        copyright: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("copyright")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        tariff: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("tariff")),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          options: [],
        },
        playcount: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("playcount")),
          type: filterTypes.NUMBER_RANGE,
          min: 0,
          max: 0
        },
        weightedPlaycount: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("weighted_playcount")),
          type: filterTypes.NUMBER_RANGE,
          min: 0,
          max: 0,
          step: 0.1,
        }

      },
      options: {
        sortable: ["title", "artist", "tariff", "copyright", "playcount", "year", "weightedPlaycount"],
        headings: {
          title: capitalize(this.$t("title")),
          artist: capitalize(this.$t("artist")),
          copyright: capitalize(this.$t("copyright")),
          tariff: capitalize(this.$t("tariff")),
          year: capitalize(this.$t("year")),
          playcount: capitalize(this.$t("playcount")),
          weightedPlaycount: capitalize(this.$t("weighted_playcount")),
          royalties: capitalize(this.$t("royalties"))
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          this.$parent.$parent.maxPlaycount = data.maxPlaycount
          this.$parent.$parent.maxWeightedPlaycount = data.maxWeightedPlaycount
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          const id = this.$parent.$parent.recordingUUID
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.repertoire.recordingUsages(id, queryParams)
        }
      }
    }
  },
  computed: {
    ...mapState("consts", ["copyrights", "tariffs"]),
    tariffOptions () {
      let options = this.tariffs
      if (this.filters.copyright.value) {
        options = options.filter(
          t => t.copyright === this.filters.copyright.value
        )
      }
      options = options.map(c => {
        return { text: c.text, value: c.code }
      })
      return options
    }
  },
  methods: {
    ...mapActions("repertoire", ["getRecordingUsages"]),
    ...mapActions("consts", ["getTariffs", "getCopyrights"]),
    getData () {
      this.$refs.usages.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    onPagination (page) {
      this.page = page
      this.updateRouterPagination("page", page)
    },
    getFiltersWithOptions () {
      this.filters.copyright.options = this.copyrights.map(c => {
        return { text: c.text, value: c.code }
      })
      this.filters.tariff.options = this.tariffOptions
      this.filters.playcount.max = this.maxPlaycount
      this.filters.weightedPlaycount.max = this.maxWeightedPlaycount
      return this.filters
    }
  },
  watch: {
    "filters.copyright.value": function () {
      if (
        this.filters.tariff.value &&
        !this.tariffOptions.filter(t => t.value === this.filters.tariff.value)
          .length
      ) {
        this.filters.tariff.value = this.filters.tariff.defaultValue
      }
      this.changeFilter("copyright", this.filters.copyright.value)
    }
  },
  props: {
    recordingUUID: String,
    resoundId: {
      type: String,
      required: true,
    },
  },
  mounted () {
    if (!this.copyrights.length) {
      this.getCopyrights()
    }
    if (!this.tariffs.length) {
      this.getTariffs()
    }
    this.updateTableSortIcons(this.$refs.usages.$children[0], true)
  }
}
</script>
<styles lang="scss" scoped>
  .tab-content {
    .no-title {
      display: none;
    }
  }
</styles>