import Vue from "vue"

const state = {
  socket: {
    connected: false,
    $socket: null,
    reconnectionAttempts: 0,
    errors: 0
  }
}

const mutations = {
  SOCKET_ONOPEN (state, event) {
    state.socket.connected = true
    state.socket.$socket = event.currentTarget
    state.socket.reconnectionAttempts = 0
    state.socket.errors = 0
  },

  SOCKET_ONCLOSE (state) {
    state.socket.connected = false
    state.socket.$socket = null
  },

  SOCKET_ONERROR (state) {
    if (state.socket.errors > Vue.prototype.$config.MAX_WS_ERRORS) {
      Vue.prototype.$disconnect()
    }
    state.socket.errors  = state.socket.errors + 1
  },

  SOCKET_RECONNECT (state) {
    Vue.prototype.$disconnect()
    if (state.socket.reconnectionAttempts > Vue.prototype.$config.MAX_WS_ERRORS) {
      return
    }
    state.socket.reconnectionAttempts = state.socket.reconnectionAttempts + 1
    Vue.prototype.$auth.getAuthResponse().then((token) => setTimeout(() =>{
      Vue.prototype.$connect(`${process.env.VUE_APP_API_WS_HOST}?token=${token}` )
    }, Vue.prototype.$config.WS_RECONNECTION_DELAY))
  },

  SOCKET_RECONNECT_ERROR (state) {
    if (state.socket.reconnectionAttempts > Vue.prototype.$config.MAX_WS_ERRORS) {
      Vue.prototype.$disconnect()
    }
  }
}

const actions = {
  sendMessage (context, message) {
    if (context.state.socket.connected) {
      context.state.socket.$socket.sendObj(message)
    }
  }
}

export const websocket = {
  state,
  actions,
  mutations,
}
