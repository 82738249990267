<template>
  <div id="container" class="d-flex align-items-center h-100">
    <b-form-input class="w-20" v-model.number="lowerBound"/>
    <div class="d-flex flex-column flex-grow-1">
      <div id="sliders" class="d-flex position-relative">
        <input ref="fromSlider" :class="{ top: topSlider === 'from', 'order-1': topSlider === 'from' }" type="range" v-model.number="lowerBound" :min="min" :max="max" :step="step"/>
        <input :class="{ top: topSlider === 'to', 'order-1': topSlider === 'to' }" type="range" v-model.number="upperBound" :min="min" :max="max" :step="step"/>
      </div>
    </div>
    <b-form-input class="w-20"  v-model.number="upperBound"/>
  </div>
</template>

<script>
import { isArray } from "lodash"
export default {
  name: "DualRangeSlider",
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      default: 1,
    },
    value: {
      type: Array
    }
  },
  data () {
    return {
      lowerBound: this.min,
      upperBound: this.max,
      topSlider: "from"
    }
  },
  methods: {
    focus () {
      this.$refs.fromSlider.focus()
    },
    parseValue (value) {
      if (isArray(value)) {
        this.lowerBound = Math.min(...value.map(Number))
        this.upperBound = Math.max(...value.map(Number))
      } else {
        this.lowerBound = this.min
        this.upperBound = this.max
      }
    }
  },
  watch: {
    value (newVal) {
      this.parseValue(newVal)
    },
    lowerBound () {
      if (this.lowerBound > this.upperBound) {
        this.topSlider = "from"
        this.lowerBound = this.upperBound
      }
      this.$emit("input", [this.lowerBound, this.upperBound])
    },
    upperBound () {
      if (this.lowerBound > this.upperBound) {
        this.topSlider = "to"
        this.upperBound = this.lowerBound
      }
      this.$emit("input", [this.lowerBound, this.upperBound])
    }
  },
  mounted () {
    this.parseValue(this.value)
  }
}
</script>

<style scoped lang="scss">
  #container {
    min-width: 200px;
    column-gap: 5px;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;
  }
  input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;
  }

  input[type=range]::-webkit-slider-thumb:hover {
    background: $gray;
  }

  input[type=range]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 1px $light-red, 0 0 6px $light-red;
    -webkit-box-shadow: inset 0 0 1px $light-red, 0 0 6px $light-red;
  }

  input[type=range]::-moz-range-thumb:hover {
    background: $gray;
  }
  input[type=range]::-moz-range-thumb:active {
    box-shadow: inset 0 0 1px $light-red, 0 0 6px $light-red;
    -webkit-box-shadow: inset 0 0 1px $light-red, 0 0 6px $light-red;
  }

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: #C6C6C6;
    pointer-events: none;

    &.top {
      z-index: 1;
      height: 0;
      margin-top: 1px;
    }

  }
</style>
