<template>
  <div class="card border-0">
    <b-row class="heading-container">
      <h4 class="ml-2">
        {{ $t("manual_matching") | startcase }}
      </h4>
    </b-row>
    <metadata-conflict-detail
      ref="conflict"
      :conflict="conflict" :standalone="true"
      @matched="onMatched"
    />
  </div>
</template>
<script>
import MetadataConflictDetail from "@/pages/UserActions/MetadataConflictDetail"
import { userActionStatuses } from "@/constants"

export default {
  name: "MetadataConflictDetailPage",
  components: { MetadataConflictDetail },
  props: {
    uuid: String,
    status: String,
    type: String,
  },
  data () {
    return {
      conflict: {
        id: this.uuid,
        status: this.status,
        type: this.type,
      }
    }
  },
  methods: {
    onMatched () {
      this.conflict.status = userActionStatuses.DONE
      this.$refs.conflict.getData()
    }
  }
}
</script>

<style lang="scss">
  .main-info {
    table thead th {
      border-top: none !important;
      background-color: white !important;
    }
  }
</style>
