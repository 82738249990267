<template>
  <div class="h-100">
    <list-layout
        :title="$t('shared_repertoire_area') | startcase"
        :no-counts="true"
        :no-search="true"
        :search-filter="filters.fuzzySearch"
        :apply-filters="applyFilters"
        :no-apply-btn="true">
      <!-- Modals -->
      <modal
        id="recordings-link-modal"
        :customTitle="$t('merge_recordings') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="xl"
        centered
        hide-footer
      >
        <recordings-link-form
          :canMerge="canMergeDirectly"
          :hasSameTypes="hasSameTypes"
          :recordingsInOtherMergeRequests="recordingsInOtherMergeRequests"
          :selectedRecordings="selectedData"
          @linked="onLinked"
        />
      </modal>
      <modal
        id="shares-invalidate-modal"
        :customTitle="$t('invalidate_shares') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="lg"
        centered
        hide-footer
      >
        <ownership-create-form
          :recording-uuids="selectedData.map(r => r.resoundId)"
          :edit-split="false"
          :carve-out="true"
          @submit="onSubmitInvalidate"
        />
      </modal>
      <modal
        id="shares-modify-modal"
        :customTitle="$t('modify_shares') | capitalize"
        modal-class="custom-modal edit"
        modalType="edit"
        size="lg"
        centered
        hide-footer
      >
        <ownership-create-form
          :recording-uuids="selectedData.map(r => r.resoundId)"
          :carve-out="true"
          @submit="onSubmitEditShares"
        />
      </modal>
      <modal 
        id="repertoire-export-modal" 
        :customTitle="exportTitle" 
        modal-class="custom-modal edit"
        modalType="edit"
        size="xl"
        centered
        hide-footer>
        <recordings-export-form :export-unclaimed="exportUnclaimed" />
      </modal>
      <template v-slot:beforeFilters>
        <b-row>
          <b-col>
            <filter-builder
              :id="userPreferenceNames.RECORDINGS_LIST_FILTER"
              :filters="getFiltersWithOptions()"
              :apply-filters="applyFilters"
              :reset-filters="resetFilters"
              :filters-pending="filtersPending"
              :filters-applied="filtersApplied"
              @filter="onFilter"
            >
              <template v-slot:proprietaryId>
                <b-form-group v-if="permissions.actions.hasAllRhPermission" :label="filters.proprietaryIdMemberCollective.label" label-cols="auto">
                  <b-form-select v-model="filters.proprietaryIdMemberCollective.value" :options="filters.proprietaryIdMemberCollective.options">
                    <template v-slot:first>
                      <b-form-select-option :value="null">
                        {{ $t("all_member_collectives") | capitalize }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group :label="filters.proprietaryId.label" label-cols="auto">
                  <form-field v-model="filters.proprietaryId.value" ref="proprietaryId" :enter-action="applyFilters"/>
                </b-form-group>
              </template>
            </filter-builder>
          </b-col>
        </b-row>
      </template>
      <!-- Table -->
      <template v-slot:table>
        <t-table
          :columns="columns"
          :options="options"
          :selected-data="selectedData"
          :selectable="permissions.actions.canMergeRecording || permissions.actions.canInvalidateShares || permissions.actions.canModifyShares"
          ref="recordings"
          id="recordings"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <template v-slot:counts>
            <div class="d-flex">
              <pagination
                class="smaller p-0"
                :page="page"
                :count="count"
                :loading="loading"
                :per-page-selector="!viewingCart"
                v-model="limit"
                @limit="onLimit"
              />
              <merge-summary
                v-if="selectedData.length || viewingCart"
                class="ml-2 smaller"
                :selectedObjects="selectedData"
                :clear="clearCart"
                :switchView="toggleCart"
                :buttonText="$t('merge_recordings')"
                badgeDisplayField="title"
              />
            </div>
          </template>
          <template v-slot:merge>
            <span>
              <b-button
                v-if="permissions.actions.canInvalidateShares"
                size="sm"
                :disabled="!selectedData.length"
                variant="outline-primary"
                @click="invalidateShares()"
              >
                <fa-icon size="sm" :icon="['fa', 'ban']" />
                {{ $t("invalidate_shares") | capitalize }}
              </b-button>
              <b-button
                v-if="permissions.actions.canModifyShares"
                size="sm"
                :disabled="!selectedData.length"
                variant="outline-primary"
                @click="modifyShares()"
                class="ml-2"
              >
                <fa-icon size="sm" :icon="['fa', 'percent']" />
                {{ $t("modify_shares") | capitalize }}
              </b-button>
              <b-button
                v-if="permissions.actions.canMergeRecording"
                size="sm"
                :disabled="selectedData.length < 2"
                variant="outline-primary"
                @click="mergeRecordings()"
                class="ml-2"
              >
                <fa-icon size="sm" :icon="['fa', 'blender']" />
                {{ $t("merge") | capitalize }}
              </b-button>
            </span>
            <span v-if="permissions.actions.canExportRepertoire">
              <b-button size="sm" variant="outline-primary" @click="exportRepertoire" class="ml-2">
                <fa-icon size="sm" :icon="['fa', 'cloud-upload-alt']" />
                {{ $t("export_repertoire") | capitalize }}
              </b-button>
            </span>
          </template>
          <!-- ResoundID header -->
          <template slot="h__fixationYear">
            <span :title="$t('year_of_fixation') | capitalize">YOF</span>
          </template>

          <!-- ResoundID row -->
          <template v-slot:resoundId="{ row }">
            <fa-icon v-if="row.reconciliationStatus === reconciliationStatuses.PENDING" :icon="['fas', 'question-circle']" class="text-gray mr-1" :title="$t('reconciliation_pending') | capitalize"/>
            <database-link :type="linkTypes.RECORDING" :item-id="row.id">{{ row.resoundId }}</database-link>
          </template>

          <!-- Album Names -->
          <template v-slot:albumNames="{ row }">
            <NamesListModal class-names="table-meta mb-2" :values="row.albumNames || []" />
          </template>
          <!-- Composers -->
          <template v-slot:composers="{ row }">
            <span :title="row.composers ? row.composers.join(', ') : ''">{{ row.composers ? row.composers.join(", ") : '' }}</span>
          </template>
          <!-- First Maker Name -->
          <template v-slot:firstMakers="{ row }">
            <span :title="row.firstMakerNames ? row.firstMakerNames : '' | startcase">
              {{ row.firstMakerNames && row.firstMakerNames.join(", ") | startcase }}
            </span>
          </template>
          <!-- Duration -->
          <template v-slot:duration="{ row }">
            <span>
              {{ row.duration ? $options.filters.toDuration(row.duration) : "" }}
            </span>
          </template>
          <!-- Featured Performers -->
          <template v-slot:hasPerformers="{ row }">
            <div v-if="row.hasPerformers && hasPerformers" :class="{'pt-2': row.featuredLineupComplete, 'icon-wrapper': true}">
              <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'microphone-alt']" />
              <b-badge v-if="row.featuredLineupComplete" variant="success" class="ml-2 text-gray lock">
                <fa-icon class="icon" size="sm" :icon="['fas', 'lock']" />
              </b-badge>
            </div>
          </template>
          <!-- Actions Count -->
          <template v-slot:actionsCount="{ row }">
            <div v-if="row.actionsCount" class="icon-wrapper pt-2">
              <fa-icon
                :title="String(row.actionsCount)"
                class="icon text-gray"
                size="sm"
                :icon="['fas', 'hand-paper']"
              />
              <b-badge class="ml-2 text-white" pill>
                {{ row.actionsCount }}
              </b-badge>
            </div>
          </template>
        </t-table>
      </template>
    </list-layout>
  </div>
</template>

<script>
import {
  UserActionTypeCodes,
  filterTypes,
  getRecordingActionTypeDisplay,
  linkTypes,
  openYears,
  reconciliationStatuses,
  userActionStatuses,
} from "@/constants"
import { capitalize, get, range, startCase, upperCase } from "lodash"
import { cartMixin, listRouteMixin, unclaimedMixin } from "@/utils/mixins"
import { getPerPageItemDefaults, userPreferenceNames } from "@/constants"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink"
import FilterBuilder from "@/components/FilterBuilder"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import OwnershipCreateForm from "./RecordingDetail/Owners/OwnershipCreateForm.vue"
import Pagination from "@/components/Pagination"
import RecordingsExportForm from "./RecordingsExportForm"
import RecordingsLinkForm from "./RecordingsLinkForm"

const userActionTypesValues = Object.values(UserActionTypeCodes)
export default {
  name: "RecordingsList",
  mixins: [cartMixin, listRouteMixin, unclaimedMixin],
  components: {
    DatabaseLink,
    RecordingsExportForm,
    NamesListModal,
    RecordingsLinkForm,
    OwnershipCreateForm,
    Pagination,
    FilterBuilder,
  },
  watch: {
    selectedData (newVal) {
      this.recomputeAllSelected(this.$refs.recordings, newVal)
    },
    "filters.proprietaryId.value": function (newVal) {
      if (newVal === null) {
        this.filters.proprietaryIdMemberCollective.value = null
      }
    }
  },
  methods: {
    ...mapActions("consts", ["getTariffs"]),
    ...mapMutations("mergers", ["resetState"]),
    ...mapMutations("alert", ["success", "error"]),
    onSubmitEditShares (data, validator) {
      this.$api.repertoire
        .recordingCarveOutModifyShares(data)
        .then(() => {
          this.success(capitalize(this.$t("shares_modified")))
          this.$bvModal.hide("shares-modify-modal")
          this.refresh()
        })
        .catch(error => {
          const { data, status } = error.response
          if (status === 400) {
            this.error(capitalize(this.$t("form_contains_errors")))
            validator.setErrors(data)
          }
        })
    },
    onSubmitInvalidate (data, validator) {
      this.$api.repertoire
        .recordingCarveOutInvalidateShares(data)
        .then(() => {
          this.success(capitalize(this.$t("shares_invalidated")))
          this.$bvModal.hide("shares-invalidate-modal")
          this.refresh()
        })
        .catch(error => {
          const { data, status } = error.response
          if (status === 400) {
            this.error(capitalize(this.$t("form_contains_errors")))
            validator.setErrors(data)
          }
        })
    },
    refresh () {
      this.selectedData = []
      this.$refs.recordings.getData()
    },
    selectedRowData (row) {
      return {
        id: row.id,
        resoundId: row.resoundId,
        isrc: row.isrc,
        title: row.title,
        artist: row.artist,
        type: row.type,
        albums: row.albumNames,
      }
    },
    onLinked () {
      this.selectedData = []
      this.recordingsInOtherMergeRequests = []
      this.getData()
    },
    getFiltersWithOptions () {
      this.filters.rightsholderType.options = this.rightsholderOptions
      this.filters.country.options = this.countryOptions
      this.filters.tariff.options = this.tariffOptions
      this.filters.genreType.options = this.genreTypeOptions
      this.filters.proprietaryIdMemberCollective.options = this.memberCollectives.map(x => ({ text: x.name, value: x.uuid }))
      return this.filters
    },
    onFilter (name, value) {
      if (name === "rightsHolderUuids") {
        if (value) {
          this.changeFilter(name, value.id)
          this.changeFilter("rightsholder_rs_id", value.resoundId)
        } else {
          this.changeFilter(name, null)
          this.changeFilter("rightsholder_rs_id", null)
        }
        if (!this.causeFiltersPending) {
          this.filtersPending = false
          this.causeFiltersPending = true
        }
      } else if (name === "fixationYear") {
        if (value) {
          this.filters.fixationYearFrom.value = value[0]
          this.filters.fixationYearTo.value = value[1]
        } else {
          this.filters.fixationYearFrom.value = null
          this.filters.fixationYearTo.value = null
        }
      } else if (name === "releaseYear") {
        if (value) {
          this.filters.releaseYearFrom.value = value[0]
          this.filters.releaseYearTo.value = value[1]
        } else {
          this.filters.releaseYearFrom.value = null
          this.filters.releaseYearTo.value = null
        }
      } else if (name === "duration") {
        if (value) {
          this.filters.durationFrom.value = this.toSeconds(value[0])
          this.filters.durationTo.value = this.toSeconds(value[1])
        } else {
          this.filters.durationFrom.value = null
          this.filters.durationTo.value = null
        }
      } else if (name === "effectivePlaycount") {
        this.filters.effectivePlaycount.value = value
      } else if (name === "weighted_playcount") {
        this.filters.weightedPlaycount.value = value
      } else if (name === "royaltiesPaid") {
        this.filters.royaltiesPaid.value = value
      } else if (name === "estimatedTrackValue") {
        this.filters.estimatedTrackValue.value = value
      }
    },
    toSeconds (timeStr) {
      if (timeStr !== null) {
        const [hours, minutes, seconds] = timeStr.split(":").map(Number)
        return hours * 3600 + minutes * 60 + seconds
      } else {
        return null
      }
    },
    toTimeFormat (seconds) {
      if (seconds) {
        return new Date(seconds * 1000).toISOString().slice(11, 19)
      } else {
        return "00:00:00"
      }
    },
    exportRepertoire () {
      this.$bvModal.show("repertoire-export-modal")
    },
    async mergeRecordings () {
      let result = await this.$api.repertoire.checkMerge({
        recordings: this.selectedData.map(r => r.id)
      })
      this.canMergeDirectly = result.data.canMergeDirectly
      this.hasSameTypes = result.data.hasSameTypes
      this.recordingsInOtherMergeRequests = result.data.recordingsInOtherMergeRequests
      this.$bvModal.show("recordings-link-modal")
    },
    invalidateShares () {
      this.$bvModal.show("shares-invalidate-modal")
    },
    modifyShares () {
      this.$bvModal.show("shares-modify-modal")
    },
    getData () {
      this.$refs.recordings.getData()
    },
  },
  data () {
    const currentYear = new Date().getFullYear()
    return {
      causeFiltersPending: true,
      linkTypes,
      reconciliationStatuses,
      loading: false,
      page: 1,
      viewingCart: false,
      limit: Number(getPerPageItemDefaults(this.$route)),
      pageNumber: 1,
      userActionTypesValues: userActionTypesValues,
      userPreferenceNames,
      selectedData: [],
      canMergeDirectly: false,
      hasSameTypes: false,
      recordingsInOtherMergeRequests: [],
      count: 0,
      columns: [
        "resoundId",
        "title",
        "artist",
        "isrc",
        "fixationYear",
        "type",
        "albumNames",
        "composers",
        "firstMakers",
        "duration",
        "releaseYear",
        "genreType",
        "playcount",
        "weightedPlaycount",
        "royaltiesPaid",
        "estimatedTrackValue",
        "hasPerformers",
        "actionsCount",
      ],
      filters: {
        rightsHolderUuids: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          label: capitalize(this.$t("rightsholder")),
          placeholder: capitalize(this.$t("search_rightsholder")),
          excludeName: "id",
          valueField: "resoundId",
          textField: "name",
          searchName: "search",
          listEndpoint: this.$api.rightsholders.rightsholdersList,
          noWatch: true,
        },
        type: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT,
          label: capitalize(this.$t("type")),
          options: [
            {
              value: "SR",
              text: capitalize(this.$t("sound_recording"))
            },
            {
              value: "MV",
              text: capitalize(this.$t("music_video"))
            }
          ],
          emptyValue: capitalize(this.$t("all"))
        },
        fuzzySearch: {
          value: null,
          defaultValue: null,
          type: filterTypes.TEXT,
          label: capitalize(this.$t("search")),
        },
        actionTypes: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT,
          label: capitalize(this.$t("user_actions")),
          options: [{
            text: capitalize(this.$t("any")),
            value: userActionTypesValues,
          },
          ].concat(
            userActionTypesValues.map(code => ({
              text: capitalize(this.$t(getRecordingActionTypeDisplay(code))),
              value: code,
            }))
          ),
          emptyValue: "---"
        },
        tariff: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("tariff")),
          options: [],
          emptyValue: capitalize(this.$t("all")),
          type: filterTypes.SELECT,
        },
        year: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("usage_year")),
          options: range(currentYear, currentYear - openYears, -1).map(y => {
            return {
              text: y,
              value: y,
            }
          }),
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all"))
        },
        country: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("country")),
          options: [],
          type: filterTypes.SELECT,
          emptyValue: capitalize(this.$t("all")),
          hidden: true,
        },
        rightsholderType: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("unclaimed_for")),
          emtpyValue: capitalize(this.$t("no_filter")),
          type: filterTypes.SELECT,
          options: [],
          children: ["tariff", "year", "country"],
        },
        proprietaryId: {
          value: null,
          defaultValue: null,
          label: this.$t("proprietary_id"),
          type: filterTypes.CUSTOM,
        },
        proprietaryIdMemberCollective: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("member_collective")),
          type: filterTypes.SELECT,
          options: [],
          hidden: true,
          addHiddenFilter: true,
        },
        releaseNames: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("album_names")),
          type: filterTypes.TEXT,
        },
        fixationYear: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("year_of_fixation")),
          type: filterTypes.YEAR_RANGE,
          noWatch: true,
        },
        fixationYearFrom: {
          value: null,
          defaultValue: null,
          hidden: true
        },
        fixationYearTo: {
          value: null,
          defaultValue: null,
          hidden: true
        },
        effectivePlaycount: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("playcount")),
          type: filterTypes.NUMBER_RANGE_NO_SLIDER,
          singleBound: true,
        },
        weightedPlaycount: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("weighted_playcount")),
          type: filterTypes.NUMBER_RANGE_NO_SLIDER,
          singleBound: true,
        },
        royaltiesPaid: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("royalties_paid")),
          type: filterTypes.NUMBER_RANGE_NO_SLIDER,
          singleBound: true,
        },
        estimatedTrackValue: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("estimated_track_value")),
          type: filterTypes.NUMBER_RANGE_NO_SLIDER,
          singleBound: true,
        },
        genreType: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("genre")),
          options: [],
          emptyValue: capitalize(this.$t("all")),
          type: filterTypes.SELECT,
        },
        releaseYear: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("year_of_release")),
          type: filterTypes.YEAR_RANGE,
          noWatch: true,
        },
        releaseYearFrom: {
          value: null,
          defaultValue: null,
          hidden: true
        },
        releaseYearTo: {
          value: null,
          defaultValue: null,
          hidden: true
        },
        duration: {
          value: null,
          defaultValue: null,
          label: capitalize(this.$t("duration")),
          type: filterTypes.TIME_RANGE,
          noWatch: true,
        },
        durationFrom: {
          value: null,
          defaultValue: null,
          hidden: true
        },
        durationTo: {
          value: null,
          defaultValue: null,
          hidden: true
        },
        firstMakers: { value: null, defaultValue: null, label: capitalize(this.$t("first_makers")), type: filterTypes.TEXT },
        composers: { value: null, defaultValue: null, label: capitalize(this.$t("composers")), type: filterTypes.TEXT },
        artist: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("artist")) },
        title: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("title")) },
        isrc: { value: null, defaultValue: null, type: filterTypes.TEXT, label: this.$t("isrc") },
        represented: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT,
          label: capitalize(this.$t("with_financial_interest")),
          emptyValue: capitalize(this.$t("any")),
          options: [
            {
              value: "true",
              text: capitalize(this.$t("yes"))
            },
            {
              value: "false",
              text: capitalize(this.$t("no"))
            },
          ]
        },
        uploadedBySelf: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT,
          label: capitalize(this.$t("uploaded_by")),
          emptyValue: capitalize(this.$t("anyone")),
          options: [
            {
              value: "true",
              text: this.$t("my_mc")
            },
            {
              value: "false",
              text: this.$t("anyone_but_my_mc")
            },
          ]
        }
      },
      options: {
        allSelected: false,
        sortable: [
          "title",
          "artist",
          "isrc",
          "type",
          "fixationYear",
          "resoundId",
          "playcount",
          "weightedPlaycount",
          "royaltiesPaid",
          "estimatedTrackValue",
          "duration",
          "releaseYear",
          "genreType",
        ],
        headings: {
          resoundId: this.$t("resound_id"),
          artist: capitalize(this.$t("artist")),
          title: capitalize(this.$t("title")),
          isrc: upperCase(this.$t("isrc")),
          fixationYear: "YOF",
          type: capitalize(this.$t("type")),
          albumNames: capitalize(this.$t("album_names")),
          playcount: capitalize(this.$t("playcount")),
          weightedPlaycount: capitalize(this.$t("weighted_playcount")),
          royaltiesPaid: capitalize(this.$t("royalties_paid")),
          estimatedTrackValue: capitalize(this.$t("estimated_track_value")),
          firstMakers: startCase(this.$t("first_makers")),
          duration: capitalize(this.$t("dur")),
          genreType: startCase(this.$t("genre")),
          releaseYear: upperCase(this.$t("yor")),
          composers: capitalize(this.$t("composer")),
          hasPerformers: "",
          actionsCount: "",
        },
        width: {
          hasPerformers: 2,
          actionsCount: 2,
          fixationYear: 90,
          type: 90,
          resoundId: 140,
        },
        columnsClasses: {
          resoundId: "width-small",
          artist: "width-medium",
          title: "width-large",
          isrc: "width-small",
          type: "width-micro",
          albumNames: "width-medium",
          playcount: "width-micro",
          weightedPlaycount: "width-micro",
          royaltiesPaid: "width-small",
          estimatedTrackValue: "width-small",
          hasPerformers: "width-small text-right",
          actionsCount: "width-small",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction () {
          let componentData = this.$parent.$parent
          let queryParams = {
            ...this.$route.query,
          }
          if (queryParams.action_types) {
            queryParams.action_statuses = [userActionStatuses.PENDING]
          }
          if (componentData.viewingCart) {
            return componentData.filterCartItems("recording")
          }
          return this.$api.repertoire.recordingsList(queryParams)
        },
      },
    }
  },
  computed: {
    ...mapGetters("user", ["hasPerformers"]),
    ...mapState("consts", ["tariffs", "memberCollectives"]),
    ...mapState("user", ["permissions"]),
    tariffOptions () {
      return this.tariffs.map(c => {
        return {
          text: c.text,
          value: c.code,
        }
      })
    },
    genreTypeOptions () {
      return [
        { text: "Classical", value: "C" },
        { text: "Pop", value: "P" },
        { text: "Jazz", value: "J" }
      ]
    },
    exportBaseTitle () {
      return capitalize(this.$t("export_repertoire"))
    },
  },
  mounted () {
    if (!this.tariffs.length) {
      this.getTariffs()
    }
    this.updateTableSortIcons(this.$refs.recordings.$children[0], true)
    let value = get(this.$route.query, "rightsholder_rs_id")
    if (value) {
      this.causeFiltersPending = false
      this.filters.rightsHolderUuids.searchValue = value
    }
    if (this.filters.fixationYearFrom.value && this.filters.fixationYearTo.value) {
      this.filters.fixationYear.value = [
        this.filters.fixationYearFrom.value.toString(),
        this.filters.fixationYearTo.value.toString()
      ]
    }
    if (this.filters.releaseYearFrom.value && this.filters.releaseYearTo.value) {
      this.filters.releaseYear.value = [
        this.filters.releaseYearFrom.value.toString(),
        this.filters.releaseYearTo.value.toString()
      ]
    }
    if (this.filters.durationFrom.value || this.filters.durationTo.value) {
      this.filters.duration.value = [
        this.toTimeFormat(this.filters.durationFrom.value),
        this.toTimeFormat(this.filters.durationTo.value),
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
.flex-fixed {
  flex-shrink: 0;
  flex-grow: 0;
}

::v-deep .nav-link {
  padding: 0;
}
</style>
