import * as Sentry from "@sentry/vue"
import { userPreferenceNames, userTypes } from "@/constants"
import { defaultTo } from "lodash"
import { mapPermissions } from "@/utils/permissions"

const localStorageKeys = {
  [userPreferenceNames.MATCHING_ACTIONS_SHOW_LANDING_DATA]: "matchingUserActions.showLandingData",
  [userPreferenceNames.MATCHING_ACTIONS_HIGHLIGHT_DIFFERENCES]: "matchingUserActions.highlightDifferences",
  [userPreferenceNames.MERGE_REQUESTS_HIGHLIGHT_DIFFERENCES]: "mergeRequests.highlightDifferences",
  [userPreferenceNames.MERGE_REQUESTS_SHOW_PARENT_DATA]: "mergeRequests.showParentData",
  [userPreferenceNames.PAYMENTS_HISTORY_FILTER]: "filters-payment-history-state",
  [userPreferenceNames.LANDING_RECORDINGS_LIST_FILTER]: "filters-landing-recordings-state",
  [userPreferenceNames.RECORDINGS_LIST_FILTER]: "filters-recordings-state",
  [userPreferenceNames.USAGES_FILTER]: "filters-recording-usages-state",
  [userPreferenceNames.REPERTOIRE_FILES_LIST_FILTER]: "filters-repertoire-state",
  [userPreferenceNames.CONSOLIDATED_USAGES_FILTER]: "filters-consolidated-usages-state",
  [userPreferenceNames.USAGE_MATCHING_FILTER]: "filters-usage-matching-state",
  [userPreferenceNames.USAGE_FILE_LIST_FILTER]: "filters-usagesfiles-state",
  [userPreferenceNames.USAGE_LIST_FILTER]: "filters-usages-state",
  [userPreferenceNames.RECORDING_MERGE_REQUESTS_FILTER]: "filters-recording-merge-requests-state",
  [userPreferenceNames.REPERTOIRE_RECORDING_FILTER]: "filters-repertoireRecording-state",
  [userPreferenceNames.REPERTOIRE_PERFORMER_FILTER]: "filters-repertoirePerformer-state",
  [userPreferenceNames.REPERTOIRE_MAKER_FILTER]: "filters-repertoireMaker-state",
  [userPreferenceNames.RIGHTSHOLDER_PERFORMER_FILTER]: "filters-rightsholderPerformer-state",
  [userPreferenceNames.RIGHTSHOLDER_MAKER_FILTER]: "filters-rightsholderMaker-state",
  [userPreferenceNames.PAYMENT_RECORDING_FILTER]: "filters-paymentRecording-state",
  [userPreferenceNames.PAYMENT_PERFORMER_FILTER]: "filters-paymentPerformer-state",
  [userPreferenceNames.PAYMENT_MAKER_FILTER]: "filters-paymentMaker-state",
}

const state = {
  waiting: false,
  user: {
    id: null,
    email: null,
    role: null,
    memberCollective: {},
    selectedMember: {},
  },
  userFetched: false,
  pageNotFound: false,
  previousPage: null,
  preferences: Object.fromEntries(Object.values(userPreferenceNames).map(preference => [preference, JSON.parse(localStorage.getItem(localStorageKeys[preference]))])),
}

const mutations = {
  waiting (state, waiting) {
    state.waiting = waiting
  },
  setUser (state, user) {
    state.user = user
    state.permissions = mapPermissions(user.userPermissions)
    state.userFetched = true
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.uuid,
      })
    })
  },
  setPageNotFound (state, pageNotFound) {
    state.pageNotFound = pageNotFound
  },
  setPreviousPage (state, data) {
    state.previousPage = data
  },
  setPreference (state, payload) {
    state.preferences[payload.preference] = payload.value
    localStorage.setItem(localStorageKeys[payload.preference], JSON.stringify(payload.value))
  },
  clearPreferences (state) {
    Object.keys(state.preferences).forEach(preference => {
      state.preferences[preference] = null
      localStorage.removeItem(localStorageKeys[preference])
    })
  }
}

const actions = {
  getUser ({ commit }) {
    return this._vm.$api.auth.getUser()
      .then(({ data }) => {
        commit("setUser", data)
        return data
      })
  },
  logout ({ commit }) {
    this._vm.$auth.logout()
    Sentry.configureScope((scope) => {
      scope.setUser(null)
    })
    commit("clearPreferences")
  },
}

const getters = {
  userFetched: state => {
    return state.userFetched
  },
  isCurrentUser: (state) => (id) => {
    return state.user.uuid ===  id
  },
  isMC: state => {
    return state.user.role === "MC"
  },
  canOverrideUserRole: state => {
    return state.user.canOverrideUserRole
  },
  previousPage: state => {
    return state.previousPage
  },
  isUsageMatcher: state => {
    return state.user.role === "RU"
  },
  isCanViewAllProprietaryIds: state => {
    return ["AD", "DM", "DS"].includes(state.user.role)
  },
  isInternal: state => {
    return state.user.type === userTypes.INTERNAL
  },
  hasMakers: state => {
    return (state.user.selectedMember || state.user.memberCollective).isMakers
  },
  hasPerformers: state => {
    return (state.user.selectedMember || state.user.memberCollective).isPerformers
  },
  memberCollectiveUuid: state => {
    if (state.user.selectedMember.uuid) {
      return state.user.selectedMember.uuid
    }
    return state.user.memberCollective.uuid
  },
  getPreference: (state) => (preference, defaultValue = null) => {
    return defaultTo(state.preferences[preference], defaultValue)
  }
}
export const user = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
