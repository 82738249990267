<template>
  <div class="d-flex flex-column h-100">
    <div ref="topInfo">
      <b-row :class="title ?'heading-container':'no-title'">
        <b-col cols="7">
          <div class="heading">
            <!-- Heading -->
            <slot name="title">
              <h4 class="title">
                {{ title }}
                <span class="title-count" v-if="count">({{ count.toLocaleString() }})</span>
              </h4>
            </slot>


            <!-- Sub-Title -->
            <slot name="subtitle"></slot>
          </div>
        </b-col>
        <b-col cols="5" class="filter-buttons d-flex justify-content-end">
          <slot v-if="searchFilter" name="search-filter">
            <div class="search-filter d-flex">
              <b-form-group label-cols="auto" class="w-95">
                <form-field 
                  v-model="searchFilter.value"
                  :placeholder="searchFilter.label"
                  :enter-action="applyFilters"
                />
              </b-form-group>
              <template>
                <b-input-group-text @click="applyFilters()">
                  <fa-icon size="lg" :icon="['fa', 'search']" />
                </b-input-group-text>
              </template>
            </div>
          </slot>
        <slot name="heading-right">
          <div v-if="!noApplyBtn">
            <b-button v-if="applyFilters && resetFilters && filtersApplied" variant="outline-secondary" size="sm" class="ml-2" @click="resetFilters(true)">{{ $t("reset_to_default") | capitalize }}</b-button>
            <b-button v-if="applyFilters && resetFilters" :disabled="!filtersPending" variant="outline-secondary" size="sm" class="ml-2" @click="resetFilters()">{{ $t("reset") | capitalize }}</b-button>
            <b-button v-if="applyFilters" :disabled="!filtersPending" class="ml-2" size="sm" @click="applyFilters()">{{ $t("apply") | capitalize }}</b-button>
          </div>
        </slot>
        </b-col>
      </b-row>

      <b-row align-v="start" v-if="!noSearch">
        <b-col cols="4">
          <!-- Search -->
          <div :class="hasLabelPad()">
            <slot name="search"></slot>
          </div>
        </b-col>
        <b-col cols="7" offset="1" class="pr-0">
          <!-- Filters -->
          <slot name="filters"></slot>
        </b-col>
        <!-- Other controls -->
        <slot name="controls"></slot>
      </b-row>

      <slot name="beforeFilters"/>

      <slot name="tabFilters" />

      <!-- Merges and Counts -->
      <b-row align-v="center" class="mb-2 mt-2" v-if="!noCounts">
        <b-col class="counts" cols="4">
          <slot name="counts"></slot>
        </b-col>
        <b-col>
          <slot name="merge"></slot>
        </b-col>
      </b-row>
    </div>

    <!-- Table -->
    <slot name="table"></slot>

    <!-- Default -->
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.heading {
  .badge {
    height: 50%;
    font-size: 50%;
    vertical-align: super;
  }
}
.no-title {
  align-items: center;
}
.title-count {
  font-size: 1.3rem;
}
.title {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.counts {
  color: var(--gray) !important;
  font-size: 14px;
}

::v-deep .filter-buttons button {
  font-size: 14px;
}

::v-deep .filter-buttons a.btn {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.filter-buttons {
  height: 36px;
}

.search-filter{
  color: $primary !important;
  width: 324px;
  height: 100%;
  ::v-deep .form-row legend {
    display: none;
  }
  ::v-deep .form-control {
    border: 1px solid $gray-light;
    border-radius: 4px 0 0 4px;
    height: 36px;
  }
  .input-group-text {
    border: 1px solid $gray-light;
    border-left: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }
}
</style>

<script>
export default {
  name: "ListLayout",
  props: {
    title: String,
    count: [String, Number],
    isChild: Boolean,
    applyFilters: Function,
    resetFilters: Function,
    filtersApplied: Boolean,
    filtersPending: Boolean,
    noCounts: Boolean,
    noSearch: Boolean,
    searchFilter: Object,
    noApplyBtn: Boolean,
  },
  methods: {
    hasLabelPad () {
      return !this.isChild ? "pt-4" : null
    },
  },
}
</script>
