<template>
  <list-layout
      :title="$t('uploads') | capitalize"
      :filters-pending="filtersPending"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-applied="filtersApplied"
      :no-counts="true"
      :no-search="true"
      :search-filter="filters.fuzzySearch"
  >
    <!-- Filters -->
    <template v-slot:beforeFilters>
      <b-row>
        <b-col>
          <filter-builder
            :id="userPreferenceNames.REPERTOIRE_FILES_LIST_FILTER"
            :filters="getFiltersWithOptions()"
            :filters-pending="filtersPending"
            :apply-filters="applyFilters"
            :reset-filters="resetFilters"
            :filters-applied="filtersApplied"
            @filter="onFilter"
          />
        </b-col>
      </b-row>
    </template>

    <!-- Search -->
    <template v-slot:heading-right>
      <b-button class="ml-4 d-flex align-items-center" variant="secondary" :href="resoundTemplateUrl">
          <fa-icon class="mr-2" size="lg" :icon="['fa', 'file-download']" />
          {{ $t("download_template") | startcase }}
      </b-button>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <t-table
        ref="repertoire"
        id="repertoire"
        :columns="columns"
        :options="options"
        :limit="limit"
        @loaded="onLoaded"
        @loading="onLoading"
        @pagination="onPagination"
        @sorted="onSorted"
      >
        <template v-slot:counts>
          <pagination
            class="smaller p-0"
            :page="page"
            :count="count"
            :loading="loading"
            v-model="limit"
            @limit="onLimit"
          />
        </template>

        <!-- File Name -->
        <template v-slot:file_name="{ row }">
          <router-link
            class="text-info"
            :to="{
              name: 'landing-recordings',
              params: { repFileId: row.id },
              query: 'rightsholder_rs_id' in $route.query && filters.rightsHolderUuid.value ? {
                rightsholder_rs_id: filters.rightsHolderUuid.value.resoundId,
                rights_holder_uuid: filters.rightsHolderUuid.value.id,
              } : {}
            }"
          >
            {{ row.fileName }}
          </router-link>
        </template>

        <!-- Name -->
        <template v-slot:uploader="{ row }">
          <router-link
            class="text-info"
            :to="{ name: 'users-update', params: { uuid: row.uploader.uuid } }"
          >
            {{ row.uploader.firstName + " " + row.uploader.lastName }}
          </router-link>
        </template>

        <!-- Upload date -->
        <template v-slot:upload_date="{ row }">
          <date-time-cell :dateTime="row.uploadDate" />
        </template>

        <!-- Status -->
        <template v-slot:status="{ row }">
          <status
            v-if="row.status === fileStatuses.PENDING"
            :text="$t('pending')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.PROCESSING"
            :text="$t('processing')"
            :icon="['fas', 'question-circle']"
            color="orange"
          />
          <status
            v-if="row.status === fileStatuses.LANDED"
            :text="$t('landed')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.INGESTED"
            :text="$t('ingested')"
            :icon="['fas', 'check-circle']"
            color="green"
          />
          <status
            v-if="row.status === fileStatuses.ERROR"
            :text="$t('error')"
            :icon="['fas', 'exclamation-circle']"
            color="red"
            :active="true"
            @click="showErrors(row, true)"
            clickable
          />
        </template>

        <!-- LANDING DB STATUS -->
        <template v-slot:ingestionStats="{ row }">
          <!-- Row errors -->
          <div v-if="row.stats && row.stats.landingDbIngestion">
            <div
              v-for="item in ['totalCount', 'ingestedCount', 'errorCount']"
              :key="item"
              class="table-sub-class text-sm inline"
            >
              <div class="mb-1">
                {{ getStatsDisplay(item) | capitalize }}:
                {{ row.stats.landingDbIngestion | get([item], "") }}
                <fa-icon
                  @click="showErrors(row)"
                  v-if="
                    item === 'errorCount' &&
                      row.stats.landingDbIngestion.errorCount > 0
                  "
                  class="blue clickable ml-2"
                  size="sm"
                  :icon="['fas', 'plus-circle']"
                />
                <fa-icon
                  @click="downloadErrorFile(row)"
                  v-if="row.errorFileName && item == 'errorCount'"
                  class="blue clickable ml-2"
                  size="sm"
                  :icon="['fas', 'file-download']"
                />
              </div>
            </div>
          </div>
        </template>

        <!-- SINGLE VIEW STATUS -->
        <template v-slot:matchingStats="{ row }">
          <div v-if="row.stats && row.stats.singleViewIngestion">
            <div
              v-for="k in [
                'totalUniqueCount',
                'linkedCount',
                'createdCount',
                'manuallyLinkedCount',
                'manuallyCreatedCount',
                'pendingForValidationCount'
              ]"
              :key="k"
              class="table-sub-class text-sm inline"
            >
              <div v-if="k === 'pendingForValidationCount' && row.stats.singleViewIngestion[k] > 0">
                <router-link class="text-info" :to="{
                  name: 'user-actions',
                  params: {
                    type: 'matching-conflicts'
                  },
                  query: {
                    'repertoire_file': row.id
                  }
                }">
                  {{ getStatsDisplay(k) | capitalize }}:
                  {{ row.stats.singleViewIngestion[k] }}
                </router-link>
              </div>
              <div v-else>
                {{ getStatsDisplay(k) | capitalize }}:
                {{ row.stats.singleViewIngestion[k] }}
              </div>
            </div>
          </div>
        </template>

        <!-- Invalidate -->
        <template v-slot:invalidated="{ row }">
          <span v-show="row.invalidated != invalidatedStatus.INVALIDATED">
            <fa-icon
                v-if="permissions.actions.canDeleteRepertoireFile"
                role="button"
                size="1x"
                :title="$t('delete') | lowercase"
                :icon="['fa', 'trash']"
                class="cursor-pointer"
                color="#c23d5b"
                @click="onDelete(row.id)"
            />
            <!-- Deletion Confirmation Modal -->
            <modal
              :id="'confirmation-modal-' + row.id"
              :customTitle="$t('invalidate_file') | capitalize"
              modal-class="custom-modal edit"
              modalType="edit"
              size="md"
              centered 
              @ok="onConfirmDelete(row)"
              okTitle="Confirm"
            >
              <div>
                {{ $t("do_you_really_want_to_invalidate_file") | capitalize }}?
              </div>
            </modal>
          </span>
        </template>
      </t-table>
      <modal
        id="errorsModal"
        ref="errorsModal"
        :customTitle="$t('errors') | capitalize"
        modal-class="custom-modal error"
        modalType="error"
        size="xl"
        centered 
        hide-footer >
        <FilesErrorsList
          :key="currentFile | get('id')"
          :item="currentFile"
          module="repertoirefiles"
          :onlyShowFileLevelErrors="onlyShowFileLevelErrors"
          :file-errors-endpoint="$api.repertoire.repertoireFileErrorsList"
          :file-errors-download-endpoint="$api.repertoire.repertoireFileErrorsListFile"
        />
      </modal>
    </template>
  </list-layout>
</template>

<style lang="scss">
.bottom-filters {
  .col-4 {
    .form-group {
      .form-row {
        align-items: center;

        .mx-input {
          padding: 0.375rem 1.75rem 0.375rem 0.75rem;
          height: calc(1.7em + 0.75rem + 2px);
        }
      }
    }
  }
}
</style>

<script>
import { capitalize, get } from "lodash"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import { fileStatuses, filterTypes, invalidatedStatus, perPageItemsSm, userPreferenceNames } from "@/constants"
import { mapGetters, mapMutations, mapState } from "vuex"
import FilesErrorsList from "@/components/FilesErrorsList"
import FilterBuilder from "@/components/FilterBuilder"
import Pagination from "@/components/Pagination"

export default {
  name: "RepertoireFilesList",
  mixins: [datePickerMixin, listRouteMixin],
  components: {
    Pagination,
    FilesErrorsList,
    FilterBuilder
  },
  watch: {
    dateRange (newVal) {
      this.filters.dateRange.value = newVal
    }
  },
  data () {
    return {
      causeFiltersPending: true,
      count: 0,
      page: 1,
      loading: false,
      summary: { all: 0, pn: 0, pr: 0, ln: 0, in: 0, er: 0, rm: 0 },
      fileStatuses,
      invalidatedStatus,
      userPreferenceNames,
      currentFile: null,
      onlyShowFileLevelErrors: false,
      limit: perPageItemsSm,
      filters: {
        rightsHolderUuid: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          label: capitalize(this.$t("rightsholder")),
          placeholder: capitalize(this.$t("search_rightsholder")),
          excludeName: "id",
          valueField: "resoundId",
          textField: "name",
          searchName: "search",
          listEndpoint: this.$api.rightsholders.rightsholdersList,
          noWatch: true,
        },
        fuzzySearch: { value: "", defaultValue: "", type: filterTypes.TEXT, label: capitalize(this.$t("search")) },
        status: { value: "", defaultValue: "", label: capitalize(this.$t("status")), type: filterTypes.SELECT, options: [] },
        member: { value: "", defaultValue: null, label: capitalize(this.$t("member_collective")), type: filterTypes.SELECT, options: [] },
        dateRange: { value: null, defaultValue: null, type: filterTypes.DATE_RANGE, format: "YYYY-MM-DD", label: capitalize(this.$t("upload_date")), noWatch: true },
        filename: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("filename")) },
        uploader: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          listEndpoint: this.$api.users.usersSearch,
          excludeName: "id",
          textField: "name",
          valueField: "email",
          label: capitalize(this.$t("uploaded_by")),
          placeholder: capitalize(this.$t("search_user")),
          searchName: "search",
          noWatch: true,
        },
        agent: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          listEndpoint: this.$api.agents.agentsList,
          excludeName: "",
          textField: "name",
          valueField: "id",
          label: capitalize(this.$t("agent")),
          placeholder: capitalize(this.$t("search_agent")),
          searchName: "search",
          noWatch: true,
        },
      },
      options: {
        sortable: ["file_name", "upload_date", "uploader", "memberCollective", "daName", "uploader.daAgent", "daType", "status"],
        initialSort: [
          {
            column: "upload_date",
            dir: "desc",
          }
        ],
        headings: {
          file_name: capitalize(this.$t("filename")),
          memberCollective: capitalize(this.$t("mc")),
          "daName": this.$t("da_name"),
          "daType": this.$t("da_type"),
          "uploader.daAgent": this.$t("da_agent"),
          uploader: capitalize(this.$t("upload_by")),
          upload_date: capitalize(this.$t("upload_date")),
          status: capitalize(this.$t("status")),
          ingestionStats: capitalize(this.$t("ingestion_stats")),
          matchingStats: capitalize(this.$t("matching_stats")),
          "invalidated": ""
        },
        width: {
          invalidated: 10
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.repertoire.repertoireFilesList(queryParams)
        }
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isMC"]),
    ...mapState("consts", ["memberCollectives"]),
    ...mapState("user", ["permissions"]),
    columns () {
      let cols = [
        "file_name",
        "memberCollective",
        "daName",
        "daType",
        "uploader.daAgent",
        "uploader",
        "upload_date",
        "status",
        "ingestionStats",
        "matchingStats",
        "invalidated"
      ]
      if (this.isMC) {
        cols.splice(2, 1)
        cols.splice(3, 1)
      }
      return cols
    },
    memberCollectiveOptions () {
      return this.memberCollectives.map(x => ({ text: x.name, value: x.uuid }))
    },
    statusOptions () {
      return [
        {
          text: `${capitalize(this.$t("all"))} (${this.summary.all})`,
          value: ""
        },
        {
          text: `${capitalize(this.$t("pending"))} (${this.summary.pn})`,
          value: "PN"
        },
        {
          text: `${capitalize(this.$t("processing"))} (${this.summary.pr})`,
          value: "PR"
        },
        {
          text: `${capitalize(this.$t("landed"))} (${this.summary.ln})`,
          value: "LN"
        },
        {
          text: `${capitalize(this.$t("ingested"))} (${this.summary.in})`,
          value: "IN"
        },
        {
          text: `${capitalize(this.$t("error"))} (${this.summary.er})`,
          value: "ER"
        },
        {
          text: `${capitalize(this.$t("deleted"))} (${this.summary.rm})`,
          value: "RM"
        }
      ]
    },
    resoundTemplateUrl () {
      return process.env.VUE_APP_RESOUND_TEMPLATE_URL
    },
  },
  methods: {
    ...mapMutations("alert", ["error", "success"]),
    onFilter (name, value) {
      if (name === "rightsHolderUuid") {
        if (value) {
          this.changeFilter(name, value.id)
          this.changeFilter("rightsholder_rs_id", value.resoundId)
        } else {
          this.changeFilter(name, null)
          this.changeFilter("rightsholder_rs_id", null)
        }
        if (!this.causeFiltersPending) {
          this.filtersPending = false
          this.causeFiltersPending = true
        }
      } else if (name === "dateRange") {
        this.dateRange = value ? value : ["", ""]
      } else if (name === "uploader") {
        if (value === null) {
          this.changeFilter(name, value)
        }
        else {
          this.changeFilter(name, value.value)
        }
      } else if (name === "agent") {
        this.changeFilter(name, value.id)
      }
    },
    onDelete (id) {
      this.$bvModal.show(`confirmation-modal-${id}`)
    },
    onConfirmDelete (row) {
      this.$api.repertoire.repertoireFileInvalidate(row.id, {})
        .then(() => {
          this.success(this.$t("repertoire_file_invalidated"))
          this.getData()
        })
        .catch((error) => {
          if (error.response.status === 400) {
            let msg = `Error invalidate file. ${error.response.data.msg}`
            this.error(msg)
          }
        })
    },
    downloadErrorFile (row) {
      this.$api.repertoire
        .downloadErrorFile(row.id)
        .then(response => {
          const link = document.createElement("a")
          link.href = response.data.link
          link.download = row.errorFileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(err => {
          if (err.response && err.response.data) {
            this.showErrors(err.response.data)
          } else {
            this.$toasted.global.toast(err)
          }
        })
    },
    showErrors (row, onlyShowFileLevelErrors = false) {
      this.currentFile = row
      this.onlyShowFileLevelErrors = onlyShowFileLevelErrors
      this.$bvModal.show("errorsModal")
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    onPagination (page) {
      this.page = page
      this.updateRouterPagination("page", page)
    },
    getData () {
      this.$refs.repertoire.getData()
    },
    getStatsDisplay (key) {
      const mapToDisplay = {
        createdCount: this.$t("created"),
        error: this.$t("error"),
        errorCount: this.$t("errors"),
        ingested: this.$t("ingested"),
        ingestedCount: this.$t("ingested_count"),
        linkedCount: this.$t("linked"),
        manuallyLinkedCount: this.$t("manually_linked"),
        manuallyCreatedCount: this.$t("manually_created"),
        pendingForValidationCount: this.$t("pending_for_validation"),
        totalUniqueCount: this.$t("total_unique"),
        totalCount: this.$t("total")
      }
      return get(mapToDisplay, key, key)
    },
    getFiltersWithOptions () {
      this.filters.member.options = this.memberCollectiveOptions
      this.filters.status.options = this.statusOptions
      return this.filters
    }
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.repertoire.$children[0], true)
    let rightsHolderId = get(this.$route.query, "rightsholder_rs_id")
    if (rightsHolderId) {
      this.causeFiltersPending = false
      this.filters.rightsHolderUuid.searchValue = rightsHolderId
    }
    let agentId = get(this.$route.query, "agent")
    if (agentId) {
      this.filters.agent.searchValue = agentId
    }
  }
}

</script>
