<template>
  <div>
    <div class="row mt-3 mb-4">
      <div class="col my-auto" v-if="isMetaData">
        <h4 class="mb-0">{{ $t("source_metadata") | startcase }}</h4>
      </div>
      <div class="col-4 my-auto" v-else>
        <h4 class="mb-0">{{ $t("merged_recordings") | startcase }}</h4>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <b-form-group>
          <b-form-radio-group
              v-model="selectedSourceMetadata"
              :options="sourceMetadataOptions"
              buttons
              button-variant="outline-secondary"
              class="radio-buttons"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div class="col d-flex justify-content-end" v-if="isMetaData">
        <div v-if="hasEligibilityConflict">
          <b-button
            class="text-nowrap ml-4 btn-outline-dark"
            :to="{
              name: 'eligibility-conflicts',
              query: {
                fuzzy_search: resoundId,
                level: null
              },
            }"
            target="_blank"
          >
            {{ $t("eligibility_conflict") | capitalize }}
            <fa-icon icon="external-link" size="sm"/>
          </b-button>
        </div>
      </div>
    </div>
    <RecordingDetailSourcesList
      :recording-id="recordingUUID"
      :collapse="true"
      :isMetaData="isMetaData"
      :id="id"
      :displayData="selectedSourceMetadata"
      :data="data"
    />
  </div>
</template>

<script>
import RecordingDetailSourcesList from "./RecordingDetailSourcesList.vue"
import { capitalize } from "lodash"
import { perPageItemsSm } from "@/constants"


export default {
  name: "GeneralDetails",
  components: { RecordingDetailSourcesList },
  data () {
    return {
      perPageItemsSm,
      page: 1,
      count: 0,

      selectedSourceMetadata : "coreMetadata",
      sourceMetadataOptions: [
        {
          text: this.isMetaData ? capitalize(this.$t("core_metadata")) : capitalize(this.$t("core_data")),
          value: "coreMetadata"
        },
        {
          text: this.isMetaData ? capitalize(this.$t("eligibility_metadata")) : capitalize(this.$t("eligibility_data")),
          value: "eligibilityMetadata"
        }
      ]
    }
  },
  props: {
    recordingUUID: String,
    id: String,
    data: Array,
    isMetaData: Boolean,
    isChallengeButtonVisible: {
      type: Boolean,
      default: true,
    },
    resoundId: {
      type: String,
      required: false,
    },
    hasEligibilityConflict: {
      required: false,
      default: false,
    },
  },
}
</script>
