export class Pages {
  static ConsolidatedUsageList = "consolidated-usage-list"
  static CopyrightsAndTariffs = "copyrights"
  static Forbidden = "forbidden"
  static Login = "login"
  static MatchingStatistics = "matching-statistics"
  static PaymentFilesList = "payment-files"
  static NotFound = "not-found"
  static RepertoireFilesList = "repertoire-files-list"
  static RepertoireRecordingList = "repertoire-recordings-list"
  static RepertoireRecordingDetail = "repertoire-recording-detail"
  static RightsHoldersList = "rightsholders-list"
  static Stations = "stations"
  static UsagesFileList = "usages-file-list"
  static UsersList = "users-list"
  static UsersUpdate = "users-update"
  static UserRoles = "user-roles"
  static DistributionList = "distribution-list"
}
