<template>
  <div>
    <h5>{{ $t("please_pick_a_parent_recording") | capitalize }}</h5>
    <div class="table-wrapper">
    <b-table
      :fields="fields"
      :items="selectedRecordings"
      :tbody-tr-class="rowClass"
      striped
      fixed
    >
      <template v-slot:cell(isrc)="row">
        <div class="d-flex">
          <b-form-radio v-model="parentRecording" name="parent-recording" :value="row.item"/>
          {{ row.item.isrc }}
        </div>
      </template>
      <!-- Albums -->
      <template v-slot:cell(albums)="row">
        <NamesListModal
          v-if="row.item.albums"
          class-names="table-meta mb-2"
          :values="row.item.albums"
        />
      </template>
    </b-table>
    </div>
    <div class="mb-2 mt-2">
      <b-form-textarea v-model="comment" :placeholder="$t('add_comment') | capitalize" rows="4"/>
    </div>
    <div class="d-flex justify-content-end">
      <div class="vertical-formatting">
        <div class="d-flex mt-2" v-if="recordingsInOtherMergeRequests.length">
          <span class="text-red small">
            {{ $t("recordings_in_other_merge_requests") | capitalize }}
          </span>
        </div>

        <div class="d-flex mt-2" v-if="!hasSameTypes">
          <span class="text-red small">
            {{ $t("incompatible_types_to_merge") | capitalize }}
          </span>
        </div>
      </div>

      <div class="d-flex" v-if="forceLink">
        <span class="text-red small" v-if="canMerge">
          {{ $t("the_information_is_too_different_merge") }}
        </span>
        <span class="text-red small" v-if="!canMerge">
          {{ $t("the_information_is_too_different_merge_request") }}
        </span>
        <b-checkbox v-model="force" class="ml-2 pt-0"/>
      </div>
    </div>
    <b-row class="modal-footer mt-4">
      <b-button class="btn-outline-dark" @click="cancel">
        {{  $t("cancel") | capitalize }}
      </b-button>
      <b-button :disabled="!canSubmit()" class="btn-outline-red" @click="link">
        {{  getMergeButtonText() | capitalize }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { capitalize, startCase, upperCase } from "lodash"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import { mapMutations } from "vuex"

export default {
  name: "RecordingsLinkForm",
  components: {
    NamesListModal,
  },
  props: {
    selectedRecordings: {
      type: Array,
      required: true,
    },
    canMerge: {
      type: Boolean,
      default: false,
    },
    hasSameTypes: {
      type: Boolean,
      default: false,
    },
    recordingsInOtherMergeRequests: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    getMergeButtonText () {
      if (this.canMerge) {
        return this.$t("merge")
      } else {
        return this.$t("create_merge_request")
      }
    },
    canSubmit () {
      return (
        this.parentRecording &&
        !this.loading &&
        this.recordingsInOtherMergeRequests.length === 0 &&
        this.comment &&
        this.hasSameTypes
      )
    },
    getSuccessMessage () {
      return capitalize(this.$t(this.canMerge ? "recordings_linked" : "merge_request_created"))
    },
    getErrorMessage () {
      return this.canMerge ? "Error merging recordings." : "Error creating merge request."
    },
    link () {
      let data = {
        parent: this.parentRecording.id,
        children: this.selectedRecordings.filter(r => r.id !== this.parentRecording.id).map(r => r.id),
        force: this.force,
        comment: this.comment,
      }
      this.loading = true
      this.$api.repertoire.recordingsLink(data)
        .then(() => {
          this.loading = false
          this.success(this.getSuccessMessage())
          this.$bvModal.hide("recordings-link-modal")
          this.$emit("linked")
        })
        .catch(error => {
          this.loading = false
          if (error.response.data.nonFieldErrors && error.response.data.nonFieldErrors[0].includes("too different")) {
            this.forceLink = true
            this.error(error.response.data.nonFieldErrors[0])
          } else {
            let msg = `${this.getErrorMessage()} ${error.response.data}`
            this.error(msg)
          }
        })
    },
    cancel () {
      this.$bvModal.hide("recordings-link-modal")
    },
    rowClass (item) {
      if (this.recordingsInOtherMergeRequests && this.recordingsInOtherMergeRequests.includes(item.id)) {
        return "table-secondary"
      }
    }
  },
  data () {
    return {
      force: false,
      forceLink: false,
      parentRecording: null,
      loading: false,
      canMergeDirectly: false,
      fields: [
        { key: "isrc", label: upperCase(this.$t("isrc")) },
        { key: "title", label: startCase(this.$t("title")), },
        { key: "artist", label: startCase(this.$t("artist")) },
        { key: "type", label: startCase(this.$t("type")) },
        { key: "albums", label: startCase(this.$t("albums")) }
      ],
      comment: "",
    }
  },
}
</script>
