<template>
  <ValidationObserver ref="ownershipsCreateForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-row>
        <b-col>
          <select-search
            :list-endpoint="$api.rightsholders.rightsholdersList"
            :label="$t('maker') | capitalize"
            label-cols="2"
            :placeholder="$t('search_maker') | capitalize"
            search-name="search"
            exclude-name="id"
            :params="{ type: 'maker' }"
            text-field="name"
            value-field="resoundId"
            v-model="selectedOwner"
            rules="required"
            name="maker"
            class="mb-3"
          />
        </b-col>
      </b-row>
      <b-row v-if="editSplit" class="mb-3">
        <b-col>
          <b-form-group
            label-cols="2"
            :label="$t('split') | capitalize"
          >
            <b-input
              type="number"
              min="0.01"
              max="100"
              step="0.01"
              v-model="split"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <include-exclude-selector
              :options="countries.map(c => { return {'name': c.text, 'value': c.code}})"
              :except-string="$t('ww_except')"
              :include-string="$t('in') | capitalize"
              :placeholder="$t('select_countries') | capitalize"
              :label="$t('territory') | capitalize"
              label-cols="6"
              v-model="selectedTerritories"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <include-exclude-selector
              :options="tariffs.map(c => { return {'name': c.text, 'value': c.code}})"
              :except-string="$t('all_except') | capitalize"
              :include-string="$t('in') | capitalize"
              :placeholder="$t('select_tariffs') | capitalize"
              :label="$t('tariff') | capitalize"
              label-cols="6"
              v-model="selectedTariffs"
          />
        </b-col>
      </b-row>
      <b-row v-if="!carveOut" class="mb-3">
        <b-col>
          <date-field
            label-cols="4"
            :group-label="$t('broadcast_start') | capitalize"
            class="w-100"
            name="broadcastStartDate"
            v-model="broadcastStartDate"
          />
        </b-col>
        <b-col>
          <date-field
            label-cols="4"
            :group-label="$t('broadcast_end') | capitalize"
            class="w-100"
            name="broadcastEndDate"
            v-model="broadcastEndDate"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <date-field
            label-cols="4"
            :group-label="$t(carveOut ? 'date_from' : 'date_start')  | capitalize"
            class="w-100"
            :name="carveOut ? 'dateFrom' : 'dateStart'"
            v-model="startDate"
          />
        </b-col>
        <b-col>
          <date-field
            label-cols="4"
            :group-label="$t(carveOut ? 'date_to' : 'date_end')  | capitalize"
            class="w-100"
            :name="carveOut ? 'dateTo' : 'dateEnd'"
            v-model="endDate"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-form-textarea
            size="lg"
            debounce="500"
            name="comment"
            rules="required"
            :placeholder="$t('make_comment') | capitalize"
            v-model="comment"
            rows="5"
          />
        </b-col>
      </b-row>
      <b-row class="modal-footer mt-4">
        <b-button @click="cancel" class="btn-outline-dark">
          {{ cancelText }}
        </b-button>
        <b-button type="submit" class="btn-outline-red">
          {{ applyText }}
        </b-button>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapState } from "vuex"
import { ValidationObserver } from "vee-validate"

export default {
  name: "OwnershipCreateForm",
  props: {
    editSplit: {
      type: Boolean,
      default: true,
    },
    recordingUuid: {
      type: String,
    },
    recordingUuids:{
      type: Array,
    },
    applyText: {
      type: String,
      default: "Save",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    carveOut: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationObserver,
  },
  computed: {
    ...mapState("consts", ["countries", "tariffs"]),
  },
  methods: {
    ...mapActions("consts", ["getCountries", "getTariffs"]),
    onSubmit () {
      if (!this.selectedOwner) {
        this.$refs.ownershipsCreateForm.setErrors({ maker: [this.$t("this_field_is_required")] })
        return
      }
      const data = {
        [this.carveOut ? "maker" : "resoundId"]: this.selectedOwner.resoundId,
        tariffs: this.selectedTariffs.except ? [] : this.selectedTariffs.values.map(t => t.value),
        excludeTariffs: this.selectedTariffs.except ? this.selectedTariffs.values.map(t => t.value) : [],
        countries: this.selectedTerritories.except ? [] : this.selectedTerritories.values.map(t => t.value),
        excludeCountries: this.selectedTerritories.except ? this.selectedTerritories.values.map(t => t.value) : [],
        split: this.split,
      }
      if (this.startDate) {
        data[this.carveOut ? "dateFrom" : "startDate"] = this.startDate
      }
      if (this.endDate) {
        data[this.carveOut ? "dateTo" : "endDate"] = this.endDate
      }
      if (this.broadcastStartDate) {
        data["broadcastStartDate"] = this.broadcastStartDate
      }
      if (this.broadcastEndDate) {
        data["broadcastEndDate"] = this.broadcastEndDate
      }
      if (this.comment) {
        data["comment"] = this.comment
      }
      if (this.recordingUuids) {
        data["recordings"] = this.recordingUuids
      }
      this.$emit("submit", data, this.$refs.ownershipsCreateForm)
    },
    cancel () {
      this.$bvModal.hide(this.$parent.$parent.id)
    }
  },
  mounted () {
    if (!this.countries.length) {
      this.getCountries()
    }
    if (!this.tariffs.length) {
      this.getTariffs()
    }
  },
  data () {
    return {
      selectedTerritories: {
        except: false,
        values: [],
      },
      selectedTariffs: {
        except: false,
        values: [],
      },
      selectedOwner: null,
      broadcastStartDate: null,
      broadcastEndDate: null,
      startDate: null,
      endDate: null,
      comment: null,
      split: 100,
    }
  }
}
</script>