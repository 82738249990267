<template>
  <list-layout
    :title="$t('matching_stats') | startcase"
    :filters-pending="filtersPending"
    :apply-filters="applyFilters"
    :reset-filters="resetFilters"
    :filters-applied="filtersApplied"
  >
    <modal 
      id="matching-stats-export-modal" 
      :customTitle="$t('matching_stats_export') | capitalize" 
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered
      hide-footer>
      <matching-stats-export-form :count="count"/>
    </modal>
    <template v-slot:filters>
      <b-container>
        <b-row>
          <b-col cols="3">
            <b-form-group :label="$t('matching_type') | capitalize" label-class="pb-0">
              <b-form-select v-model="filters.matchingType.value" :options="matchingTypeOptions">
                <template v-slot:first>
                  <b-form-select-option :value="null">
                    {{ $t("all") | capitalize }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="$t('action_type') | capitalize" label-class="pb-0">
              <b-form-select v-model="filters.actionType.value" :options="actionTypeOptions">
                <template v-slot:first>
                  <b-form-select-option :value="null">
                    {{ $t("all") | capitalize }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="$t('user') | capitalize" label-class="pb-0">
              <select-search
                :list-endpoint="$api.users.usersSearch"
                label=""
                :placeholder="$t('search_user') | capitalize"
                search-name="search"
                exclude-name="id"
                text-field="name"
                value-field="email"
                v-model="selectedUser"
                ref="selectUser"
                :show-value="false"
                :disabled="!permissions.actions.canSeeOtherUsersMatchingStats"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="$t('date_range') | capitalize" label-class="pb-0">
              <date-picker
                class="w-100"
                :format="$config.DATE_FORMAT"
                range
                :clearable="false"
                v-model="dateRange"
                />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:merge>
      <div class="row align-items-center">
        <div class="d-flex col justify-content-end">
          <b-form-group class="mr-2">
            <b-form-checkbox v-model="filters.detailed.value" switch>
              {{ $t("detailed_stats") | capitalize }}
            </b-form-checkbox>
          </b-form-group>
          <div>
            <b-button size="sm" variant="outline-primary" @click="exportData" class="ml-2">
              <fa-icon size="sm" :icon="['fa', 'cloud-upload-alt']" />
              {{ $t("export") | capitalize }}
            </b-button>
          </div>
        </div>
      </div>
    </template>

    <!-- Counts -->
    <template v-slot:counts>
      <div class="row align-items-center">
        <div class="col d-flex">
          <pagination
            class="smaller p-0"
            reference="stats"
            :page="page"
            :count="count"
            :loading="loading"
            v-model="limit"
            @limit="onLimit"
          />
        </div>
      </div>
    </template>

    <!-- Table -->
    <template v-slot:table>
      <v-server-table
        ref="stats"
        :columns="columns"
        :options="options"
        @loaded="onLoaded"
        @loading="onLoading"
        @pagination="onPagination"
        @sorted="onSorted"
      >
        <template v-slot:recordingId="{ row }">
          <database-link :type="linkTypes.RECORDING" :item-id="row.recording.id" v-if="row.recording">{{ row.recording.resoundId }}</database-link>
        </template>

        <template v-slot:matchingType="{ row }">
            {{ $t(Object.keys(matchingTypes).find(k => matchingTypes[k] === row.matchingType).toLowerCase()) | capitalize }}
        </template>

        <template v-slot:actionType="{ row }">
            {{ $t(Object.keys(matchingActionTypes).find(k => matchingActionTypes[k] === row.actionType).toLowerCase()) | capitalize }}
        </template>

        <template v-slot:matched="{ row }">
          {{ formatMatchTime(row.matched) }}
        </template>

        <template v-slot:user="{ row }">
          {{ row.userName }}
        </template>

      </v-server-table>
    </template>
  </list-layout>
</template>

<script>
import { capitalize, get } from "lodash"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import { getPerPageItemDefaults, linkTypes, matchingActionTypes, matchingTypes } from "@/constants"
import DatabaseLink from "@/components/DatabaseLink"
import MatchingStatsExportForm from "@/pages/Reporting/MatchingStatsExportForm"
import Pagination from "@/components/Pagination"
import { mapState } from "vuex"
import moment from "moment"

export default {
  name: "MatchingStatistics",
  components: { DatabaseLink, MatchingStatsExportForm, Pagination },
  mixins: [datePickerMixin, listRouteMixin],
  data: function () {
    const today = moment().format("YYYY-MM-DD")
    return {
      linkTypes,
      matchingTypes,
      matchingActionTypes,
      skipFirstRequest: true,
      showDateTime: false,
      matchingTypeOptions: [
        {
          text: capitalize(this.$t("repertoire_matching")),
          value: matchingTypes.REPERTOIRE_MATCHING
        },
        {
          text: capitalize(this.$t("usage_matching")),
          value: matchingTypes.USAGE_MATCHING
        },
      ],
      actionTypeOptions: [
        {
          text: capitalize(this.$t("review")),
          value: matchingActionTypes.REVIEW
        },
        {
          text: capitalize(this.$t("match")),
          value: matchingActionTypes.MATCH
        },
        {
          text: capitalize(this.$t("create")),
          value: matchingActionTypes.CREATE
        },
      ],
      count: 0,
      page: 1,
      limit: Number(getPerPageItemDefaults(this.$route)),
      loading: false,
      columns: [],
      options: {
        headings: {
          matchingType: capitalize(this.$t("matching_type")),
          actionType: capitalize(this.$t("action_type")),
          user: capitalize(this.$t("user")),
          count: capitalize(this.$t("count")),
          originalTitle: capitalize(this.$t("original_title")),
          originalArtist: capitalize(this.$t("original_artist")),
          matched: capitalize(this.$t("match_date")),
          recordingId: this.$t("recording_id"),
          recordingTitle: capitalize(this.$t("recording_title")),
          recordingArtist: capitalize(this.$t("recording_artist")),
        },
        columnsClasses: {
          matchingType: ["width-small"],
          actionType: ["width-small"],
        },
        sortable: ["matchingType", "actionType", "user", "matched", "count"],
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.reporting.matchingStatistics(queryParams)
        },
        sendInitialRequest: false
      },
      filters: {
        matchingType: { value: null, defaultValue: null },
        actionType: { value: null, defaultValue: null },
        user: { value: null, defaultValue: null },
        to: { value: "", defaultValue: today, type: Date, format: "YYYY-MM-DD" },
        from: { value: "", defaultValue: today, type: Date, format: "YYYY-MM-DD" },
        detailed: { value: false, defaultValue: false },
      },
      selectedUser: null,
    }
  },
  computed: {
    ...mapState("user", ["user", "permissions"]),
  },
  methods: {
    setColumns () {
      if (this.filters.detailed.value) {
        this.columns = [
          "matchingType",
          "actionType",
          "originalTitle",
          "originalArtist",
          "matched",
          "user",
          "recordingId",
          "recordingTitle",
          "recordingArtist"
        ]
        this.options.headings.matched = capitalize(this.$t("matched_on"))
      } else {
        this.columns = [
          "matchingType",
          "actionType",
          "user",
          "matched",
          "count"
        ]
        this.options.headings.matched = capitalize(this.$t("match_date"))
      }
      this.showDateTime = this.filters.detailed.value
    },
    formatMatchTime (matchTime) {
      if (this.showDateTime) {
        return moment(matchTime).format(this.$config.DATETIME_FORMAT)
      } else {
        return moment(matchTime).format(this.$config.DATE_FORMAT)
      }
    },
    getData () {
      this.$refs.stats.getData()
    },
    exportData () {
      this.$bvModal.show("matching-stats-export-modal")
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
      this.setColumns()
    },
  },
  mounted () {
    if (!this.permissions.actions.canSeeOtherUsersMatchingStats) {
      this.selectedUser = {
        value: this.user.email,
        text: this.user.name,
      }
    }
  },
  watch: {
    selectedUser (newVal) {
      if (newVal && typeof newVal === "object") {
        this.filters.user.value = newVal.value
      } else {
        this.filters.user.value = null
      }
    },
    "filters.user.value": function (newVal) {
      if (newVal) {
        this.$refs.selectUser.searchValue = newVal
      } else {
        this.selectedUser = null
      }
    }
  }
}
</script>


