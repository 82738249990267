import { omit } from "lodash/object"

const state = {
  userActionCounts: {
    claimActionsCount: null,
    eligibilityActionsCount: null,
    lineupConflictsCount: null,
    makerChallengesCount: null,
    mandateActionsCount: null,
    matchingChallengeActionsCount: null,
    notificationActionsCount: null,
    ownershipConflicts: null,
    performerChallengesCount: null,
    repertoireActionsCount: null,
    repertoireErrorActionsCount: null,
    rightsholderActionsCount: null,
    usageChallengeActionsCount: null,
    usageMatchingConflicts: null,
    eligibilityConflicts: null,
    lineupAdjustmentsActionsCount: null,
    ownershipAdjustmentsActions: null,
    usageAdjustmentsActions: null,
    duplicateConflictsCount: null,
    mergeRequestsCount: null,
  },
  mandateConflicts: {
    data: null,
    count: 0
  },
}

const mutations = {
  setUserActionCounts (state, data) {
    const counts = omit(data, ["partial"])
    if ( data.partial === false ) {
      state.userActionCounts = counts
    } else {
      for (const property in counts) {
        const val = data[property]
        if ( val !== null && val !== undefined) {
          state.userActionCounts[property] = val
        }
      }
    }
  },
  setMandateConflicts (state, data) {
    state.mandateConflicts.count = data.count
    state.mandateConflicts.data = data.results
  },
}

const actions = {
  setUserActionCounts ({ commit }, data) {
    commit("setUserActionCounts", data)
  },
  decrementUserActionCount ({ commit, state }, countName) {
    const count = state.userActionCounts[countName]
    if (count !== null && count !== undefined && count >= 1) {
      const newCount = count - 1
      const data = { ...state.userActionCounts, [countName]: newCount }
      commit("setUserActionCounts", data)
    }
  },
  getMandateConflicts (state, params) {
    return this._vm.$api.actions.mandateConflicts(params)
      .then(d => state.commit("setMandateConflicts", d.data))
  }
}

const getters = {
  mandateConflictsData (state) {
    return state.mandateConflicts.data
  },
  mandateConflictsCount (state) {
    return state.mandateConflicts.count
  }
}
export const actionTabs = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
