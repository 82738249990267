const conflictColumnsOrder = [
  // Recording
  "title",
  "artist",
  "isrc",
  "fixationCountry",
  "fixationYear",
  "firstMakersNationalities",
  "firstMakers",
  "releases",
  "type",
  "owners",
  "performers",
  "duration",
  "hasUsages",
  // Rightsholder
  "name",
  "recordingsCount",
  "ipn",
  "dateOfBirth",
  "gender",
  "aliases",
  "nationalities",
  "memberCollectives",
  // Common
  "sisterSociety",
  "amountGross",
  // Actions
  "sources",
  "actions",
]

const widthAttributes = {
  5: { width: "5%", tdClass: "width-5" },
  7: { width: "7%", tdClass: "width-7" },
  11: { width: "11%", tdClass: "width-mini" },
  12: { width: "12%", tdClass: "width-12" },
}

function sortColumns (columns) {
  return columns.sort((a, b) => {
    let val_a = a
    let val_b = b
    if (typeof a !== "string") {
      val_a = a["key"]
      val_b = b["key"]
    }
    return conflictColumnsOrder.indexOf(val_a) - conflictColumnsOrder.indexOf(val_b)
  })
}

export {
  sortColumns,
  widthAttributes,
}
