<template>
  <div>
    <list-layout
        :no-search="true"
        :no-counts="true"
        :search-filter="filters.fuzzySearch"
        :apply-filters="applyFilters"
        :no-apply-btn="true"
    >
      <template v-slot:title>
        <div class="row heading-container">
          <go-back />
          <h4 class="ml-2">
            {{ fileName | tolower }}
          </h4>
        </div>
      </template>
      <template v-slot:beforeFilters>
        <b-row>
          <b-col>
            <filter-builder
              :id="userPreferenceNames.LANDING_RECORDINGS_LIST_FILTER"
              :filters="filters"
              :filters-pending="filtersPending"
              :apply-filters="applyFilters"
              :reset-filters="resetFilters"
              :filters-applied="filtersApplied"
              @filter="onFilter"
            />
          </b-col>
        </b-row>
      </template>
      <template v-slot:table>
        <t-table
          ref="landingRep"
          id="landingRep"
          :columns="columns"
          :options="options"
          :limit="limit"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <!-- Counts -->
          <template v-slot:counts>
            <pagination
              class="smaller p-0"
              reference="landingRep"
              :page="page"
              :count="count"
              :loading="loading"
              v-model="limit"
              @limit="onLimit"
            />
          </template>

          <!-- Title -->
          <template v-slot:title="{ row }">
              <database-link
                v-if="['CR', 'LN', 'MC', 'ML'].includes(row.status)"
                :type="linkTypes.RECORDING"
                :item-id="row.recording"
              >
                {{ row.title }}
              </database-link>
              <span v-else class="text-gray">
                {{ row.title }}
              </span>
          </template>

          <!-- Duration -->
          <template v-slot:duration="{ row }">
            <span>
              {{
                row.duration
                  ? $options.filters.toDuration(row.duration)
                  : ""
              }}
            </span>
          </template>

          <!-- Type -->
          <template v-slot:type="{ row }">
            <span>
              {{ row.type }}
            </span>
          </template>

          <!-- Album Names -->
          <template v-slot:albumNames="{ row }">
            <NamesListModal
              class-names="table-meta mb-2"
              :values="row.releaseNames"
            />
          </template>

          <!-- Main Performers -->
          <template v-slot:performers="{ row }">
            <NamesListModal
              :values="row.performers"
            />
          </template>

          <!-- Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="row.status === 'CR'"
              :text="$t('created') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-else-if="row.status === 'LN'"
              :text="$t('linked') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-else-if="row.status === 'MC'"
              :text="$t('manually_created') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-else-if="row.status === 'ML'"
              :text="$t('manually_linked') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <router-link class="text-info" :to="{
              name: 'manual-matching-detail',
              params: {
                uuid: row.id
              },
              query: {
                type: metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT,
              }
            }" v-else-if="row.status == 'PN'">
              <status
                :text="$t('pending') | capitalize"
                :icon="['fas', 'question-circle']"
                color="orange"
              /><fa-icon :icon="['fa', 'external-link']" size="sm" class="ml-2"/>
            </router-link>
          </template>
        </t-table>
      </template>
    </list-layout>
  </div>
</template>
<script>
import {
  capitalize,
  get,
  startCase as startcase,
  upperCase as uppercase
} from "lodash"
import { filterTypes, linkTypes, metadataConflictTypeCodes, perPageItemsSm, userActionStatuses, userPreferenceNames } from "@/constants"
import DatabaseLink from "@/components/DatabaseLink"
import FilterBuilder from "@/components/FilterBuilder"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import Pagination from "@/components/Pagination"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "LandingRecordingsList",
  mixins: [listRouteMixin],
  components: {
    FilterBuilder,
    DatabaseLink,
    NamesListModal,
    Pagination
  },
  watch: {
    summary () {
      this.filters.status.options = this.statusOptions
    }
  },
  methods: {
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.$emit("loaded", data.count)
      this.loading = false
    },
    getData () {
      this.$refs.landingRep.getData()
    },
    onFilter (name, value) {
      if (name === "rightsHolderUuid") {
        if (value) {
          this.changeFilter(name, value.id)
          this.changeFilter("rightsholder_rs_id", value.resoundId)
        } else {
          this.changeFilter(name, null)
          this.changeFilter("rightsholder_rs_id", null)
        }
        if (!this.causeFiltersPending) {
          this.filtersPending = false
          this.causeFiltersPending = true
        }
      }
    },
  },
  props: ["repFileId"],
  data () {
    return {
      causeFiltersPending: true,
      page: 1,
      limit: perPageItemsSm,
      loading: false,
      count: 0,
      fileId: this.repFileId,
      linkTypes,
      metadataConflictTypeCodes,
      userActionStatuses,
      userPreferenceNames,
      columns: [
        "title",
        "artist",
        "isrc",
        "duration",
        "type",
        "albumNames",
        "performers",
        "status"
      ],
      options: {
        sortable: ["isrc", "title", "artist", "duration", "type", ],
        headings: {
          isrc: uppercase(this.$t("isrc")),
          title: startcase(this.$t("title")),
          artist: startcase(this.$t("artist")),
          duration: capitalize(this.$t("duration")),
          type: capitalize(this.$t("type")),
          albumNames: startcase(this.$t("albums")),
          performers: startcase(this.$t("performers")),
          status: capitalize(this.$t("status"))
        },
        requestKeys: {},
        responseAdapter ({ data }) {
          this.$parent.$parent.fileName = data.context.filename
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          const componentData = this.$parent.$parent
          const id = componentData.fileId

          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))

          return this.$api.repertoire.landingRecordingsList(id, queryParams)
        }
      },
      filters: {
        rightsHolderUuid: {
          value: null,
          defaultValue: null,
          type: filterTypes.SELECT_SEARCH,
          label: capitalize(this.$t("rightsholder")),
          placeholder: capitalize(this.$t("search_rightsholder")),
          excludeName: "id",
          valueField: "resoundId",
          textField: "name",
          searchName: "search",
          listEndpoint: this.$api.rightsholders.rightsholdersList,
          noWatch: true,
        },
        status: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("status")), options: [] },
        fuzzySearch: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("search")) },
        title: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("title")) },
        artists: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("artist")) },
        isrc: { value: null, defaultValue: null, type: filterTypes.TEXT, label: this.$t("isrc") },
        performers: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("performers")) },
        releaseNames: { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("release")) },
        type: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("type")), options: ["SR", "MV"], emptyValue: capitalize(this.$t("all")) },
      },
      fileName: "",
      summary: { cr: 0, ln: 0, mc: 0, ml: 0, pn: 0 }
    }
  },
  computed: {
    statusOptions () {
      return [
        {
          text: `${capitalize(this.$t("all"))} (${this.summary.cr +
            this.summary.ln +
            this.summary.mc +
            this.summary.ml +
            this.summary.pn})`,
          value: null
        },
        {
          text: `${capitalize(this.$t("created"))} (${this.summary.cr})`,
          value: "CR"
        },
        {
          text: `${capitalize(this.$t("linked"))} (${this.summary.ln})`,
          value: "LN"
        },
        {
          text: `${capitalize(this.$t("manually_created"))} (${
            this.summary.mc
          })`,
          value: "MC"
        },
        {
          text: `${capitalize(this.$t("manually_linked"))} (${
            this.summary.ml
          })`,
          value: "ML"
        },
        {
          text: `${capitalize(this.$t("pending"))} (${this.summary.pn})`,
          value: "PN"
        }
      ]
    }
  },
  mounted () {
    this.updateTableSortIcons(this.$refs.landingRep.$children[0], true)
    let value = get(this.$route.query, "rightsholder_rs_id")
    if (value) {
      this.causeFiltersPending = false
      this.filters.rightsHolderUuid.searchValue = value
    }
  }
}
</script>
