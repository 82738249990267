<template>
  <div class="collapse-table">
    <b-table
        :fields="fields"
        :items="items"
        v-if="items.length"
        class="smaller mb-0"
        striped
        fixed
    >
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width }"
        />
      </template>

      <!-- Name -->
      <template v-slot:cell(name)="row">
        <div class="d-flex">
          <router-link v-if="isReconciledData && row.item.conflict"
            class="text-red small mr-2"
            :to="{
              name: 'user-actions',
              params: { type: actionsTabs.RIGHTS_OWNERSHIP_CONFLICT },
              query: { fuzzy_search: resoundId },
            }"
          >
            <fa-icon
              :icon="['fas', 'exclamation-circle']"
              :title="$t('ownership_conflicts') | capitalize"
              class="text-red"
            />
          </router-link>
          <database-link v-if="isReconciledData"
            :type="linkTypes.RIGHTS_HOLDER"
            :item-id="row.item.ownerId"
          >
            {{ row.item.name }}
          </database-link>
          <span v-else>
            {{ row.item.rightOwner }}
          </span>
        </div>
        <div class="very-small">
          <span>
            {{ row.item.resoundId }}
          </span>
        </div>
        <span v-if="!isReconciledData && !noChallengesPending(row.item) && challengeUuid(row.item)">
          <router-link
            class="text-red small"
            :to="{
              name: 'rightsholder-challenge',
              params: {
                standalone: true,
                uuid: challengeUuid(row.item),
                challengeType: rightsholderTypes.MAKER,
              },
            }"
          >
            {{ $t("challenge_pending") | capitalize }}
          </router-link><br>
        </span>
        <span v-if="!isReconciledData && row.item.hasMetadataConflict">
          <router-link
            class="text-red small"
            :to="{
              name: 'manual-matching-detail',
              params: {
                uuid: matchingConflictSourceUuid (row.item),
              },
              query: {
                type: metadataConflictTypeCodes.MAKERS_METADATA_CONFLICT,              }
            }"
          >
            {{ $t("matching_conflict_pending") | capitalize }}
          </router-link>
        </span>
        <span v-if="isReconciledData && row.item.mandateConflict">
          <router-link
            class="text-red small"
            :to="{
              name: 'user-actions',
              params: { type: actionsTabs.MANDATES },
              query: { fuzzy_search: row.item.resoundId },
            }"
          >
            {{ $t("mandate_conflict_pending") | capitalize }}
          </router-link>
        </span>
      </template>

      <!-- Tariff -->
      <template v-slot:cell(tariffs)="row">
        <NamesListModal
            class-names="table-meta mb-2"
            :text-plural="$t('tariffs')"
            :text-exception="$t('all') | capitalize"
            :text-none="$t('all') | capitalize"
            :exception="isReconciledData ? row.item.excludeTariffs && !!row.item.excludeTariffs.length : false"
            :values="getTariffs(row)"
        />
      </template>

      <!-- Countries -->
      <template v-slot:cell(countries)="row">
        <NamesListModal
            class-names="table-meta mb-2"
            :text-plural="$t('countries')"
            :text-exception="$t('ww')"
            :text-none="$t('all') | capitalize"
            :sort="true"
            :exception="!!row.item.excludeCountries ? row.item.excludeCountries && !!row.item.excludeCountries.length : false"
            :values="getCountries(row)"
        />
      </template>

      <!-- Representative -->
      <template v-slot:cell(representative)="row">
        <NamesListModal
            class-names="table-meta mb-2"
            :text-plural="$t('collectives')"
            :values="row.item.collectives && row.item.collectives.length ? row.item.collectives.flatMap(c => c.name) : []"
        />
      </template>

      <!-- Date Start -->
      <template v-slot:cell(broadcastStartDate)="row">
        <span v-if="!isReconciledData">{{ getDate(row.item.broadcastStartDate) }}</span>
        <span v-else-if="!editModeOwners">
          {{ row.item.broadcastStartDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.item.broadcastStartDate"
        />
      </template>

      <!-- Date End -->
      <template v-slot:cell(broadcastEndDate)="row">
        <span v-if="!isReconciledData">{{ getDate(row.item.broadcastEndDate) }}</span>
        <span v-else-if="!editModeOwners">
          {{ row.item.broadcastEndDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.item.broadcastEndDate"
        />
      </template>

      <!-- Date Bought -->
      <template v-slot:cell(startDate)="row">
        <span v-if="!isReconciledData">{{ getDate(row.item.startDate) }}</span>
        <span v-else-if="!editModeOwners">
          {{ row.item.startDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.item.startDate"
        />
      </template>

      <!-- Date Sold -->
      <template v-slot:cell(endDate)="row">
        <span v-if="!isReconciledData">{{ getDate(row.item.endDate) }}</span>
        <span v-else-if="!editModeOwners">
          {{ row.item.endDate }}
        </span>
        <date-field
          v-else
          class="w-100"
          v-model="row.item.endDate"
        />
      </template>

      <!-- Split -->
      <template v-slot:cell(split)="row">
        <span v-if="!editModeOwners || !isReconciledData">{{ `${row.item.split}%` }}</span>
        <b-input
            v-else
          type="number"
          min="0.01"
          max="100"
          step="0.01"
          v-model="row.item.split"
        />
      </template>

      <!-- Status -->
      <template v-slot:cell(status)="row">
        <fa-icon
          v-if="row.item.status === landingDataStatuses.PENDING"
          :icon="['fas', 'question-circle']"
          class="text-orange"
        />
        <fa-icon
            v-else-if="[
              landingDataStatuses.CREATED,
              landingDataStatuses.LINKED,
              landingDataStatuses.MANUALLY_CREATED,
              landingDataStatuses.MANUALLY_LINKED
              ].includes(row.item.status)"
            :icon="['fas', 'check-circle']"
            class="text-green"
        />
      </template>

      <!-- Invalidate -->
      <template v-slot:cell(invalidate)="row">
          <span v-if="row.item.deleted">{{ getDate(row.item.deleted) }}</span>
          <fa-icon class="clickable" v-else-if="editModeOwners" :title="$t('invalidate') | capitalize" :icon="['fa', 'trash']" @click="deleteShare(row.item)"/>
      </template>

      <template v-slot:row-details="row">
        <b-row class="mt-2">
          <b-col>
            <b-form-input
              size="lg"
              debounce="500"
              :placeholder="$t('make_comment') | capitalize"
              v-model="comments[row.item.resoundId]"
            ></b-form-input>
          </b-col>
        </b-row>
      </template>
    </b-table>
  </div>
</template>
<script>
import { actionsTabs, landingDataStatuses, linkTypes, metadataConflictTypeCodes, rightsholderTypes, userActionStatuses } from "@/constants"
import { mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import moment from "moment"
import { rightsholderDetailTabs } from "@/constants"
import { startCase } from "lodash"

export default {
  name: "OwnersList",
  props: {
    recordingUuid: {
      type: String,
      required: true,
    },
    resoundId: {
      type: String,
      required: true,
    },
    isReconciledData: {
      type: Boolean,
      required: true,
    },
    ownerData: {
      type: Array,
      required: true,
    },
    countriesFilter: {
      type: Object,
    },
    hideInvalidated: {
      type: Boolean,
      default: false,
    },
    sourceIndex: {
      type: Number,
    },
    isRepTrack: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    noChallengesPending (row) {
      return ![
        userActionStatuses.PENDING,
        userActionStatuses.IN_PROGRESS,
      ].includes(row.matchingChallengeStatus) && row.status !== landingDataStatuses.PENDING
    },
    challengeUuid (row) {
      return row.matchingChallengeId
    },
    matchingConflictSourceUuid (row) {
      return row.landingMakerId
    },
    getDate (date) {
      return date ? moment(date).format(this.$config.ISO_DATE_FORMAT) : ""
    },
    getTariffs (row) {
      if (this.isReconciledData) {
        if (!row.item.tariffs) {
          return []
        }
        return row.item.tariffs.length ? row.item.tariffs : row.item.excludeTariffs
      } else {
        return row.item.copyrights || []
      }
    },
    getCountries (row) {
      return row.item.countries && row.item.countries.length ? (row.item.countries || []) : (row.item.excludeCountries || [])
    },
    deleteShare (item) {
      item.toDelete = true
      this.$emit("delete:share")
    }
  },
  components: {
    DatabaseLink,
    NamesListModal,
  },
  data () {
    return {
      linkTypes,
      rightsholderTypes,
      actionsTabs,
      landingDataStatuses,
      rightsholderDetailTabs,
      metadataConflictTypeCodes,
    }
  },
  computed: {
    ...mapState("repertoire", [
      "editModeOwners",
      "recordingOwners",
    ]),
    items () {
      let data = []
      if (this.isReconciledData && this.countriesFilter.values.length) {
        const values = this.countriesFilter.values.map(c => c.name)
        if (this.countriesFilter.except) {
          data = this.ownerData.filter(o => {
            if (o.countries.length) {
              return o.countries.every(c => !values.includes(c))
            } else {
              return values.every(v => o.excludeCountries.includes(v))
            }
          })
        } else {
          data = this.ownerData.filter(o => {
            if (o.countries.length) {
              return o.countries.some(c => values.includes(c))
            } else {
              return values.some(v => !o.excludeCountries.includes(v))
            }
          })
        }
      } else {
        data = this.ownerData
      }
      if (this.hideInvalidated) {
        data = data.filter(d => d.deleted === null && (d.endDate === null || new Date(d.endDate) > new Date()))
      }
      return data
    },
    fields () {
      let fields = [
        { key: "name", label: startCase(this.$t("name")), width: "15%" },
        { key: "tariffs", label: startCase(this.isReconciledData ? this.$t("tariff"): this.$t("copyright")), width: "10%" },
        { key: "countries", label: startCase(this.$t("territory")), width: "10%" },
        { key: "representative", label: startCase(this.$t("representative")), width: "10%" },
        { key: "broadcastStartDate", label: startCase(this.$t("broadcast_start")), width: "10%" },
        { key: "broadcastEndDate", label: startCase(this.$t("broadcast_end")), width: "10%" },
        { key: "startDate", label: startCase(this.$t("date_start")), width: "9%" },
        { key: "endDate", label: startCase(this.$t("date_end")), width: "9%" },
      ]
      if (this.isReconciledData) {
        fields.push({ key: "split", label: startCase(this.$t("split")), width: "7%" },)
        fields.push({ key: "invalidate", label: startCase(this.$t("invalidated")), width: "7%" })
      } else {
        fields.push({ key: "split", label: startCase(this.$t("split")), width: "6%" },)
        fields.push({ key: "status", label: "", width: "3%" })
      }
      return fields
    }
  },
}
</script>

<style lang="scss" scoped>
.very-small {
  font-size: 9pt;
}

.collapse-table {
  border-top: 1px solid $gray;
  padding: 10px;
}
</style>
