<template>
  <div>
    <list-layout
        :title="$t('usage_files') | startcase"
        :no-search="true"
        :no-counts="true"
        :search-filter="filters.search"
        :apply-filters="applyFilters"
        :no-apply-btn="true"
      >

      <!-- Filters -->
      <template v-slot:beforeFilters>
        <b-row>
          <b-col>
            <filter-builder
              :id="userPreferenceNames.USAGE_FILE_LIST_FILTER"
              :filters="getFiltersWithOptions()"
              :filters-pending="filtersPending"
              :apply-filters="applyFilters"
              :reset-filters="resetFilters"
              :filters-applied="filtersApplied"
              @filter="onFilter"
            />
          </b-col>
        </b-row>
      </template>

      <!-- Table -->
      <template v-slot:table>
        <t-table
          ref="usagesfiles"
          :columns="columns"
          :options="options"
          :limit="limit"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <template v-slot:counts>
            <pagination
              class="smaller p-0"
              reference="usagesfiles"
              :page="page"
              :count="count"
              :loading="loading"
              v-model="limit"
              @limit="onLimit"
            />
          </template>

          <template v-slot:fileName="{ row }">
            <router-link
              class="text-info"
              :to="{ name: 'usages-list', query: { file: row.id } }"
            >
              {{ row.fileName }}
            </router-link>
          </template>

          <template v-slot:uploadDate="{ row }">
            <date-time-cell :dateTime="row.uploadDate" />
          </template>

          <template v-slot:uploadUser="{ row }">
            {{ row.uploader }}
          </template>

          <template v-slot:stations="{ row }">
            <NamesListModal :values="row.stations" />
          </template>

          <template v-slot:tariffCode="{ row }">
            {{ row.tariff }}
          </template>

          <template v-slot:copyrightCode="{ row }">
            {{ row.copyright }}
          </template>

          <!-- Ingestion stats -->
          <template v-slot:ingestionStats="{ row }">
            <div v-if="row.stats && row.stats.landingDbIngestion">
              <div
                v-for="(stat, k) in getIngestionStats(row)"
                :key="k"
                class="table-sub-class text-sm inline"
              >
                <div>
                  {{ getStatsDisplay(k) | capitalize }}:
                  {{ stat }}
                  <fa-icon
                    @click="showErrors(row)"
                    v-if="k === 'errorCount' && stat > 0"
                    class="blue clickable ml-2"
                    size="sm"
                    :icon="['fas', 'plus-circle']"
                  />
                  <fa-icon
                    @click="downloadErrorFile(row)"
                    v-if="row.errorFileName && k == 'errorCount'"
                    class="blue clickable ml-2"
                    size="sm"
                    :icon="['fas', 'file-download']"
                  />
                </div>
              </div>
            </div>
          </template>

          <!-- Matching stats -->
          <template v-slot:matchingStats="{ row }">
            <div v-if="row.stats && row.stats.matching">
              <div
                v-for="k in [
                  'totalCount',
                  'uniqueCount',
                  'unmatchedCount',
                  'automatchedCount',
                  'manuallyMatchedCount',
                  'reviewedCount'
                ]"
                :key="k"
                class="table-sub-class text-sm inline"
              >
                <div>
                  {{ getStatsDisplay(k) | capitalize }}:
                  {{ row.stats.matching[k] }}
                  <span v-if="k === 'automatchedCount'">{{
                    `(${automatchedPercentage(row.stats)}%)`
                  }}</span>
                </div>
              </div>
            </div>
          </template>

          <!-- Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="row.status === fileStatuses.PENDING"
              :text="$t('pending') | capitalize"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            <status
              v-if="row.status === fileStatuses.PROCESSING"
              :text="$t('processing') | capitalize"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
            <status
              v-if="row.status === fileStatuses.LANDED"
              :text="$t('landed') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === fileStatuses.INGESTED"
              :text="$t('ingested') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === fileStatuses.MATCHED"
              :text="$t('matched') | capitalize"
              :icon="['fas', 'check-circle']"
              color="green"
            />
            <status
              v-if="row.status === fileStatuses.ERROR"
              :text="$t('error') | capitalize"
              :icon="['fas', 'exclamation-circle']"
              color="red"
              :active="true"
              @click="showErrors(row, true)"
              clickable
            />
          </template>

          <!-- Distribution -->
          <template v-slot:distributionStatus="{ row }">
            <div class="d-flex">
              <b-form-checkbox
                v-if="
                  permissions.pages[Pages.UsagesFileList].canWrite && 
                  [
                    distributionStatuses.PENDING,
                    distributionStatuses.READY
                  ].includes(row.distributionStatus) &&
                    row.status !== fileStatuses.ERROR
                "
                :checked="distributionCheckbox(row)"
                @change="changeDistributionStatus(row)"
                switch
              />
              <status
                v-if="row.distributionStatus === distributionStatuses.PENDING"
                :text="$t('pending') | capitalize"
                :icon="['fas', 'question-circle']"
                color="orange"
              />
              <status
                v-if="row.distributionStatus === distributionStatuses.READY"
                :text="$t('ready') | capitalize"
                :icon="['fas', 'check-circle']"
                color="blue"
              />
              <status
                v-if="row.distributionStatus === fileStatuses.DISTRIBUTED"
                :text="$t('distributed') | capitalize"
                :icon="['fas', 'check-circle']"
                color="green"
              />
            </div>
          </template>

          <template v-slot:period="{ row }">
            <span v-if="row.startDate && row.endDate">{{
              `${parseDateTime(row.startDate, $config.DATE_FORMAT)} - ${parseDateTime(
                row.endDate,
                $config.DATE_FORMAT
              )}`
            }}</span>
          </template>
        </t-table>
        <modal
          id="errorsModal"
          ref="errorsModal"
          :customTitle="$t('errors') | capitalize"
          modal-class="custom-modal error"
          modalType="error"
          size="xl"
          centered 
          hide-footer
        >
          <FilesErrorsList
            :key="currentFile | get('id')"
            :item="currentFile"
            module="repertoirefiles"
            :onlyShowFileLevelErrors="onlyShowFileLevelErrors"
            :file-errors-endpoint="$api.usages.usageFilesErrorsList"
            :extra-columns="['sheetName']"
          />
        </modal>
      </template>
    </list-layout>
  </div>
</template>
<script>
import { capitalize, get, range as rangeFunc } from "lodash"
import { datePickerMixin, listRouteMixin } from "@/utils/mixins"
import { distributionStatuses, fileStatuses, filterTypes, openYears, perPageItemsSm, userPreferenceNames } from "@/constants"
import { mapActions, mapState } from "vuex"
import FilesErrorsList from "@/components/FilesErrorsList"
import FilterBuilder from "@/components/FilterBuilder"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import { Pages } from "@/utils/pages"
import Pagination from "@/components/Pagination"
import parseDate from "@/utils/date-parser"

export default {
  name: "UsageFileList",
  mixins: [datePickerMixin, listRouteMixin],
  components: {
    NamesListModal,
    FilterBuilder,
    Pagination,
    FilesErrorsList
  },
  computed: {
    ...mapState("consts", ["copyrights", "tariffs"]),
    ...mapState("user", ["permissions"]),
    yearOptions () {
      let currentYear = new Date().getFullYear()
      let options = rangeFunc(currentYear, currentYear - openYears, -1).map(y => {
        return { text: y, value: y }
      })
      options.unshift({ text: capitalize(this.$t("all")), value: null })
      return options
    },
    copyrightOptions () {
      let options = this.copyrights.map(c => { return { text: c.text, value: c.code }})
      options.unshift({ text: capitalize(this.$t("all")), value: null })
      return options
    },
    tariffOptions () {
      let options = this.tariffs.map(c => { return { text: c.text, value: c.code }})
      options.unshift({ text: capitalize(this.$t("all")), value: null })
      return options
    },
    distributionOptions () {
      let options = Object.keys(distributionStatuses).map(c => { return {  text: capitalize(c),value: distributionStatuses[c] }})
      options.unshift({ text: capitalize(this.$t("all")), value: null })
      return options
    },
    statusOptions () {
      let options = Object.keys(fileStatuses).map(c => { return {  text: capitalize(c),value: fileStatuses[c] }})
      options.unshift({ text: capitalize(this.$t("all")), value: null })
      return options
    },
  },
  watch: {
    dateRange (newVal) {
      this.filters.usageDateRange.value = newVal
    }
  },
  data () {
    return {
      distributionStatus: false,
      currentFile: null,
      onlyShowFileLevelErrors: false,
      fileStatuses: fileStatuses,
      distributionStatuses: distributionStatuses,
      userPreferenceNames,
      columns: [
        "fileName",
        "uploadDate",
        "uploadUser",
        "stations",
        "copyrightCode",
        "tariffCode",
        "period",
        "invoiceNumber",
        "amount",
        "ingestionStats",
        "matchingStats",
        "status",
        "distributionStatus"
      ],
      loading: false,
      page: 1,
      count: 0,
      limit: perPageItemsSm,
      filters: {
        copyright: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("copyright")), options: [] },
        tariff: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("tariff")), options: [] },
        status: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("status")), options: [] },
        search: { value: "", defaultValue: "", type: filterTypes.TEXT, label: capitalize(this.$t("search")) },
        distribution: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("distribution")), options: [] },
        uploader: {
          value: "",
          defaultValue: "",
          type: filterTypes.SELECT_SEARCH,
          listEndpoint: this.$api.users.usersSearch,
          excludeName: "id",
          textField: "name",
          valueField: "email",
          label: capitalize(this.$t("uploaded_by")),
          placeholder: capitalize(this.$t("search_user")),
          searchName: "search",
          noWatch: true
        },
        invoiceNumber : { value: null, defaultValue: null, type: filterTypes.TEXT, label: capitalize(this.$t("invoice")) },
        amount: { value: null, defaultValue: null, type: filterTypes.NUMBER_RANGE_NO_SLIDER, singleBound: true, label: capitalize(this.$t("amount")) },
        year: { value: null, defaultValue: null, type: filterTypes.SELECT, label: capitalize(this.$t("year")), options: [] },
        usagePeriod: { value: "", defaultValue:"", type: filterTypes.MONTH_YEAR, format: "MM-YYYY", label: capitalize(this.$t("usage_period")), noWatch: false },
        usageDateRange: { value: null, defaultValue:null, type: filterTypes.DATE_RANGE, format: "YYYY-MM-DD", label: capitalize(this.$t("upload_date")), noWatch: true }
      },
      options: {
        sortable: ["fileName","uploadDate","uploadUser","amount","invoice","tariffCode","copyrightCode","status","invoiceNumber"],
        initialSort: [
          {
            column: "uploadDate",
            dir: "desc",
          }
        ],
        headings: {
          fileName: capitalize(this.$t("filename")),
          uploadDate: capitalize(this.$t("upload_date")),
          uploadUser: capitalize(this.$t("upload_user")),
          stations: capitalize(this.$t("stations")),
          copyrightCode: capitalize(this.$t("copyright")),
          tariffCode: capitalize(this.$t("tariff")),
          period: capitalize(this.$t("period")),
          invoiceNumber: capitalize(this.$t("invoice")),
          amount: capitalize(this.$t("amount")),
          ingestionStats: capitalize(this.$t("ingestion_stats")),
          matchingStats: capitalize(this.$t("matching_stats")),
          status: capitalize(this.$t("status")),
          distributionStatus: capitalize(this.$t("distribution"))
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          componentData.pageNumber = this.page
          return this.$api.usages.usagesFilesList(queryParams)
        }
      },
      Pages,
    }
  },
  methods: {
    ...mapActions("consts", ["getCopyrights", "getTariffs"]),
    distributionCheckbox (row) {
      return row.distributionStatus === this.distributionStatuses.READY
    },
    changeDistributionStatus (row) {
      let newStatus =
        row.distributionStatus === this.distributionStatuses.READY
          ? this.distributionStatuses.PENDING
          : this.distributionStatuses.READY
      this.$api.usages
        .usageFileDistributionStatus(row.id, { status: newStatus })
        .then(() => {
          this.getData()
        })
        .catch(error => {
          let msg = `Error swapping distribution status. ${error.response.data.detail}`
          this.error(msg)
        })
    },
    onLimit (limit, reference) {
      this.limit = limit
      this.updateRouterPagination("limit", limit)
      this.$refs[reference].setLimit(limit)
    },
    automatchedPercentage (stats) {
      return stats.matching["uniqueCount"]
        ? Math.round(
          (stats.matching["automatchedCount"] /
              stats.matching["uniqueCount"]) *
              100
        )
        : 0
    },
    getStatsDisplay (key) {
      const mapToDisplay = {
        errorCount: this.$t("errors"),
        ingestedCount: this.$t("ingested_count"),
        totalCount: this.$t("total"),
        uniqueCount: this.$t("unique_count"),
        automatchedCount: this.$t("automatched_count"),
        manuallyMatchedCount: this.$t("manually_matched_count"),
        unmatchedCount: this.$t("unmatched_count"),
        reviewedCount: this.$t("reviewed_count"),
      }
      return get(mapToDisplay, key, key)
    },
    showErrors (row, onlyShowFileLevelErrors = false) {
      this.currentFile = row
      this.onlyShowFileLevelErrors = onlyShowFileLevelErrors
      this.$bvModal.show("errorsModal")
    },
    getIngestionStats (row) {
      return {
        totalCount: row.stats.landingDbIngestion.totalCount,
        ingestedCount: row.stats.singleViewIngestion ? row.stats.singleViewIngestion.ingestedCount : row.stats.landingDbIngestion.ingestedCount,
        errorCount: row.stats.landingDbIngestion.errorCount + (row.stats.singleViewIngestion ? row.stats.singleViewIngestion.errorCount : 0)
      }
    },
    downloadErrorFile (row) {
      this.$api.usages
        .downloadErrorFile(row.id)
        .then(response => {
          const link = document.createElement("a")
          link.href = response.data.link
          link.download = row.errorFileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(err => {
          if (err.response && err.response.data) {
            this.showErrors(err.response.data)
          } else {
            this.$toasted.global.toast(err)
          }
        })
    },
    getFiltersWithOptions () {
      this.filters.year.options = this.yearOptions
      this.filters.copyright.options = this.copyrightOptions
      this.filters.tariff.options = this.tariffOptions
      this.filters.distribution.options = this.distributionOptions
      this.filters.status.options = this.statusOptions
      return this.filters
    },
    onFilter (name, value) {
      if (name === "usageDateRange") {
        this.dateRange = value ? value : ["", ""]
      } else if (name === "uploader") {
        if (value === null) {
          this.changeFilter(name, value)
        }
        else {
          this.changeFilter(name, value.value)
        }
      }
    },
    getData () {
      this.$refs.usagesfiles.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    parseDateTime: parseDate,
    capitalize
  },
  mounted () {
    if (!this.copyrights.length) {
      this.getCopyrights()
    }
    if (!this.tariffs.length) {
      this.getTariffs()
    }    
    this.updateTableSortIcons(this.$refs.usagesfiles.$children[0], true)
  }
}
</script>

<style lang="scss" scoped>
.very-small {
  font-size: 9pt;
}
</style>
