<template>
  <div>
      <rightsholder-table
        v-if="rightsholderData"
        :data="[rightsholderData]"
        :fields="primaryFields"
        class="rightsholder-details">
      </rightsholder-table>
      <rightsholder-table
        className="general-details"
        v-if="rightsholderData && rightsholderData.type === rightsholderTypes.PERFORMER"
        :data="[rightsholderData]"
        :fields="secondaryFields"
      >
      </rightsholder-table>
    <RightsHolderMandateList
      :rightsholder-id="rightsholderId"
      :solved-manually="solvedManually"
      :data="{type}"
      :canEdit="permissions.actionsTabs[actionsTabs.MANDATES].canWrite"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import RightsHolderFieldsMixin from "@/pages/Rightsholders/RightsHolderDetail/RightsHolderFieldsMixin"
import RightsHolderMandateList from "@/pages/Rightsholders/RightsHolderDetail/RightsHolderMandates"
import RightsholderTable from "@/pages/Rightsholders/RightsHolderDetail/RightsholderTable"
import { actionsTabs } from "@/constants"

export default {
  name: "MandateConflictDetail",
  components: {
    RightsHolderMandateList,
    RightsholderTable
  },
  props: {
    rightsholderId: String,
    type: String,
    solvedManually: {
      type: Array,
      required: false,
    }
  },
  mixins: [RightsHolderFieldsMixin],
  data () {
    return {
      actionsTabs,
    }
  },
  computed: {
    ...mapState("rightsholders", ["rightsholderData"]),
    ...mapState("consts", ["countries"]),
    ...mapState("user", ["permissions"]),
  },
  methods: {
    ...mapActions("rightsholders", ["getRightsholderData"]),
  },
  mounted () {
    this.$emit("opened", this.rightsholderId)
    this.getRightsholderData(this.rightsholderId)
  },
  destroyed () {
    this.$emit("closed", this.rightsholderId)
  }
}
</script>

<style scoped>
.rightsholder-details {
  margin-top: 10px;
  border: none;
  background: #fafafa;
}
</style>
