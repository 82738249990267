<template>
  <div>

    <b-table :fields="fields()" :items="items" fixed striped>
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>

      <template v-slot:cell(showDetails)="row">
        <collapse-button v-if="row.item.tariffs.length > 1" :toggle="'row-' + row.item.id" :expanded="expanded[row.index]"/>
      </template>

      <template v-slot:cell(tariffs)="row">
        <b-row align-v="start">
          <b-col cols="1" class="d-flex align-items-center">
            <b-form-checkbox :disabled="!row.item.isEditable" v-model="selected[row.index]" :key="row.item.id" @change="onCheckboxChange(row)"></b-form-checkbox>
          </b-col>
          <b-col>
            <span v-if="showTariffLabel(row)">{{ getTariffLabel(row.item.tariffs) }}</span>
            <b-collapse v-if="row.item.tariffs.length > 1" :id="'row-' + row.item.id" v-model="expanded[row.index]">
             <b-row v-for="tariff in row.item.tariffs" :key="tariff.id">
               <b-col>{{ tariff.name }} <span class="text-gray">({{ getTariffCopyright(tariff) }})</span></b-col>
               <b-col cols="4">
                 <fa-icon size="sm" icon="exclamation-circle" color="red" v-if="tariff.conflict"></fa-icon>
               </b-col>
             </b-row>
            </b-collapse>
          </b-col>
          <b-col v-if="showTariffLabel(row)">
            <fa-icon size="sm" icon="exclamation-circle" color="red" v-if="isConflict(row.item.tariffs)"></fa-icon>
          </b-col>
        </b-row>
      </template>
    </b-table>
  </div>
</template>

<script>
import { capitalize, uniq } from "lodash"
import { mapActions, mapState } from "vuex"
import MandateFieldsMixin from "@/pages/Rightsholders/RightsHolderDetail/MandateFieldsMixin"

export default {
  name: "RightsHolderMandatesTable",
  props: {
    data: Array,
    rightsHolderType: String
  },
  mixins: [MandateFieldsMixin],
  data () {
    return {
      isSource: false,
      expanded: [],
      selected: [],
      selectedMandates: []
    }
  },
  computed: {
    ...mapState("consts", ["countries", "tariffs"]),
    items () {
      return this.data.map(function (m) {
        return {
          id: m.id,
          tariffs: m.tariffs.map((t) => {return { conflict: m.conflicts.find((o) => Object.values(o).includes(t.code)), ...t }}),
          territory: this.getMandateTerritory(m),
          memberCollective: m.membership.memberCollective.name,
          indirectMC: m.membership.indirectMembership ? m.membership.indirectMembership.name : "",
          agent: m.membership.agent ? m.membership.agent.name : "-",
          startDate: m.startDate,
          endDate: m.endDate,
          category: this.getCategory(m),
          isEditable: m.isEditable
        }
      }, this)
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", "getTariffs"]),
    getCountriesList (countries_ids) {
      let findCountry = (id) => this.countries.find((c) => c.value === id)
      return countries_ids.map(function (id) {
        let country = findCountry(id)
        return country ? country.text : null
      }).filter((c) => c !== null)
    },
    isConflict (copyrights) {
      return copyrights.find((c) => c.conflict !== undefined)
    },
    getTariffLabel (tariffs) {
      if (tariffs.length === 1) {
        return tariffs[0].name
      } else if (tariffs.length > 1) {
        let copyrights = uniq(tariffs.map(t => this.getTariffCopyright(t)))
        let copyrightsLabel = copyrights.length === 1 ? `in ${copyrights[0]}`:`in ${copyrights.length} ${this.$t("copyrights")}`
        return `${tariffs.length} ${capitalize(this.$t("tariffs"))} ${copyrightsLabel}`
      }
    },
    getTariffCopyright (tariff) {
      return this.tariffs.find(tt => tt.code === tariff.code).copyright
    },
    showTariffLabel (row) {
      return row.item.tariffs.length === 1 || !this.expanded[row.index]
    },
    onCheckboxChange (row) {
      let selectedMandate = {}
      if (this.selected[row.index] === undefined || this.selected[row.index] === false) {
        selectedMandate = this.data.find((m) => m.id == row.item.id)
      }
      this.selected = this.selected.map(() => false)
      this.$emit("select:mandate", selectedMandate)
    }
  },
  mounted () {
    this.getCountries()
    this.getTariffs()
  }
}
</script>

<style scoped>

::v-deep tbody > tr > td {
  vertical-align: top !important;
}

::v-deep .table-striped th {
  background-color: #ededed;
}

::v-deep .table-striped tbody tr:nth-of-type(2n) {
  background-color: #fafafa;
}

::v-deep .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: white;
}
</style>
