<template>
  <b-container fluid class="h-100">
    <!-- Private pages render here. -->
    <template v-if="userFetched">
      <logged-in-layout>
        <page-not-found v-if="pageNotFound" />
        <router-view v-else></router-view>
      </logged-in-layout>
    </template>

    <!-- Public pages render here. -->
    <template v-else>
      <not-logged-in-layout>
        <router-view></router-view>
      </not-logged-in-layout>
    </template>

    <!-- Alerts will be shown here. -->
    <b-toast
      :id="type"
      :visible="visible"
      @hidden="clearAlert()"
      toaster="b-toaster-bottom-center"
      :variant="type"
      body-class="custom-toast-body"
      auto-hide-delay="10000"
      solid
    >
    <template #toast-title>
      <fa-icon v-if="type==='danger'" :icon="['fas', 'exclamation-circle']" class="text-red mr-3 toast-icon"/>
      <fa-icon v-else :icon="['fas', 'check-circle']" class="darker-green mr-3 toast-icon"/>
      <h4 class="d-flex align-items-center mb-0">{{ type==="success"?'Confirmation':'Errors' }}</h4>
    </template>
      {{ message }}
      <b-row class="justify-content-end mt-4 pr-5">
        <b-button class="btn-outline-red" @click="$bvToast.hide(type)">
          {{ $t("cancel") | capitalize }}
        </b-button>
      </b-row>
    </b-toast>
  </b-container>
</template>

<style lang="scss" scoped>
  .b-toast {
    z-index: 1080; // See _variables.scss for Z-index master list
    max-width: 700px;
    min-width: 350px;
    ::v-deep .toast {
      min-width: 350px;
      max-width: 700px;
      width: fit-content;
      margin: auto;
    }
    .toast-icon{
      height: 28px;
      width: 28px;
    }
  }

  ::v-deep .custom-toast-body {
    text-align: center;
    font-size: 1rem;
    padding: 15px 10px;
    letter-spacing: 1px;
    white-space: pre-line;
  }

  .b-toast-success {
    ::v-deep .custom-toast-body {
      background-color: $green-success;
      color: $primary;
    }
    ::v-deep .toast {
      border-left: 5px solid $green-darker;
      .toast-header {
        background-color: $green-success;
        border-bottom: none;
        padding: 15px 10px;
        color: $primary;
      }
    }
  }

  .b-toast-danger {
    ::v-deep .custom-toast-body {
      background-color: $red-error;
      color: $primary;
    }
    ::v-deep .toast {
      border-left: 5px solid $red;
      .toast-header {
        background-color: $red-error;
        border-bottom: none;
        padding: 15px 10px;
        color: $primary;
      }
    }
  }

  .darker-green {
    color: $green-darker;
  }
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import  PageNotFound from "./pages/PageNotFound"
import Vue from "vue"

export default {
  name: "app",
  components: {
    PageNotFound
  },
  computed: {
    ...mapState("alert", ["message", "type"]),
    ...mapState("user", ["pageNotFound"]),
    ...mapGetters("user", ["userFetched", "canOverrideUserRole"]),
    ...mapGetters("alert", ["visible"]),
  },
  methods: {
    ...mapActions("alert", ["clearAlert"]),
    ...mapActions("consts", ["getUserRoles"]),
    ...mapActions("filters", ["clearFilters"]),
    ...mapActions("user", ["getUser"]),
    ...mapMutations("consts", ["setMemberCollectives"]),
    ...mapActions("userActions", ["getUserActionsSummary"]),
  },
  watch: {
    $route () {
      // clear filters on location change
      this.clearFilters()
    }
  },
  async created () {
    // fetch user info
    const auth = Vue.prototype.$auth
    if (auth.isAuthenticated()) {
      let user = null
      try {
        user = await this.getUser()
      } catch (e) {
        //
      }

      if (user !== null) {
        const members = await this.$api.members.membersList()
        this.setMemberCollectives(members.data)
        if (this.canOverrideUserRole) {
          await this.getUserRoles()
        }
        const token = await this.$auth.getAuthResponse()
        this.$connect(`${process.env.VUE_APP_API_WS_HOST}?token=${token}`)
        await this.getUserActionsSummary({ partial: true })
      } else {
        auth.logout()
      }
    }
  }
}
</script>
