import { Pages } from "@/utils/pages"
import { actionsTabs } from "@/constants"

export const calculatePermission = (number) => {
  if (number === 2) {
    return { canRead: true, canWrite: false }
  }
  if (number === 3) {
    return { canRead: true, canWrite: true }
  }

  return { canRead: false, canWrite: false }
}

export const mapPermissions = (userPermissions) => {
  return {
    pages: {
      [Pages.ConsolidatedUsageList]: calculatePermission(userPermissions.isConsolidatedUsages),
      [Pages.CopyrightsAndTariffs]: calculatePermission(userPermissions.isCopyrightsTariff),
      [Pages.PaymentFilesList]: calculatePermission(userPermissions.isPayments),
      [Pages.RepertoireFilesList]: calculatePermission(userPermissions.isRepUploads),
      [Pages.RepertoireRecordingDetail]: calculatePermission(userPermissions.isSra),
      [Pages.RepertoireRecordingList]: calculatePermission(userPermissions.isSra),
      [Pages.RightsHoldersList]: calculatePermission(userPermissions.isRightsHolders),
      [Pages.Stations]: calculatePermission(userPermissions.isStation),
      [Pages.UsagesFileList]: calculatePermission(userPermissions.isUsageFiles),
      [Pages.UsersList]: calculatePermission(userPermissions.isSystemUser),
      [Pages.UsersUpdate]: calculatePermission(userPermissions.isSystemUser),
      [Pages.MatchingStatistics]: calculatePermission(userPermissions.isMatchingStats),
      [Pages.UserRoles]: calculatePermission(userPermissions.isUserRoles),
      [Pages.DistributionList]: calculatePermission(userPermissions.isDistributions)
    },
    actions: {
      canChallengeOwner: userPermissions.isOwnerChallenge,
      canChallengePerformer: userPermissions.isPerformerChallenge,
      canChallengeRightsHolder: userPermissions.isRightsHolderChallenge,
      canChallengeRecordingSource: userPermissions.isRecordingSourceChallenge,
      canChallengeUsage: userPermissions.isUsageChallengeCreate,
      canDeleteRepertoireFile: userPermissions.isDeleteRepFile,
      canExportRepertoire: userPermissions.isExportRepertoire,
      canExportUsages: userPermissions.isExportUsages,
      canInvalidateShares: userPermissions.isInvalidateShares,
      canMarkAsJunk: userPermissions.isMarkAsJunk,
      canMergeRecording: userPermissions.isMergeRecording,
      canMergeRightsHolders: userPermissions.isMergeRightsHolders,
      canModifyShares: userPermissions.isModifyShares,
      canRematchUsages: userPermissions.isRematchUsages,
      canSeeRepertoireErrors: userPermissions.isRepertoireErrors,
      canSeeDuplicateConflict: userPermissions.isIpnConflict && userPermissions.isIsrcConflict,
      canSeeOtherUsersMatchingStats: userPermissions.isOtherUsersMatchingStats,
      hasAllRhPermission: userPermissions.hasAllRhPermission,
      hasAllUsersPermission: userPermissions.hasAllUsersPermission,
      canModifyAnyComment: userPermissions.canModifyAnyComment,
      canUndoMatching: userPermissions.canUndoMatching,
    },
    actionsTabs: {
      [actionsTabs.ELIGIBILITY_CONFLICTS]: calculatePermission(userPermissions.isEligibilityConflict),
      [actionsTabs.LINEUP_CONFLICTS]: calculatePermission(userPermissions.isPerformerLineup),
      [actionsTabs.MANDATES]: calculatePermission(userPermissions.isUserActionMandates),
      [actionsTabs.MATCHING_CONFLICTS]: calculatePermission(userPermissions.isMatchingConflicts),
      [actionsTabs.NEGATIVE_ADJUSTMENTS]: calculatePermission(userPermissions.isNegativeAdjustments),
      [actionsTabs.RECORDING_CHALLENGES]: calculatePermission(userPermissions.isRecordingChallenges),
      [actionsTabs.RIGHTSHOLDER_CHALLENGES]: calculatePermission(userPermissions.isRightsholderChallenges),
      [actionsTabs.RIGHTS_OWNERSHIP_CONFLICT]: calculatePermission(userPermissions.isOwnershipConflict),
      [actionsTabs.USAGE_CHALLENGES]: calculatePermission(userPermissions.isUsageChallenges),
      [actionsTabs.USAGE_MATCHING]: calculatePermission(userPermissions.isUsageMatching),
      [actionsTabs.MERGE_REQUESTS]: calculatePermission(userPermissions.isRecordingMergeRequest),
    }
  }
}
