<template>
  <ValidationObserver ref="mandateEditForm" v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-row class="mb-3">
        <b-col>
          <include-exclude-selector
              :options="countriesOptions.map(c => { return {'name': c.text, 'value': c.value}})"
              :except-string="$t('ww_except')"
              :include-string="$t('canada_and')"
              :placeholder="$t('select_countries') | capitalize"
              :label="$t('territory') | capitalize"
              label-cols="6"
              v-model="selectedTerritories"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <include-exclude-selector
              :options="getTariffOptions()"
              :except-string="$t('all_except') | capitalize"
              :include-string="$t('in') | capitalize"
              :placeholder="$t('select_tariffs') | capitalize"
              :label="$t('tariffs') | capitalize"
              label-cols="6"
              v-model="selectedTariffs"
              group-label="copyright"
              group-values="tariffs"
              :group-select="true"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <date-field
            label-cols="4"
            :group-label="$t(carveOut ? 'date_from' : 'date_start')  | capitalize"
            class="w-100"
            :name="carveOut ? 'dateFrom' : 'dateStart'"
            v-model="startDate"
            rules="required"
          />
        </b-col>
        <b-col>
          <date-field
            label-cols="4"
            :group-label="$t(carveOut ? 'date_to' : 'date_end')  | capitalize"
            class="w-100"
            :name="carveOut ? 'dateTo' : 'dateEnd'"
            v-model="endDate"
            :rules="{ mandateEndDate: [startDate] }"
          />
        </b-col>
      </b-row>
      <b-row v-show="!isMaker()" >
        <b-col>
          <b-form-group label="Category" label-cols="2">
            <b-form-radio-group
              :options="categories"
              name="plain-inline"
              plain
              v-model="selectedCategory"
              class="mt-2"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="modal-footer mt-4">
        <b-button @click="cancel" class="btn-outline-dark">
          {{ cancelText }}
        </b-button>
        <b-button type="submit" class="btn-outline-red">
          {{ applyText }}
        </b-button>
      </b-row>
    </b-form>
  </ValidationObserver>
</template>
<script>

import { capitalize, groupBy, map } from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import MandateFieldsMixin from "@/pages/Rightsholders/RightsHolderDetail/MandateFieldsMixin"
import { ValidationObserver } from "vee-validate"
import { extend } from "vee-validate"
import { i18n } from "@/utils/i18n"
import { mandateType } from "@/constants"
import moment from "moment"

extend("mandateEndDate", {
  params: ["startDate"],
  validate (value, { startDate }) {
    if (!startDate || !value) {
      return true
    }
    return moment(value) >= moment(startDate)
  },
  message: i18n.t("end_date_should_be_greater_or_equal")
})

export default {
  name: "MandateEditForm",
  props: {
    endpointMethod: {
      type: Function,
      required: true,
    },
    modalId: {
      type: String,
      required: true
    },
    applyText: {
      type: String,
      default: "Save",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    carveOut: {
      type: Boolean,
      default: false
    },
    mandateData: {
      type: Object,
      required: true,
    },
    rightsHolderType: {
      type: String,
      required: true
    },
    rightsholderId: {
      type: String,
      required: false,
    },
    solvedManually: {
      type: Array,
      required: false,
    }
  },
  mixins: [MandateFieldsMixin],
  components: {
    ValidationObserver,
  },
  computed: {
    ...mapState("consts", ["countries", "tariffs"]),
    countriesOptions () {
      if (this.selectedTerritories.except) {
        return this.countries
      }
      return this.countries.filter(c => c.code !== "CA")
    },
    canada () {
      return this.countries.filter(c => c.code === "CA")
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries"]),
    ...mapMutations("alert", ["success", "error"]),
    getTariffOptions () {
      return map(groupBy(this.tariffs, t => t.copyright), (val, key) => {return { copyright: key, tariffs: val.map(t => {return { "name": t.text, "value": t.value }}) }})
    },
    onSubmit () {
      let tariffIds = []
      if (this.selectedTariffs.except) {
        tariffIds = this.tariffs.filter(c => !this.selectedTariffs.values.map(t => t.value).includes(c.value)).map(t => t.value)
      } else {
        tariffIds = this.selectedTariffs.values.map(t => t.value)
      }

      let countries = this.selectedTerritories.values
      countries = this.selectedTerritories.except ? countries : countries.concat(this.canada)
      const data = {
        tariffs: tariffIds,
        countries: countries.map(t => t.value),
        scope: this.selectedTerritories.except ? "worldwide" : "domestic",
      }
      if (!this.isMaker()) {
        data["featured"] = this.selectedCategory === mandateType.ANY || this.selectedCategory === mandateType.FEATURED
        data["non_featured"] = this.selectedCategory === mandateType.ANY || this.selectedCategory === mandateType.NON_FEATURED
      }
      if (this.startDate) {
        data[this.carveOut ? "dateFrom" : "startDate"] = this.startDate
      }
      data[this.carveOut ? "dateTo" : "endDate"] = this.endDate
      let params = this.carveOut ? [data] : [this.mandateData.id, data]

      if (this.rightsholderId && this.solvedManually) {
        this.solvedManually.push(this.rightsholderId)
      }
      this.endpointMethod(...params)
        .then(() => {
          this.success(capitalize(this.$t("saved_changes")))
          this.$bvModal.hide(this.modalId)
          this.$emit("mandate:updated")
        })
        .catch(error => {
          const { data, status } = error.response
          if (status === 400) {
            this.error(capitalize(this.$t("form_contains_errors")))
            this.$refs.mandateEditForm.setErrors(data)
          }
        })
    },
    cancel () {
      this.$bvModal.hide(this.$parent.$parent.id)
    }
  },
  mounted () {
    if (!this.countries.length) {
      this.getCountries()
    }

    this.selectedTariffs = {
      except: false,
      values: this.tariffs.filter(d => this.mandateData.tariffs.map(t => t.id).includes(d.value)).map(k => { return { "name": k.text, "value": k.value }}),
    }

    const except = this.mandateData.scope === "worldwide"
    const ca = this.canada[0].value
    const allCountries = this.countries
    const mandateCountries = this.mandateData.countries
    const countries = except ? allCountries.filter(d => mandateCountries.includes(d.value)) : allCountries.filter(d => mandateCountries.includes(d.value) && d.value !== ca)
    this.selectedTerritories = {
      except: except,
      values: countries.map(c => { return { "name": c.text, "value": c.value }}),
    }
    if (this.mandateData.featured && this.mandateData.nonFeatured) {
      this.selectedCategory = mandateType.ANY
    } else if (this.mandateData.featured) {
      this.selectedCategory = mandateType.FEATURED
    } else {
      this.selectedCategory = mandateType.NON_FEATURED
    }

  },

  data () {
    return {
      selectedTerritories: {
        except: false,
        values: [],
      },
      selectedTariffs: {
        except: false,
        values: [],
      },
      startDate: this.mandateData.startDate,
      endDate: this.mandateData.endDate,
      categories: [
        { "text": "Featured", "value": mandateType.FEATURED },
        { "text": "Non-Featured", "value": mandateType.NON_FEATURED },
        { "text": "Featured and Non-Featured", "value": mandateType.ANY },
      ],
      selectedCategory: mandateType.FEATURED,
    }
  }
}
</script>
