import { API } from "./utils"


export const rightsholders = {
  rightsholderBatchesList: (params = null) => API.get("/parties/rightsholder-batch/",
    { params: params }),
  rightsholderBatchErrorsList: (uuid, params = null) => API.get(`/parties/rightsholder-batch/${uuid}/errors/`, { params }),
  rightsholdersList: (params=null) => API.get("/parties/rights-holders/",
    { params: params }),
  landingPerformersList: (params = null) => API.get("/parties/landing-performers/", { params, }),
  landingMakersList: (params = null) => API.get("/parties/landing-makers/", { params, }),
  rightsholdersDetail: (uuid, params=null) => API.get(`/parties/rights-holders/${uuid}/`, { params }),
  landingRightsholderDetail: (uuid, params=null) => API.get(`/parties/rights-holders/${uuid}/general_detail/`, { params }),
  rightsholdersUpdate: (uuid, data) => API.put(`/repertoire/recordings/${uuid}/performers/`, data),
  performerChallengesList: (params=null) => API.get("/user-actions/performer-challenges/", { params }),
  performerChallengeRetrieve: (uuid, params=null) => API.get(`/user-actions/performer-challenges/${uuid}/`, { params }),
  makerChallengeRetrieve: (uuid, params=null) => API.get(`/user-actions/maker-challenges/${uuid}/`, { params }),
  makerChallengesList: (params=null) => API.get("/user-actions/maker-challenges/", { params }),
  rightsholdersMandatesList: (uuid, params=null) => API.get(`/parties/rights-holders/${uuid}/mandates/`, { params }),
  rightsholdersMandatesUpdate: (uuid, data) => API.patch(`/parties/mandate/${uuid}/`, data),
  rightsholdersMandatesMetadataList: (uuid, params=null) => API.get(`/parties/rights-holders/${uuid}/mandates-metadata/`, { params }),
  rightsholdersRepertoireList: (uuid, params=null) => API.get(`/parties/rights-holders/${uuid}/repertoire/`, { params }),
}
