import camelcaseKeys from "camelcase-keys"
import { store } from "@/store"

const state = {
  channelName: "",
  wsMandateConflictStatuses: {},
  wsUserActionStatuses: {},
  wsLineupConflictStatuses: {},
  wsOwnershipConflictStatuses: {},
  wsRecordingEligibilityConflictStatuses: {},
  wsUniqueUsageMatchingStatuses: {},
  wsRecordingLineupNegativeAdjustmentStatuses: {},
  wsRecordingOwnershipNegativeAdjustmentStatuses: {},
  wsIsrcConflictStatus: {},
  wsIpnConflictStatus: {},
}

const mutations = {
  updateChannelName (state, data) {
    state.channelName = data
  },
  updateActionStatuses (state, data) {
    let state_copy = JSON.parse(JSON.stringify(state[data.type]))
    state_copy[data.id] = {
      status: data.status,
      channel: data.channel
    }
    // Workaround to trigger watches and UI changes, deep handlers don't work directly
    state[data.type] = state_copy
  },
  resetState (state, key=null) {
    if (key) {
      state[key] = {}
    } else {
      Object.assign(state, {
        channelName: state.channelName,
        wsMandateConflictStatuses: {},
        wsUserActionStatuses: {},
        wsLineupConflictStatuses: {},
        wsOwnershipConflictStatuses: {},
        wsRecordingEligibilityConflictStatuses: {},
        wsUniqueUsageMatchingStatuses: {},
        wsRecordingLineupNegativeAdjustmentStatuses: {},
        wsRecordingOwnershipNegativeAdjustmentStatuses: {},
        wsIsrcConflictStatus: {},
        wsIpncConflictStatus: {},
      })
    }
  }
}

const actions = {
  channelName (context, event) {
    context.commit("updateChannelName", event.data.channel)
  },
  mandateConflictStatus (context, event) {
    let update_data = {
      id: event.data["rights_holder_id"],
      type: "wsMandateConflictStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },
  userActionStatus (context, event) {
    let update_data = {
      id: event.data["user_action_id"],
      type: "wsUserActionStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },
  lineupConflictStatus (context, event) {
    let update_data = {
      id: event.data["recording_id"],
      type: "wsLineupConflictStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },
  ownershipConflictStatus (context, event) {
    let update_data = {
      id: event.data["recording_id"],
      type: "wsOwnershipConflictStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },
  recordingEligibilityConflictStatus (context, event) {
    let update_data = {
      id: event.data["conflict_id"],
      type: "wsRecordingEligibilityConflictStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },
  uniqueUsageStatus (context, event) {
    let update_data = {
      id: event.data["unique_usage_id"],
      type: "wsUniqueUsageMatchingStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },
  recordingLineupNegativeAdjustmentStatus (context, event) {
    let update_data = {
      id: event.data["recording_id"],
      type: "wsRecordingLineupNegativeAdjustmentStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },

  isrcConflictStatus (context, event) {
    let update_data = {
      id: event.data["recording_id"],
      conflicted_ipn: event.data["conflicted_ipn"],
      type: "wsIsrcConflictStatus",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },

  ipnConflictStatus (context, event) {
    let update_data = {
      id: event.data["performer_id"],
      conflicted_isrc: event.data["conflicted_isrc"],
      type: "wsIpnConflictStatus",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },

  recordingOwnershipNegativeAdjustmentStatus (context, event) {
    let update_data = {
      id: event.data["recording_id"],
      type: "wsRecordingOwnershipNegativeAdjustmentStatuses",
      status: event.data.status,
      channel: event.data.channel
    }
    context.commit("updateActionStatuses", update_data)
  },
  userActionsSummary ({ dispatch }, event) {
    dispatch("actionTabs/setUserActionCounts", camelcaseKeys(event.data, { deep: true }), { root: true })
  },
  subscribeUserActions (context) {
    // Subscribe to user action notifications each time the socket is connected
    this.unwatch = store.watch(state => state.websocket.socket.connected, newValue => {
      if (newValue) {
        context.dispatch("sendMessage", {
          "type": "user_action.subscribe",
        }, { root: true })
      }
    }, { immediate: true })
  },
  unsubscribeUserActions (context) {
    // unwatch and unsubscribe
    if (this.unwatch !== undefined) {
      this.unwatch()
    }
    context.dispatch("sendMessage", {
      "type": "user_action.unsubscribe",
    }, { "root": true })
  },
  notifyUserAction (context, data) {
    context.dispatch("sendMessage", {
      "type": data.type,
      "data": {
        [data.action_identifier]: data.id,
        "status": data.status,
        "channel": context.state.channelName,
        ...data.extra
      }
    }, { root: true })
  },
  getUserActionsSummary (context, data) {
    // Subscribe to user action notifications each time the socket is connected
    this.unwatch = store.watch(state => state.websocket.socket.connected, newValue => {
      if (newValue) {
        context.dispatch("sendMessage", {
          "type": "user_action.summary.subscribe",
          "data": data
        }, { root: true })
      }
    }, { immediate: true })
  },
}

export const userActions = {
  namespaced: true,
  state,
  mutations,
  actions
}
