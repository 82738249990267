<template>
  <span class="go-back">
    <fa-icon @click="goBack" size="lg" :icon="['fas', 'angle-left']" />
  </span>
</template>

<script>

export default {
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  },
}
</script>

<style scoped>
.go-back {
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
  padding-left: 15px;
}
</style>