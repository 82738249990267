<template>
  <div>
    <b-col>
      <div>
        <div class="mb-2 flex-fixed">
          <b-button
            v-show="selectedObjects.length"
            :disabled="selectedObjects.length < 2"
            class="btn-outline-red"
            @click="merge()"
          >
            {{ buttonText }}
          </b-button>
          <span v-for="object in selectedObjects" class="mt-1 ml-2" :key="object.id">
            <b-badge>
              <fa-icon
                size="sm"
                :icon="['fa', 'times']"
                @click="removeObjectFromCart(object)"
                style="cursor: pointer;"
              />
              {{ object[badgeDisplayField] }}
            </b-badge>
          </span>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "MergeButton",
  props: {
    selectedObjects: {
      type: Array,
      required: true,
    },
    merge: {
      type: Function,
      required: true,
    },
    cart: {
      type: String,
      required: true
    },
    remove: {
      type: Function,
      required: true
    },
    buttonText: {
      type: String,
      required: true,
    },
    badgeDisplayField: {
      type: String,
      required: true,
    },
  },
  methods: {
    removeObjectFromCart (object) {
      let payLoad = {
        cart: this.cart,
        data: object
      }

      this.remove(payLoad)
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  font-size: 75% !important;
}
</style>
