<!-- Based On: https://codesandbox.io/s/validation-components-bootstrapvue-usdwv -->
<template>
  <ValidationProvider :vid="$attrs.name" :name="$attrs.name" :rules="rules" v-slot="{ errors }">
    <transition name="fade" mode="out-in">
      <b-form-group
        v-bind="$attrs"
        :invalid-feedback="errors[0]"
        :label="null"
      >
        <label v-if="$attrs.name && $attrs.label" :for="$attrs.name">
          {{ $attrs.label | capitalize }}: <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <b-input-group>
          <b-spinner v-if="spinner && loading" variant="light" class="spinner" small/>
          <b-input-group-prepend v-if="icon">
            <span class="input-group-text"><fa-icon class="mr-0" :icon="icon"/></span>
          </b-input-group-prepend>
          <b-form-input
            ref="input"
            :id="$attrs.name"
            v-model="innerValue"
            v-bind="$attrs"
            @keydown.native="keyHandler"
            :state="errors[0] ? false : null"
          />
          <b-input-group-append v-if="cancelIcon">
            <span class="input-group-text"><fa-icon class="mr-0 clickable" :icon="cancelIcon" @click="onCancelIconClick"/></span>
          </b-input-group-append>
          <b-input-group-append v-if="rightIcon">
            <span class="input-group-text"><fa-icon class="mr-0 clickable" :icon="rightIcon" @click="onRightIconClick"/></span>
          </b-input-group-append>
        </b-input-group>
        <b-form-text v-if="helpText">{{ helpText }}</b-form-text>
        <b-form-invalid-feedback :state="!errors[0]">{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </transition>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 11pt;
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "FormField",
  components: {
    ValidationProvider
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    icon: {
      type: Array,
      default: null
    },
    helpText: String,
    rightIcon: {
      type: Array,
      default: null
    },
    cancelIcon: {
      type: Array,
      default: null
    },
    enterAction: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  computed: {
    isRequired () {
      return this.rules ? this.rules.split("|").includes("required") : false
    }
  },
  methods: {
    keyHandler (event) {
      if (this.enterAction && event.which === 13) {
        this.enterAction()
      }
    },
    onRightIconClick () {
      this.$emit("right-icon-clicked")
    },
    onCancelIconClick () {
      this.$emit("cancel-icon-clicked")
    },
    focus () {
      this.$refs.input.focus()
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .spinner {
    position: absolute;
    top: 30%;
    right: 2%;
    z-index: 99;
  }
</style>
