<template>
  <div>
    <v-server-table
      ref="ipnConflicts"
      :columns=columns
      :options=options
      @row-click="selectConflict"
      @loaded="onLoaded"
      @loading="onLoading"
      @pagination="onPagination"
    >
      <template v-slot:inProgress="{ row }">
        <fa-icon
          v-if="isInProgressFrontend(row.id) || (isInProgressBackend([row.status]) && !isReleasedFrontend(row.id))"
          :icon="['fa', 'user-edit']"
          color="red"
        />
      </template>
      <!-- resoundId-->
      <template v-slot:resoundId="{ row }">
        <database-link :type="linkTypes.RIGHTS_HOLDER" :item-id="row.id">{{ row.resoundId }}</database-link>
      </template>
      <!-- created-->
      <template v-slot:created="{ row }">
        {{ parseDate(row.created, $config.DATE_FORMAT) }}
      </template>
      <template v-slot:status="{ row }">
        <status
          v-if="(
            isInProgressFrontend(row.id) ||
            (isInProgressBackend(row.status) && !isReleasedFrontend(row.id))
          ) && !isSolved(row.id)"
          :text="$t('in_progress') | capitalize"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-else-if="(!row.resolved || isReleasedFrontend(row.id)) && !isSolved(row.id)"
          :text="$t('conflict') | capitalize"
          :icon="['fas', 'exclamation-circle']"
          color="red"
        />
        <status
          v-else
          :text="$t('resolved') | capitalize"
          :icon="['fas', 'check-circle']"
          color="green"
        />
      </template>
    </v-server-table>
  </div>
</template>

<script>

import { get, startCase } from "lodash"
import DatabaseLink from "@/components/DatabaseLink.vue"
import { linkTypes } from "@/constants"
import { mapState } from "vuex"
import parseDate from "@/utils/date-parser"
import { userActionNotifications } from "@/constants"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "DuplicateIpnConflicts",
  components: {
    DatabaseLink,
  },
  mixins: [websocketsMixin],
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      linkTypes,
      data: [],
      selectedConflict: null,
      columns: [
        "inProgress",
        "resoundId",
        "name",
        "conflictedIpn",
        "royaltiesPaid",
        "weightedPlayCount",
        "created",
        "status",
      ],
      options: {
        sortable: ["name", "created"],
        rowClassCallback: (row) => {
          let classes = ["hover-highlight"]
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          return classes.join(" ")
        },
        headings: {
          inProgress: "",
          resoundId: startCase(this.$t("resound_id")),
          name: startCase(this.$t("rightsholder_name")),
          conflictedIpn: startCase(this.$t("conflicted_ipn")),
          royaltiesPaid: startCase(this.$t("royalties_paid")),
          weightedPlayCount: startCase(this.$t("weighted_playcount")),
          created: startCase(this.$t("created")),
          status: startCase(this.$t("status")),

        },
        columnsClasses: {
          "inProgress": "width-2",
          "resoundId": "width-small",
          "name": "width-large",
          "ipn": "width-small",
          "conflictedIpn": "width-small",
          "royaltiesPaid": "width-mini",
          "weightedPlayCount": "width-mini",
          "created": "width-mini",
          "status": "width-mini",
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          queryParams = { ...this.$route.query, ...queryParams  }
          this.page = Number(get(queryParams, "page", 1))
          return this.$api.actions.duplicatedIpn(queryParams)
        },
      },
      // WS Values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.IPN_CONFLICT,
      storeStatusesKey: "wsIpnConflictStatus",
    }
  },
  methods: {
    setLimit (limit) {
      this.$refs.ipnConflicts.setLimit(limit)
    },
    getData () {
      this.$refs.ipnConflicts.getData()
    },
    onLoaded (data) {
      this.$emit("onLoaded", data)
    },
    onLoading () {
      this.$emit("onLoading")
      this.selectedConflict = null
    },
    onPagination (page) {
      this.$emit("onPagination", page)
    },
    onSorted (data) {
      this.$emit("onSorted", data)
    },
    selectConflict (conflict) {
      this.selectedConflict = conflict ? conflict.row || conflict : null
      this.$emit("selected", this.selectedConflict)
    },
    parseDate
  },
  computed: {
    ...mapState("userActions", ["wsIpnConflictStatus",]),

    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
  },
  watch: {
    selectedConflict (newVal, oldVal) {
      if (oldVal) {
        this.onClosed(
          oldVal.id,
          { "conflicted_ipn": oldVal.conflictedIpn }
        )
      }
      if (newVal) {
        this.onOpened(
          newVal.id,
          { "conflicted_ipn": newVal.conflictedIpn }
        )
      }
    }
  },
  mounted () {
    this.page = Number(get(this.$route.query, "page", 1))
    this.perPage = Number(get(this.$route.query, "limit", this.perPage))
    // We need to mount both watchers by hand
    this.$watch("wsIpnConflictStatus", this.statusWatcher, { deep: true })
  }
}
</script>
<style lang="scss" scoped>
.table-responsive {
  .table {
    tr {
      &.hover-highlight {
        &:hover {
          cursor: pointer;
          background-color: $red-light;
        }
      }
    }
  }
}
</style>
