<template>
  <div>
    <t-table
      id="performer-sources"
      ref="table"
      v-if="performers"
      :local-data="performers"
      :options="options"
      :columns="columns"
      :limit="limit"
      @pagination="onPage"
    >
      <template v-slot:counts>
        <pagination
          class="smaller p-0"
          :page="page"
          :count="performers.length"
          :loading="false"
          v-model="limit"
          @limit="onLimit"
        />
      </template>
      <template v-slot:merge>
        <b-form-checkbox
          v-if="user.selectedMember.name === RESOUND"
          switch
          v-model="showRepTracks"
        >
          {{ $t("show_rep_tracks") }}
        </b-form-checkbox>
      </template>
      <template v-slot:category="{ row }">
        {{ row.featured ? performerCategoryTypes.FEATURED : performerCategoryTypes.NON_FEATURED | capitalize }}
      </template>
      <template v-slot:status="{ row }">
        <span v-if="row.isRepTrack" class="text-muted">
          {{ $t("rep_track") }}
        </span>
        <span v-else-if="!row.fromPerformerSource" class="text-muted" :title="$t('from_maker_source')">
          {{ $t("informational") | capitalize }}
        </span>
        <span v-else-if="row.performer && noChallengesPending(row)" class="text-green">
          {{ $t("matched") | capitalize }}
        </span>
        <span v-else-if="row.performer">
          <router-link class="text-warning" :to="{
              name: 'rightsholder-challenge',
              params: {
                standalone: true,
                uuid: row.matchingChallengeId,
                challengeType: rightsholderTypes.PERFORMER,
              },
            }">
            {{ $t("challenged") | capitalize }}
          </router-link>
        </span>
        <span v-else>
          <router-link
            v-if="row.fromPerformerSource && row.hasMetadataConflict"
            class="text-secondary"
            :to="{
              name: 'manual-matching-detail',
              params: {
                uuid: row.publicId,
              },
              query: {
                type: metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT,
              }
            }"
          >
            {{ $t("unmatched") | capitalize }}
          </router-link>
        </span>
      </template>
      <template v-slot:common="{ row }">
        <div v-if="!row.isRepTrack && row.fromPerformerSource">
          <fa-icon :icon="['fa', 'check-circle']" v-if="row.common" class="text-green"/>
          <fa-icon :icon="['fa', 'times-circle']" v-else-if="row.performer || row.hasMetadataConflict" class="text-secondary"/>
        </div>
      </template>
      <template v-slot:resoundId="{ row }">
        <database-link v-if="row.performer" :type="linkTypes.RIGHTS_HOLDER" :item-id="row.performer.id">
          {{ row.performer.resoundId }}
        </database-link>
      </template>
      <template v-slot:dateOfBirth="{ row }">
        {{ row.dateOfBirth }}
      </template>
      <template v-slot:dateOfDeath="{ row }">
        {{ row.dateOfDeath }}
      </template>
      <template v-slot:nationalities="{ row }">
        <NamesListModal :values="row.nationalities" v-if="row.nationalities"/>
      </template>
      <template v-slot:aliases="{ row }">
        <NamesListModal :values="row.performer.aliases" v-if="row.performer"/>
      </template>
      <template v-slot:sources="{ row }">
        <span v-if="row.repertoireFiles" class="text-info">
          {{ row.repertoireFiles.length }}
          <fa-icon
            :title="$t('expand_all_sources') | capitalize"
            @click.stop="showSources(row.repertoireFiles)"
            class="blue clickable ml-2 more-values-icon"
            size="sm"
            :icon="['fas', 'plus-circle']"
          >
          </fa-icon>
        </span>
      </template>
      <template v-slot:actions="{ row }" v-if="editModePerformers && permissions.pages[Pages.RepertoireRecordingDetail].canWrite">
        <b-button size="sm" v-if="showAddButton(row)" class="btn-outline-red" @click="addPerformer(row)">{{ $t("add") | capitalize }}</b-button>
      </template>

    </t-table>
    <b-modal 
      id="sourcesModal" 
      ref="sourcesModal" 
      modal-class="custom-modal info"
      size="xl"
      centered
      hide-footer>
      <template #modal-title>
        <h4 class="d-flex align-items-center mb-0">
          <fa-icon :icon="['fas', 'exclamation-circle']" class="icon-blue mr-2"/>
          {{ $t('sources') | capitalize }}
        </h4>
      </template>
      <SourcesList
        :sources="currentSources"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  RESOUND,
  landingRecordingReconciliationStatuses,
  linkTypes,
  metadataConflictTypeCodes,
  perPageItemsSm,
  performerCategoryTypes,
  rightsholderTypes,
  userActionStatuses
} from "@/constants"
import { mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import { Pages } from "@/utils/pages"
import Pagination from "@/components/Pagination"
import SourcesList from "@/components/SourcesList"
import { capitalize } from "lodash"
import { getSubGenre } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import parseDate  from "@/utils/date-parser"

export default {
  name: "PerformerSources",
  components: { DatabaseLink, NamesListModal, Pagination, SourcesList },
  props: {
    sources: {
      type: Array,
      required: true
    },
    categoryTypes: {
      type: Array,
      default: () => [],
    },
    recordingId: {
      type: String,
    }
  },
  data () {
    return {
      performerCategoryTypes,
      linkTypes,
      metadataConflictTypeCodes,
      rightsholderTypes,
      Pages,
      RESOUND,
      currentSources: null,
      performers: null,
      showRepTracks: false,
      page: 1,
      limit: perPageItemsSm,
      options: {
        hiddenColumns: ["ipn", "gender", "dateOfBirth", "dateOfDeath", "role", "aliases", "nationalities", "genre"],
        sortable: ["memberCollective", "name", "status", "common", "resoundId", "numberOfPerformers", "ipn", "gender", "dateOfBirth", "dateOfDeath", "role", "genre"],
        sorters: {
          category (a, b) {
            return b.featured - a.featured
          },
          ipn: "string",
          gender: "string",
          role: "string",
        },
        permanentSort: {
          column: "category",
          dir: "asc",
        },
        initialSort: [
          {
            column: "name",
            dir: "asc",
          },
        ],
        rowClasses (row) {
          return [row.featured ? "featured" : "non-featured"]
        },
        width: {
          actions: 80,
          sources: 50,
        },
        headings: {
          memberCollective: capitalize(this.$t("collective")),
          name: capitalize(this.$t("name")),
          category: capitalize(this.$t("category")),
          status: capitalize(this.$t("status")),
          common: capitalize(this.$t("common")),
          resoundId: this.$t("id"),
          numberOfPerformers: this.$t("number_of_performers"),
          ipn: this.$t("ipn"),
          gender: capitalize(this.$t("gender")),
          dateOfBirth: capitalize(this.$t("date_of_birth")),
          dateOfDeath: capitalize(this.$t("date_of_death")),
          nationalities: capitalize(this.$t("nationalities")),
          role: capitalize(this.$t("role")),
          aliases: capitalize(this.$t("aliases")),
          genre: capitalize(this.$t("genre"))
        }
      }
    }
  },
  computed: {
    ...mapState("user", ["permissions", "user"]),
    ...mapState("repertoire", [
      "editModePerformers",
      "recordingPerformers",
      "performersUpdate",
    ]),
    updateDict: {
      get () {
        return this.performersUpdate
      },
      set (value) {
        this.setPerformersUpdate(value)
      },
    },
    columns () {
      let columns = [
        "memberCollective",
        "name",
        "category",
        "status",
        "common",
        "resoundId",
        "numberOfPerformers",
        "sources",
        "ipn",
        "gender",
        "dateOfBirth",
        "dateOfDeath",
        "nationalities",
        "role",
        "aliases",
        "genre",
      ]
      if (this.permissions.actions.canChallengePerformer && this.challengesEnabled || this.editModePerformers && this.permissions.pages[Pages.RepertoireRecordingDetail].canWrite) {
        columns.push("actions")
      }
      return columns

    },
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    noChallengesPending (row) {
      return ![
        userActionStatuses.PENDING,
        userActionStatuses.IN_PROGRESS,
      ].includes(row.matchingChallengeStatus)
    },
    preparePerformers () {
      let performers = []
      this.sources.forEach(source => {
        if (!this.showRepTracks && source.reconciliationStatus === landingRecordingReconciliationStatuses.NOT_READY) {
          return
        }
        if (!this.showRepTracks && !source.fromPerformerSource) {
          return
        }
        source.landingPerformers.forEach(lp => {
          lp.numberOfPerformers = lp.featured ? source.lineupInfo.numberOfFeaturedPerformers : source.lineupInfo.numberOfNonFeaturedPerformers
          lp.memberCollective = lp.sender.name
          lp.resoundId = lp.performer ? lp.performer.resoundId : null
          lp.gender = lp.performer ? lp.performer.gender : lp.gender
          lp.ipn = lp.performer ? lp.performer.ipn : lp.ipn
          lp.dateOfBirth = parseDate(lp.performer ? lp.performer.dateOfBirth : (lp.dateAndPlaceOfBirth ? lp.dateAndPlaceOfBirth.date : null), this.$config.DATE_FORMAT)
          lp.dateOfDeath = parseDate(lp.performer ? lp.performer.dateOfDeath : (lp.dateAndPlaceOfDeath ? lp.dateAndPlaceOfDeath.date : null), this.$config.DATE_FORMAT)
          lp.nationalities = lp.performer ? lp.performer.nationalities.map(n => n.alpha2) : lp.nationalities
          lp.status = lp.performer ? (this.noChallengesPending(lp) ? "m" : "c") : "u"
          lp.repertoireFiles = source.repertoireFiles
          lp.fromPerformerSource = source.fromPerformerSource
          lp.isRepTrack = source.reconciliationStatus === landingRecordingReconciliationStatuses.NOT_READY
          lp.genre = source.genres.map((genreData) => {
            let genre = genreData.genre
            if (genreData.subGenre) {
              let subGenre = getSubGenre(genreData.subGenre) || genreData.subGenre
              genre = `${genre} (${subGenre})`
            }
            return genre
          })
          performers.push(lp)
        })
      })
      this.performers = performers
      this.performers.forEach(lp => {
        lp.common = this.performerIsCommon(lp)
      })
    },
    performerIsCommon (landingPerformer) {
      if (!landingPerformer.performer) {
        return false
      }
      const performerId = landingPerformer.performer.id
      let commonSources = new Set()
      let sources = new Set()
      let mcSources = new Set()
      let hasMultipleSources = true
      this.sources.forEach(source => {
        if (source.fromPerformerSource && source.reconciliationStatus === landingRecordingReconciliationStatuses.READY) {
          hasMultipleSources = false
          source.landingPerformers.forEach(lp => {
            if (lp.featured === landingPerformer.featured) {
              sources.add(source.memberCollectiveName)
              mcSources.add(source.memberCollectiveName)
              if (lp.performer && lp.performer.id === performerId) {
                commonSources.add(source.memberCollectiveName)
              }
            }
          })
        }
      })

      if (hasMultipleSources) {
        return ""
      }

      if (mcSources.size > 1) {
        return sources.size === commonSources.size
      }
      
      return false
    },
    showAddButton (row) {
      let type = row.featured ? "F" : "N"
      return (
        this.permissions.pages[Pages.RepertoireRecordingDetail].canWrite &&
        this.categoryTypes.includes(type) &&
        row.performer &&
        row.performer.id &&
        !this.inLineup(row.performer.id, this.categoryTypes) &&
        (
          this.numberOfPerformers(row.featured) === null ||
          this.numberOfPerformers(row.featured) &&
          this.lineupPerformers(row.featured).length < this.numberOfPerformers(row.featured)
        )
      )
    },
    addPerformer (row) {
      let actualCategory = this.updateDict.lineupCategories.find(c => c.type === (row.featured ? "F" : "N"))
      actualCategory.lineup.push({
        ...row.performer,
        role: row.role,
      })
    },
    getActualCategory (id, categoryTypes, originalDict) {
      const sourceData =
        this.updateDict.lineupCategories && !originalDict
          ? this.updateDict.lineupCategories
          : this.recordingPerformers[this.recordingId].lineupCategories
      return sourceData.find(c => {
        return categoryTypes.length
          ? categoryTypes.includes(c.type) && c.lineup.some(p => p.id === id)
          : c.lineup.some(p => p.id === id)
      })
    },
    inLineup (performerId, categoryTypes, originalDict = false) {
      return this.getActualCategory(performerId, categoryTypes, originalDict)
    },
    numberOfPerformers (featured=true) {
      let type = featured ? "F" : "N"
      let data = this.updateDict.lineupCategories ? this.updateDict.lineupCategories : this.recordingPerformers[this.recordingId].lineupCategories
      return data.find(c => c.type === type).numberOfPerformers
    },
    lineupPerformers (featured=true) {
      let type = featured ? "F" : "N"
      const sourceData = this.editModePerformers ? this.updateDict : this.recordingPerformers[this.recordingId]
      return sourceData && sourceData.lineupCategories
        ? sourceData.lineupCategories.find(c => c.type === type).lineup
        : []
    },
    showSources (sources) {
      this.currentSources = sources
      this.$refs.sourcesModal.show()
    },
    onLimit (limit) {
      this.limit = limit
    },
    onPage (page) {
      this.page = page
    }
  },
  watch: {
    sources () {
      this.preparePerformers()
    },
    updateDict: {
      handler () {
        this.preparePerformers()
      },
      deep: true
    },
    showRepTracks () {
      this.preparePerformers()
      this.$emit("showRepTracks", this.showRepTracks)
    }
  },
  mounted () {
    this.preparePerformers()
  }
}
</script>
<style lang="scss">
  .tabulator-row.featured:has(+.non-featured) {
    margin-bottom: 30px;
  }
</style>
