<template>
  <div class="nav-container">
  <div>
    <div class="top-container" :class="isCollapsed?'sb-collapsed':'sb-expanded'">
      <b-navbar-brand class="mt-2 mb-2" :class="{'pl-3': isCollapsed}" id="logo" href="#">
        <b-img
          :src="this.isCollapsed ? require('@/assets/crescendo_logo_collapsed.svg') : require('@/assets/crescendo_logo.svg')"/>
        <div v-if="!isCollapsed" class="icon-wrapper mt-2 mr-1" @click="collapse(true)">
          <fa-icon size="lg" :icon="['fa', 'angle-left']" />
        </div>
      </b-navbar-brand>
      <div class="split-line"></div>
    </div>
    <div class="middle-container" @click="collapse(false)" :style="{'padding-bottom': canOverrideUserRole ? '250px' : '110px' }">
      <b-nav class="menu mt-3" >
        <div v-for="section in visibleSections" :key="section.id" class="w-100">
          <!-- if section has children -->
          <div v-if="section.isParent">
            <b-nav-item @click="toggleExpanded(section.id, $event)" class="mb-2">
              <div class="d-flex align-items-center sidebar-item justify-content-center text-nowrap">
                <div class="icon-wrapper" :class="{ active: isActiveSection(section, child=true) && isCollapsed, 'ml-2 mr-3': isCollapsed }">
                  <fa-icon size="lg" :icon="section.icon" />
                </div>
                <div :class="{hide : isCollapsed}" class="action">
                  <span class="ml-2">{{ section.name | startcase }}</span>
                  <span v-if="section.count" class="flex-grow-1 ml-4">
                    {{ section.count }}
                  </span>
                  <div class="icon-wrapper mr-1 pl-1">
                    <fa-icon v-if="expanded === section.id" size="lg" :icon="['fa', 'angle-right']" />
                    <fa-icon v-else size="lg" :icon="['fa', 'angle-down']" />
                  </div>
                </div>
              </div>
            </b-nav-item>
            <b-collapse :visible="showChildSection(section)" :class="{'ml-3': !isCollapsed}">
              <b-nav-item v-for="child in visibleChildren(section)" :key="child.id" 
                :to="sideNavRoute(section,child)"
                exact class="mb-2">
                <div class="d-flex align-items-center sidebar-item">
                  <div class="icon-wrapper" :class="{ active: isActiveSubSection(child, section) }">
                    <fa-icon size="lg" :icon="child.icon" />
                  </div>
                  <div class="d-flex w-100 section-name">
                    <span class="sub-title ml-2" :class="{ active: isActiveSubSection(child, section) }">
                      {{ child.name }}
                    </span>
                    <div class="d-flex">
                      <div class="icon-wrapper ml-2" @click="setLandingPage(section,child)">
                        <fa-icon v-if="isLandingPage(section,child)" class="star active show" size="lg" :icon="['fa', 'star']"/>
                        <fa-icon v-else class="star" size="lg" :icon="['far', 'star']"/>
                      </div>
                      <span class="active">{{ child.actionsCount }}</span>
                    </div>
                  </div>
                </div>
              </b-nav-item>
            </b-collapse>
          </div>
          <!-- if section hasn't children -->
          <b-nav-item v-else :to="!isCollapsed ? {
              name: section.id,
              params: {...section.routeParams, ...{sidebar: true}},
              query: section.query
            } : '' " 
            @click="toggleExpanded(section.id, $event)" exact class="mb-2">
            <div class="d-flex align-items-center sidebar-item justify-content-center text-nowrap">
              <div class="icon-wrapper" :class="{ active: isActiveSection(section, child=false), 'ml-2 mr-3': isCollapsed }">
                <fa-icon size="lg" :icon="section.icon" />
              </div>
              <div :class="{hide : isCollapsed}" class="d-flex w-100">
                <span class="title ml-2" :class="{ active: isActiveSection(section, child=false) }">
                {{ section.name | startcase }}
                </span>
                <div class="icon-wrapper ml-1 mr-5" @click="setLandingPage(section)">
                  <fa-icon v-if="isLandingPage(section)" class="star active show" size="lg" :icon="['fa', 'star']"/>
                  <fa-icon v-else class="star" size="lg" :icon="['far', 'star']"/>
                </div>
              </div>
            </div>
          </b-nav-item>
        </div>
      </b-nav>
    </div>
  </div>
  <div class="bottom-container-wrapper">
    <div class="bottom-container" :class="isCollapsed?'sb-collapsed':'sb-expanded'">
      <div class="split-line mb-3"></div>
      <nav-user-menu :isCollapsed="isCollapsed" :collapse="collapse" :toggleExpanded="toggleExpanded" :expanded="expanded"/>
      <div class="footer" :class="isCollapsed?'footer-collapsed':'footer-expanded'">
        <div class="bottom-logo center">
          <div class="icon-wrapper">
            <b-img :src="require('@/assets/resound_logo.svg')"/>
          </div>
        </div>
        <div class="icon-wrapper help" :class="isCollapsed?'ml-3 mr-3 mb-3':'mt-2 mr-2 pr-1'">
          <b-dropdown id="help-menu-popup" variant="link" dropup no-caret toggle-class="px-0 py-0">
            <template #button-content>
              <fa-icon size="lg" :icon="['far', 'question-circle']" />
            </template>
            <div class="list-element" v-for="(helpEl, index) in visibleHelpMenuElements" :key="index" >
              <component
                :is="'b-dropdown-' + helpEl.ddComponentType"
                :href="helpEl.link" :target="helpEl.openInNewTab ? '_blank' : '_self'"
                :class="helpEl.indentation ? 'indentation-'+helpEl.indentation : ''"
                :disabled="helpEl.disable">
                <div class="icon-wrapper" v-if="helpEl.icon">
                  <fa-icon :icon="helpEl.icon" size="sm" />
                </div>
                <div class="help-action" v-if="helpEl.ddComponentType!=='divider'">
                  <span>{{ helpEl.name | startcase }}</span>
                  <div class="icon-wrapper" v-if="helpEl.openInNewTab">
                    <fa-icon class="ext-link" icon="external-link" size="xs"
                      :class="helpEl.disable ? 'disabled':''"/>
                  </div>
                </div>
              </component>
            </div>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
    <p class="pt-2 version" v-if="version">v{{ version }}</p>
  </div>
</template>

<script>
import { actionsTabs, distributionMethodologies, documentationLinks, helpDeskMailbox } from "@/constants"
import { capitalize, startCase, sum, upperCase, values } from "lodash"
import { mapActions, mapGetters, mapState } from "vuex"
import { Pages } from "@/utils/pages"

export default {
  name: "SideBar",
  data () {
    return {
      isCollapsed: true,
      expanded: "",
      landingPage: {}
    }
  },
  computed: {
    ...mapGetters("user", ["isInternal", "hasMakers", "hasPerformers", "canOverrideUserRole"]),
    ...mapState("user", ["permissions","user"]),
    ...mapState("actionTabs", ["userActionCounts"]),
    sections () {
      return [
        {
          name: this.$t("repertoire"),
          id: "repertoire",
          icon: ["fa", "music"],
          visible: this.permissions.pages[Pages.RepertoireFilesList].canRead || this.permissions.pages[Pages.RepertoireRecordingList].canRead,
          isParent: true,
          children: [
            {
              name: startCase(this.$t("uploads")),
              id: "repertoire-files-list",
              icon: ["fa", "cloud-upload-alt"],
              visible: this.permissions.pages[Pages.RepertoireFilesList].canRead,
              routeParams: { type: "metadata", routerLink: "uploads" },
            },
            {
              name: upperCase(this.$t("sra")),
              id: "repertoire-recordings-list",
              icon: ["fa", "database"],
              visible: this.permissions.pages[Pages.RepertoireRecordingList].canRead,
            },
          ],
        },
        {
          name: this.$t("usage"),
          id: "usage",
          icon: ["fa", "play-circle"],
          visible: this.permissions.pages[Pages.UsagesFileList].canRead || this.permissions.pages[Pages.ConsolidatedUsageList].canRead,
          isParent: true,
          children: [
            {
              name: startCase(this.$t("usage_files")),
              id: "usage-files-list",
              icon: ["fa", "bullhorn"],
              visible: this.permissions.pages[Pages.UsagesFileList].canRead,
              routeParams: { type: "metadata", routerLink: "uploads" },
            },
            {
              name: startCase(this.$t("consolidated_usages")),
              id: "consolidated-usage-list",
              icon: ["fa", "list"],
              visible: this.permissions.pages[Pages.ConsolidatedUsageList].canRead,
            },
          ],
        },
        {
          name: this.$t("rights_holders"),
          id: "rightsholders",
          icon: ["fa", "user-circle"],
          visible: this.permissions.pages[Pages.RightsHoldersList].canRead,
          isParent: true,
          children: [
            {
              name: startCase(this.$t("uploads")),
              id: "rightsholder-batches-list",
              icon: ["fa", "cloud-upload-alt"],
              visible: this.permissions.pages[Pages.RightsHoldersList].canRead,
              routeParams: { type: "metadata", routerLink: "uploads" },
            },
            {
              name: startCase(this.$t("database")),
              id: "rightsholders-list",
              icon: ["fa", "database"],
              visible: this.permissions.pages[Pages.RightsHoldersList].canRead,
            },
          ],
        },
        {
          name: this.$t("payments"),
          id: "payment-files",
          icon: ["fa", "sack-dollar"],
          visible: this.permissions.pages[Pages.PaymentFilesList].canRead,
        },
        {
          name: this.$t("stations"),
          id: "stations",
          icon: ["fa", "broadcast-tower"],
          visible: this.permissions.pages[Pages.Stations].canRead,
        },
        {
          name: this.$t("copyrights_and_tariffs"),
          id: "copyrights",
          routeParams: { type: "metadata", routerLink: "copyrights-tariffs" },
          icon: ["fa", "copyright"],
          visible: this.permissions.pages[Pages.CopyrightsAndTariffs].canRead,
        },
        {
          name: this.$t("user_actions"),
          id: "user-actions",
          icon: ["fa", "hand-paper"],
          visible: true,
          isParent: true,
          count: this.roundOffCount(this.actionsCount),
          children: [
            {
              id: actionsTabs.NOTIFICATIONS,
              actionsCount: this.roundOffCount(this.userActionCounts.notificationActionsCount),
              name: startCase(this.$t("notification_center")),
              icon: ["fa", "bell"],
              visible: true,
            },
            {
              id: actionsTabs.MANDATES,
              actionsCount: this.roundOffCount(this.userActionCounts.mandateActionsCount),
              name: startCase(this.$t("mandates")),
              icon: ["fa", "file-alt"],
              visible: this.permissions.actionsTabs[actionsTabs.MANDATES].canRead,
            },
            {
              id: actionsTabs.MATCHING_CONFLICTS,
              actionsCount: this.roundOffCount(this.userActionCounts.repertoireActionsCount),
              name: startCase(this.$t("manual_matching")),
              icon: ["fa", "music"],
              visible: this.permissions.actionsTabs[actionsTabs.MATCHING_CONFLICTS].canRead,
            },
            {
              id: actionsTabs.REPERTOIRE_ERRORS,
              actionsCount: this.roundOffCount(this.userActionCounts.repertoireErrorActionsCount),
              name: startCase(this.$t("repertoire_errors")),
              icon: ["fa", "exclamation-circle"],
              visible: this.permissions.actions.canSeeRepertoireErrors,
            },
            {
              id: actionsTabs.RECORDING_CHALLENGES,
              actionsCount: this.roundOffCount(this.userActionCounts.matchingChallengeActionsCount),
              name: startCase(this.$t("recording_challenges_actions")),
              icon: ["fa", "check"],
              visible: this.permissions.actionsTabs[actionsTabs.RECORDING_CHALLENGES].canRead,
            },
            {
              id: actionsTabs.RIGHTSHOLDER_CHALLENGES,
              actionsCount: this.roundOffCount(this.userActionCounts.performerChallengesCount + this.userActionCounts.makerChallengesCount),
              name: startCase(this.$t("rightsholder_challenges")),
              icon: ["fa", "user-circle"],
              visible: this.permissions.actionsTabs[actionsTabs.RIGHTSHOLDER_CHALLENGES].canRead,
            },
            {
              id: actionsTabs.LINEUP_CONFLICTS,
              actionsCount: this.roundOffCount(this.userActionCounts.lineupConflictsCount),
              name: startCase(this.$t("performer_lineups")),
              icon: ["fa", "user-circle"],
              visible: this.permissions.actionsTabs[actionsTabs.LINEUP_CONFLICTS].canRead && this.hasPerformers,
            },
            {
              id: actionsTabs.RIGHTS_OWNERSHIP_CONFLICT,
              actionsCount: this.roundOffCount(this.userActionCounts.ownershipConflicts),
              name: startCase(this.$t("ownership_conflicts")),
              icon: ["fa", "compact-disc"],
              visible: this.permissions.actionsTabs[actionsTabs.RIGHTS_OWNERSHIP_CONFLICT].canRead && this.hasMakers,
            },
            {
              id: actionsTabs.USAGE_MATCHING,
              actionsCount: this.roundOffCount(this.userActionCounts.usageMatchingConflicts),
              name: startCase(this.$t("usage_matching")),
              icon: ["fa", "bullhorn"],
              visible: this.permissions.actionsTabs[actionsTabs.USAGE_MATCHING].canRead,
            },
            {
              id: actionsTabs.USAGE_CHALLENGES,
              actionsCount: this.roundOffCount(this.userActionCounts.usageChallengeActionsCount),
              name: startCase(this.$t("usage_challenge_actions")),
              icon: ["fa", "bullhorn"],
              visible: this.permissions.actionsTabs[actionsTabs.USAGE_CHALLENGES].canRead,
            },
            {
              id: actionsTabs.ELIGIBILITY_CONFLICTS,
              actionsCount: this.roundOffCount(this.userActionCounts.eligibilityActionsCount),
              name: startCase(this.$t("eligibility_conflicts")),
              icon: ["fa", "id-card"],
              visible: this.permissions.actionsTabs[actionsTabs.ELIGIBILITY_CONFLICTS].canRead,
            },
            {
              id: actionsTabs.NEGATIVE_ADJUSTMENTS,
              actionsCount: this.roundOffCount(this.userActionCounts.lineupAdjustmentsActionsCount + this.userActionCounts.ownershipAdjustmentsActions + this.userActionCounts.usageAdjustmentsActions),
              name: startCase(this.$t("negative_adjustments")),
              icon: ["fa", "sack-dollar"],
              visible: this.permissions.actionsTabs[actionsTabs.NEGATIVE_ADJUSTMENTS].canRead,
            },
            {
              id: actionsTabs.DUPLICATED_CONFLICTS,
              actionsCount: this.roundOffCount(this.userActionCounts.duplicateConflictsCount),
              name: this.$t("duplicated_conflicts"),
              icon: ["fa", "barcode"],
              visible: this.permissions.actions.canSeeDuplicateConflict
            },
            {
              id: actionsTabs.MERGE_REQUESTS,
              actionsCount: this.roundOffCount(this.userActionCounts.mergeRequestsCount),
              name: startCase(this.$t("merge_requests")),
              icon: ["fa", "blender"],
              visible: this.permissions.actionsTabs[actionsTabs.MERGE_REQUESTS].canRead
            },
          ]
        },
        {
          name: this.$t("distribution"),
          id: "distribution",
          icon: ["fa", "hand-holding-usd"],
          visible: this.permissions.pages[Pages.DistributionList].canRead,
          isParent: true,
          children: [
            {
              name: startCase(this.$t("tariff_periods")),
              id: "distributions-list",
              icon: ["fa", "funnel-dollar"],
              visible: this.permissions.pages[Pages.DistributionList].canRead,
              params: { distribution_methodology: distributionMethodologies.STANDARD },
            }
          ]
        },
        {
          name: this.$t("reporting"),
          id: "reporting",
          icon: ["fa", "analytics"],
          visible: this.permissions.pages[Pages.MatchingStatistics].canRead,
          isParent: true,
          children: [
            {
              name: startCase(this.$t("matching_stats")),
              id: "matching-statistics",
              icon: ["fa", "user-chart"],
              visible: this.permissions.pages[Pages.MatchingStatistics].canRead,
              isParent: false,
              routeParams: { routerLink: "matching-statistics" },
            }
          ]
        },
        {
          name: this.$t("user_management"),
          id: "users",
          icon: ["fa", "users-cog"],
          isParent: true,
          visible: (this.permissions.pages[Pages.UsersList].canRead && this.permissions.actions.hasAllUsersPermission) || this.permissions.pages[Pages.UserRoles].canRead,
          children: [
            {
              name: startCase(this.$t("system_users")),
              id: "users-list",
              visible: this.permissions.pages[Pages.UsersList].canRead && this.permissions.actions.hasAllUsersPermission,
              icon: ["fa", "users"],
            },
            {
              name: startCase(this.$t("user_roles")),
              id: "user-roles",
              icon: ["fa", "user-lock"],
              visible: this.permissions.pages[Pages.UserRoles].canRead,
            }
          ]
        },
      ]
    },
    version () {
      return this.$config.VERSION
    },
    visibleSections () {
      return this.sections.filter((section) => section.visible)
    },
    actionsCount () {
      return sum(values(this.userActionCounts)) - this.userActionCounts.notificationActionsCount
    },
    helpMenuElements () {
      return [
        {
          name: this.$t("crescendo_help"),
          ddComponentType: "header",
          indentation: 1,
          visible: true
        },
        {
          ddComponentType: "divider",
          visible: true
        },
        {
          name: this.$t("help_guide"),
          ddComponentType: "item",
          visible: true,
          indentation: 2,
          link: this.isInternal ? documentationLinks.RESOUND_USER_GUIDE_URL : documentationLinks.MC_USER_GUIDE_URL,
          openInNewTab: true
        },
        {
          name: this.$t("technical_documentation"),
          ddComponentType: "item",
          visible: true,  // ToDo: Have to be changed later after enabling tech docs on all env
          indentation: 2,
          link: documentationLinks.TECHNICAL_DOCUMENTATION_URL,
          openInNewTab: true
        },
        {
          ddComponentType: "divider",
          visible: true
        },
        {
          name: this.$t("more_resources"),
          ddComponentType: "header",
          indentation: 2,
          visible: true
        },
        {
          name: this.$t("iconography"),
          ddComponentType: "item",
          icon: ["fas", "icons"],
          visible: true,
          disable: true,
          indentation: 3,
          link: "#",
          openInNewTab: true
        },
        {
          name: this.$t("contact_service_desk"),
          ddComponentType: "item",
          icon: ["fas", "suitcase"],
          visible: true,
          indentation: 3,
          link: helpDeskMailbox,
          openInNewTab: false
        }
      ]
    },
    visibleHelpMenuElements () {
      return this.helpMenuElements.filter((section) => section.visible)
    },
  },
  watch: {
    isCollapsed () {
      this.storeState()
    },
    "$route" () {
      if (!this.isCollapsed) {
        this.expanded = this.getSectionId(this.$route.path.split("/")[1])
      }
    }
  },
  methods: {
    ...mapActions("userActions", ["subscribeUserActions", "unsubscribeUserActions"]),
    setLandingPage (section, child = null) {
      let data = section.id === "user-actions" ?
        {
          preferred_landing_page: JSON.stringify({
            name: section.id,
            type: child.id,
          })
        } :
        {
          preferred_landing_page: JSON.stringify({
            name: child ? child.id : section.id,
          })
        }
      this.$api.users.usersSetLandingPage(data).then(() => {
        this.landingPage = JSON.parse(data.preferred_landing_page)
        localStorage.setItem("landingPage", data.preferred_landing_page)
      })
    },
    collapse: function (isCollapsed) {
      if (isCollapsed) {
        this.expanded = ""
      }
      //open the dropdown state when user click anywhere in the sidebar in closed state other than b-nav-item
      if (this.isCollapsed && !isCollapsed) {
        this.expanded = this.getSectionId(this.$route.path.split("/")[1])
      }
      this.isCollapsed = isCollapsed
      this.$emit("collapsed",this.isCollapsed)
    },
    showChildSection (section) {
      return this.expanded === section.id  
    },
    isActiveSection (section, child) {
      let routeName = ""
      if (section.id === "user-actions") {
        routeName = this.$route.name === "manual-matching-detail" ? this.commonRouteName(this.$route.name) : this.commonRouteName(this.$route.params.type)
      } else {
        routeName = this.commonRouteName(this.$route.name)
      }
      return child ? section.children.some(c => c.id === routeName) : section.id === routeName
    },
    isActiveSubSection (child, section) {
      let routeName = ""
      if (section.id === "user-actions") {
        routeName = this.$route.name === "manual-matching-detail" ? this.commonRouteName(this.$route.name) : this.commonRouteName(this.$route.params.type)
      } else {
        routeName = this.commonRouteName(this.$route.name)
      }
      return child.id === routeName
    },
    commonRouteName (routeName) {
      switch (routeName) {
      case "landing-recordings": return "repertoire-files-list"
      case "repertoire-recording-detail": return "repertoire-recordings-list"
      case "usages-list": return "usage-files-list"
      case "landing-rightsholders-list": return "rightsholder-batches-list"
      case "rightsholder-detail": return "rightsholders-list"
      case "payment-file-details": return "payment-files"
      case "station-detail": return "stations"
      case "users-update": return "users-list"
      case "manual-matching-detail": return actionsTabs.MATCHING_CONFLICTS
      default: return routeName
      }
    },
    isLandingPage (section,child=null) {
      if (section.id === "user-actions" && child) {
        return this.landingPage.name === section.id && this.landingPage.type === child.id
      } else if (section.id !== "user-actions" && child) {
        return this.landingPage.name === child.id
      } else {
        return this.landingPage.name === section.id
      }
    },
    toggleExpanded (sectionId, event) {
      event.stopPropagation()
      this.collapse(false)
      if (this.expanded === sectionId) {
        this.expanded = ""
      } else {
        this.expanded = sectionId
      }
    },
    sideNavRoute (section, child) {
      if (section.id !== "user-actions") {
        return {
          name: child.id,
          params: { ...child.routeParams, ...{ sidebar: true } },
          query: child.query,
        }
      } else {
        return {
          name: "user-actions",
          params: { type: child.id, ...{ sidebar: true } },
          query: child.query,
        }
      }
    },
    visibleChildren (section) {
      return section.children ? section.children.filter((child) => child.visible) : []
    },
    roundOffCount (count) {
      if (count || count === 0) {
        let formattedCount = ""
        let suffix = ""
        if (count >= 1.0e+6) {
          formattedCount =  (count / 1.0e+6).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
          suffix = count % 1.0e+6 ? "M+" : "M"
        } else if (count >= 1.0e+3) {
          formattedCount =  (count / 1.0e+3).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
          suffix = count % 1.0e+3 ? "K+" : "K"
        } else {
          formattedCount = count.toString()
        }
        let digitsBeforeDecimal = formattedCount.split(".")[0].length
        if (digitsBeforeDecimal === 3) {
          formattedCount = formattedCount.split(".")[0]
        } else if (formattedCount.endsWith(".0")) {
          formattedCount = formattedCount.split(".")[0]
        }
        return formattedCount+suffix
      }
    },
    storeState () {
      return localStorage.setItem("sideNavState", this.isCollapsed)
    },
    getStoredState () {
      return JSON.parse(localStorage.getItem("sideNavState"))
    },
    getSectionId (route) {
      switch(route) {
      case "repertoire": return "repertoire"
      case "consolidated-usages":
      case "usage-files": return "usage"
      case "rightsholders":
      case "rightsholder-batches": return "rightsholders"
      case "user-actions": return "user-actions"
      case "reporting": return "reporting"
      case "user-roles":
      case "users": return "users"
      default: return this.expanded
      }
    },
    upperCase,
    capitalize,
    startCase,
  },
  mounted () {
    this.isCollapsed = this.getStoredState()
    this.$emit("collapsed",this.isCollapsed)
    this.$root.$on("bv::dropdown::show", async (bvEvent) => {
      if (bvEvent?.componentId === "help-menu-popup"){
        if (this.isCollapsed)
          this.collapse(false)
      }
    })
    this.landingPage = JSON.parse(this.user.preferredLandingPage)
  },
}
</script>

<style lang="scss" scoped>
.nav-container {
  z-index: 100;
  height: 100vh;
  background: $white-dark;
  transition: 0.3s;
  border-right: 2px solid $red;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: scroll;
  scrollbar-width: none;
  .top-container {
    position: fixed;
    top: 0;
    background-color: $white-dark;
    border-right: 2px solid $red;
    z-index: 10;
    transition: 0.3s;
    .navbar-brand {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    #logo {
      margin-right: 0;
      padding: 0.5rem 0.5rem;
      img {
        max-width: fit-content;
        height: 36px;
      }
    }
  }
  .middle-container {
    position: absolute;
    top: 65px;
    .menu {
      flex-direction: row !important;
      overflow-y: scroll;
      scrollbar-width: none;
    }
    .sidebar-item {
      font-size: 16px;
      .section-name {
        font-size: 14px !important;
      }
      .star {
        display: none;
        font-size: 18px !important;
      }
      &:hover {
        color: $gray-light !important;
        .star {
          display: block !important;
        }
      }
    }
    .action {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .title {
      width: 171px;
    }
    .sub-title {
      width: 155px;
    }
  }
  .split-line {
    border: 1px solid $gray-darker;
    margin: 0 1rem;
  }
  .bottom-container-wrapper{
    position: fixed;
    bottom: 0;
  }
  .bottom-logo {
    display: flex;
    padding-bottom: 0.5rem;
    background-color: $white-dark;
    z-index: 10;
    max-width: 60px;
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    transition: 0.3s;
    background: $white-dark;
    border-right: 2px solid $red;
    .footer {
      display: flex;
      ::v-deep #help-menu-popup .btn {
        font-size: 16px;
        box-shadow: none;
        &:hover {
          color: $gray-light;
        }
      }
      ::v-deep #help-menu-popup .dropdown-menu {
        inset: auto 0.55rem 2.0rem auto !important;
        transform: none !important;
        border: 1px solid $gray-darker;
        a {
          padding: 0.3rem 1rem;
          font-size: 16px;
          color: $primary;
          &:active, &:hover {
            background-color: $gray;
          }
          &.disabled{
            color: $gray-dark;
          }
        }
        .list-element li a {
          display: flex;
          padding: 0.3rem 1rem;
          font-size: 16px;
          color: $primary;
          &:active, &:hover {
            background-color: $gray;
          }
          &.disabled{
            color: $gray-dark;
          }
        }
        .list-element .help-action {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        header {
          color: $primary;
          padding: 0.3rem 0rem;
          font-size: 16px;
        }
        .indentation-1 header {
          font-size: 18px;
        }
        .indentation-1 :is(header, a) {
          padding-left: 0.75rem;
        }
        .indentation-2 :is(header, a) {
          padding-left: 1.5rem;
        }
        .indentation-3 :is(header, a) {
          padding-left: 2.25rem;
        }
        .dropdown-divider {
          border-bottom: 1px solid $gray-darker;
          margin: 0.5rem 0.7rem;
        }
        .ext-link {
          color: $blue-dark;
          margin-left: 0.75rem;
          &.disabled {
            color: $gray-dark
          }
        }
      }
    }
    .bottom-logo {
      display: flex;
      padding-bottom: 0.5rem;
      background-color: $white-dark;
      z-index: 10;
      max-width: 60px;
    }
    .footer-expanded {
      padding-left: 0.7rem;
      justify-content: space-between;
    }
    .footer-collapsed {
      flex-direction: column;
      .bottom-logo {
        order: 2;
      }
      .help {
        order: 1;
      }
    }
  }
  .icon-wrapper {
    min-width: 30px;
    text-align: center;
    position: relative;
  }
  .hide {
    display: none;
  }
  .nav-link {
    padding: 0.5rem 
  } 
  .active {
    color: $red !important;
  }
  .show {
    display: block !important;
  }
  .sb-expanded {
    width: 306px !important;
  }
  .sb-collapsed {
    width: 59px !important;
  }
  .center {
    justify-content: center !important;
  }
}
</style>
