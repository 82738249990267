<template>
  <div>
    <h5>{{ getTitle() | capitalize }}</h5>
    <div class="table-wrapper">
      <b-table
        :fields="fields"
        :items="items"
        class="smaller overflow-break mb-0"
        striped
        fixed
      >
        <!-- ID -->
        <template v-slot:cell(resoundId)="row">
          <div class="d-flex">
            <b-form-radio
              v-model="parentRightsHolder"
              name="parent-maker"
              :value="row.item"
            />
            <database-link :type="linkTypes.RIGHTS_HOLDER" :item-id="row.item.id">
              {{ row.item.resoundId }}
            </database-link>
          </div>
        </template>
        <!-- Nationalities -->
        <template v-slot:cell(nationalities)="row">
          <div
            v-for="(country, index) in row.item.nationalities"
            :key="`country-${index}`"
          >
            {{ country.name }}
          </div>
        </template>
        <!--Member Collectives-->
        <template v-slot:cell(memberCollectives)="row">
          <div
            v-for="(collective, index) in row.item.memberCollectives"
            :key="`collective-${index}`"
          >
            {{ collective.name }}
          </div>
        </template>
        <!-- Split -->
        <template v-slot:cell(split)="row">
          <span> {{ row.item.split ? `${row.item.split}%` : "" }} </span>
        </template>
      </b-table>
    </div>
    <b-row class="modal-footer mt-4">
      <b-row v-if="forceLink" class="d-flex align-items-center justify-content-end w-100">
        <b-checkbox v-model="force" class="ml-2 pt-0" />
        <span class="text-red small">
          {{ $t("the_information_is_too_different") }}
        </span>
      </b-row>
      <b-row class="justify-content-between w-100">
      <b-button class="btn-outline-dark" @click="cancel">
        {{ $t("cancel") | capitalize }}
      </b-button>
      <b-button :disabled="!parentRightsHolder" class="btn-outline-red" @click="link">
        {{ $t("save") | capitalize }}
      </b-button>
      </b-row>
    </b-row>
    <b-spinner class="ml-2" v-if="loading" variant="dark"></b-spinner>
  </div>
</template>

<script>
import { capitalize, startCase, upperCase } from "lodash"
import DatabaseLink from "@/components/DatabaseLink"
import { linkTypes } from "@/constants"
import { mapMutations } from "vuex"

export default {
  name: "RightsHoldersLinkForm",
  components: { DatabaseLink },
  props: {
    selectedObjects: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapMutations("alert", ["success", "error"]),
    ...mapMutations("mergers", ["resetMergeCart", "setMergeCart"]),
    isMakers () {
      return this.items.some(so => so.type === "maker")
    },
    getTitle () {
      return this.isMakers()
        ? this.$t("please_pick_a_parent_maker")
        : this.$t("please_pick_a_parent_performer")
    },
    handleFunctionCall (functionName, data) {
      return this.$api.repertoire[functionName](data)
    },
    getFunctionName () {
      return this.isMakers()
        ? "mergeMakers"
        : "mergePerformers"
    },
    link () {
      let functionName = this.getFunctionName()
      let data = {
        parent: this.parentRightsHolder.id,
        children: this.items
          .filter((r) => r.id !== this.parentRightsHolder.id)
          .map((r) => r.id),
        force: this.force,
      }

      this.handleFunctionCall(functionName, data)
        .then(() => {
          let msg = capitalize(this.$t("rightsholders_linked")) + ". "
              + capitalize(this.$t("recordings_reconciliation_started"))
              + capitalize(this.$t("processing_may_take_some_time"))
          this.success(msg)
          this.resetMergeCart("rightsHolderMergeCart")
          this.$bvModal.hide("rightsholders-link-modal")
          this.$emit("linked")
        }).catch((error) => {
          if (
            error.response.data.nonFieldErrors &&
            error.response.data.nonFieldErrors[0].includes("too different")
          ) {
            this.forceLink = true
            this.error(error.response.data.nonFieldErrors[0])
          } else {
            let msg = `Error linking rightsholders. ${error.response.data}`
            this.$bvModal.hide("rightsholders-link-modal")
            this.error(msg)
          }
        })
    },
    cancel () {
      this.$bvModal.hide("rightsholders-link-modal")
    },
    getData () {
      let promises = []
      for (let obj of this.selectedObjects) {
        const p = this.$api.rightsholders.rightsholdersDetail(obj.id).then(d => d.data)
        promises.push(p)
      }
      Promise.all(promises)
        .then(values => {this.loading = false; this.items = values})
        .catch(() => this.loading = false)
    }
  },
  data () {
    return {
      linkTypes,
      force: false,
      forceLink: false,
      parentRightsHolder: null,
      fields: [
        { key: "resoundId", label: upperCase(this.$t("id")) },
        { key: "name", label: startCase(this.$t("name")) },
        { key: "nationalities", label: startCase(this.$t("nationalities")) },
        {
          key: "memberCollectives",
          label: startCase(this.$t("mc")),
        },
        { key: "recordingsCount", label: startCase(this.$t("recordings_count")) },
      ],
      items: [],
      loading: true
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
