<template>
  <b-card class="mb-3 orange">
    <div>There is no data to display</div>
  </b-card>
</template>

<script>
export default {
  name: "NoData",
}
</script>

<style lang="scss" scoped>
.orange {
  background-color: $orange-lighter;
}
</style>