<template>
  <div>
    <div v-if="standalone">
      <b-row class="mb-3 heading-container">
        <go-back ref="goback"/>
        <h4 class="pl-2">{{ landingName }}</h4>
      </b-row>
    </div>
    <b-table class="overflow-break" v-if="landingName" :fields="fields(true)" :items="landingRightsholderData(data)" striped fixed>
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>

      <!-- Member Collectives -->
      <template v-slot:cell(memberCollectives)="row">
        <div v-for="(mc, mci) in row.item.memberCollectives" :key="`mc-${mci}`">
          {{ mc ? mc.name : "" }}
        </div>
      </template>

      <!-- Date of Birth -->
      <template v-slot:cell(dateOfBirth)="data">
        {{ parseDateSafe(data.value) }}
      </template>

      <!-- Nationalities -->
      <template v-slot:cell(nationalities)="row">
        {{ row.item.nationalities.length ? row.item.nationalities.join(", ") : "" }}
      </template>
    </b-table>
    <h3 class="mb-2">
      {{ $t("linked_with_master") | capitalize }}:
    </h3>
    <b-table class="overflow-break" v-if="data && (data.performer || data.maker)" :fields="fields()" :items="rightsholderData(data)" striped fixed>
      <!-- Width handling -->
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>
      <!-- RS ID -->
      <template v-slot:cell(resoundId)="row">
        <database-link :type="linkTypes.RIGHTS_HOLDER" :item-id="row.item.id">{{ row.item.resoundId }}</database-link>
      </template>
      <!-- Member Collectives -->
      <template v-slot:cell(memberCollectives)="row">
        <div v-for="(mc, mci) in row.item.memberCollectives" :key="`mc-${mci}`">
          {{ mc ? mc.name : "" }}
        </div>
      </template>

      <!-- Date of Birth -->
      <template v-slot:cell(dateOfBirth)="data">
        {{ parseDateSafe(data.value) }}
      </template>

      <!-- Nationalities -->
      <template v-slot:cell(nationalities)="row">
        {{ row.item.nationalities.length ? row.item.nationalities.join(", ") : "" }}
      </template>
    </b-table>
    <b-row>
      <b-button
          v-if="data && permissions.actionsTabs[actionsTabs.RIGHTSHOLDER_CHALLENGES].canWrite"
          :disabled="![userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS].includes(data.status) || waiting"
          variant="outline-success"
          class="ml-3"
          @click="confirmMatch(data.id)"
      >
        <fa-icon size="sm" :icon="['fa', 'check']"/>
        {{ $t("confirm_the_match") | capitalize }}
      </b-button>
      <b-button
          v-if="data && permissions.actionsTabs[actionsTabs.RIGHTSHOLDER_CHALLENGES].canWrite"
          :disabled="![userActionStatuses.PENDING, userActionStatuses.IN_PROGRESS].includes(data.status) || waiting"
          variant="outline-warning"
          class="ml-3"
          @click="unlink(data.id)"
      >
        <fa-icon size="sm" :icon="['fa', 'unlink']"/>
        {{ $t("unlink_create_rightsholder") }}
      </b-button>
    </b-row>
    <comments
          v-if="data"
          :createEndpoint="this.challengeType === rightsholderTypes.PERFORMER ? this.$api.comments.performerChallengeCommentCreate : this.$api.comments.makerChallengeCommentCreate"
          :listEndpoint="this.challengeType === rightsholderTypes.PERFORMER ? this.$api.comments.performerChallengeCommentsList : this.$api.comments.makerChallengeCommentList"
          :uuid="data.id"
    />
  </div>
</template>
<script>
import { capitalize, startCase, upperCase } from "lodash"
import {
  linkTypes,
  rightsholderTypes,
  userActionStatuses
} from "@/constants"
import { mapActions, mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import { actionsTabs } from "@/constants"
import parseDate from "@/utils/date-parser"

export default {
  name: "RightsHolderChallengeDetail",
  components: { DatabaseLink },
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
    ...mapState("user", ["permissions", "waiting"]),
    landingName () {
      if (this.challengeType === rightsholderTypes.PERFORMER) {
        return this.data && this.data.landingPerformer ? this.data.landingPerformer.name : ""
      } else {
        return this.data && this.data.landingMaker ? this.data.landingMaker.name : ""
      }
    },
  },
  props: {
    challengeType: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
    standalone: {
      type: Boolean,
      default: true
    },
    solvedManually: {
      type: Array,
      required: false,
    },
    onDone: {
      type: Function,
      default: () => {}
    },
  },
  data () {
    return {
      linkTypes,
      rightsholderTypes,
      userActionStatuses: userActionStatuses,
      data: null,
      challengeRetrieveEndpoint: null,
      unlinkEndpoint: null,
      confirmEndpoint: null,
      actionsTabs,
    }
  },
  methods: {
    ...mapActions("userActions", ["notifyUserAction"]),
    ...mapMutations("actionTabs", ["setUserActionCounts"]),
    ...mapMutations("alert", ["error", "success"]),
    fields (landingData=false){
      if ( this.challengeType === this.rightsholderTypes.PERFORMER) {
        let fields = [
          { key: "name", label: startCase(this.$t("performer_name")), width: "24%" },
          { key: "ipn", label: upperCase(this.$t("ipn")), width: "24%" },
          { key: "memberCollectives", label: startCase(this.$t("mc")), width: "24%" },
          { key: "dateOfBirth", label: startCase(this.$t("date_of_birth")), width: "12%" },
          { key: "dateOfDeath", label: startCase(this.$t("date_of_death")), width: "13%" },
          { key: "gender", label: startCase(this.$t("gender")), width: "8%" },
          { key: "nationalities", label: startCase(this.$t("nationalities")), width: "10%" },
        ]
        if (landingData){
          fields.splice(0, 0, { key: "id", label: this.$t("id_proprietary_id"), width: "22%" })
        } else {
          fields.splice(0, 0, { key: "resoundId", label: this.$t("resound_id"), width: "22%" })
        }
        return fields
      } else {
        let fields = [
          { key: "name", label: startCase(this.$t("maker_name")), width: "24%" },
          { key: "memberCollectives", label: startCase(this.$t("mc")), width: "24%" },
          { key: "nationalities", label: startCase(this.$t("nationalities")), width: "10%" },
        ]
        if (landingData){
          fields.splice(0, 0, { key: "id", label: this.$t("id_proprietary_id"), width: "22%" })
        } else {
          fields.splice(0, 0, { key: "resoundId", label: this.$t("resound_id"), width: "22%" })
        }
        return fields
      }
    },
    parseDateSafe (date) {
      if (date !== null && date !== "") {
        return parseDate(date, this.$config.DATE_FORMAT)
      }
      return date
    },
    backOrEmit () {
      if(this.standalone) {
        if (this.challengeType === this.rightsholderTypes.PERFORMER) {
          this.setUserActionCounts({
            ...this.userActionCounts,
            performerChallengesCount: --this.userActionCounts.performerChallengesCount
          })
        } else {
          this.setUserActionCounts({
            ...this.userActionCounts,
            makerChallengesCount: --this.userActionCounts.makerChallengesCount
          })
        }
        this.$refs.goback.goBack()
      } else {
        this.onDone(this.type)
      }
    },
    confirmMatch (uuid) {
      if (this.solvedManually) {
        this.solvedManually.push(uuid)
      }
      this.confirmEndpoint(uuid)
        .then(() => {
          this.success(capitalize(this.$t("matched")))
          this.backOrEmit()
        })
        .catch(err => {
          let msg = `Error confirming match. ${err.response.data.detail}`
          this.error(msg)
        })
    },
    unlink (uuid) {
      if (this.solvedManually) {
        this.solvedManually.push(uuid)
      }
      this.unlinkEndpoint(uuid)
        .then(() => {
          this.success(capitalize(this.$t("unlinked")))
          this.backOrEmit()
        })
        .catch(err => {
          let msg = `Error unlinking rightsholder. ${err.response.data.detail}`
          this.error(msg)
        })
    },
    landingRightsholderData (data) {
      if (this.challengeType === this.rightsholderTypes.PERFORMER) {
        return [{
          "id": data.landingPerformer.proprietaryId || data.landingPerformer.id,
          "name": data.landingPerformer.name,
          "ipn": data.landingPerformer.ipn,
          "memberCollectives": [],
          "dateOfBirth": data.landingPerformer.dateAndPlaceOfBirth ? data.landingPerformer.dateAndPlaceOfBirth.date : null,
          "dateOfDeath": null,
          "gender": data.landingPerformer.gender,
          "nationalities": [data.landingPerformer.nationality],
        }]
      } else {
        return [{
          "id": data.landingMaker.proprietaryId || data.landingMaker.id,
          "name": data.landingMaker.name,
          "memberCollectives": [],
          "nationalities": [data.landingMaker.nationality],
        }]
      }
    },
    rightsholderData (data) {
      if (this.challengeType === this.rightsholderTypes.PERFORMER) {
        return [{
          "id": data.performer.id,
          "resoundId": data.performer.resoundId,
          "name": data.performer.name,
          "ipn": data.performer.ipn,
          "memberCollectives": data.performer.memberCollectives,
          "dateOfBirth": data.performer.dateOfBirth,
          "dateOfDeath": data.performer.dateOfDeath,
          "gender": data.performer.gender,
          "nationalities": data.performer.nationalities.map(n => n.alpha_2),
        }]
      } else {
        return [{
          "id": data.maker.id,
          "resoundId": data.maker.resoundId,
          "name": data.maker.name,
          "memberCollectives": data.maker.memberCollectives,
          "nationalities": data.maker.nationalities.map(n => n.alpha_2),
        }]
      }
    },
    responseAdapter (data) {
      this.data = data.data
    },
    async getData () {
      this.challengeRetrieveEndpoint(
        this.uuid
      ).then(response => {
        this.responseAdapter(response)
      })
    },
  },
  mounted () {
    if (this.challengeType === rightsholderTypes.PERFORMER) {
      this.challengeRetrieveEndpoint = this.$api.rightsholders.performerChallengeRetrieve
      this.unlinkEndpoint = this.$api.actions.unlinkLandingPerformerMatchingChallenge
      this.confirmEndpoint = this.$api.actions.confirmLandingPerformerMatchingChallenge
    } else {
      this.challengeRetrieveEndpoint = this.$api.rightsholders.makerChallengeRetrieve
      this.unlinkEndpoint = this.$api.actions.unlinkLandingMakerMatchingChallenge
      this.confirmEndpoint = this.$api.actions.confirmLandingMakerMatchingChallenge
    }
    this.type = this.challengeType
    this.getData()
    this.$emit("opened", this.uuid)
  },
  destroyed () {
    this.$emit("closed", this.uuid)
  },
}
</script>
