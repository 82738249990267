<template>
  <div>
    <div v-if="batch">
      <b-row class="heading-container">
        <go-back />
        <h4 class="ml-2">
          {{ batchName | tolower }}
        </h4>
      </b-row>
      <b-row class="mb-3 p-15">
        <span class="text-gray">
          {{
            `${capitalize($t("uploaded_by"))} ${batch.uploader} ${$t(
              "on"
            )} ${parseDate(batch.uploadDate, $config.DATETIME_FORMAT)}`
          }}
        </span>
      </b-row>
    </div>
    <list-layout
        :apply-filters="applyFilters"
        :filters-pending="filtersPending"
        :reset-filters="resetFilters"
    >
      <!-- Search -->
      <template v-slot:search>
        <form-field
          :placeholder="$t('search') | capitalize"
          :enter-action="applyFilters"
          v-model="filters.search.value"
          debounce="1000"
          :icon="['fas', 'search']"
        />
      </template>

      <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              reference="landingRH"
              :page="page"
              :count="count"
              :loading="loading"
              v-model="limit"
              @limit="onLimit"
            />
          </div>
        </div>
      </template>

      <template v-slot:filters>
        <b-container>
          <b-row>
            <b-col offset="7">
              <b-form-group
                :label="$t('type') | capitalize"
                label-class="pb-0"
                v-if="batch && batch.name !== rhBatchTypes.CRESCENDO_FILE"
              >
                <b-form-radio-group
                  class="w-100"
                  selected
                  v-model="filters.rhType.value"
                  name="radios-btn-outline"
                  :options="rhTypeOptions"
                >
                </b-form-radio-group>
              </b-form-group>
              <!-- Status filter -->
              <b-form-group
                :label="$t('status') | capitalize"
                label-class="pb-0"
              >
                <b-form-select
                  v-model="filters.status.value"
                  :options="
                    Object.keys(landingStatuses).map(k => {
                      return { text: startcase(landingStatuses[k]), value: k }
                    })
                  "
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">{{
                      $t("all") | capitalize
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:table>
        <v-server-table
          v-show="!loading"
          ref="landingRH"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <!-- Name -->
          <template v-slot:name="{ row }">
            <div>
              <database-link
                v-if="['CR', 'LN', 'MC', 'ML'].includes(row.status)"
                :type="linkTypes.RIGHTS_HOLDER"
                :item-id="row.performer !== undefined ? row.performer : row.maker"
              >
                {{ row.name }}
              </database-link>
              <span v-else>
                {{ row.name }}
              </span>
            </div>
          </template>
          <!-- Date of birth -->
          <template v-slot:dateOfBirth="{ row }">
            {{ row.dateOfBirth ? parseDate(row.dateOfBirth, $config.DATE_FORMAT) : "" }}
          </template>
          <!-- Aliases -->
          <template v-slot:aliases="{ row }">
            <NamesListModal
              class-names="table-meta mb-2"
              :values="row.aliases || []"
            />
          </template>
          <!-- Status -->
          <template v-slot:status="{ row }">
            <status
              v-if="row.status === 'CR'"
              :text="$t('created') | capitalize"
              :icon="['fas', 'plus-circle']"
              color="green"
            />
            <status
              v-else-if="row.status === 'LN'"
              :text="$t('linked') | capitalize"
              :icon="['fas', 'link']"
              color="light-blue"
            />
            <status
              v-else-if="row.status === 'MC'"
              :text="$t('manually_created') | capitalize"
              :icon="['fas', 'hand-holding-medical']"
              color="green"
            />
            <status
              v-else-if="row.status === 'ML'"
              :text="$t('manually_linked') | capitalize"
              :icon="['fas', 'hand-holding-magic']"
              color="light-blue"
            />
            <status
              v-else-if="row.status === 'PN'"
              :text="$t('pending') | capitalize"
              :icon="['fas', 'question-circle']"
              color="orange"
            />
          </template>
        </v-server-table>
      </template>
    </list-layout>
  </div>
</template>
<script>
import {
  capitalize,
  get,
  startCase as startcase,
  upperCase as uppercase
} from "lodash"
import {
  landingStatuses,
  linkTypes,
  perPageItemsSm,
  rhBatchTypes,
  rightsholderTypes
} from "@/constants"
import DatabaseLink from "@/components/DatabaseLink"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal.vue"
import Pagination from "@/components/Pagination"
import { listRouteMixin } from "@/utils/mixins"
import parseDate from "@/utils/date-parser"

export default {
  name: "LandingRightsHolderList",
  mixins: [listRouteMixin],
  components: {
    DatabaseLink,
    NamesListModal,
    Pagination
  },
  methods: {
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.$emit("loaded", data.count)
      this.loading = false
    },
    getData () {
      this.$refs.landingRH.getData()
    },
  },
  props: ["batchId", "rhType"],
  data () {
    return {
      linkTypes,
      data: [],
      page: 1,
      limit: perPageItemsSm,
      loading: false,
      landingSrStatuses: {},
      count: 0,
      batch: null,
      uploader: "",
      options: {
        sortable: ["proprietaryId", "name"],
        requestKeys: {},
        headings: {
          proprietaryId: startcase(this.$t("proprietary_id")),
          name: capitalize("name"),
          ipn: uppercase(this.$t("ipn")),
          dateOfBirth: startcase(this.$t("date_of_birth")),
          gender: capitalize(this.$t("gender")),
          aliases: capitalize(this.$t("aliases")),
          status: capitalize(this.$t("status"))
        },
        responseAdapter ({ data }) {
          this.$parent.$parent.$options.parent.batch = data.context
          this.data = data
          return {
            data: data.results,
            count: data.count
          }
        },
        requestFunction (queryParams) {
          const componentData = this.$parent.$parent.$options.parent
          queryParams = {
            ...queryParams,
            ...this.$route.query,
            source: componentData.batchId
          }
          this.page = Number(get(queryParams, "page", 1))
          if (
            componentData.filters.rhType.value === rightsholderTypes.PERFORMER
          ) {
            return this.$api.rightsholders.landingPerformersList(queryParams)
          }
          return this.$api.rightsholders.landingMakersList(queryParams)
        }
      },
      filters: {
        status: { value: null, defaultValue: null },
        search: { value: "", defaultValue: "" },
        rhType: {
          value: rightsholderTypes.PERFORMER,
          defaultValue: rightsholderTypes.PERFORMER
        }
      },
      fileName: "",
      landingStatuses,
      rhBatchTypes,
      capitalize,
      startcase,
      parseDate
    }
  },
  computed: {
    columns () {
      let mainColumns = ["proprietaryId", "name", "aliases", "status"]
      if (this.filters.rhType.value === rightsholderTypes.PERFORMER) {
        mainColumns.splice(2, 0, "ipn", "dateOfBirth", "gender")
      }
      return mainColumns
    },
    rhTypeOptions () {
      return [
        {
          text: capitalize(this.$t("performer")),
          value: rightsholderTypes.PERFORMER
        },
        { text: capitalize(this.$t("maker")), value: rightsholderTypes.MAKER }
      ]
    },
    batchName () {
      return this.batch.filename
        ? this.batch.filename
        : `${uppercase(this.$t("sf"))} ${capitalize(
          this.$t("import")
        )} ${this.$t("on")} ${parseDate(this.batch.uploadDate)}`
    }
  },
  mounted () {
    this.filters.rhType.value = this.rhType
    this.updateTableSortIcons(this.$refs.landingRH.$children[0])
  }
}
</script>
<style lang="scss" scoped>
.p-15 {
  padding-left: 15px;
}
</style>
