<template>
  <div>
    <b-row class="heading-container">
      <go-back />
      <h4 class="ml-2" v-if="this.stationData">
        {{ this.stationData.name }}
      </h4>
    </b-row>
    <div id="main-content">
      <b-card class="recording-details">
        <b-table
          id="general-details"
          v-if="this.stationData"
          :items="[this.stationData]"
          :fields="generalDetails"
          fixed
          small
        >
          <template v-slot:cell(name)="row">
            <router-link
              class="text-info"
              :to="{
                name: 'usage-files-list',
                query: { search: row.item.name },
              }"
            >
              {{ row.item.name }}
            </router-link>
          </template>

          <template v-slot:cell(aliases)="row">
            <NamesListModal
              class-names="table-meta mb-2"
              :values="row.item.aliases || []"
            />
          </template>

          <template v-slot:cell(broadcasterName)="row">
            <NamesListModal
              class-names="table-meta mb-2"
              :values="[row.item.broadcaster.name].concat(row.item.broadcaster.aliases)"
            />
          </template>

          <template v-slot:cell(broadcasterId)="row">
           {{ row.item.broadcaster.salesforceId }}
          </template>

          <template v-slot:cell(tariffs)="row">
            <NamesListModal
              class-names="table-meta mb-2"
              :values="row.item.tariffs.map(code => (t = tariffs.find(t => t.code === code)) ? t.text : null) || []"
            />
          </template>

          <template v-slot:cell(logFormat)="row">
            <span v-if="row.item.logFormat">
              {{ $t(Object.keys(logFormat).find(k => logFormat[k] === row.item.logFormat).toLowerCase()) | capitalize }}
            </span>
          </template>
        </b-table>
      </b-card>
    </div>

    <tabs-layout :tabs="this.sections" v-model="tabIndex">
      <template v-slot:tabTitle="{ tab }">{{
        tab.tabTitle | startcase
      }}</template>

      <template v-slot:content="{ tab }">
        <component
          class="tab-content"
          :data="stationData ? stationData[tab.data] : []"
          v-bind:is="tab.component"
        ></component>
      </template>
    </tabs-layout>

  </div>
</template>
<script>

import { capitalize, startCase } from "lodash"
import { commercialRadioTariff, recordingDetailTabs } from "@/constants"
import { mapActions, mapState } from "vuex"
import NamesListModal from "@/pages/Repertoire/Recordings/NamesListModal"
import StationGeneralDetails from "@/pages/Broadcasters/StationGeneralDetails"
import { logFormat } from "@/constants"

export default {
  name: "StationDetail",
  props: {
    stationId: String,
  },
  components: {
    NamesListModal,
  },
  data () {
    return {
      logFormat: logFormat,
      tabIndex: 0,
      stationData: null,
      generalDetails: [
        {
          key: "name",
          label: capitalize(this.$t("name")),
        },
        {
          key: "aliases",
          label: capitalize(this.$t("aliases")),
        },
        {
          key: "broadcasterName",
          label: capitalize(this.$t("broadcaster_name")),
        },
        {
          key: "broadcasterId",
          label: startCase(this.$t("broadcaster_id")),
        },
        {
          key: "tariffs",
          label: capitalize(this.$t("tariffs")),
        },
        {
          key: "logFormat",
          label: capitalize(this.$t("log_format")),
        }
      ]
    }
  },
  methods: {
    ...mapActions("consts", ["getTariffs"]),
    async getStationData (stationId) {
      let response = await this.$api.broadcasters.stationRetrieve(stationId)
      this.stationData = response.data
    },
    capitalize,
  },
  computed: {
    ...mapState("consts", ["tariffs"]),
    sections () {
      return [
        {
          tabTitle: this.$t("general_details"),
          component: StationGeneralDetails,
          data: "weightingDetails",
          type: recordingDetailTabs.GENERAL_DETAILS,
          visible: this.stationData && this.stationData.tariffs.includes(commercialRadioTariff),
        }
      ]
    }
  },
  mounted () {
    this.getStationData(this.stationId)
    this.getTariffs()
  }
}
</script>

<style lang="scss" scoped>
.recording-details {
  border: none;
  background: #fafafa;

  ::v-deep table {
    thead {
      background: transparent;

      th {
        font-weight: bold;
        padding: 0;
      }
    }

    tbody {
      td {
        padding: 0;
      }
    }
  }

  .meta-data-row {
    padding: 20px;
  }

  ::v-deep table td th {
    padding: 0;
  }
}

#main-content {
  position: relative;
  padding-top: 30px;

  #edit-controls {
    position: absolute;
    left: 19px;
    top: 12px;

    button {
      margin: 3px;
    }
  }
}
</style>
