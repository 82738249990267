<template>
  <list-layout
      :title="$t('duplicated_conflicts')"
      :count="userActionCounts.duplicateConflictsCount"
      :apply-filters="applyFilters"
      :reset-filters="resetFilters"
      :filters-pending="filtersPending"
      :filters-applied="filtersApplied"
      :isChild="true"
  >
    <template v-slot:search>
      <form-field
        :placeholder="$t('search') | capitalize"
        v-model="filters.fuzzy_search.value"
        :enter-action="applyFilters"
        :icon="['fas', 'search']"
      />
    </template>
    <template v-slot:filters>
      <b-row align-h="end" align-v="center">
        <b-col cols="3">
          <!-- Type filter -->
          <b-form-group>
            <b-form-radio-group
              v-model="filters.type.value"
              buttons
              button-variant="outline-primary"
              name="radios-btn-outline"
            >
              <b-form-radio :value="duplicatedTypes.ISRC">
                {{ $t("isrc") }}
              </b-form-radio>
              <b-form-radio v-show="hasPerformers" :value="duplicatedTypes.IPN">
                {{ $t("ipn") }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-checkbox v-model="only_conflicted_source" switch>
            {{ $t("show_only_source_metadata_in_conflict") | capitalize }}
          </b-form-checkbox>
        </b-col>
        <b-col cols="3">
          <b-form-checkbox v-model="filters.resolved.value" switch>
            {{ $t("show_resolved") | capitalize }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>
    <template v-slot:counts>
      <div>
        <pagination
          class="smaller p-0 mt-3"
          reference="duplicateConflicts"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="limit"
          @limit="onLimit"
        />
      </div>
    </template>

    <crescendo-split-panes ref="panes">
      <template v-slot:top>
        <component
          ref="duplicateConflicts"
          :filters="filters"
          :page="page"
          :page-number="pageNumber"
          @selected="onSelected"
          @onLoaded="onLoaded"
          @onLoading="onLoading"
          @onPagination="onPagination"
          @onSorted="onSorted"
          :is="duplicateComponents[filters.type.value]['list']"
        />
      </template>
      <template v-slot:bottom>
          <component
            v-if="selectedRow"
            ref="duplicateConflictsDetail"
            :selected-row="selectedRow"
            :only-conflicted-source="only_conflicted_source"
            :is="duplicateComponents[filters.type.value]['detail']"
            @resolved="onResolved"
          />
      </template>
    </crescendo-split-panes>
  </list-layout>
</template>
<script>
import { duplicatedTypes, getPerPageItemDefaults } from "@/constants"
import { mapGetters, mapState } from "vuex"
import CrescendoSplitPanes from "@/components/CrescendoSplitPanes"
import DuplicateIpnConflicts from "@/pages/UserActions/DuplicateConflicts/DuplicateIpnConflicts"
import DuplicateIpnConflictsDetail from "@/pages/UserActions/DuplicateConflicts/DuplicateIpnConflictsDetail"
import DuplicateIsrcConflicts from "@/pages/UserActions/DuplicateConflicts/DuplicateIsrcConflicts"
import DuplicateIsrcConflictsDetail from "@/pages/UserActions/DuplicateConflicts/DuplicateIsrcConflictsDetail"
import Pagination from "@/components/Pagination"
import { get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"


export default {
  name: "DuplicateConflictsList",
  mixins: [listRouteMixin],
  components: {
    CrescendoSplitPanes,
    DuplicateIpnConflicts,
    DuplicateIpnConflictsDetail,
    DuplicateIsrcConflicts,
    DuplicateIsrcConflictsDetail,
    Pagination,
  },
  methods: {
    onResolved () {
      this.selectedRow = null
      this.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    getData () {
      this.selectedRow = null
      this.$refs.duplicateConflicts.getData()
    },
    onSelected (item) {
      this.selectedRow = item
    }
  },
  watch: {
    "filters.type.value": function () {
      this.selectedRow = null
    },
  },
  data () {
    return {
      duplicatedTypes: duplicatedTypes,
      selectedRow: null,
      only_conflicted_source: false,
      duplicateComponents: {
        [duplicatedTypes.ISRC]: {
          list: DuplicateIsrcConflicts,
          detail: DuplicateIsrcConflictsDetail,
        },
        [duplicatedTypes.IPN]: {
          list: DuplicateIpnConflicts,
          detail: DuplicateIpnConflictsDetail,
        },
      },

      page: Number(get(this.$route.query, "page", 1)),
      pageNumber: 1,
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      loading: false,

      filters: {
        fuzzy_search: { value: "", defaultValue: "" },
        resolved: {
          value: get(this.$route.query, "resolved", false),
          defaultValue: false
        },
        type: {
          value: get(this.$route.query, "type", duplicatedTypes.ISRC),
          defaultValue: duplicatedTypes.ISRC
        },
      },

    }
  },
  computed: {
    ...mapGetters("user", ["hasPerformers"]),
    ...mapState("actionTabs", ["userActionCounts"]),
  },
}
</script>