<template>
  <div class="mt-3">
    <div class="text-center mb-2">
      <b-form-group label-cols="auto">
        <b-form-radio-group
          v-model="historyType"
          buttons
          button-variant="outline-secondary"
          name="radios-btn-outline"
          class="radio-buttons"
        >
          <b-form-radio value="actions">
            {{ $t("actions") | capitalize }}
          </b-form-radio>
          <b-form-radio value="challenges">
            {{ $t("challenges") | capitalize }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <ChallengeHistory
      v-if="historyType === 'challenges'"
      :recordingId="recordingUUID"
    />
    <ActionsHistory
      v-if="historyType === 'actions'"
      :recordingId="recordingUUID"
    />
  </div>
</template>

<script>
import ActionsHistory from "@/pages/Repertoire/Recordings/RecordingDetail/History/ActionsHistory"
import ChallengeHistory from "@/pages/Repertoire/Recordings/RecordingDetail/History/ChallengeHistory"

export default {
  name: "SrHistory",
  components: {
    ActionsHistory,
    ChallengeHistory,
  },
  props: {
    recordingUUID: {
      type: String,
    },
  },
  data: () => {
    return {
      historyType: "actions",
    }
  },
}
</script>
