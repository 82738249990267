var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mt-3 mb-4"},[(_vm.isMetaData)?_c('div',{staticClass:"col my-auto"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm._f("startcase")(_vm.$t("source_metadata"))))])]):_c('div',{staticClass:"col-4 my-auto"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm._f("startcase")(_vm.$t("merged_recordings"))))])]),_c('div',{staticClass:"col-4 d-flex justify-content-center"},[_c('b-form-group',[_c('b-form-radio-group',{staticClass:"radio-buttons",attrs:{"options":_vm.sourceMetadataOptions,"buttons":"","button-variant":"outline-secondary"},model:{value:(_vm.selectedSourceMetadata),callback:function ($$v) {_vm.selectedSourceMetadata=$$v},expression:"selectedSourceMetadata"}})],1)],1),(_vm.isMetaData)?_c('div',{staticClass:"col d-flex justify-content-end"},[(_vm.hasEligibilityConflict)?_c('div',[_c('b-button',{staticClass:"text-nowrap ml-4 btn-outline-dark",attrs:{"to":{
            name: 'eligibility-conflicts',
            query: {
              fuzzy_search: _vm.resoundId,
              level: null
            },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("eligibility_conflict")))+" "),_c('fa-icon',{attrs:{"icon":"external-link","size":"sm"}})],1)],1):_vm._e()]):_vm._e()]),_c('RecordingDetailSourcesList',{attrs:{"recording-id":_vm.recordingUUID,"collapse":true,"isMetaData":_vm.isMetaData,"id":_vm.id,"displayData":_vm.selectedSourceMetadata,"data":_vm.data}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }