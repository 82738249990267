<template>
  <div>
    <div v-if="createEndpoint">
      <b-collapse
        id="commentInput"
        v-model="visible"
        role="tabpanel"
        class="mt-4"
      >
        <b-form-textarea
          size="lg"
          debounce="500"
          :placeholder="$t('make_comment') | capitalize"
          v-model="newComment"
          rows="5"
        />
      </b-collapse>
      <b-button
        @click="showTextBox"
        class="mt-1 btn-outline-red"
        v-if="!visible && !hideAddCommentBtn"
      >
        {{ $t("comment") | capitalize }}
      </b-button>
      <div v-if="visible">
        <b-button
        class="mt-4 mr-2 btn-outline-dark"
        @click="showTextBox"
        >
        {{ $t("cancel") | capitalize }}
      </b-button>
      <b-button
        :disabled="!newComment"
        class="mt-4 btn-outline-red"
        @click="makeComment(null, extraContent)"
      >
        {{ $t("save") | capitalize }}
      </b-button>
      </div>
    </div>
    <div
      class="mt-4"
      v-for="(comment) in comments"
      :key="comment.uuid"
    >
      <b-row class="comment mt-1 ml-1 mr-1">
        <div class="icon-body d-flex align-items-center">
          <div class="icon-wrapper">
            <fa-icon v-if="!comment.userAction" size="2x" :icon="['far', 'comment-lines']"/>
            <span v-else>
              <fa-icon size="lg" :icon="['fa', getCommentIcon(comment)]"/>
              <b-badge class="ml-2" pill v-if="getCommentBadge(comment)">
                <fa-icon size="ms" :icon="['fa', getCommentBadge(comment)]"/>
              </b-badge>
            </span>
          </div>
        </div>
        <b-col>
          <b-row class="comment-body pl-0">
            <b-col>
              <b-row class="mb-1">
                <b-col>
                  <span>
                    <b>{{ parseDate(comment.created, $config.DATETIME_FORMAT) }}</b>
                    {{
                      `-
                      ${getCommentAuthor(comment)}
                      ${$t("wrote")}:`
                    }}
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="view" v-if="!editing || editing !== comment.id"> {{ comment.comment }} </span>
                  <b-form-textarea
                    v-else-if="comment.id === editing"
                    size="xs"
                    v-model="editCommentData.comment"
                    :value="editCommentData.comment"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="1"
              v-if="permissions.actions.canModifyAnyComment || (comment.createdBy && isCurrentUser(comment.createdBy.uuid))"
              class="justify-content-end d-flex align-items-center"
            >
              <span v-if="editEndpoint">
                <fa-icon
                  v-if="editing && editing === comment.id"
                  size="1x"
                  :title="$t('save') | lowercase"
                  :icon="['fa', 'check']"
                  class="mr-1 clickable"
                  @click="editComment(comment, {comment: editCommentData.comment})"
                />
                <fa-icon
                  v-if="editing && editing === comment.id"
                  size="1x"
                  :title="$t('cancel') | lowercase"
                  :icon="['fa', 'reply']"
                  class="mr-2 clickable"
                  @click="cancelEditMode()"
                />
                <fa-icon
                  v-if="!editing"
                  size="1x"
                  :title="$t('edit') | lowercase"
                  :icon="['fa', 'pen']"
                  class="view mr-2 clickable"
                  @click="editMode(comment, {comment: comment.comment})"
                />
              </span>
              <span v-if="deleteEndpoint && !editing">
                <fa-icon
                  role="button"
                  size="1x"
                  :title="$t('delete') | lowercase"
                  :icon="['fa', 'trash']"
                  class="clickable"
                  @click="onDelete(comment.id)"
                />
                <!-- Deletion Confirmation Modal -->
                <modal
                  :id="'confirmation-modal-' + comment.id"
                  :customTitle="$t('delete_comment') | capitalize"
                  modal-class="custom-modal edit"
                  modalType="edit"
                  size="md"
                  centered 
                  @ok="onConfirmDelete(comment.id)"
                  okTitle="Confirm"
                >
                  <div>
                    {{ $t("do_you_really_want_to_delete_comment") | capitalize }}?
                  </div>
                </modal>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="count"
      :per-page="perPageItemsSm"
    />
  </div>
</template>
<script>
import { UserActionTypeCodes, perPageItemsSm } from "@/constants"
import { mapGetters, mapMutations, mapState } from "vuex"
import { commentMixin } from "@/utils/mixins"

import parseDate from "@/utils/date-parser"

export default {
  name: "Comments",
  mixins: [commentMixin],
  props: {
    extraContent: {
      type: Object,
      required: false,
    },
    listEndpoint: {
      type: Function,
      required: true,
    },
    createEndpoint: {
      type: Function,
    },
    deleteEndpoint: {
      type: Function,
    },
    editEndpoint: {
      type: Function,
    },
    uuid: {
      type: String,
      required: true,
    },
    hideAddCommentBtn: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      perPageItemsSm,
      page: 1,
      count: 0,
      comments: [],
      visible: false,
    }
  },
  watch: {
    page () {
      this.getComments()
    },
    uuid () {
      this.getComments()
    }
  },
  computed: {
    ...mapGetters("user", ["isCurrentUser"]),
    ...mapState("user", ["permissions"])
  },
  methods: {
    ...mapMutations("alert", ["error", "success"]),
    parseDate,
    getComments () {
      let params = { page: this.page }
      if (this.extraContent) {
        params = { ...params, ...this.extraContent }
      }
      this.listEndpoint(this.uuid, params).then((response) => {
        this.comments = response.data.results
        this.count = response.data.count
      })
    },
    getCommentIcon (comment) {
      return comment.userAction ? "hand-paper": "user-circle"
    },
    getCommentBadge (comment) {
      if (comment.userAction) {
        switch (comment.userAction.type) {
        case UserActionTypeCodes.RIGHTS_OWNERSHIP_CONFLICT:
          return "compact-disc"
        case UserActionTypeCodes.RECORDING_LINEUP_CONFLICT:
          return "user"
        case UserActionTypeCodes.RECORDING_CHALLENGE:
          return "check"
        default:
          return ""
        }
      }
    },
    getCommentAuthor (comment) {
      return (
        comment.createdBy ?
          `${comment.createdBy.firstName} ${comment.createdBy.lastName} (${comment.createdBy.memberCollective ? comment.createdBy.memberCollective : comment.createdBy.daAgent})`
          : this.$t("crescendo_system")
      )
    },
    showTextBox () {
      if (this.hideAddCommentBtn) {
        this.newComment = ""
      } else {
        this.visible = !this.visible
      }
    }
  },
  mounted () {
    this.getComments()
    if (this.hideAddCommentBtn) {
      this.visible = true
    }
  },
}
</script>

<style lang="scss" scoped>
.view {
  white-space: pre-line;
}

.comment {
  border-radius: 4px;
}

.comment-body, .icon-body {
  padding: 10px 0 10px 10px;
  background-color: $table-header;
}

.comment-body {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.icon-body {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
</style>
