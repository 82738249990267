<template>
  <div></div>
</template>

<script>
import Vue from "vue"

export default {
  name: "Login",
  created () {
    Vue.prototype.$auth.login()
  }
}
</script>