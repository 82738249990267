<template>
  <list-layout :isChild="true">
    <template v-slot:beforeFilters>
      <modal
        id="compare-performers-modal"
        :customTitle="$t('performers_comparison') | startcase"
        modal-class="custom-modal edit"
        modalType="edit"
        size="xl"
        centered
        hide-footer
      >
        <performer-comparison
          :uuid="landingObject.id"
          :candidates="selectedRows"
          :proprietary-id="landingObject.proprietaryId"
        />
      </modal>
      <b-row>
        <div class="col-4">
          <b-button
              variant="primary"
              class="mr-3"
              v-if="isRecordingAction"
              :disabled="selectedRows && !selectedRows.length"
              @click="comparePerformers"
          >
            {{ $t("compare_performers") | capitalize }}
          </b-button>
          <b-button
            variant="secondary"
            v-if="!standalone"
            :to="{
              name: 'manual-matching-detail',
              params: {
                uuid: conflict.id,
              },
              query: {
                type: conflict.type,
                status: conflict.status
              }
            }"
            target="_blank"
          >
            {{ $t("open_in_new_tab") | capitalize }}
            <fa-icon icon="external-link" size="sm"/>
          </b-button>
        </div>
        <div class="col-6 offset-2">
          <b-row v-if="!parentObject" class="mr-auto d-flex justify-content-end mb-3">
            <div class="mr-3">
              <form-field
                :placeholder="$t('search_other_candidates') | capitalize"
                v-model="fuzzy_search"
                debounce="1000"
                :icon="['fas', 'search']"
              />
            </div>
            <div class="mr-3"
              v-if="isLandingAction && permissions.actionsTabs[actionsTabs.MATCHING_CONFLICTS].canWrite"
            >
              <b-button v-if="isLandingRecordingAction" v-on:click="createRecording()" variant="outline-secondary" :disabled="waiting">
                <fa-icon :icon="['fas', 'plus-circle']"/>
                {{ $t("create_new_sr") }}
              </b-button>
              <b-button v-else v-on:click="createRightsHolder()" variant="outline-secondary" :disabled="waiting">
                <fa-icon :icon="['fas', 'plus-circle']"/>
                {{ $t("create_rightsholder") | capitalize }}
              </b-button>
            </div>
          </b-row>
          <b-row class="d-flex justify-content-end mr-3">
            <b-form-checkbox v-if="showLandingDataToggleAvailable" v-model="showLandingData" variant="outline-secondary" switch>
            {{ $t("show_landing_data") | capitalize }}
            </b-form-checkbox>
            <b-form-checkbox v-if="isLandingAction" class="ml-5" v-model="highlightDifferences" variant="outline-secondary" switch>
            {{ $t("highlight_differences") | capitalize }}
            </b-form-checkbox>
            <b-form-checkbox v-if="isLandingRecordingAction" class="ml-5" v-model="showSameType" variant="outline-secondary" switch :disabled="!!fuzzy_search">
            {{ $t("show_same_type") | capitalize }}
            </b-form-checkbox>
          </b-row>
        </div>
      </b-row>
    </template>
    <template v-slot:table v-if="landingObject">
      <!-- Landing Object -->
      <div class="main-info">
      <metadata-conflict-table
        striped
        :data="conflictItem()"
        :fields="columnFields()"
        :action="false"
        :is-recording-action="isRecordingAction"
        :show-recordings="false"
        v-if="showLandingData"
      />
      </div>
      <b-row class="mt-2">
        <div class="col-4 table">
          <span class="title">{{ parentObject ? $t("matched_to") : $t("candidates") | startcase }}</span>
        </div>
      </b-row>

      <!-- Parent record in case it is already matched -->
      <metadata-conflict-table
        v-if="parentObject"
        striped
        :data="conflictItem(false)"
        :fields="candidatesFields()"
        :action="false"
        :is-recording-action="isRecordingAction"
        :show-recordings="true"
        :show-header="!isLandingAction || !showLandingData"
        :selectedRows="selectedRows"
      />

      <!-- Candidates -->
      <metadata-conflict-table
        v-else-if="!fuzzy_search && !parentObject"
        :data="filteredCandidates"
        :fields="candidatesFields()"
        :button-caption="buttonCaption"
        :action="true"
        :method=getMatchMethod()
        :is-recording-action="isRecordingAction"
        :show-recordings="true"
        :show-header="!isLandingAction || !showLandingData"
        :selectedRows="selectedRows"
      />
      <!-- Search -->
      <metadata-conflict-candidates-search-list
        v-else
        :isRecording="isRecordingAction"
        :fuzzy_search="fuzzy_search"
        :additionalCandidatesFn="additionalCandidatesFn"
        :candidates="candidates"
        :columns="columns"
        :headings="headings"
        :columnsClasses="columnsClasses"
        :cellClasses="cellClasses"
        :uuid="landingObject.id"
        :link=getMatchMethod()
        :show-recordings="true"
        :show-header="!isLandingAction || !showLandingData"
        :selectedRows="selectedRows"
      />
      <div v-if="!fuzzy_search && !parentObject && isLandingRecordingAction" id="recording-result">
        <hr style="background-color: var(--secondary);">
        <b-row>
          <b-col class="ml-2">
            {{ $t("same_type") | capitalize }}: {{ sameCandidateTypeCount }}
          </b-col>
          <b-col class="ml-2">
            {{ $t("different_type") | capitalize }}: {{ differentCandidateTypeCount }}
          </b-col>
          <b-col class="ml-2">
            {{ $t("showing") | capitalize }}: {{ filteredCandidates.length }}
          </b-col>         
          <b-col class="ml-2">
            {{ $t("total") | capitalize }}: {{ totalCandidates }}
          </b-col>         
        </b-row>
      </div>
    </template>
  </list-layout>
</template>

<style lang="scss" scoped>
  .main-info {
    background-color: #fafafa;

    .table thead th {
      background-color: #fafafa !important;
    }
  }

  .title {
    font-size: 1.1rem;
    font-weight: bolder;
  }

  .second-level {
    .form-group {
      margin-bottom: 0;
    }
  }
  ::v-deep table td {
    &.red {
      background-color: $red-light;
    }
    &.green {
      background-color: $green;
    }
  }
  </style>

<script>
import {
  actionsTabs,
  metadataConflictTypeCodes,
  statementConflictTypeCodes,
  userActionStatuses,
  userPreferenceNames,
} from "@/constants"
import { capitalize, difference, omit, toUpper } from "lodash"
import { mapGetters, mapMutations, mapState } from "vuex"
import MetadataConflictTable from "@/components/UserActions/MetadataConflictTable"
import PerformerComparison from "@/components/PerformerComparison"
import parseDate from "@/utils/date-parser"
import { unaccent } from "@/utils/strings"

export default {
  name: "MetadataConflictDetail",
  components: {
    MetadataConflictTable,
    PerformerComparison,
    MetadataConflictCandidatesSearchList: () => import("./MetadataConflictCandidatesSearchList")
  },
  props: {
    conflict: {
      type: Object,
      required: true,
    },
    solvedManually: {
      type: Array,
      required: false,
      default () { return [] }
    },
    standalone: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    ...mapMutations("alert", ["error", "success"]),
    ...mapMutations("user", ["setPreference"]),
    onLoaded ({ data }) {
      this.count = data.length
    },
    getData () {
      this.selectedRows = []
      let requestFn = this.$api.actions.userActionsMetadataConflictsRetrieve
      let queryParams = {}
      if (this.conflict.type === metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT) {
        requestFn = this.$api.actions.repertoireMatchingActionRetrieve
      } else if (this.conflict.type === metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT || this.conflict.type === metadataConflictTypeCodes.MAKERS_METADATA_CONFLICT) {
        requestFn = this.$api.actions.rightsHolderMatchingActionRetrieve
      } else {
        if (this.conflict.status === userActionStatuses.DONE) {
          queryParams = { status: this.conflict.status }
        }
      }
      requestFn(
        this.conflict.id,
        queryParams
      ).then(response => {
        this.landingObject = response.data.relatedRecord
        this.actionUuid = response.data.id
        this.$emit("opened", this.actionUuid)
        if (this.isRecordingAction) {
          this.parentObject = response.data.relatedRecord.recording
        } else {
          this.parentObject = response.data.relatedRecord.rightsHolder
        }
        this.candidates = response.data.candidates
      })
    },
    comparePerformers () {
      this.$bvModal.show("compare-performers-modal")
    },
    linkRecording (uuid) {
      this.solvedManually.push(this.actionUuid)
      this.$api.actions.metadataConflictSelectRecording(this.landingObject.id, { "recording_id": uuid })
        .then(() => {
          this.$emit("matched")
        })
        .catch(() => {
          this.$emit("notMatched")
        })
    },
    linkStatementRecording (uuid) {
      this.solvedManually.push(this.actionUuid)
      this.$api.actions.metadataConflictSelectStatementRecording(this.actionUuid, { "recording_id": uuid })
        .then(() => {
          this.$emit("matched")
        })
        .catch(() => {
          this.$emit("notMatched")
        })
    },
    additionalCandidatesFn (context) {
      if (this.isRecordingAction) {
        let params = {
          "fuzzy_search": context.$parent.$parent.fuzzy_search,
          "page": context.page,
          "sorting": "-has_usage",
        }
        if (this.showSameType) {
          params.type = this.landingObject.type
        }
        return this.$api.actions.metadataConflictSearchRecordings(params)
      } else {
        return this.$api.rightsholders.rightsholdersList({
          "search": context.$parent.$parent.fuzzy_search,
          "page": context.page
        })
      }
    },
    linkRightsHolder (uuid) {
      this.solvedManually.push(this.actionUuid)
      this.$api.actions.metadataConflictSelectRightsHolder(this.landingObject.id, { "rights_holder_id": uuid })
        .then(() => {
          this.$emit("matched")
        })
        .catch(() => {
          this.$emit("notMatched")
        })
    },
    linkStatementRightsHolder (uuid) {
      this.solvedManually.push(this.actionUuid)
      this.$api.actions.metadataConflictSelectStatementRightsHolder(this.actionUuid, { "rights_holder_id": uuid })
        .then(() => {
          this.$emit("matched")
        })
        .catch(() => {
          this.$emit("notMatched")
        })
    },
    createRecording () {
      let data = {
        "title": this.landingObject.title,
        "isrc": this.landingObject.isrc,
        "artist": this.landingObject.artist,
        "duration": this.landingObject.duration,
        "fixation_country": this.landingObject.fixationCountry,
        "fixation_year": this.landingObject.fixationYear,
        "type": this.landingObject.type,
      }
      this.solvedManually.push(this.actionUuid)
      this.$api.actions.metadataConflictCreateRecording(this.landingObject.id, data)
        .then(() => {
          this.$emit("matched")
        })
        .catch(() => {
          this.$emit("notMatched")
        })
    },
    createRightsHolder () {
      const nationalities = this.landingObject.nationalities.map(
        c => {
          return { ...c, alpha_2: c.alpha2, alpha_3: c.alpha3, }
        })
      let data = {
        "name": this.landingObject.name,
        "gender": this.landingObject.gender,
        "ipn": this.landingObject.ipn,
        "nationalities": nationalities,
        "type": this.landingObject.type,
      }
      this.solvedManually.push(this.actionUuid)
      this.$api.actions.metadataConflictCreateRightsHolder(this.landingObject.id, data)
        .then(() => {
          this.$emit("matched")
        })
        .catch(() => {
          this.$emit("notMatched")
        })
    },
    getMatchMethod () {
      if (this.isLandingAction) {
        return this.isLandingRecordingAction ? this.linkRecording : this.linkRightsHolder
      } else {
        return this.isStatementRecordingAction ? this.linkStatementRecording : this.linkStatementRightsHolder
      }
    },
    parseFirstMakersNationalities (row) {
      return row.firstMakers ? row.firstMakers.reduce((current, next) => {
        current.push(...next.nationalities)
        return current.sort()
      }, []) : []
    },
    parseFirstMakers (row) {
      return row.firstMakers ? row.firstMakers.reduce((current, next) => {
        current.push(next.name)
        return current.sort()
      }, []) : []
    },
    parsePerformers (row) {
      return row.performers.map(p => typeof p === "string" ? p : p.name )
    },
    parseFixationCountry (row) {
      return (row.fixationCountry && row.fixationCountry.alpha2) ? row.fixationCountry.alpha2 : row.fixationCountry
    },
    parseDateOfBirth (row) {
      if (row.dateOfBirth) {
        return parseDate(row.dateOfBirth, this.$config.DATE_FORMAT)
      }
    },
    compareField (value, key, item) {
      let landing_value = this.landingObject[key]
      if (key in this.transformationComparisons) {
        value = this.transformationComparisons[key](item)
        landing_value = this.transformationComparisons[key](this.landingObject)
      }
      if (this.highlightDifferences && this.fieldsToCompare.includes(key) && landing_value && value) {
        if (Array.isArray(value)) {
          if (value.length > 0 && landing_value.length > 0) {
            let c1 = value.map(v => toUpper(unaccent(v)))
            let c2 = landing_value.map(v => toUpper(unaccent(v)))
            return difference(c1, c2).length === 0 ? "green" : "red"
          }
        } else {
          return toUpper(unaccent(value)) === toUpper(unaccent(landing_value)) ? "green" : "red"
        }
      }
    },
    candidatesFields () {
      let recordingColumns = [
        { key: "resoundId", label: capitalize(this.$t("resound_id")), weight: 3  },
        { key: "title", label: capitalize(this.$t("title")), weight: 3 },
        { key: "artist", label: capitalize(this.$t("artist")), weight: 3  },
        { key: "isrc", label: this.$t("isrc"), weight: 2 },
        { key: "duration", label: capitalize(this.$t("duration")), weight: 2  },
        { key: "releases", label: capitalize(this.$t("releases")), weight: 3  },
        { key: "fixationYear", label: capitalize(this.$t("fixation_year")), weight: 2  },
        { key: "fixationCountry", label: capitalize(this.$t("fixation_country")), weight: 2  },
        { key: "firstMakers", label: capitalize(this.$t("first_makers")), weight: 3  },
        { key: "firstMakersNationalities", label: this.$t("fm_nationality"), weight: 2  },
        { key: "type", label: capitalize(this.$t("type")), weight: 2 },
        { key: "owners", label: capitalize(this.$t("owners")), weight: 2  },
        { key: "performers", label: capitalize(this.$t("performers")), weight: 2 },
        { key: "hasUsages", label: capitalize(this.$t("usages")), weight: 2 },
        { key: "actions", label: "", weight: 2  },
      ]
      let rightsHolderColumns = [
        { key: "resoundId", label: capitalize(this.$t("resound_id")), weight: 2  },
        { key: "name", label: capitalize(this.$t("name")), weight: 4 },
        { key: "recordingsCount", label: capitalize(this.$t("recordings")), weight: 2  },
        { key: "aliases", label: capitalize(this.$t("aliases")), weight: 4 },
        { key: "memberCollectives", label: capitalize(this.$t("collective")), weight: 3  },
        { key: "nationalities", label: capitalize(this.$t("nationalities")), weight: 3  },
      ]
      if (this.conflict.type === metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT) {
        rightsHolderColumns = rightsHolderColumns.concat([
          { key: "gender", label: capitalize(this.$t("gender")), weight: 2 },
          { key: "ipn", label: toUpper(this.$t("ipn")), weight: 4 },
          { key: "dateOfBirth", label: capitalize(this.$t("date_of_birth")), weight: 3 },
        ])
      }
      rightsHolderColumns.push({ key: "actions", label: "", weight: 2  })
      if (this.isRecordingAction) {
        return this.applyWeights(recordingColumns)
      } else {
        return this.applyWeights(rightsHolderColumns)
      }
    },
    columnFields () {
      let common_statement_columns =[
        { key: "sisterSociety", label: capitalize(this.$t("sister_society")), weight: 3  },
        { key: "amountGross", label: capitalize(this.$t("amount_gross")), weight: 2 },
      ]
      let recordingColumns = [
        { key: "proprietaryId", label: capitalize(this.$t("proprietary_id")), weight: 2  },
        { key: "title", label: capitalize(this.$t("title")), weight: 3  },
        { key: "artist", label: capitalize(this.$t("artist")), weight: 3 },
        { key: "isrc", label: this.$t("isrc"), weight: 2 },
        { key: "duration", label: capitalize(this.$t("duration")), weight: 2  },
        { key: "hasUsages", label: capitalize(this.$t("usages")), weight: 2  },
      ]
      if (this.conflict.type === metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT) {
        recordingColumns = recordingColumns.concat([
          { key: "releases", label: capitalize(this.$t("releases")), weight: 3  },
          { key: "fixationYear", label: capitalize(this.$t("fixation_year")), weight: 2  },
          { key: "firstMakers", label: capitalize(this.$t("first_makers")), weight: 3 },
          { key: "fixationCountry", label: capitalize(this.$t("fixation_country")), weight: 2  },
          { key: "firstMakersNationalities", label: this.$t("fm_nationality"), weight: 2 },
          { key: "type", label: capitalize(this.$t("type")), weight: 2  },
          { key: "owners", label: capitalize(this.$t("owners")), weight: 2  },
          { key: "performers", label: capitalize(this.$t("performers")), weight: 2  },
          { key: "sources", label: capitalize(this.$t("sources")), weight: 2 }
        ])
      }
      if (!this.isLandingAction) {
        recordingColumns = recordingColumns.concat(common_statement_columns)
        recordingColumns.push({ key: "type", label: capitalize(this.$t("type")), weight: 2 })
      }

      let rightsHolderColumns = [
        { key: "proprietaryId", label: capitalize(this.$t("proprietary_id")), weight: 2  },
        { key: "name", label: capitalize(this.$t("name")), weight: 4 },
        { key: "recordingsCount", label: capitalize(this.$t("recordings")), weight: 2  },
      ]

      if (this.conflict.type === metadataConflictTypeCodes.PERFORMERS_METADATA_CONFLICT ||
          this.conflict.type === statementConflictTypeCodes.STATEMENT_PERFORMER_MATCHING ) {
        rightsHolderColumns = rightsHolderColumns.concat([
          { key: "gender", label: capitalize(this.$t("gender")), weight: 2 },
          { key: "ipn", label: toUpper(this.$t("ipn")), weight: 4 },
          { key: "dateOfBirth", label: capitalize(this.$t("date_of_birth")), weight: 3 },
        ])
      }
      if (this.isLandingAction) {
        rightsHolderColumns = rightsHolderColumns.concat([
          { key: "aliases", label: capitalize(this.$t("aliases")), weight: 4 },
          { key: "nationalities", label: capitalize(this.$t("nationalities")), weight: 3  },
          { key: "memberCollectives", label: capitalize(this.$t("collective")), weight: 3  },
          { key: "sources", label: capitalize(this.$t("sources")), weight: 2  }
        ])
      } else {
        rightsHolderColumns = rightsHolderColumns.concat(common_statement_columns)
        rightsHolderColumns.push({ key: "recordingsCount", label: capitalize(this.$t("recordings")), weight: 2  })
      }
      if (this.isRecordingAction) {
        return this.applyWeights(recordingColumns, false)
      } else {
        return this.applyWeights(rightsHolderColumns, false)
      }
    },
    applyWeights (columns, compare=true) {
      const width_per_weight = Math.round(100 / columns.map(c => c.weight).reduce((a, b) => a +b, 0))
      const mod = 100 % width_per_weight
      columns =  columns.map(c => {
        let width = width_per_weight * c.weight
        if (c.key === columns[1].key) {
          width += mod
        }
        return {
          key: c.key,
          label: c.label,
          width: width,
          tdClass: (value, key, item) => {
            return compare ? ["w-" + width, this.compareField(value, key, item)] : ["w-" + width]
          },
          thClass: ["w-" + width],
        }
      })
      return columns
    },
    conflictItem (landing=true) {
      let data = landing ? this.landingObject : this.parentObject
      if (data) {
        let itemData = omit(data, [
          "firstMakers", "firstMakersNationalities", "performers", "alternativeIsrcs", "aliases", "nationalities", "collectives",
        ])
        itemData.firstMakers = data.firstMakers && data.firstMakers.length ? data.firstMakers : []
        itemData.firstMakersNationalities = data.firstMakers && data.firstMakers.length ? data.firstMakers.map(f => f.nationalities ? f.nationalities : []) : []
        itemData.performers = data.performers ? data.performers.map((x) => x.name) : []
        itemData.alternativeIsrcs = data.alternativeIsrcs ? data.alternativeIsrcs : []
        itemData.memberCollectives = data.memberCollectives ? data.memberCollectives : []
        itemData.aliases = data.aliases ? data.aliases : []
        itemData.nationalities = data.nationalities ? data.nationalities : []
        return [itemData]
      }
      return []
    }
  },
  data () {
    return {
      isRecordingAction: this.conflict.type === metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT ||
          this.conflict.type === statementConflictTypeCodes.STATEMENT_RECORDING_MATCHING,
      isLandingRecordingAction: this.conflict.type === metadataConflictTypeCodes.RECORDING_METADATA_CONFLICT,
      isStatementRecordingAction: this.conflict.type === statementConflictTypeCodes.STATEMENT_RECORDING_MATCHING,
      isLandingAction: Object.values(metadataConflictTypeCodes).includes(this.conflict.type),
      landingObject: null,
      parentObject: null,
      candidates: [],
      count: 0,
      actionUuid: "",
      fuzzy_search: "",
      showSameType: true,
      selectedRows: [],
      showLandingData: false,
      actionsTabs,
      transformationComparisons: {
        "firstMakersNationalities": this.parseFirstMakersNationalities,
        "firstMakers": this.parseFirstMakers,
        "fixationCountry": this.parseFixationCountry,
        "performers": this.parsePerformers,
        "dateOfBirth": this.parseDateOfBirth,
      },
      fieldsToCompare: [
        "title",
        "artist",
        "isrc",
        "fixationYear",
        "fixationCountry",
        "firstMakersNationalities",
        "firstMakers",
        "releases",
        "name",
        "ipn",
        "dateOfBirth",
        "nationalities",
        "owners",
        "type",
        "performers",
        "gender"
      ],
      highlightDifferences: true,
    }
  },
  computed: {
    ...mapState("user", ["waiting", "permissions"]),
    ...mapGetters("user", ["getPreference"]),
    buttonCaption () {
      return this.isRecordingAction ? this.$t("match_sr") : this.$t("match")
    },
    columns () {
      return this.candidatesFields().map(x => x["key"])
    },
    headings () {
      return this.candidatesFields().reduce((object, item) => {
        object[item["key"]] = item["label"]
        return object
      }, {})
    },
    columnsClasses () {
      return this.candidatesFields().reduce((object, item) => {
        object[item["key"]] = item["tdClass"](item["key"])[0]
        return object
      }, {})
    },
    cellClasses () {
      return this.candidatesFields().reduce((object, item) => {
        object[item["key"]] = [
          {
            class: "green",
            condition: (row) => item["tdClass"](row[item["key"]], item["key"], row).includes("green")
          },
          {
            class: "red",
            condition: (row) => item["tdClass"](row[item["key"]], item["key"], row).includes("red")
          }
        ]
        return object
      }, {})
    },
    filteredCandidates () {
      if (!this.isLandingRecordingAction || !this.showSameType) {
        return this.candidates
      }

      return this.candidates.filter(c => c.type === this.landingObject.type)
    },
    sameCandidateTypeCount () {
      return this.candidates.filter(c => c.type === this.landingObject.type).length
    },
    differentCandidateTypeCount () {
      return this.candidates.filter(c => c.type !== this.landingObject.type).length
    },
    totalCandidates () {
      return this.candidates.length
    },
    showLandingDataToggleAvailable () {
      return !this.standalone && this.isLandingAction
    }
  },
  mounted () {
    this.getData()
    if (!this.showLandingDataToggleAvailable) {
      this.showLandingData = true
    } else {
      this.showLandingData = this.getPreference(userPreferenceNames.MATCHING_ACTIONS_SHOW_LANDING_DATA, false)
    }
    this.highlightDifferences = this.getPreference(userPreferenceNames.MATCHING_ACTIONS_HIGHLIGHT_DIFFERENCES, true)
  },
  watch: {
    showLandingData (newValue) {
      if (this.showLandingDataToggleAvailable) {
        this.setPreference({ preference: userPreferenceNames.MATCHING_ACTIONS_SHOW_LANDING_DATA, value: newValue })
      }
    },
    highlightDifferences (newValue) {
      if (this.isLandingAction) {
        this.setPreference({ preference: userPreferenceNames.MATCHING_ACTIONS_HIGHLIGHT_DIFFERENCES, value: newValue })
      }
    },
    conflict () {
      this.fuzzy_search = ""
      this.getData()
    }
  },
  destroyed () {
    this.$emit("closed", this.actionUuid)
  }
}
</script>
