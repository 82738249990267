<template>
  <div>
    <no-data class="no-data" v-if="noData"></no-data>
    <b-table v-else :current-page="page" :items="challengesList" :fields="fields" fixed>
      <template v-slot:cell(created)="row">
        {{ getDate(row.item.created) }}
      </template>

      <template v-slot:cell(status)="row">
        <status
          v-if="row.item.status === userActionStatuses.PENDING"
          :text="$t('pending')"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-if="row.item.status === userActionStatuses.IN_PROGRESS"
          :text="$t('in_progress')"
          :icon="['fas', 'question-circle']"
          color="orange"
        />
        <status
          v-if="row.item.status === userActionStatuses.DONE"
          :text="$t('done')"
          :icon="['fas', 'check-circle']"
          color="green"
        />
      </template>

      <template v-slot:cell(showDetails)="row">
        <collapse-button
          :toggle="'row-' + row.item.id"
          :expanded="expanded[row.index]"
        />
      </template>

      <template v-slot:row-details="row">
        <b-collapse :id="'row-' + row.item.id" v-model="expanded[row.index]">
          <b-card class="challenge-detail">
            <b-row>
              <b-col><strong>{{ $t("challenged_by") | startcase }}:</strong>
                <template v-if="row.item.raisedBy">
                  {{ row.item.raisedBy.firstName }}
                  {{ row.item.raisedBy.lastName }}
                </template>
                <template v-else>
                  -
                </template>
              </b-col>
              <b-col v-if="!!row.item.user"><strong>{{ $t("answered_by") | startcase }}:</strong>
                {{ row.item.user.firstName }}
                {{ row.item.user.lastName }}
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </template>
    </b-table>

    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="total"
      :per-page="perPage"
    />
  </div>
</template>


<script>
import { perPageItemsSm, userActionStatuses } from "@/constants"
import { capitalize } from "lodash"
import { mapState } from "vuex"
import moment from "moment"
const perPage = perPageItemsSm
const actionStatuses = userActionStatuses

export default {
  props: {
    recordingId: {
      type: String,
      required: true,
    },
  },
  name: "ChallengeHistory",
  computed: {
    ...mapState("user", ["waiting"]),
    noData () {
      return (!this.waiting && this.challengesList.length < 1) || this.waiting
    }
  },
  methods: {
    getDate (date) {
      return moment(date).format("YYYY-MM-DD")
    },
    responseAdapter (data) {
      this.total = data.data.count
      this.challengesList = data.data.results
      this.challengesList.forEach((c) => {
        c["_showDetails"] = true
      })
    },
    async getData () {
      let payLoad = {
        page: this.page,
      }
      let response = await this.$api.repertoire.challengeHistory(
        this.recordingId,
        payLoad
      )
      this.responseAdapter(response)
    },
  },
  data () {
    return {
      challengesList: [],
      page: 1,
      total: 0,
      expanded: [],
      perPage: perPage,
      userActionStatuses: actionStatuses,
      fields: [
        { key: "recording.uuid", label: this.$t("id") },
        { key: "recording.isrc", label: this.$t("isrc") },
        { key: "created", label: capitalize(this.$t("challenged_on")) },
        { key: "source.uuid", label: this.$t("source_proprietary_id") },
        { key: "source.isrc", label: this.$t("source_isrc") },
        { key: "status", label: capitalize(this.$t("result")) },
        { key: "showDetails", label: "" },
      ],
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    page () {
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.challenge-detail {
  color: white;
  background: grey;
}

.no-data {
  padding: 15px;
}
</style>
