<template>
  <div>
    <v-server-table
      :columns="columns"
      :options="options"
      ref="history"
      @loaded="onLoaded"
      @loading="onLoading"
    >
      <template v-slot:created="{ row }">
        {{ parseDate(row.created, $config.DATETIME_FORMAT) }}
      </template>

      <template v-slot:createdBy="{ row }">
        {{ `${row.createdBy.firstName} ${row.createdBy.lastName}` }}
      </template>

      <template v-slot:isJunk="{ row }">
        {{ (row.isJunk ? $t("junk") : $t("not_junk")) | capitalize }}
      </template>
    </v-server-table>
    <b-row class="modal-footer float-right mt-4">
      <b-button @click="closeModal()" class="btn-outline-red">{{ $t("cancel") | capitalize }}</b-button>
    </b-row>
  </div>
</template>
<script>
import { capitalize } from "lodash"
import parseDate from "@/utils/date-parser"

export default {
  name: "JunkHistoryList",
  props: {
    usageUuid: {
      required: true,
      type: String,
    }
  },
  methods: {
    parseDate,
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    onLoading () {
      this.loading = true
    },
    closeModal () {
      this.$parent.$parent.hide()
    }
  },
  data () {
    return {
      columns: [
        "created",
        "createdBy",
        "isJunk",
        "comment",
      ],
      page: 1,
      count: 0,
      loading: false,
      options: {
        sortable: [],
        headings: {
          "created": capitalize(this.$t("created")),
          "createdBy": capitalize(this.$t("created_by")),
          "isJunk": capitalize(this.$t("marked_as")),
          "comment": capitalize(this.$t("comment")),
        },
        responseAdapter ({ data }) {
          return {
            data: data,
            count: data.length,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent
          return this.$api.usages.junkHistoryConsolidatedUsages(componentData.usageUuid, queryParams)
            .then(response => response)
            .catch(error => {
              let msg = `Error. ${JSON.stringify(error.response.data)}`
              componentData.error(msg)
            })
        },
      },
    }
  },
}
</script>