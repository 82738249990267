<template>
  <b-modal v-bind="$attrs" :modal-class="{'custom-modal': true, [modalType]: true}" @show="onShow">
    <!-- Header -->
    <template #modal-title>
      <b-img v-if="modalType === 'edit'" :src="require('@/assets/pen_circle.svg')" height="28" class="pr-2"/>
      <fa-icon v-if="modalType === 'info'" :icon="['fas', 'info-circle']" class="icon-blue mr-2"/>
      <fa-icon v-if="modalType === 'error'" :icon="['fas', 'exclamation-circle']" class="text-red mr-2"/>
      <h4 class="d-flex align-items-center mb-0">
        {{ customTitle }}
      </h4>
    </template>

    <!-- Body -->
    <slot></slot>

    <!-- Footer -->
    <template v-slot:modal-footer="{cancel, ok}">
      <slot name="footer" :cancel="cancel" :ok="ok">
        <b-button class="btn-outline-dark" @click="cancelClick">
          {{ cancelTitle }}
        </b-button>
        <b-button class="btn-outline-red" @click="okClick">
          {{ okTitle }}
        </b-button>
      </slot>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "modal",
  inheritAttrs: false,
  data: () => ({}),
  methods: {
    cancelClick () {
      this.$bvModal.hide(this.$attrs.id)
      this.$emit("cancel")
    },
    okClick () {
      this.$bvModal.hide(this.$attrs.id)
      this.$emit("ok")
    },
    onShow () {
      this.$emit("show")
    },
  },
  props: {
    okTitle: {
      type: String,
      default: "Accept",
    },
    cancelTitle: {
      type: String,
      default: "Cancel",
    },
    customTitle: {
      type: String,
      required: false,
    },
    modalType: {
      type: String,
      required: false,
    }
  },
}
</script>

<style lang="scss">
.custom-modal {
  .modal-header {
    border-bottom: none;
    padding: 15px 10px;
    .modal-title {
      display: flex;
      align-items: end;
    }
  }
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
    .table-wrapper {
      padding: 0 5px;
      border: 1px solid $gray-dark;
      border-radius: 4px;
      background-color: #FFFFFF;
    }
    .table-responsive {
      padding: 0 5px;
      background-color: #FFFFFF;
      .tabulator-header {
        border-top: none;
        border-bottom: 1px solid $gray-light;
        .tabulator-headers .tabulator-col {
          background-color: transparent !important;
        }
      }
      .tabulator-row:not(:last-child) {
        border-bottom: 1px solid $gray-dark;
      }
      &.table-border {
        border: 1px solid $gray-dark !important;
        border-radius: 4px;
      }
    }
    .pagination-border {
      border: none;
    }
    .table-striped {
      border: none;
    }
    table {
      thead {
        background-color: #FFFFFF !important;
      }
      thead th {
        border-top: none !important;
        border-bottom: 1px solid $gray-light;
      }
      tbody tr:not(:last-child) td {
        border-bottom: 1px solid $gray-dark;
      }
      tr{
        background-color: #FFFFFF !important;
      }
    }
    textarea {
      border: 1px solid $gray-light
    }
  }
  .modal-footer {
    padding-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-top: none;
    justify-content: space-between;
    .btn {
      min-width: 108px;
    }
  }

  &.edit {
    .modal-content {
      background-color: $modal-gray;
      border-left: 5px solid $gray-light;
    }
  }

  &.info {
    .modal-content {
      background-color: #FFFFFF;
      border-left: 5px solid $gray-light;
    }
    .modal-title {
      height: 28px;
      .icon-blue {
        color: $blue-dark;
        height: 100%;
        width: 28px;
      }
    }
    .modal-body {
      .table-responsive {
        border: none;
        padding: 0;
      }
    }
  }

  &.error {
    .modal-content {
      background-color: $red-error;
      border-left: 5px solid $red;
    }
    .modal-title {
      height: 28px;
      .text-red {
        color: $red;
        height: 100%;
        width: 28px;
      }
    }
    .modal-body {
      table {
        thead {
          background-color: $red-error !important;
        }
        tr{
          background-color: $red-error !important;
        }
      }
      .table-responsive {
        border: none;
        padding: 0;
      }
    }
  }
}
</style>
