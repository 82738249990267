import { API } from "./utils"


export const actions = {
  userActionsMetadataConflicts: (params=null) => API.get(
    "/user-actions/metadata-conflicts/",
    { params: params }),
  repertoireMatchingActions: (params=null) => API.get(
    "/user-actions/repertoire-matching/",
    { params: params }),
  rightsHolderMatchingActions: (params=null) => API.get(
    "/user-actions/rights-holder-matching/",
    { params: params }),
  usageConflictsSelectRecording: (data) => API.post("/user-actions/metadata-conflicts/select_usages_recording/", data,
    { suppressAlert: true }),
  userActionsMetadataConflictsRetrieve: (uuid, params=null) => API.get(`/user-actions/metadata-conflicts/${uuid}/`,
    { params: params }),
  repertoireMatchingActionRetrieve: (uuid, params=null) => API.get(`/user-actions/repertoire-matching/${uuid}/`,
    { params: params }),
  performerComparisonActionRetrieve: (uuid, data) => API.post(`/user-actions/repertoire-matching/${uuid}/performer_comparison/`, data),
  rightsHolderMatchingActionRetrieve: (uuid, params=null) => API.get(`/user-actions/rights-holder-matching/${uuid}/`,
    { params: params }),
  metadataConflictSelectRecording: (uuid, data) => API.post(`/user-actions/repertoire-matching/${uuid}/select_recording/`, data,
    { suppressAlert: true }),
  metadataConflictSelectStatementRecording: (uuid, data) => API.post(`/user-actions/metadata-conflicts/${uuid}/statement_select_recording/`, data,
    { suppressAlert: true }),
  metadataConflictCreateRecording: (uuid, data) => API.post(`/user-actions/repertoire-matching/${uuid}/create_recording/`, data,
    { suppressAlert: true }),
  metadataConflictSelectRightsHolder: (uuid, data) => API.post(`/user-actions/rights-holder-matching/${uuid}/select_rights_holder/`, data,
    { suppressAlert: true }),
  metadataConflictSelectStatementRightsHolder: (uuid, data) => API.post(`/user-actions/metadata-conflicts/${uuid}/statement_select_rights_holder/`, data,
    { suppressAlert: true }),
  metadataConflictCreateRightsHolder: (uuid, data) => API.post(`/user-actions/rights-holder-matching/${uuid}/create_rights_holder/`, data,
    { suppressAlert: true }),
  metadataConflictSearchRecordings: (params=null) => API.get(
    "/user-actions/repertoire-matching/search_recordings/",
    { params: params }),
  recordingEligibilityConflictActions: (params=null) => API.get("/user-actions/recording-eligibility-conflicts/", { params }),
  recordingEligibilityConflictActionsRetrieve: (uuid, params=null) => API.get(`/user-actions/recording-eligibility-conflicts/${uuid}/`, { params }),
  recordingChallengeActions: (params=null) => API.get("/user-actions/recording-challenges/", { params }),
  recordingChallengeActionsRetrieve: (uuid, params=null) => API.get(`/user-actions/recording-challenges/${uuid}/`, { params }),
  confirmLandingRecordingMatchingChallenge: (uuid) => API.post(`/user-actions/recording-challenges/${uuid}/confirm_match/`),
  unlinkLandingRecordingMatchingChallenge: (uuid) => API.post(`/user-actions/recording-challenges/${uuid}/unlink_landing_recording/`),
  confirmLandingPerformerMatchingChallenge: (uuid) => API.post(`/user-actions/performer-challenges/${uuid}/confirm_match/`),
  confirmLandingMakerMatchingChallenge: (uuid) => API.post(`/user-actions/maker-challenges/${uuid}/confirm_match/`),
  unlinkLandingPerformerMatchingChallenge: (uuid) => API.post(`/user-actions/performer-challenges/${uuid}/unlink_landing_performer/`),
  unlinkLandingMakerMatchingChallenge: (uuid) => API.post(`/user-actions/maker-challenges/${uuid}/unlink_landing_maker/`),
  userActionsLineupConflicts: (params=null) => API.get("/user-actions/lineup-conflicts/",
    { params: params }),
  ownershipConflicts: (params=null) => API.get("/user-actions/ownership-conflicts/", { params }),
  ownershipConflictsRetrieve: (uuid) => API.get(`/user-actions/ownership-conflicts/${uuid}`),
  ownershipConflictsExport: (data) => API.post("/user-actions/ownership-conflicts/export/", data),
  mandateConflicts: (params=null) => API.get("/user-actions/mandate-conflicts/", { params }),
  usageChallengeCreate: (data=null) => API.post("/user-actions/usage-challenges/", data),
  resolveUsageMatchingChallenge: (uuid, data) => API.post(`/user-actions/usage-challenges/${uuid}/resolve_challenge/`, data),
  unmatchUsageMatchingChallenge: (uuid) => API.post(`/user-actions/usage-challenges/${uuid}/unmatch_challenge/`),
  usageChallengeActions: (params=null) => API.get("/user-actions/usage-challenges/", { params }),
  lineupAdjustmentActions: (params=null) => API.get("/user-actions/lineup-negative-adjustments/", { params }),
  resolveLineupAdjustmentAction: (uuid, data=null) => API.post(`/user-actions/lineup-negative-adjustments/${uuid}/resolve/`, data),
  resolveOwnershipAdjustmentAction: (uuid, data=null) => API.post(`/user-actions/ownership-negative-adjustments/${uuid}/resolve/`, data),
  ownershipAdjustmentActions: (params=null) => API.get("/user-actions/ownership-negative-adjustments/", { params }),
  usageAdjustmentActions: (params=null) => API.get("/user-actions/usage-adjustment-conflicts/", { params }),
  usageAdjustmentActionsRetrieve: (uuid, params=null) => API.get(`/user-actions/usage-adjustment-conflicts/${uuid}/`, { params }),
  resolveUsageAdjustmentAction: (uuid, data=null) => API.post(`/user-actions/usage-adjustment-conflicts/${uuid}/resolve/`, data),
  duplicatedIsrc: (params=null) => API.get("/user-actions/isrc-conflicts/", { params }),
  duplicatedIsrcDetail: (uuid, params=null) => API.get(`/user-actions/isrc-conflicts/${uuid}/`, { params }),
  duplicatedIpn: (params=null) => API.get("/user-actions/ipn-conflicts/", { params }),
  duplicatedIpnDetail: (uuid, params=null) => API.get(`/user-actions/ipn-conflicts/${uuid}/`, { params }),
  undoRecordingMatchingAction: (uuid) => API.post(`/user-actions/repertoire-matching/${uuid}/undo/`),
  undoRightsHolderMatchingAction: (uuid) => API.post(`/user-actions/rights-holder-matching/${uuid}/undo/`),
  recordingMergeRequests: (params=null) => API.get("/user-actions/recording-merge-requests/", { params }),
  recordingMergeRequest: (uuid) => API.get(`/user-actions/recording-merge-requests/${uuid}/`),
  recordingMergeRequestApprove: (uuid, data) => API.post(`/user-actions/recording-merge-requests/${uuid}/merge_recordings/`, data),
  recordingMergeRequestReject: (uuid, data) => API.post(`/user-actions/recording-merge-requests/${uuid}/reject_merge/`, data)
}
