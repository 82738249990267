<template>
  <div class="d-flex">
    <b-form-group
      >
      <b-form-input
        v-if="showTooltip"
        placeholder="From"
        v-b-tooltip.hover.v-light
        :title="emptyBoundHelpText"
        v-model.number="lowerBound"
      />
      <b-form-input v-else v-model.number="lowerBound"/>
    </b-form-group>

    <b-form-group
      >
      <b-form-input
        v-if="showTooltip"
        placeholder="To"
        v-b-tooltip.hover.v-light
        :title="emptyBoundHelpText"
        v-model.number="upperBound"
      />
      <b-form-input v-else v-model.number="upperBound"/>
    </b-form-group>
  </div>
</template>

<script>
import { isArray } from "lodash"
export default {
  name: "DualRange",
  props: {
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    singleBound: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array
    }
  },
  data () {
    return {
      lowerBound: null,
      upperBound: null,
      // show tooltip is the range is not strict, to indicate possible empty bound
      showTooltip: this.singleBound,
      emptyBoundHelpText: this.$t("range_filter_empty_bound_description")
    }
  },
  methods: {
    focus () {},
    isNumberOrEmpty (value) {
      if (typeof value === "string" && !isNaN(value)) {
        value = Number(value)
      }
      return (typeof value === "number" || value === "")
    },
    parseValue (value) {
      if (isArray(value)) {
        if (!this.isNumberOrEmpty(value[0])) {
          this.lowerBound = 0
        } else this.lowerBound = value[0]

        if (!this.isNumberOrEmpty(value[1])) {
          this.upperBound = 0
        } else this.upperBound = value[1]
      }
    }
  },
  watch: {
    value (newVal) {
      this.parseValue(newVal)
    },
    lowerBound () {
      this.$emit("input", [this.lowerBound, this.upperBound])
    },
    upperBound () {
      this.$emit("input", [this.lowerBound, this.upperBound])
    }
  },
  mounted () {
    this.parseValue(this.value)
  }
}
</script>
