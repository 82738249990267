<template>
  <validation-provider
    v-slot="{ valid, changed, dirty, errors, validate }"
    :rules="rules"
    :vid="vid"
    ref="provider"
    :name="label"
    :detect-input="detectInput"
    :immediate="immediate"
  >
    <b-form-group v-bind="$attrs" :state="changed || dirty ? valid : null" :invalid-feedback="errors[0] | capitalize">
      <template slot="label">
        <span>
          {{ label }}
        </span>
        <fa-icon v-if="description" v-b-tooltip.hover.right="{ variant: 'light', title: description }" :icon="['far', 'question-circle']" class="text-gray"/>
      </template>
      <slot v-bind:state="changed || dirty ? valid : null" v-bind:validate="validate">
        <slot />
      </slot>
    </b-form-group>
  </validation-provider>
</template>

<script>

import { ValidationProvider } from "vee-validate"


export default {
  name: "DistributionFormGroup",
  components: { ValidationProvider },
  props: {
    description: String,
    label: String,
    rules: String,
    vid: {
      type: String,
      required: true,
    },
    detectInput: {
      type: Boolean,
      default: true,
    },
    immediate: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    setErrors (errors) {
      this.$refs.provider.setErrors(errors)
      this.$refs.provider.setFlags({ dirty: true })
    }
  }
}
</script>

<style lang="scss" scoped>
  legend:has(+ div :is(input,select,div.multiselect)[required]) span::after {
    content: "*";
    color: #c03d5a;
    margin-left: 3px;
    margin-right: 3px;
  }

  .form-row > .col {
    display: flex;

    &>:is(input,select,div.multiselect,div.input-group,textarea) {
      max-width: 50%;
    }

  }
  .invalid-feedback {
    padding-left: 10px;
    margin-left: 0;
    margin-top: 8px;
    max-width: 50%;
  }
  .mx-datepicker {
    width: 100%;
    max-width: 50%;
    display: block;
    position: initial;
    &.invalid { .mx-input { border-color: var(--danger); } }
  }

</style>
