const state = {
  rightsHolderMergeCart: [],
  recordingMergeCart: [],
}

const mutations = {
  resetState () {
    state.rightsHolderMergeCart = []
    state.recordingMergeCart = []
  },
  clearCartMixinVersion () {
    this.viewingCart = !this.viewingCart
    this.resetState()
    this.getData()
  },
  setMergeCart (state, payload) {
    if (!state[payload.cart].map((c) => c.id).includes(payload.data.id)) {
      state[payload.cart].push(payload.data)
    }
    else {
      state[payload.cart] = state[payload.cart].filter(c => c.id !== payload.data.id)
    }
  },
  resetMergeCart (state, type) {
    state[type] = []
  },
}

export const mergers = {
  namespaced: true,
  state,
  mutations,
}
