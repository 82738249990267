<template>
  <div
    :class="[inline ? 'd-inline' : 'd-inline-flex align-items-center', { clickable }]"
    @click="$emit('click', name )"
  >
    <div v-if="!!icon" class="icon mr-1" :class="{ 'd-inline': inline }">
      <fa-icon  class="icon" :title="$t(name) | capitalize" :size="(size ? size : 'sm')" :class="color" :icon="icon"/>
    </div>

    <div :class="[classValue, { 'd-inline': inline }]">
      <span :class="(bold ? 'font-weight-bold' : '')">{{ text }}</span>
      <span v-if="showCount()" class="count mr-2">({{ count }})</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .green {
    color: $green-darker;
  }

  .orange {
    color: var(--orange);
  }

  .red {
    color: var(--red);
  }

  .gray {
    color: var(--gray);
  }

  .blue {
    color: var(--blue);
  }

  .icon {
    min-width: 20px;
  }

  .text {
    color: var(--gray);
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.2px;
  }

  .count {
    min-width: 15px;
    text-align: right;
    display: inline-block;
  }

  .active {
    span {
      color: var(--primary);
      font-weight: 600;
    }
  }
</style>

<script>
import { isNumber } from "lodash"

export default {
  name: "Status",
  props: {
    text: String,
    name: String,
    count: [String, Number],
    icon: Array,
    color: String,
    clickable: Boolean,
    active: Boolean,
    size: String,
    bold: Boolean,
    inline: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    classValue () {
      let className = this.size ? this.size : "text"
      return this.active ? ("active " + className) : className
    }
  },
  methods: {
    showCount () {
      return isNumber(this.count)
    }
  }
}
</script>
