<template>
  <div>
    <no-data class="no-data" v-if="noData"></no-data>
    <b-table
      striped
      v-else
      :current-page="page"
      :items="logActionsList"
      :fields="fields"
      fixed
    >
      <template v-slot:cell(date)="row">
        {{ getDate(row.item.date) }}
      </template>

      <template v-slot:cell(type)="row">
        <span v-if="row.item.type === 'recording'">{{ $t("core data") | capitalize }}</span>
        <span v-else-if="row.item.type === 'lineupmember' || row.item.type === 'recordinglineupcategory'">{{ $t("lineup") | capitalize }}</span>
      </template>

      <template v-slot:cell(changedFields)="row">
        <div
          class="mb-2"
          v-for="(field, i) in row.item.changedFields"
          :key="`field-${i}`"
        >
          <ActionHistoryElement :field="field.fieldName" :show-tooltip="false">
            <template v-slot:default>
              {{ field.fieldName | startcase }}
            </template>
          </ActionHistoryElement>
        </div>
      </template>

      <template v-slot:cell(old)="row">
        <div
          class="mb-2"
          v-for="(field, i) in row.item.changedFields"
          :key="`field-${i}`"
        >
          <ActionHistoryElement :field="field.from"></ActionHistoryElement>
        </div>
      </template>

      <template v-slot:cell(new)="row">
        <div
            class="mb-2"
            v-for="(field, i) in row.item.changedFields"
            :key="`field-${i}`"
        >
          <ActionHistoryElement :field="field.to"></ActionHistoryElement>
        </div>
      </template>
    </b-table>

    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="total"
      :per-page="perPage"
    />
  </div>
</template>


<script>
import { capitalize, startCase } from "lodash"
import { logActionTypes, perPageItemsSm, userActionStatuses } from "@/constants"
import { mapActions, mapState } from "vuex"
import ActionHistoryElement from "@/pages/Repertoire/Recordings/RecordingDetail/History/ActionHistoryElement"
import { get } from "lodash"
import moment from "moment"
const perPage = perPageItemsSm
const actionStatuses = userActionStatuses

export default {
  components: { ActionHistoryElement },
  props: {
    recordingId: {
      type: String,
      required: true,
    },
  },
  name: "ActionsHistory",
  computed: {
    ...mapState("consts", ["countries", "tariffs"]),
    ...mapState("user", ["waiting"]),
    noData () {
      return (!this.waiting && this.logActionsList.length < 1) || this.waiting
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", "getTariffs"]),
    getDate (date) {
      return moment(date).format("YYYY-MM-DD")
    },
    responseAdapter (data) {
      let changes = []
      this.total = data.data.count
      data.data.results.forEach(function (result){
        let changedFields = []
        switch (result.objectType) {
        case "recording":
          switch (result.actionType) {
          case logActionTypes.CARVEOUT:
            changedFields = Object.keys(result.changeMessage)
              .filter((f) => f !== "locks")
              .map((cm) => {
                let message = get(result.changeMessage, cm, null)
                return {
                  fieldName: cm,
                  from: "",
                  to: get(message, "to", message),
                }
              })
            break
          case logActionTypes.ADD_SHARE:case logActionTypes.EDIT_SHARE:
            changedFields = Object.keys(result.changeMessage)
              .filter((f) => f !== "recordingId")
              .map(function (cm) {
                let message = get(result.changeMessage, cm, null)
                if (Array.isArray(message) && (cm === "excludeCountries" || cm === "countries")) {
                  message = this.countries.filter(d => message.includes(d.code)).map(c => c.text).join(", ")
                } else if (Array.isArray(message) && (cm === "excludeTariffs" || cm === "tariffs")) {
                  message = this.tariffs.filter(t => message.includes(String(t.code))).map(c => c.text).join(", ")
                }
                return {
                  fieldName: cm,
                  from: "",
                  to: get(message, "to", message),
                }
              }.bind(this))
            break
          default:
            changedFields = Object.keys(result.changeMessage)
              .filter((f) => f !== "locks")
              .map((cm) => {
                return {
                  fieldName: cm,
                  from: result.changeMessage[cm].from,
                  to: result.changeMessage[cm].to,
                }
              })
          }
          break
        case "lineupmember": {
          let fromMessages = [logActionTypes.DELETION, logActionTypes.CHANGE]
          let toMessages = [logActionTypes.ADDITION, logActionTypes.CHANGE]

          changedFields = Object.keys(result.changeMessage)
            .filter((f) => f !== "recordingId")
            .map(cm => {
              let message = get(result.changeMessage, cm, null)
              return {
                fieldName: cm,
                from: fromMessages.includes(result.actionType) ? get(message, "from", message) : "",
                to: toMessages.includes(result.actionType) ? get(message, "to", message) : "",
              }
            })
          break
        }
        case "recordinglineupcategory":
          changedFields = Object.keys(result.changeMessage)
            .filter((f) => !f.includes("Locked"))
            .map((cm) => {
              return {
                fieldName: cm,
                from: result.changeMessage[cm].from,
                to: result.changeMessage[cm].to,
              }
            })
        }
        changes.push({
          userId: result.user.uuid,
          userName: `${result.user.firstName} ${result.user.lastName}`,
          date: result.actionTime,
          changedFields: changedFields,
          type: result.objectType
        })

      }.bind(this))
      this.logActionsList = changes
    },
    async getData () {
      let payLoad = {
        page: this.page,
      }
      let response = await this.$api.repertoire.actionsHistory(
        this.recordingId,
        payLoad
      )
      this.responseAdapter(response)
    },
  },
  data () {
    return {
      logActionsList: [],
      page: 1,
      total: 0,
      expanded: [],
      perPage: perPage,
      userActionStatuses: actionStatuses,
      fields: [
        { key: "userId", label: startCase(this.$t("user_id")) },
        { key: "userName", label: startCase(this.$t("user_name")) },
        { key: "date", label: capitalize(this.$t("date")) },
        { key: "type", label: startCase(this.$t("type")) },
        { key: "changedFields", label: capitalize(this.$t("input")) },
        { key: "old", label: startCase(this.$t("old_data")) },
        { key: "new", label: startCase(this.$t("new_data")) },
      ],
    }
  },
  mounted () {
    if (!this.countries.length) {
      this.getCountries()
    }
    this.getData()
  },
  watch: {
    page () {
      this.getData()
    },
  },
}
</script>
<style lang="scss" scoped>
  .no-data {
    padding: 15px;
  }
</style>
