<template>
  <div>
    <list-layout
        :title="$t('stations') | capitalize"
        :apply-filters="applyFilters"
        :reset-filters="resetFilters"
        :filters-pending="filtersPending"
        :filters-applied="filtersApplied"
    >
      <!-- Search -->
      <template v-slot:search>
        <b-form-group>
          <form-field
            :placeholder="$t('search') | capitalize"
            :enter-action="applyFilters"
            v-model="filters.search.value"
            :icon="['fas', 'search']"
          />
        </b-form-group>
      </template>

      <!-- Counts -->
      <template v-slot:counts>
        <div class="row align-items-center">
          <div class="col d-flex">
            <pagination
              class="smaller p-0"
              reference="stations"
              :page="page"
              :count="count"
              :loading="loading"
              v-model="limit"
              @limit="onLimit"
            />
          </div>
        </div>
      </template>

      <template v-slot:filters>
        <b-row align-h="end" class="mr-1">
          <b-col cols="4">
            <b-form-group
              :label="$t('broadcasters') | startcase"
              label-class="font-weight-bold pb-0"
            >
              <b-form-select v-model="filters.broadcaster.value" :options="broadcastersOptions">
                <template v-slot:first>
                  <b-form-select-option :value="null">{{
                    $t("all") | capitalize
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <!-- Table -->
      <template v-slot:table>
        <v-server-table
          ref="stations"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="onLoading"
          @pagination="onPagination"
          @sorted="onSorted"
        >
          <template v-slot:name="{ row }">
            <router-link
              v-if="row.id"
              class="text-info"
              :to="{
                name: 'station-detail',
                params: { stationId: row.id },
              }"
            >
              {{ row.name }}
            </router-link>
          </template>
        </v-server-table>
      </template>
    </list-layout>
  </div>
</template>

<script>
import { capitalize, get } from "lodash"
import Pagination from "@/components/Pagination"
import { getPerPageItemDefaults } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"

export default {
  name: "StationsList",
  mixins: [listRouteMixin],
  components: {
    Pagination,
  },
  methods: {
    onLoaded ({ data }) {
      this.count = data.count
      this.summary = data.summary
      this.loading = false
    },
    getData () {
      this.$refs.stations.getData()
    },
    async getBroadcasters () {
      let response = await this.$api.broadcasters.broadcastersList()
      this.broadcasters = response.data.results
    },
  },
  data () {
    return {
      page: 1,
      loading: false,
      pageNumber: 1,
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      broadcasters: [],
      columns: ["salesforceId", "name", "broadcaster"],
      options: {
        columnsClasses: {
          resoundId: "width-micro",
          name: "width-medium",
          stationsCount: "width-medium",
        },
        sortable: [],
        headings: {
          salesforceId: this.$t("salesforce_id"),
          name: capitalize(this.$t("station_name")),
          broadcaster: capitalize(this.$t("broadcaster_name")),
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          componentData.pageNumber = this.page
          return this.$api.broadcasters.stationsList(queryParams)
        },
      },
      filters: {
        search: { value: "", defaultValue: "" },
        broadcaster: { value: "", defaultValue: null },
      },
    }
  },
  computed: {
    broadcastersOptions () {
      return this.broadcasters.map(x => ({ text: x.name, value: x.name }))
    },
  },
  mounted () {
    this.getBroadcasters()
    this.updateTableSortIcons(this.$refs.stations.$children[0])
  },
}
</script>
