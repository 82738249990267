<template>
  <div class="ml-0">
    <div v-b-popover.focus.bottomleft="popoverData" class="blue clickable" tabindex="0">
      <fa-icon
        class="blue clickable ml-2"
        size="sm"
        :icon="['fas', 'server']"
      />
      <span v-if="sources"> ({{ sources.length }}) </span>
      <fa-icon
        :icon="['fas', 'caret-down']"
      />
    </div>
  </div>
</template>

<script>
import SourceItem from "@/components/SourceItem"
import Vue from "vue"

export default {
  name: "Sources",
  components: { SourceItem, }, // eslint-disable-line
  props: {
    sources: Array,
    sourceType: String,
  },
  methods: {
    getHTMLRow (source) {
      const sourceItem = Vue.extend(SourceItem)
      const mountedComponent = new sourceItem({
        parent: this,
        propsData: { source: source, sourceType: this.sourceType }
      }).$mount()
      return mountedComponent.$el.innerHTML
    }
  },
  computed: {
    popoverData () {
      let content = ""
      if (this.sources && this.sources.length) {
        this.sources.forEach(source => {content += this.getHTMLRow(source)})
      }
      return {
        content: content,
        html: true,
      }
    }
  }
}
</script>
