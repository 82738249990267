<template>
  <div>
    <a class="tooltip-link d-flex mt-2 overflow-break" :href="getHref()">
      <fa-icon class="clickable mr-1" :icon="['fas', source.filename ? 'file-upload': 'upload']"/>
      <span class="h6 mb-0"> {{ getSourceName() }} </span>
    </a>
    <span>Uploaded on {{ uploadedDate.format("DD/MM/YYYY") }} at {{ uploadedDate.format("HH:mm:ss") }} by {{ getUploaderName() }}</span>
  </div>
</template>

<script>
import { capitalize, upperCase } from "lodash"
import moment from "moment"
import { sourceTypes } from "@/constants"

export default {
  name: "SourceItem",
  props: {
    source: {
      type: Object,
      required: true,
    },
    sourceType: {
      type: String,
    }
  },
  computed: {
    uploadedDate () {
      return moment(this.source.uploadDate)
    },
  },
  methods: {
    getUploaderName () {
      if (this.source.uploader && typeof this.source.uploader === "object") {
        return this.source.uploader.firstName + " " + this.source.uploader.lastName
      }
      return this.source.uploader
    },
    getSourceName () {
      return this.source.filename ? this.source.filename :  upperCase(this.$t("sf")) + " " + capitalize(this.$t("import"))
    },
    getHref () {
      // TODO: Can be updated for Usage Files
      let linkParams = { name: "landing-recordings", params: { repFileId: this.source.id } }
      if (this.sourceType === sourceTypes.RIGHTSHOLDER_BATCH) {
        linkParams = { name: "landing-rightsholders-list", params: { batchId: this.source.id }, }
      }
      if (this.sourceType === sourceTypes.PAYMENT_FILE) {
        linkParams = { name: "payment-file-details", query: { source: this.source.id }, params: { fileName: this.source.filename } }
      }
      const route = this.$router.resolve(linkParams)

      return route.href
    },
  }
}
</script>
