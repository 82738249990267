<template>
  <div>
    <v-server-table
      ref="adjustments"
      :columns="columns"
      :options="options"
      @row-click="selectAdjustment"
      @loaded="onLoaded"
      @loading="onLoading"
      @pagination="onPagination"
      @sorted="onSorted"
    >
      <template v-slot:inProgress="{ row }">
        <modal
          :id="`confirmation-modal-solved-${row.id}`"
          :customTitle="$t('user_action_solved') | capitalize"
          modal-class="custom-modal edit"
          modalType="edit"
          size="md"
          centered
          hide-footer
          hide-header-close
          no-close-on-esc
          no-close-on-backdrop
        >
          <div>{{ $t("user_action_has_been_solved") | capitalize }}</div>
          <b-row class="float-right modal-footer mt-4">
            <b-button @click="blinkAndHide(row.id, null, getData)" class="btn-outline-red">
              {{ $t("accept") | capitalize }}
            </b-button>
          </b-row>
        </modal>
        <div>
          <fa-icon
            v-if="isInProgressFrontend(row.id) || (isInProgressBackend(row.userActionsStatuses) && !isReleasedFrontend(row.id))"
            :icon="['fa', 'user-edit']"
            color="red"
            class="mr-2"
          />
        </div>
      </template>
      <template v-slot:resoundId="{ row }">
        <database-link :type="linkTypes.RECORDING" :item-id="row.id">
          {{ row.resoundId }}
        </database-link>
      </template>
      <!-- Royalties paid -->
      <template v-slot:royaltiesPaid="{ row }">
        <span class="smaller">
          CAD
        </span>
        {{ row.royaltiesPaid }}
      </template>

      <!-- Estimated track value -->
      <template v-slot:estimatedTrackValue="{ row }">
        <span class="smaller">
          CAD
        </span>
        {{ row.estimatedTrackValue }}
      </template>
    </v-server-table>
  </div>
</template>
<script>
import { adjustmentTypes, linkTypes, userActionNotifications } from "@/constants"
import { capitalize, get, upperCase } from "lodash"
import { mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import { websocketsMixin } from "@/utils/wsmixin"

export default {
  name: "NegativeAdjustmentsRecordingList",
  components: { DatabaseLink },
  mixins: [websocketsMixin],
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    adjustmentType: {
      type: String,
      required: true,
    }
  },
  watch: {
    adjustmentType () {
      this.releaseAll()
      if (this.adjustmentType === adjustmentTypes.LINEUP) {
        this.userActionNotification = userActionNotifications.LINEUP_ADJUSTMENT
        this.storeStatusesKey = "wsRecordingLineupNegativeAdjustmentStatuses"
      } else {
        this.userActionNotification = userActionNotifications.OWNERSHIP_ADJUSTMENT
        this.storeStatusesKey = "wsRecordingOwnershipNegativeAdjustmentStatuses"
      }
    }
  },
  methods: {
    ...mapMutations("alert", ["error"]),
    setLimit (limit) {
      this.$refs.adjustments.setLimit(limit)
    },
    getData () {
      this.selectAdjustment(null)
      this.$refs.adjustments.getData()
    },
    onLoaded (data) {
      this.$emit("onLoaded", data)
    },
    onLoading () {
      this.$emit("onLoading")
    },
    onPagination (page) {
      this.$emit("onPagination", page)
    },
    onSorted (data) {
      this.$emit("onSorted", data)
    },
    selectAdjustment (adjustment) {
      adjustment = adjustment ? adjustment.row || adjustment : null
      this.$emit("selected", adjustment)
      if (adjustment) {
        this.onOpened(adjustment.id)
      }
    },
  },
  computed: {
    ...mapState("userActions", ["wsRecordingLineupNegativeAdjustmentStatuses", "wsRecordingOwnershipNegativeAdjustmentStatuses"]),
    // WS values
    shownUuidList () {
      return this.data.map(c => c.id)
    },
  },
  mounted () {
    // We need to mount both watchers by hand
    this.$watch("wsRecordingLineupNegativeAdjustmentStatuses", this.statusWatcher, { deep: true })
    this.$watch("wsRecordingOwnershipNegativeAdjustmentStatuses", this.statusWatcher, { deep: true })
  },
  data () {
    return {
      linkTypes,
      data: [],
      columns: [
        "inProgress",
        "resoundId",
        "isrc",
        "title",
        "artist",
        "playCount",
        "weightedPlayCount",
        "royaltiesPaid",
        "estimatedTrackValue",
      ],
      options: {
        rowClassCallback: (row) => {
          let classes = ["hover-highlight"]
          if (this.shouldBlink(row.id)) {
            classes.push("blink")
          }
          if (this.isHidden(row.id)) {
            classes.push("d-none")
          }
          return classes.join(" ")
        },
        columnsClasses: {
          inProgress: "width-2",
          resoundId: "width-mini",
          isrc: "width-small",
          title: "width-large",
          artist: "width-large",
          playCount: "width-small",
          weightedPlayCount: "width-small",
          royaltiesPaid: "width-small",
          estimatedTrackValue: "width-small",
        },
        sortable: [],
        headings: {
          inProgress: "",
          resoundId: this.$t("resound_id"),
          isrc: upperCase(this.$t("isrc")),
          title: capitalize(this.$t("title")),
          artist: capitalize(this.$t("artist")),
          playCount: capitalize(this.$t("playcount")),
          weightedPlayCount: capitalize(this.$t("weighted_playcount")),
          royaltiesPaid: capitalize(this.$t("royalties_paid")),
          estimatedTrackValue: capitalize(this.$t("estimated_track_value")),
        },
        responseAdapter ({ data }) {
          let componentData = this.$parent.$parent
          componentData.data = data.results
          if (data.results.length === 1) {
            componentData.selectAdjustment(data.results[0])
          }
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          let componentData = this.$parent.$parent
          queryParams = { ...queryParams, ...this.$route.query }
          this.page = Number(get(queryParams, "page", 1))
          // we need to get this from the route because either way it'll always be the default value
          let adjustmentType = get(this.$route.query, "adjustment_type", adjustmentTypes.LINEUP)
          componentData.pageNumber = this.page
          if (adjustmentType === adjustmentTypes.LINEUP) {
            return this.$api.actions.lineupAdjustmentActions(queryParams)
          } else {
            return this.$api.actions
              .ownershipAdjustmentActions(queryParams)
              .catch((e) => {
                if (e.response.data.fuzzySearch) {
                  this.error(capitalize(this.$t("wrong_filters")))
                }
              })
          }
        },
      },
      // WS Values
      reloadMethod: this.getData,
      userActionNotification: userActionNotifications.LINEUP_ADJUSTMENT,
      storeStatusesKey: "wsRecordingLineupNegativeAdjustmentStatuses",
    }
  }
}
</script>
<style lang="scss" scoped>
.table-responsive {
  .table {
    tr {
      &.hover-highlight {
        &:hover {
          cursor: pointer;
          background-color: $red-light;
        }
      }
    }
  }
}
</style>
