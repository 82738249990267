<template>
  <list-layout 
    :isChild="true"
    :title="$t('negative_adjustments') | startcase"
    :count="userActionCounts.lineupAdjustmentsActionsCount + userActionCounts.ownershipAdjustmentsActions + userActionCounts.usageAdjustmentsActions"
  >
    <template v-slot:search>
      <form-field
        :placeholder="$t('search') | capitalize"
        v-model="filters.fuzzy_search.value"
        debounce="1000"
        :icon="['fas', 'search']"
      />
    </template>

    <template v-slot:filters>
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-radio-group
              v-model="filters.adjustment_type.value"
              buttons
              button-variant="outline-primary"
              name="radios-btn-outline"
            >
              <b-form-radio :value="adjustmentTypes.LINEUP">
                {{ $t("lineup_adjustments") | capitalize }}
              </b-form-radio>
              <b-form-radio :value="adjustmentTypes.OWNERSHIP">
                {{ $t("ownership_adjustments") | capitalize }}
              </b-form-radio>
              <b-form-radio :value="adjustmentTypes.USAGE">
                {{ $t("usage_adjustments") | capitalize }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template v-slot:counts>
      <div>
        <pagination
          class="smaller p-0 mt-3"
          reference="adjustments"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="limit"
          @limit="onLimit"
        />
      </div>
    </template>

    <crescendo-split-panes ref="panes">
      <template v-slot:top>
        <component
          ref="adjustments"
          :filters="filters"
          :page="page"
          :page-number="pageNumber"
          :adjustment-type="adjustmentType"
          @selected="onSelected"
          @onLoaded="onLoaded"
          @onLoading="onLoading"
          @onPagination="onPagination"
          @onSorted="onSorted"
          :is="adjustmentComponents[adjustmentType]['list']"
        />
      </template>
      <template v-slot:bottom>
        <component
          v-if="selectedRow"
          ref="adjustmentsDetail"
          :selected-row="selectedRow"
          :status="filters.status.value"
          :is="adjustmentComponents[adjustmentType]['detail']"
          @resolved="onResolved"
        />
      </template>
    </crescendo-split-panes>
  </list-layout>
</template>

<script>
import { adjustmentTypes, getPerPageItemDefaults, userActionStatuses } from "@/constants"
import CrescendoSplitPanes from "@/components/CrescendoSplitPanes"
import NegativeAdjustmentLineupDetail from "@/pages/UserActions/NegativeAdjustmentLineupDetail"
import NegativeAdjustmentOwnershipDetail from "@/pages/UserActions/NegativeAdjustmentOwnershipDetail"
import NegativeAdjustmentUsageDetail from "@/pages/UserActions/NegativeAdjustmentUsageDetail"
import NegativeAdjustmentsRecordingList from "@/pages/UserActions/NegativeAdjustmentsRecordingList"
import NegativeAdjustmentsUsageList from "@/pages/UserActions/NegativeAdjustmentsUsageList"
import Pagination from "@/components/Pagination"
import { get } from "lodash"
import { listRouteMixin } from "@/utils/mixins"
import { mapState } from "vuex"

export default {
  name: "NegativeAdjustmentsTab",
  mixins: [listRouteMixin],
  components: {
    CrescendoSplitPanes,
    Pagination,
    NegativeAdjustmentsRecordingList,
    NegativeAdjustmentLineupDetail,
    NegativeAdjustmentOwnershipDetail,
    NegativeAdjustmentUsageDetail,
  },
  methods: {
    onResolved () {
      this.selectedRow = null
      this.getData()
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
    },
    getData () {
      this.$refs.adjustments.getData()
    },
    onSelected (item) {
      this.selectedRow = item
    }
  },
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
    adjustmentType () {
      return this.filters.adjustment_type.value || this.filters.adjustment_type.defaultValue
    },
  },
  watch: {
    "filters.adjustment_type.value": function (newVal, oldVal) {
      this.selectedRow = null
      if (oldVal && newVal !== oldVal) {
        this.filters.adjustment_id.value = ""
      }
    },
  },
  data () {
    return {
      adjustmentTypes,
      selectedRow: null,
      adjustmentComponents: {
        [adjustmentTypes.LINEUP]: {
          list: NegativeAdjustmentsRecordingList,
          detail: NegativeAdjustmentLineupDetail,
        } ,
        [adjustmentTypes.OWNERSHIP]: {
          list: NegativeAdjustmentsRecordingList,
          detail: NegativeAdjustmentOwnershipDetail,
        },
        [adjustmentTypes.USAGE]: {
          list: NegativeAdjustmentsUsageList,
          detail: NegativeAdjustmentUsageDetail,
        }
      },
      page: Number(get(this.$route.query, "page", 1)),
      pageNumber: 1,
      count: 0,
      limit: Number(getPerPageItemDefaults(this.$route)),
      loading: false,
      instantFilters: true,
      filters: {
        fuzzy_search: { value: "", defaultValue: "" },
        adjustment_type: { value: "", defaultValue: get(this.$route.query, "adjustment_type", adjustmentTypes.LINEUP) },
        adjustment_id: { value: "", defaultValue: "", dataType: String },
        status: {
          value: [userActionStatuses.DONE],
          defaultValue: [userActionStatuses.DONE],
        },
      },
    }
  }
}
</script>
<style scoped>
::v-deep .smaller {
  font-size: 10pt;
}
</style>
