import { render, staticRenderFns } from "./RightsHolderMandateMetadata.vue?vue&type=template&id=2c154234&scoped=true&"
import script from "./RightsHolderMandateMetadata.vue?vue&type=script&lang=js&"
export * from "./RightsHolderMandateMetadata.vue?vue&type=script&lang=js&"
import style0 from "./RightsHolderMandateMetadata.vue?vue&type=style&index=0&id=2c154234&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c154234",
  null
  
)

export default component.exports