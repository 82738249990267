<template>
  <div>
    <!-- Global Errors -->
    <template v-if="item && item.statusString && onlyShowFileLevelErrors">
      <div :key="index" v-for="(line, index) in item.statusString.split('\n')">
        <p class="mb-1">{{ cleanupLine(line) }}</p>
      </div>
    </template>
    <!-- Row Errors -->
    <v-server-table
      v-if="item && !onlyShowFileLevelErrors"
      v-show="errorCount"
      ref="fileErrorsTable"
      :columns="[...columns, ...extraColumns]"
      :options="options"
    />
    <b-row class="modal-footer mt-4">
      <div v-if="fileErrorsDownloadEndpoint" class="d-flex justify-content-between w-100">
        <b-button @click="closeModal()" class="btn-outline-dark">{{ $t("cancel") | capitalize }}</b-button>
        <div>
          <b-button @click="downloadErrors" class="btn-outline-red" :disabled="downloading">{{ $t("download") | capitalize }}</b-button>
          <b-spinner v-if="downloading" variant="dark" class="spinner ml-2" small/>
        </div>
      </div>
      <div v-else class="d-flex justify-content-end w-100">
        <b-button @click="closeModal()" class="btn-outline-red">{{ $t("cancel") | capitalize }}</b-button>
      </div>
    </b-row>
  </div>
</template>

<script>
import { capitalize, trim } from "lodash"
import { fileDownloadFromBlob } from "@/utils/file-download"

export default {
  name: "FilesErrorsList",
  data () {
    return {
      downloading: false,
      columns: ["itemSeq", "field", "reason"],
      errorCount: 0,
      options: {
        skin: "table table-striped modal-table",
        headings: {
          itemSeq: capitalize(this.$t("item")),
          field: capitalize(this.$t("field")),
          reason: capitalize(this.$t("reason")),
        },
        sortable: [],
        perPage: 10,
        responseAdapter ({ data }) {
          this.$parent.$parent.errorCount = data.count
          this.errorCount = data.count
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction (queryParams) {
          const id = this.$parent.$parent.item.id
          return this.$parent.$parent.fileErrorsEndpoint(id, queryParams)
        }
      }
    }
  },
  methods: {
    cleanupLine (line) {
      return trim(line, "()''")
    },
    async downloadErrors () {
      this.downloading = true
      let response = await this.fileErrorsDownloadEndpoint(this.item.id)
      this.downloading = false
      fileDownloadFromBlob(response.data, `Error List - ${this.item.fileName}.csv`)
    },
    closeModal () {
      this.$parent.$parent.hide()
    }
  },
  props: {
    item: Object,
    module: String,
    onlyShowFileLevelErrors: {
      type: Boolean,
      default: false,
    },
    fileErrorsEndpoint: {
      type: Function,
      required: true,
    },
    fileErrorsDownloadEndpoint: {
      type: Function,
      required: false,
    },
    extraColumns: {
      type: Array,
      default () {
        return []
      }
    }
  }

}
</script>
