<template>
  <div class="d-flex flex-column">
    <div v-if="title" class="heading mb-3">
      <slot name="title">
        <h3>
          {{ title | capitalize }}
          <template v-if="count">({{ count }})</template>
        </h3>
    </slot>
      <!-- Sub-Title -->
      <slot name="subtitle"></slot>
    </div>

    <b-card no-body class="card-container">
      <b-tabs v-model="tabIndex" card justified lazy>
        <b-tab
          v-for="(tab, index) in visibleTabs"
          :key="index"
        >
          <!-- Tab Title -->
          <template v-slot:title>
            <slot name="tabTitle" :tab="tab" :index="index"></slot>
          </template>

          <!-- Tab Static content  -->
          <slot name="static-content" :tab="tab">

          </slot>

          <!-- Tab Content -->
          <b-card-text>
            <slot name="content" :tab="tab" :index="index"></slot>
          </b-card-text>

        </b-tab>

        <!-- Static Tabs -->
        <slot name="static-tabs"></slot>
      </b-tabs>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
  ::v-deep .card-header {
    background-color: transparent;
    border-bottom: 1px solid $gray-light;
  }

  ::v-deep .card-body {
    padding: 0px 0;

    .card {
      margin-bottom: 10px;
      border: none;
    }

    .card-header {
      padding: 12px;
      border: none;
      border-radius: 0 5px 5px 5px;
    }

    .first-level {
      background-color: $gray;
    }

    .second-level {
      background-color: $orange-lighter;
      padding: 12px 27px;
    }
  }

  ::v-deep .tab-content {
    .pagination {
      margin-bottom: 0;

      * {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  ::v-deep .nav-item {
    font-size: 11pt;
    text-align: left;
    width: auto;
    flex: 0 0 auto;

    & .active {
      border: none;
      border-bottom: 3px solid var(--secondary);
    }

    & .nav-link {
      padding: 10px 15px;
      display: flex;
      align-items: baseline;
    }

    & .nav-link:not(.active) {
      color: var(--primary);
    }

    & .nav-link.disabled {
      color: var(--gray);
    }

    & .nav-link.active {
      color: var(--primary);
    }
  }

  .card-container {
    border: none;
  }

  ::v-deep .heading {
    color: var(--red);
  }
</style>

<script>
export default {
  name: "TabsLayout",
  data: () => {
    return {
      tabIndex: 0,
    }
  },
  props: {
    title: String,
    count: [String, Number],
    value: null,
    tabs: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    heading: null
  },
  computed: {
    visibleTabs () {
      return this.tabs.filter(tab => !(tab.visible === false))
    }
  },
  watch: {
    // Handles internal model changes.
    tabIndex (index) {
      this.$emit("change", { index, tab: this.tabs[index] })
    },
    // Handles external model changes.
    value (index) {
      this.tabIndex = index
    }
  },
  mounted () {
    if (this.value) {
      this.tabIndex = this.value
    }
  }
}
</script>
