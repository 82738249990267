<template>
  <list-layout 
    :isChild="true"
    :title="$t('repertoire_errors') | startcase"
    :count="userActionCounts.repertoireErrorActionsCount"
    >
    <template v-slot:search>
      <form-field
        :placeholder="$t('search') | capitalize"
        v-model="fuzzy_search"
        debounce="1000"
        :icon="['fas', 'search']"
      />
    </template>

    <template v-slot:counts>
      <pagination
          class="smaller p-0 mb-3 mt-3"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="perPage"
          @limit="onLimit"
      />
    </template>

    <b-card no-body v-for="(file, index) in data" :key="index">
      <b-card-header class="first-level">
        <b-row align-v="center">
          <b-col cols="6">
            <collapse-button
              class="collapse-button"
              :toggle="'file-' + file.id"
              :expanded="expanded[index]"
            />
            <span>{{ $t("file") | capitalize }}: </span>
            <router-link
              class="text-info"
              :to="{
                name: 'landing-recordings',
                params: { repFileId: file.id },
              }"
            >
              {{ file.fileName }}
            </router-link>
          </b-col>
          <b-col cols="6">
            <b-row class="w-100">
              <b-col cols="4">
                <span
                  >{{ $t("uploaded_on") | capitalize }}:
                  {{ parseDate(file.uploadDate, $config.DATE_FORMAT) }}</span
                >
              </b-col>
              <b-col cols="5">
                <span
                  >{{ $t("by") | capitalize }}:
                  {{
                    file.uploader.firstName + " " + file.uploader.lastName
                  }}</span
                >
              </b-col>
              <b-col cols="3">
                <span v-if="file.status !== 'ER'" class="conflicts-count"
                  >{{ $t("errors") | capitalize }}:
                  {{ file.stats.landingDbIngestion.errorCount }}</span
                >
                <span v-else class="ingestion-error">{{
                  $t("ingestion_error") | startcase
                }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse
        accordion="files-list"
        :id="'file-' + file.id"
        v-model="expanded[index]"
        role="tabpanel"
      >
        <b-card-body v-if="expanded[index]">
          <div v-if="file.status !== 'ER'">
            <RepertoireErrorDetail :file="file" />
          </div>
          <div v-else>
            <p>
              {{ $t("ingestion_error_message") | capitalize }}
              <span class="ingestion-error">
                <div id="ingestion-error">
                  {{ file.statusString }}
                </div>
              </span>
            </p>
            <p>{{ $t("contact_admin") | capitalize }}</p>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <table-pagination
      class="mt-3"
      v-model="page"
      :total-rows="count"
      :per-page="perPage"
    />
  </list-layout>
</template>
<style lang="scss" scoped>
.first-level {
  .collapse-button {
    margin-right: 5px;
  }
}
.card {
  margin-bottom: 10px;
}
.ingestion-error {
  color: var(--red);

  #ingestion-error {
    font-weight: bold;
  }
}
</style>

<script>
import Pagination from "@/components/Pagination"
import RepertoireErrorDetail from "./RepertoireErrorDetail"
import { get } from "lodash"
import { getPerPageItemDefaults } from "@/constants"
import { listRouteMixin } from "@/utils/mixins"
import { mapState } from "vuex"
import parseDate from "@/utils/date-parser"

export default {
  name: "RepertoireErrorsList",
  components: {
    RepertoireErrorDetail,
    Pagination,
  },
  mixins: [listRouteMixin],
  computed: {
    ...mapState("actionTabs", ["userActionCounts"]),
  },
  methods: {
    responseAdapter (data) {
      this.data = data.data.results
      this.count = data.data.count
      this.loading = false
    },
    async getData () {
      this.loading = true
      this.expanded = []
      let response = await this.$api.repertoire.repertoireFilesList({
        ...this.$route.query, errors_inside: true
      })
      this.responseAdapter(response)
    },
    parseDate,
  },
  data () {
    return {
      instantFilters: true,
      count: 0,
      loading: true,
      data: [],
      expanded: [],
      fuzzy_search: "",
      page: 1,
      perPage: Number(getPerPageItemDefaults(this.$route)),
      filters: {
        fuzzy_search: { value: "", defaultValue: "" },
      }
    }
  },
  watch: {
    fuzzy_search (value) {
      this.changeFilter("fuzzy_search", value)
    },
    page (value) {
      this.onPagination(value)
      this.getData()
    },
    perPage () {
      if (this.page === 1) {
        this.getData()
      } else {
        this.page = 1
      }
    },
  },
  mounted () {
    this.getData()
    this.fuzzy_search = get(this.$route.query, "fuzzy_search", "")
    this.page = Number(get(this.$route.query, "page", 1))
    this.perPage = Number(get(this.$route.query, "limit", this.perPage))
  },
}
</script>
