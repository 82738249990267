import { API } from "@/services/utils"

export const usages = {
  consolidatedUsagesList: (params = null) => API.get("/usage/consolidated-usages/",
    { params: params }),
  usagesCandidatesList: (params = null) => API.get("/usage/consolidated-usages/candidates/",
    { params: params, keepState: false }),
  usagesFilesList: (params = null) => API.get("/usage/usage-files/",
    { params: params }),
  usageFilesErrorsList: (uuid, params = null) => API.get(`/usage/usage-files/${uuid}/errors/`, { params }),
  usagesRematch: (params = null) => API.get("/usage/consolidated-usages/rematch/", { params: params }),
  usageFileDistributionStatus: (uuid, data) => API.post(`/usage/usage-files/${uuid}/set_distribution_status/`, data),
  usagesExport: data => API.post("/usage/consolidated-usages/export/", data),
  checkUsagesExport: data => API.post("/usage/consolidated-usages/check_export_count/", data),
  usagesReview: data => API.post("/usage/consolidated-usages/review/", data),
  usagesReviewHistoryList: (params = null) => API.get("/usage/consolidated-usages/review_history/",
    { params: params, keepState: false }),

  recordingExport: data => API.post("/usage/consolidated-usages/export_unclaimed_recordings/", data),
  markAsJunkConsolidatedUsages: data => API.post("/usage/consolidated-usages/mark_as_junk/", data),
  junkHistoryConsolidatedUsages: (uuid, params) => API.get(`/usage/consolidated-usages/${uuid}/junk_history/`, { params: params }),
  downloadErrorFile: (uuid, params = null) => API.get(`/usage/usage-files/${uuid}/download-error-file/`, { params, }),
}
