<template>
  <div class="bg-white" v-if="uuids.length">
    <modal
      :id="usageModalTypes.REVIEW_USAGES"
      :customTitle="`${$t('mark_as')} ${$t('reviewed')}` | capitalize"
      modal-class="custom-modal edit"
      modalType="edit"
      size="lg"
      centered
      hide-footer
    >
      <usages-review-form
          :id="usageModalTypes.REVIEW_USAGES"
          @reviewed="reviewUsages"
      />
    </modal>
    <modal
      :id="usageModalTypes.USE_SUGGESTED_RECORDING"
      :customTitle="$t('use_suggested_recording') | startcase"
      modal-class="custom-modal edit"
      modalType="edit"
      size="xl"
      centered
      hide-footer
    >
      <usage-challenge-form
          :id="usageModalTypes.USE_SUGGESTED_RECORDING"
          :recording="selectedRecording"
          :challenge-id="challengeUuid"
          :usage-id="uuids[0]"
          @resolved="challengeResolved"
      />
    </modal>
    <t-table
      class="h-75 pb-0"
      ref="candidatesTable"
      id="candidates"
      :columns="candidatesColumns"
      :options="candidatesOptions"
      :local-data="candidates"
      :limit="perPage"
      @loaded="onLoaded"
      @loading="onLoading"
      @pagination="onPage"
      @sorted="onSorted"
    >
      <template v-slot:counts>
        <div class="row mb-2 d-flex align-items-center">
          <div class="col d-flex justify-content-start title">
            <h3>{{ $t("suggested_recordings") | startcase }}</h3>
            <b-spinner class="ml-2" v-if="candidatesLoading" variant="light"/>
            <fa-icon v-if="candidateRecordingsSearch && !candidatesLoading" class="ml-2 mt-1" :icon="['fas', 'search']" size="lg"/>
          </div>
        </div>
      </template>

      <template v-slot:filters>
        <div class="row mb-2 d-flex align-items-center">
          <div class="col d-flex justify-content-end">
             <div class="bg-white" v-if="uuids.length">
                <div class="row mb-2 d-flex align-items-end">
                  <div class="col d-flex justify-content-end">
                    <b-form-group v-if="canReview && hasPermissionToUseOrMatch()" class="mr-3" :disabled="waiting">
                      <div class="d-inline gray-button">
                        <b-button variant="outline-primary right-border" @click="reviewUsages">
                          {{ $t("mark_as") | capitalize }} {{ $t("reviewed") }}
                        </b-button>
                      </div>
                      <b-dropdown variant="outline-primary" class="gray-button">
                        <template #button-content>
                          <span>|</span>
                        </template>
                        <b-dropdown-item @click="reviewUsages">
                          {{ $t("mark_as") | capitalize }} {{ $t("reviewed") }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="$bvModal.show(usageModalTypes.REVIEW_USAGES)">
                          {{ $t("mark_as") | capitalize }} {{ $t("reviewed") }} {{ $t("with_comment") }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-form-group>
                    <b-form-group class="m-0" v-if="!challengeView">
                      <form-field
                          :placeholder="$t('search') | capitalize"
                          v-model="candidateRecordingsSearch"
                          debounce="1000"
                          :icon="['fas', 'search']"
                      />
                       <b-form-checkbox v-model="candidateRecordingsSearchSameType" variant="outline-secondary" switch :disabled="!!candidatesRecordingsSearchInProgress">
                        {{ $t("show_same_type") | capitalize }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
             </div>
          </div>
        </div>
      </template>

      <!-- RS ID -->
      <template v-slot:resoundId="{ row }">
        <database-link :type="linkTypes.RECORDING" :item-id="row.id">{{ row.resoundId }}</database-link>
      </template>
      <!-- Artist -->
      <template v-slot:artist="{ row }">
        <span :title="row.artist">{{ row.artist }}</span>
      </template>
      <!-- Title -->
      <template v-slot:title="{ row }">
        <span class="short-text" :title="row.title">{{ row.title }}</span>
      </template>
      <!-- Albums -->
      <template v-slot:album="{ row }">
        <span :title="getMetadata(row.extras, 'release').join(', ')">{{ getMetadata(row.extras, 'release').join(", ") }}</span>
      </template>
      <!-- Composers -->
      <template v-slot:composer="{ row }">
        <span :title="getMetadata(row.extras, 'composer').join(', ')">{{ getMetadata(row.extras, 'composer').join(", ") }}</span>
      </template>
      <!-- First Maker Name -->
      <template v-slot:firstMakerNames="{ row }">
        <span :title="row.firstMakers ? row.firstMakers.map(f => f.name) : ''">
          {{ row.firstMakers && row.firstMakers.map(f => f.name).join(", ") }}
        </span>
      </template>
      <!-- Nationality -->
      <template v-slot:nationality="{ row }">
        {{ row.firstMakerNationalityIds ? countries.filter(c => row.firstMakerNationalityIds.includes(c.value)).map(n => n.alpha2).join(", ") : "" }}
      </template>
      <!-- Fixation Country -->
      <template v-slot:fixationCountry="{ row }">
        <span :title="row.fixationCountry ? row.fixationCountry.name : ''">
          {{ row.fixationCountry ? row.fixationCountry.name : "" }}
        </span>
      </template>
      <!-- Duration -->
      <template v-slot:duration="{ row }">
        <span>
          {{ row.duration ? $options.filters.toDuration(row.duration) : "" }}
        </span>
      </template>
      <!-- Performers Owners FinancialInterest -->
      <template v-slot:hasPerformersOwnersFinancialInterest="{row}">
        <div class="d-flex">
          <div v-if="row.hasPerformers" class="icon-wrapper icon-width" >
            <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'microphone-alt']"/>
          </div>
          <div v-if="row.hasOwners" class="icon-wrapper icon-width" >
            <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'compact-disc']"/>
          </div>
          <div v-if="showFinancialInterest && row.hasFinancialInterest" class="icon-wrapper icon-width" >
            <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'dollar-sign']"/>
          </div>
        </div>
      </template>
      <!-- Match button -->
      <template v-slot:actions="{ row }">
        <div class="text-left mr-1">
          <b-button
            v-if="hasPermissionToUseOrMatch()"
            class="pt-0 pb-0"
            @click="linkRecording(row)"
            :disabled="uuids.length === 0 || waiting"
            variant="outline-secondary">
            {{ challengeView ? $t("use") : $t("match") | capitalize }}
          </b-button>
        </div>
      </template>
    </t-table>
    <t-table
      ref="allRecordingsSearchTable"
      id="all-recordings-search"
      :key="tableKey"
      :load-data="Boolean(uuids.length && allRecordingsSearch)"
      :columns="candidatesColumns"
      :options="candidatesOptions"
      :limit="limit"
      @loaded="onLoaded"
      @loading="onLoading"
      @pagination="onPage"
      @sorted="onSorted">

      <template v-slot:counts>
        <div class="row mb-2 d-flex align-items-center">
          <div class="col d-flex justify-content-start title">
            <h3>{{ $t("searched_recordings") | startcase }}</h3>
          </div>
        </div>
        <pagination
          class="smaller p-0"
          :page="page"
          :count="count"
          :loading="loading"
          v-model="limit"
          @limit="onLimit"
        />
      </template>

      <template v-slot:filters>
        <b-form-group class="m-0">
          <form-field
              :placeholder="$t('search_all_recordings') | capitalize"
              v-model="allRecordingsSearch"
              debounce="1000"
              :icon="['fas', 'search']"
          />
          <b-form-checkbox v-if="recordingType !== null" v-model="allRecordingsSearchSameType" variant="outline-secondary" switch :disabled="!!allRecordingsSearchInProgress">
            {{ $t("show_same_type") | capitalize }}
          </b-form-checkbox>
        </b-form-group>
      </template>

      <!-- RS ID -->
      <template v-slot:resoundId="{ row }">
        <database-link :type="linkTypes.RECORDING" :item-id="row.id">
          {{ row.resoundId }}
        </database-link>
      </template>
      <!-- Artist -->
      <template v-slot:artist="{ row }">
        <span :title="row.artist">{{ row.artist }}</span>
      </template>
      <!-- Title -->
      <template v-slot:title="{ row }">
          <span class="short-text" :title="row.title">{{ row.title }}</span>
      </template>
      <!-- Albums -->
      <template v-slot:album="{ row }">
        <span :title="row.albumNames">
          {{ row.albumNames && row.albumNames.length ? row.albumNames.join(", ") : "" }}
        </span>
      </template>
      <!-- Composers -->
      <template v-slot:composer="{ row }">
        <span :title="row.composers ? row.composers.join(', ') : ''">{{ row.composers ? row.composers.join(", ") : '' }}</span>
      </template>
      <!-- First Maker Name -->
      <template v-slot:firstMakerNames="{ row }">
        <span :title="row.firstMakerNames ? $t(row.firstMakerNames) : '' | startcase">
          {{ row.firstMakerNames && $t(row.firstMakerNames.join(", ")) | startcase }}
        </span>
      </template>
      <!-- Country of Fixation -->
      <template v-slot:fixationCountry="{ row }">
        <span :title="countries.find(c => c.value === row.fixationCountryId) ? countries.find(c => c.value === row.fixationCountryId).text : ''">
                    {{ countries.find(c => c.value === row.fixationCountryId) ? countries.find(c => c.value === row.fixationCountryId).text : "" }}
        </span>
      </template>
      <!-- Nationality -->
      <template v-slot:nationality="{ row }">
        {{ row.firstMakerNationalityCodes ? countries.filter(c => row.firstMakerNationalityCodes.includes(c.code)).map(n => n.alpha2).join(", ") : "" }}
      </template>
      <!-- Duration -->
      <template v-slot:duration="{ row }">
        <span>
          {{ row.duration ? $options.filters.toDuration(row.duration) : "" }}
        </span>
      </template>
      <!-- Performers Owners FinancialInterest -->
      <template v-slot:hasPerformersOwnersFinancialInterest="{row}">
        <div class="d-flex">
          <div v-if="row.hasPerformers" class="icon-wrapper icon-width" >
            <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'microphone-alt']"/>
          </div>
          <div v-if="row.hasOwners" class="icon-wrapper icon-width" >
            <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'compact-disc']"/>
          </div>
          <div v-if="showFinancialInterest && row.hasFinancialInterest" class="icon-wrapper icon-width" >
            <fa-icon class="icon text-gray" size="sm" :icon="['fas', 'dollar-sign']"/>
          </div>
        </div>
      </template>
      <!-- Match button -->
      <template v-slot:actions="{ row }">
        <div class="text-left mr-1">
          <b-button
            v-if="hasPermissionToUseOrMatch()"
            class="pt-0 pb-0"
            @click="linkRecording(row)"
            :disabled="uuids.length === 0 || waiting"
            variant="outline-secondary">
            {{ challengeView ? $t("use") : $t("match") | capitalize }}
          </b-button>
        </div>
      </template>
    </t-table>
    <t-table
      ref="reviewHistoryTable"
      id="review-history"
      :columns="reviewColumns"
      :options="reviewOptions"
      :local-data="reviewHistoryData"
      :limit="perPage">

      <template v-slot:counts>
      <div class="row mb-2 d-flex align-items-center">
        <div class="col d-flex justify-content-start title">
          <h3>{{ $t("review_history") | startcase }}</h3>
        </div>
      </div>
    </template>

      <template v-slot:reviewDate="{ row }">
        <div class="d-flex">
          <div class="sequence-number pr-2">{{ uuids.indexOf(row.usageId) >= 0 ? uuids.indexOf(row.usageId) + 1 : "" }}</div>
          <span>{{ parseDate(row.reviewDate, $config.DATETIME_FORMAT) }}</span>
        </div>
      </template>

      <template v-slot:user="{ row }">
        <span v-if="row.user">{{ row.user.firstName }} {{ row.user.lastName }}</span>
      </template>
    </t-table>
  </div>
</template>
<script>
import { CHUNK_SIZE_UUIDS, actionsTabs, linkTypes, perPageItemsSm, usageModalTypes } from "@/constants"
import { capitalize, chunk, snakeCase, startCase, upperCase } from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import DatabaseLink from "@/components/DatabaseLink.vue"
import Pagination from "@/components/Pagination"
import UsageChallengeForm from "@/pages/Usages/Forms/UsageChallengeForm"
import UsagesReviewForm from "@/pages/Usages/Forms/UsagesReviewForm"
import { getMetadata } from "@/pages/Repertoire/Recordings/RecordingDetail/utils"
import moment from "moment"
import parseDate from "@/utils/date-parser"

export default {
  name: "UsageRecordingCandidates",
  components: { DatabaseLink, Pagination, UsagesReviewForm, UsageChallengeForm },
  props: {
    uuids: {
      type: Array,
      default: () => [],
    },
    reviewedUsages: {
      type: Array,
      default: () => [],
    },
    candidates: {
      type: Array,
      default: () => [],
    },
    challengeUuid: {
      type: String,
      default: null,
    },
    challengeView: {
      type: Boolean,
      default: false,
    },
    solvedManually: {
      type: Array,
      required: false,
    },
    recordingType: {
      type: String,
      default: null,
    },
    showFinancialInterest: {
      type: Boolean,
      default: false,
    },
    candidatesLoading: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    allRecordingsSearch (newVal) {
      if (newVal && this.$refs.allRecordingsSearchTable) {
        this.$refs.allRecordingsSearchTable.getData()
      } else {
        this.count = 0
        this.page = 1
        // Force reload of t-table so that no results are shown
        this.tableKey++
      }
    },
    candidateRecordingsSearch (newVal) {
      let data ={ fuzzy_search: newVal }
      if (this.candidateRecordingsSearchSameType) {
        data.type = this.recordingType
      }
      this.$emit("candidates-search-changed", data)
    },
    candidateRecordingsSearchSameType (newVal) {
      let data = {}
      if (this.candidateRecordingsSearch) data.fuzzy_search =this.candidateRecordingsSearch
      if (newVal) data.type = this.recordingType
      this.$emit("candidates-search-changed", data)
    },
    allRecordingsSearchSameType () {
      if (this.allRecordingsSearch) {
        this.queryParams = {}
        this.$refs.allRecordingsSearchTable.getData()
      }
    },
    uuids (newVal) {
      if (newVal.length) {
        this.loadReviewHistory(newVal)
      }
    }
  },
  computed: {
    ...mapState("consts", ["countries"]),
    ...mapState("user", ["permissions", "waiting"]),
    canReview () {
      /**
       * Return true if the selected usages can be reviewed, false otherwise.
       *
       * The selected unique usages can be reviewed if there's no pending
       * challenge and at least one of them hasn't been reviewed yet
       * or no pending challenge and has new matches.
       */

      const pendingReviewed = this.uuids.filter(u => !this.reviewedUsages.map(reviewed => reviewed.uuid).includes(u)).length

      const hasNewMatches = this.reviewedUsages.filter(reviewed => 
        this.uuids.includes(reviewed.uuid) && 
        reviewed.matchingResultsUpdated !== null && 
        moment(reviewed.matchingResultsUpdated) > moment(reviewed.latestReview)
      ).length

      return Boolean(!this.challengeUuid && (pendingReviewed || hasNewMatches))
    }
  },
  data () {
    return {
      linkTypes,
      selectedRecording: null,
      tableKey: 1,
      currentY: 0,
      candidatesData: [],
      reviewHistoryData: [],
      // All recordings search
      allRecordingsSearch: "",
      allRecordingsSearchInProgress: false,
      allRecordingsSearchSameType: this.recordingType !== null,
      // Candidate recordings search
      candidateRecordingsSearch: "",
      candidatesRecordingsSearchInProgress: false,
      candidateRecordingsSearchSameType: this.recordingType !== null,
      queryParams: {},
      candidatesColumns: [
        "resoundId",
        "artist",
        "title",
        "album",
        "composer",
        "isrc",
        "firstMakerNames",
        "nationality",
        "fixationCountry",
        "fixationYear",
        "releaseYear",
        "type",
        "duration",
        "genreType",
        "rank",
        "hasPerformersOwnersFinancialInterest",
        "actions",
      ],
      candidatesOptions: {
        sortable: ["resoundId","artist","title","isrc","fixationCountry","fixationYear","releaseYear","type","duration","genreType","rank"],
        sorters: {
          fixationCountry (a, b) {
            if (typeof a.fixationCountry?.name === "undefined") return -1
            if (typeof b.fixationCountry?.name === "undefined") return 1
            return a.fixationCountry?.name.localeCompare(b.fixationCountry?.name)
          },
          isrc: "string",
          genreType: "string",
        },
        headings: {
          "artist": capitalize(this.$t("artist")),
          "title": capitalize(this.$t("title")),
          "album": capitalize(this.$t("album")),
          "composer": capitalize(this.$t("composer")),
          "isrc": upperCase(this.$t("isrc")),
          "resoundId": this.$t("resound_id"),
          "firstMakerNames": startCase(this.$t("first_makers")),
          "nationality": upperCase(this.$t("fmn")),
          "fixationYear": upperCase(this.$t("yof")),
          "releaseYear": upperCase(this.$t("yor")),
          "fixationCountry": upperCase(this.$t("cof")),
          "type": capitalize(this.$t("type")),
          "duration": capitalize(this.$t("dur")),
          "genreType": startCase(this.$t("genre")),
          "rank": capitalize(this.$t("rank")),
          "hasPerformersOwnersFinancialInterest": "",
          "actions": "",
        },
        columnsClasses: {
          artist: "width-mini short-text",
          title: "width-mini short-text",
          album: "width-micro-big short-text",
          composer: "width-micro-big short-text",
          isrc: "width-micro-big",
          resoundId: "width-micro-big",
          firstMakerNames: "width-micro-big short-text",
          nationality: "width-micro short-text",
          fixationYear: "width-nano-big",
          releaseYear: "width-nano-big",
          fixationCountry: "width-micro short-text",
          type: "width-nano-big",
          duration: "width-nano-big",
          genreType: "width-nano-big",
          hasPerformersOwnersFinancialInterest: "pl-0 pr-0 text-right",
          rank: "width-2 pl-0 pr-0 text-right",
        },
        width: {
          hasPerformersOwnersFinancialInterest: 50,
          actions: 60,
        },
        responseAdapter ({ data }) {
          return {
            data: data.results,
            count: data.count,
          }
        },
        requestFunction () {
          const searchTerm = this.$parent.allRecordingsSearch
          this.$parent.queryParams.fuzzy_search = searchTerm
          if (this.$parent.allRecordingsSearchSameType) {
            this.$parent.queryParams.type = this.$parent.recordingType
          }
          return this.$api.repertoire.recordingsList(this.$parent.queryParams)
        },
      },
      reviewColumns: ["reviewDate", "user", "memberCollective.name", "comment"],
      reviewOptions: {
        sortable: ["reviewDate", "user", "memberCollective.name"],
        sorters: {
          user (a, b) {
            let name1 = a.user.firstName + " " + a.user.lastName
            let name2 = b.user.firstName + " " + b.user.lastName
            if (name1 < name2) return -1
            if (name1 > name2) return 1
            return 0
          }
        },
        headings: {
          "reviewDate": capitalize(this.$t("review_date")),
          "user": capitalize(this.$t("reviewer")),
          "memberCollective.name": capitalize(this.$t("mc")),
          "comment": capitalize(this.$t("comment")),
        },
        responseAdapter ({ data }) {
          return {
            data: data,
            count: data.length,
          }
        },
        requestFunction (queryParams) {
          if (this.$parent.$parent.fetchDetails) {
            queryParams.uuids = this.$parent.$parent.uuids
            return this.$api.usages.usagesReviewHistoryList(queryParams)
          }
        },
      },
      loading: false,
      page: 1,
      count: 0,
      limit: perPageItemsSm,
      perPage: perPageItemsSm,
      usageModalTypes,
    }
  },
  methods: {
    ...mapActions("consts", ["getCountries", ]),
    ...mapMutations("alert", ["success", "error"]),
    ...mapActions("userActions", ["getUserActionsSummary"]),
    onPage (page) {
      this.page = page
      this.updateQueryParams("page",page)
    },
    onLimit (limit) {
      this.limit = limit
      this.updateQueryParams("limit",limit)
    },
    onLoaded ({ data }) {
      this.count = data.count
      this.loading = false
      this.allRecordingsSearchInProgress = false
      this.candidatesRecordingsSearchInProgress = false
    },
    onLoading () {
      this.loading = true
      this.allRecordingsSearchInProgress = true
      this.candidatesRecordingsSearchInProgress = true
    },
    onSorted (data) {
      let values = data.reduce((current, next) => {
        let already_in = current.map(d => d.startsWith("-") ? d.substring(0) : d)
        if (!already_in.includes(next.column.startsWith("-") ? next.column.substring(0) : next.column)) {
          current.push(next.ascending ? next.column : `-${next.column}`)
        }
        return current
      }, [])
      this.updateQueryParams("sorting", values)
    },
    updateQueryParams (fieldName, value) {
      fieldName = snakeCase(fieldName)
      this.queryParams = { ...this.queryParams, [fieldName]: value }
      this.$refs.allRecordingsSearchTable.getData()
    },
    hasPermissionToUseOrMatch () {
      if (this.challengeView) {
        return this.permissions.actionsTabs[actionsTabs.USAGE_CHALLENGES].canWrite
      }
      return this.permissions.actionsTabs[actionsTabs.USAGE_MATCHING].canWrite
    },
    addToSolvedManually (){
      // Calling this method will trigger a reload
      if (this.solvedManually) {
        if (this.challengeUuid) {
          this.solvedManually.push(this.challengeUuid)
        }
        this.uuids.forEach(selectedUuid => {
          this.solvedManually.push(selectedUuid)
        })
      }
    },
    linkRecording (recording) {
      this.addToSolvedManually()
      if (this.challengeView) {
        this.selectedRecording = recording
        this.$bvModal.show(usageModalTypes.USE_SUGGESTED_RECORDING)
      } else {
        this.$api.actions.usageConflictsSelectRecording({
          unique_usage_ids: this.uuids, recording_id: recording.id
        })
          .then(() => {
            this.$bvModal.hide(usageModalTypes.USE_SUGGESTED_RECORDING)
            this.$emit("matched", recording)
            this.getUserActionsSummary({ partial: true })
          })
          .catch(() => {
            this.$emit("notMatched", recording)
          })
      }
    },
    reviewUsages (data) {
      const params = data ? data : {}
      params.publicIds = this.uuids
      if (this.solvedManually) {
        if (this.challengeUuid) {
          this.solvedManually.push(this.challengeUuid)
        }
        this.uuids.forEach(selectedUuid => {
          this.solvedManually.push(selectedUuid)
        })
      }
      this.$api.usages.usagesReview(data)
        .then(() => {
          this.success(capitalize(this.$t("usages_reviewed")))
          this.$bvModal.hide(usageModalTypes.REVIEW_USAGES)
          this.$emit("reviewed")
        })
        .catch((error) => {
          let msg = `Error review usages. ${JSON.stringify(error.response.data)}`
          this.error(msg)
        })
    },
    challengeResolved (obj) {
      this.$emit("resolved", obj)
    },
    loadReviewHistory (uuids) {
      let requests = []
      const chunks = chunk(uuids, CHUNK_SIZE_UUIDS)
      for (const chunk of chunks) {
        requests.push(this.$api.usages.usagesReviewHistoryList({ uuids: chunk }))
      }
      Promise.all(requests)
        .then((values) => {
          this.reviewHistoryData = values.reduce((data, response) => [...data, ...response.data], [])
        })
        .catch(() => {
          this.error(capitalize(this.$t("wrong_uuids_specified")))
        })
    },
    getMetadata,
    parseDate,
  },
  mounted () {
    if (!this.countries.length) {
      this.getCountries()
    }
    if (!this.reviewHistoryData.length && this.uuids) {
      this.loadReviewHistory(this.uuids)
    }
  }
}
</script>
<style lang="scss">
.gray-button {
  .btn-outline-primary {
    border: 1px solid $light;
    color: $gray-darker;
    font-size: 1rem;
  }

  .right-border {
    border-right: none;
    left: 1px;
    position: relative;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .dropdown-toggle {
    border-left: 0;
    border-radius: 0 0.25rem 0.25rem 0;
  }

  .dropdown-item {
    color: $gray-darker;
  }
}

#sc-drag-handle {
  &:hover {
    cursor: n-resize;
  }
}

.title {
  color: var(--primary) !important;
}

.icon-width {
  min-width: 2rem;
}
</style>
