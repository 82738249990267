<template>
  <span v-if="tooltipEnabled(field)" class="d-inline-block text-truncate history-element" v-b-tooltip.hover :title="field">
    <slot>{{ field ? field : "-" }}</slot>
  </span>
  <span v-else class="d-inline-block text-truncate">
    <slot>{{ field ? field : "-" }}</slot>
  </span>
</template>

<script>
export default {
  name: "ActionHistoryElement",
  props: {
    field: {
      required: true,
    },
    showTooltip: {
      default: true
    }
  },
  methods: {
    tooltipEnabled ( field ) {
      if ( this.showTooltip === false || field === null ) {
        return false
      }
      return field.length > 20
    }
  }
}
</script>

<style scoped>
.history-element {
  max-width: 150px;
}
</style>